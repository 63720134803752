import * as CountryConstants from "../constants/country.constants";
import {Loading} from "../../utils/helpers/constants";
import {
  formatCountriesDropdown,
  formatCountriesPhoneCode, formatCurrenciesInCountriesDropdown,
  formatCurrencyDropdown, formatPayCentreDropdown
} from "../../utils/helpers/logicHelper";

const initialState = {
  supportedCountries: [],
  countries: [],
  supportedOriginCountries: [],
  currencies: [],
  supportedWalletCurrencies: [],
  supportedPhoneCodes: [],
  transferFundsCountries: [],
  cashPickupCountries: [],
  cashPickupLocations: [],
  mobileMoneyCountries: [],
  airtimeTopUpCountries: [],
  billPaymentCountries: [],
  merchantProductCountries: [],
  supportedCurrencies: [],
  loadingCurrencies: "",
  loadingWalletCurrencies: "",
  loadingCountries: "",
  loadingOriginCountries: "",
  loadingCashPickupLocations: ""
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CountryConstants.FETCH_COUNTRIES_REQUEST:
      return {...state, loadingCountries: Loading.FETCHING};

    case CountryConstants.FETCH_COUNTRIES_SUCCESS:
      const supportedCountries = formatCountriesDropdown(action.payload['countries']);
      const countries = formatCountriesDropdown(action.payload['countries']);
      return {...state, loadingCountries: Loading.SUCCESS, supportedCountries, countries};

    case CountryConstants.FETCH_COUNTRIES_PHONE_CODE_SUCCESS:
      const supportedPhoneCodes = formatCountriesPhoneCode(action.payload['countries']);
      return {...state, loadingCountries: Loading.SUCCESS, supportedPhoneCodes};

    case CountryConstants.FETCH_COUNTRIES_CURRENCIES_SUCCESS:
      const currencies = formatCurrenciesInCountriesDropdown(action.payload['countries']);
      return {...state, loadingCountries: Loading.SUCCESS, currencies};

    case CountryConstants.FETCH_COUNTRIES_FAILURE:
      return {...state, loadingCountries: Loading.ERROR};

    case CountryConstants.FETCH_ORIGIN_COUNTRIES_REQUEST:
      return {...state, loadingOriginCountries: Loading.FETCHING};

    case CountryConstants.FETCH_ORIGIN_COUNTRIES_SUCCESS:
      const supportedOriginCountries = formatCountriesDropdown(action.payload);
      return {...state, loadingOriginCountries: Loading.SUCCESS, supportedOriginCountries};

    case CountryConstants.FETCH_ORIGIN_COUNTRIES_FAILURE:
      return {...state, loadingOriginCountries: Loading.ERROR};

    case CountryConstants.FETCH_SUPPORTED_CURRENCIES_REQUEST:
      return {...state, loadingCurrencies: Loading.FETCHING};

    case CountryConstants.FETCH_SUPPORTED_CURRENCIES_SUCCESS:
      const supportedCurrencies = formatCurrencyDropdown(action.payload['response']);
      return {...state, loadingCurrencies: Loading.SUCCESS, supportedCurrencies};

    case CountryConstants.FETCH_SUPPORTED_CURRENCIES_FAILURE:
      return {...state, loadingCurrencies: Loading.ERROR};

    case CountryConstants.FETCH_SUPPORTED_WALLET_CURRENCIES_REQUEST:
      return {...state, loadingWalletCurrencies: Loading.FETCHING};

    case CountryConstants.FETCH_SUPPORTED_WALLET_CURRENCIES_SUCCESS:
      return {
        ...state,
        loadingWalletCurrencies: Loading.SUCCESS,
        supportedWalletCurrencies: action.payload["currencies"]
      };

    case CountryConstants.FETCH_SUPPORTED_WALLET_CURRENCIES_FAILURE:
      return {...state, loadingWalletCurrencies: Loading.ERROR};

    case CountryConstants.FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_REQUEST:
      return {...state, loadingCountries: Loading.FETCHING};

    case CountryConstants.FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_SUCCESS:
      const transferFundsCountries = formatCountriesDropdown(action.payload['countries']);
      return {...state, loadingCountries: Loading.SUCCESS, transferFundsCountries};

    case CountryConstants.FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_FAILURE:
      return {...state, loadingCountries: Loading.ERROR};

    case CountryConstants.FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_REQUEST:
      return {...state, loadingCountries: Loading.FETCHING};

    case CountryConstants.FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_SUCCESS:
      const cashPickupCountries = formatCountriesDropdown(action.payload['countries']);
      return {...state, loadingCountries: Loading.SUCCESS, cashPickupCountries};

    case CountryConstants.FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_FAILURE:
      return {...state, loadingCountries: Loading.ERROR};

    case CountryConstants.FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_REQUEST:
      return {...state, loadingCountries: Loading.FETCHING};

    case CountryConstants.FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_SUCCESS:
      const mobileMoneyCountries = formatCountriesDropdown(action.payload['countries']);
      return {...state, loadingCountries: Loading.SUCCESS, mobileMoneyCountries};

    case CountryConstants.FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_FAILURE:
      return {...state, loadingCountries: Loading.ERROR};

    case CountryConstants.FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_REQUEST:
      return {...state, loadingCountries: Loading.FETCHING};

    case CountryConstants.FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_SUCCESS:
      const airtimeTopUpCountries = formatCountriesDropdown(action.payload['countries']);
      return {...state, loadingCountries: Loading.SUCCESS, airtimeTopUpCountries};

    case CountryConstants.FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_FAILURE:
      return {...state, loadingCountries: Loading.ERROR};

    case CountryConstants.FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_REQUEST:
      return {...state, loadingCountries: Loading.FETCHING};

    case CountryConstants.FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_SUCCESS:
      const billPaymentCountries = formatCountriesDropdown(action.payload['countries']);
      return {...state, loadingCountries: Loading.SUCCESS, billPaymentCountries};

    case CountryConstants.FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_FAILURE:
      return {...state, loadingCountries: Loading.ERROR};

    case CountryConstants.FETCH_MERCHANT_SUPPORTED_COUNTRIES_REQUEST:
      return {...state, loadingCountries: Loading.FETCHING};

    case CountryConstants.FETCH_MERCHANT_SUPPORTED_COUNTRIES_SUCCESS:
      const merchantProductCountries = formatCountriesDropdown(action.payload['countries']);
      return {...state, loadingCountries: Loading.SUCCESS, merchantProductCountries};

    case CountryConstants.FETCH_MERCHANT_SUPPORTED_COUNTRIES_FAILURE:
      return {...state, loadingCountries: Loading.ERROR};

    case CountryConstants.FETCH_CASHPICKUP_LOCATIONS_REQUEST:
      return {...state, loadingCashPickupLocations: Loading.FETCHING};

    case CountryConstants.FETCH_CASHPICKUP_LOCATIONS_SUCCESS:
      const cashPickupLocations = action.payload['cashPickupLocations'] === null ? [] : formatPayCentreDropdown(action.payload['cashPickupLocations']);
      return {...state, loadingCashPickupLocations: Loading.SUCCESS, cashPickupLocations};

    case CountryConstants.FETCH_CASHPICKUP_LOCATIONS_FAILURE:
      return {...state, loadingCashPickupLocations: Loading.ERROR};

    default:
      return state;
  }
};

export default countryReducer;
