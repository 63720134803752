import {NavLink} from "react-router-dom";
import HoverTooltip from "../../../../../components/tootltips/HoverTooltip";

const Messages = ({messages, view, mode, setMode, loading}) => {

  const viewMessage = item => view(item);
  const refreshMails = event => {
    event.preventDefault();
    setMode(event, mode);
  }

  return (
    <>
      <div className="col-md-12 col-lg-8 col-xl-9">
        <div className="card">
          <div className="card-body p-6">
            <div className="inbox-body">
              <div className="mail-option">
                <div className="btn-group">
                  <HoverTooltip title="Refresh">
                    <NavLink onClick={event => refreshMails(event)} exact to="" className="btn mini tooltips">
                      <i className={`fa fa-sync ${loading ? "fa-spin" : ""}`}/>
                    </NavLink>
                  </HoverTooltip>

                </div>
                <ul className="unstyled inbox-pagination">
                  <li><span>1 - {messages.length} of {messages.length}</span></li>
                  <li>
                    <a className="np-btn" href="/"><i className="fa fa-angle-right pagination-right"/></a>
                  </li>
                </ul>
              </div>

              <div className="table-responsive">
                <table className="table table-inbox table-hover text-nowrap mb-0">
                  <tbody>
                  {messages.map((item, index) => (
                    <tr key={index} className={item["status"] ? "" : "unread"} onClick={() => viewMessage(item)}>
                      <td className="inbox-small-cells">
                        <label className="custom-control custom-checkbox mb-0">
                          <input type="checkbox" className="custom-control-input" name="example-checkbox2"
                                 value="option2"/>
                          <span className="custom-control-label"/>
                        </label>
                      </td>
                      <td className="view-message dont-show fw-semibold">{item["senderName"]}</td>
                      <td className="view-message fw-semibold">{item.subject}</td>
                      <td className="view-message ">{`${item.body.replace(/<[^>]*>?/gm, ' ').slice(0, 40)}...`}</td>
                      <td className="view-message text-end fw-semibold">
                        {new Date(item["sentDate"]).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/*<ul className="pagination mb-4">
          <li className="page-item page-prev disabled">
            <button className="page-link" tabIndex="-1">Prev</button>
          </li>
          <li className="page-item active">
            <button className="page-link">1</button>
          </li>
          <li className="page-item">
            <button className="page-link">2</button>
          </li>
          <li className="page-item page-next">
            <button className="page-link">
              {languageStatus ? dictionary["button_next___"] : "Next"}
            </button>
          </li>
        </ul>*/}

      </div>
    </>
  );
}

export default Messages;



