import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { batch, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import * as Yup from "yup";

import Body from "./Body";
import Header from "../../../../../../components/header/Header";
import HeaderMenu from "../../../../../../components/header/HeaderMenu";
import Footer from "../../../../../../components/footer/Footer";
import SphereLoader from "../../../../../../components/loaders/SphereLoader";

import {
  fieldsValidator,
  formatBeneficiaryBanksDropdown,
} from "../../../../../../utils/helpers/logicHelper";
import { getCountries } from "../../../../../../store/actions/country.action";
import { updateBeneficiaryDetails } from "../../../../../../store/actions/users.action";
import { updateBeneficiaryValidator } from "../../../../../../utils/helpers/validationHelpers";
import {
  customerTypeDropdown,
  relationshipOptions,
} from "../../../../../../utils/helpers/objectHelpers";
import {
  fetchBeneficiaryBankDetails,
  fetchBeneficiaryDetails,
} from "../../../../../../utils/services/users.services";
import { getDynamicFields } from "../../../../../../store/actions/component.action";
import { fetchComplianceRequiredFields } from "../../../../../../store/actions/compliance.action";
import {
  dynamicFieldsTypes,
  Loading,
  mandatoryFieldsTypes,
} from "../../../../../../utils/helpers/constants";

const UpdateBeneficiary = () => {
  const dispatch = useDispatch(),
    location = useLocation(),
    history = useHistory();
  const customerTypesDropdown = customerTypeDropdown("", "");
  const {
    domainBrand,
    component,
    compliance,
    countries: { supportedCountries, loadingCountries, currencies },
  } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      complianceReducer,
      countryReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      compliance: complianceReducer,
      countries: countryReducer,
    })
  );

  const initialData = {
    type: customerTypesDropdown[0],
    firstName: location.state.data.firstName ?? "",
    middleName: "",
    lastName: location.state.data.lastName ?? "",
    companyName: "",
    regNumber: "",
    email: "",
    phoneCountryCode: "",
    phone: "",
    address: "",
    address1: "",
    address2: "",
    city: "",
    postcode: "",
    state: "",
    country: "",
    relationShip: "",
    bankDetails: "",
    bankFields: "",
  };

  const [beneficiary, updateBeneficiary] = useState(initialData);
  const [initialValues, updateInitialValues] = useState(initialData);
  const [updated, setUpdated] = useState("");
  const [loading, setLoading] = useState(false);
  const [individualFields, setIndividualFields] = useState({});
  const [companyFields, setCompanyFields] = useState({});
  const [mandatoryFields, mandatoryValidators] = useState([]);
  const [beneficiaryBankDropdown, updateBeneficiaryBankDropdown] = useState([]);
  const initialValidation = updateBeneficiaryValidator(mandatoryFields);
  const loadState =
    loading ||
    domainBrand.loading === Loading.FETCHING ||
    loadingCountries === Loading.FETCHING ||
    compliance.fetchingRequiredFields === Loading.FETCHING;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(initialValidation),
    onSubmit: async (values) => {
      await formik.setFieldValue("submitting", true);
      const id = location.state.data.id;
      const payload = {
        id,
        type: values.type.value,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        companyName: values.companyName,
        regNumber: values.regNumber,
        email: values.email,
        phone: values.phone,
        relationShip: values.relationShip.value,
        address: {
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          postcode: values.postcode,
          state: values.state,
          countryCommonName: values.country["common_name"],
          countryIso3: values.country.value,
        },
      };
      await dispatch(updateBeneficiaryDetails(payload));
      await formik.setFieldValue("submitting", false);
    },
  });

  useEffect(() => {
    batch(() => {
      dispatch(getDynamicFields(dynamicFieldsTypes.COMPANY_CREATE_BENEFICIARY));
      dispatch(
        getDynamicFields(dynamicFieldsTypes.CUSTOMER_CREATE_BENEFICIARY)
      );
      dispatch(fetchComplianceRequiredFields(mandatoryFieldsTypes.RECEIVER));
      dispatch(
        fetchComplianceRequiredFields(mandatoryFieldsTypes.RECEIVER_COMPANY)
      );
    });
  }, []);

  useEffect(async () => {
    if (typeof location.state === "undefined") history.push("/beneficiaries");
    if (loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
    else {
      setLoading(true);
      const { data: response } = await fetchBeneficiaryDetails(
        location.state.data["id"]
      );
      const { data: responseData } = await fetchBeneficiaryBankDetails(
        location.state.data["beneficiaryCode"]
      );
      if (
        response.status !== Loading.SUCCESS ||
        responseData.status !== Loading.SUCCESS
      )
        toast.error(`Could not fetch beneficiary bank information, try again`);
      else {
        const formattedBeneficiaries = formatBeneficiaryBanksDropdown(
          responseData.data["bankDetails"]
        );
        updateBeneficiaryBankDropdown(formattedBeneficiaries);
        updateBeneficiary(response.data);
      }
      await formik.setFieldValue("bankDetails", null);
      setLoading(false);
    }
  }, [location.state, updated]);

  useEffect(() => {
    const type = customerTypesDropdown.find(
      (item) => item.value === formik.values.type || formik.values.type.value
    );
    const country = supportedCountries.find(
      (item) => item.value === beneficiary.address.countryIso3 || ""
    );
    const relationShip = relationshipOptions(type.value).find(
      (item) => item.value === beneficiary.relationShip || ""
    );
    const updatingBeneficiaryDetails = {
      ...beneficiary,
      country,
      type,
      relationShip,
      id: location.state.data.id ?? "",
      companyName: beneficiary.companyName || "",
      regNumber: beneficiary.regNumber || "",
      firstName: beneficiary.firstName || "",
      middleName: beneficiary.middleName || "",
      lastName: beneficiary.lastName || "",
      address1: beneficiary.address.address1 ?? "",
      address2: beneficiary.address.address2 ?? "",
      city: beneficiary.address.city ?? "",
      postcode: beneficiary.address.postcode ?? "",
      state: beneficiary.address.state ?? "",
    };
    updateInitialValues(updatingBeneficiaryDetails);
  }, [beneficiary, supportedCountries]);

  useEffect(() => {
    const receiverFields = compliance[mandatoryFieldsTypes.RECEIVER] ?? [],
      receiverCompanyFields =
        compliance[mandatoryFieldsTypes.RECEIVER_COMPANY] ?? [];
    if (receiverFields.length > 0 && receiverCompanyFields.length > 0) {
      setIndividualFields(
        fieldsValidator(compliance[mandatoryFieldsTypes.RECEIVER])
      );
      setCompanyFields(
        fieldsValidator(compliance[mandatoryFieldsTypes.RECEIVER_COMPANY])
      );
    }
  }, [compliance]);

  const properties = {
    individualFields,
    companyFields,
    beneficiary,
    currencies,
    domainBrand,
  };

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="beneficiaries"
              active="beneficiary"
              domainBrand={domainBrand}
            />
            <Body
              countries={supportedCountries}
              beneficiaryBanks={beneficiaryBankDropdown}
              formik={formik}
              properties={properties}
              updated={setUpdated}
              setMandatoryValidator={mandatoryValidators}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default UpdateBeneficiary;
