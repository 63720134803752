import React from "react";
import { getBaseUrl } from "../../../../../utils/services/baseUrl";

const WalletPayment = ({
  languageStatus,
  dictionary,
  selectedWallet,
  profileData,
  ...rest
}) => {
  const firstInput = React.useRef();
  const secondInput = React.useRef();
  const thirdInput = React.useRef();
  const fourthInput = React.useRef();

  const [pin, setPin] = React.useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });
  const [password, updatePassword] = React.useState("");

  const handleLastPin = async (e) => {
    const value = e.target.value;
    if (value !== "") {
      const payload = { ...pin, fourth: value };
      await rest.getPin(payload);
    }
  };

  const handlePassword = async (e) => {
    const value = e.target.value;
    if (value !== "") {
      await rest.getPassword(value);
    }
  };

  const domainUrl = getBaseUrl();

  return (
    <>
      {Object.entries(selectedWallet).length > 0 ? (
        <div>
          <div className="row">
            <div className="col-6">
              <p className="text-muted">
                {languageStatus
                  ? dictionary["WO_ACCOUNT_NAM_-935856925"]
                  : "Account Holder Name"}
              </p>
              <p className="border-bottom">
                {selectedWallet["accountName"]?.toUpperCase() ??
                  "No Account Name"}
              </p>
            </div>
            <div className="col-6">
              <p className="text-muted">
                {languageStatus ? dictionary["l_acct_no"] : "Account Number"}
              </p>
              <p className="border-bottom">
                {selectedWallet["walletAccountNumber"]}
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <p className="text-muted">
                {languageStatus ? dictionary["l_current_balance"] : "Balance"}
              </p>
              <p className="border-bottom">
                {`${selectedWallet["walletAccountCurrency"]} ${
                  selectedWallet["walletAccountBalance"]
                    ? selectedWallet["walletAccountBalance"].toFixed(2)
                    : ""
                }`}
              </p>
            </div>
            <div className="col-6">
              <p className="text-muted">
                {languageStatus ? dictionary["l_currency"] : "Currency"}
              </p>
              <p className="border-bottom">
                {selectedWallet["walletAccountCurrency"]}
              </p>
            </div>
          </div>

          {/* PIN ENABLED ROW */}
          {profileData["pinEnabled"] &&
          domainUrl === "https://test.moneytransferapplication.uk" ? (
            <>
              <div className="row mb-3">
                <div className="col-6">
                  <p className="text-muted">
                    {languageStatus
                      ? dictionary["WO_ENTER_YOUR_PI_483786945"]
                      : "Enter Your Pin"}
                  </p>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <input
                      className="form-controll otp-pin-input-bar"
                      value={pin.first}
                      onChange={(e) => {
                        setPin({ ...pin, first: e.target.value });
                        e.target.value && secondInput.current?.focus();
                      }}
                      maxLength={1}
                      ref={firstInput}
                    />

                    <input
                      className="form-controll otp-pin-input-bar"
                      value={pin.second}
                      onChange={(e) => {
                        setPin({ ...pin, second: e.target.value });
                        e.target.value
                          ? thirdInput.current?.focus()
                          : firstInput.current?.focus();
                      }}
                      maxLength={1}
                      ref={secondInput}
                    />

                    <input
                      className="form-controll otp-pin-input-bar"
                      value={pin.third}
                      onChange={(e) => {
                        setPin({ ...pin, third: e.target.value });
                        e.target.value
                          ? fourthInput.current?.focus()
                          : secondInput.current?.focus();
                      }}
                      maxLength={1}
                      ref={thirdInput}
                    />

                    <input
                      className="form-controll otp-pin-input-bar"
                      value={pin.fourth}
                      onChange={async (e) => {
                        setPin({ ...pin, fourth: e.target.value });
                        !e.target.value && thirdInput.current?.focus();
                        await handleLastPin(e);
                      }}
                      maxLength={1}
                      ref={fourthInput}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {/* OTP && STRONG CUSTOMER AUTHENTICATION ENABLED ROW  */}
          {profileData["strongCustomerAuthenticationEnabled"] &&
          domainUrl === "https://test.moneytransferapplication.uk" ? (
            <>
              <div className="row mb-5">
                <div className="col-8 col-md-8">
                  <p className="text-muted">
                    {languageStatus ? dictionary["param_password"] : "Password"}
                  </p>
                  <input
                    className="input-100"
                    type="password"
                    name="password"
                    value={password}
                    id="password"
                    onChange={(e) => {
                      updatePassword(e.target.value);
                      handlePassword(e);
                    }}
                    placeholder={
                      languageStatus ? dictionary["l_password"] : "Password"
                    }
                    required
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default WalletPayment;
