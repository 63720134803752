import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import TransactionSummary from "../../home/atom/customer/TransactionSummary";
// import SphereLoader from "../../../../components/loaders/SphereLoader";
import DynamicDataTable from "../../../../components/dataTable/DynamicDataTable";
import {
  customerOptionsDropdown,
  pageSizeOptions,
} from "../../../../utils/helpers/objectHelpers";
import { customersTableConfig } from "../../../../utils/helpers/dataTableConfigs";
import { ReactComponent as Activate } from "../../../../assets/images/svgs/check-circle.svg";
import { ReactComponent as Deactivate } from "../../../../assets/images/svgs/circle-off.svg";
import { getAllCustomers } from "../../../../store/actions/users.action";

const Body = ({ domainBrand, customers = [] }) => {
  const dispatch = useDispatch();
  const menuPortalTarget = document.getElementById("root");
  const [transactionInfo, updateTransactionInfo] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [customerOption, setCustomerOption] = useState("");
  const [pageSize, setPageSize] = useState(pageSizeOptions[1]);
  // const [loadingCustomers, updateLoadingCustomers] = useState(false);
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const customerOptions = customerOptionsDropdown(languageStatus, dictionary);

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 4,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-center pointer">
          <Activate onClick={() => console.log()} />
          <Deactivate />
        </div>
      );
    },
  };

  const tableObject = [
    ...customersTableConfig(languageStatus, dictionary),
    actionColumn,
  ];
  const columns = useMemo(() => tableObject, []);

  useEffect(() => {
    updateTransactionInfo([]);
  }, []);

  const fetchData = () => {
    // console.log(pageSize);
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus
                ? dictionary["para_manage_customers"]
                : "Customers"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  <span className="">
                    {languageStatus
                      ? dictionary["l_customer_information"]
                      : "Customer Information"}
                  </span>
                </h3>
              </div>
              <div className="card-body pb-0 pt-4">
                <TransactionSummary graphData={transactionInfo} />
              </div>
            </div>
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  <span className="">
                    {languageStatus
                      ? dictionary["m_manage_customer"]
                      : "Customers"}
                  </span>
                </h3>
              </div>
              <div className="card-body pb-0 pt-4">
                <div className="needs-validation">
                  <div className="row form-row">
                    <div className="col-md-3 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["l_customer_type"]
                          : "Customer Type"}
                      </label>
                      <Select
                        options={customerOptions}
                        isClearable={false}
                        isSearchable={false}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="selectedCurrency"
                        value={customerOption}
                        onChange={async (option) => {
                          setCustomerOption(option);
                          await dispatch(getAllCustomers(option.value));
                        }}
                      />
                    </div>
                    <div className="col-md-3 mb-3" />
                    <div className="col-md-2 mb-3">
                      <label>Page Size</label>
                      <Select
                        options={pageSizeOptions}
                        isClearable={false}
                        isSearchable={false}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="pageSize"
                        value={pageSize}
                        onChange={(option) => setPageSize(option)}
                      />
                    </div>
                    <div className="col-4 mr-auto mb-3">
                      <label>
                        {languageStatus ? dictionary["l_filter"] : "Filter"}
                      </label>
                      <input
                        type="search"
                        value={filterInput}
                        onChange={(event) => setFilterInput(event.target.value)}
                        className="form-control form-control-sm"
                        placeholder="Search or filter by name or customerId..."
                      />
                    </div>
                  </div>
                </div>
                {/*<div className="table-responsive mt-3">
                  {loadingCustomers ? <SphereLoader/> :
                    <DynamicDataTable columns={columns} data={customers}
                                      fetchData={fetchData}
                                      filterData={filterInput}
                                      updatedPageSize={parseInt(pageSize.value, 10)}/>
                  }
                </div>*/}

                <div className="table-responsive mt-3">
                  <DynamicDataTable
                    columns={columns}
                    data={customers}
                    fetchData={fetchData}
                    filterData={filterInput}
                    updatedPageSize={parseInt(pageSize.value, 10)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
