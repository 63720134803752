import Axios from "../services/axios.service";

export const fetchManagementCustomers = async (size = 10) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/adminmanagement/list-customers?page=1&size=${10}`,
  });
};

export const createAmlRules = async (payload) => {
  return Axios({
    method: "POST",
    url: `/web-services/api/v6/services/admin/compliance/create-aml-rules`,
    data: payload,
  });
};

export const editAmlRule = async (payload) => {
  return Axios({
    method: "PUT",
    url: `/web-services/api/v6/services/admin/compliance/update-aml-rules`,
  });
};
