import Axios from "../services/axios.service";

export const fetchInterestRates = async () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/settings/list-interest-rate`,
  });
};

export const addNewInterestRate = async (payload) => {
  return Axios({
    method: "POST",
    url: `/web-services/api/v6/services/admin/settings/add-interest-rate`,
    data: payload,
  });
};

export const deleteInterestRate = async (rateId) => {
  return Axios({
    method: "DELETE",
    url: `/web-services/api/v6/services/admin/settings/delete-interest-rate/${rateId}`,
  });
};

export const editInterestRate = async (payload) => {
  return Axios({
    method: "PUT",
    url: `/web-services/api/v6/services/admin/settings/edit-interest-rate`,
    data: payload,
  });
};

export const getSingleInterestRate = async (interest_id) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/interest-rate/${interest_id}`,
  });
};
