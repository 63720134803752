import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import fallbackImage from "../../assets/images/2.jpg";
import { fileToBase64 } from "../../utils/helpers/logicHelper";
import { updateProfilePicture } from "../../store/actions/profile.action";
import { useDispatch } from "react-redux";

const UploadProfilePhotoModal = ({
  onchange,
  setPhotoUrl,
  profile,
  photoUrl,
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [submitPhoto, setSubmitPhoto] = useState(null);

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const onChangePhoto = async (event) => {
    const selectedFileSize = event.target.files[0].size || null;
    if (selectedFileSize === null || typeof selectedFileSize === "undefined") {
    } else if (
      event.target.files[0] &&
      !event.target.files[0].type.includes("image")
    ) {
      toast.error("File is not an image");
    } else if (selectedFileSize > 512758) {
      toast.error("File size too large");
    } else {
      let src = URL.createObjectURL(event.target.files[0]);
      setSubmitPhoto(event.target.files[0]);
      setPhotoUrl(src);
    }
  };

  const uploadPhoto = async () => {
    setUploading(true);
    const fileDataBase64 = await fileToBase64(submitPhoto);
    const [, imageBase64] = fileDataBase64.split(",");
    const senderCode = profile.userData["customerCode"];
    const payload = {
      imageBase64,
      senderCode,
      imageFileName: senderCode + "_profile_picture",
    };
    await dispatch(updateProfilePicture(payload));
    setSubmitPhoto(null);
    setUploading(false);
  };

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="modal-body text-center p-4">
            <button
              aria-label="Close"
              onClick={handleClose}
              className="btn-close"
              data-bs-dismiss="modal"
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="d-flex avatar-flex mb-3">
              <img
                alt="Profile"
                className="rounded-circle avatar-lg"
                src={photoUrl}
                onError={(e) => (e.target.src = fallbackImage)}
              />
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 mb-3">
                <div className="input-group">
                  <input
                    onChange={onChangePhoto}
                    type="file"
                    className="form-control"
                    placeholder="Choose.."
                    readOnly
                  />
                </div>
                <button
                  onClick={uploadPhoto}
                  className="btn btn-primary mt-5 w-80"
                  type="button"
                >
                  Upload{" "}
                  {uploading ? (
                    <>
                      &nbsp; <i className="fa fa-spin fa-spinner" />
                    </>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadProfilePhotoModal;
