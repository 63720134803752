import { NavLink } from "react-router-dom";
import DashboardMenu from "../../../../../components/slickSlider/DashboardMenu";
import { useState } from "react";

const ManagerDashboard = ({ profile, domainBrand }) => {
  const [canShowMore, setCanShowMore] = useState(false);
  const [showMoreState, setShowMoreState] = useState(false);
  const viewMore = (event) => {
    event.preventDefault();
    setShowMoreState(!showMoreState);
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 height-100">
        <div className="row">
          <div className="col-xl-12 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  <span className="">Quick Actions</span>
                </h3>
                <div className="card-options">
                  {canShowMore ? (
                    <NavLink
                      onClick={viewMore}
                      to=""
                      className="card-options-collapse"
                    >
                      {showMoreState ? "View Less" : "View More"}
                    </NavLink>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="card-body pb-0 pt-4">
                <DashboardMenu
                  domainBrand={domainBrand}
                  transTypes={domainBrand.transTypes}
                  profile={profile}
                  showMoreState={showMoreState}
                  canShowMore={setCanShowMore}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerDashboard;
