import ActionCreator from "../../utils/helpers/actionCreator";
import * as DomainBrand from "../constants/domain-brand.constants";
import * as DomainBrandService from "../../utils/services/domainBrand.service";

export const getDomainBrandIdentity =
  (subdomain, languageOption) => async (dispatch) => {
    try {
      dispatch(ActionCreator(DomainBrand.GET_DOMAIN_BRAND_IDENTITY_REQUEST));
      const { data: responseData } =
        await DomainBrandService.fetchDomainBrandIdentity(
          subdomain,
          languageOption
        );
      if (responseData.status === "FAILED") throw responseData;
      const domainData = responseData["data"];

      dispatch(
        ActionCreator(DomainBrand.GET_DOMAIN_BRAND_IDENTITY_SUCCESS, domainData)
      );
    } catch (error) {
      dispatch(ActionCreator(DomainBrand.GET_DOMAIN_BRAND_IDENTITY_FAILURE));
    }
  };

export const getTransTypes = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(DomainBrand.GET_TRANSACTION_TYPES_REQUEST));
    const { data: responseData } = await DomainBrandService.transactionTypes();
    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        DomainBrand.GET_TRANSACTION_TYPES_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    dispatch(ActionCreator(DomainBrand.GET_TRANSACTION_TYPES_FAILURE));
  }
};
