import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./beneficiary.css";

// import avatar from "../../../../assets/images/gravatar.png";
import { ReactComponent as Archive } from "../../../../assets/images/svgs/archive.svg";
import { ReactComponent as RemoveArchive } from "../../../../assets/images/svgs/unarchive.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Create from "./atom/create/Create";
import {
  fetchArchivedBeneficiary,
  // getAllBeneficiaries,
} from "../../../../store/actions/users.action";
import {
  archiveBeneficiary,
  unArchiveBeneficiary,
} from "../../../../utils/services/users.services";
import ArchiveModal from "../../../../components/modals/ArchiveModal";
import UnArchiveModal from "../../../../components/modals/UnarchiveModal";
import { beneficiaryTriggers } from "../../../../utils/helpers/objectHelpers";
import toast from "react-hot-toast";

const Body = ({
  countries,
  phoneCodes,
  currencies,
  component,
  mandatoryFields,
  beneficiaries,
  domainBrand,
  nation,
  senderCode,
}) => {
  const history = useHistory();
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [searchInput, updateSearchInput] = useState("");
  const [filteredList, updateFilteredList] = useState(beneficiaries);
  const [loadMore, updateLoadMore] = useState(false);
  const [beneficiaryCode, setBeneficiaryCode] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [archive, setArchive] = useState(false);
  const [unArchive, setUnArchive] = useState(false);
  // const [setArchiveStatus] = useState(false);
  const [val, setVal] = useState("");
  const size = loadMore ? filteredList.length : 8;

  const dispatch = useDispatch();

  const filterSearch = (value) => {
    return beneficiaries.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  useEffect(() => {
    updateFilteredList(filterSearch(searchInput));
  }, [searchInput]);

  const editBeneficiary = (beneficiary) =>
    history.push({
      pathname: `/beneficiaries/update/${beneficiary.firstName}`,
      state: { data: beneficiary },
    });

  const transferFundsToBeneficiary = (event, beneficiary) => {
    event.stopPropagation();
    history.push({
      pathname: `/transfer`,
      state: { data: {}, beneficiary: beneficiary },
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSelect = async (e) => {
    const { value } = e.target;
    setVal(value);
    if (value !== "") {
      await dispatch(fetchArchivedBeneficiary(value));
    }
  };

  return (
    <>
      {archive && (
        <ArchiveModal
          show={show}
          handleClose={handleClose}
          firstName={firstName}
          lastName={lastName}
          beneficiaryCode={beneficiaryCode}
          handleArchive={async () => {
            const data = await archiveBeneficiary(customerId);
            if (data.status === 200) {
              toast.success("success");
              setShow(false);
              dispatch(fetchArchivedBeneficiary("UN_ARCHIVED"));
            }
          }}
        />
      )}

      {unArchive && (
        <UnArchiveModal
          show={show}
          handleClose={handleClose}
          firstName={firstName}
          lastName={lastName}
          beneficiaryCode={beneficiaryCode}
          handleUnArchive={async () => {
            const data = await unArchiveBeneficiary(customerId);

            if (data.status === 200) {
              toast.success("success");
              setShow(false);
              await dispatch(fetchArchivedBeneficiary("UN_ARCHIVED"));
            }
          }}
        />
      )}

      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">
                {languageStatus
                  ? dictionary["WO_BENEFICIARIE_-1845397122"]
                  : "Beneficiaries"}
              </h1>
            </div>
            <div className="ms-auto pageheader-btn" />
          </div>
          <div className="row row-sm">
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">
                    {languageStatus
                      ? dictionary["WO_BENEFICIARIE_-1845397122"]
                      : "Beneficiaries"}
                  </h3>
                </div>
                <div className="main-content-app pt-0">
                  <div className="main-content-left main-content-left-chat">
                    <div className="card-body">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="input-group pe-3">
                          <input
                            type="text"
                            className="form-control"
                            value={searchInput}
                            onChange={(event) =>
                              updateSearchInput(event.target.value)
                            }
                            placeholder="Search for beneficiary.."
                          />
                          <button className="input-group-text btn btn-primary">
                            Search
                          </button>
                        </div>

                        <div className="ps-3">
                          <select
                            className=" py-2 px-3 select"
                            value={val}
                            onChange={(e) => {
                              setVal(e.target.value);
                              handleSelect(e);
                            }}
                          >
                            <option value="" selected hidden>
                              Select...
                            </option>
                            {beneficiaryTriggers.map((item) => (
                              <option key={item.id} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* beneficiary list */}
                    <div className="tab-content main-chat-list">
                      <div className="tab-pane active">
                        {filteredList.slice(0, size).map((forEach, index) => {
                          return (
                            <div
                              key={index}
                              // onClick={() => editBeneficiary(forEach)}
                              className={`d-flex align-items-center media`}
                            >
                              {/* <div className="mb-0 me-2">
                                <div className="main-img-user online">
                                  <img alt="" src={avatar} />
                                </div>
                              </div> */}
                              <div
                                className="align-items-center justify-content-between"
                                onClick={() => editBeneficiary(forEach)}
                              >
                                <div className="media-body ms-2">
                                  <div className="media-contact-name">
                                    <span>{forEach["label"]}</span>
                                    <span />
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <p className="text-muted tx-13">
                                      {forEach["beneficiaryCode"]} -{" "}
                                      {forEach["phoneNumber"]}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="ms-auto d-flex flex-row align-items-center ">
                                {!forEach.isBeneficiaryArchived ? (
                                  <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id={`tooltip-${``}`}>
                                        Archive
                                      </Tooltip>
                                    }
                                  >
                                    <Archive
                                      className="me-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setBeneficiaryCode(
                                          forEach.beneficiaryCode
                                        );
                                        setShow(true);
                                        setFirstName(forEach.firstName);
                                        setLastName(forEach.lastName);
                                        setCustomerId(forEach.id);
                                        setArchive(true);
                                      }}
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id={`tooltip-${``}`}>
                                        Unarchive
                                      </Tooltip>
                                    }
                                  >
                                    <RemoveArchive
                                      className="me-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setUnArchive(true);
                                        setBeneficiaryCode(
                                          forEach.beneficiaryCode
                                        );
                                        setShow(true);
                                        setFirstName(forEach.firstName);
                                        setLastName(forEach.lastName);
                                        setCustomerId(forEach.id);
                                      }}
                                    />
                                  </OverlayTrigger>
                                )}

                                <button
                                  type="button"
                                  onClick={(event) =>
                                    transferFundsToBeneficiary(event, forEach)
                                  }
                                  className="btn btn-primary btn-sm  z-index2"
                                >
                                  {languageStatus
                                    ? dictionary["para_send_money"]
                                    : "Send Money"}
                                </button>
                              </div>
                            </div>
                          );
                        })}
                        {filteredList.length < 8 ? (
                          ""
                        ) : (
                          <div
                            onClick={() => updateLoadMore(!loadMore)}
                            className="load-more"
                          >
                            <span className="">
                              {loadMore ? "Show less" : "Load More"}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">
                    {languageStatus
                      ? dictionary["WO_ADD_A_NEW_BENEFICIAR_1555543493"]
                      : "Add a new beneficiary"}
                  </h3>
                </div>
                <div className="card-body">
                  <Create
                    countries={countries}
                    phoneCodes={phoneCodes}
                    mandatoryFieldsObject={mandatoryFields}
                    currencies={currencies}
                    domainBrand={domainBrand}
                    component={component}
                    nation={nation}
                    senderCode={senderCode}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
