import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-hot-toast";

import { addBankFieldConfig } from "../../../../../utils/services/bankfield.service";
import OpacityLoader from "../../../../../components/loaders/OpacityLoader";
import { getBankFields } from "../../../../../store/actions/bankfield.action";

function AddBankConfig({
  domainBrand,
  currencies,
  countries,
  userId,
  addNewStatus = () => {},
}) {
  const menuPortalTarget = document.getElementById("root");
  const [loading, setLoading] = useState(false);

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const dispatch = useDispatch();

  const formRef = useRef();

  const schema = yup
    .object({
      templateName: yup.string().required(),
      accountType: yup.boolean().required(),
      receipientType: yup.boolean().required(),
      bankCode: yup.boolean().required(),
      bankCodeLength: yup.number().positive().integer().required(),
      bankName: yup.boolean().required(),
      transitName: yup.boolean().required(),
      transitNumberLength: yup.number().positive().integer().required(),
      accNumber: yup.boolean().required(),
      accNumberMaxLength: yup.number().positive().integer().required(),
      sortCode: yup.boolean().required(),
      sortCodeLength: yup.number().positive().integer().required(),
      iBan: yup.boolean().required(),
      swiftCode: yup.boolean().required(),
      branchCode: yup.boolean().required(),
      branchName: yup.boolean().required(),
      branchDistrict: yup.boolean().required(),
      routingNumber: yup.boolean().required(),
      ifsc: yup.boolean().required(),
      cpf: yup.boolean().required(),
      cnpj: yup.boolean().required(),
      op: yup.boolean().required(),
      rutNumber: yup.boolean().required(),
      countryNumber: yup.boolean().required(),
      cityReq: yup.boolean().required(),
      addressLine: yup.boolean().required(),
      postCode: yup.boolean().required(),
      stateReq: yup.boolean().required(),
      docs: yup.boolean().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const payload = {
      bankFieldsConfigurationsRequestDTOs: [
        {
          accountTypeRequired: data?.accountType,
          recipientTypeRequired: data?.receipientType,
          bankCodeRequired: data?.bankCode,
          bankCodeLength: data?.bankCodeLength,
          bankNameRequired: data?.bankName,
          transitNumberRequired: data?.transitName,
          transitNumberLength: data?.transitNumberLength,
          accountNumberRequired: data?.accNumber,
          accountNumberLength: data?.accNumberMaxLength,
          sortCodeRequired: data?.sortCode,
          sortCodeLength: data?.sortCodeLength,
          ibanRequired: data?.iBan,
          swiftCodeRequired: data?.swiftCode,
          branchCodeRequired: data?.branchCode,
          branchNameRequired: data?.branchName,
          branchDistrictRequired: data?.branchDistrict,
          routingNumberRequired: data?.routingNumber,
          ifscRequired: data?.ifsc,
          cpfRequired: data?.cpf,
          cnpjRequired: data?.cnpj,
          opRequired: data?.op,
          rutNumberRequired: data?.rutNumber,
          countryRequired: data?.countryNumber,
          cityRequired: data?.cityReq,
          addressLineRequired: data?.addressLine,
          postCodeRequired: data?.postCode,
          documentNumberRequired: data?.docs,
          phoneNumberRequired: data?.phone,
          country: data?.country?.iso3Code,
          updatedDate: Date.now(),
          stateRequired: data?.stateReq,
          templateName: data?.templateName,
          updatedUser: userId,
          currencyCode: data?.currencyCode?.currencyCode,
        },
      ],
    };

    if (payload) {
      setLoading(true);
      const res = await addBankFieldConfig(payload);
      if (res.status === 200) {
        toast.success("bank configuration successfully added");
        setLoading(false);
        reset();
        addNewStatus();
        dispatch(getBankFields());
      }
    }
  };

  return (
    <form
      className="needs-validation"
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <div className="form-row">
        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
          <label>
            {languageStatus
              ? dictionary["WO_TEMPLATE_NAM_2095784414"]
              : "Template Name"}
            <span style={{ color: "red" }}> *</span>
          </label>

          <input
            type={"text"}
            className="form-control"
            id="templateName"
            name="templateName"
            {...register("templateName", {
              required: {
                value: "",
                message: "Template Name is required",
              },
            })}
          />
          <p
            className="text-danger fst-italic pt-1"
            style={{ fontSize: "12px" }}
          >
            {errors.templateName?.type === "required" &&
              "Template Name is required"}
          </p>
        </div>
        <div className="col-md-6 col-lg-6 mb-3">
          <label>
            {languageStatus
              ? dictionary["WO_SUPPORTED_COUNTR_1270901801"]
              : "Supported Country"}
            <span style={{ color: "red" }}> *</span>
          </label>
          <Controller
            name="country"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  options={countries}
                  isClearable={false}
                  isSearchable={true}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                />
              );
            }}
          />
        </div>

        <div className="col-md-6 col-lg-6 mb-3">
          <label>
            {languageStatus ? dictionary["WO_CURRENCY_1471979772"] : "Currency"}
            <span style={{ color: "red" }}> *</span>
          </label>
          <Controller
            name="currencyCode"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  options={currencies}
                  isClearable={false}
                  isSearchable={true}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                />
              );
            }}
          />
        </div>

        {/* TRIGGERS */}
        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column ">
          <label>
            {languageStatus
              ? dictionary["WO_ACCOUNT_TYPE_REQUIRE_-1470375591"]
              : "Account Type Required"}
            <span style={{ color: "red" }}> *</span>
          </label>
          <div className="border py-2 rounded-3" style={{ height: "40px" }}>
            <input
              type={"checkbox"}
              className=""
              id="accountType"
              name="accountType"
              {...register("accountType", {
                required: {
                  value: true,
                  message: "Account Type is required",
                },
              })}
            />
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column ">
          <label>
            {languageStatus
              ? dictionary["WO_RECEIPIENT_TYPE_REQU_-906248804"]
              : " Receipient Type Required"}
            <span style={{ color: "red" }}> *</span>
          </label>
          <div className="border py-2 rounded-3" style={{ height: "40px" }}>
            <input
              type={"checkbox"}
              className=""
              id="receipientType"
              name="receipientType"
              {...register("receipientType", {
                required: {
                  value: true,
                  message: "Receipient Type is required",
                },
              })}
            />
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column ">
          <div className="row row-sm">
            <div className="col-md-4">
              <label>
                {languageStatus
                  ? dictionary["WO_BANK_CODE_REQUIRE_-1798434446"]
                  : " Bank Code Required"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="bankCode"
                  name="bankCode"
                  {...register("bankCode", {
                    required: {
                      value: true,
                      message: "Bank Code is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                {languageStatus
                  ? dictionary["WO_BANK_CODE_LENGT_1703153598"]
                  : " Bank code length"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="">
                <input
                  type={"number"}
                  className="form-control border px-3"
                  id="bankCodeLength"
                  name="bankCodeLength"
                  {...register("bankCodeLength", {
                    required: true,
                    message: "Bank Code Length is required",
                  })}
                />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                {languageStatus
                  ? dictionary["WO_BANK_NAME_REQUIRE_-628269855"]
                  : " Bank Name Required"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="bankName"
                  name="bankName"
                  {...register("bankName", {
                    required: {
                      value: true,
                      message: "Bank Name is required",
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
          <div className="row row-sm">
            <div className="col-md-5">
              <label>
                {languageStatus
                  ? dictionary["WO_TRANSIT_NUMBER_REQUI_1480957994"]
                  : " Transit Number Required"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="transitName"
                  name="transitName"
                  {...register("transitName", {
                    required: {
                      value: true,
                      message: "Transit Name is required",
                    },
                  })}
                />
              </div>
            </div>

            <div className="col-md-5">
              <label>
                {languageStatus
                  ? dictionary["WO_TRANSIT_NUMBER_LENGT_17230771"]
                  : "Transit Number length"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="">
                <input
                  type={"number"}
                  className="form-control border px-3"
                  name="transitNumberLength"
                  id="transitNumberLength"
                  {...register("transitNumberLength", {
                    required: true,
                    message: "Transit Number Length is required",
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
          <div className="row row-sm">
            <div className="col-md-5">
              <label>
                {languageStatus
                  ? dictionary["WO_ACCOUNT_NUMBER_REQUI_-1905418647"]
                  : "Account Number Required:"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="accNumber"
                  name="accNumber"
                  {...register("accNumber", {
                    required: {
                      value: true,
                      message: "Account Number is required",
                    },
                  })}
                />
              </div>
            </div>

            <div className="col-md-5">
              <label>
                {languageStatus
                  ? dictionary["WO_ACCOUNT_NUMBER_MAX_L_1317371118"]
                  : " Account number max length"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="">
                <input
                  type={"number"}
                  className="form-control border px-3"
                  name="accNumberMaxLength"
                  id="accNumberMaxLength"
                  {...register("accNumberMaxLength", {
                    required: true,
                    message: "Account Number Max Length is required",
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
          <div className="row row-sm">
            <div className="col-md-5">
              <label>
                {languageStatus
                  ? dictionary["WO_SORT_CODE_REQUIRED_127345416"]
                  : "Sort code required:"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="sortCode"
                  name="sortCode"
                  {...register("sortCode", {
                    required: {
                      value: true,
                      message: "Sort Code is required",
                    },
                  })}
                />
              </div>
            </div>

            <div className="col-md-5">
              <label>
                {languageStatus
                  ? dictionary["WO_SORT_CODE_LENGT_-1482252313"]
                  : "Sort code length"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="">
                <input
                  type={"number"}
                  className="form-control border px-3"
                  id="sortCodeLength"
                  name="sortCodeLength"
                  {...register("sortCodeLength", {
                    required: true,
                    message: "Sort Code Length is required",
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
          <div className="row row-sm">
            <div className="col-md-5">
              <label>
                {languageStatus ? dictionary["l_iban_code"] : "IBAN required:"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="iBan"
                  name="iBan"
                  {...register("iBan", {
                    required: {
                      value: true,
                      message: "IBAN is required",
                    },
                  })}
                />
              </div>
            </div>

            <div className="col-md-5">
              <label>
                {/* {languageStatus
                              ? dictionary[" WO_SWIFT_CODE_REQUIRED_496796530"]
                              : "Swift code required:"} */}
                Swift code required
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="swiftCode"
                  name="swiftCode"
                  {...register("swiftCode", {
                    required: {
                      value: true,
                      message: "swift code is required",
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column ">
          <div className="row row-sm">
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                Branch code required:
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="branchCode"
                  name="branchCode"
                  {...register("branchCode", {
                    required: {
                      value: true,
                      message: "Branch code is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                Branch name required:
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="branchName"
                  name="branchName"
                  {...register("branchName", {
                    required: {
                      value: true,
                      message: "Branch Name is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                Branch district required:
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="branchDistrict"
                  name="branchDistrict"
                  {...register("branchDistrict", {
                    required: {
                      value: true,
                      message: "Branch District is required",
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
          <div className="row row-sm">
            <div className="col-md-6">
              <label className="mb-3">
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                Routing number required:
                <span style={{ color: "red" }}> *</span>
              </label>
              <div
                className="border py-2 rounded-3 mt-4"
                style={{ height: "40px" }}
              >
                <input
                  type={"checkbox"}
                  className=""
                  id="routingNumber"
                  name="routingNumber"
                  {...register("routingNumber", {
                    required: {
                      value: true,
                      message: "Routing Number is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-6">
              <label className="mb-3">
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                IFSC required:
                <span style={{ color: "red" }}> *</span>
              </label>
              <div
                className="border py-2 rounded-3 mt-4"
                style={{ height: "40px" }}
              >
                <input
                  type={"checkbox"}
                  className=""
                  id="ifsc"
                  name="ifsc"
                  {...register("ifsc", {
                    required: {
                      value: true,
                      message: "IFSC is required",
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column ">
          <div className="row row-sm">
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                CPF required:
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="cpf"
                  name="cpf"
                  {...register("cpf", {
                    required: {
                      value: true,
                      message: "CPF is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                CNPJ required:
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="cnpj"
                  name="cnpj"
                  {...register("cnpj", {
                    required: {
                      value: true,
                      message: "CNPJ is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                OP required:
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="op"
                  name="op"
                  {...register("op", {
                    required: {
                      value: true,
                      message: "OP is required",
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column ">
          <div className="row row-sm">
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                RUT number required:
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="rutNumber"
                  name="rutNumber"
                  {...register("rutNumber", {
                    required: {
                      value: true,
                      message: "RUT Number is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                Country required:
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="countryNumber"
                  name="countryNumber"
                  {...register("countryNumber", {
                    required: {
                      value: true,
                      message: "Country Number is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                City required:
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="cityReq"
                  name="cityReq"
                  {...register("cityReq", {
                    required: {
                      value: true,
                      message: "City is required",
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column ">
          <div className="row row-sm">
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                Address line required:
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="addressLine"
                  name="addressLine"
                  {...register("addressLine", {
                    required: {
                      value: true,
                      message: "Address Line is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                Postcode / Area Code required:
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="postCode"
                  name="postCode"
                  {...register("postCode", {
                    required: {
                      value: true,
                      message: "Post Code is required",
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column ">
          <div className="row row-sm">
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                State required:
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="stateReq"
                  name="stateReq"
                  {...register("stateReq", {
                    required: {
                      value: true,
                      message: "State is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                Document required:
              </label>
              <div className="border py-2 rounded-3" style={{ height: "40px" }}>
                <input
                  type={"checkbox"}
                  className=""
                  id="docs"
                  name="docs"
                  {...register("docs", {
                    required: {
                      value: true,
                      message: "Document is required",
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column ">
          <label>
            Phone number required
            <span style={{ color: "red" }}> *</span>
          </label>
          <div className="border py-2 rounded-3" style={{ height: "40px" }}>
            <input
              type={"checkbox"}
              className=""
              id="phone"
              name="phone"
              {...register("phone", {
                required: {
                  value: true,
                  message: "Phone Number is required",
                },
              })}
            />
          </div>
        </div>

        <div className="col-md-6">
          {loading ? (
            <OpacityLoader />
          ) : (
            <input
              // onClick={addNewAMLRule}
              className="btn btn-primary margin-label width-100 fw-bold"
              type="submit"
              value={"submit"}
            />
          )}
        </div>
      </div>
    </form>
  );
}

export default AddBankConfig;
