import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import ProfileService from "../../utils/services/profile.service";
import toast from "react-hot-toast";

function OverdraftModal({ show, handleClose, walletDetails = {} }) {
  const [overdraft, setOverdraft] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleOverdraftRequest = async () => {
    setLoading(true);
    try {
      const payload = {
        walletAccountIdentificationNumber: walletDetails["id"],
        amount: parseFloat(overdraft),
      };

      const response = await ProfileService.requestForOverdraft(payload);

      if (response.data.status === "FAILED") {
        setLoading(true);
        setOverdraft("");
        toast.error(
          response.data.message !== null
            ? response.data.message
            : response.data.status
        );
      } else {
        toast.success(
          response.data.message !== null
            ? response.data.message
            : response.data.status
        );
        setOverdraft("");
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Modal show={show} backdrop="static" keyboard={false} onHide={handleClose}>
      <Modal.Header closeButton={false}>
        <Modal.Title className="fw-bold">
          {`Enter the overdraft amount on ${walletDetails["accName"]} (${walletDetails["accNumber"]})`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row row-sm">
            <div className="col-lg-8 col-md-8">
              <label className="pb-3 text-start">
                Please enter your overdraft amount
              </label>
              <input
                type={"number"}
                value={overdraft}
                onChange={(e) => setOverdraft(e.target.value)}
                placeholder="Enter an amount..."
                className="form-control"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={handleOverdraftRequest}>
          Submit
          {loading && (
            <>
              &nbsp;
              <i className="fa fa-spinner fa-spin" />
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OverdraftModal;

OverdraftModal.defaultProps = {
  handleClose: () => {},
  handleSubmit: () => {},
};

OverdraftModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  walletDetails: PropTypes.shape({
    accName: PropTypes.string,
    accNumber: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};
