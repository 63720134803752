import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import KycHeaderMenu from "../../../../components/header/KycHeaderMenu";
import Footer from "../../../../components/footer/Footer";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import {toggleNav} from "../../../../store/actions/component.action";
import {fetchNotifications} from "../../../../store/actions/profile.action";
import {Loading, messageStates} from "../../../../utils/helpers/constants";


const Support = () => {
  const dispatch = useDispatch();
  const {domainBrand, component, profile} = useSelector(
    ({domainBrandReducer, componentReducer, profileReducer}) => ({
    domainBrand: domainBrandReducer,
    component: componentReducer,
    profile: profileReducer
  }));

  const loadState = domainBrand.loading === Loading.FETCHING || profile.messagesLoading === Loading.FETCHING;

  useEffect(() => {
    dispatch(toggleNav(false));
    dispatch(fetchNotifications(messageStates.ALL));
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? <SphereLoader/> :
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"/>
            <Header domainBrand={domainBrand}/>
            {profile["userAccountVerified"] ?
              <HeaderMenu menu="general" active="support" domainBrand={domainBrand}/> :
              <KycHeaderMenu menu="general" active="support" domainBrand={domainBrand}/>
            }
            <Body domainBrand={domainBrand} initMessages={profile.messages}/>
          </div>
          <Footer domainBrand={domainBrand}/>
        </>
      }
    </div>
  );
}

export default Support;
