import React, { useState, useMemo } from "react";
import DataTable from "../../../../../components/dataTable/dataTable";
import { bankFieldsConfig } from "../../../../../utils/helpers/dataTableConfigs";
import { ReactComponent as Trash } from "../../../../../assets/images/svgs/trash.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EditCorridorForm from "./EditCorridorForm";

function Body({
  domainBrand,
  countries,
  currencies,
  bankConfigId,
  templateName,
}) {
  const [filterData, setFilterData] = useState("");

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const actionColumn = {
    Header: () => {
      return (
        <span className="text-center fw-bold">
          {languageStatus ? dictionary["l_action"] : "Action"}
        </span>
      );
    },
    columnId: 4,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-start pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${`1`}`}>Delete</Tooltip>}
          >
            <Trash />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const bankFieldsTable = bankFieldsConfig(languageStatus, dictionary, "");

  const tableObject = [...bankFieldsTable, actionColumn];

  const columns = useMemo(() => tableObject, []);

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {/* {languageStatus
                ? dictionary["WO_GLOBAL_CONFIGURATION_570634089"]
                : "Global Configurations"} */}
              Global Configurations
            </h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                {/* {languageStatus
                  ? dictionary["WO_YOU_CAN_SET_MANDATOR_1316489625"]
                  : "Use this section to edit bank configurations for all of your agent transactions."} */}
                Use this section to edit bank configurations for all of your
                agent transactions.
              </li>
            </ol>
          </div>
        </div>
        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">GLOBAL CONFIGURATION FIELDS</h3>
              </div>
              <div className="card-body">
                <EditCorridorForm
                  domainBrand={domainBrand}
                  countries={countries}
                  currencies={currencies}
                  bankConfigId={bankConfigId}
                  templateName={templateName}
                />
              </div>
            </div>
          </div>

          {/* TABLE FOR GLOBAL CONFIGURATIONS */}
          <div className="col-lg-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {/* {languageStatus
                    ? dictionary["m_transaction_rules"]
                    : "All Bank Configurations"} */}
                  All Global Configurations
                </h3>
              </div>
              <div className="card-body">
                <div className="col-lg-6 col-md-6 mb-3">
                  <p className="text-muted card-sub-title">
                    Sort and filter table
                  </p>

                  <input
                    className="form-control border"
                    type={"text"}
                    value={filterData}
                    onChange={(e) => setFilterData(e.target.value)}
                  />
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={[]}
                    filterData={filterData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
