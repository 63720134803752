import { useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

const DynamicDataTable = ({
  columns,
  data,
  fetchData,
  filterData,
  updatedPageSize,
  pageCount: controlledPageCount,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    setPageSize,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    pageCount,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  let renderPageCountButton = [];

  useEffect(() => {
    setPageSize(updatedPageSize);
  }, [updatedPageSize]);

  // useEffect(() => {
  //   console.log(pageIndex, pageSize);
  //   fetchData({pageIndex, pageSize})
  // }, [fetchData, pageIndex, pageSize])

  console.log(pageSize);
  console.log(setPageSize);

  useEffect(() => {
    setGlobalFilter(filterData);
  }, [filterData]);

  for (let i = 0; i < pageCount; i++) {
    if (i > 8) break;
    renderPageCountButton.push(
      <li
        key={i}
        className={`paginate_button page-item ${
          i === pageIndex ? "active" : ""
        }`}
      >
        <button onClick={() => gotoPage(i)} className="page-link">
          {i + 1}
        </button>
      </li>
    );
  }

  return (
    <>
      <table
        {...getTableProps()}
        className="table text-nowrap text-md-nowrap table-striped mg-b-0"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="fw-bold"
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <>
                          &nbsp;
                          <i className="fa fa-angle-down" />
                        </>
                      ) : (
                        <>
                          &nbsp;
                          <i className="fa fa-angle-up" />
                        </>
                      )
                    ) : (
                      <>
                        &nbsp;
                        <i className="fa fs-12 fa-sort" />
                      </>
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <div className="dataTables_info" role="status" aria-live="polite">
            Showing results {pageIndex * 15 + 1} -{" "}
            {pageIndex * 15 + 1 + page.length - 1} of {`${data.length} `}
            {data.length > 1 ? "entries." : "entry."}
          </div>
        </div>
        <div className="col-sm-12 col-md-7 col-md-push-6">
          <div className="dataTables_paginate float-end mb-4">
            <ul className="pagination">
              <li
                className={`paginate_button page-item previous ${
                  canPreviousPage ? "" : "disabled"
                }`}
              >
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="page-link"
                >
                  Previous
                </button>
              </li>
              {renderPageCountButton}
              <li
                className={`paginate_button page-item next ${
                  canNextPage ? "" : "disabled"
                }`}
              >
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="page-link"
                >
                  {" "}
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicDataTable;
