import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerProductModals from "../../../../components/modals/CustomerProductModals";
import ProductCard from "./atom/productCard";
import { getManagerApproval } from "../../../../store/actions/customer-products.action";
import { Loading } from "../../../../utils/helpers/constants";

function Body({ products = [] }) {
  const dispatch = useDispatch();

  const { customerProducts } = useSelector(({ customerProductsRedcucer }) => ({
    customerProducts: customerProductsRedcucer,
  }));

  const [getProdName, setGetProdName] = useState("");
  const [show, setShow] = useState(false);
  const [loadMore, updateLoadMore] = useState(9);
  const [accountProductId, setAccountProdId] = useState("");

  const handleTrigger = async (id) => {
    await dispatch(getManagerApproval(id));
    if (
      customerProducts.loadingRequest === Loading.SUCCESS ||
      customerProducts.loadingRequest === Loading.FAILED
    ) {
      setShow(false);
    }
  };

  const handleClick = (item) => {
    setGetProdName(item.prod);
    setShow(true);
  };

  const handleLoadMore = (limit, list = []) => {
    if (list.length > limit) {
      updateLoadMore(limit + 9);
    } else if (list.length < limit || list.length === limit) {
      updateLoadMore(9);
    }
  };

  const handleLoadText = (limit, list) => {
    if (list.length > limit) {
      return <span className="">Show More</span>;
    } else if (list.length === limit || list.length < limit) {
      return <span className="">Show Less</span>;
    }
  };

  return (
    <>
      <CustomerProductModals
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        prodName={getProdName}
        loading={customerProducts.loadingRequest}
        handleTrigger={() => handleTrigger(accountProductId)}
      />
      <div className="app-content hor-content">
        <div className="container my-5 py-3">
          <div className="row row-sm">
            {products.length > 0 &&
              products.slice(0, loadMore).map((item) => {
                return (
                  <>
                    {item["activatedStatus"] && (
                      <ProductCard
                        products={item}
                        handleClick={() => {
                          handleClick(item);
                          setGetProdName(item["productName"]);
                          setAccountProdId(item["accountProductId"]);
                        }}
                      />
                    )}
                  </>
                );
              })}
            {products.length < 10 ? (
              ""
            ) : (
              <div
                onClick={() => {
                  handleLoadMore(loadMore, products);
                }}
                className="load-more-products"
              >
                {handleLoadText(loadMore, products)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
