import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import CustomDatePicker from "../../../../../../components/datePickers/CustomDatePicker";

import CreateBank from "./CreateBank";
import DynamicFields from "../../../../../../components/dynamicFields/DynamicFields";
import {
  customerTypeDropdown,
  relationshipOptions,
} from "../../../../../../utils/helpers/objectHelpers";
import { beneficiaryValidator } from "../../../../../../utils/helpers/validationHelpers";
import { createBeneficiary } from "../../../../../../utils/services/users.services";
import { documentType } from "../../../../../../utils/helpers/objectHelpers";

import {
  customerTypes,
  dynamicFieldsTypes,
  Loading,
} from "../../../../../../utils/helpers/constants";

const Create = ({
  countries,
  currencies,
  phoneCodes,
  component,
  mandatoryFieldsObject,
  domainBrand,
  nation,
  senderCode,
}) => {
  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const customerTypesDropdown = customerTypeDropdown(
    languageStatus,
    dictionary
  );

  const documentTypeDropDown = documentType(languageStatus, dictionary);

  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [visibleFields, setVisibleFields] = useState([]);
  const [userType, updateUserType] = useState(customerTypesDropdown[0]);
  const [DOMState, updateDOMState] = useState("CREATE");
  const [bankDetails, updateBankDetails] = useState({});
  const [loading, updateLoading] = useState(false);
  const [onSubmitClick, setOnSubmitClick] = useState(false);
  const [dynamicFormType, setDynamicFormType] = useState(
    dynamicFieldsTypes.CUSTOMER_CREATE_BENEFICIARY
  );
  const [dateOfBirth, getDOB] = useState("");

  const initialValues = {
    type: customerTypesDropdown[0] ?? "",
    firstName: "",
    middleName: "",
    lastName: "",
    companyName: "",
    regNumber: "",
    email: "",
    phoneCountryCode: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    postcode: "",
    state: "",
    country: "",
    relationShip: "",
    idNumber: "",
    employer: "",
    salary: "",
    salaryCurrency: "",
  };

  const initialValidation = beneficiaryValidator(mandatoryFields);
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(initialValidation),
    onSubmit: () => {},
  });

  useEffect(() => {
    if (
      userType.value === customerTypes.INDIVIDUAL &&
      "requiredFields" in mandatoryFieldsObject.individualFields
    ) {
      setMandatoryFields(mandatoryFieldsObject.individualFields.requiredFields);
      setVisibleFields(mandatoryFieldsObject.individualFields.visibleFields);
    } else if (
      userType.value === customerTypes.COMPANY &&
      "requiredFields" in mandatoryFieldsObject.companyFields
    ) {
      setMandatoryFields(mandatoryFieldsObject.companyFields.requiredFields);
      setVisibleFields(mandatoryFieldsObject.companyFields.visibleFields);
    }
  }, [userType, mandatoryFieldsObject]);

  // visibleFields.some((o) => console.log(o["fieldType"] === "DOB"));

  useEffect(() => {
    if (component.loading === Loading.SUCCESS) {
      if (userType.value === customerTypes.COMPANY)
        setDynamicFormType(dynamicFieldsTypes.COMPANY_CREATE_BENEFICIARY);
      else if (userType.value === customerTypes.INDIVIDUAL)
        setDynamicFormType(dynamicFieldsTypes.CUSTOMER_CREATE_BENEFICIARY);
      else setDynamicFormType(dynamicFieldsTypes.CUSTOMER_CREATE_BENEFICIARY);
    }
  }, [userType]);

  const createNewBeneficiary = async () => {
    updateLoading(true);
    setOnSubmitClick(true);
    // let dynamicValues = dynamicFieldsFormikGetKeys(
    //   component[dynamicFormType]["dynamicFields"],
    //   formik.values
    // );

    const payload = {
      dynamicFields: {},
      senderCode: senderCode !== undefined ? senderCode : "",
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      dob: dateOfBirth,
      encodedCustomerPhoto: "",
      employer: formik.values.employer,
      middleName: formik.values.middleName,
      companyName: formik.values.companyName,
      regNumber: formik.values.regNumber,
      email: formik.values.email,
      phone: formik.values.phone,
      type: formik.values.type.value,
      relationShip: formik.values.relationShip.value,
      salary: formik.values.salary,
      salaryCurrency: formik.values.salaryCurrency.value,
      address: {
        address1: formik.values.address1,
        address2: formik.values.address2,
        city: formik.values.city,
        countryCommonName: formik.values.country["common_name"],
        postcode: formik.values.postcode,
        countryIso3: formik.values.country["iso3Code"],
        state: formik.values.state,
      },
    };

    if (Object.entries(formik.errors).length === 0) {
      const { data: responseData } = await createBeneficiary(payload);
      if (responseData.status !== Loading.SUCCESS)
        toast.error(`${responseData.message}`);
      else {
        const { firstName, lastName, companyName, type } = payload;
        const accountHolderName =
          type === customerTypes.COMPANY
            ? companyName
            : `${firstName} ${lastName}`;
        const details = {
          accountHolderName,
          accountOwnerCustomerCode: responseData.data["beneficiaryCode"],
        };
        updateBankDetails(details);
        updateDOM("CREATE_BANK");
      }
    }
    updateLoading(false);
  };

  const disableNextButton = formik.values.firstName === "";
  const disablePhoneInput =
    typeof formik.values.phoneCountryCode.value === "undefined" ||
    formik.values.phoneCountryCode.value === "";
  const updateDOM = (value) => updateDOMState(value);

  const allDynamicFields =
    component.loading === Loading.FETCHING ||
    typeof component[dynamicFormType] === "undefined"
      ? []
      : component[dynamicFormType]["dynamicFields"];

  const renderDynamicFields = allDynamicFields.map((eachField, index) => (
    <DynamicFields
      divClass="col-lg-6 col-md-6 mb-3"
      key={index}
      eachField={eachField}
      formik={formik}
    />
  ));

  return (
    <>
      {DOMState === "CREATE" ? (
        <form className="needs-validation" onSubmit={formik.handleSubmit}>
          <div className="form-row">
            <div className="col-md-6 col-lg-6 mb-3">
              <label>UserType</label>
              <Select
                options={customerTypesDropdown}
                isClearable={false}
                isSearchable={false}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                name="userType"
                value={userType}
                onChange={(option) => {
                  updateUserType(option);
                  formik.setFieldValue("type", option);
                }}
              />
            </div>
            <div className="col-md-6 col-lg-6 mb-3">
              <label>
                {languageStatus ? dictionary["l_country"] : "Country"}
              </label>
              <Select
                options={countries || nation}
                isClearable={false}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                name="country"
                onChange={(option) => formik.setFieldValue("country", option)}
              />
            </div>
            {formik.values.type.value === customerTypes.COMPANY ? (
              !visibleFields.some((o) => o["fieldType"] === "COMPANY_NAME") ? (
                ""
              ) : (
                <div className="col-md-6 col-lg-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["WO_COMPANY_NAM_-1629274032"]
                      : "Company name"}
                  </label>
                  <input
                    className={`form-control ${
                      formik.errors["companyName"] && onSubmitClick
                        ? "is-invalid"
                        : ""
                    }`}
                    type="text"
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors["companyName"] ? (
                    <div className="invalid-feedback">
                      Please provide company name.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )
            ) : (
              ""
            )}
            <div className="col-md-6 col-lg-6 mb-3">
              <label>
                {languageStatus ? dictionary["para_first_name"] : "First name"}
              </label>
              <input
                type="text"
                name="firstName"
                className={`form-control ${
                  formik.errors["firstName"] && onSubmitClick
                    ? "is-invalid"
                    : ""
                }`}
                onChange={formik.handleChange}
              />
              {formik.errors["firstName"] ? (
                <div className="invalid-feedback">
                  Please provide first name.
                </div>
              ) : (
                ""
              )}
            </div>
            {formik.values.type.value === customerTypes.INDIVIDUAL ? (
              !visibleFields.some((o) => o["fieldType"] === "MIDDLE_NAME") ? (
                ""
              ) : (
                <div className="col-md-6 col-lg-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["WO_MIDDLE_NAM_1847397348"]
                      : "Middle name"}
                  </label>
                  <input
                    type="text"
                    name="middleName"
                    className={`form-control ${
                      formik.errors["middleName"] && onSubmitClick
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                  />
                  {formik.errors["middleName"] ? (
                    <div className="invalid-feedback">
                      Please provide middle name.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )
            ) : (
              ""
            )}
            <div className="col-md-6 col-lg-6 mb-3">
              <label>
                {languageStatus ? dictionary["l_last_name"] : "Last Name"}
              </label>
              <input
                className={`form-control ${
                  formik.errors["lastName"] && onSubmitClick ? "is-invalid" : ""
                }`}
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              {formik.errors["lastName"] ? (
                <div className="invalid-feedback">
                  Please provide last name.
                </div>
              ) : (
                ""
              )}
            </div>

            {formik.values.type.value === customerTypes.COMPANY ? (
              !visibleFields.some(
                (o) => o["fieldType"] === "COMPANY_REG_NUMBER"
              ) ? (
                ""
              ) : (
                <div className="col-md-6 col-lg-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["WO_REGISTRATION_NUMBE_1407955590"]
                      : "Reg. Number"}
                  </label>
                  <input
                    className={`form-control ${
                      formik.errors["regNumber"] && onSubmitClick
                        ? "is-invalid"
                        : ""
                    }`}
                    type="text"
                    name="regNumber"
                    value={formik.values.regNumber}
                    onChange={formik.handleChange}
                  />
                  {formik.errors["regNumber"] ? (
                    <div className="invalid-feedback">
                      Please provide registration number.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )
            ) : (
              ""
            )}
            {!visibleFields.some(
              (o) =>
                o["fieldType"] === "EMAIL" || o["fieldType"] === "COMPANY_EMAIL"
            ) ? (
              ""
            ) : (
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus ? dictionary["l_email"] : "Email"}
                </label>
                <input
                  type="text"
                  name="email"
                  className={`form-control ${
                    formik.errors["email"] && onSubmitClick ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                />
                {formik.errors["email"] ? (
                  <div className="invalid-feedback">Email is required.</div>
                ) : (
                  ""
                )}
              </div>
            )}
            {!visibleFields.some((o) => o["fieldType"] === "PHONE") ? (
              ""
            ) : (
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_phone_number"]
                    : "Phone Number"}
                </label>
                <div className="form-row">
                  <div className="col-md-5 col-lg-5 mb-3">
                    <Select
                      options={phoneCodes}
                      isClearable={false}
                      maxMenuHeight={250}
                      menuPlacement="bottom"
                      menuPortalTarget={menuPortalTarget}
                      name="phoneCountryCode"
                      onChange={(option) =>
                        formik.setFieldValue("phoneCountryCode", option)
                      }
                    />
                  </div>
                  <div className="col-md-7 col-lg-7 mb-3">
                    <input
                      type="text"
                      name="phone"
                      disabled={disablePhoneInput}
                      className={`form-control ${
                        formik.errors["phone"] && onSubmitClick
                          ? "is-invalid"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                    />
                    {formik.errors["phone"] ? (
                      <div className="invalid-feedback">Required field</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}
            {!visibleFields.some((o) => o["fieldType"] === "ADDRESS_1") ? (
              ""
            ) : (
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_address_line_1"]
                    : "Address line 1"}
                </label>
                <input
                  type="text"
                  name="address1"
                  className={`form-control ${
                    formik.errors["address1"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                />
                {formik.errors["address1"] ? (
                  <div className="invalid-feedback">Field is required</div>
                ) : (
                  ""
                )}
              </div>
            )}
            {visibleFields.some((o) => o["fieldType"] === "DOB") && (
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_date_of_birth"]
                    : "Date of Birth"}
                </label>
                <CustomDatePicker
                  getDate={getDOB}
                  showYearDropdown
                  selectDropdownMode
                  withPortal={false}
                  setMaxDate={new Date()}
                />
              </div>
            )}
            {!visibleFields.some((o) => o["fieldType"] === "ID_TYPE") ? (
              ""
            ) : (
              <div className="col-md-6 col-lg-6 mb-3">
                <div className="form-row">
                  <div className="col-md-5 col-lg-5 mb-3">
                    <label>
                      {languageStatus
                        ? dictionary["WO_ID_TYP_2013858264"]
                        : "ID Type"}
                    </label>
                    <Select
                      options={documentTypeDropDown}
                      isClearable={false}
                      maxMenuHeight={250}
                      menuPlacement="bottom"
                      menuPortalTarget={menuPortalTarget}
                      name="idType"
                      // onChange={(option) =>
                      //   formik.setFieldValue("phoneCountryCode", option)
                      // }
                    />
                  </div>
                  <div className="col-md-7 col-lg-7 mb-3">
                    <label>
                      {languageStatus
                        ? dictionary["WO_ID_NUMBE_1281572260"]
                        : "ID Number"}
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="idNumber"
                        className={`form-control ${
                          formik.errors["idNumber"] && onSubmitClick
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                      />
                      {formik.errors["idNumber"] ? (
                        <div className="invalid-feedback">
                          Field is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!visibleFields.some((o) => o["fieldType"] === "ADDRESS_2") ? (
              ""
            ) : (
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_address_line_2"]
                    : "Address line 2"}
                </label>
                <input
                  className={`form-control ${
                    formik.errors["address2"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  type="text"
                  name="address2"
                  value={formik.values.address2}
                  onChange={formik.handleChange}
                />
              </div>
            )}
            {!visibleFields.some((o) => o["fieldType"] === "CITY") ? (
              ""
            ) : (
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus ? dictionary["para_city"] : "City"}
                </label>
                <input
                  type="text"
                  name="city"
                  className={`form-control ${
                    formik.errors["city"] && onSubmitClick ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                />
              </div>
            )}

            {visibleFields.some((o) => o["fieldType"] === "POSTCODE") && (
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus ? dictionary["para_postcode"] : "Post code"}
                </label>
                <input
                  className={`form-control ${
                    formik.errors["postcode"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  type="text"
                  name="postcode"
                  value={formik.values.postcode}
                  onChange={formik.handleChange}
                />
              </div>
            )}
            {visibleFields.some((o) => o["fieldType"] === "EMPLOYER") && (
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["WO_EMPLOYE_1313991626"]
                    : "Employer"}
                </label>
                <input
                  className={`form-control ${
                    formik.errors["employer"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  type="text"
                  name="employer"
                  value={formik.values.employer}
                  onChange={formik.handleChange}
                />
              </div>
            )}
            {/* HERE */}
            {visibleFields.some((o) => o["fieldType"] === "ANNUAL_SALARY") && (
              <div className="col-md-6 col-lg-6 mb-3">
                <div className="form-row">
                  <div className="col-md-5 col-lg-5 mb-3">
                    <label>
                      {languageStatus
                        ? dictionary["WO_CURRENCY_1471979772"]
                        : "Currency"}
                    </label>
                    <Select
                      options={currencies}
                      isClearable={false}
                      maxMenuHeight={250}
                      menuPlacement="bottom"
                      menuPortalTarget={menuPortalTarget}
                      name="salaryCurrency"
                      onChange={(option) =>
                        formik.setFieldValue("salaryCurrency", option)
                      }
                    />
                  </div>
                  <div className="col-md-7 col-lg-7 mb-3">
                    <label>
                      {languageStatus
                        ? dictionary["WO_ANNUAL_SALAR_1783123859"]
                        : "Annual Salary"}
                    </label>
                    <input
                      type="text"
                      name="salary"
                      className={`form-control ${
                        formik.errors["salary"] && onSubmitClick
                          ? "is-invalid"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                    />
                    {formik.errors["salary"] ? (
                      <div className="invalid-feedback">Required field</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}

            {visibleFields.some((o) => o["fieldType"] === "STATE") && (
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus ? dictionary["WO_STAT_-878960465"] : "State"}
                </label>
                <input
                  type="text"
                  name="state"
                  className={`form-control ${
                    formik.errors["state"] && onSubmitClick ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                />
              </div>
            )}
            <div className="col-md-6 col-lg-6 mb-3">
              <label>
                {languageStatus
                  ? dictionary["WO_BENEFICIARY_RELATION_1083165793"]
                  : "Relationship"}
              </label>
              <Select
                options={relationshipOptions(formik.values.type.value)}
                isClearable={false}
                maxMenuHeight={250}
                menuPlacement="top"
                menuPortalTarget={menuPortalTarget}
                name="relationShip"
                onChange={(option) =>
                  formik.setFieldValue("relationShip", option)
                }
              />
            </div>

            {renderDynamicFields}
          </div>
          <div className="mt-4 float-end">
            <button
              onClick={createNewBeneficiary}
              disabled={disableNextButton}
              className="btn btn-primary"
              type="button"
            >
              {languageStatus
                ? dictionary["para_bank_account_details"]
                : "Bank Details"}{" "}
              &nbsp;
              {loading ? (
                <i className="fa fa-spin fa-spinner" />
              ) : (
                <i className="fa fa-arrow-right" />
              )}
            </button>
          </div>
        </form>
      ) : (
        <CreateBank
          countries={countries}
          currencies={currencies}
          domainBrand={domainBrand}
          formik={formik}
          bankInfo={bankDetails}
        />
      )}
    </>
  );
};

export default Create;
