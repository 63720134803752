import { toast } from "react-hot-toast";

import ActionCreator from "../../utils/helpers/actionCreator";
import { expiredTokenCheck } from "./auth.action";
import * as Profile from "../constants/profile.constants";
import ProfileService from "../../utils/services/profile.service";
import cookieHelper from "../../utils/helpers/cookieHelper";
import { accessScopes, configs } from "../../utils/helpers/constants";

export const getProfile = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(Profile.FETCH_USER_PROFILE_REQUEST));
    const { data: responseData } = await ProfileService.fetchUser();

    if (responseData.status === "FAILED") throw responseData;
    const userData = responseData.data;
    const userAccessScope = userData["accessScope"];
    const canViewDashboard =
      userAccessScope === accessScopes.CUSTOMER_FLOW ||
      userAccessScope === accessScopes.CUSTOMER_FLOW_OPTIONAL_KYC
        ? "VERIFIED"
        : userAccessScope === accessScopes.KYC_FLOW_UPLOAD_DOCUMENT ||
          userAccessScope === accessScopes.KYC_FLOW ||
          userAccessScope === accessScopes.AWAITING_APPROVAL_FLOW
        ? "UNVERIFIED"
        : null;

    await cookieHelper.set(
      configs.USER_VERIFIED_STATUS,
      canViewDashboard,
      configs.COOKIE_EXPIRY_PERIOD
    );
    dispatch(ActionCreator(Profile.FETCH_USER_PROFILE_SUCCESS, userData));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Profile.FETCH_USER_PROFILE_FAILURE));
  }
};

export const updateProfilePicture = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Profile.UPDATE_PROFILE_PICTURE_REQUEST));
    const { data: responseData } = await ProfileService.updateProfilePicture(
      payload
    );
    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(Profile.UPDATE_PROFILE_PICTURE_SUCCESS, responseData.data)
    );
    toast.success("Profile picture successfully modified!");
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Profile.UPDATE_PROFILE_PICTURE_FAILURE));
    toast.error(`Profile picture failed to upload!`);
  }
};

export const fetchDocuments = (customerCode) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Profile.FETCH_DOCUMENTS_REQUEST));
    const { data: responseData } = await ProfileService.listDocuments(
      customerCode
    );
    if (responseData.status === "FAILED") throw responseData;

    dispatch(ActionCreator(Profile.FETCH_DOCUMENTS_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Profile.FETCH_DOCUMENTS_FAILURE));
  }
};

export const uploadDocuments = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Profile.UPLOAD_DOCUMENTS_REQUEST));
    const { data: responseData } = await ProfileService.attachDocuments(
      payload
    );
    if (responseData.status === "FAILED") throw responseData;
    toast.success(responseData.message);

    dispatch(
      ActionCreator(Profile.UPLOAD_DOCUMENTS_SUCCESS, responseData.data.docs)
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Profile.UPLOAD_DOCUMENTS_FAILURE));
    toast.error(`Document Upload Failed! ${error.message}`);
  }
};

export const fetchNotifications = (type) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Profile.FETCH_MESSAGES_REQUEST));
    const { data: responseData } = await ProfileService.fetchNotifications(
      type
    );
    if (responseData.status === "FAILED") throw responseData;

    dispatch(ActionCreator(Profile.FETCH_MESSAGES_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Profile.FETCH_MESSAGES_FAILURE));
  }
};

export const fetchAwaitingNotificationState = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(Profile.FETCH_NOTIFICATIONS_REQUEST));
    const { data: responseData } =
      await ProfileService.showAwaitingNotification();
    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(Profile.FETCH_NOTIFICATIONS_SUCCESS, responseData.data)
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Profile.FETCH_NOTIFICATIONS_FAILURE));
  }
};
