import pdfmake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

const pdfGeneratorHistory = async (data, statement, domainBrand) => {
  pdfmake.vfs = pdfFonts["pdfMake"].vfs;
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const { profile, fromDate, toDate } = data;
  const {
    firstName,
    lastName,
    address: { address1, address2, city, countryCommonName },
  } = profile;
  const dateInfo =
    fromDate && toDate
      ? `Please find below transfer history from ${fromDate ?? "-"} to ${
          toDate ?? "-"
        }`
      : `Please find below transfer history`;

  const headerWidth = "*";
  const tableHeaders = [
    "Date",
    "Details",
    "Reference",
    "Status",
    { text: "Amount", alignment: "right" },
  ];
  const headers = tableHeaders.map((item) => ({
    text: item,
    style: "tableHeader",
  }));
  const tableColumns = tableHeaders.map(() => headerWidth);

  const docDefinition = {
    pageOrientation: "portrait",

    content: [
      {
        columns: [],
      },
      {
        text: languageStatus
          ? dictionary["WO_TRANSFER_HISTOR_57573459"]
          : "Transfer History",
        style: "header",
      },
      {
        columns: [
          {
            columns: [
              {
                text: "Name \u200B \n Address \u200B \n",
                width: 100,
                style: "infoNude",
              },
              {
                text: `${firstName ?? ""} ${lastName ?? ""} \n ${address1} ${
                  address2 ?? ""
                }, ${city ?? ""}, ${countryCommonName ?? ""} \n`,
                width: "auto",
                style: "info",
              },
            ],
          },
        ],
      },
      {
        text: dateInfo,
        style: "subInfo",
      },
      {
        text: "Transfer Information",
        style: "subheader",
      },
      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 5,
            x2: 595 - 2 * 40,
            y2: 5,
            lineWidth: 0.2,
          },
        ],
        style: "pretext",
      },
      {
        table: {
          headerRows: 1,
          heights: 30,
          widths: [100, 200, 70, 70, 60],
          tableColumns,
          body: [headers, ...statement],
        },
        layout: "noBorders",
        style: "tableBody",
      },
    ],

    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 20],
        alignment: "left",
      },
      subheader: {
        fontSize: 12,
        bold: true,
        margin: [0, 30, 0, 10],
        alignment: "left",
      },
      pretext: {
        margin: [0, 0, 0, 20],
        alignment: "left",
      },
      infoNude: {
        color: "#696969",
        fontSize: 10,
        margin: [0, 10, 0, 0],
        bold: true,
        alignment: "left",
      },
      info: {
        color: "black",
        fontSize: 10,
        margin: [0, 10, 0, 0],
        alignment: "left",
      },
      subInfo: {
        color: "black",
        fontSize: 8,
        margin: [0, 10, 0, 0],
        alignment: "left",
      },
      tableHeader: {
        bold: true,
        color: "black",
        fontSize: 10,
        alignment: "left",
      },
      tableBody: {
        fontSize: 10,
        alignment: "left",
      },
    },
  };

  pdfmake.createPdf(docDefinition).open();
};

export default pdfGeneratorHistory;
