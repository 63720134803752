import {NavLink} from "react-router-dom";

const Body = ({details, pageStatus}) => {


  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {`${details['firstName']} ${details['lastName']} - ${pageStatus.name.slice(0, -1)}`}
            </h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to={`/management${pageStatus.path}`}>{pageStatus.name}</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">{`${details['firstName']}'s details`}</li>
            </ol>
          </div>
          <div className="ms-auto pageheader-btn">
            <NavLink to="/" className="btn btn-danger btn-icon text-white me-2">
              <span><i className="fa fa-check"/></span> Suspend
            </NavLink>
            <NavLink to="/" className="btn btn-primary btn-icon text-white me-2">
              <span><i className="fa fa-check"/></span> Approve
            </NavLink>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Basic Information</h3>
              </div>
              <div className="card-body">
                <form>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label>Email</label>
                      <input type="text" className="form-control" defaultValue={details.email} readOnly/>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Staff ID</label>
                      <input type="text" className="form-control" defaultValue={details.phone} readOnly/>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label>First name</label>
                      <input type="text" className="form-control" defaultValue={details.firstName} readOnly/>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Last name</label>
                      <input type="text" className="form-control" defaultValue={details.lastName} readOnly/>
                    </div>
                  </div>
                  {/*<div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationServer03">City</label>
                      <input type="text" className="form-control is-invalid" id="validationServer03"
                             aria-describedby="validationServer03Feedback" required/>
                        <div id="validationServer03Feedback" className="invalid-feedback">Please provide a valid city.
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationServer04">State</label>
                      <select className="form-select select2 form-control is-invalid" id="validationServer04"
                              aria-describedby="validationServer04Feedback" required>
                        <option selected disabled value="">Choose...</option>
                        <option>...</option>
                      </select>
                      <div id="validationServer04Feedback" className="invalid-feedback">Please select a valid state.
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationServer05">Zip</label>
                      <input type="text" className="form-control is-invalid" id="validationServer05"
                             aria-describedby="validationServer05Feedback" required/>
                        <div id="validationServer05Feedback" className="invalid-feedback">Please provide a valid zip.
                        </div>
                    </div>
                  </div>*/}
                  {/*<div className="form-group">
                    <div className="form-check">
                      <input className="form-check-input is-invalid" type="checkbox" value="" id="invalidCheck3"
                             aria-describedby="invalidCheck3Feedback" required/>
                        <label className="form-check-label" htmlFor="invalidCheck3">Agree to terms and
                          conditions</label>
                        <div id="invalidCheck3Feedback" className="invalid-feedback">You must agree before submitting.
                        </div>
                    </div>
                  </div>*/}
                  <button className="btn btn-primary mt-4" type="submit">Update</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
