import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import {toggleNav} from "../../../../store/actions/component.action";
import {Loading} from "../../../../utils/helpers/constants";

const GetSender = () => {
  const history = useHistory(), dispatch = useDispatch();
  const prevLocation = history.location.state?.prevLocation;
  const pageStatus = history.location.state?.pageStatus;
  const {domainBrand, component} = useSelector(
    ({domainBrandReducer, componentReducer}) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
    }));

  const loadState = domainBrand.loading === Loading.FETCHING;

  useEffect(() => {
    dispatch(toggleNav(false));
    if (typeof history.location.state === "undefined") history.push('/');
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? <SphereLoader/> :
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"/>
            <Header domainBrand={domainBrand}/>
            <HeaderMenu menu="accounts" active="fund-wallet" domainBrand={domainBrand}/>
            <Body domainBrand={domainBrand} prevLocation={prevLocation} pageStatus={pageStatus}/>
          </div>
          <Footer domainBrand={domainBrand}/>
        </>
      }
    </div>
  );
}

export default GetSender;
