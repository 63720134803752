import { toast } from "react-hot-toast";

import ActionCreator from "../../utils/helpers/actionCreator";
import * as TransferConstants from "../constants/transfer.constants";
import { expiredTokenCheck } from "./auth.action";
import {
  airtimeTopup,
  billsPayment,
  bookWalletToWalletPayment,
  cancelTransaction,
  createTransaction,
  fetchMonthlyTransaction,
  fetchSupportedPaymentMethods,
  getQuote,
  initiateFundWalletPayment,
  listPayoutCentres,
  makePaymentFromWallet,
} from "../../utils/services/transfer.service";

export const getQuoteData = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(TransferConstants.GET_QUOTE_REQUEST));
    const { data: responseData } = await getQuote(payload);

    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(TransferConstants.GET_QUOTE_SUCCESS, responseData.data)
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(TransferConstants.GET_QUOTE_FAILURE));
  }
};

export const resetQuoteData = () => async (dispatch) => {
  dispatch(ActionCreator(TransferConstants.RESET_QUOTE_SUCCESS));
};

export const fetchMonthlyTransactions =
  (code, yearSuffix) => async (dispatch) => {
    try {
      dispatch(
        ActionCreator(TransferConstants.FETCH_MONTHLY_TRANSACTIONS_REQUEST)
      );
      const { data: responseData } = await fetchMonthlyTransaction(
        code,
        yearSuffix
      );
      if (responseData.status === "FAILED") throw responseData;

      await dispatch(
        ActionCreator(
          TransferConstants.FETCH_MONTHLY_TRANSACTIONS_SUCCESS,
          responseData.data
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(
        ActionCreator(TransferConstants.FETCH_MONTHLY_TRANSACTIONS_FAILURE)
      );
      toast.error("Error fetching monthly transactions");
    }
  };

export const initiateTransaction =
  ({ payload, quoteData, navigator, password }) =>
  async (dispatch) => {
    try {
      dispatch(ActionCreator(TransferConstants.INITIATE_TRANSFER_REQUEST));
      const { data: responseData } = await createTransaction(payload, password);
      if (responseData.error["errorCategory"] === "FAILED") throw responseData;

      const { objectReference, payAbleId, payableType } = responseData;
      const { originCountry, receivingCountry, sendAmount, type } = payload;
      const { sendingCurrency, receivingCurrency } = quoteData;
      const dataValues = {
        payAbleId,
        payableType,
        sendAmount,
        sendingCurrency,
        receivingCurrency,
        originCountry,
        receivingCountry,
        pcn: objectReference,
        transactionType: type,
      };

      navigator(dataValues);
      dispatch(
        ActionCreator(
          TransferConstants.INITIATE_TRANSFER_SUCCESS,
          responseData.data
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(ActionCreator(TransferConstants.INITIATE_TRANSFER_FAILURE));
      toast.error(
        error.error["errorDescription"] || "Error submitting transaction"
      );
    }
  };

export const initiateWalletToWalletTransaction =
  (payload, navigator) => async (dispatch) => {
    try {
      dispatch(ActionCreator(TransferConstants.BOOK_WALLET_TO_WALLET_REQUEST));
      const { data: responseData } = await bookWalletToWalletPayment(payload);
      if (responseData.status === "FAILED") throw responseData;

      const { creditingCurrency, amount } = payload;
      const paymentMethod = {
        ...responseData.data,
        sendAmount: amount,
        sendingCurrency: creditingCurrency,
        receivingCurrency: creditingCurrency,
        pcn: responseData.data["pcn"],
      };

      // const dataValues = {
      //   payAbleId,
      //   payableType,
      //   amount,
      //   sendingCurrency,
      //   receivingCurrency,
      //   originCountry,
      //   receivingCountry,
      //   pcn: objectReference,
      //   transactionType: type,
      // };

      navigator(paymentMethod);
      dispatch(
        ActionCreator(
          TransferConstants.BOOK_WALLET_TO_WALLET_SUCCESS,
          responseData.data
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(ActionCreator(TransferConstants.BOOK_WALLET_TO_WALLET_FAILURE));
      toast.error(error["errorDescription"] || "Error submitting transaction");
    }
  };

export const initiateWalletPayment =
  (payload, navigateToTransfer, options) => async (dispatch) => {
    try {
      dispatch(ActionCreator(TransferConstants.INITIATE_WALLET_REQUEST));
      const { data: responseData } = await initiateFundWalletPayment(payload);
      if (responseData.status === "FAILED") throw responseData;

      console.log(responseData.data, "mj");

      const paymentMethod = { ...responseData.data, ...options };
      await dispatch(ActionCreator(TransferConstants.INITIATE_WALLET_SUCCESS));
      navigateToTransfer(paymentMethod);
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(ActionCreator(TransferConstants.INITIATE_WALLET_FAILURE));
      // toast.error(`Error completing payment by wallet. ${error.message}`);
      toast.custom((handler) => (
        <div
          className={`alert alert-danger w-30 alert-dismissible fade ${
            handler.visible ? "show" : ""
          } mb-0`}
          role="alert"
        >
          <strong>Error completing payment by wallet</strong>
          <hr className="message-inner-separator" />
          <p>{error.message}</p>
          <button
            onClick={() => toast.dismiss(handler.id)}
            type="button"
            className="text-white btn-close"
            data-bs-dismiss="alert"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      ));
    }
  };

export const initiateBillsPayment =
  (payload, navigateToTransfer, options) => async (dispatch) => {
    try {
      dispatch(ActionCreator(TransferConstants.INITIATE_BILL_PAYMENT_REQUEST));
      const { data: responseData } = await billsPayment(payload);
      if (responseData.status === "FAILED") throw responseData;

      const paymentMethod = { ...responseData.data, ...options };
      await dispatch(
        ActionCreator(TransferConstants.INITIATE_BILL_PAYMENT_SUCCESS)
      );
      navigateToTransfer(paymentMethod);
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(ActionCreator(TransferConstants.INITIATE_BILL_PAYMENT_FAILURE));
      toast.error("Error initiating bill payment");
    }
  };

export const initiateAirtimeTopup =
  (payload, navigateToTransfer, options) => async (dispatch) => {
    try {
      dispatch(ActionCreator(TransferConstants.INITIATE_AIRTIME_TOPUP_REQUEST));
      const { data: responseData } = await airtimeTopup(payload);

      if (responseData.status === "FAILED") throw responseData;
      const paymentMethod = { ...responseData.data, ...options };

      await dispatch(
        ActionCreator(TransferConstants.INITIATE_AIRTIME_TOPUP_SUCCESS)
      );
      navigateToTransfer(paymentMethod);
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(ActionCreator(TransferConstants.INITIATE_AIRTIME_TOPUP_FAILURE));
      toast.error("Error initiating airtime topup request");
    }
  };

export const walletPayment =
  (payload, navigateToTransfer) => async (dispatch) => {
    try {
      dispatch(ActionCreator(TransferConstants.MAKE_WALLET_REQUEST));
      const { data: responseData } = await makePaymentFromWallet(payload);
      if (responseData.status === "FAILED") throw responseData;

      await dispatch(ActionCreator(TransferConstants.MAKE_WALLET_SUCCESS));
      navigateToTransfer();
      toast.success("Payment submitted");
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(ActionCreator(TransferConstants.MAKE_WALLET_FAILURE));
      toast.error(error.message);
    }
  };

export const cancelTransactionPayment = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(TransferConstants.CANCEL_TRANSACTION_REQUEST));
    const { data: responseData } = await cancelTransaction(payload);
    if (responseData.status === "FAILED") throw responseData;

    await dispatch(ActionCreator(TransferConstants.CANCEL_TRANSACTION_SUCCESS));
    toast.success("Payment cancelled");
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(TransferConstants.CANCEL_TRANSACTION_FAILURE));
    toast.error(error.message);
  }
};

export const fetchPaymentMethodsNext = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(TransferConstants.FETCH_PAYMENT_METHODS_REQUEST));
    const { data: responseData } = await fetchSupportedPaymentMethods(payload);

    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        TransferConstants.FETCH_PAYMENT_METHODS_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(TransferConstants.FETCH_PAYMENT_METHODS_FAILURE));
    toast.error(error.message);
  }
};

export const fetchPaymentMethods = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(TransferConstants.FETCH_PAYMENT_METHODS_REQUEST));
    const {
      countryIsoCode3,
      currency,
      pcn,
      amount,
      payableType,
      transactionType,
    } = payload;
    const requestPayload = {
      countryIsoCode3,
      transactionType,
      currency,
      pcn,
      amount,
      payAbleType: payableType,
      appId: "1",
    };

    const { data: responseData } = await fetchSupportedPaymentMethods(
      requestPayload
    );
    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        TransferConstants.FETCH_PAYMENT_METHODS_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(TransferConstants.FETCH_PAYMENT_METHODS_FAILURE));
  }
};

export const fetchPayCentres = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(TransferConstants.FETCH_PAYCENTRE_LIST_REQUEST));
    const { data: responseData } = await listPayoutCentres();
    if (responseData.status === "FAILED") throw responseData;

    await dispatch(
      ActionCreator(
        TransferConstants.FETCH_PAYCENTRE_LIST_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(TransferConstants.FETCH_PAYCENTRE_LIST_FAILURE));
    toast.error(error.message);
  }
};
