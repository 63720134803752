import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import Body from "./Body";

import { getCountries } from "../../../../store/actions/country.action";
import { Loading } from "../../../../utils/helpers/constants";

import SphereLoader from "../../../../components/loaders/SphereLoader";

const RemittanceSettings = () => {
  const dispatch = useDispatch();

  const { domainBrand, component, country } = useSelector(
    ({ domainBrandReducer, componentReducer, countryReducer }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      country: countryReducer,
    })
  );

  const loadState = domainBrand.loading === Loading.FETCHING;

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="settings"
              active="remittance"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              currency={country.currencies}
              countries={country.countries}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default RemittanceSettings;
