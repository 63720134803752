import {useState} from "react";
import ProfileService from "../../../../../utils/services/profile.service";
import {toast} from "react-hot-toast";

const Compose = ({domainBrand, compose, switchDom}) => {

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(typeof dictionary === "undefined" || dictionary === null);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const closeComposer = () => compose(true);

  const sendMessage = async () => {
    setLoading(true);
    const payload = {subject, body};
    const {data: responseData} = await ProfileService.sendNotification(payload);
    if (responseData.status === "FAILED") toast.error(responseData.message);
    else toast.success(responseData.message);
    switchDom(true);
    setLoading(false);
  }

  return (
    <>
      <div className="col-lg-8 col-xl-9 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-header border-bottom-0">
            <h3 className="card-title">{languageStatus ? dictionary["WO_COMPOSE_MESSAG_-1485040865"] : "Compose"}</h3>
          </div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <div className="row align-items-center">
                  <label className="col-sm-2 form-label">
                    {languageStatus ? dictionary["para_subject"] : "Subject"}
                  </label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" value={subject}
                           onChange={event => setSubject(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row ">
                  <label className="col-sm-2 form-label">{languageStatus ? dictionary["l_message"] : "Message"}</label>
                  <div className="col-sm-10">
                    <textarea rows="10" className="form-control" value={body}
                              onChange={event => setBody(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer d-sm-flex">
            <div className="btn-list ms-auto">
              <button onClick={closeComposer} className="btn btn-danger btn-space" type="button">
                {languageStatus ? dictionary["button_cancel"] : "Cancel"}
              </button>
              <button onClick={sendMessage} className="btn btn-primary btn-space">
                {languageStatus ? dictionary["WO_SEND_A_MESSAG_-2043557256"] : "Send"}
                {loading ? <>&nbsp;<i className="fa fa-spin fa-spinner"/></> : ""}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Compose;



