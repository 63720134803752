import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";

import SelectWallet from "./atom/SelectWallet";
import BankTransfer from "./atom/BankTransfer";
import CardPayment from "./atom/CardPayment";
import ConfirmBankTransfer from "./atom/ConfirmBankTransfer";
import WalletPayment from "./atom/WalletPayment";
import { formatStringCurrency } from "../../../../utils/helpers/logicHelper";
import { getBaseUrl } from "../../../../utils/services/baseUrl";
import { walletPayment } from "../../../../store/actions/transfer.action";
import { supportedPaymentMap } from "../../../../utils/helpers/mappers";
import { paymentType } from "../../../../utils/helpers/constants";
import OtpModal from "../../../../components/modals/OtpModal";
import ProfileService from "../../../../utils/services/profile.service";

const Body = ({
  domainBrand,
  activeData,
  supportedMethods,
  extraData,
  supportedPaymentWallets,
  terms,
  profileData,
}) => {
  const history = useHistory(),
    dispatch = useDispatch();
  const [activeMethod, updateActiveMethod] = useState("ONLINE_CARD_PAYMENT");
  // const [activeMethod, updateActiveMethod] = useState(supportedMethods[0]?.type);
  const [activeMethodData, updateActiveMethodData] = useState(
    supportedMethods[0]
  );
  const [renderData, updateRenderData] = useState(supportedMethods[0]);
  const [selectedWallet, updateSelectedWallet] = useState({});
  const [isInsufficientFunds, updateIsInsufficientFunds] = useState(false);
  const [readyToPay, updateReadyToPay] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [otpData, setOtpData] = useState({});
  const [getPinFromChild, setGetPinFromChild] = useState("");
  const [getPasswordFromChild, setGetPasswordFromChild] = useState("");

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const disablePayButton =
    supportedMethods.length === 0 ||
    !readyToPay ||
    isInsufficientFunds ||
    (activeMethod === paymentType.E_WALLET &&
      Object.entries(selectedWallet).length === 0);
  const setWallet = (wallet) => updateSelectedWallet(wallet);
  const toggleCheckBox = () => updateReadyToPay(!readyToPay);
  const navigateToTransferDetails = () =>
    history.push({
      pathname: "/transfer/details",
      state: { data: activeData },
    });

  const updateMethod = (event, method) => {
    event.preventDefault();

    updateActiveMethod(method.type);
    updateActiveMethodData(method);
  };

  const getPaymentInfoForSelector = (muted, currency) => {
    if (typeof muted === "undefined" || muted === "" || muted === "0")
      muted = `no `;
    else if (muted % 1 !== 0)
      muted = parseFloat(muted).toFixed(2) + " " + currency + " in";
    else muted = parseFloat(muted) + " " + currency + " in";
    return muted;
  };

  const getPin = (data) => {
    const pin = data?.first + data?.second + data?.third + data?.fourth;
    setGetPinFromChild(pin);
  };

  const getPassword = (pass) => {
    setGetPasswordFromChild(pass);
  };

  const PaymentComponents = (dataObject) => ({
    ONLINE_CARD_PAYMENT: <CardPayment dataObject={dataObject} />,
    BANK_TRANSFER: <ConfirmBankTransfer dataObject={dataObject} />,
    E_WALLET: (
      <>
        <WalletPayment
          languageStatus={languageStatus}
          dictionary={dictionary}
          selectedWallet={selectedWallet}
          profileData={profileData}
          getPin={getPin}
          getPassword={getPassword}
        />
      </>
    ),
  });

  const makeCardPayment = () => {
    const domainUrl = getBaseUrl();
    const originUrl = window.location.origin;
    const payableType = activeData["payableType"];
    const redirectUrl = `&callbackurl=${originUrl}/transfer/details?payabletype=${payableType}`;
    const finalLink = activeMethodData["hosted_gateway_url"];
    window.location.href = `${domainUrl}${finalLink}${redirectUrl}`;
  };

  const baseUrl = getBaseUrl();

  const makeWalletPayment = async () => {
    updateLoading(true);
    const { parameters } = activeMethodData;
    const data = {
      payAbleId: activeData.payAbleId,
      accountNumber: selectedWallet["walletAccountNumber"],
      paymentAccountNumbeCurrencyCode: renderData["sendingCurrency"],
      paymentGatewayRef: parameters.paymentGatewayRef,
      payableType: parameters.payableType,
      password: "password",
      totalCheckOutAmount: parseFloat(renderData["total-amount"]),
    };

    if (
      activeMethod === "E_WALLET" &&
      profileData["strongCustomerAuthenticationEnabled"] === true &&
      baseUrl === "https://test.moneytransferapplication.uk" &&
      profileData["pinEnabled"] !== true
    ) {
      const otpPayload = {
        passWord: "password",
        currency: renderData["sendingCurrency"],
        amount: renderData["total-amount"].toString(),
      };
      const response = await ProfileService.generateUserOtp(otpPayload);
      if (response.data.status === "FAILED") {
        toast.error(
          response.data.message !== null ? response.data.message : "FAILED"
        );
      } else {
        // opens modal for otp
        setShow(true);
        setOtpData({
          ...data,
          password: getPasswordFromChild,
          // pin: getPinFromChild,
        });
      }
      updateLoading(false);
    } else if (
      activeMethod === "E_WALLET" &&
      baseUrl === "https://test.moneytransferapplication.uk" &&
      profileData["pinEnabled"] === true &&
      profileData["strongCustomerAuthenticationEnabled"] !== true
    ) {
      const payload = { ...data, pin: getPinFromChild };
      await dispatch(walletPayment(payload, navigateToTransferDetails));
      updateLoading(false);
    } else if (
      profileData["strongCustomerAuthenticationEnabled"] === true &&
      activeMethod === "E_WALLET" &&
      baseUrl === "https://test.moneytransferapplication.uk" &&
      profileData["pinEnabled"] === true
    ) {
      const otpPayload = {
        passWord: "password",
        currency: renderData["sendingCurrency"],
        amount: renderData["total-amount"].toString(),
      };
      const response = await ProfileService.generateUserOtp(otpPayload);
      if (response.data.status === "FAILED") {
        toast.error(
          response.data.message !== null ? response.data.message : "FAILED"
        );
      } else {
        // opens modal for otp
        setShow(true);
        setOtpData({
          ...data,
          password: getPasswordFromChild,
          pin: getPinFromChild,
        });
      }
      updateLoading(false);
    } else {
      await dispatch(walletPayment(data, navigateToTransferDetails));
      updateLoading(false);
    }
    // await dispatch(walletPayment(data, navigateToTransferDetails));
    updateLoading(false);
  };

  const buttonMethod = {
    ONLINE_CARD_PAYMENT: makeCardPayment,
    E_WALLET: makeWalletPayment,
    BANK_TRANSFER: navigateToTransferDetails,
  };

  const updateWalletData = async (method, wallet) => {
    if (!wallet["sufficient-fund"]) {
      updateIsInsufficientFunds(true);
      toast.error(
        "Insufficient balance in wallet to continue this transaction"
      );
    } else updateIsInsufficientFunds(false);
    const activeData = {
      ...activeMethodData,
      "total-amount": wallet["total-payable-item-amount"],
      sendingCurrency: wallet["walletAccountCurrency"],
    };
    updateRenderData(activeData);
  };

  return (
    <>
      <OtpModal
        show={show}
        handleClose={() => setShow(false)}
        activeData={activeData}
        paymentData={otpData}
      />
      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">Payment Methods</h1>
            </div>
            <div className="ms-auto pageheader-btn" />
          </div>
          <div className="row">
            {/* LEFT */}
            <div className="col-xl-7 col-md-7">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">Payment Information</h3>
                </div>
                <div className="card-body">
                  {supportedMethods.length === 0 ? (
                    <h6> No Supported Payment method found.</h6>
                  ) : (
                    ""
                  )}
                  <div className="main-chat-list tab-pane">
                    {supportedMethods.map((item, index) => {
                      return (
                        <div key={index}>
                          <div
                            onClick={(event) => {
                              updateMethod(event, item);
                              updateActiveMethodData(item);
                            }}
                            className={
                              activeMethod === item.type
                                ? "media selected"
                                : "media"
                            }
                          >
                            <div className="main-img-user main-img-user-icon online">
                              <img
                                alt=""
                                src={supportedPaymentMap[item.type].image}
                              />
                            </div>
                            <div className="media-body">
                              <div className="media-contact-name">
                                <span>
                                  {languageStatus
                                    ? dictionary[
                                        supportedPaymentMap[item.type]
                                          .lingualText
                                      ]
                                    : [supportedPaymentMap[item.type].text]}
                                </span>
                                <span />
                              </div>
                              {item["supported-payment-accounts"] !==
                                undefined &&
                              item["supported-payment-accounts"].length ===
                                0 ? (
                                <p>You do not have an account</p>
                              ) : (
                                <p>
                                  <span>
                                    {[
                                      supportedPaymentMap[item.type].notePrefix,
                                    ]}{" "}
                                  </span>
                                  <span>
                                    {getPaymentInfoForSelector(
                                      item["payment-method-fee"],
                                      extraData["principal_amount_currency"]
                                    )}{" "}
                                  </span>
                                  payment fees
                                </p>
                              )}
                            </div>
                          </div>
                          {item.type === "E_WALLET" &&
                          activeMethod === "E_WALLET" ? (
                            <div
                              key={index + 1}
                              className={
                                activeMethod === item.type
                                  ? "media selected"
                                  : "media"
                              }
                            >
                              <div className="media-body">
                                <SelectWallet
                                  languageStatus={languageStatus}
                                  dictionary={dictionary}
                                  wallets={supportedPaymentWallets}
                                  selectedWallet={setWallet}
                                  updateWalletData={updateWalletData}
                                />
                              </div>
                            </div>
                          ) : item.type === "BANK_TRANSFER" &&
                            activeMethod === "BANK_TRANSFER" ? (
                            <div
                              className={
                                activeMethod === item.type
                                  ? "media selected"
                                  : "media"
                              }
                            >
                              <div className="media-body">
                                <BankTransfer
                                  languageStatus={languageStatus}
                                  dictionary={dictionary}
                                  dataObject={activeMethodData}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                    <div className="" />
                  </div>
                </div>
              </div>
            </div>

            {/* RIGHT */}
            <div className="col-xl-5 col-md-5">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">
                    Confirm Payment for {activeData["pcn"]}
                  </h3>
                </div>
                {supportedMethods.length < 1 ? (
                  <div className="card-body">
                    No Supported Payment Method Added.
                  </div>
                ) : (
                  <>
                    <div className="card-body">
                      <table className="table mt-3">
                        <tbody>
                          <tr>
                            <td className="border-top-0">
                              {languageStatus
                                ? dictionary["para_sending_amount"]
                                : "Sending Amount"}
                            </td>
                            <td className="text-end fw-bold border-top-0">
                              {`${formatStringCurrency(
                                extraData["principal_amount"]
                              )} ${
                                extraData["principal_amount_currency"]
                                  ? extraData["principal_amount_currency"]
                                  : extraData["currency"]
                              }`}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-top-0">
                              {languageStatus
                                ? dictionary["para_payment_fee"]
                                : "Payment Fee"}
                            </td>
                            {/* renderData["payment-method-fee"] */}
                            <td className="text-end fw-bold border-top-0">
                              {`${formatStringCurrency(
                                activeMethodData["payment-method-fee"]
                              )} ${
                                extraData["principal_amount_currency"]
                                  ? extraData["principal_amount_currency"]
                                  : extraData["currency"]
                              }`}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-top-0">
                              {languageStatus ? dictionary["l_vat"] : "Tax"}
                            </td>
                            <td className="text-end fw-bold border-top-0">
                              {`${formatStringCurrency(renderData["tax"])} ${
                                extraData["principal_amount_currency"]
                                  ? extraData["principal_amount_currency"]
                                  : extraData["currency"]
                              }`}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-top-0">
                              {languageStatus
                                ? dictionary["para_commission"]
                                : "Commission"}
                            </td>
                            <td className="text-end fw-bold border-top-0">
                              {`${formatStringCurrency(
                                renderData["commission"]
                              )} ${
                                extraData["principal_amount_currency"] ||
                                extraData["currency"]
                              }`}
                            </td>
                          </tr>
                          {extraData["fx_rate"] ? (
                            <tr>
                              <td className="border-top-0">
                                {languageStatus
                                  ? dictionary["l_exchangerate"]
                                  : "Rate"}
                              </td>
                              <td className="text-end fw-bold border-top-0">
                                {`1.00 ${extraData.currency} = ${
                                  extraData["fx_rate"]
                                } ${
                                  extraData["derive_amount_currency"] ||
                                  extraData["currency"]
                                }`}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          <tr>
                            <td className="border-top-0">
                              {languageStatus
                                ? dictionary["para_your_beneficiary_receives"]
                                : "Your beneficiary receives"}
                            </td>
                            <td className="text-end fw-bold border-top-0">
                              {`${formatStringCurrency(
                                extraData["derive_amount"]
                              )} ${
                                extraData["derive_amount_currency"] ||
                                extraData["currency"]
                              }`}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-top-0">
                              {languageStatus
                                ? dictionary["para_total_to_pay"]
                                : "Total to pay"}
                            </td>
                            <td className="text-end fs-12 fw-bold border-top-0 d-flex flex-row-reverse align-items-center justify-centent-end">
                              <span className="d-flex flex-row d-md-flex flex-md-row">
                                {`${formatStringCurrency(
                                  activeMethodData["total-amount"]
                                )} ${
                                  extraData["principal_amount_currency"] ||
                                  extraData["currency"]
                                }`}
                              </span>
                              {/* <br /> */}
                              {activeMethod === "E_WALLET" &&
                              Object.entries(selectedWallet).length > 0 &&
                              extraData["principal_amount_currency"] !==
                                renderData["sendingCurrency"] ? (
                                <>
                                  <i className="fa fa-exchange-alt mx-1" />
                                  <span className="d-flex flex-row d-md-flex flex-md-row">{`${formatStringCurrency(
                                    renderData["total-amount"]
                                  )} ${renderData["sendingCurrency"]}`}</span>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer">
                      {PaymentComponents(activeMethodData)[activeMethod]}
                      <div className="text-center">
                        <div className="m-0 temsAndCondition">
                          <input
                            type={"checkbox"}
                            className="pt-2"
                            defaultChecked={readyToPay}
                            onChange={toggleCheckBox}
                          />
                          <span className="pointer hover-underline fs-12 align-top mx-2">
                            <a
                              href={`${terms}`}
                              target="_blank"
                              rel="noreferrer"
                              className="fs-12 pointer termsUrl"
                            >
                              {languageStatus
                                ? dictionary[
                                    "WO_BY_CHECKING_THIS_BOX_711574489"
                                  ]
                                : "Agree to terms and conditions"}{" "}
                              transaction.
                            </a>
                          </span>
                        </div>

                        {isInsufficientFunds &&
                        domainBrand.serviceControl.wallet ? (
                          <button
                            onClick={() => history.push("/fund-wallet")}
                            className="btn btn-danger m-3 text-center vertical-middle"
                            type="button"
                          >
                            Top up Wallet Account
                          </button>
                        ) : (
                          ""
                        )}
                        <button
                          disabled={disablePayButton}
                          onClick={buttonMethod[activeMethod]}
                          className="btn btn-primary m-3 text-center vertical-middle"
                          type="button"
                        >
                          {languageStatus
                            ? dictionary["WO_CLICK_HERE_TO_CONTIN_297369385"]
                            : "Click here to continue"}
                          {loading ? (
                            <>
                              &nbsp;
                              <i className="fa fa-spin fa-spinner" />
                            </>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
