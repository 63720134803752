export const GET_KYC_RULES_REQUEST = "GET_KYC_RULES_REQUEST";
export const GET_KYC_RULES_SUCCESS = "GET_KYC_RULES_SUCCESS";
export const GET_KYC_RULES_FAILURE = "GET_KYC_RULES_FAILURE";

export const GET_AML_RULES_REQUEST = "GET_AML_RULES_REQUEST";
export const GET_AML_RULES_SUCCESS = "GET_AML_RULES_SUCCESS";
export const GET_AML_RULES_FAILURE = "GET_AML_RULES_FAILURE";

export const GET_AWAITING_REQUIREMENTS_REQUEST =
  "GET_AWAITING_REQUIREMENTS_REQUEST";
export const GET_AWAITING_REQUIREMENTS_SUCCESS =
  "GET_AWAITING_REQUIREMENTS_SUCCESS";
export const GET_AWAITING_REQUIREMENTS_FAILURE =
  "GET_AWAITING_REQUIREMENTS_FAILURE";

export const GET_REQUIRED_COMPLIANCE_FIELDS_REQUEST =
  "GET_REQUIRED_COMPLIANCE_FIELDS_REQUEST";
export const GET_REQUIRED_COMPLIANCE_FIELDS_SUCCESS =
  "GET_REQUIRED_COMPLIANCE_FIELDS_SUCCESS";
export const GET_REQUIRED_COMPLIANCE_FIELDS_FAILURE =
  "GET_REQUIRED_COMPLIANCE_FIELDS_FAILURE";

export const CREATE_AML_RULES_REQUEST = "CREATE_AML_RULES_REQUEST";
export const CREATE_AML_RULES_SUCCESS = "CREATE_AML_RULES_SUCCESS";
export const CREATE_AML_RULES_FAILURE = "CREATE_AML_RULES_FAILURE";
