import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import CustomDatePicker from "../../../components/datePickers/CustomDatePicker";
import { signUpValidator } from "../../../utils/helpers/validationHelpers";
import { customerTypes } from "../../../utils/helpers/constants";

const NameFields = ({
  deliverables,
  properties,
  setInitialDomState,
  countriesProps,
  formikData,
}) => {
  const { languageStatus, dictionary, customerTypesDropdown } = deliverables;
  const { visibleFields, mandatoryFields, namesFieldsData } = properties;
  const { phoneCodes } = countriesProps;
  const menuPortalTarget = document.getElementById("root");
  const [dateOfBirth, getDOB] = useState("");
  const [passwordType, updatePasswordType] = useState("password");
  const [confirmPasswordType, updateConfirmPasswordType] = useState("password");
  const [onSubmitClick, setOnSubmitClick] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);

  const initialValues = {
    type: customerTypesDropdown[0],
    companyName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneCountryCode: "",
    phone: "",
    companyRegistrationNumber: "",
    password: "",
    confirmPassword: "",
  };

  const initialValidation = signUpValidator(mandatoryFields);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(initialValidation),
    onSubmit: () => {},
  });

  useEffect(() => {
    if (Object.entries(namesFieldsData).length > 0)
      formik.setValues({ ...namesFieldsData });
  }, [namesFieldsData]);

  const togglePasswordField = (type) => {
    updatePasswordType(type);
    document.getElementById("password").type = type;
  };

  const toggleConfirmPasswordField = (type) => {
    updateConfirmPasswordType(type);
    document.getElementById("confirmPassword").type = type;
  };

  const savePersonalDetails = () => {
    setOnSubmitClick(true);
    if (formik.values.password !== formik.values.confirmPassword)
      setPasswordNotMatch(true);
    else {
      const data = { ...formik.values, dateOfBirth };
      formikData(data);
      setInitialDomState(false);
    }
  };

  const disablePhoneInput =
    typeof formik.values.phoneCountryCode.value === "undefined" ||
    formik.values.phoneCountryCode.value === "";
  const disableNextButton =
    formik.values.password === "" ||
    formik.values.phone === "" ||
    dateOfBirth === "";

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <form>
          <div className="form-row">
            <div
              className="col-xl-12 col-lg-12 col-md-12 mb-3"
              id="customer-type"
            >
              <label>
                {languageStatus ? dictionary["l_customer_type"] : "Type"}
              </label>
              <Select
                options={customerTypesDropdown}
                isClearable={false}
                isSearchable={true}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                name="type"
                value={formik.values.type}
                onChange={(option) => formik.setFieldValue("type", option)}
              />
            </div>

            {formik.values.type.value === customerTypes.COMPANY ? (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["WO_COMPANY_NAM_-805761030"]
                    : "Company Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={formik.values.companyName}
                  className={`form-control ${
                    formik.errors["companyName"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["companyName"] ? (
                  <div className="invalid-feedback">
                    Company Name is Required
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_first_name"]
                    : "First Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  className={`form-control ${
                    formik.errors["firstName"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["firstName"] ? (
                  <div className="invalid-feedback">First Name is Required</div>
                ) : (
                  ""
                )}
              </div>
            )}

            {formik.values.type.value === customerTypes.COMPANY ? (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["WO_REPRESENTATIVE_FIRST_797939742"]
                    : "First Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formik.values.firstName}
                  className={`form-control ${
                    formik.errors["firstName"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["firstName"] ? (
                  <div className="invalid-feedback">First Name is Required</div>
                ) : (
                  ""
                )}
              </div>
            ) : !visibleFields.some((o) => o["fieldType"] === "MIDDLE_NAME") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["WO_MIDDLE_NAM_1847397348"]
                    : "Middle Name"}
                  {mandatoryFields.some(
                    (o) => o["fieldType"] === "MIDDLE_NAME"
                  ) ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="middleName"
                  value={formik.values.middleName}
                  className={`form-control ${
                    formik.errors["middleName"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["middleName"] ? (
                  <div className="invalid-feedback">
                    Middle Name is Required
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            {formik.values.type.value === customerTypes.COMPANY ? (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["WO_REPRESENTATIVE_LAST_-1662581052"]
                    : "Last Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formik.values.lastName}
                  className={`form-control ${
                    formik.errors["lastName"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["lastName"] ? (
                  <div className="invalid-feedback">Last Name is Required</div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus ? dictionary["para_last_name"] : "Last Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={formik.values.lastName}
                  className={`form-control ${
                    formik.errors["lastName"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["lastName"] ? (
                  <div className="invalid-feedback">Last Name is Required</div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "EMAIL") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus ? dictionary["l_email"] : "Email"}
                  {mandatoryFields.some((o) => o["fieldType"] === "EMAIL") ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={formik.values.email}
                  className={`form-control ${
                    formik.errors["email"] && onSubmitClick ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["email"] ? (
                  <div className="invalid-feedback">
                    Email address is Required
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "PHONE") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label id="phoneNumber">
                  {languageStatus
                    ? dictionary["para_phone_number"]
                    : "Phone Number"}
                  {mandatoryFields.some((o) => o["fieldType"] === "PHONE") ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
                <div className="form-row">
                  <div className="col-md-5 col-lg-5 mb-3">
                    <Select
                      options={phoneCodes}
                      isClearable={false}
                      maxMenuHeight={250}
                      menuPlacement="bottom"
                      menuPortalTarget={menuPortalTarget}
                      value={formik.values.phoneCountryCode}
                      name="phoneCountryCode"
                      onChange={(option) =>
                        formik.setFieldValue("phoneCountryCode", option)
                      }
                    />
                  </div>
                  <div className="col-md-7 col-lg-7 mb-3" id="phoneContainer">
                    <input
                      disabled={disablePhoneInput}
                      type="text"
                      name="phone"
                      id="phoneField"
                      value={formik.values.phone}
                      className={`form-control ${
                        formik.errors["phone"] && onSubmitClick
                          ? "is-invalid"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      required
                    />
                    {formik.errors["phone"] ? (
                      <div className="invalid-feedback">
                        Phone number is Required
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "DOB") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                {formik.values.type.value === customerTypes.COMPANY ? (
                  <label>
                    {languageStatus
                      ? dictionary["WO_REPRESENTATIVE_DATE_703049064"]
                      : "DOB"}
                    {mandatoryFields.some((o) => o["fieldType"] === "DOB") ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : (
                      ""
                    )}
                  </label>
                ) : (
                  <label>
                    {languageStatus ? dictionary["para_date_of_birth"] : "DOB"}
                    {mandatoryFields.some((o) => o["fieldType"] === "DOB") ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : (
                      ""
                    )}
                  </label>
                )}
                <CustomDatePicker
                  getDate={getDOB}
                  showYearDropdown
                  selectDropdownMode
                  withPortal
                  setMaxDate={new Date()}
                />
              </div>
            )}

            {formik.values.type.value === customerTypes.COMPANY ? (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["l_registration_no"]
                    : "Reg Number"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  name="companyRegistrationNumber"
                  className={`form-control ${
                    formik.errors["companyRegistrationNumber"] && onSubmitClick
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["companyRegistrationNumber"] ? (
                  <div className="invalid-feedback">Required</div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
              <label>
                {languageStatus ? dictionary["param_password"] : "Password"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                value={formik.values.password}
                onChange={formik.handleChange}
                required
              />
              <div className="eye-right sign-up">
                {passwordType === "password" ? (
                  <i
                    onClick={() => togglePasswordField("text")}
                    className="fa fa-eye"
                  />
                ) : (
                  <i
                    onClick={() => togglePasswordField("password")}
                    className="fa fa-eye-slash"
                  />
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
              <label>
                {languageStatus
                  ? dictionary["para_confirm_password"]
                  : "Confirm Password"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={formik.values.confirmPassword}
                className={`form-control ${
                  passwordNotMatch && onSubmitClick ? "is-invalid" : ""
                }`}
                onChange={formik.handleChange}
                required
              />
              <div className="eye-right sign-up">
                {confirmPasswordType === "password" ? (
                  <i
                    onClick={() => toggleConfirmPasswordField("text")}
                    className="fa fa-eye"
                  />
                ) : (
                  <i
                    onClick={() => toggleConfirmPasswordField("password")}
                    className="fa fa-eye-slash"
                  />
                )}
              </div>
              {passwordNotMatch ? (
                <div className="invalid-feedback">Passwords not not match</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
              <button
                disabled={disableNextButton}
                onClick={savePersonalDetails}
                className="btn btn-primary mt-3 m-0-auto justify-content-center width-100"
                type="button"
              >
                {" "}
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NameFields;
