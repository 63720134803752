import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Body from "./Body";
import Footer from "../../../../components/footer/Footer";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import {Loading} from "../../../../utils/helpers/constants";
import {getItemsInCart} from "../../../../store/actions/merchant.action";

const Cart = () => {
  const dispatch = useDispatch();
  const {domainBrand, component, merchant, profile} = useSelector(
    ({domainBrandReducer, componentReducer, countryReducer, merchantReducer, profileReducer}) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      merchant: merchantReducer,
      profile: profileReducer,
    }));

  const loadState = domainBrand.loading === Loading.FETCHING || merchant.loadingCart === Loading.FETCHING;

  useEffect(() => {
    dispatch(getItemsInCart());
  }, []);


  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? <SphereLoader/> :
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"/>
            <Header domainBrand={domainBrand}/>
            <HeaderMenu menu="merchant" active="cart" domainBrand={domainBrand}/>
            <Body domainBrand={domainBrand} cart={merchant.cart} profile={profile}/>
          </div>
          <Footer domainBrand={domainBrand}/>
        </>
      }
    </div>
  );
}

export default Cart;
