/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../../components/footer/Footer";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { Loading } from "../../../../utils/helpers/constants";
import Body from "./Body";

function CashierBalance() {
  const dispatch = useDispatch();
  const { component, domainBrand } = useSelector(
    ({ componentReducer, domainBrandReducer }) => ({
      component: componentReducer,
      domainBrand: domainBrandReducer,
    })
  );

  const loadState = domainBrand.loading === Loading.FETCHING;

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="reports"
              active="reports"
              domainBrand={domainBrand}
            />
            <Body domainBrand={domainBrand} />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
}

export default CashierBalance;
