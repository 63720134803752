import { useMemo, useState } from "react";
import DataTable from "../../../../components/dataTable/dataTable";
// import { Loading } from "../../../../utils/helpers/constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toast from "react-hot-toast";
import { ReactComponent as Edit } from "../../../../assets/images/svgs/edit.svg";
import { ReactComponent as Lock } from "../../../../assets/images/svgs/lock.svg";
import AddNewEmployeeModal from "../../../../components/modals/AddNewEmployeeModal";
import EditEmployeeModal from "../../../../components/modals/EditEmployeeModal";
import SuspendModal from "../../../../components/modals/SuspendModal";
import { employeeTableConfig } from "../../../../utils/helpers/dataTableConfigs";
import { suspendEmployee } from "../../../../utils/services/employees.service";

const Body = ({ employees = [], info, currency, countries, domainBrand }) => {
  const [filterInput] = useState("");
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [employeeObject, setEmployeeObject] = useState({});
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newEmployee, setNewEmployee] = useState(false);
  const [role, setRole] = useState("");

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 5,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-center pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Edit</Tooltip>}
          >
            <Edit
              onClick={() => {
                setShow(true);
                setId(row?.original?.userId);
                setEmployeeObject(row?.original);
              }}
            />
          </OverlayTrigger>

          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Suspend</Tooltip>}
          >
            <Lock
              onClick={() => {
                setType("SUSPEND-EMPLOYEE");
                setId(row?.original?.userId);
                setOpen(true);
                setEmployeeObject(row?.original);
              }}
            />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...employeeTableConfig, actionColumn];
  const columns = useMemo(() => tableObject, []);
  // const handleFilterChange = (e) => setFilterInput(e.target.value);

  const handleSuspend = async (id) => {
    setLoading(true);
    try {
      const response = await suspendEmployee(id);

      if (response.data.status !== "FAILED") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      setLoading(false);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      setOpen(false);
      return error;
    }
    setLoading(false);
  };

  return (
    <>
      <SuspendModal
        type={type}
        handleSuspend={() => handleSuspend(id)}
        show={open}
        handleClose={() => [setOpen(false)]}
        employee={{
          firstName: employeeObject?.firstName,
          lastName: employeeObject.lastName,
        }}
        loading={loading}
      />
      <EditEmployeeModal
        show={show}
        handleClose={() => setShow(false)}
        countries={countries?.countries}
        domainBrand={domainBrand}
        employeeObject={employeeObject}
      />
      <AddNewEmployeeModal
        show={newEmployee}
        handleClose={() => setNewEmployee(false)}
        domainBrand={domainBrand}
        countries={countries}
        loading={loading}
        type={role}
      />
      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">Employees</h1>
            </div>
            <div className="ms-auto pageheader-btn" />
          </div>

          <>
            <div className="row row-sm">
              <div className="col-lg-12">
                <div className="card overflow-hidden">
                  <div className="card-header bg-primary text-white">
                    <h3 className="card-title">
                      Create new users here, new user types can be editor,
                      auditor etc. You can also edit the registered user details
                      here.
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="form-row mb-5 pb-5">
                      <div className="col-md-6 col-lg-6">
                        <button
                          className="btn btn-primary w-100 fw-bold"
                          type="button"
                          // onClick={() => history.push("/addNewEmployee")}
                          onClick={() => {
                            setNewEmployee(true);
                            setRole("");
                          }}
                        >
                          ADD NEW EMPLOYEE
                        </button>
                      </div>

                      <div className="col-md-6 col-lg-6">
                        <button
                          className="btn btn-primary w-100 fw-bold"
                          type="button"
                          onClick={() => {
                            setNewEmployee(true);
                            setRole("new-role");
                          }}
                        >
                          ADD OTHER STAFF ROLES
                        </button>
                      </div>
                    </div>
                    {/* STAF ROLES FORM GOES HERE */}
                    <div className="form-row"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={employees}
                        filterData={filterInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Body;
