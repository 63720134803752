import Select from "react-select";
import {dynamicFieldsDropdown} from "../../utils/helpers/logicHelper";
import {bankPairs} from "../../utils/helpers/constants";

const InputField = ({eachField, formik}) => {
  const key = eachField["apiName"];
  return <>
    <div className="col-md-12 mb-3">
      <label>{eachField["label"]}</label>
      <input type={`${eachField["validation"] === "numeric" ? "number" : "text"}`}
             className="form-control"
             name={key}
             minLength={eachField["minLength"]}
             maxLength={eachField["maxLength"]}
             onChange={formik.handleChange}
             required
      />
    </div>
  </>
}

const DropDownField = ({eachField, formik}) => {
  const dropdownValues = dynamicFieldsDropdown(eachField["dropDownValues"]);
  const key = eachField["apiName"];
  const menuPortalTarget = document.getElementById('root');
  return <>
    <div className="col-md-12 mb-3">
      <label>{eachField["label"]}</label>
      <Select options={dropdownValues}
              isClearable={false}
              isSearchable={false}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              name={key}
              onChange={option => formik.setFieldValue(key, option)}
      />
    </div>
  </>
}

const BankFields = ({bankFields, formik}) => {
  const filteredBankFields = bankFields.filter(forEach => {
    return forEach["apiName"] !== bankPairs["bankName"] && forEach["apiName"] !== "country"
      && forEach["apiName"] !== bankPairs["recipientType"];
  });

  const renderBankFields = filteredBankFields.map((eachField, index) => {
    if (eachField.inputType === "DROP_DOWN") return <DropDownField key={index} eachField={eachField} formik={formik}/>;
    else return <InputField key={index} eachField={eachField} formik={formik}/>;
  });

  return (
    <div className="form-row">
      {renderBankFields}
    </div>
  );
}

export default BankFields;
