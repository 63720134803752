import { toast } from "react-hot-toast";
import * as ProductConstants from "../constants/product.constant";

import ActionCreator from "../../utils/helpers/actionCreator";
import { expiredTokenCheck } from "./auth.action";

import {
  createAccountProduct,
  listAccountProduct,
} from "../../utils/services/product.service";

import { Loading } from "../../utils/helpers/constants";

export const createProduct = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(ProductConstants.CREATE_PRODUCT_ACCOUNT_REQUEST));

    const { data: responseData } = await createAccountProduct(payload);

    if (responseData.status !== Loading.SUCCESS) throw responseData;

    dispatch(
      ActionCreator(
        ProductConstants.CREATE_PRODUCT_ACCOUNT_SUCCESS,
        responseData
      )
    );

    toast.success("Product created successfully.");
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(ProductConstants.CREATE_PRODUCT_ACCOUNT_FAILURE));

    toast.error("Invalid");
  }
};

export const getProductsList = (productObject) => async (dispatch) => {
  try {
    dispatch(ActionCreator(ProductConstants.GET_PRODUCT_ACCOUNT_REQUEST));

    const { data: responseData } = await listAccountProduct(productObject);

    // if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        ProductConstants.GET_PRODUCT_ACCOUNT_SUCCESS,
        responseData.data
      )
    );

    toast.success("Products fetched successfully.");
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ProductConstants.GET_PRODUCT_ACCOUNT_FAILURE);
  }
};
