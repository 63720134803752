import {useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {toast} from "react-hot-toast";
import Select from "react-select";

import fallbackImage from "../../../../assets/images/2.jpg";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import CountriesDataTable from "../../../../components/dataTable/countries";

import {getQuoteData, initiateBillsPayment} from "../../../../store/actions/transfer.action";
import {
  formatBillOperators, formatBillProducts, formatBillServices, formatStringCurrency, navigatePageTo
} from "../../../../utils/helpers/logicHelper";
import {
  fetchCountryServices, fetchProductsByOperator, fetchServiceOperators
} from "../../../../utils/services/transfer.service";
import {Loading} from "../../../../utils/helpers/constants";

const Body = ({domainBrand, countries, profile, quoteObject, quoteData}) => {

  const dispatch = useDispatch(), history = useHistory();
  const menuPortalTarget = document.getElementById('root');
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(typeof dictionary === "undefined" || dictionary === null);
  const sendingCurrency = profile.defaultCurrency;
  const [productMessage, updateProductMessage] = useState("Select a country to find a service");
  const [country, updateCountry] = useState("");
  const [service, updateService] = useState("");
  const [operator, updateOperator] = useState("");
  const [refNo, updateRefNo] = useState("");
  const [description, updateDescription] = useState("");
  const [services, updateServices] = useState([]);
  const [serviceOperators, updateServiceOperators] = useState([]);
  const [products, updateProducts] = useState([]);
  const [product, updateProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingService, setLoadingService] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const selectCountry = async option => {
    setLoading(true);
    updateCountry(option);
    const {data: responseData} = await fetchCountryServices(option.value);
    if (responseData.status !== Loading.SUCCESS) toast.error(`${responseData.message}`);
    else {
      const formattedTopup = formatBillServices(responseData.data["serviceDTO"]);
      updateServices(formattedTopup);
    }
    setLoading(false);
  }

  const selectService = async option => {
    setLoadingService(true);
    updateService(option);
    const {data: responseData} = await fetchServiceOperators(country.value, option.value);
    if (responseData.status !== Loading.SUCCESS) toast.error(`${responseData.message}`);
    else {
      const formattedTopup = formatBillOperators(responseData.data["providerDTO"]);
      updateServiceOperators(formattedTopup);
    }
    setLoadingService(false);
  }

  const selectedOperator = async (operator) => {
    setLoadingProducts(true);
    updateProduct("");
    updateOperator(operator);
    const {data: responseData} = await fetchProductsByOperator(country.value, service.value, operator["providerId"]);
    if (responseData.status !== Loading.SUCCESS) toast.error(`${responseData.message}`);
    else {
      const formattedTopup = formatBillProducts(responseData.data["productDTO"]);
      if (formattedTopup.length === 0) updateProductMessage("No Products available");
      updateProducts(formattedTopup);
    }
    setLoadingProducts(false);
  }

  const selectedProduct = async product => {
    updateProduct(product);
    const quoteData = {
      ...quoteObject,
      principalAmount: product["product_value"],
      principalAmountCurrency: profile.defaultCurrency,
      deriveAmount: product["product_value"],
      deriveAmountCurrency: product["product_currency"],
    };
    dispatch(getQuoteData(quoteData));
  }

  const payBill = async () => {
    setPaymentLoading(true);
    const localCurrency = sendingCurrency === product["account_currency"];
    const payload = {
      localCurrency,
      payer_currency: sendingCurrency,
      payer_value: product["product_value"],
      product_currency: product["account_currency"],
      product_id: product["product_id"],
      product_value: product["product_value"],
      service_id: service["service_id"],
      operator_id: operator["value"],
      bill_payment_destination_iso: country.value,
      payment_reference_number: refNo,
      sender_sms_text: description,
      sender_code: profile.userData["customerCode"]
    };
    const paymentMethodOptions = {
      sendAmount: product["product_value"],
      originCountry: profile.userData?.address.countryIso3,
      receivingCountry: country.value,
      sendingCurrency: quoteData.sendingCurrency,
      receivingCurrency: quoteData.receivingCurrency,
      transactionType: quoteObject.transactionType
    };

    const navigator = navigatePageTo(history, "/transfer/choose-payment-method");
    await dispatch(initiateBillsPayment(payload, navigator, paymentMethodOptions));
    setPaymentLoading(false);
  }

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">{languageStatus ? dictionary["WO_BILL_PAYMEN_-1233806888"] : "Bill Payment"}</h1>
          </div>
          <div className="ms-auto pageheader-btn"/>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">{languageStatus ? dictionary["l_bill_information"] : "Bill Information"}</h3>
              </div>
              <div className="card-body">
                <div className="col-12 d-none d-md-block d-xl-block">
                  <CountriesDataTable countries={countries} selectCountry={selectCountry}/>
                </div>
                <div className="form-row">
                  <div className="col-md-12 d-lg-none d-xl-none mb-3">
                    <label>{languageStatus ? dictionary["l_destination_country"] : "Receiving Country"}</label>
                    <Select options={countries}
                            isClearable={false}
                            isSearchable={true}
                            maxMenuHeight={250}
                            menuPlacement="bottom"
                            menuPortalTarget={menuPortalTarget}
                            name="country"
                            value={country}
                            isLoading={loading}
                            onChange={option => selectCountry(option)}/>
                  </div>
                  {country && !loading ?
                    <div className="col-md-12 mb-3">
                      <label>{languageStatus ? dictionary["WO_PLEASE_PICK_A_SERVIC_1559624811"] : "Service"}</label>
                      <Select options={services}
                              isClearable={false}
                              isSearchable={false}
                              maxMenuHeight={250}
                              menuPlacement="bottom"
                              menuPortalTarget={menuPortalTarget}
                              name="service"
                              value={service}
                              isLoading={loadingService}
                              onChange={option => selectService(option)}/>
                    </div> : ""
                  }
                </div>
              </div>
            </div>
            {service && !loadingService ?
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">{languageStatus ? dictionary["l_select_operator"] : "Operators"}</h3>
                </div>
                <div className="card-body">
                  <div className="list-unstyled row">
                    {serviceOperators.map((item, index) => (
                      <div key={index} onClick={() => selectedOperator(item)}
                           className="col-md-2 col-xl-2 d-none d-md-block d-xl-block mb-2">
                        <img className="avatar avatar-xl bradius cover-image d-block mb-1 width-100 pointer"
                             src={`https://test.moneytransferapplication.uk/${item["logo"]}`}
                             onError={e => e.target.src = fallbackImage} alt={item["provider"]}/>
                        <h6 className="fs-8px text-center">{item["provider"]}</h6>
                      </div>
                    ))}
                  </div>
                  <div className="form-row">
                    <div className="col-sm-12 d-lg-none d-xl-none mb-3">
                      <Select options={serviceOperators}
                              isClearable={false}
                              isSearchable={false}
                              maxMenuHeight={250}
                              menuPlacement="bottom"
                              menuPortalTarget={menuPortalTarget}
                              name="operator"
                              value={operator}
                              isLoading={loadingService}
                              onChange={option => selectedOperator(option)}/>
                    </div>
                  </div>
                </div>
              </div> : ""
            }
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus ? dictionary["WO_PRODUCT_INFORMATIO_-1728631634"] : "Product"}
                </h3>
              </div>
              <div className="card-body">
                {loadingProducts ? <SphereLoader/> :
                  <>
                    {products.length < 1 ?
                      <p className="text-muted">{productMessage}</p> :
                      <>
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label>{languageStatus ? dictionary["WO_SELECT_PRODUC_-1243389070"] : "Products"}</label>
                            <Select options={products}
                                    isClearable={false}
                                    isSearchable={true}
                                    maxMenuHeight={250}
                                    menuPlacement="bottom"
                                    menuPortalTarget={menuPortalTarget}
                                    name="product"
                                    value={product}
                                    onChange={option => selectedProduct(option)}/>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label>{languageStatus ? dictionary["WO_PAY_WITH_ACCOUNT_REF_653311092"] : "Reference"}</label>
                            <input type="text" name="refNo" value={refNo} className="form-control"
                                   onChange={event => updateRefNo(event.target.value)}/>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label>{languageStatus ? dictionary["MESSAGE_FOR_THE_RECE_821862653"] : "Message"}</label>
                            <textarea name="description" rows="4" className="form-control"
                                      value={description} onChange={event => updateDescription(event.target.value)}/>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus ? dictionary["WO_PRODUCT_NAM_-1698044882"] : "Product Name"}
                            </p>
                            <p>
                              {product["product_name"] ?? "-"}
                            </p>
                          </div>
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus ? dictionary["l_price"] : "Price"}
                            </p>
                            <p>
                              {product["retail_price"] ?? "-"} {product["local_currency"]}
                            </p>
                          </div>
                          {Object.entries(quoteData).length === 0 ? "" :
                            <>
                              <div className="col-6">
                                <p className="text-muted">
                                  {languageStatus ? dictionary["l_vat"] : "Tax rate"}
                                </p>
                                <p>
                                  {quoteData["tax"]} {quoteData["sendingCurrency"]}
                                </p>
                              </div>
                              <div className="col-6">
                                <p className="text-muted">
                                  {languageStatus ? dictionary["para_payment_fee"] : "Payment fee"}
                                </p>
                                <p>
                                  {quoteData["payment_method_fee"]} {quoteData["sendingCurrency"]}
                                </p>
                              </div>
                              <div className="col-6">
                                <p className="text-muted">
                                  {languageStatus ? dictionary["l_exchangerate"] : "Exchange Rate"}
                                </p>
                                <p>
                                  {`1.00 ${quoteData.sendingCurrency} = ${quoteData["fxRate"]} ${quoteData["receivingCurrency"]}`}
                                </p>
                              </div>
                              <div className="col-6">
                                <p className="text-muted">
                                  {languageStatus ? dictionary["para_total_amount_to_pay"] : "Amount"}
                                </p>
                                <p>
                                  {formatStringCurrency(quoteData["total_amount"])} {quoteData["sendingCurrency"]}
                                </p>
                              </div>
                            </>
                          }
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus ? dictionary["para_operator"] : "Operator"}
                            </p>
                            <p>
                              {operator["provider"]}
                            </p>
                          </div>
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus ? dictionary["para_wallet_service_type"] : "Service"}
                            </p>
                            <p>
                              {service["service"]}
                            </p>
                          </div>
                        </div>
                        {Object.entries(quoteData).length === 0 ? "" :
                          <button onClick={payBill} className="btn btn-primary mt-3 float-end" type="button">
                            {languageStatus ? dictionary["WO_PROCEE_1790863502"] : "Proceed"}
                            {paymentLoading ? <>&nbsp;<i className="fa fa-spin fa-spinner"/></> : ""}
                          </button>
                        }
                      </>
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
