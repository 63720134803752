import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import Select from "react-select";

import { addCustomerBeneficiary } from "../../store/actions/cashierbeneficiary.action";
import { getCountries } from "../../store/actions/country.action";
import { Loading } from "../../utils/helpers/constants";
import { customerBeneficiaryTypes } from "../../utils/helpers/objectHelpers";

function AddBeneficiaryModal({
  show,
  handleClose,
  handleSave,
  children = <></>,
  domainBrand,
  customerCode,
}) {
  const dispatch = useDispatch();

  const { countries } = useSelector(
    ({ domainBrandReducer, componentReducer, countryReducer }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      countries: countryReducer,
    })
  );

  const [type, setType] = useState("");
  const [nation, setNation] = useState({});
  const [commonName, setCommonName] = useState("");
  const [lg, setLg] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [regNo, setRegNo] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [income, setIncome] = useState("");
  const [incomeCode, setIncomeCode] = useState("");

  const country = countries?.countries;

  const currency = countries?.currencies;

  // const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  // const loadState = domainBrand.loading === Loading.FETCHING;

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
  }, []);

  const filterCountryCommonName = (data = [], word) => {
    const result = data?.filter((item) => item.iso3Code === word);
    if (result.length !== 0) {
      setCommonName(result[0].common_name);
      setPhoneCode(result[0].telephoneCode);
    }
  };

  const handleCountrySelect = (e) => {
    const { value } = e.target;
    if (value !== "") {
      filterCountryCommonName(country, value);
    }
  };
  const digits = phoneCode + phone.substring(1);

  const payload = {
    senderCode: customerCode,
    firstName: firstName,
    lastName: lastName,
    middleName: "",
    phone: digits,
    companyName: companyName,
    regNumber: regNo,
    email: email,
    beneficiaryName: null,
    dob: null,
    type: type,
    action: null,
    comment: null,
    employer: null,
    salary: parseInt(income),
    salaryCurrency: incomeCode,
    relationShip: "Sponsor",
    address: {
      address1: lg,
      countryCommonName: commonName,
      countryIso3: nation,
    },
  };

  return (
    <Modal show={show} backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton={false}>
        <Modal.Title className="fw-bold">New Receiver</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="needs-validation" onSubmit={(e) => e.preventDefault()}>
          <div className="form-row">
            <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
              <label>
                {languageStatus ? dictionary["l_transaction_tyep"] : "Type"}
              </label>

              <select
                className="py-2 px-2 select"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  Select...
                </option>
                {customerBeneficiaryTypes.map((item) => (
                  <>
                    <option value={item.value}>{item.label}</option>
                  </>
                ))}
              </select>
            </div>

            <div className="col-lg-6 col-md-6 mb-3">
              <label>
                {languageStatus
                  ? dictionary["WO_FIRST_NAM_1034963835"]
                  : "First Name"}
              </label>
              <input
                type={"text"}
                className="form-control border"
                value={firstName}
                placeholder=""
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="col-lg-6 col-md-6 mb-3">
              <label>
                {languageStatus
                  ? dictionary["WO_LAST_NAM_-1125905146"]
                  : "Last Name"}
              </label>
              <input
                type={"text"}
                className="form-control border"
                value={lastName}
                placeholder=""
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
              <label>
                {languageStatus
                  ? dictionary["WO_COUNTRY_1435082597"]
                  : "Country"}
                <span style={{ color: "red" }}> *</span>
              </label>

              <select
                className="py-2 px-2 select"
                value={nation}
                onChange={(e) => {
                  setNation(e.target.value);
                  handleCountrySelect(e);
                }}
              >
                <option value="" disabled selected hidden>
                  Select...
                </option>
                {country?.map((item) => (
                  <>
                    <option value={item.iso3Code}>{item.common_name}</option>
                  </>
                ))}
              </select>
            </div>

            <div className="col-lg-6 col-md-6 mb-3">
              <label>
                {languageStatus ? dictionary["WO_STAT_-878960465"] : "State"}
              </label>
              <input
                type={"text"}
                className="form-control border"
                value={lg}
                placeholder=""
                onChange={(e) => setLg(e.target.value)}
              />
            </div>

            <div className="col-md-6 col-lg-6 mb-3">
              <label>
                {languageStatus ? dictionary["WO_EMAI_1903518907"] : "Email"}
                <span style={{ color: "red" }}> *</span>
              </label>

              <input
                type={"text"}
                className="form-control border px-3"
                placeholder=""
                value={email}
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* BANK INFORMATION */}
            <div className="col-md-12 col-lg-12 mb-3">
              <h6 className="text-uppercase text-center">
                {languageStatus
                  ? dictionary["para_bank_information"]
                  : "Bank Information"}
              </h6>
              <hr />
              <div className="col-md-12 col-lg-12 mb-5">
                <label>
                  {languageStatus
                    ? dictionary["WO_I_WILL_PROVIDE_BENEF_879192437"]
                    : " I will provide beneficiary bank account details now"}
                </label>
                <div
                  className="border py-2 rounded-3"
                  style={{ height: "40px" }}
                >
                  <input type={"checkbox"} className="" checked />
                </div>
              </div>
            </div>

            {/* COMPANY INFORMATION */}
            <div className="col-md-12 col-lg-12 mb-3">
              <h6 className="text-uppercase text-center">
                {languageStatus
                  ? dictionary["para_company_information"]
                  : "Company Information"}
              </h6>
              <hr />
              <div className="row row-sm">
                <div className="col-md-6 col-lg-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["WO_COMPANY_NAM_-805761030"]
                      : "Company Name"}
                    <span style={{ color: "red" }}> *</span>
                  </label>

                  <input
                    type={"text"}
                    className="form-control border px-3"
                    placeholder=""
                    value={companyName}
                    name="company_name"
                    id="company_name"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>

                <div className="col-md-6 col-lg-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["l_registration_no"]
                      : "Registration No"}
                    <span style={{ color: "red" }}> *</span>
                  </label>

                  <input
                    type={"text"}
                    className="form-control border px-3"
                    placeholder=""
                    value={regNo}
                    name="registration_number"
                    id="registration_number"
                    onChange={(e) => setRegNo(e.target.value)}
                  />
                </div>

                <div className="col-md-12 col-lg-12 mb-3">
                  <div className="row row-sm d-flex flex-column">
                    <h6>
                      {languageStatus ? dictionary["para_phone"] : "Phone"}
                    </h6>
                    <div className="col-md-12 col-lg-12 d-flex flex-row justify-content-between">
                      <select
                        className="py-2 px-3 select me-2"
                        value={phoneCode}
                        onChange={(e) => {
                          setPhoneCode(e.target.value);
                          handleCountrySelect(e);
                        }}
                      >
                        <option className="" selected hidden disabled>
                          Select...
                        </option>
                        {country?.map((item) => (
                          <>
                            <option value={item.telephoneCode}>
                              {item.common_name}({item.telephoneCode})
                            </option>
                          </>
                        ))}
                      </select>

                      <input
                        type={"text"}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder=""
                        className=" form-control border px-3 ms-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["WO_SALARYINCOM_-2079325580"]
                      : "Salary"}
                    <span style={{ color: "red" }}> *</span>
                  </label>

                  <input
                    type={"text"}
                    className="form-control border px-3"
                    placeholder=""
                    value={income}
                    name="salary"
                    id="salary"
                    onChange={(e) => setIncome(e.target.value)}
                  />
                </div>

                <div className="col-md-6 col-lg-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["para_currency_code"]
                      : "Currency Code"}
                    <span style={{ color: "red" }}> *</span>
                  </label>

                  <select
                    className="py-2 px-2 select w-full"
                    value={incomeCode}
                    onChange={(e) => {
                      setIncomeCode(e.target.value);
                    }}
                  >
                    <option value="" disabled selected hidden>
                      Select...
                    </option>
                    {currency?.map((item) => (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    ))}
                  </select>
                </div>

                {/* <div className="col-md-12 col-lg-12 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["WO_RELATIONSHI_1806861560"]
                      : "Relationship"}
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <Select
                    options={country}
                    isClearable={false}
                    isSearchable={true}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    menuPortalTarget={menuPortalTarget}
                    //   value={exchangeUpdate}
                    //   onChange={(option) => setExchangeRateUpdate(option)}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            dispatch(addCustomerBeneficiary(payload));
            handleClose();
          }}
        >
          SAVE CHANGES
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddBeneficiaryModal;

AddBeneficiaryModal.defaultProps = {
  handleClose: () => {},
  handleSave: () => {},
};

AddBeneficiaryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
