import {NavLink} from "react-router-dom";

const Footer = ({domainBrand}) => {

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(typeof dictionary === "undefined" || dictionary === null);
  const footerLink = "https://moneytransferapplication.com/";
  const getYear = new Date().getFullYear();
  const getNewsLink = domainBrand["domainBrand"]["companyWebSiteUrl"];
  const getMailLink = domainBrand["domainBrand"]["companyContactEmailAddress"];
  const footerRenderStatus = domainBrand["domainBrand"]["footer"] === "" || domainBrand["domainBrand"]["footer"] === null;
  const footerRender = domainBrand["domainBrand"]["footer"];
  const createMarkup = () => ({__html: footerRender});

  return (
    <>
      {!footerRenderStatus ? <div dangerouslySetInnerHTML={createMarkup()}/> :
        <footer className="footer border-top-0 footer-1">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12  col-md-5 mt-3 mt-lg-0 d-none d-md-block">
                      <a href={`mailto:${getMailLink}`} className="btn btn-link">
                        {languageStatus ? dictionary["WO_CONTACT_U_631529475"] : "Contact Us"}
                      </a>
                      <NavLink to={{pathname: getNewsLink}} target="_blank" className="btn btn-link">
                        {languageStatus ? dictionary["title_news"] : "News"}
                      </NavLink>
                      <NavLink to="/support" className="btn btn-link">
                        {languageStatus ? dictionary["l_help"] : "Help"}
                      </NavLink>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-md-7 mt-3 mt-lg-0 text-end">
                      Copyright © {getYear} <a href={footerLink}>
                      {languageStatus ? dictionary["l_all_rights_reserved"] : "All Rights Reserved"}
                    </a>.
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </footer>
      }
    </>
  );
}

export default Footer;
