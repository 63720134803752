import React, { useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toast from "react-hot-toast";
import { GiPadlock, GiPadlockOpen } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { ReactComponent as Edit } from "../../../../assets/images/svgs/edit.svg";
import { ReactComponent as Trash } from "../../../../assets/images/svgs/trash.svg";
import DataTable from "../../../../components/dataTable/dataTable";
import EditAmlModal from "../../../../components/modals/EditAmlModal";
import ProductModal from "../../../../components/modals/ProductModal";
import SuspendModal from "../../../../components/modals/SuspendModal";
import { getAMLRules } from "../../../../store/actions/compliance.action";
import { amlTableConfig } from "../../../../utils/helpers/dataTableConfigs";
import {
  activateAmlRule,
  deactivateAmlRule,
  deleteAmlRule,
} from "../../../../utils/services/compliance.service";
import AddNewAMLRule from "./atom/AddNewAMLRule";

const Body = ({ domainBrand, rules = [], countries }) => {
  const dispatch = useDispatch();

  const [addNewState, updateState] = useState(false);
  const [show, setShow] = useState(false);
  const [rule, setRule] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [amlObject, setAmlObject] = useState({});
  const [open, setOpen] = useState(false);
  const [suspend, setSuspend] = useState(false);
  const [type, setType] = useState("");

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const actionColumn = {
    Header: () => {
      return (
        <span className="text-center fw-bold">
          {languageStatus ? dictionary["l_action"] : "Action"}
        </span>
      );
    },
    columnId: 3,
    accessor: "actions",
    Cell: ({ row }) => {
      const amLId = row?.original?.id;
      const activateRule = row?.original?.activateRule;

      return (
        <div className="text-start pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${`1`}`}>Edit</Tooltip>}
          >
            <Edit
              onClick={() => {
                setId(amLId);
                setShow(true);
                setAmlObject(row?.original);
              }}
            />
          </OverlayTrigger>

          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${`2`}`}>Delete</Tooltip>}
          >
            <Trash
              className="mx-2"
              onClick={() => {
                setRule(row?.original?.businessRuleNumber);
                setId(amLId);
                setOpen(true);
              }}
            />
          </OverlayTrigger>

          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={
              <Tooltip id={`tooltip-${`2`}`}>
                {activateRule ? "Deactivate" : "Activate"}
              </Tooltip>
            }
          >
            {activateRule ? (
              <GiPadlock
                size={20}
                color={"#6259CA"}
                onClick={() => {
                  setId(amLId);
                  setSuspend(true);
                  setType("DEACTIVATE-AML");
                  setRule(row?.original?.businessRuleNumber);
                }}
              />
            ) : (
              <GiPadlockOpen
                size={20}
                color={"#6259CA"}
                onClick={() => {
                  setId(amLId);
                  setSuspend(true);
                  setType("ACTIVATE-AML");
                  setRule(row?.original?.businessRuleNumber);
                }}
              />
            )}
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...amlTableConfig, actionColumn];
  const columns = useMemo(() => tableObject, []);

  const addNewStatus = (event) => {
    event.preventDefault();
    updateState(!addNewState);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const res = await deleteAmlRule(id);
      if (res?.data?.status === "SUCCESS") {
        toast.success(res?.data?.message);
        setLoading(false);
        setShow(false);
        dispatch(getAMLRules());
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  const handleActivateAml = async (id) => {
    setLoading(true);
    try {
      const res = await activateAmlRule(id);
      if (res?.data?.status === "SUCCESS") {
        toast.success(res?.data?.message);
        setLoading(false);
        setShow(false);
        dispatch(getAMLRules());
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  const handleDeactivateAml = async (id) => {
    setLoading(true);
    try {
      const res = await deactivateAmlRule(id);
      if (res?.data?.status === "SUCCESS") {
        toast.success(res?.data?.message);
        setLoading(false);
        setShow(false);
        dispatch(getAMLRules());
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <ProductModal
        show={open}
        handleClose={() => {
          setShow(false);
          setLoading(false);
          setRule("");
          setId("");
          setOpen(false);
        }}
        kyc={rule}
        handleDeleteKyc={() => handleDelete(id)}
        loading={loading}
        type="KYC-DELETE"
      />
      <EditAmlModal
        show={show}
        handleClose={() => setShow(false)}
        id={id}
        amlObject={{ ...amlObject }}
        countries={countries}
      />
      <SuspendModal
        show={suspend}
        handleClose={() => setSuspend(false)}
        type={type}
        aml={rule}
        handleSuspend={() => handleDeactivateAml(id)}
        handleUnsuspend={() => handleActivateAml(id)}
        loading={loading}
      />
      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">
                {dictionary
                  ? dictionary["WO_CUSTOMER_AM_75995121"]
                  : "AML Rules"}
              </h1>
            </div>
            <div className="ms-auto pageheader-btn">
              <NavLink
                onClick={addNewStatus}
                to="/"
                className="btn btn-primary btn-icon text-white me-2"
              >
                <span>
                  <i className="fa fa-folder-plus" />
                </span>{" "}
                &nbsp;
                {languageStatus ? dictionary["button_add_new"] : "Add New"}
              </NavLink>
            </div>
          </div>
          <div className="row row-sm">
            {addNewState ? (
              <div className="col-lg-12">
                <div className="card overflow-hidden">
                  <div className="card-header bg-primary text-white">
                    <h3 className="card-title fw-bold">
                      {dictionary
                        ? dictionary["WO_ADD_NEW_AM_719296172"]
                        : "Add New AML"}
                    </h3>
                  </div>

                  <AddNewAMLRule
                    languageStatus={languageStatus}
                    dictionary={dictionary}
                    countries={countries}
                    domainBrand={domainBrand}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-lg-12">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title fw-bold">
                    {languageStatus
                      ? dictionary["m_transaction_rules"]
                      : "All AML Rules"}
                  </h3>
                </div>
                <div className="card-body">
                  <div>
                    <p className="text-muted card-sub-title">
                      {languageStatus
                        ? dictionary["WO_SORT_AND_FILTER_TABL_1070504595"]
                        : " Sort and filter table"}
                    </p>
                  </div>
                  <div className="table-responsive">
                    <DataTable columns={columns} data={rules.amlRules} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Body;
