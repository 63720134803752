import { SUBDOMAIN_CHECK } from "../helpers/logicHelper"; // checks the subdomian, takes in url params
import { subDomainMapper } from "../helpers/mappers";

export const DEFAULT_BASEURL = "stag";
export const LOCALHOST_BASEURL = "localhost";
export const DEV_BRANCH_BASEURL = "multiview-admin";
export const LIVE_BRANCH_BASEURL = "mta-live";

export const getSubDomain = () => {
  const subDomainBaseURL = window.location.hostname.split(".")[0];
  return subDomainBaseURL === DEV_BRANCH_BASEURL ||
    subDomainBaseURL === LIVE_BRANCH_BASEURL ||
    subDomainBaseURL === LOCALHOST_BASEURL
    ? DEFAULT_BASEURL
    : subDomainBaseURL;
};

export const fetchSubDomain = () => {
  const domainURL = getSubDomain();
  const subdomainChecker = SUBDOMAIN_CHECK(window.location.hostname);
  return domainURL === DEFAULT_BASEURL
    ? subDomainMapper["STAG"]
    : subdomainChecker
    ? subDomainMapper[domainURL.toUpperCase()]
    : (window.location.href = "https://moneytransferapplication.com/");
};

export const getBaseUrl = () => {
  const subdomainObject = fetchSubDomain();
  console.log(
    `https://${subdomainObject?.API_SUBDOMAIN}.${subdomainObject?.API_ENV?.env}`,
    "dom"
  );
  return `https://${subdomainObject?.API_SUBDOMAIN}.${subdomainObject?.API_ENV?.env}`;
};
