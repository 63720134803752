import * as AccountsConstants from "../constants/accounts.constants";
import {Loading} from "../../utils/helpers/constants";
import {formatAccountsDropdown} from "../../utils/helpers/logicHelper";

const initialState = {
  accountList: [],
  loading: "",
};

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {

    case AccountsConstants.FETCH_ACCOUNTS_REQUEST:
      return {...state, loading: Loading.FETCHING};

    case AccountsConstants.FETCH_ACCOUNTS_SUCCESS:
      const accountDropDown = formatAccountsDropdown(action.payload.accounts);
      return {...state, accountList: accountDropDown, loading: Loading.SUCCESS};

    case AccountsConstants.FETCH_ACCOUNTS_FAILURE:
      return {...state, loading: Loading.ERROR};

    default:
      return state;

  }
}

export default accountsReducer;
