/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiDownload } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { FiPrinter } from "react-icons/fi";
import Select from "react-select";
import DataTable from "../../../../components/dataTable/dataTable";
import CustomDatePicker from "../../../../components/datePickers/CustomDatePicker";
import { apiReportDataTableConfig } from "../../../../utils/helpers/dataTableConfigs";

function Body({ domainBrand, payCentres }) {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const payCentresDropdown = [
    { label: domainBrand.domainBrand.companyName, value: "DEFAULT" },
  ].concat(payCentres);
  const menuPortalTarget = document.getElementById("root");

  const actionColumn = {
    Header: () => {
      return (
        <span className="text-center fw-bold">
          {languageStatus ? dictionary["l_action"] : "Action"}
        </span>
      );
    },
    columnId: 10,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-start pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${`1`}`}>Print Receipt</Tooltip>}
          >
            <FiPrinter size={20} color={"#6259CA"} />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const apiReportTable = apiReportDataTableConfig(languageStatus, dictionary);
  const tableObject = [...apiReportTable, actionColumn];
  const columns = useMemo(() => tableObject, []);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState("");

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {dictionary
                ? dictionary["WO_MY_API_TRANSACTION_R_658004357"]
                : "My API Transaction Report"}
            </h1>
          </div>
        </div>

        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title fw-bold">
                  {dictionary
                    ? dictionary["WO_API_REPOR_-1067201474"]
                    : "API Transaction Report"}
                </h3>
              </div>
              <div className="card-body">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="needs-validation"
                >
                  <div className="form-row">
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <label>From</label>
                      <CustomDatePicker
                        getDate={setFrom}
                        showYearDropdown
                        selectDropdownMode
                        withPortal={true}
                        setMaxDate={new Date()}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <label>To</label>
                      <CustomDatePicker
                        getDate={setTo}
                        showYearDropdown
                        selectDropdownMode
                        withPortal={true}
                        setMaxDate={new Date()}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <label>
                        Please select partner you wish to pay-out on behalf{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        options={payCentresDropdown}
                        isClearable={true}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        value={partner}
                        name="pycenter-partner"
                        onChange={(option) => setPartner(option)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <label>Transaction ID</label>
                      <input
                        type={"text"}
                        value={""}
                        className="form-control"
                        onChange={(e) => e.target.value}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <button
                        onClick={() => {}}
                        className="btn btn-primary margin-label w-100"
                        type="button"
                      >
                        Search
                        {loading && (
                          <>
                            &nbsp;
                            <i className="fa fa-spinner fa-spin" />
                          </>
                        )}
                      </button>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <button
                        onClick={() => {}}
                        className="btn btn-primary margin-label w-100 d-flex flex-row justify-content-center align-items-center"
                        type="button"
                      >
                        <BsDownload size={16} color={"#fff"} />
                        <span className="ms-3">Download</span>
                      </button>
                    </div>
                  </div>
                </form>

                <div className="col-lg-12 col-md-12 mt-5">
                  <div className="table-responsive">
                    <DataTable columns={columns} data={[]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
