import React, { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "../../../../components/dataTable/dataTable";
import { billsAndServicesDataTableConfig } from "../../../../utils/helpers/dataTableConfigs";

function Body({ domainBrand, bills = [] }) {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [addNewState, updateState] = useState(false);
  const [filterData, setFilerData] = useState("");

  const billsAndServicesTable = billsAndServicesDataTableConfig(
    languageStatus,
    dictionary
  );

  const tableObject = [...billsAndServicesTable];

  const columns = useMemo(() => tableObject, []);

  const addNewStatus = (event) => {
    event.preventDefault();
    updateState(!addNewState);
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus ? dictionary["l_pay_bills"] : "Pay Bills"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn">
            <NavLink
              onClick={addNewStatus}
              to="/"
              className="btn btn-primary btn-icon text-white me-2"
            >
              <span>
                <i className="fa fa-folder-plus" />
              </span>{" "}
              &nbsp;
              {languageStatus
                ? dictionary["m_pay_bills"]
                : "Pay Bills and Services"}
            </NavLink>
          </div>
        </div>
        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title fw-bold">Bills and Services</h3>
              </div>
              <div className="card-body">
                <div className="mb-2">
                  <p className="text-muted card-sub-title">
                    {languageStatus
                      ? dictionary["WO_SORT_AND_FILTER_TABL_1070504595"]
                      : " Sort and filter table"}
                  </p>

                  <input
                    type={"text"}
                    value={filterData}
                    onChange={(e) => setFilerData(e.target.value)}
                    className="form-control w-50"
                  />
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={bills}
                    filterData={filterData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
