import {NavLink} from "react-router-dom";

const Body = ({domainBrand, cart}) => {

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(typeof dictionary === "undefined" || dictionary === null);
  console.log(cart);

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">{languageStatus ? dictionary["WO_CAR_-1891781838"] : "Cart"}</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to="/merchant/products">
                {languageStatus ? dictionary["WO_MERCHANT_PRODUCT_1436670176"] : "Products"}
              </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Cart</li>
            </ol>
          </div>
          <div className="ms-auto pageheader-btn">
            <NavLink to="/" className="btn btn-primary btn-icon text-white me-2">
              <span><i className="fa fa-credit-card"/></span> &nbsp;Pay now
              {/*{languageStatus ? dictionary["button_add_new"] : "Add New"}*/}
            </NavLink>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-8 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">Shopping Cart</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-vcenter text-nowrap mb-0">
                    <thead>
                    <tr className="border-top">
                      <th className="w-5">Product</th>
                      <th>Title</th>
                      <th>Price</th>
                      <th className="w-15">Quantity</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {cart.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <img src={item["productMainPhotoUrl"]} alt={item["product_name"]} className="cart-img"/>
                        </td>
                        <td>{item["product_name"]}</td>
                        <td className="fw-bold">{`${item["product_currency"]} ${item["retail_price"]}`}</td>
                        <td>
                          <div className="input-group input-indec">
                            <input type="text" name="quantity" className="form-control text-center qty" value="1"/>
                          </div>
                        </td>
                        <td>
                          <NavLink to="/" className="btn btn-square btn-danger-light me-1"
                             data-bs-toggle="tooltip" data-bs-placement="top" title="Remove">
                            <i className="fa fa-trash text-danger fs-13"/>
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">Price Details</h3>
              </div>
              <div className="card-body">
                <table className="table border-top-0">
                  <tr>
                    <td className="border-top-0">Sub Total</td>
                    <td className="text-end fw-bold border-top-0">$4,360</td>
                  </tr>
                  <tr>
                    <td className="border-top-0">Discount</td>
                    <td className="text-end fw-bold border-top-0">-</td>
                  </tr>
                  <tr>
                    <td className="fs-20 fw-bold border-top-0">Total</td>
                    <td className="text-end fs-20 fw-bold border-top-0">$3,976</td>
                  </tr>
                </table>
              </div>
              <div className="card-footer">
                <div className="step-footer text-end">
                  <NavLink to="/" className="btn btn-primary"><i className="fa fa-arrow-left me-1"/>
                    {languageStatus ? dictionary["WO_CONTINUE_SHOPPIN_-822938188"] : "Continue Shopping"}
                  </NavLink>
                  <NavLink to="/" className="btn btn-success">Check out<i className="fa fa-arrow-right ms-1"/></NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
