import React, { useRef } from "react";
// import { useDispatch } from "react-redux";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { toast } from "react-hot-toast";

import { customerBeneficiaryTypes } from "../../../../../utils/helpers/objectHelpers";
// import DatePicker from "react-datepicker";

function CreateCustomer({
  domainBrand,
  dictionary,
  languageStatus,
  currency,
  countries,
  controlView = true || false,
}) {
  const menuPortalTarget = document.getElementById("root");
  // const [startDate, setStartDate] = useState("");

  // const dispatch = useDispatch();

  const formRef = useRef();

  const schema = yup
    .object({
      customerBeneficiaryTypes: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(),
      addressLine1: yup.string().required(),
      addressLine2: yup.string().required(),
      firstName: yup.string().notRequired(),
      lastName: yup.string().notRequired(),
      middleName: yup.string().notRequired(),
      countryDialCode: yup.string().required(),
      postCode: yup.string().required(),
      email: yup.string().required(),
      city: yup.string().required(),
      currencyCode: yup
        .object()
        .shape({
          currencyCode: yup.string().required(),
          label: yup.string().required(),
          iso3Code: yup.string().required(),
        })
        .required(),
      country: yup
        .object()
        .shape({
          common_name: yup.string().required(),
          iso3Code: yup.string().required(),
        })
        .required(),
      nationality: yup
        .object()
        .shape({
          common_name: yup.string().required(),
          iso3Code: yup.string().required(),
        })
        .required(),
      openingAccFee: yup.string().required(),
      companyName: yup.string().notRequired(),
      regNo: yup.string().notRequired(),
      password: yup.string().notRequired(),
      blackList: yup.string().notRequired(),
      riskAssessment: yup.string().notRequired(),
    })
    .required();

  const {
    register,
    handleSubmit,
    // formState: {  },
    control,
    // reset,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="card-body">
      <form
        className="needs-validation"
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
      >
        <div className="form-row">
          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_transaction_tyep"] : "Type"}
              <span style={{ color: "red" }}> *</span>
            </label>
            <Controller
              name="customerBeneficiaryTypes"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    options={customerBeneficiaryTypes}
                    isClearable={false}
                    isSearchable={true}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    menuPortalTarget={menuPortalTarget}
                  />
                );
              }}
            />
          </div>
          {watch("customerBeneficiaryTypes")?.value === "INDIVDUAL" && (
            <>
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_first_name"]
                    : "First Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>

                <input
                  type={"text"}
                  className="form-control border px-3"
                  id="firstName"
                  name="firstName"
                  {...register("firstName", {
                    required: {
                      value: "",
                      message: "This field is required",
                    },
                  })}
                />
              </div>

              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus ? dictionary["para_last_name"] : "Last Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>

                <input
                  type={"text"}
                  className="form-control border px-3"
                  placeholder=""
                  id="lastName"
                  name="lastName"
                  {...register("lastName", {
                    required: {
                      value: "",
                      message: "This field is required",
                    },
                  })}
                />
              </div>

              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["WO_MIDDLE_NAM_1847397348"]
                    : "Middle Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>

                <input
                  type={"text"}
                  className="form-control border px-3"
                  placeholder=""
                  id="middleName"
                  name="middleName"
                  {...register("middleName", {
                    required: {
                      value: "",
                      message: "This field is required",
                    },
                  })}
                />
              </div>
            </>
          )}

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["para_address1"] : "Address line 1"}
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"text"}
              className="form-control border px-3"
              placeholder=""
              id="addressLine1"
              name="addressLine1"
              {...register("addressLine1", {
                required: {
                  value: "",
                  message: "This field is required",
                },
              })}
            />
          </div>

          {/* <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["WO_ALLOW_ONLIN_534191470"]
                : " Allow Customer to login"}
              <span style={{ color: "red" }}> *</span>
            </label>
            <div className="border py-2 rounded-3" style={{ height: "40px" }}>
              <input type={"checkbox"} className="" checked />
            </div>
          </div> */}

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_address_line_2_"]
                : "Address line 2"}
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"text"}
              className="form-control border px-3"
              placeholder=""
              id="addressLine2"
              name="addressLine2"
              {...register("addressLine1", {
                required: {
                  value: "",
                  message: "This field is required",
                },
              })}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {/* {languageStatus
                ? dictionary[""]
                : "Country Dialing Code + Phone "} */}
              Country Dialing Code + Phone
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"text"}
              className="form-control border px-3"
              placeholder=""
              id="countryDialCode"
              name="countryDialCode"
              {...register("countryDialCode", {
                required: {
                  value: "",
                  message: "This field is required",
                },
              })}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_postcode"] : "Area/post Code"}
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"text"}
              className="form-control border px-3"
              placeholder=""
              id="postCode"
              name="postCode"
              {...register("postCode", {
                required: {
                  value: "",
                  message: "This field is required",
                },
              })}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["WO_EMAI_1903518907"] : "Email"}
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"email"}
              className="form-control border px-3"
              placeholder=""
              id="email"
              name="email"
              {...register("email", {
                required: "required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              })}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["WO_CIT_-488793102"] : "City"}
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"text"}
              className="form-control border px-3"
              placeholder=""
              id="city"
              name="city"
              {...register("city", {
                required: {
                  value: "",
                  message: "This field is required",
                },
              })}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <div className="row row-sm">
              <div className="col-md-6">
                <label>
                  {languageStatus
                    ? dictionary["WO_CURRENCY_COD_1626671491"]
                    : "Currency Code"}
                  <span style={{ color: "red" }}> *</span>
                </label>

                <Controller
                  name="currencyCode"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        options={currency}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-md-6">
                <label>
                  {languageStatus
                    ? dictionary["WO_OPENING_BALANCE_FE_-1248866573"]
                    : "Opening Account Fee"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type={"text"}
                  className="form-control border px-3"
                  placeholder=""
                  id="openingAccFee"
                  name="openingAccFee"
                  {...register("openingAccFee", {
                    required: {
                      value: "",
                      message: "This field is required",
                    },
                  })}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <div className="row row-sm">
              <div className="col-md-6">
                <label>
                  {languageStatus
                    ? dictionary["WO_COUNTRY_1435082597"]
                    : "Country"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        options={countries}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-md-6">
                <label>
                  {languageStatus
                    ? dictionary["para_nationality"]
                    : "Nationality"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Controller
                  name="nationality"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        options={countries}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>

          {/* COMPANY INFORMATION  */}
          {watch("customerBeneficiaryTypes")?.value === "COMPANY" ? (
            <div className="col-md-12 col-lg-12 mb-3">
              {controlView === false && (
                <>
                  <h4 className="fw-bold mt-3 text-center">
                    COMPANY INFORMATION
                  </h4>

                  <hr />
                </>
              )}

              <div className="row row-sm">
                {controlView === false && (
                  <>
                    <div className="col-md-6 mb-3">
                      <label className="fw-bold">
                        {languageStatus
                          ? dictionary["WO_COMPANY_NAM_-805761030"]
                          : "Company Name"}
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control border px-3"
                        placeholder=""
                        id="companyName"
                        name="companyName"
                        {...register("companyName", {
                          required: {
                            value: "",
                            message: "This field is required",
                          },
                        })}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="fw-bold">
                        {languageStatus
                          ? dictionary["l_registration_no"]
                          : "Registration No"}
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control border px-3"
                        placeholder=""
                        id="regNo"
                        name="regNo"
                        {...register("regNo", {
                          required: {
                            value: "",
                            message: "This field is required",
                          },
                        })}
                      />
                    </div>
                  </>
                )}

                <div className="col-md-6 mb-3">
                  <label className="fw-bold">
                    {languageStatus
                      ? dictionary["l_retype_password"]
                      : "Please enter password for security reasons "}
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type={"password"}
                    className="form-control border px-3"
                    placeholder=""
                    id="password"
                    name="password"
                    {...register("password", {
                      required: {
                        value: "",
                        message: "This field is required",
                      },
                    })}
                  />
                </div>

                {controlView && (
                  <div className="col-md-6 col-lg-6 mb-3">
                    <button
                      // onClick={addNewAMLRule}
                      className="btn btn-primary margin-label width-100 fw-bold"
                      type="button"
                    >
                      BLACKLIST REQUEST
                    </button>
                  </div>
                )}

                <div className="col-md-6 mb-3">
                  <label className="fw-bold">
                    Blacklist status
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control border px-3"
                    placeholder=""
                    id="blackList"
                    name="blackList"
                    {...register("blackList", {
                      required: {
                        value: "",
                        message: "This field is required",
                      },
                    })}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="fw-bold">
                    Risk Assessment
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control border px-3"
                    placeholder=""
                    name="riskAssessment"
                    id="riskAssessment"
                    {...register("riskAssessment", {
                      required: {
                        value: "",
                        message: "This field is required",
                      },
                    })}
                  />
                </div>

                <div className="col-md-6 col-lg-6">
                  <input
                    // onClick={addNewAMLRule}
                    className="btn btn-primary margin-label width-100 fw-bold"
                    type="submit"
                    value={"SAVE CHANGES"}
                    id="save_changes"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-6 col-lg-6">
              <input
                // onClick={addNewAMLRule}
                className="btn btn-primary margin-label width-100 fw-bold"
                type="submit"
                value={"SAVE CHANGES"}
                id="save"
                onClick={() => getValues()}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default CreateCustomer;
