import ActionCreator from "../../utils/helpers/actionCreator";
import * as CountryConstants from "../constants/country.constants";
import {
  allCountries,
  cashPickupLocations,
  fetchOriginSupportedCountries,
  fetchSupportedSendingFromCurrencies,
  supportedCountries,
  supportedWalletCurrencies,
} from "../../utils/services/country.service";
import { expiredTokenCheck } from "./auth.action";
import { transferStatusMap } from "../../utils/helpers/mappers";

export const getCountries = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(CountryConstants.FETCH_COUNTRIES_REQUEST));
    const { data: responseData } = await allCountries();
    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        CountryConstants.FETCH_COUNTRIES_PHONE_CODE_SUCCESS,
        responseData["data"]
      )
    );
    dispatch(
      ActionCreator(
        CountryConstants.FETCH_COUNTRIES_CURRENCIES_SUCCESS,
        responseData["data"]
      )
    );
    dispatch(
      ActionCreator(
        CountryConstants.FETCH_COUNTRIES_SUCCESS,
        responseData["data"]
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CountryConstants.FETCH_COUNTRIES_FAILURE));
  }
};

export const getOriginSupportedCountries = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(CountryConstants.FETCH_ORIGIN_COUNTRIES_REQUEST));
    const { data: responseData } = await fetchOriginSupportedCountries();
    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        CountryConstants.FETCH_ORIGIN_COUNTRIES_SUCCESS,
        responseData["data"]
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CountryConstants.FETCH_ORIGIN_COUNTRIES_FAILURE));
  }
};

export const getSupportedCurrencies = () => async (dispatch) => {
  try {
    dispatch(
      ActionCreator(CountryConstants.FETCH_SUPPORTED_CURRENCIES_REQUEST)
    );
    const { data: responseData } = await supportedWalletCurrencies();
    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        CountryConstants.FETCH_SUPPORTED_CURRENCIES_SUCCESS,
        responseData["data"]
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(
      ActionCreator(CountryConstants.FETCH_SUPPORTED_CURRENCIES_FAILURE)
    );
  }
};

export const getSupportedWalletCurrencies = (payload) => async (dispatch) => {
  try {
    dispatch(
      ActionCreator(CountryConstants.FETCH_SUPPORTED_WALLET_CURRENCIES_REQUEST)
    );
    const { country, destinationCountry, deliveryMethod, currency } = payload;
    const { data: responseData } = await fetchSupportedSendingFromCurrencies(
      country,
      destinationCountry,
      deliveryMethod,
      currency
    );
    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        CountryConstants.FETCH_SUPPORTED_WALLET_CURRENCIES_SUCCESS,
        responseData["data"]
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(
      ActionCreator(CountryConstants.FETCH_SUPPORTED_WALLET_CURRENCIES_FAILURE)
    );
  }
};

export const getTransferFundsSupportedCountry =
  (originCountry) => async (dispatch) => {
    try {
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_REQUEST
        )
      );
      const { data: responseData } = await supportedCountries(
        originCountry,
        transferStatusMap.TRANSFER_FUNDS.transactionType
      );
      if (responseData.status === "FAILED") throw responseData;

      dispatch(
        ActionCreator(
          CountryConstants.FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_SUCCESS,
          responseData["data"]
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_FAILURE
        )
      );
    }
  };

export const getCashPickupSupportedCountry =
  (originCountry) => async (dispatch) => {
    try {
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_REQUEST
        )
      );
      const { data: responseData } = await supportedCountries(
        originCountry,
        transferStatusMap.CASH_PICKUP.transactionType
      );
      if (responseData.status === "FAILED") throw responseData;

      dispatch(
        ActionCreator(
          CountryConstants.FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_SUCCESS,
          responseData["data"]
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_FAILURE
        )
      );
    }
  };

export const getMobileMoneySupportedCountry =
  (originCountry) => async (dispatch) => {
    try {
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_REQUEST
        )
      );
      const { data: responseData } = await supportedCountries(
        originCountry,
        transferStatusMap.MOBILE_MONEY.transactionType
      );
      if (responseData.status === "FAILED") throw responseData;

      dispatch(
        ActionCreator(
          CountryConstants.FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_SUCCESS,
          responseData["data"]
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_FAILURE
        )
      );
    }
  };

export const getAirtimeTopUpSupportedCountry =
  (originCountry) => async (dispatch) => {
    try {
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_REQUEST
        )
      );
      const { data: responseData } = await supportedCountries(
        originCountry,
        transferStatusMap.AIRTIME_TOPUP.transactionType
      );
      if (responseData.status === "FAILED") throw responseData;

      dispatch(
        ActionCreator(
          CountryConstants.FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_SUCCESS,
          responseData["data"]
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_FAILURE
        )
      );
    }
  };

export const getBillPaymentSupportedCountry =
  (originCountry) => async (dispatch) => {
    try {
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_REQUEST
        )
      );
      const { data: responseData } = await supportedCountries(
        originCountry,
        transferStatusMap.BILL_PAYMENT.transactionType
      );
      if (responseData.status === "FAILED") throw responseData;

      dispatch(
        ActionCreator(
          CountryConstants.FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_SUCCESS,
          responseData["data"]
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_FAILURE
        )
      );
    }
  };

export const getMerchantProductsSupportedCountry =
  (originCountry) => async (dispatch) => {
    try {
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_MERCHANT_SUPPORTED_COUNTRIES_REQUEST
        )
      );
      const { data: responseData } = await supportedCountries(
        originCountry,
        transferStatusMap.MERCHANT_PRODUCT.transactionType
      );
      if (responseData.status === "FAILED") throw responseData;

      dispatch(
        ActionCreator(
          CountryConstants.FETCH_MERCHANT_SUPPORTED_COUNTRIES_SUCCESS,
          responseData["data"]
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(
        ActionCreator(
          CountryConstants.FETCH_MERCHANT_SUPPORTED_COUNTRIES_FAILURE
        )
      );
    }
  };

export const getCashPickupLocations = (originCountry) => async (dispatch) => {
  try {
    dispatch(
      ActionCreator(CountryConstants.FETCH_CASHPICKUP_LOCATIONS_REQUEST)
    );
    const { data: responseData } = await cashPickupLocations(originCountry);
    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        CountryConstants.FETCH_CASHPICKUP_LOCATIONS_SUCCESS,
        responseData["data"]
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(
      ActionCreator(CountryConstants.FETCH_CASHPICKUP_LOCATIONS_FAILURE)
    );
  }
};
