import * as EmployeeConstants from "../constants/employee.constants";
import { Loading } from "../../utils/helpers/constants";

const initialState = {
  employees: [],
  loadingEmployees: "",
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EmployeeConstants.EMPLOYEES_REQUEST:
      return { ...state, loadingEmployees: Loading.FETCHING };

    case EmployeeConstants.EMPLOYEES_SUCCESS:
      return {
        ...state,
        loadingEmployees: Loading.SUCCESS,
        employees: action.payload,
      };

    case EmployeeConstants.EMPLOYEES_FAILURE:
      return {
        ...state,
        loadingEmployees: Loading.ERROR,
      };

    default:
      return state;
  }
};

export default employeeReducer;
