import { toast } from "react-hot-toast";
import * as EmployeeConstants from "../constants/employee.constants";
import ActionCreator from "../../utils/helpers/actionCreator";
import { expiredTokenCheck } from "./auth.action";
import { listEmployees } from "../../utils/services/employees.service";

export const getEmployees = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(EmployeeConstants.EMPLOYEES_REQUEST));
    const { data: responseData } = await listEmployees();
    // if (responseData.status === "FAILED") throw responseData;
    dispatch(ActionCreator(EmployeeConstants.EMPLOYEES_SUCCESS, responseData));
    toast.success("Employees fetched successfully.");
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(EmployeeConstants.EMPLOYEES_FAILURE));
  }
};
