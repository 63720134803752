import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, NavLink } from "react-router-dom";
import { toast } from "react-hot-toast";
import Select from "react-select";

import * as DomainBrand from "../../../store/actions/domain-brand.action";
import * as AuthConstants from "../../../store/constants/auth.constants";
import logo from "../../../assets/images/logo.png";
import user from "../../../assets/images/svgs/user.svg";
import passwordSvg from "../../../assets/images/svgs/password.svg";
import AuthImageSlider from "../../../components/slickSlider/AuthImageSlider";
import ActionCreator from "../../../utils/helpers/actionCreator";
import SphereLoader from "../../../components/loaders/SphereLoader";
import { loginUser, validateOtp } from "../../../store/actions/auth.action";
import {
  fetchAppLanguage,
  languageChangeHelper,
} from "../../../utils/helpers/logicHelper";
import { Loading } from "../../../utils/helpers/constants";
import { navBarStyles } from "../../../components/selectStyles/customStyles";
import { languageDropDownOptions } from "../../../utils/helpers/objectHelpers";
import TokenHelpers from "../../../utils/helpers/tokenHelpers";
import AuthService from "../../../utils/services/auth.service";

const Login = () => {
  let selectedLanguage = fetchAppLanguage(),
    isLoggedIn = TokenHelpers.checkIfLoggedIn();
  const dispatch = useDispatch(),
    history = useHistory();
  const { auth, domainBrand } = useSelector(
    ({ authReducer, domainBrandReducer }) => ({
      auth: authReducer,
      domainBrand: domainBrandReducer,
    })
  );

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  // states
  const [requireToken, setRequireToken] = useState(false);
  const [languageField, updateLanguageField] = useState(
    selectedLanguage["commonName"]
  );
  const [email, updateEmail] = useState("");
  const [passwordType, updatePasswordType] = useState("password");
  const [password, updatePassword] = useState("");
  const [otp, updateOTP] = useState("");
  const resetOtp = () => {
    dispatch(ActionCreator(AuthConstants.DISABLED_AWAITING_TOKEN));
    setRequireToken(false);
  };

  useEffect(() => {
    setRequireToken(auth.awaitingToken);
  }, [auth.awaitingToken]);

  if (isLoggedIn) return <Redirect to={{ pathname: "/dashboard" }} />;
  const loginState = auth.loginStatus === Loading.FETCHING;
  const showSpinner = domainBrand.loading === Loading.FETCHING;
  const disableOTPButton = email === "" || password === "" || otp === "";
  const disableButton =
    email === "" || password === "" || domainBrand.loading === Loading.FETCHING;
  const brandLangSelectorOptions =
    domainBrand.loading === Loading.SUCCESS
      ? domainBrand["domainBrand"]["language"]
      : languageDropDownOptions;

  const togglePasswordField = (type) => {
    updatePasswordType(type);
    document.getElementById("password").type = type;
  };

  const handleLanguageChange = (option) => {
    const languageHelper = languageChangeHelper(option);
    updateLanguageField(languageHelper["value"]);
    dispatch(
      DomainBrand.getDomainBrandIdentity(
        languageHelper["subdomain"],
        languageHelper["value"]["id"]
      )
    );
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const navigateMethod = () => history.push("/dashboard");
    const payload = { email, currentPassword: password };
    await dispatch(loginUser(payload, navigateMethod));
  };

  const resendOTP = async (event) => {
    event.preventDefault();
    const { data: responseData } = await AuthService.resendOtp();
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not resend otp`);
    else toast.success(`A new otp has been sent to your email`);
  };

  const validateLoginOTP = async (event) => {
    event.preventDefault();
    const navigateMethod = () => history.push("/dashboard");
    await dispatch(validateOtp({ otp }, navigateMethod));
  };

  return (
    <div className="login-img">
      <div className="page">
        {showSpinner ? (
          <SphereLoader />
        ) : (
          <>
            <div className="login-language" id="language-selector-container">
              <Select
                options={brandLangSelectorOptions}
                isClearable={false}
                isSearchable={false}
                name={languageField}
                value={selectedLanguage}
                onChange={(option) => handleLanguageChange(option)}
                styles={navBarStyles}
                className="language-select"
                id="language-select"
              />
            </div>
            <div className="container">
              <div className="row py-5 mt-4 align-items-center" id="row">
                <div
                  className="col-md-5 mb-5 mb-md-0 d-none d-sm-none d-md-block text-center"
                  id="auth-image-slider-container"
                >
                  <AuthImageSlider />
                </div>
                <div className="col-md-7 col-lg-7 ml-auto">
                  {requireToken ? (
                    <div className="container-login100">
                      <div className="wrap-login100 p-0">
                        <div className="card-body">
                          <form className="login100-form validate-form">
                            <span className="login100-form-title">
                              Validate OTP
                            </span>
                            <p className="text-muted text-center">
                              We just sent you an email. Enter the security code
                              we sent to your email.
                            </p>
                            <div
                              className="wrap-input100 validate-input"
                              data-bs-validate="OTP is required"
                            >
                              <input
                                className="input100"
                                type="text"
                                name="otp"
                                value={otp}
                                onChange={(event) =>
                                  updateOTP(event.target.value)
                                }
                                placeholder={
                                  languageStatus
                                    ? dictionary["WO_ENTER_OT_430460335"]
                                    : "OTP"
                                }
                              />
                              <span className="focus-input100" />
                              <span className="symbol-input100">
                                <i className="fa fa-key" aria-hidden="true" />
                              </span>
                            </div>
                            <div className="text-end pt-1">
                              <p className="mb-0">
                                <NavLink
                                  onClick={resendOTP}
                                  to=""
                                  className="text-primary ms-1"
                                >
                                  {"I didn't receive a code"}
                                </NavLink>
                              </p>
                            </div>
                            <div className="container-login100-form-btn">
                              <button
                                disabled={disableOTPButton}
                                onClick={validateLoginOTP}
                                className="login100-form-btn btn-primary"
                              >
                                {languageStatus && requireToken
                                  ? dictionary["WO_VALIDATE_OT_1624166261"]
                                  : "Login"}
                                {loginState ? (
                                  <>
                                    &nbsp;
                                    <i className="fa fa-spinner fa-spin" />
                                  </>
                                ) : (
                                  ""
                                )}
                              </button>
                            </div>
                            <div className="text-center pointer pt-3">
                              <p
                                onClick={resetOtp}
                                className="text-primary ms-1 mb-0"
                              >
                                {"Attempt another login"}
                              </p>
                            </div>
                          </form>
                        </div>
                        {domainBrand["domainBrand"]["isGoogleLoginEnabled"] ||
                        domainBrand["domainBrand"]["isFacebookLoginEnabled"] ? (
                          <div className="card-footer">
                            <div className="d-flex justify-content-center my-3">
                              {domainBrand["domainBrand"][
                                "isGoogleLoginEnabled"
                              ] ? (
                                <a
                                  href="/"
                                  className="social-login text-center me-4"
                                >
                                  <i className="fab fa-google" />
                                </a>
                              ) : (
                                ""
                              )}
                              {domainBrand["domainBrand"][
                                "isFacebookLoginEnabled"
                              ] ? (
                                <a
                                  href="/"
                                  className="social-login text-center me-4"
                                >
                                  <i className="fab fa-facebook" />
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="container-login100">
                      <div className="wrap-login100 p-0">
                        <div className="card-body">
                          <div className="col col-login mx-auto">
                            <div className="text-center">
                              <img
                                src={domainBrand["domainBrand"]["logo"] || logo}
                                className="header-brand-img"
                                alt="LandingLogo"
                              />
                            </div>
                          </div>
                          <form className="login100-form validate-form">
                            <span className="login100-form-title fw-bolder">
                              Login
                            </span>
                            <div
                              className="wrap-input100 validate-input"
                              data-bs-validate="Valid email is required: ex@abc.xyz"
                              data-cy="Valid email is required: ex@abc.xyz"
                            >
                              <input
                                className="input100"
                                type="text"
                                name="email"
                                value={email}
                                onChange={(event) =>
                                  updateEmail(event.target.value)
                                }
                                readOnly={requireToken}
                                placeholder={
                                  languageStatus
                                    ? dictionary["para_email"]
                                    : "Email"
                                }
                              />
                              <span className="focus-input100" />
                              <span className="symbol-input100">
                                <img
                                  src={user}
                                  className="input-brand"
                                  alt="user-icon"
                                  id="user-brand"
                                />
                              </span>
                            </div>
                            <div
                              className="wrap-input100"
                              data-bs-validate="Password is required"
                              data-cy="Password is required"
                            >
                              <input
                                className="input100"
                                type="password"
                                name="password"
                                value={password}
                                id="password"
                                onChange={(event) =>
                                  updatePassword(event.target.value)
                                }
                                readOnly={requireToken}
                                placeholder={
                                  languageStatus
                                    ? dictionary["l_password"]
                                    : "Password"
                                }
                              />
                              <span className="focus-input100" />
                              <span className="symbol-input100">
                                <img
                                  src={passwordSvg}
                                  className="input-brand"
                                  alt="user"
                                  id="padlock"
                                />
                              </span>
                              <div className="eye-right">
                                {passwordType === "password" ? (
                                  <i
                                    onClick={() => togglePasswordField("text")}
                                    className="fa fa-eye"
                                  />
                                ) : (
                                  <i
                                    onClick={() =>
                                      togglePasswordField("password")
                                    }
                                    className="fa fa-eye-slash"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="text-end pt-1">
                              <p className="mb-0">
                                <NavLink
                                  to="/forgot-password"
                                  className="text-primary ms-1 fs-13"
                                  id="nav-link"
                                >
                                  {languageStatus
                                    ? dictionary["para_forgot_password"]
                                    : "Forgot Password?"}
                                </NavLink>
                              </p>
                            </div>
                            <div className="container-login100-form-btn">
                              <button
                                disabled={disableButton}
                                onClick={handleLogin}
                                className="login100-form-btn btn-primary"
                              >
                                {languageStatus
                                  ? dictionary["l_login"]
                                  : "Login"}
                                {loginState ? (
                                  <>
                                    &nbsp;
                                    <i className="fa fa-spinner fa-spin" />
                                  </>
                                ) : (
                                  ""
                                )}
                              </button>
                            </div>
                            <div
                              className="text-center pt-3 fs-13 m-3"
                              id="sign-up-for-account"
                            >
                              <p
                                className="text-dark mb-0"
                                id="sign-up-paragraph"
                              >
                                <NavLink
                                  to="/sign-up"
                                  className="text-primary ms-1"
                                >
                                  {languageStatus
                                    ? dictionary[
                                        "WO_SIGN_UP_FOR_AN_ACCOU_-546145385"
                                      ]
                                    : "Sign up for an account"}
                                </NavLink>
                              </p>
                            </div>
                          </form>
                        </div>
                        {domainBrand["domainBrand"]["isGoogleLoginEnabled"] ||
                        domainBrand["domainBrand"]["isFacebookLoginEnabled"] ? (
                          <div className="card-footer">
                            <div className="d-flex justify-content-center my-3">
                              {domainBrand["domainBrand"][
                                "isGoogleLoginEnabled"
                              ] ? (
                                <a
                                  href="/"
                                  className="social-login text-center me-4"
                                >
                                  <i className="fab fa-google" />
                                </a>
                              ) : (
                                ""
                              )}
                              {domainBrand["domainBrand"][
                                "isFacebookLoginEnabled"
                              ] ? (
                                <a
                                  href="/"
                                  className="social-login text-center me-4"
                                >
                                  <i className="fab fa-facebook" />
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
