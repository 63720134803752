import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Loading } from "../../../../../utils/helpers/constants";
import Body from "../corridor/Body";

import Header from "../../../../../components/header/Header";
import HeaderMenu from "../../../../../components/header/HeaderMenu";
import Footer from "../../../../../components/footer/Footer";
import SphereLoader from "../../../../../components/loaders/SphereLoader";
import { getCountries } from "../../../../../store/actions/country.action";

function BankConfigCorridor() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { domainBrand, component, country } = useSelector(
    ({ domainBrandReducer, componentReducer, countryReducer }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      country: countryReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    country.loadingCountries === Loading.FETCHING;

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) {
      dispatch(getCountries());
    }
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="settings"
              active="rates"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              currencies={country.currencies}
              countries={country.countries}
              bankConfigId={location?.state?.detail["bankConfigId"]}
              templateName={location?.state?.detail["templateName"]}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
}

export default BankConfigCorridor;
