import * as Yup from "yup";
import { customerTypes } from "./constants";

const beneficiaryValidatorHandler = (fields) => {
  const email = fields.some(
    (o) => o["fieldType"] === "EMAIL" || o["fieldType"] === "COMPANY_EMAIL"
  )
    ? Yup.string().email().required()
    : null;
  const phone = fields.some((o) => o["fieldType"] === "PHONE")
    ? Yup.string().required()
    : null;
  const address1 = fields.some((o) => o["fieldType"] === "ADDRESS_1")
    ? Yup.string().min(3).max(100).required()
    : null;
  const address2 = fields.some((o) => o["fieldType"] === "ADDRESS_2")
    ? Yup.string().min(3).max(100).required()
    : null;
  const city = fields.some((o) => o["fieldType"] === "CITY")
    ? Yup.string().min(3).max(100).required()
    : null;
  const postcode = fields.some((o) => o["fieldType"] === "POSTCODE")
    ? Yup.string().min(3).max(100).required()
    : null;
  const state = fields.some((o) => o["fieldType"] === "STATE")
    ? Yup.string().min(3).max(100).required()
    : null;
  return { email, phone, address1, address2, city, postcode, state };
};

export const signUpValidator = (mandatoryFields) => {
  const fields = beneficiaryValidatorHandler(mandatoryFields);

  return {
    ...fields,
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    companyName: Yup.string().when("type", {
      is: (val) => val.value === customerTypes.COMPANY,
      then: Yup.string().required(),
    }),
    companyRegistrationNumber: Yup.string().when("type", {
      is: (val) => val.value === customerTypes.COMPANY,
      then: Yup.string().required(),
    }),
    password: Yup.string().required(),
  };
};

export const signUpDetailsValidator = (mandatoryFields) => {
  return beneficiaryValidatorHandler(mandatoryFields);
};

export const beneficiaryValidator = (mandatoryFields) => {
  const fields = beneficiaryValidatorHandler(mandatoryFields);

  return {
    ...fields,
    firstName: Yup.string().when("type", {
      is: (val) => val.value === customerTypes.INDIVIDUAL,
      then: Yup.string().required(),
    }),
    lastName: Yup.string().when("type", {
      is: (val) => val.value === customerTypes.INDIVIDUAL,
      then: Yup.string().required(),
    }),
    companyName: Yup.string().when("type", {
      is: (val) => val.value === customerTypes.COMPANY,
      then: Yup.string().required(),
    }),
  };
};

export const updateBeneficiaryValidator = (mandatoryFields) => {
  return beneficiaryValidatorHandler(mandatoryFields);
};

export const requestAccountValidator = () => {
  return {
    currency: Yup.object({ value: Yup.string().required() }),
    accountName: Yup.string().required(),
  };
};

export const attachDocumentsValidator = () => {
  return {
    issueNo: Yup.string().required(),
  };
};

export const addKYCValidator = () => {
  return {
    country: Yup.object({ value: Yup.string().required() }),
    group: Yup.object({ value: Yup.string().required() }),
    ruleType: Yup.object({ value: Yup.string().required() }),
    restriction: Yup.object({ value: Yup.string().required() }),
    actionType: Yup.object({ value: Yup.string().required() }),
  };
};

export const addAMLValidator = () => {
  return {
    direction: Yup.object({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }),
    group: Yup.object({ value: Yup.string().required() }),
    ruleType: Yup.object({ value: Yup.string().required() }),
    country: Yup.object({ value: Yup.string().required() }),
    currency: Yup.object({ value: Yup.string().required }),
    restriction: Yup.object({ value: Yup.string().required }),
    numOfDays: Yup.number().required,
    buisnessRule: Yup.string().required,
    complianceCriteria: Yup.object({ value: Yup.string().required }),
    minNumOfMatches: Yup.number().required,
    minTransactionCount: Yup.number().required,
    deliveryMethod: Yup.object({ value: Yup.string().required() }),
  };
};

export const employeeSchema = Yup.object().shape({
  firstName: Yup.string().notRequired(""),
  lastName: Yup.string().notRequired(),
  address: Yup.string().notRequired(),
  province: Yup.string().notRequired(),
  city: Yup.string().notRequired(),
  staffId: Yup.string().notRequired(),
  postCode: Yup.string().notRequired(),
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const schemas = (type) => {
  if (type === "new-role") {
    return Yup.object().shape({
      email: Yup.string()
        .email()
        .matches(emailRegExp, "email is not valid")
        .required("Email is required"),
      reports: Yup.boolean().notRequired(),
    });
  } else if (type !== "new-role") {
    return Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email()
        .matches(emailRegExp, "email is not valid")
        .required("Email is required"),
      addressLine1: Yup.string().required("Address is required"),
      addressLine2: Yup.string().notRequired(),
      province: Yup.string().notRequired(),
      postCode: Yup.string().notRequired(),
      staffId: Yup.string().required(),
      tillAccountNumber: Yup.string().required(),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required"),
      transactionCurrency: Yup.string().notRequired(),
      city: Yup.string().required("City is required"),
      country: Yup.object({}).required("Country is required"),
      enablePayIn: Yup.boolean().notRequired(),
      enablePayOut: Yup.boolean().notRequired(),
      enableRefund: Yup.boolean().notRequired(),
      paymentCollector: Yup.boolean().notRequired(),
      enableFundWallet: Yup.boolean().notRequired(),
      enableWithdrawFromWallet: Yup.boolean().notRequired(),
      enableCreateWallet: Yup.boolean().notRequired(),
      multipleCountries: Yup.boolean().notRequired(),
      seeTransactions: Yup.boolean().notRequired(),
      seeApiTransactions: Yup.boolean().notRequired(),
      seeDetailedTransactions: Yup.boolean().notRequired(),
      seeTransactionSummary: Yup.boolean().notRequired(),
      seeWalletLedger: Yup.boolean().notRequired(),
      paidOutTransactions: Yup.boolean().notRequired(),
      seeOpeningBalance: Yup.boolean().notRequired(),
      seeOpeningBalanceHistory: Yup.boolean().notRequired(),
      recieveMoneyWithSystem: Yup.boolean().notRequired(),
      passwordExpires: Yup.boolean().notRequired(),
      passwordExpiration: Yup.object({}).notRequired(),
      approveProfile: Yup.boolean().notRequired(),
      userRoles: Yup.array().required(),
    });
  }
};

export const newEmployeeSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email().required("Email is required"),
  addressLine1: Yup.string().required("Address is required"),
  addressLine2: Yup.string().notRequired(),
  province: Yup.string().notRequired(),
  postCode: Yup.string().notRequired(),
  staffId: Yup.string().required(),
  tillAccountNumber: Yup.string().required(),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  transactionCurrency: Yup.string().notRequired(),
  city: Yup.string().required("City is required"),
  country: Yup.object({}).required("Country is required"),
  enablePayIn: Yup.boolean().notRequired(),
  enablePayOut: Yup.boolean().notRequired(),
  enableRefund: Yup.boolean().notRequired(),
  paymentCollector: Yup.boolean().notRequired(),
  enableFundWallet: Yup.boolean().notRequired(),
  enableWithdrawFromWallet: Yup.boolean().notRequired(),
  enableCreateWallet: Yup.boolean().notRequired(),
  multipleCountries: Yup.boolean().notRequired(),
  seeTransactions: Yup.boolean().notRequired(),
  seeApiTransactions: Yup.boolean().notRequired(),
  seeDetailedTransactions: Yup.boolean().notRequired(),
  seeTransactionSummary: Yup.boolean().notRequired(),
  seeWalletLedger: Yup.boolean().notRequired(),
  paidOutTransactions: Yup.boolean().notRequired(),
  seeOpeningBalance: Yup.boolean().notRequired(),
  seeOpeningBalanceHistory: Yup.boolean().notRequired(),
  recieveMoneyWithSystem: Yup.boolean().notRequired(),
  passwordExpires: Yup.boolean().notRequired(),
  passwordExpiration: Yup.object({}).notRequired(),
  approveProfile: Yup.boolean().notRequired(),
  userRoles: Yup.array().required(),
});

export const employeeInitialValues = (type) => {
  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    province: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postCode: "",
    phoneCountryCode: "",
    phone: "",
    staffId: "",
    tillAccountNumber: "",
    transactionCurrency: "",
    country: "",
    enablePayIn: false,
    enablePayOut: false,
    enableRefund: false,
    paymentCollector: false,
    enableFundWallet: false,
    enableWithdrawFromWallet: false,
    enableCreateWallet: false,
    multipleCountries: false,
    seeTransactions: false,
    seeApiTransactions: false,
    seeDetailedTransactions: false,
    seeTransactionSummary: false,
    seeWalletLedger: false,
    paidOutTransactions: false,
    seeOpeningBalance: false,
    seeOpeningBalanceHistory: false,
    recieveMoneyWithSystem: false,
    passwordExpires: false,
    passwordExpiration: "",
    approveProfile: false,
    userRoles: "",
  };

  if (type !== "new-role") {
    return initialValues;
  } else if (type === "new-role") {
    const payload = {
      email: "",
      reports: false,
      compliance: false,
      management: false,
      payCentreManagement: false,
      accounting: false,
      manageTreasuryAccount: false,
      forex: false,
      feeManagement: false,
      marketingCrm: false,
      itSecurity: false,
      fraudPreventionTeam: false,
      editorTeam: false,
      complianceTeam: false,
      regulator: false,
      paymentCollector: false,
      apiTransactionReport: false,
      salesPerson: false,
      customerServices: false,
    };
    return payload;
  }
};

export const interestRateSchema = Yup.object().shape({
  chargeRef: Yup.string().required("This field is required"),
  interestRateType: Yup.object({}).required("This field is required"),
  charge: Yup.number().required("charge is a required field"),
  repeatDaysCount: Yup.number().required("Days count is a required field"),
  repeatDaysCountUnit: Yup.object({}).required("Days count unit is required"),
  gracePeriod: Yup.number().required("Principal is a required field"),
  accountMaintenanceFeeInterestType: Yup.object({}).required(
    "interest type is required"
  ),
  maximum_amount: Yup.number().required("Max amount is required"),
  minimum_amount: Yup.number().required("Min amount is required"),
  principal: Yup.number().notRequired(),
  accountType: Yup.object({}).required("Acc type is required"),
  accountMaintenanceFeePaymentMethod: Yup.object({}).required(
    "Payment method typr is required"
  ),
  interestCalculationMethods: Yup.object({}).required(
    "interest method field is required"
  ),
  password: Yup.string().required("password is required"),
});

export const interestInitialValues = (actionType, intObject = {}) => {
  if (actionType === "ADD-INTEREST" && Object.keys(intObject).length === 0) {
    return {
      chargeRef: "",
      interestRateType: "",
      charge: 0,
      repeatDaysCount: 0,
      repeatDaysCountUnit: "DAY",
      gracePeriod: 0,
      accountMaintenanceFeeInterestType: "SIMPLE_INTEREST",
      maximum_amount: 0,
      minimum_amount: 0,
      principal: 0,
      accountType: "CURRENT_ACCOUNT",
      accountMaintenanceFeePaymentMethod: "STANDARD_PAYMENTS",
      interestCalculationMethods: "FIXED_FLAT",
      password: "",
    };
  } else if (
    actionType === "EDIT-INTEREST" &&
    Object.keys(intObject).length !== 0
  ) {
    return {
      chargeRef: intObject ? intObject["feeReferenceCode"] : "",
      interestRateType: intObject ? intObject["interestRateType"] : "",
      charge: intObject ? intObject["amount"] : 0,
      repeatDaysCount: intObject ? intObject["repeatDaysCount"] : 0,
      repeatDaysCountUnit: "DAY",
      gracePeriod: intObject ? intObject["gracePeriod"] : 0,
      accountMaintenanceFeeInterestType: intObject
        ? intObject["accountMaintenanceFeeInterestType"]
        : "SIMPLE_INTEREST",
      maximum_amount: intObject ? intObject["maximum_amount"] : 0,
      minimum_amount: intObject ? intObject["minimum_amount"] : 0,
      principal: 0,
      accountType: "CURRENT_ACCOUNT",
      accountMaintenanceFeePaymentMethod: intObject
        ? intObject["accountMaintenanceFeePaymentMethod"]
        : "STANDARD_PAYMENTS",
      interestCalculationMethods: intObject
        ? intObject["interestCalculationMethods"]
        : "FIXED_FLAT",
      password: intObject ? intObject["password"] : "",
    };
  }
};
