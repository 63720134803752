import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function UpdateDetails({
  dictionary,
  languageStatus,
  currency,
  countries,
  controlView = true || false,
  customerObject,
}) {
  const formRef = useRef();

  const schema = yup
    .object({
      category: yup.string().required(),
      firstName: yup.string().notRequired(),
      lastName: yup.string().notRequired(),
      middleName: yup.string().notRequired(),
      addressLine1: yup.string().notRequired(),
      addressLine2: yup.string().notRequired(),
      dialCodeAndPhone: yup.string().required(),
      areaPostCode: yup.string().required(),
      email: yup.string().email().required(),
      city: yup.string().required(),
      currencyCode: yup.string().required(),
      openingAccount: yup.string().required(),
      country: yup.string().required(),
      nationality: yup.string().required(),
      password: yup.string().required(),
    })
    .required();

  const { handleSubmit, register, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      currency: "",
      category: customerObject ? customerObject["type"] : "",
      firstName: customerObject ? customerObject["firstName"] : "",
      lastName: customerObject ? customerObject["lastName"] : "",
      middleName: customerObject ? customerObject["middleName"] : "",
      addressLine1: "",
      addressLine2: "",
      dialCodeAndPhone: customerObject ? customerObject["phoneNumber"] : "",
      areaPostCode: "",
      email: customerObject ? customerObject["emailAddress"] : "",
      city: customerObject ? customerObject["countryCommonName"] : "",
      currencyCode: customerObject ? customerObject["currencyCode"] : "",
      openingAccount: customerObject ? customerObject["openingAccount"] : "",
      country: customerObject ? customerObject["countryIso3"] : "",
    },
  });

  const onSubmit = (data) => {
    console.log(data, "kl");
  };

  return (
    <div className="card-body">
      <form
        className="needs-validation"
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
      >
        <div className="form-row">
          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_transaction_tyep"] : "Type"}
              <span style={{ color: "red" }}> *</span>
            </label>
            <select
              {...register("category")}
              className="form-control select"
              name="category"
              id="category"
            >
              <option value="" selected hidden disabled>
                Select...
              </option>
              <option value="INDIVIDUAL">INDIVIDUAL</option>
              <option value="COMPANY">COMPANY</option>
            </select>
          </div>

          {watch("category") === "INDIVIDUAL" ? (
            <>
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_first_name"]
                    : "First Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>

                <input
                  type={"text"}
                  className="form-control border px-3"
                  name="firstName"
                  id="firstName"
                  {...register("firstName")}
                />
              </div>

              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus ? dictionary["para_last_name"] : "Last Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>

                <input
                  type={"text"}
                  className="form-control border px-3"
                  name="lastName"
                  id="lastName"
                  {...register("lastName")}
                />
              </div>

              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["WO_MIDDLE_NAM_1847397348"]
                    : "Middle Name"}
                  <span style={{ color: "red" }}> *</span>
                </label>

                <input
                  type={"text"}
                  className="form-control border px-3"
                  name="middleName"
                  id="middleName"
                  {...register("middleName")}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["para_address1"] : "Address line 1"}
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"text"}
              className="form-control border px-3"
              name="addressLine1"
              id="addressLine1"
              {...register("addressLine1")}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_address_line_2_"]
                : "Address line 2"}
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"text"}
              className="form-control border px-3"
              name="addressLine2"
              id="addressLine2"
              {...register("addressLine2")}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {/* {languageStatus
                ? dictionary[""]
                : "Country Dialing Code + Phone "} */}
              Country Dialing Code + Phone
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"text"}
              className="form-control border px-3"
              name="dialCodeAndPhone"
              id="dialCodeAndPhone"
              {...register("dialCodeAndPhone", {
                required: {
                  value: true,
                  message: "country dial code and phone number is required",
                },
              })}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_postcode"] : "Area/post Code"}
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"text"}
              className="form-control border px-3"
              name="areaPostCode"
              id="areaPostCode"
              {...register("areaPostCode", {
                required: {
                  value: true,
                  message: "post code is required",
                },
              })}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["WO_EMAI_1903518907"] : "Email"}
              <span style={{ color: "red" }}> *</span>
            </label>

            <input
              type={"text"}
              className="form-control border px-3"
              name="email"
              id="email"
              {...register("email", {
                required: {
                  value: true,
                  message: "email is required",
                },
              })}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["WO_CIT_-488793102"] : "City"}
              <span style={{ color: "red" }}> *</span>
            </label>
            <input
              type={"text"}
              className="form-control border px-3"
              name="city"
              id="city"
              {...register("city", {
                required: {
                  value: true,
                  message: "city is required",
                },
              })}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <div className="row row-sm">
              <div className="col-md-6">
                <label>
                  {languageStatus
                    ? dictionary["WO_CURRENCY_COD_1626671491"]
                    : "Currency Code"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <select
                  {...register("currencyCode")}
                  className="form-control select"
                  name="currencyCode"
                  id="currencyCode"
                >
                  <option value="" selected hidden disabled>
                    Select...
                  </option>
                  {currency?.map((i) => (
                    <>
                      <option value={i.value} key={i.value}>
                        {i.label}
                      </option>
                    </>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label>
                  {languageStatus
                    ? dictionary["WO_OPENING_BALANCE_FE_-1248866573"]
                    : "Opening Account Fee"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type={"text"}
                  className="form-control border px-3"
                  id="openingAccount"
                  name="openingAccount"
                  {...register("openingAccount", {
                    required: {
                      value: true,
                      message: "opening account is required",
                    },
                  })}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <div className="row row-sm">
              <div className="col-md-6">
                <label>
                  {languageStatus
                    ? dictionary["WO_COUNTRY_1435082597"]
                    : "Country"}
                  <span style={{ color: "red" }}> *</span>
                </label>

                <select
                  {...register("country")}
                  className="form-control select"
                  name="country"
                  id="country"
                >
                  <option value="" selected hidden disabled>
                    Select...
                  </option>
                  {countries?.map((i) => (
                    <>
                      <option value={i.iso3Code} key={i.iso3Code}>
                        {i.common_name}
                      </option>
                    </>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label>
                  {languageStatus
                    ? dictionary["para_nationality"]
                    : "Nationality"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <select
                  {...register("nationality")}
                  className="form-control select"
                  name="nationality"
                  id="nationality"
                >
                  <option value="" selected hidden disabled>
                    Select...
                  </option>
                  {countries?.map((i) => (
                    <>
                      <option value={i.iso3Code} key={i.iso3Code}>
                        {i.common_name}
                      </option>
                    </>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <label className="fw-bold">
              {languageStatus
                ? dictionary["l_retype_password"]
                : "Please enter password for security reasons "}
              <span style={{ color: "red" }}> *</span>
            </label>
            <input
              type={"password"}
              className="form-control border px-3"
              placeholder=""
              name="password"
              id="password"
              autoComplete=""
              {...register("password", {
                required: {
                  value: true,
                  message: "password is required",
                },
              })}
            />
          </div>

          <div className="col-md-6">
            <button
              className="btn btn-primary margin-label width-100 fw-bold"
              type="submit"
            >
              SAVE CHANGES
            </button>
          </div>

          {/* COMPANY INFORMATION  */}
          <div className="col-md-6 col-lg-6 mb-3">
            {controlView === false && (
              <>
                <h4 className="fw-bold mt-3">COMPANY INFORMATION</h4>

                <hr />
              </>
            )}

            <div className="row row-sm">
              {controlView === false && (
                <>
                  <div className="col-md-6 mb-3">
                    <label className="fw-bold">
                      {languageStatus
                        ? dictionary["WO_COMPANY_NAM_-805761030"]
                        : "Company Name"}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type={"text"}
                      className="form-control border px-3"
                      placeholder=""
                      value={""}
                      name="address_line_2"
                      id="address_line_2"
                      //   onChange={(e) => setName(e.target.value)}
                      autoComplete=""
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="fw-bold">
                      {languageStatus
                        ? dictionary["l_registration_no"]
                        : "Registration No"}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type={"text"}
                      className="form-control border px-3"
                      placeholder=""
                      value={""}
                      name="address_line_2"
                      id="address_line_2"
                      //   onChange={(e) => setName(e.target.value)}
                      autoComplete=""
                    />
                  </div>
                </>
              )}
            </div>

            {/* <div className="row row-sm"> */}

            {/* </div> */}
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdateDetails;
