import Axios from "./axios.service";

export const createAccountProduct = async (payload) => {
  return Axios({
    method: "POST",
    url: `/web-services/api/v6/services/admin/account-product/create-account-product`,
    data: payload,
  });
};

export const listAccountProduct = async (payload) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/account-product/account-products?page=1&size=15`,
    data: payload,
  });
};

export const activateAccountProduct = async (account_id) => {
  return Axios({
    method: "PUT",
    url: `/web-services/api/v6/services/admin/account-product/activate-account-product/${account_id}`,
  });
};

export const deactivateAccountProduct = async (account_id) => {
  return Axios({
    method: "DELETE",
    url: `/web-services/api/v6/services/admin/account-product/deactivate-account-product/${account_id}`,
  });
};
