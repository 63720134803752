import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

// import { addAMLValidator } from "../../../../../utils/helpers/validationHelpers";
import {
  amlRuleDirectionOptions,
  // amlRuleTypeOptions,
  complianceGroupOptions,
  currencies,
  deliveryMethod,
  restrictionOptions,
  sanctionComplianceCriteria,
  threshold,
} from "../../../../../utils/helpers/objectHelpers";
// import { errorStyles } from "../../../../../components/selectStyles/customStyles";
import { toast } from "react-hot-toast";
import {
  // addAMLRules,
  getAMLRules,
} from "../../../../../store/actions/compliance.action";
import { createAmlRules } from "../../../../../utils/services/customers.service";

const AddNewAMLRule = ({
  languageStatus,
  dictionary,
  countries,
  data = {},
}) => {
  const menuPortalTarget = document.getElementById("root");
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState("");
  const [complianceGroup, setComplianceGroup] = useState("");
  const [payCenterId, setPayCenterId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [complianceGroupType, setComplianceGroupType] = useState("");
  const [idReq, setIdReq] = useState(false);
  const [photoId, setPhotoId] = useState(false);
  const [addressProof, setAddressProof] = useState(false);
  const [sourceReq, setSourceReq] = useState(false);
  const [customerDec, setCustomerReq] = useState(false);
  const [miniTransactionCount, setMiniTransactionCount] = useState("");
  // const [ruleType, setRuleType] = useState("");
  const [amountFrom, setAmountFrom] = useState("");
  const [ruleScore, setRuleScore] = useState("");
  const [deliveryMode, setDeliveryMode] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [numOfDays, setNumOfDays] = useState("");
  // const [businessRule, setBusinessRule] = useState("");
  const [restrict, setRestriction] = useState("");
  const [complianceMode, setComplianceMode] = useState("");
  const [minNumOfMatches, setMinNumOfMatches] = useState("");
  const [amountTo, setAmountTo] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const ruleDirectionDropdown = amlRuleDirectionOptions(
    languageStatus,
    dictionary
  );

  const groupDropdown = complianceGroupOptions(
    languageStatus,
    dictionary,
    "AML"
  );

  // const ruleTypeDropdown = amlRuleTypeOptions(languageStatus, dictionary);

  const restriction = restrictionOptions(languageStatus, dictionary);

  const complianceCriteria = sanctionComplianceCriteria(
    languageStatus,
    dictionary
  );

  const deliveries = deliveryMethod();

  const addNewAMLRule = async () => {
    const payload = {
      businessTransactionRuleDTO: [
        {
          amountFrom: parseInt(amountFrom) || 900,
          amountTo: parseInt(amountTo) || 1100,
          currencyCode: currency.value,
          message: message,
          // ruleType: ruleType.value,
          restriction: restrict.value,
          country: country.value,
          countryToOpt: null,
          direction: direction.value,
          deliveryMethod: deliveryMode.value,
          thresholdType: complianceGroupType.value,
          thresholdLimitType: complianceGroup.value,
          noOfDays: parseInt(numOfDays),
          idRequired: idReq,
          addressProofRequired: addressProof,
          photoIdRequired: photoId,
          sourceOfIncomeRequired: sourceReq,
          customerDeclarationRequired: customerDec,
          payCenterId: null,
          customerId: null,
          sanctionComplianceCriteria: complianceMode.value,
          miniNoOfMatches: parseInt(minNumOfMatches) || 0,
          // miniNoOfMatches: 0,
          miniTransactionCount: parseInt(miniTransactionCount) || 0,
          // miniTransactionCount: 0,
          ruleWeight: parseInt(ruleScore),
        },
      ],
    };
    setLoading(true);
    const data = await createAmlRules(payload);
    if (data?.data?.status !== "FAILED") {
      toast.success(data?.data?.message);
      setLoading(false);
      setAddressProof(false);
      setAmountFrom("");
      setAmountTo("");
      // setBusinessRule("");
      setComplianceGroup("");
      setComplianceGroupType("");
      setCountry("");
      setCurrency("");
      setDeliveryMode("");
      setDirection("");
      setComplianceGroup("");
      setComplianceGroupType("");
      setComplianceMode("");
      setMinNumOfMatches("");
      setRuleScore("");
      setNumOfDays("");
      dispatch(getAMLRules());
    } else {
      toast.error(data?.data?.message);
      setLoading(false);
    }
  };

  return (
    <div className="card-body">
      <form className="needs-validation" onSubmit={(e) => e.preventDefault()}>
        <div className="form-row">
          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["WO_DIRECTIO_-1508617426"]
                : "When to apply rule"}
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={ruleDirectionDropdown}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              // styles={styles}
              value={direction}
              onChange={(option) => setDirection(option)}
            />
          </div>

          {/* HAS A TRIGGER */}
          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus ? dictionary["m_security8"] : "Group"}
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={groupDropdown}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              // styles={styles}
              value={complianceGroup}
              onChange={(option) => setComplianceGroup(option)}
            />
          </div>

          {/* GROUP TRIGGER */}
          {complianceGroup.value === "PAYCENTER" ? (
            <>
              <div className="col-md-6 col-lg-6">
                <div className="d-flex flex-row justify-content-between align-items-center py-5">
                  <label className="d-flex flex-row align-items-center border rounded-top rounded-bottom">
                    <input
                      type={"checkbox"}
                      className="pe-3 "
                      value={payCenterId}
                      onChange={(e) =>
                        setPayCenterId(e.target.value === "" && null)
                      }
                      id="all"
                      name="paycenter"
                      checked
                    />{" "}
                    <span className="ps-3">All</span>
                  </label>
                  <label className="d-flex  align-items-center w-75">
                    <span className="pe-3">Paycenter</span>{" "}
                    <input
                      type={"text"}
                      className="form-control border px-3"
                      placeholder="Search..."
                      autoComplete=""
                      disabled
                    />
                  </label>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 col-lg-6">
                <div className="d-flex flex-row justify-content-between align-items-center py-5">
                  <label className="d-flex flex-row align-items-center border rounded-top rounded-bottom">
                    <input
                      type={"checkbox"}
                      className="pe-3"
                      value={customerId}
                      onChange={() => setCustomerId(null)}
                      id="all-customer"
                      name="customer"
                      checked
                    />{" "}
                    <span className="ps-3">
                      {languageStatus
                        ? dictionary["WO_SELECT_AL_1255003187"]
                        : "All"}
                    </span>
                  </label>
                  {/* para_customer_ */}
                  <label className="d-flex flex-col align-items-center">
                    <span className="pe-3">
                      {languageStatus
                        ? dictionary["para_customer"]
                        : "Customer"}
                    </span>{" "}
                    <input
                      type={"text"}
                      className="form-control border px-3"
                      placeholder="Search..."
                      autoComplete=""
                      disabled
                    />
                  </label>
                </div>
              </div>
            </>
          )}

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {/* {languageStatus ? dictionary["m_security8"] : "Group"} */}
              Scope of rule
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={threshold}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              // styles={styles}
              value={complianceGroupType}
              onChange={(option) => setComplianceGroupType(option)}
            />
          </div>

          {complianceGroupType.value === "THRESHOLD_IDENTIFICATION" && (
            <>
              <div className="col-md-6 col-lg-6 mb-3">
                <p className="fw-bold text-dark">
                  Required Documents (Customer will be asked to upload the
                  selected document(s) while performing a transaction)
                </p>
                <hr />
                {/* ID required */}
                <label className="d-flex flex-row align-items-center border rounded-top rounded-bottom">
                  <input
                    type={"checkbox"}
                    className="pe-3 "
                    value={idReq}
                    onChange={(e) => setIdReq(!idReq)}
                    id="id-required"
                    name="id-required"
                  />{" "}
                  <span className="ps-3">
                    Upload one of the following documents (PASSPORT, NATIONAL
                    ID, DRIVING LICENSE, VOTER'S CARD)
                  </span>
                </label>
                <label className="d-flex flex-row align-items-center border rounded-top rounded-bottom d-none">
                  <input
                    type={"checkbox"}
                    className="pe-3"
                    value={photoId}
                    onChange={() => setPhotoId(!photoId)}
                    id="photo-id"
                    name="photo-id"
                  />{" "}
                  <span className="ps-3">Photo ID</span>
                </label>
                <label className="d-flex flex-row align-items-center border rounded-top rounded-bottom">
                  <input
                    type={"checkbox"}
                    className="pe-3 "
                    value={addressProof}
                    onChange={() => setAddressProof(!addressProof)}
                    id="address-proof"
                    name="address-proof"
                  />{" "}
                  <span className="ps-3">Address Proof Required</span>
                </label>

                <label className="d-flex flex-row align-items-center border rounded-top rounded-bottom">
                  <input
                    type={"checkbox"}
                    className="pe-3 "
                    value={sourceReq}
                    onChange={() => setSourceReq(!sourceReq)}
                    id="source-req"
                    name="source-req"
                  />{" "}
                  <span className="ps-3">Source of income proof required</span>
                </label>

                <label className="d-flex flex-row align-items-center border rounded-top rounded-bottom">
                  <input
                    type={"checkbox"}
                    className="pe-3 "
                    vvalue={customerDec}
                    onChange={() => setCustomerReq(!customerDec)}
                    id="customer-dec"
                    name="customer-dec"
                  />{" "}
                  <span className="ps-3">Customer Declaration Required</span>
                </label>
              </div>
            </>
          )}

          {complianceGroupType.value === "LINKED_TRANSACTION" && (
            <div className="col-md-6 col-lg-6 mb-3">
              <label>
                {/* {languageStatus
                ? dictionary["l_destination_country"]
                : "Receiving Country"} */}{" "}
                Minimum Transaction Count
                <span style={{ color: "red" }}> *</span>
              </label>
              <div>
                <input
                  type={"text"}
                  value={miniTransactionCount}
                  onChange={(e) => setMiniTransactionCount(e.target.value)}
                  id="miniTransactionCount"
                  className="border form-control "
                />
              </div>
            </div>
          )}

          {/* <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Rule Type
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={ruleTypeDropdown}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              // styles={styles}
              value={ruleType}
              onChange={(option) => setRuleType(option)}
            />
          </div> */}

          {/* {ruleType.value === "GENERIC_ACCOUNT" && (
            <div className="col-md-6 col-lg-6 mb-3">
              <label>
                Account limit
                <span style={{ color: "red" }}> *</span>
              </label>
              <div>
                <input
                  type={"text"}
                  value={amountFrom}
                  onChange={(e) => setAmountFrom(e.target.value)}
                  id="amount-from"
                  className="border form-control "
                />
              </div>
            </div>
          )} */}

          {/* {ruleType.value === "ONE_OFF_TRANSACTION" && (
            <>
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  Minimum Transaction Count
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div>
                  <input
                    type={"text"}
                    value={miniTransactionCount}
                    onChange={(e) => setMiniTransactionCount(e.target.value)}
                    id="miniTransactionCount"
                    className="border form-control "
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                 
                  Amount from
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div>
                  <input
                    type={"text"}
                    value={amountFrom}
                    onChange={(e) => setAmountFrom(e.target.value)}
                    id="amountfrom"
                    className="border form-control "
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["WO_RULE_SCOR_-1334001714"] + " (%)"
                    : "Rule Score"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div>
                  <input
                    type={"number"}
                    value={ruleScore}
                    onChange={(e) => setRuleScore(e.target.value)}
                    id="ruleScore"
                    className="border form-control "
                  />
                </div>
              </div>
            </>
          )} */}

          {/* {ruleType.value === "LINKED_TRANSACTION" && (
            <>
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  
                  Minimum Transaction Count
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div>
                  <input
                    type={"text"}
                    value={miniTransactionCount}
                    onChange={(e) => setMiniTransactionCount(e.target.value)}
                    id="miniTransactionCount"
                    className="border form-control "
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  
                  Amount from
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div>
                  <input
                    type={"text"}
                    value={amountFrom}
                    onChange={(e) => setAmountFrom(e.target.value)}
                    id="amountfrom"
                    className="border form-control "
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                 
                  Rule Score(%)
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div>
                  <input
                    type={"number"}
                    value={ruleScore}
                    onChange={(e) => setRuleScore(e.target.value)}
                    id="ruleScore"
                    className="border form-control "
                  />
                </div>
              </div>
            </>
          )} */}

          {/* {ruleType.value === "SANCTION_WORKFLOW" && (
            <>
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  Minimum Transaction Count
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div>
                  <input
                    type={"text"}
                    value={miniTransactionCount}
                    onChange={(e) => setMiniTransactionCount(e.target.value)}
                    id="miniTransactionCount"
                    className="border form-control "
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  Amount from
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div>
                  <input
                    type={"text"}
                    value={amountFrom}
                    onChange={(e) => setAmountFrom(e.target.value)}
                    id="amountfrom"
                    className="border form-control "
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  Rule Score(%)
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div>
                  <input
                    type={"number"}
                    value={ruleScore}
                    onChange={(e) => setRuleScore(e.target.value)}
                    id="ruleScore"
                    className="border form-control "
                  />
                </div>
              </div>
            </>
          )} */}

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_delivery_method"]
                : "Delivery Method"}

              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={deliveries}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              // styles={styles}
              value={deliveryMode}
              onChange={(option) => setDeliveryMode(option)}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["l_destination_country"]
                : "Receiving Country"}
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={countries}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              // styles={styles}
              value={country}
              onChange={(option) => setCountry(option)}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_currency_code"]
                : "Currency Code"}
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={currencies}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              // styles={styles}
              value={currency}
              onChange={(option) => setCurrency(option)}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Number of Days
              <span style={{ color: "red" }}> *</span>
            </label>
            <div>
              <input
                type={"text"}
                value={numOfDays}
                onChange={(e) => setNumOfDays(e.target.value)}
                className="border form-control"
                id="numOfDays"
              />
            </div>
          </div>

          {/* <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Business Rule Number
              <span style={{ color: "red" }}> *</span>
            </label>
            <div>
              <input
                type={"text"}
                value={businessRule}
                id="businessRule"
                onChange={(e) => setBusinessRule(e.target.value)}
                className="border form-control "
              />
            </div>
          </div> */}

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["WO_RULE_SCOR_-1334001714"] + "(%)"
                : "Rule Score(%)"}

              <span style={{ color: "red" }}> *</span>
            </label>
            <input
              type={"number"}
              value={ruleScore}
              onChange={(e) => setRuleScore(e.target.value)}
              id="ruleScore"
              className="border form-control "
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Restriction
              <span style={{ color: "red" }}> *</span>
            </label>

            <Select
              options={restriction}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              // styles={styles}
              value={restrict}
              onChange={(option) => setRestriction(option)}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Sanction Compliance Criteria
              <span style={{ color: "red" }}> *</span>
            </label>

            <Select
              options={complianceCriteria}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              // styles={styles}
              value={complianceMode}
              onChange={(option) => setComplianceMode(option)}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Minimum Number of Matches
              <span style={{ color: "red" }}> *</span>
            </label>
            <div>
              <input
                type={"number"}
                value={minNumOfMatches}
                id="minNumOfMatches"
                onChange={(e) => setMinNumOfMatches(e.target.value)}
                className="border form-control "
              />
            </div>
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Amount from
              <span style={{ color: "red" }}> *</span>
            </label>
            <div>
              <input
                type={"text"}
                value={amountFrom}
                onChange={(e) => setAmountFrom(e.target.value)}
                id="amountfrom"
                className="border form-control "
              />
            </div>
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Amount to
              <span style={{ color: "red" }}> *</span>
            </label>
            <div>
              <input
                type={"number"}
                value={amountTo}
                onChange={(e) => setAmountTo(e.target.value)}
                className="border form-control"
                id="amounTo"
              />
            </div>
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Displayed message to customer/compliance officer{" "}
              <span style={{ color: "red" }}> *</span>
            </label>
            <textarea
              className="px-3 form-control border"
              placeholder="Type here..."
              autoComplete="on"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              id="message"
            />
          </div>

          <div className="col-md-12 mb-3 text-end">
            <button
              onClick={addNewAMLRule}
              className="btn btn-primary margin-label width-25 fw-bold"
              type="button"
            >
              Create
              {loading ? (
                <>
                  &nbsp;
                  <i className="fa fa-spinner fa-spin" />
                </>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewAMLRule;

/**
 * Airtime TopUp
Bill Payment
Transfer Funds
Cash Pickup
Mobile Money
Transfer History
Add Money all leads to payment method 
 */
