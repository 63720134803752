import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { corridorTableConfig } from "../../../../utils/helpers/dataTableConfigs";
import DataTable from "../../../../components/dataTable/dataTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Trash } from "../../../../assets/images/svgs/trash.svg";
import ProductModal from "../../../../components/modals/ProductModal";
import {
  deleteCorridor,
  suspendCorridor,
  unSuspendCorridor,
} from "../../../../utils/services/corridor.service";
import toast from "react-hot-toast";
import { fetchCorridors } from "../../../../store/actions/corridor.action";
import "./corridor.css";
import SuspendModal from "../../../../components/modals/SuspendModal";

const Body = ({ domainBrand, corridor = [] }) => {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const [filterData, setFilterData] = useState("");
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 10,
    accessor: "actions",
    Cell: ({ row }) => {
      const approval = row.original.approved;

      return (
        <div className="text-center pointer d-flex flex-row align-items-center justify-content-center">
          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={
              <Tooltip id={`tooltip-${``}`}>
                {approval ? "Suspend" : "Unsuspend"}
              </Tooltip>
            }
          >
            {approval === true ? (
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked
                  onClick={() => {
                    setChecked("SUSPEND");
                    setShow(true);
                    setId(row.original.id);
                  }}
                  readOnly
                />
                <label
                  class="form-check-label"
                  for="flexSwitchCheckDefault"
                  onClick={() => {
                    setChecked("UNSUSPEND");
                    setShow(true);
                    setId(row.original.id);
                  }}
                ></label>
              </div>
            ) : (
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckCheckedDisabled"
                  checked
                  disabled
                  onClick={() => {
                    setChecked("UNSUSPEND");
                    setShow(true);
                    setId(row.original.id);
                  }}
                  readOnly
                />
                <label
                  class="form-check-label"
                  for="flexSwitchCheckCheckedDisabled"
                  onClick={() => {
                    setChecked("UNSUSPEND");
                    setShow(true);
                    setId(row.original.id);
                  }}
                ></label>
              </div>
            )}
          </OverlayTrigger>

          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Delete</Tooltip>}
          >
            <Trash
              onClick={() => {
                setShow(true);
                setId(row.original.id);
              }}
              style={{
                alignSelf: "baseline",
                verticalAlign: "baseline",
                marginTop: "-3px",
              }}
            />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...corridorTableConfig, actionColumn];
  const columns = useMemo(() => tableObject, []);

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const res = await deleteCorridor(id);

      if (res?.data?.status === "SUCCESS") {
        toast.success("corridor deleted successfully");
        setLoading(false);
        setShow(false);
        dispatch(fetchCorridors());
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log(error?.response, "k");
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  const handleSuspend = async (id) => {
    setLoading(true);
    try {
      const res = await suspendCorridor(id);
      if (res?.data?.status === "SUCCESS") {
        toast.success("corridor suspended");
        setLoading(false);
        setShow(false);
        dispatch(fetchCorridors());
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  const handleUnsuspend = async (id) => {
    setLoading(true);
    try {
      const res = await unSuspendCorridor(id);
      if (res?.data?.status === "SUCCESS") {
        toast.success("corridor unsuspended");
        setLoading(false);
        setShow(false);
        dispatch(fetchCorridors());
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <ProductModal
        show={show}
        handleClose={() => setShow(false)}
        type="KYC-DELETE"
        handleDeleteKyc={() => handleDelete(id)}
        loading={loading}
      />
      <SuspendModal
        show={show}
        handleClose={() => {
          setShow(false);
          setChecked("");
        }}
        type={checked}
        handleSuspend={() => handleSuspend(id)}
        handleUnsuspend={() => handleUnsuspend(id)}
        loading={loading}
      />
      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">
                {/* {languageStatus
                ? dictionary["para_global_exchange_rates"]
                : "Exchange Rate"} */}
                Transaction Corridors
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  {/* {languageStatus
                  ? dictionary["para_use_this_section_to_edit_exchange_rates"]
                  : "Exchange Rate"} */}
                  Use this section to set up corridors and services you wish to
                  operate in the selected countries.
                </li>
              </ol>
            </div>
          </div>
          <div className="row row-sm">
            <div className="col-lg-12">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title fw-bold">
                    {dictionary
                      ? dictionary["WO_ALL_TRANSACTION_CORR_438999497"]
                      : "ALL TRANSACTION CORRIDORS"}
                  </h3>
                </div>
                <div className="card-body">
                  <div className="col-md-6 col-lg-6">
                    <p className="text-muted card-sub-title">
                      Sort and filter table
                    </p>

                    <input
                      type={"text"}
                      className="form-control border px-3"
                      placeholder="Search..."
                      autoComplete=""
                      value={filterData}
                      onChange={(e) => setFilterData(e.target.value)}
                    />
                  </div>
                  <div className="table-responsive mt-5">
                    <DataTable
                      columns={columns}
                      data={corridor}
                      filterData={filterData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Body;
