import { useState, useMemo } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "../../../../components/dataTable/dataTable";
import { treasuryAccountTypes } from "../../../../utils/helpers/objectHelpers";
import { ReactComponent as Lock } from "../../../../assets/images/svgs/lock.svg";
import { ReactComponent as Unlock } from "../../../../assets/images/svgs/lock-off.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { managementConfig } from "../../../../utils/helpers/dataTableConfigs";
import { Loading } from "../../../../utils/helpers/constants";
import { getTreasuryAccount } from "../../../../store/actions/treasury.action";

const Body = ({ list, currency, users }) => {
  const dispatch = useDispatch();

  const menuPortalTarget = document.getElementById("root");

  const [filterInput] = useState("");
  const [accountType, setAccountType] = useState({});
  const [money, setMoney] = useState({});

  const { treasuries } = useSelector(({ treasuryReducer }) => ({
    treasuries: treasuryReducer,
  }));

  const payload = {
    currency: money?.currencyCode,
    walletAccountType: accountType?.value,
  };

  const fetchTreasury = async (payload) => {
    const { currency, walletAccountType } = payload;
    if (currency !== "" && walletAccountType !== "") {
      await dispatch(getTreasuryAccount(payload));
      if (treasuries.loadingTreasuries === Loading.SUCCESS) {
        setAccountType("");
        setMoney("");
      }
    }
  };

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 5,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-center pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Suspend</Tooltip>}
          >
            <Lock />
          </OverlayTrigger>

          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Unrestrict</Tooltip>}
          >
            <Unlock />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...managementConfig, actionColumn];
  const columns = useMemo(() => tableObject, []);
  // const handleFilterChange = (e) => setFilterInput(e.target.value);

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">Treasury Accounts</h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>

        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h3 className="card-title mb-1">All Treasury Accounts</h3>
                  <p className="text-muted card-sub-title">
                    Filter account to fetch the account lists
                  </p>
                </div>
                <div className="table-responsive">
                  <div className="form-row mb-5 pb-5">
                    <div className="col-md-6 col-lg-6">
                      <label>Account Type</label>
                      <Select
                        options={treasuryAccountTypes}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        value={accountType}
                        onChange={(option) => setAccountType(option)}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <label>
                        Currency
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <Select
                        options={currency}
                        isClearable={false}
                        isSearchable={false}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="currency"
                        value={money}
                        onChange={(option) => setMoney(option)}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <button
                        className="btn btn-primary margin-label w-100"
                        type="button"
                        onClick={() => fetchTreasury(payload)}
                      >
                        Search{" "}
                        {treasuries.loadingTreasuries === Loading.FETCHING && (
                          <>
                            &nbsp;
                            <i className="fa fa-spinner fa-spin" />
                          </>
                        )}
                      </button>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <button
                        className="btn btn-primary margin-label w-100"
                        type="button"
                      >
                        Print/Download PDF
                      </button>
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={
                      treasuries?.treasuryAccounts?.walletAccountList !==
                      undefined
                        ? treasuries?.treasuryAccounts?.walletAccountList
                        : []
                    }
                    filterData={filterInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
