import { NavLink } from "react-router-dom";

import pdfGeneratorTransDetails from "../../../../utils/helpers/pdfGeneratorTransDetails";
import { formatStringCurrency } from "../../../../utils/helpers/logicHelper";
import {
  transactionMethodsMap,
  supportedPaymentMap,
} from "../../../../utils/helpers/mappers";
import downloadSvg from "../../../../assets/images/svgs/download.svg";
import historySvg from "../../../../assets/images/svgs/history.svg";

const Body = ({ domainBrand, details }) => {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const beneficiary = details["receiver"] ?? {};
  const bankDetails = details["recipient_bank_details"] ?? {};

  const bankDetailsRender = Object.keys(bankDetails).map((key, index) => {
    return (
      <div key={index} className="col-6">
        <p className="text-muted">{key}</p>
        <p className="border-bottom">{bankDetails[key]}</p>
      </div>
    );
  });

  const downloadPDF = (event) => {
    event.preventDefault();
    // const pdfData = formatPDFData(details);
    const logo = domainBrand.domainBrand.logo;
    pdfGeneratorTransDetails(details, domainBrand, logo).catch((err) =>
      console.log(err)
    );
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus
                ? dictionary["l_transaction_details"]
                : "Transaction Details"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn">
            <NavLink
              onClick={downloadPDF}
              to=""
              className="btn btn-primary btn-icon text-white me-2"
            >
              <span>
                <img src={downloadSvg} alt="downloadSvg" />
                &nbsp;
              </span>
              {languageStatus
                ? dictionary["para_download_receipt"]
                : "Download Receipt"}
            </NavLink>
            <NavLink
              to="/transfer-history"
              className="btn btn-primary btn-icon text-white me-2"
            >
              <span>
                <img src={historySvg} alt="history" />
                &nbsp;
              </span>
              {languageStatus
                ? dictionary["WO_TRANSFER_HISTOR_57573459"]
                : "Transfer History"}
            </NavLink>
          </div>
        </div>
        <div className="row mb-3rem">
          <div className="col-xl-6 col-md-6">
            <div className="card overflow-hidden height-100">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary["l_transaction_status"]
                    : "Transaction Status"}
                </h3>
                <div className="card-options">
                  <span className="badge bg-info me-1 mb-1 mt-1">
                    {details["display_status"]}
                  </span>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <tr>
                    <td className="border-top-0">
                      {languageStatus
                        ? dictionary["para_send_amount"]
                        : "Amount"}
                    </td>
                    <td className="text-end fw-bold border-top-0">
                      {details["sending_currency_code"]}{" "}
                      {formatStringCurrency(details["amount_sent"])}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-top-0">
                      {languageStatus
                        ? dictionary["para_commission"]
                        : "Commission"}
                    </td>
                    <td className="text-end fw-bold border-top-0">
                      {details["sending_currency_code"]}{" "}
                      {formatStringCurrency(details["charges"])}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-top-0">
                      {languageStatus ? dictionary["para_payment_fee"] : "Fees"}
                    </td>
                    <td className="text-end fw-bold border-top-0">
                      {details["sending_currency_code"]}{" "}
                      {formatStringCurrency(details["paymentMethodFee"])}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-top-0">
                      {languageStatus ? dictionary["VA_-1896798840"] : "VAT"}
                    </td>
                    <td className="text-end fw-bold border-top-0">
                      {details["sending_currency_code"]}{" "}
                      {formatStringCurrency(details["vat"]) || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-top-0">
                      {languageStatus
                        ? dictionary["l_total_amount_paid"]
                        : "Total"}
                    </td>
                    <td className="text-end fw-bold border-top-0">
                      {details["sending_currency_code"]}{" "}
                      {formatStringCurrency(details["total_amount_paid"])}
                    </td>
                  </tr>
                  <tr>
                    <td className="fs-20 fw-bold border-top-0">
                      {languageStatus
                        ? dictionary["para_your_beneficiary_receives"]
                        : "Beneficiary receives"}
                    </td>
                    <td className="text-end fs-20 fw-bold border-top-0">
                      {details["receiving_currency_code"] ??
                        details["homeCurrency"]}
                      {` ${formatStringCurrency(details["amount_received"])}`}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="card overflow-hidden height-100">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary["l_transaction_information"]
                    : "Transaction Info"}
                </h3>
              </div>
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-6">
                    <p className="text-muted">
                      {languageStatus ? dictionary["para_pcn"] : "PCN"}
                    </p>
                    <p className="border-bottom">{details["code"]}</p>
                  </div>
                  <div className="col-6">
                    <p className="text-muted">
                      {languageStatus
                        ? dictionary["l_transaction_date"]
                        : "Date"}
                    </p>
                    <p className="border-bottom">{details["date"]}</p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <p className="text-muted">
                      {languageStatus
                        ? dictionary["l_transaction_status"]
                        : "Status"}
                    </p>
                    <p className="border-bottom">{details["display_status"]}</p>
                  </div>
                  <div className="col-6">
                    <p className="text-muted">
                      {languageStatus
                        ? dictionary["para_delivery_method"]
                        : "Method"}
                    </p>
                    <p className="border-bottom">
                      {(languageStatus
                        ? dictionary[
                            transactionMethodsMap[
                              details["deliveryMethod"] ?? "OTHER"
                            ].title
                          ]
                        : "Other") ??
                        (languageStatus ? dictionary["l_other"] : "Other")}
                    </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <p className="text-muted">
                      {languageStatus ? dictionary["l_payment_type"] : "Type"}
                    </p>
                    <p className="border-bottom">
                      {supportedPaymentMap[details["payment_method"] ?? "OTHER"]
                        .text ??
                        (languageStatus ? dictionary["l_other"] : "Other")}
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="text-muted">
                      {languageStatus
                        ? dictionary["l_receiving_country"]
                        : "Country"}
                    </p>
                    <p className="border-bottom">{details["country_to"]}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {beneficiary === null || Object.entries(beneficiary).length < 1 ? (
          ""
        ) : (
          <div className="row mb-3rem">
            <div className="col-xl-12 col-md-12">
              <div className="card height-100">
                <div className="card-header">
                  <h3 className="card-title">
                    {languageStatus
                      ? dictionary["l_beneficiary_details"]
                      : "Beneficiary Info"}
                  </h3>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    <div className="col-6">
                      <p className="text-muted">
                        {languageStatus
                          ? dictionary["l_beneficiary_name"]
                          : "Name"}
                      </p>
                      <p className="border-bottom">
                        {`${beneficiary["first_name"] ?? ""} ${
                          beneficiary["last_name"] ?? ""
                        }`}
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="text-muted">
                        {languageStatus
                          ? dictionary["l_beneficiary_code"]
                          : "Code"}
                      </p>
                      <p className="border-bottom">
                        {beneficiary["code"] ?? ""}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      <p className="text-muted">
                        {languageStatus
                          ? dictionary["l_beneficiary_email"]
                          : "Email"}
                      </p>
                      <p className="border-bottom">{beneficiary["email"]}</p>
                    </div>
                    <div className="col-6">
                      <p className="text-muted">
                        {languageStatus
                          ? dictionary["l_beneficiary_phone"]
                          : "Phone"}
                      </p>
                      <p className="border-bottom">{beneficiary["phone"]}</p>
                    </div>
                  </div>
                  <div className="row mb-4">{bankDetailsRender}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Body;
