import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  productOptions,
  productAccountTypes,
} from "../../../../utils/helpers/objectHelpers";
import { getProductsList } from "../../../../store/actions/product.action";
import { toast } from "react-hot-toast";
import { createAccountProduct } from "../../../../utils/services/product.service";

function CreateAccountForm({
  countries,
  currency,
  status,
  handleNewState = () => {},
}) {
  const menuPortalTarget = document.getElementById("root");
  const [productName, setProductName] = useState("");
  const [productTrigger, setProductTrigger] = useState({});
  const [country, setCountry] = useState({});
  const [money, setMoney] = useState({});
  const [accountTypes, setAccountTypes] = useState({});
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();

  const payload = {
    trigger: productTrigger.value,
    productName: productName,
    accountType: accountTypes.value,
    currencyCode: money.currencyCode,
    // isoCountry3: country.iso3Code,
  };

  const handleAccountCreation = async (values) => {
    try {
      setLoading(true);
      const data = await createAccountProduct(values);
      if (data.status === 200) {
        setLoading(false);
        dispatch(getProductsList({}));
        setProductName("");
        setAccountTypes({});
        setMoney({});
        setCountry({});
        setProductTrigger({});
        toast.success("Product created successfully.");
        setShowForm(true);
        handleNewState();
      } else {
        toast.error("Something went wrong, kindly fill all required fields");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong, kindly fill all required fields");
    }
  };

  return (
    <div className={`card-body ${showForm && "d-none"}`}>
      <form className="needs-validation">
        <div className="form-row">
          <div className="col-md-6 col-lg-6 mb-3">
            <label>Product name</label>
            <input
              type="text"
              vlaue={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="form-control border px-3"
            />
          </div>
          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Apply on
              {/* also known as trigger */}
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={productOptions}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              name="country"
              value={productTrigger}
              onChange={(option) => setProductTrigger(option)}
            />
          </div>
          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Country
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={countries}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              name="country"
              value={country}
              onChange={(option) => setCountry(option)}
            />
          </div>
          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Currency
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={currency}
              isClearable={false}
              isSearchable={false}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              name="currency"
              value={money}
              onChange={(option) => setMoney(option)}
            />
          </div>
          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Account Type
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={productAccountTypes}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              name="country"
              value={accountTypes}
              onChange={(option) => setAccountTypes(option)}
            />
          </div>
          <div className="col-md-12 mb-3 text-end">
            <button
              className="btn btn-primary margin-label width-25"
              type="button"
              onClick={() => handleAccountCreation(payload)}
            >
              {loading ? "Creating" : "Create Account"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateAccountForm;
