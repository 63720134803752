import { useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import ProfileService from "../../../../utils/services/profile.service";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { Loading } from "../../../../utils/helpers/constants";

const CreateUser = ({ domainBrand, profile, phoneCodes }) => {
  const history = useHistory();
  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [loading, updateLoading] = useState(false);
  const [onSubmitClick, setOnSubmitClick] = useState(false);
  const address = profile.userData.address;

  const initialValidation = {
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    phone: Yup.string().required(),
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneCountryCode: "",
    password: "",
    confirmPassword: "",
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(initialValidation),
    onSubmit: () => {},
  });

  const createUser = async (event) => {
    event.preventDefault();
    updateLoading(true);
    setOnSubmitClick(true);
    delete address.state;
    const payload = {
      address: { ...address },
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      dialingCode: formik.values.phoneCountryCode.value,
      phone: formik.values.phone,
      // customerGender: formik.values.customerGender,
      email: formik.values.email,
      password: formik.values.password,
      confirmPassword: formik.values.confirmPassword,
    };
    if (formik.values.password !== formik.values.confirmPassword)
      toast.error("Password do not match");
    else {
      const { data: responseData } = await ProfileService.createUserProfile(
        payload
      );
      if (responseData.status !== Loading.SUCCESS)
        toast.error(responseData.message);
      else {
        toast.success("New user added to account");
        history.push("/users");
      }
    }
    updateLoading(false);
  };

  const disablePhoneInput =
    typeof formik.values.phoneCountryCode.value === "undefined" ||
    formik.values.phoneCountryCode.value === "";

  return (
    <div className="card-body">
      <form>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus ? dictionary["para_first_name"] : "First name"}
            </label>
            <input
              className={`form-control ${
                formik.errors["firstName"] && onSubmitClick ? "is-invalid" : ""
              }`}
              type="text"
              name="firstName"
              onChange={formik.handleChange}
            />
            {formik.errors["firstName"] ? (
              <div className="invalid-feedback">Please provide first name.</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_last_name"] : "Last Name"}
            </label>
            <input
              type="text"
              name="lastName"
              className={`form-control ${
                formik.errors["lastName"] && onSubmitClick ? "is-invalid" : ""
              }`}
              onChange={formik.handleChange}
            />
            {formik.errors["firstName"] ? (
              <div className="invalid-feedback">Please provide last name.</div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>{languageStatus ? dictionary["para_email"] : "Email"}</label>
            <input
              type="email"
              name="email"
              className={`form-control ${
                formik.errors["email"] && onSubmitClick ? "is-invalid" : ""
              }`}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_phone_number"]
                : "Phone Number"}
              <span style={{ color: "red" }}>*</span>
            </label>
            <div className="form-row">
              <div className="col-md-5 col-lg-5 mb-3">
                <Select
                  options={phoneCodes}
                  isClearable={false}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                  value={formik.values.phoneCountryCode}
                  name="phoneCountryCode"
                  onChange={(option) =>
                    formik.setFieldValue("phoneCountryCode", option)
                  }
                />
              </div>
              <div className="col-md-7 col-lg-7 mb-3">
                <input
                  disabled={disablePhoneInput}
                  type="text"
                  name="phone"
                  value={formik.values.phone}
                  className={`form-control ${
                    formik.errors["phone"] && onSubmitClick ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["phone"] ? (
                  <div className="invalid-feedback">
                    Phone number is Required
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/*<div className="form-row">
                    <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                      <label>{languageStatus ? dictionary["WO_GENDE_-274590021"] : "Gender"}</label>
                    </div>
                  </div>*/}
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_password"] : "Password"}
            </label>
            <input
              className={`form-control ${
                formik.errors["password"] && onSubmitClick ? "is-invalid" : ""
              }`}
              type="password"
              name="password"
              onChange={formik.handleChange}
            />
            {formik.errors["password"] ? (
              <div className="invalid-feedback">
                Please provide a password for user
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_confirm_password"]
                : "Confirm Password"}
            </label>
            <input
              type="password"
              name="confirmPassword"
              className={`form-control ${
                formik.errors["confirmPassword"] && onSubmitClick
                  ? "is-invalid"
                  : ""
              }`}
              onChange={formik.handleChange}
            />
            {formik.errors["confirmPassword"] ? (
              <div className="invalid-feedback">Password does not match.</div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_address_line_1"]
                : "Address Line 1"}
            </label>
            <input
              type="text"
              className="form-control is-valid"
              defaultValue={address ? address["address1"] : ""}
              readOnly
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_address_line_2"]
                : "Address Line 2"}
            </label>
            <input
              type="text"
              className="form-control is-valid"
              defaultValue={address ? address["address2"] : ""}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>{languageStatus ? dictionary["l_city"] : "City"}</label>
            <input
              type="text"
              className="form-control is-valid"
              defaultValue={address ? address["city"] : ""}
              readOnly
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_post_code"] : "Postcode"}
            </label>
            <input
              type="text"
              className="form-control is-valid"
              defaultValue={address ? address["postcode"] : ""}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_country"] : "Country"}
            </label>
            <input
              type="text"
              className="form-control is-valid"
              defaultValue={address ? address["countryCommonName"] : ""}
              readOnly
            />
          </div>
        </div>
        <button
          onClick={createUser}
          className="btn btn-primary mt-3 w-50"
          type="button"
        >
          {languageStatus ? dictionary["b_create"] : "Create"}
          {loading ? (
            <>
              &nbsp;
              <i className="fa fa-spin fa-spinner" />
            </>
          ) : (
            ""
          )}
        </button>
      </form>
    </div>
  );
};

export default CreateUser;
