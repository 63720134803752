import { Loading } from "../../utils/helpers/constants";
import * as MyTransactionConstants from "../constants/mytransactions.constants";

const initialState = {
  myTransactions: [],
  loadingMyTransactions: "",
};

const myTransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MyTransactionConstants.MY_TRANSACTION_REQUEST:
      return { ...state, loadingMyTransactions: Loading.FETCHING };

    case MyTransactionConstants.MY_TRANSACTION_SUCCESS:
      return {
        ...state,
        loadingMyTransactions: Loading.SUCCESS,
        myTransactions: action.payload,
      };

    case MyTransactionConstants.MY_TRANSACTION_FAILURE:
      return {
        ...state,
        loadingMyTransactions: Loading.ERROR,
      };

    default:
      return state;
  }
};

export default myTransactionsReducer;
