const BankTransfer = ({languageStatus, dictionary, dataObject}) => {
  return (
    <div>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="mt-1">
            <p className="mb-0">{languageStatus ? dictionary["para_bank_transfer_step1"] : "Transfer"}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="mt-1">
            <p className="mb-4">{languageStatus ? dictionary["para_bank_transfer_step2"] : "Transfer"}</p>
            <div className="row">
              {Object.entries(dataObject.parameters["bank-details"]).map(([fieldKey, fieldValue], index) => (
                <div key={index} className="col-6 mb-2">
                  <div className="text-muted">{fieldKey}</div>
                  <div className="semibold-text">{fieldValue}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankTransfer;
