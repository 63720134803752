import ActionCreator from "../../utils/helpers/actionCreator";
import * as AccountsConstants from "../constants/accounts.constants";
import AccountsService from "../../utils/services/accounts.service";
import { expiredTokenCheck } from "./auth.action";
import { toast } from "react-hot-toast";

export const getAccounts = (payload, type) => async (dispatch) => {
  try {
    dispatch(ActionCreator(AccountsConstants.FETCH_ACCOUNTS_REQUEST));
    const { data: responseData } = await AccountsService.fetchAccounts(
      payload,
      type
    );
    if (responseData.status === "FAILED") throw responseData;
    console.log(responseData);
  } catch (error) {}
};

export const createWalletAccount = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(AccountsConstants.CREATE_ACCOUNT_REQUEST));
    const { data: responseData } = await AccountsService.requestAccount(
      payload
    );

    if (responseData.status === "FAILED") throw responseData;
    dispatch(ActionCreator(AccountsConstants.CREATE_ACCOUNT_SUCCESS));
    toast.success(
      `Your new ${responseData?.data?.currencyCode} account has been successfully created`
    );
    dispatch(getAllWallets());
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(AccountsConstants.CREATE_ACCOUNT_FAILURE));
    toast.error(
      `There was an error trying to create your ${payload.walletCurrency} account, try again.`
    );
  }
};

export const getAllWallets = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(AccountsConstants.FETCH_ACCOUNTS_REQUEST));
    const { data: responseData } = await AccountsService.fetchAllWallets();

    if (responseData.status === "FAILED") throw responseData;
    dispatch(
      ActionCreator(
        AccountsConstants.FETCH_ACCOUNTS_SUCCESS,
        responseData["data"]
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(AccountsConstants.FETCH_ACCOUNTS_FAILURE));
  }
};

export const getWalletList = (currency) => async (dispatch) => {
  try {
    dispatch(ActionCreator(AccountsConstants.FETCH_ACCOUNTS_REQUEST));
    const { data: responseData } = await AccountsService.fetchWallets(currency);

    if (responseData.status === "FAILED") throw responseData;
    dispatch(
      ActionCreator(
        AccountsConstants.FETCH_ACCOUNTS_SUCCESS,
        responseData["data"]
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(AccountsConstants.FETCH_ACCOUNTS_FAILURE));
  }
};
