import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-hot-toast";
import { deleteModalPropsMap } from "../../utils/helpers/mappers";

const DeleteModal = ({
  onchange,
  deleteProps,
  propsType,
  onDeleteResource,
}) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validateInput, setValidateInput] = useState("");
  const properties = deleteModalPropsMap[propsType];
  const disabledState = !(
    validateInput === deleteProps[properties.validator] ||
    deleteProps[properties.validator] === null
  );

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const deleteResource = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();
      await onDeleteResource(deleteProps);
      setLoading(false);
      handleClose();
    } catch (error) {
      toast.error(`${error.message}`);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="m-4">
            <strong>
              Delete{" "}
              {deleteProps[properties.name] === null ||
              deleteProps[properties.name] === ""
                ? ""
                : deleteProps[properties.name]}{" "}
              details
            </strong>
            <hr />
            <div className="mb-4">
              Do you want to delete {properties.prefix}{" "}
              <strong>{deleteProps[properties.label]}</strong>?
            </div>
            <div className="mb-4">
              You can send in a support request about this information, and may
              choose not to continue with this action.
            </div>
            <div className="mb-5 text-danger">
              <strong>This action cannot be undone</strong>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder={properties.placeholder}
              value={validateInput}
              onChange={(event) => setValidateInput(event.target.value)}
            />
          </div>
          <div className="float-end ">
            <Button
              className="ms-lg-4"
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </Button>{" "}
            &nbsp;
            <Button
              disabled={disabledState}
              className="mr-2"
              variant="danger"
              onClick={deleteResource}
            >
              Delete{" "}
              {loading ? (
                <>
                  &nbsp;
                  <i className="fa fa-spin fa-spinner" />
                </>
              ) : (
                ""
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteModal;
