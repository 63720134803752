import { toast } from "react-hot-toast";
import ActionCreator from "../../utils/helpers/actionCreator";
import { expiredTokenCheck } from "./auth.action";
import { getCorridorList } from "../../utils/services/corridor.service";
import * as CorridorConstants from "../constants/corridor.constant";

export const fetchCorridors = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(CorridorConstants.CORRIDOR_LIST_REQUEST));
    const { data: responseData } = await getCorridorList();

    if (responseData.status === "FAILED") {
      throw responseData;
    } else {
      dispatch(
        ActionCreator(CorridorConstants.CORRIDOR_LIST_SUCCESS, responseData)
      );
    }

    toast.success("Corridors fetched successfully.");
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CorridorConstants.CORRIDOR_LIST_FAILURE));
  }
};
