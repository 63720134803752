import Axios from './axios.service';

const authService = {
  login(payload) {
    return Axios({
      method: 'PUT',
      url: '/web-services/api/v6/services/securitymanagement/login',
      data: payload,
    });
  },

  otpVerification(payload) {
    return Axios({
      method: 'POST',
      url: `/web-services/api/v6/services/securitymanagement/verify-login-otp`,
      data: payload,
    });
  },

  resendOtp() {
    return Axios({
      method: 'POST',
      url: `/web-services/api/v6/services/securitymanagement/resend-login-otp`,
    });
  },

  forgotPasswordService({email}) {
    return Axios({
      method: 'GET',
      url: `/web-services/api/v6/services/securitymanagement/reset-password/${email}`,
    });
  },

  logout() {
    return Axios({
      method: 'POST',
      url: '/web-services/api/v6/services/securitymanagement/logout'
    });
  }
};

export default authService;
