
const Body = () => {

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">Fund Accounts</h1>
          </div>
          <div className="ms-auto pageheader-btn"/>
        </div>
      </div>
    </div>
  );
}

export default Body;
