import {NavLink} from "react-router-dom";
import {toast} from "react-hot-toast";
import {Loading} from "../../../../utils/helpers/constants";
import productImage from "../../../../assets/images/1.png";
import MerchantService from "../../../../utils/services/merchant.service";

const Body = ({domainBrand, product}) => {

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(typeof dictionary === "undefined" || dictionary === null);


  const addToCart = async event => {
    event.preventDefault();
    const payload = {
      payableType: "MERCHANT",
      payableId: product.product_id,
      quantity: 1,
      productName: product["product_name"]
    }
    const {data: responseData} = await MerchantService.addToCart(payload);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else toast.success(`Item, ${product["product_name"]} added to cart`);
  }

  
  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus ? dictionary["WO_PRODUCT_DESCRIPTIO_-40082400"] : "Product Details"}
            </h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to="/merchant/products">Shop</NavLink></li>
              <li className="breadcrumb-item active" aria-current="page">{product["product_name"]}</li>
            </ol>
          </div>
          <div className="ms-auto pageheader-btn"/>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card productdesc">
              <div className="card-body">
                <div className="productdec text-center">
                  <div className="bg-light-gray p-6 text-center br-5">
                    <img className="img-fluid" src={product["productMainPhotoUrl"]} alt="img"
                         onError={e => e.target.src = productImage}/>
                  </div>
                </div>
                <div className="text-center mt-5 pb-5 border-bottom">
                  <NavLink onClick={e => addToCart(e)} to="/" className="btn btn-primary mr-2rem">
                    <i className="fa fa-shopping-cart"/> Add to cart
                  </NavLink>
                  <NavLink to="/cart" className="btn btn-secondary">
                    <i className="fa fa-shopping-cart"> </i> Checkout
                  </NavLink>
                </div>

                <div className="mt-4 mb-4">
                  <h3>{product["product_name"]}</h3>
                </div>
                <div className="panel panel-primary">
                  <div className="panel-body tabs-menu-body">
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab1">
                        <h4 className="mb-5 mt-3">More Information</h4>
                        <ul className="list-unstyled mb-0">
                          <li className="row">
                            <div className="col-sm-3 text-muted">Service</div>
                            <div className="col-sm-6">{product["service"]}</div>
                          </li>
                          <li className=" row">
                            <div className="col-sm-3 text-muted">Operator</div>
                            <div className="col-sm-6">{product["operator"]}</div>
                          </li>
                          <li className=" row">
                            <div className="col-sm-3 text-muted">Country</div>
                            <div className="col-sm-6">{product["country_iso3"]}</div>
                          </li>
                          <li className=" row">
                            <div className="col-sm-3 text-muted">Product Currency</div>
                            <div className="col-sm-6">{product["product_currency"]}</div>
                          </li>
                          <li className=" row">
                            <div className="col-sm-3 text-muted">Price</div>
                            <div className="col-sm-6">{product["retail_price"]}</div>
                          </li>
                          <li className=" row">
                            <div className="col-sm-3 text-muted">Shipping and Installation Fee</div>
                            <div className="col-sm-6">{product["shippingAndInstallationFee"]}</div>
                          </li>
                          <li className=" row">
                            <div className="col-sm-3 text-muted">More Information</div>
                            <div className="col-sm-9">{product["productInfoWebsiteUrl"]}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Body;
