import Axios from "../services/axios.service";

export const fetchCustomerProducts = () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/account-product/account-products`,
  });
};

export const fetchSingleCustomerProduct = (accountproductId) => {
  return Axios({
    method: "PUT",
    url: `/web-services/api/v6/services/admin/account-product/request-account-product/${accountproductId}`,
  });
};
