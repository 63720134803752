import React, { useEffect, useState } from "react";
import { BiTransfer } from "react-icons/bi";
import {
  BsCurrencyExchange,
  BsDoorOpen,
  BsFileEarmarkPdfFill,
  BsGearWideConnected,
  BsReverseLayoutTextWindowReverse,
  BsWalletFill,
} from "react-icons/bs";
import { FaMoneyBillAlt } from "react-icons/fa";
import { FcMoneyTransfer, FcRules } from "react-icons/fc";
import { GiSandsOfTime } from "react-icons/gi";
import { GrUserAdmin, GrUserWorker } from "react-icons/gr";
import { ImUsers } from "react-icons/im";
import { IoIosCreate } from "react-icons/io";
import {
  MdAccountBalanceWallet,
  MdOutlineAccountBalance,
  MdOutlineHistory,
  MdPayment,
  MdPending,
  MdRequestPage,
  MdSettings,
  MdSupportAgent,
} from "react-icons/md";
import { SiKnown } from "react-icons/si";
import { NavLink } from "react-router-dom";
import cashSvg from "../../assets/images/svgs/cash.svg";
import document from "../../assets/images/svgs/document.svg";
import peopleSvg from "../../assets/images/svgs/people.svg";
import reportSvg from "../../assets/images/svgs/report.svg";
import transferSvg from "../../assets/images/svgs/transfer.svg";
import walletSvg from "../../assets/images/svgs/wallet.svg";
import {
  menuInitialStatus,
  menuType,
  userTypes,
} from "../../utils/helpers/constants";
import { getBaseUrl } from "../../utils/services/baseUrl";

const MenuLinks = ({ active, menu, domainBrand, profile }) => {
  const dropDownInitState = menuInitialStatus;
  const [dropDownStatus, updateDropDownStatus] = useState(dropDownInitState);
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const reboundNav = (event, id) => {
    event.preventDefault();
    expandMenu(id);
  };

  const expandMenu = (idName) => {
    const dropDownData = { ...dropDownInitState, [idName]: true };
    updateDropDownStatus(dropDownData);
  };

  const domainUrl = getBaseUrl();

  useEffect(() => {
    expandMenu(menu);
  }, [menu]);

  return (
    <>
      <li>
        <NavLink
          exact
          to="/"
          className={menu === menuType.HOME ? "active" : ""}
        >
          <svg
            width="28"
            height="24"
            viewBox="0 0 28 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.2857 7.64679V0.857143H18V3.98839L13.7143 0L0 12.8571H3.42857V24H11.1429V15.4286H16.2857V24H24V12.8571H27.4286L22.2857 7.64679Z"
              fill={"#6259CA"}
            />
          </svg>
          &nbsp;
          {languageStatus ? dictionary["para_home"] : "Home"}
        </NavLink>
      </li>
      {profile.userType === userTypes.MANAGER ? (
        <>
          <li>
            <NavLink
              onClick={(event) => reboundNav(event, menuType.CRM)}
              exact
              to="/crm"
              className={menu === menuType.CRM ? "active" : ""}
            >
              <img src={walletSvg} alt="homeSvg" /> &nbsp;
              {languageStatus ? dictionary["WO_CR_-560706472"] : "CRM"} &nbsp;
              <i className="fa fa-angle-down float end horizontal-icon" />
            </NavLink>
            <ul
              className={`sub-menu ${
                dropDownStatus[menuType.CRM]
                  ? "submenu-dropdown"
                  : "no-dropdown"
              }`}
            >
              <li className={active === "customer" ? "active" : ""}>
                <NavLink to="/customers" className="slide-item">
                  <ImUsers color={"#6259CA"} fontSize={25} />
                  &nbsp;
                  {languageStatus
                    ? dictionary["m_manage_customer"]
                    : "Customers"}
                </NavLink>
              </li>
              <li>
                <NavLink to="/" className="slide-item">
                  <MdPending color={"#6259CA"} fontSize={25} />
                  &nbsp;
                  {languageStatus
                    ? dictionary["WO_AWAITING_VERIFICATIO_4608421"]
                    : "Awaiting Verification"}
                </NavLink>
              </li>
              <li>
                <NavLink to="/account-creation" className="slide-item">
                  <IoIosCreate color={"#6259CA"} fontSize={25} />
                  &nbsp;
                  {/* {languageStatus
                    ? dictionary["WO_AWAITING_VERIFICATIO_4608421"]
                    : "Account Creation"} */}
                  Account Creation
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink
              onClick={(event) => reboundNav(event, menuType.COMPLIANCE)}
              exact
              to="/compliance"
              className={menu === menuType.COMPLIANCE ? "active" : ""}
            >
              <img src={walletSvg} alt="homeSvg" /> &nbsp;
              {languageStatus ? dictionary["m_compliance"] : "Compliance"}{" "}
              &nbsp;
              <i className="fa fa-angle-down float end horizontal-icon" />
            </NavLink>
            <ul
              className={`sub-menu ${
                dropDownStatus[menuType.COMPLIANCE]
                  ? "submenu-dropdown"
                  : "no-dropdown"
              }`}
            >
              <li className={active === "customer" ? "active" : ""}>
                <NavLink to="/kyc-rules" className="slide-item">
                  <SiKnown color={"#6259CA"} fontSize={24} />
                  &nbsp;
                  {languageStatus
                    ? dictionary["KYCKNOW_YOUR_CUSTOM_723094592"]
                    : "KYC Rules"}
                </NavLink>
              </li>
              <li>
                <NavLink to="/aml-rules" className="slide-item">
                  <FcRules color={"#6259CA"} fontSize={24} />
                  &nbsp;
                  {languageStatus
                    ? dictionary["m_transaction_rules"]
                    : "AML Rules"}
                </NavLink>
              </li>
            </ul>
          </li>
        </>
      ) : (
        ""
      )}
      {profile.userType === userTypes.MANAGER ? (
        <>
          <li>
            <NavLink
              onClick={(event) => reboundNav(event, menuType.MANAGEMENT)}
              exact
              to="/management"
              className={menu === menuType.MANAGEMENT ? "active" : ""}
            >
              <img src={walletSvg} alt="homeSvg" /> &nbsp;
              {languageStatus
                ? dictionary["para_management"]
                : "Management"}{" "}
              &nbsp;
              <i className="fa fa-angle-down float end horizontal-icon" />
            </NavLink>
            <ul
              className={`sub-menu ${
                dropDownStatus[menuType.MANAGEMENT]
                  ? "submenu-dropdown"
                  : "no-dropdown"
              }`}
            >
              <li className={active === "management" ? "active" : ""}>
                <NavLink to="/management/admins" className="slide-item">
                  <GrUserAdmin
                    color={"#6259CA"}
                    fontSize={24}
                    fill={"#6259CA"}
                  />
                  &nbsp; Administrators
                </NavLink>
                <NavLink to="/management/employees" className="slide-item">
                  <GrUserWorker
                    color={"#6259CA"}
                    fontSize={24}
                    fill={"#6259CA"}
                  />
                  &nbsp; Employees
                </NavLink>
                <NavLink to="/management/agents" className="slide-item">
                  <MdSupportAgent
                    color={"#6259CA"}
                    fontSize={24}
                    fill={"#6259CA"}
                  />
                  &nbsp; Agents
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink
              onClick={(event) => reboundNav(event, menuType.ACCOUNTING)}
              exact
              to="/accounting"
              className={menu === menuType.ACCOUNTING ? "active" : ""}
            >
              <img src={walletSvg} alt="homeSvg" /> &nbsp; Accounting &nbsp;
              <i className="fa fa-angle-down float end horizontal-icon" />
            </NavLink>
            <ul
              className={`sub-menu ${
                dropDownStatus[menuType.ACCOUNTING]
                  ? "submenu-dropdown"
                  : "no-dropdown"
              }`}
            >
              <li className={active === "treasury" ? "active" : ""}>
                {/* <NavLink to="/management/employees" className="slide-item">
                  Treasury Accounts
                </NavLink> */}
                <NavLink to="/treasury-accounts" className="slide-item">
                  <MdOutlineAccountBalance
                    color={"#6259CA"}
                    fontSize={24}
                    fill={"#6259CA"}
                  />
                  &nbsp; Treasury Accounts
                </NavLink>
                {/* <NavLink to="/fund-accounts" className="slide-item">
                  <FaCashRegister
                    color={"#6259CA"}
                    fontSize={23}
                    fill={"#6259CA"}
                  />
                  &nbsp; Fund Accounts
                </NavLink>
                <NavLink to="/overdrafts" className="slide-item">
                  <FaCashRegister
                    color={"#6259CA"}
                    fontSize={23}
                    fill={"#6259CA"}
                  />
                  &nbsp; Overdrafts
                </NavLink> */}
              </li>
            </ul>
          </li>
        </>
      ) : (
        ""
      )}
      {profile.userType === userTypes.MANAGER ? (
        <>
          <li>
            <NavLink
              onClick={(event) => reboundNav(event, menuType.SETTINGS)}
              exact
              to="/settings"
              className={menu === menuType.SETTINGS ? "active" : ""}
            >
              <MdSettings color={"#6259CA"} fontSize={23} /> &nbsp; Settings
              &nbsp;
              {/* <img src={walletSvg} alt="homeSvg" /> &nbsp; Settings &nbsp; */}
              <i className="fa fa-angle-down float end horizontal-icon" />
            </NavLink>
            <ul
              className={`sub-menu ${
                dropDownStatus[menuType.SETTINGS]
                  ? "submenu-dropdown"
                  : "no-dropdown"
              }`}
            >
              <li className={active === "rates" ? "active" : ""}>
                <NavLink to="/settings/rates" className="slide-item">
                  <BsCurrencyExchange
                    color={"#6259CA"}
                    fontSize={24}
                    fill={"#6259CA"}
                  />
                  &nbsp;
                  {languageStatus ? dictionary["para_exchange_rate"] : "Rates"}
                </NavLink>
              </li>
              <li className={active === "rates" ? "active" : ""}>
                <NavLink
                  to="/settings/transaction-corridors"
                  className="slide-item"
                >
                  <BsDoorOpen
                    color={"#6259CA"}
                    fontSize={20}
                    fill={"#6259CA"}
                  />
                  &nbsp;
                  {languageStatus
                    ? dictionary["WO_CORRIDOR_166705112"]
                    : "Corridors"}
                </NavLink>
              </li>
              <li className={active === "rates" ? "active" : ""}>
                <NavLink
                  to="/settings/bank-account-settings"
                  className="slide-item"
                >
                  <BsReverseLayoutTextWindowReverse
                    color={"#6259CA"}
                    fontSize={20}
                    fill={"#6259CA"}
                  />
                  &nbsp;
                  {languageStatus
                    ? dictionary["WO_BANK_ACCOUNT_SETTING_1025629397"]
                    : "Bank Account Fields"}
                </NavLink>
              </li>
              <li className={active === "rates" ? "active" : ""}>
                <NavLink
                  to="/settings/remittance-settings"
                  className="slide-item"
                >
                  <BsGearWideConnected
                    color={"#6259CA"}
                    fontSize={20}
                    fill={"#6259CA"}
                  />
                  &nbsp;
                  {languageStatus
                    ? dictionary["m_manage_money_transfer_commission"]
                    : "Remmittance Fee"}
                </NavLink>
              </li>
              <li className={active === "rates" ? "active" : ""}>
                <NavLink to="/settings/interest-rate" className="slide-item">
                  <BsGearWideConnected
                    color={"#6259CA"}
                    fontSize={20}
                    fill={"#6259CA"}
                  />
                  &nbsp;
                  {languageStatus
                    ? dictionary["WO_INTEREST_RAT_1193574154"]
                    : "Account maintenance fee"}
                </NavLink>
              </li>
            </ul>
          </li>
        </>
      ) : (
        ""
      )}
      {domainBrand.serviceControl.wallet &&
      profile.userType !== userTypes.MANAGER ? (
        <li>
          <NavLink
            onClick={(event) => reboundNav(event, menuType.ACCOUNTS)}
            exact
            to="/balances"
            className={menu === menuType.ACCOUNTS ? "active" : ""}
          >
            <img src={walletSvg} alt="homeSvg" /> &nbsp;
            {languageStatus
              ? dictionary["WO_ACCOUNT_1211395312"]
              : "Accounts"}{" "}
            &nbsp;
            <i className="fa fa-angle-down float end horizontal-icon" />
          </NavLink>
          <ul
            className={`sub-menu ${
              dropDownStatus[menuType.ACCOUNTS]
                ? "submenu-dropdown"
                : "no-dropdown"
            }`}
          >
            <li className={active === "balances" ? "active" : ""}>
              <NavLink to="/balances" className="slide-item">
                <MdAccountBalanceWallet fontSize={26} color={"#6259CA"} />{" "}
                &nbsp;
                {languageStatus
                  ? dictionary["WO_ACCOUNT_BALANCE_960871353"]
                  : "Account Balances"}
              </NavLink>
            </li>
            <li className={active === "request-account" ? "active" : ""}>
              <NavLink to="/request-account" className="slide-item">
                <MdRequestPage color={"#6259CA"} fontSize={25} /> &nbsp;
                {languageStatus
                  ? dictionary["WO_REQUEST_BANK_ACCOUN_1842427382"]
                  : "Request Account"}
              </NavLink>
            </li>
            {profile.userType === userTypes.CUSTOMER ? (
              <li>
                <NavLink to="/statement" className="slide-item">
                  <BsFileEarmarkPdfFill fontSize={24} color={"#6259CA"} />{" "}
                  &nbsp;
                  {languageStatus
                    ? dictionary["l_wallet_transaction_history"]
                    : "Account Statements"}
                </NavLink>
              </li>
            ) : (
              ""
            )}
            <li className={active === "fund-wallet" ? "active" : ""}>
              <NavLink to="/fund-wallet" className="slide-item">
                <FaMoneyBillAlt fontSize={25} color={"#6259CA"} /> &nbsp;
                {languageStatus ? dictionary["l_fund_wallet"] : "Add Money"}
              </NavLink>
            </li>
          </ul>
        </li>
      ) : (
        ""
      )}
      {domainBrand.serviceControl.transfer &&
      profile.userType !== userTypes.MANAGER ? (
        <li>
          <NavLink
            onClick={(event) => reboundNav(event, menuType.TRANSFER)}
            exact
            to="/transfer"
            className={menu === menuType.TRANSFER ? "active" : ""}
          >
            <img src={transferSvg} alt="arrowRight" /> &nbsp;
            {languageStatus
              ? dictionary["m_wallet_fund_transfer"]
              : "Transfers"}{" "}
            &nbsp;
            <i className="fa fa-angle-down horizontal-icon" />
          </NavLink>
          <ul
            className={`sub-menu ${
              dropDownStatus[menuType.TRANSFER]
                ? "submenu-dropdown"
                : "no-dropdown"
            }`}
          >
            <li className={active === "transfer-history" ? "active" : ""}>
              <NavLink exact to="/transfer-history" className="slide-item">
                <MdOutlineHistory fontSize={25} color={"#6259CA"} />
                &nbsp;
                {languageStatus
                  ? dictionary["WO_TRANSFER_HISTOR_57573459"]
                  : "Transfer History"}
              </NavLink>
            </li>
            {/*  */}
            <li className={active === "transfers" ? "active" : ""}>
              <NavLink exact to="/transfer" className="slide-item">
                <FcMoneyTransfer fontSize={25} color={"#6259CA"} />
                &nbsp;
                {languageStatus
                  ? dictionary["WO_TRANSFER_FUND_-1694451190"]
                  : "Transfer Funds"}
              </NavLink>
            </li>
          </ul>
        </li>
      ) : (
        ""
      )}
      {(domainBrand.serviceControl.billPayment ||
        domainBrand.serviceControl.airtimeTopup) &&
      profile.userType !== userTypes.MANAGER ? (
        <li>
          <NavLink
            onClick={(event) => reboundNav(event, menuType.UTILITIES)}
            exact
            to="/utils"
            className={menu === menuType.UTILITIES ? "active" : ""}
          >
            <img src={document} alt="arrowRight" /> &nbsp;
            {languageStatus ? dictionary["m_transactions4"] : "Bills"} &nbsp;
            <i className="fa fa-angle-down horizontal-icon" />
          </NavLink>
          <ul
            className={`sub-menu ${
              dropDownStatus[menuType.UTILITIES]
                ? "submenu-dropdown"
                : "no-dropdown"
            }`}
          >
            {domainBrand.serviceControl.airtimeTopup ? (
              <li className={active === "airtime" ? "active" : ""}>
                <NavLink exact to="/airtime-topup" className="slide-item">
                  <GiSandsOfTime fontSize={24} color={"#6259CA"} />
                  &nbsp;
                  {languageStatus
                    ? dictionary["m_mobile_top_up"]
                    : "Airtime Topup"}
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {domainBrand.serviceControl.billPayment ? (
              <li className={active === "billPayment" ? "active" : ""}>
                <NavLink exact to="/bill-payment" className="slide-item">
                  <MdPayment fontSize={24} color={"#6259CA"} />
                  &nbsp;
                  {languageStatus
                    ? dictionary["WO_BILL_PAYMEN_-1233806888"]
                    : "Bill Payments"}
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>
        </li>
      ) : (
        ""
      )}
      {profile.userType === userTypes.CUSTOMER ? (
        <>
          <li>
            <NavLink
              exact
              to="/beneficiaries"
              className={
                menu === menuType.BENEFICIARY ? "sub-icon active" : "sub-icon"
              }
            >
              <img src={peopleSvg} alt="arrowRight" /> &nbsp;
              {languageStatus
                ? dictionary["WO_BENEFICIARIE_-1845397122"]
                : "Beneficiaries"}
            </NavLink>
          </li>
          {domainUrl === "https://test.moneytransferapplication.uk" && (
            <li>
              <NavLink
                exact
                to="/customer-products"
                className={
                  menu === menuType.CUSTOMERPRODUCTS
                    ? "sub-icon active"
                    : "sub-icon"
                }
              >
                <BsWalletFill color={"#6259CA"} fontSize={23} /> &nbsp;
                {languageStatus
                  ? dictionary["WO_PRODUCT_-541446518"]
                  : "Products"}
              </NavLink>
            </li>
          )}
        </>
      ) : (
        ""
      )}
      {profile.userType === userTypes.CASHIER &&
      domainUrl === "https://test.moneytransferapplication.uk" ? (
        <React.Fragment>
          <li>
            <NavLink
              exact
              to="/"
              className={menu === menuType.REPORTS ? "active" : ""}
            >
              <img src={reportSvg} alt="arrowRight" /> &nbsp;
              {languageStatus ? dictionary["para_reports"] : "Reports"} &nbsp;
              <i className="fa fa-angle-down horizontal-icon" />
            </NavLink>
            <ul
              className={`sub-menu ${
                dropDownStatus[menuType.REPORTS]
                  ? "submenu-dropdown"
                  : "no-dropdown"
              }`}
            >
              <li className={active === "reports" ? "active" : ""}>
                <NavLink
                  exact
                  to="/report/my-transactions"
                  className="slide-item"
                >
                  <BiTransfer fontSize={25} color={"#6259CA"} />
                  &nbsp;
                  {languageStatus
                    ? dictionary["l_my_transactions"]
                    : "My Transactions"}
                </NavLink>
              </li>
              {domainUrl === "https://test.moneytransferapplication.uk" && (
                <li className={active === "reports" ? "active" : ""}>
                  <NavLink
                    exact
                    to="/report/api-transaction-report"
                    className="slide-item"
                  >
                    <img src={reportSvg} alt="arrowRight" /> &nbsp;
                    {languageStatus
                      ? dictionary["WO_MY_API_TRANSACTION_R_658004357"]
                      : "My API Transaction Report"}
                  </NavLink>
                </li>
              )}

              {domainUrl === "https://test.moneytransferapplication.uk" && (
                <li className={active === "reports" ? "active" : ""}>
                  <NavLink
                    exact
                    to="/report/bills-and-services"
                    className="slide-item"
                  >
                    <img src={cashSvg} alt="arrowRight" /> &nbsp;
                    {languageStatus
                      ? dictionary["m_pay_bills"]
                      : "Pay Bills and Services"}
                  </NavLink>
                </li>
              )}

              {domainUrl === "https://test.moneytransferapplication.uk" && (
                <li className={active === "reports" ? "active" : ""}>
                  <NavLink
                    exact
                    to="/report/cashier-opening-balance"
                    className="slide-item"
                  >
                    <MdOutlineAccountBalance
                      color={"#6259CA"}
                      fontSize={24}
                      fill={"#6259CA"}
                    />
                    &nbsp;
                    {languageStatus
                      ? dictionary["m_opening_balance"]
                      : "Cashier/Teller Opening Balance"}
                  </NavLink>
                </li>
              )}

              {domainUrl === "https://test.moneytransferapplication.uk" && (
                <li className={active === "reports" ? "active" : ""}>
                  <NavLink
                    exact
                    to="/report/opening-balance"
                    className="slide-item"
                  >
                    <MdOutlineAccountBalance
                      color={"#6259CA"}
                      fontSize={24}
                      fill={"#6259CA"}
                    />
                    &nbsp;
                    {languageStatus
                      ? dictionary["m_opening_balance_history"]
                      : "Opening Balance History"}
                  </NavLink>
                </li>
              )}

              {domainUrl === "https://test.moneytransferapplication.uk" && (
                <li className={active === "reports" ? "active" : ""}>
                  <NavLink
                    exact
                    to="/report/wallet-ledger"
                    className="slide-item"
                  >
                    <MdAccountBalanceWallet
                      color={"#6259CA"}
                      fontSize={24}
                      fill={"#6259CA"}
                    />
                    &nbsp;
                    {languageStatus
                      ? dictionary["m_wallet_statement"]
                      : "Wallet Ledger"}
                  </NavLink>
                </li>
              )}
            </ul>
          </li>
        </React.Fragment>
      ) : (
        ""
      )}

      {profile.userType === userTypes.CASHIER ? (
        <li>
          <NavLink
            exact
            to="/customers-beneficiaries"
            className={
              menu === menuType.PAYOUT ? "sub-icon active" : "sub-icon"
            }
          >
            <img src={walletSvg} alt="homeSvg" /> &nbsp;
            {languageStatus ? dictionary["WO_CR_-560706472"] : "CRM"}
          </NavLink>
        </li>
      ) : (
        ""
      )}
    </>
  );
};

export default MenuLinks;
