import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import {getSupportedCurrencies} from "../../../../store/actions/country.action";
import {toggleNav} from "../../../../store/actions/component.action";
import {Loading} from "../../../../utils/helpers/constants";


const RequestAccount = () => {
  const dispatch = useDispatch();
  const {domainBrand, component, country} = useSelector(
    ({domainBrandReducer, componentReducer, countryReducer}) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      country: countryReducer
    }));

  const loadState = domainBrand.loading === Loading.FETCHING || country.loadingCurrencies === Loading.FETCHING;

  useEffect(async () => {
    dispatch(toggleNav(false));
    if (country.loadingCurrencies !== Loading.SUCCESS) dispatch(getSupportedCurrencies());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? <SphereLoader/> :
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"/>
            <Header domainBrand={domainBrand}/>
            <HeaderMenu menu="accounts" active="request-account" domainBrand={domainBrand}/>
            <Body domainBrand={domainBrand} currencies={country["supportedCurrencies"]}/>
          </div>
          <Footer domainBrand={domainBrand}/>
        </>
      }
    </div>
  );
}

export default RequestAccount;
