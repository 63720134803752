import { combineReducers } from "redux";

import accManagementReducer from "./acc-management.reducer";
import accountsReducer from "./accounts.reducer";
import authReducer from "./auth.reducer";
import bankFieldReducer from "./bankfield.reducer";
import cashierBeneficiaryReducer from "./cashierbeneficiary.reducer";
import complianceReducer from "./compliance.reducer";
import componentReducer from "./component.reducer";
import corridorReducer from "./corridor.reducer";
import countryReducer from "./country.reducer";
import customerProductsRedcucer from "./customer-products.reducer";
import domainBrandReducer from "./domain-brand.reducer";
import employeeReducer from "./employees.reducer";
import merchantReducer from "./merchant.reducer";
import productReducer from "./product.reducer";
import profileReducer from "./profile.reducer";
import rateReducer from "./rate.reducer";
import transferReducer from "./transfer.reducer";
import treasuryReducer from "./treasury.reducer";
import usersReducer from "./users.reducer";
import billsAndServicesReducer from "./bills.reducer";
import myTransactionsReducer from "./mytransactions.reducer";

const rootReducer = combineReducers({
  accountsReducer,
  authReducer,
  complianceReducer,
  componentReducer,
  countryReducer,
  domainBrandReducer,
  merchantReducer,
  profileReducer,
  transferReducer,
  usersReducer,
  productReducer,
  treasuryReducer,
  cashierBeneficiaryReducer,
  rateReducer,
  bankFieldReducer,
  customerProductsRedcucer,
  corridorReducer,
  employeeReducer,
  accManagementReducer,
  billsAndServicesReducer,
  myTransactionsReducer,
});

export default rootReducer;
