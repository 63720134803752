import * as CorridorConstants from "../constants/corridor.constant";
import { Loading } from "../../utils/helpers/constants";
const initialState = {
  corridor: [],
  loadingCorridor: "",
};

const corridorReducer = (state = initialState, action) => {
  switch (action.type) {
    case CorridorConstants.CORRIDOR_LIST_REQUEST:
      return { ...state, loadingCorridor: Loading.FETCHING };

    case CorridorConstants.CORRIDOR_LIST_SUCCESS:
      return {
        ...state,
        loadingCorridor: Loading.SUCCESS,
        exchangeRates: action.payload,
      };

    case CorridorConstants.CORRIDOR_LIST_FAILURE:
      return {
        ...state,
        loadingCorridor: Loading.ERROR,
      };

    default:
      return state;
  }
};

export default corridorReducer;
