import { useHistory } from "react-router-dom";

const ConstructionPage = () => {
  const history = useHistory();

  const goToDashboard = () => history.push("/");

  return (
    <div className="login-img construction">
      <div className="page">
        <div className="">
          <div className="container">
            <div className="row text-center mx-auto">
              <div className="col-lg-8 col-sm-12 center-block align-items-center">
                <div className="text-white">
                  <div className="card-body">
                    <h1 className="display-2 mb-0 fw-semibold">Coming Soon</h1>
                    <p>
                      We are working on the manager end of the application. Use
                      moneytransferapplication.uk
                    </p>
                    <div className="mt-5">
                      <button className="btn btn-icon" type="button">
                        <span className="btn-inner--icon">
                          <i className="fa fa-envelope" />
                        </span>
                      </button>
                      <button className="btn btn-icon" type="button">
                        <span className="btn-inner--icon">
                          <i className="fab fa-linkedin" />
                        </span>
                      </button>
                      <button
                        onClick={goToDashboard}
                        className="btn btn-icon"
                        type="button"
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-sign-in-alt" />
                        </span>
                      </button>
                      <button className="btn btn-icon" type="button">
                        <span className="btn-inner--icon">
                          <i className="fa fa-sign-out-alt" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConstructionPage;
