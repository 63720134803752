import Axios from "../services/axios.service";

export default class ProfileService {
  static async fetchUser() {
    return Axios({
      method: "GET",
      url: "/web-services/api/v6/services/usermanagement/profile",
    });
  }

  static async createUserProfile(payload) {
    payload.appVersion = 0;
    return Axios({
      method: "POST",
      url: "/web-services/api/v6/services/usermanagement/add-user-to-account",
      data: payload,
    });
  }

  static async editUserProfile(payload) {
    return Axios({
      method: "PUT",
      url: "/web-services/api/v6/services/usermanagement/profile-edit",
      data: payload,
    });
  }

  static async updateProfilePicture(payload) {
    return Axios({
      method: "PUT",
      url: `web-services/api/v6/services/documentmanagement/upload-profile-picture`,
      data: payload,
    });
  }

  static async changePassword(payload) {
    return Axios({
      method: "PUT",
      url: `/web-services/api/v6/services/securitymanagement/change-password`,
      data: payload,
    });
  }

  static async listDocuments(customerCode) {
    return Axios({
      method: "GET",
      url: `/web-services/api/v6/services/documentmanagement/list-documents/${customerCode}`,
    });
  }

  static async deleteDocuments(customerCode, docId) {
    return Axios({
      method: "DELETE",
      url: `web-services/api/v6/services/documentmanagement/delete-document/${customerCode}/${docId}`,
    });
  }

  static attachDocuments(payload) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/documentmanagement/attach-documents`,
      data: payload,
    });
  }

  static async fetchNotifications(type) {
    return Axios({
      method: "GET",
      url: `/web-services/api/v6/services/notification/list-notifications/${type}`,
    });
  }

  static async showAwaitingNotification() {
    return Axios({
      method: "GET",
      url: `/web-services/api/v6/services/notification/show-awaiting-message-notification`,
    });
  }

  static async sendNotification(payload) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/notification/send-notifications`,
      data: payload,
    });
  }

  static async createUserPIn(payload) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/securitymanagement/create-user-pin`,
      data: payload,
    });
  }

  static async generateUserOtp(payload) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/walletmanagement/generate-otp`,
      data: payload,
    });
  }

  static async requestForOverdraft(payload) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/walletmanagement/request-overdraft`,
      data: payload,
    });
  }
}
