/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toast from "react-hot-toast";
import { BiLinkExternal, BiPencil } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { ReactComponent as Trash } from "../../../../assets/images/svgs/trash.svg";
import DataTable from "../../../../components/dataTable/dataTable";
import EditInterestRateModal from "../../../../components/modals/EditInterestRateModal";
import ProductModal from "../../../../components/modals/ProductModal";
import { fetchAccInterestRate } from "../../../../store/actions/acc-management.action";
import { interestTableConfig } from "../../../../utils/helpers/dataTableConfigs";
import { deleteInterestRate } from "../../../../utils/services/acc-management.service";
import AddInterestRate from "./atom/AddInterestRate";

function Body({ domainBrand, interestRate = [] }) {
  const dispatch = useDispatch();
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const [filterInput, setFilterInput] = useState("");
  const [addNewState, updateState] = useState(false);
  const [show, setShow] = useState(false);
  const [intObject, setIntObject] = useState({});
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 7,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-center pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Edit</Tooltip>}
          >
            <BiPencil
              fontSize={22}
              color={"#6259CA"}
              onClick={() => {
                setShow(true);
                setIntObject(row?.original);
              }}
            />
          </OverlayTrigger>
          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Delete</Tooltip>}
          >
            <Trash
              onClick={() => {
                setOpen(true);
                setType(row.original.interestRateType);
                setIntObject(row?.original);
              }}
            />
          </OverlayTrigger>{" "}
        </div>
      );
    },
  };

  const handleDeleteRate = async (id) => {
    setLoading(true);
    try {
      const response = await deleteInterestRate(id);
      if (response.data.status === "SUCCESS") {
        toast.success(response.data.message);
        dispatch(fetchAccInterestRate());
        setLoading(false);
        setOpen(false);
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const interestTable = interestTableConfig(dictionary);

  const tableObject = [...interestTable, actionColumn];

  const columns = useMemo(() => tableObject, []);

  const addNewStatus = (event) => {
    event.preventDefault();
    updateState(!addNewState);
  };

  return (
    <React.Fragment>
      <EditInterestRateModal
        show={show}
        handleClose={() => {
          setShow(false);
          setIntObject({});
        }}
        actionType={"EDIT-INTEREST"}
        intObject={intObject}
        domainBrand={domainBrand}
      />
      <ProductModal
        show={open}
        handleClose={() => {
          setOpen(false);
        }}
        type={"KYC-DELETE"}
        kyc={type}
        handleDeleteKyc={() => handleDeleteRate(intObject?.id)}
        loading={loading}
      />
      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">
                {dictionary
                  ? dictionary["WO_INTEREST_RAT_1193574154"]
                  : "Account Management Fee"}
              </h1>
            </div>
            <div className="ms-auto pageheader-btn">
              <NavLink
                onClick={addNewStatus}
                to="/"
                className="btn btn-primary btn-icon text-white me-2 fw-bold"
              >
                <span>
                  <i className="fa fa-folder-plus" />
                </span>{" "}
                &nbsp;
                {/* {dictionary ? dictionary["button_add_new"] : "Add New"} */}
                Set up Interest Rate
              </NavLink>
            </div>
          </div>

          <div className="row row-sm">
            {addNewState && (
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card overflow-hidden">
                  <div className="card-header bg-primary text-white">
                    <h3 className="card-title">
                      {dictionary
                        ? dictionary["WO_USE_THIS_SCREEN_TO_S_89780656"]
                        : "Use this screen to setup interest rate for customer(s)."}
                    </h3>
                  </div>
                  <div className="card-body">
                    <AddInterestRate
                      domainBrand={domainBrand}
                      handleCloseForm={() => updateState(false)}
                      actionType={"ADD-INTEREST"}
                      intObject={{}}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* TABLE DOWN HERE */}
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card custom-card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">
                    {/* {dictionary
                    ? dictionary["WO_USE_THIS_SCREEN_TO_S_89780656"]
                    : "Use this screen to setup interest rate for customer(s)."} */}
                    Interest Rates
                  </h3>
                </div>
                <div className="col-lg-6 col-md-6 mt-2">
                  <input
                    type={"text"}
                    value={filterInput}
                    className="form-control mt-3"
                    onChange={(e) => setFilterInput(e.target.value)}
                    placeholder="Search..."
                  />
                </div>

                <div className="card-body">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={interestRate}
                      filterData={filterInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Body;
