import { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import { fetchTransactionDetails } from "../../../../utils/services/transfer.service";
import { toggleNav } from "../../../../store/actions/component.action";
import { Loading } from "../../../../utils/helpers/constants";

const TransactionDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [transactionDetails, updateTransactionDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const { domainBrand, component, transfers } = useSelector(
    ({ domainBrandReducer, componentReducer, transferReducer }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      transfers: transferReducer,
    })
  );

  const loadState =
    loading ||
    domainBrand.loading === Loading.FETCHING ||
    transfers.loading === Loading.FETCHING;

  useEffect(async () => {
    dispatch(toggleNav(false));
    setLoading(true);
    let pcnInfo, payableType;
    if (location.search) {
      const payableTypePrefix = location.search.split("?payabletype=")[1];
      pcnInfo = location.search.split("?pcn=")[1];
      payableType = payableTypePrefix.split("?")[0];
    } else if (Object.entries(location.state?.data).length > 0) {
      pcnInfo = location.state.data.pcn;
      payableType =
        location.state.data["payableItemType"] ||
        location.state.data["payableType"];
    } else return <Redirect to="/transfer-history" />;

    const { data: responseData } = await fetchTransactionDetails(
      pcnInfo,
      payableType
    );
    updateTransactionDetails(responseData.data);
    setLoading(false);
  }, [location]);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="transfers"
              active="transfers"
              domainBrand={domainBrand}
            />
            <Body domainBrand={domainBrand} details={transactionDetails} />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default TransactionDetails;
