import {NavLink} from "react-router-dom";
import moment from "moment";

import {messageDirection} from "../../../../../utils/helpers/constants";
import HoverTooltip from "../../../../../components/tootltips/HoverTooltip";

const Inbox = ({message, domainBrand, inboxState, compose}) => {

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const date = new Date(message["sentDate"]);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const dateResult = moment([year, month, day]).fromNow();
  const timeStamp = moment(date).format('MMMM Do YYYY, h:mma');
  const messagePosition = message["inBoxDirection"] === messageDirection.OUTGOING_MAIL ? "flex-row-reverse chat-right" : "chat-left";
  const createMarkup = () => ({__html: message.body});

  const closeInbox = event => {
    event.preventDefault();
    inboxState(false);
  }

  const replyInbox = event => {
    event.preventDefault();
    inboxState(false);
    compose(false);
  }

  const direction = message["inBoxDirection"] === messageDirection.OUTGOING_MAIL ?
    dictionary["l_message"] || "Outgoing Message" : dictionary["l_message"] || "Incoming Message";

  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8">
        <div className="card">
          <div className="main-content-app pt-0">
            <div className="main-content-body main-content-body-chat">
              <div className="main-chat-header pt-3">
                <div className="main-chat-msg-name mt-2">
                  <h6>{message["senderName"]}</h6>
                  <small className="me-3">{direction}</small>
                </div>
                <nav className="nav">
                  <HoverTooltip title="Reply">
                    <NavLink onClick={event => replyInbox(event)} className="nav-link" to="">
                      <i className="fa fa-reply"/>
                    </NavLink>
                  </HoverTooltip>
                  <HoverTooltip title="Close">
                    <NavLink onClick={event => closeInbox(event)} className="nav-link" to="">
                      <i className="fa fa-times"/>
                    </NavLink>
                  </HoverTooltip>
                </nav>
              </div>
              <div className="main-chat-body" id="ChatBody">
                <div className="content-inner">
                  <label className="main-chat-time"><span>{dateResult}</span></label>
                  <div className={`media flex-row-reverse ${messagePosition}`}>
                    <div className="media-body">
                      <div dangerouslySetInnerHTML={createMarkup()} className="main-msg-wrapper"/>
                      <div><span>{timeStamp}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Inbox;
