import fallbackImage from "../../assets/images/2.jpg";
import { useEffect, useState } from "react";

const CountriesDataTable = ({ countries, selectCountry }) => {
  const sizeOfCountries = 18;
  const [countryRenderIndex, updateCountryRenderIndex] = useState(0);
  const [searchInput, updateSearchInput] = useState("");
  const [filteredList, updateFilteredList] = useState(countries);

  const filterSearch = (value) => {
    return countries.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  useEffect(() => {
    updateFilteredList(filterSearch(searchInput));
  }, [searchInput]);

  const pageSizeIndex = () => {
    if (countryRenderIndex + sizeOfCountries > filteredList.length)
      return filteredList.length;
    else return countryRenderIndex + sizeOfCountries;
  };

  const previousPage = () =>
    updateCountryRenderIndex(countryRenderIndex - sizeOfCountries);
  const nextPage = () =>
    updateCountryRenderIndex(countryRenderIndex + sizeOfCountries);
  const canPreviousPage = () => countryRenderIndex >= 1;
  const canNextPage = () =>
    countryRenderIndex + sizeOfCountries <= filteredList.length;

  return (
    <div className="fs-8px">
      {/*{filteredList.length <= pageSizeIndex() ? "" :
        <div className="input-group mb-3rem">
          <input type="text" className="form-control" value={searchInput}
                 onChange={event => updateSearchInput(event.target.value)}
                 placeholder="Search for country.."/>
          <button className="input-group-text btn btn-primary">Search</button>
        </div>
      }*/}
      <div className="input-group mb-3rem">
        <input
          type="text"
          className="form-control"
          value={searchInput}
          onChange={(event) => updateSearchInput(event.target.value)}
          placeholder="Search for country.."
        />
        <button className="input-group-text btn btn-primary">Search</button>
      </div>
      <div className="list-unstyled row">
        {filteredList
          .slice(countryRenderIndex, pageSizeIndex())
          .map((item, index) => (
            <div
              key={index}
              onClick={() => selectCountry(item)}
              className="col-md-2 col-xl-2 d-none d-md-block d-xl-block mb-2"
            >
              <img
                id="airtime"
                className="avatar avatar-xl bradius cover-image d-block mb-1 width-100 pointer"
                src={item["flag"]}
                onError={(e) => (e.target.src = fallbackImage)}
                alt={item["provider"]}
              />
              <h6 className="fs-8px text-center">{item["common_name"]}</h6>
            </div>
          ))}
      </div>
      {filteredList.length <= pageSizeIndex() ? (
        ""
      ) : (
        <div className="row mt-4">
          <div className="col-sm-12 col-md-5">
            <div className="dataTables_info" role="status" aria-live="polite">
              Showing results{" "}
              {`${countryRenderIndex + 1} - ${pageSizeIndex()} of ${
                filteredList.length
              } countries`}
            </div>
          </div>
          <div className="col-sm-12 col-md-7 col-md-push-6">
            <div className="dataTables_paginate float-end mb-4">
              <ul className="pagination">
                <li
                  className={`paginate_button page-item previous ${
                    canPreviousPage() ? "" : "disabled"
                  }`}
                >
                  <button onClick={previousPage} className="page-link">
                    Previous
                  </button>
                </li>
                <li
                  className={`paginate_button page-item next ${
                    canNextPage() ? "" : "disabled"
                  }`}
                >
                  <button onClick={nextPage} className="page-link">
                    {" "}
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountriesDataTable;
