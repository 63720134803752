import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import ProfileService from "../../../../utils/services/profile.service";

const UpdateUser = ({ domainBrand, profile, userDetails }) => {
  const address = profile.userData.address;
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [initialValues, updateInitialValues] = useState(userDetails);
  const [loading, updateLoading] = useState(false);

  const initialValidation = {
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    phone: Yup.string().required(),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(initialValidation),
    onSubmit: () => {},
  });

  useEffect(() => {
    updateInitialValues(userDetails);
  }, [userDetails]);

  const updateUser = async (event) => {
    event.preventDefault();
    updateLoading(true);
    delete address.state;
    const payload = {
      address: { ...address },
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      phone: formik.values.phone,
    };
    const { data: responseData } = await ProfileService.editUserProfile(
      payload
    );

    console.log(responseData);
    updateLoading(false);
  };

  return (
    <div className="card-body">
      <form>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus ? dictionary["para_first_name"] : "First name"}
            </label>
            <input
              className={`form-control ${
                formik.errors["firstName"] ? "is-invalid" : "is-valid"
              }`}
              type="text"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
            {formik.errors["firstName"] ? (
              <div className="invalid-feedback">Please provide first name.</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_last_name"] : "Last Name"}
            </label>
            <input
              type="text"
              name="lastName"
              className={`form-control ${
                formik.errors["lastName"] ? "is-invalid" : "is-valid"
              }`}
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
            {formik.errors["firstName"] ? (
              <div className="invalid-feedback">Please provide last name.</div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>{languageStatus ? dictionary["para_email"] : "Email"}</label>
            <input
              className="form-control is-valid"
              type="email"
              name="email"
              defaultValue={formik.values.email ? formik.values.email : ""}
              readOnly
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_phone_number"]
                : "Phone number"}
            </label>
            <input
              type="text"
              name="phone"
              className={`form-control ${
                formik.errors["phone"] ? "is-invalid" : "is-valid"
              }`}
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
            {formik.errors["phone"] ? (
              <div className="invalid-feedback">Phone Number is required</div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_address_line_1"]
                : "Address Line 1"}
            </label>
            <input
              type="text"
              className="form-control is-valid"
              defaultValue={address ? address["address1"] : ""}
              readOnly
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus
                ? dictionary["para_address_line_2"]
                : "Address Line 2"}
            </label>
            <input
              type="text"
              className="form-control is-valid"
              defaultValue={address ? address["address2"] : ""}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>{languageStatus ? dictionary["l_city"] : "City"}</label>
            <input
              type="text"
              className="form-control is-valid"
              defaultValue={address ? address["city"] : ""}
              readOnly
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_post_code"] : "Postcode"}
            </label>
            <input
              type="text"
              className="form-control is-valid"
              defaultValue={address ? address["postcode"] : ""}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label>
              {languageStatus ? dictionary["l_country"] : "Country"}
            </label>
            <input
              type="text"
              className="form-control is-valid"
              defaultValue={address ? address["countryCommonName"] : ""}
              readOnly
            />
          </div>
        </div>
        <button
          onClick={updateUser}
          className="btn btn-primary mt-3 w-50"
          type="button"
        >
          {languageStatus ? dictionary["button_submit"] : "Submit"}
          {loading ? (
            <>
              &nbsp;
              <i className="fa fa-spin fa-spinner" />
            </>
          ) : (
            ""
          )}
        </button>
      </form>
    </div>
  );
};

export default UpdateUser;
