import Select from "react-select";
import { dynamicFieldsDropdown } from "../../utils/helpers/logicHelper";

const DROPDOWN = ({ divClass, eachField, formik }) => {
  const dropdownValues = dynamicFieldsDropdown(eachField["dropDown"]);
  const menuPortalTarget = document.getElementById("root");
  const key = eachField["key"];
  return (
    <div className={divClass}>
      <label>
        {eachField["label"]}
        <span style={{ color: "red" }}>*</span>
      </label>
      <Select
        options={dropdownValues}
        isClearable={false}
        isSearchable={false}
        maxMenuHeight={250}
        menuPlacement="bottom"
        menuPortalTarget={menuPortalTarget}
        name={key}
        onChange={(option) => formik.setFieldValue(key, option["value"])}
      />
    </div>
  );
};

const INPUT = ({ divClass, eachField, formik }) => {
  const key = eachField["key"];
  return (
    <div className={divClass}>
      <label>
        {eachField["label"]}
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        name={key}
        defaultValue={eachField["defaultValue"]}
        className={`form-control ${formik.errors["state"] ? "is-invalid" : ""}`}
        onChange={formik.handleChange}
        minLength={eachField["minCharacterLen"]}
        maxLength={eachField["maxCharacterLen"]}
      />
    </div>
  );
};

const DynamicFields = ({ divClass, eachField, formik }) => {
  let renderState;
  renderState =
    eachField["inputType"] === "DROP_DOWN"
      ? DROPDOWN({ divClass, eachField, formik })
      : eachField["inputType"] === "FREE_TEXT_SINGLE_LINE"
      ? INPUT({ divClass, eachField, formik })
      : INPUT({ divClass, eachField, formik });
  return <> {renderState} </>;
};

export default DynamicFields;
