import { toast } from "react-hot-toast";
import ActionCreator from "../../utils/helpers/actionCreator";
import { fetchInterestRates } from "../../utils/services/acc-management.service";
import * as AccManagementConstants from "../constants/acc-management.constants";
import { expiredTokenCheck } from "./auth.action";

export const fetchAccInterestRate = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(AccManagementConstants.FETCH_INTEREST_RATE_REQUEST));

    const { data: responseData } = await fetchInterestRates();

    // if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        AccManagementConstants.FETCH_INTEREST_RATE_SUCCESS,
        responseData
      )
    );

    toast.success("Interest rate fetched successfully.");
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(AccManagementConstants.FETCH_INTEREST_RATE_FAILURE));
  }
};
