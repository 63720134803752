import Axios from "./axios.service";

const accountsService = {
  fetchAccounts(payload, type) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/admin/account/${type}`,
      data: payload,
    });
  },

  createWallet(payload) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/walletmanagement/createnewwalletaccount`,
      data: payload,
    });
  },

  requestAccount(payload) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/walletmanagement/request-account`,
      data: payload,
    });
  },

  fetchAllWallets() {
    return Axios({
      method: "GET",
      url: `/web-services/api/v6/services/walletmanagement/walletaccountlist`,
    });
  },

  fetchWallets(currency) {
    return Axios({
      method: "GET",
      url: `/web-services/api/v6/services/walletmanagement/walletaccountlist?account-currency=${currency}`,
    });
  },

  fetchWalletBalance(currency, startYear, accountQuery) {
    return Axios({
      method: "GET",
      url: `/web-services/api/v6/services/walletmanagement/wallet-account-balance/${currency}?start-year=${startYear}${accountQuery}`,
    });
  },

  fetchWalletLedger(payload) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/walletmanagement/walletledger`,
      data: payload,
    });
  },

  accountLookUp(payload) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/walletmanagement/walletaccountinfolookup`,
      data: payload,
    });
  },
};

export default accountsService;
