import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import Body from "./Body";
import { getCountries } from "../../../../store/actions/country.action";
import { Loading } from "../../../../utils/helpers/constants";

const TreasuryAccounts = () => {
  const dispatch = useDispatch();
  const { domainBrand, component, users, country } = useSelector(
    ({
      accountsReducer,
      domainBrandReducer,
      componentReducer,
      usersReducer,
      countryReducer,
    }) => ({
      accounts: accountsReducer,
      domainBrand: domainBrandReducer,
      component: componentReducer,
      users: usersReducer,
      country: countryReducer,
    })
  );
  const currencyArray = [
    {
      capital: "",
      common_name: "",
      currencyCode: "",
      currencySymbol: "",
      delivery_methods: "",
      flag: "",
      iso2Code: "GE",
      iso3Code: "GEO",
      label: "All",
      telephoneCode: "",
      value: "All",
    },
    ...country?.currencies,
  ];

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
  }, []);

  const loadState = domainBrand.loading === Loading.FETCHING;

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="accounting"
              active="treasury"
              domainBrand={domainBrand}
            />
            <Body
              // list={accounts["accountList"]}
              currency={currencyArray}
              users={users}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default TreasuryAccounts;
