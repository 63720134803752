import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../components/loaders/SphereLoader";
import KycHeaderMenu from "../../../components/header/KycHeaderMenu";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { toggleNav } from "../../../store/actions/component.action";
import { getCountries } from "../../../store/actions/country.action";
import { awaitingComplianceRequirements } from "../../../store/actions/compliance.action";
import { Loading } from "../../../utils/helpers/constants";

const KycUpload = () => {
  const dispatch = useDispatch();
  const [steps, updateSteps] = useState(0);
  const [initMethod, setInitMethod] = useState("E-PHOTO");
  const { domainBrand, component, compliance, country, profile } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      complianceReducer,
      countryReducer,
      profileReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      compliance: complianceReducer,
      country: countryReducer,
      profile: profileReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    compliance.fetchingAwaitingCompliance === Loading.FETCHING;

  useEffect(() => {
    dispatch(toggleNav(false));
    batch(() => {
      dispatch(getCountries());
      dispatch(awaitingComplianceRequirements());
    });
  }, []);

  useEffect(() => {
    if (compliance.fetchingAwaitingCompliance === Loading.SUCCESS) {
      if (compliance.ePhoto.status) updateSteps(steps + 1);
      if (compliance.eVideo.status) updateSteps(steps + 1);
      if (compliance["awaitingDocuments"].length > 0) updateSteps(steps + 1);
      const method = compliance.ePhoto.status
        ? "E-PHOTO"
        : compliance.eVideo.status
        ? "E-VIDEO"
        : "DOCUMENTS";
      setInitMethod(method);
    }
  }, [compliance.fetchingAwaitingCompliance]);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <KycHeaderMenu menu="home" domainBrand={domainBrand} />
            <Body
              domainBrand={domainBrand}
              steps={steps}
              eVideo={compliance.eVideo}
              ePhoto={compliance.ePhoto}
              verifyEmail={compliance.verifyEmail}
              documents={compliance["awaitingDocuments"]}
              countries={country["supportedCountries"]}
              profile={profile["userData"]}
              initMethod={initMethod}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default KycUpload;
