import { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getAllCustomersUsers } from "../../store/actions/users.action";

import { toggleCustomerUserStatus } from "../../utils/services/users.services";

const ToggleUserStatusModal = ({ onchange, toggledSuccess, toggleProps }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const toggleResource = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    const state =
      toggleProps.status === "ACTIVATED" || toggleProps.status === "CONFIRMED"
        ? true
        : toggleProps.status === "DEACTIVATED"
        ? false
        : null;

    const { data: responseData } = await toggleCustomerUserStatus(
      !state,
      toggleProps.id
    );
    dispatch(getAllCustomersUsers());
    setLoading(false);
    if (responseData.status !== "SUCCESS") setError(true);
    else {
      handleClose();
      toggledSuccess();
    }
  };

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="m-4">
            <strong>
              {toggleProps.status === "ACTIVATED" ||
              toggleProps.status === "CONFIRMED"
                ? "Disable "
                : toggleProps.status === "DEACTIVATED"
                ? "Enable "
                : null}{" "}
              {toggleProps.firstName}'s account
            </strong>
            <hr />
            {error ? (
              <div className="mb-4 text-danger semibold-text">
                Issues, with toggling account, try again.
              </div>
            ) : (
              <div className="mb-4">
                Are you sure you want to{" "}
                {toggleProps.status === "ACTIVATED" ||
                toggleProps.status === "CONFIRMED"
                  ? " disable "
                  : toggleProps.status === "DEACTIVATED"
                  ? " enable "
                  : null}{" "}
                this account?
              </div>
            )}
            <div className="mb-4">
              You can send in a support request about this information, and may
              choose not to continue with this action.
            </div>
          </div>
          <Button className="ms-lg-4" variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="ms-lg-2"
            variant={`${
              toggleProps.status === "ACTIVATED" ? "danger" : "primary"
            }`}
            onClick={toggleResource}
          >
            {toggleProps.status === "ACTIVATED" ||
            toggleProps.status === "CONFIRMED"
              ? "Disable "
              : toggleProps.status === "DEACTIVATED"
              ? "Enable"
              : null}
            {loading ? (
              <>
                &nbsp;
                <i className="fa fa-spin fa-spinner" />
              </>
            ) : (
              ""
            )}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ToggleUserStatusModal;
