import Axios from "./axios.service";

export const allCountries = async () => {
  return Axios({
    method: 'GET',
    url: `/web-services/api/v6/services/utilitiesmanagement/list-all-countries`,
  });
};

export const fetchOriginSupportedCountries = async () => {
  return Axios({
    method: 'GET',
    url: '/web-services/api/v6/services/quote/supported-origin-countries',
  });
};

export const supportedWalletCurrencies = async () => {
  return Axios({
    method: 'GET',
    url: `/web-services/api/v6/services/walletmanagement/listSupportedwalletaccountcurrencies`,
  });
};

export const supportedCountries = async (originCountry, deliveryMethod) => {
  return Axios({
    method: 'GET',
    url: `/web-services/api/v6/services/quote/destination-country-by-delivery-method/${originCountry}/${deliveryMethod}`
  });
};

export const cashPickupLocations = async originCountry => {
  return Axios({
    method: 'GET',
    url: `/web-services/api/v6/services/branches/list-pickup-locations/${originCountry}`
  });
};

export const fetchSupportedSendingCurrencies = async (originCountry, destinationCountry, deliveryMethod) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/quote/supported-currencies/${originCountry}/${destinationCountry}/${deliveryMethod}`
  });
};

export const fetchSupportedSendingFromCurrencies = async (originCountry, destinationCountry, deliveryMethod, receivingCurrency) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/quote/supported-from-currencies/${originCountry}/${destinationCountry}/${deliveryMethod}/${receivingCurrency}`,
  });
};

export const fetchSupportedReceivingCurrencies = async (originCountry, destinationCountry, deliveryMethod, sendingCurrency) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/quote/supported-delivery-currencies/${originCountry}/${destinationCountry}/${deliveryMethod}/${sendingCurrency}`,
  });
};
