export const navBarStyles = {
  option: (provided) => {
    return {
      ...provided,
      padding: "0.5rem 1rem",
      color: "#03014C",
      background: "white",
      ":hover": {
        color: "white",
        background: "#9e88f5",
      },
    };
  },
  control: (provided) => {
    return {
      ...provided,
      background: "white",
      border: 0,
      color: "red",
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: "#03014C",
      fontSize: "14px",
      fontWeight: 300,
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      minWidth: "5rem",
    };
  },
};

export const errorStyles = {
  control: (provided) => {
    return {
      ...provided,
      background: "white",
      borderColor: "red",
      color: "red",
      ":hover": {
        color: "white",
        borderColor: "red",
      },
    };
  },
  container: (provided) => {
    return {
      ...provided,
      background: "white",
      borderColor: "red",
      color: "red",
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      borderColor: "red",
    };
  },
};
