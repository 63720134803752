import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { batch, useDispatch, useSelector } from "react-redux";

import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import HeaderMenu from "../../../components/header/HeaderMenu";
import SphereLoader from "../../../components/loaders/SphereLoader";
import { getAllWallets } from "../../../store/actions/accounts.action";
import { toggleNav } from "../../../store/actions/component.action";
import { getSupportedCurrencies } from "../../../store/actions/country.action";
import { Loading, userTypes } from "../../../utils/helpers/constants";
import {
  addFillToTransactionHistoryStatus,
  formatDashboardStatement,
  reorderGraphData,
} from "../../../utils/helpers/logicHelper";
import { transactionDataMap } from "../../../utils/helpers/mappers";
import accountsService from "../../../utils/services/accounts.service";
import { fetchTransactionHistory } from "../../../utils/services/transfer.service";
import Body from "./Body";

const Home = () => {
  const dispatch = useDispatch();
  const date = new Date();
  const priorDate = new Date().setDate(date.getDate() - 90);
  const daysAgo = new Date(priorDate);
  const [transactionHistory, updateTransactionHistory] = useState([]);
  const [graphData, updateGraphData] = useState([]);
  const [statement, updateStatement] = useState([]);
  const [loadingStatement, updateLoadingStatement] = useState(false);
  const [loadingHistory, updateLoadingHistory] = useState(false);
  const { accounts, domainBrand, component, countries, profile, transfer } =
    useSelector(
      ({
        accountsReducer,
        domainBrandReducer,
        componentReducer,
        countryReducer,
        profileReducer,
        transferReducer,
      }) => ({
        accounts: accountsReducer,
        domainBrand: domainBrandReducer,
        component: componentReducer,
        countries: countryReducer,
        profile: profileReducer,
        transfer: transferReducer,
      })
    );
  const loadState =
    loadingHistory ||
    loadingStatement ||
    domainBrand.loading === Loading.FETCHING ||
    countries.loadingCurrencies === Loading.FETCHING ||
    profile.loading === Loading.FETCHING ||
    transfer.loading === Loading.FETCHING ||
    accounts.loading === Loading.FETCHING;

  useEffect(() => {
    dispatch(toggleNav(false));
    if (profile.userType === userTypes.CUSTOMER) {
      batch(() => {
        dispatch(getAllWallets());
        if (countries.loadingCurrencies !== Loading.SUCCESS)
          dispatch(getSupportedCurrencies());
      });
    }
  }, [profile.userType]);

  useEffect(async () => {
    if (
      countries.loadingCurrencies === Loading.SUCCESS &&
      profile.userType === userTypes.CUSTOMER
    ) {
      const fromDate = moment(daysAgo).format();
      const toDate = moment(date).endOf("day").format();
      const objectValues = { fromDate, toDate };
      if (countries["supportedCurrencies"].length > 0)
        // objectValues.selectedCurrency =
        //   countries["supportedCurrencies"][0].value;
        dispatchTransferHistory(objectValues).catch((err) => console.log(err));
      dispatchAccountStatement(
        countries["supportedCurrencies"],
        fromDate,
        toDate
      ).catch((err) => console.log(err));
    }
  }, [countries.loadingCurrencies]);

  const dispatchTransferHistory = async (historyObject) => {
    updateLoadingHistory(true);
    const { data: responseData } = await fetchTransactionHistory(historyObject);
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch transaction history, try again`);
    else {
      let chartData = [];
      await updateTransactionHistory(
        addFillToTransactionHistoryStatus(
          responseData.data["transactionHistory"]
        )
      );
      delete responseData.data["transactionHistory"];
      delete responseData.data["voidCount"];
      delete responseData.data["heldCount"];
      delete responseData.data["failedCount"];
      delete responseData.data["pendingCount"];
      delete responseData.data["expiredCount"];
      delete responseData.data["totalCount"];
      Object.keys(responseData.data).forEach((key) =>
        chartData.push({
          name: transactionDataMap[key].label,
          value: responseData.data[key],
          fill: transactionDataMap[key].fill,
        })
      );
      updateGraphData(
        reorderGraphData(chartData, [0, 3, 1, 4, 2], chartData.length)
      );
      // updateGraphData(reorderGraphData(chartData, [0, 3, 4, 2, 1], chartData.length));
    }
    updateLoadingHistory(false);
  };

  const dispatchAccountStatement = async (currencies, fromDate, toDate) => {
    let statementArray = [];
    updateLoadingStatement(true);
    for (const element of currencies) {
      const payload = { selectedCurrency: element.value, toDate, fromDate };
      const { data: responseData } = await accountsService.fetchWalletLedger(
        payload
      );
      const data = formatDashboardStatement(
        responseData.data["walletStatemtent"]
      );
      const currencyStatement = {
        currency: element.value,
        moneyIn: data.moneyIn,
        moneyOut: data.moneyOut,
      };
      statementArray.push(currencyStatement);
    }
    updateStatement(statementArray);
    updateLoadingStatement(false);
  };

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <Header domainBrand={domainBrand} />
            <HeaderMenu active="home" menu="home" domainBrand={domainBrand} />
            <Body
              profile={profile}
              domainBrand={domainBrand}
              recentHistory={transactionHistory}
              statement={statement}
              graphData={graphData}
              accounts={accounts["accountList"]}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default Home;
