import { dynamicFieldsDropdown } from "../../utils/helpers/logicHelper";
import Select from "react-select";

const InputField = ({ eachField, formik, keyPressed }) => {
  const key = eachField["apiName"];
  const value =
    formik.values[key] === null || typeof formik.values[key] === "undefined"
      ? ""
      : formik.values[key];
  return (
    <>
      <div className="col-md-12 mb-3">
        <label>{eachField["label"]}</label>
        <input
          type="text"
          className="form-control"
          name={key}
          value={value}
          minLength={eachField["minLength"]}
          maxLength={eachField["maxLength"]}
          onChange={formik.handleChange}
          onKeyUp={() => keyPressed(true)}
          required={eachField["required"]}
        />
      </div>
    </>
  );
};

const DropDownField = ({ eachField, formik }) => {
  const dropdownValues = dynamicFieldsDropdown(eachField["dropDownValues"]);

  const key = eachField["apiName"];

  const menuPortalTarget = document.getElementById("root");
  let dropdownValue = formik.values[key];

  if (key === "bankname") {
    const bankDetails = dropdownValues.find(
      (element) => element["label"] === formik.values["bankname"]
    );

    if (typeof bankDetails !== "undefined") {
      dropdownValue = bankDetails;
    }
  }

  return (
    <>
      <div className="col-md-12 mb-3">
        <label>{eachField["label"]}</label>
        <Select
          options={dropdownValues}
          isClearable={false}
          isSearchable={false}
          maxMenuHeight={250}
          menuPlacement="bottom"
          menuPortalTarget={menuPortalTarget}
          value={dropdownValue}
          name={key}
          onChange={(option) => formik.setFieldValue(key, option)}
        />
      </div>
    </>
  );
};

const FilledBankFields = ({ bankFields, formik, keyPressed, userType }) => {
  const renderBankFields = bankFields.map((eachField, index) => {
    if (eachField.inputType === "DROP_DOWN") {
      return (
        <DropDownField key={index} eachField={eachField} formik={formik} />
      );
    } else {
      return (
        <>
          <InputField
            key={index}
            eachField={eachField}
            formik={formik}
            keyPressed={keyPressed}
          />
        </>
      );
    }
  });

  return <div className="form-row">{renderBankFields}</div>;
};

export default FilledBankFields;
