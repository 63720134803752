import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getEmployees } from "../../store/actions/employees.action";
import { employeeSchema } from "../../utils/helpers/validationHelpers";
import { editEmployee } from "../../utils/services/employees.service";

function EditEmployeeModal({
  handleClose,
  handleUpdate,
  show,
  loading,
  countries,
  domainBrand,
  employeeObject,
}) {
  const formRef = useRef();
  const dispatch = useDispatch();

  const [country, setCountry] = useState({});

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const userId = employeeObject?.userId;

  const editStaff = async (values, { resetForm }) => {
    try {
      const payload = {
        userId: userId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: employeeObject?.email,
        phone: employeeObject?.phone,
        addressEntityDTO: {
          address1: values.address,
          address2: "string",
          city: values.city,
          countryCommonName: country
            ? country.common_name
            : employeeObject.addressEntityDTO?.countryCommonName,
          postcode: values.postCode,
          province: values.province,
          countryIso3: country
            ? country.iso3Code
            : employeeObject.addressEntityDTO?.countryIso3,
          state: employeeObject.addressEntityDTO?.state,
        },
        staffActiveDirectoryId: values
          ? values.staffId
          : employeeObject.staffActiveDirectoryId,
      };
      const response = await editEmployee(payload);
      if (response.data.status) {
        toast.success(response.data.message);
        dispatch(getEmployees());
        resetForm();
        handleClose();
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <Modal show={show} backdrop="static" keyboard={false} size={"md"}>
      <Modal.Header closeButton={false}>
        <Modal.Title className="fw-bold">Edit Employee</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            firstName: employeeObject?.firstName,
            lastName: employeeObject?.lastName,
            address: employeeObject?.addressEntityDTO?.address1,
            province: employeeObject?.addressEntityDTO?.province,
            city: employeeObject?.addressEntityDTO?.city,
            staffId: employeeObject?.staffActiveDirectoryId,
            postCode: employeeObject?.addressEntityDTO?.postcode,
          }}
          validationSchema={employeeSchema}
          onSubmit={editStaff}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <Form
                ref={formRef}
                className="needs-validation"
                onSubmit={handleSubmit}
              >
                <div className="form-row">
                  <div className="col-md-6 col-lg-6 mb-3">
                    <label>
                      {dictionary
                        ? dictionary["para_first_name"]
                        : "First Name"}
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={values.firstName}
                      className={`form-control`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>

                  <div className="col-md-6 col-lg-6 mb-3">
                    <label>
                      {dictionary ? dictionary["l_last_name"] : "Last Name"}
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={values.lastName}
                      className={`form-control`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>

                  <div className="col-md-6 col-lg-6 mb-3">
                    <label>
                      {dictionary ? dictionary["para_address"] : "Address"}
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={values.address}
                      className={`form-control`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>

                  <div className="col-md-6 col-lg-6 mb-3">
                    <label>
                      {dictionary ? dictionary["para_province"] : "Province"}
                    </label>
                    <input
                      type="text"
                      name="province"
                      id="province"
                      value={values.province}
                      className={`form-control`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>

                  <div className="col-md-6 col-lg-6 mb-3">
                    <label>{dictionary ? dictionary["l_city"] : "City"}</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      value={values.city}
                      className={`form-control`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>

                  <div className="col-md-6 col-lg-6 mb-3">
                    <label>
                      {dictionary
                        ? dictionary["WO_STAFF_I_-725097220"]
                        : "Staff ID"}
                    </label>
                    <input
                      type="text"
                      name="staffId"
                      value={values.staffId}
                      className={`form-control`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>

                  <div className="col-md-6 col-lg-6 mb-3">
                    <label>
                      {dictionary ? dictionary["l_postcode"] : "Area/Postcode"}
                    </label>
                    <input
                      type="text"
                      name="postCode"
                      id="postCode"
                      value={values.postCode}
                      className={`form-control`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>

                  <div className="col-md-6 col-lg-6 mb-3">
                    <label>
                      {dictionary ? dictionary["para_country"] : "Country"}

                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Select
                      options={countries}
                      isClearable={false}
                      isSearchable={true}
                      maxMenuHeight={250}
                      menuPlacement="bottom"
                      value={country}
                      onChange={(option) => setCountry(option)}
                      // defaultValue={amlObject?.country}
                    />
                  </div>
                </div>

                <div className="float-end pt-4">
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="pe-2"
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleUpdate}
                    className="fw-bold px-3 ms-2"
                    type="submit"
                  >
                    Update
                    {isSubmitting ? (
                      <>
                        &nbsp;
                        <i className="fa fa-spinner fa-spin" />
                      </>
                    ) : (
                      ""
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default EditEmployeeModal;

EditEmployeeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
