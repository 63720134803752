import addUser from "../../assets/images/svgs/addUser.svg";
import bankSvg from "../../assets/images/svgs/bank.svg";
import cardSvg from "../../assets/images/svgs/card.svg";
import cashSvg from "../../assets/images/svgs/cash.svg";
import cryptoSvg from "../../assets/images/svgs/crypto.svg";
import mobileSvg from "../../assets/images/svgs/mobile.svg";
import statementSvg from "../../assets/images/svgs/statement.svg";
import transferSvg from "../../assets/images/svgs/transfer.svg";
import walletSvg from "../../assets/images/svgs/wallet.svg";
import { transferStatus } from "./constants";

export const envMapper = {
  TEST: {
    id: 1,
    env: "moneytransferapplication.uk",
  },
  OlD_ENV: {
    id: 2,
    env: "themoneytransferapplication.com",
  },
  LIVE: {
    id: 3,
    env: "moneytransferapplication.cloud",
  },
  SPOTBANC_LIVE: {
    id: 4,
    env: "spotbanc.cloud",
  },
  IBANC26_LIVE: {
    id: 5,
    env: "spotbanc.cloud",
  },
};

export const subDomainMapper = {
  TEST: {
    id: 1,
    SUBDOMAIN: "test",
    API_SUBDOMAIN: "test",
    API_ENV: envMapper.TEST,
  },
  STAG: {
    id: 2,
    SUBDOMAIN: "stag",
    API_SUBDOMAIN: "stag",
    API_ENV: envMapper.TEST,
  },
  DEMO: {
    id: 3,
    SUBDOMAIN: "demo",
    API_SUBDOMAIN: "demo",
    API_ENV: envMapper.TEST,
  },
  STERN: {
    id: 4,
    SUBDOMAIN: "stern",
    API_SUBDOMAIN: "stern",
    API_ENV: envMapper.LIVE,
  },
  RIIO: {
    id: 5,
    SUBDOMAIN: "riio",
    API_SUBDOMAIN: "riio",
    API_ENV: envMapper.LIVE,
  },
  PAGOTEC: {
    id: 6,
    SUBDOMAIN: "pagotec",
    API_SUBDOMAIN: "pagotec",
    API_ENV: envMapper.LIVE,
  },
  HVC: {
    id: 7,
    SUBDOMAIN: "hvc",
    API_SUBDOMAIN: "hvc",
    API_ENV: envMapper.LIVE,
  },
  HELVTICA: {
    id: 8,
    SUBDOMAIN: "helvtica",
    API_SUBDOMAIN: "helvtica",
    API_ENV: envMapper.LIVE,
  },
  WORFX: {
    id: 9,
    SUBDOMAIN: "worfx",
    API_SUBDOMAIN: "worfx",
    API_ENV: envMapper.LIVE,
  },
  KOGOPAY: {
    id: 10,
    SUBDOMAIN: "kogopay",
    API_SUBDOMAIN: "kogopay",
    API_ENV: envMapper.TEST,
  },
  EUROFUNDING: {
    id: 11,
    SUBDOMAIN: "eurofunding",
    API_SUBDOMAIN: "eurofunding",
    API_ENV: envMapper.LIVE,
  },
  WISELYWIRE: {
    id: 12,
    SUBDOMAIN: "wiselywire",
    API_SUBDOMAIN: "wiselywire",
    API_ENV: envMapper.LIVE,
  },
  SPECTRUM1: {
    id: 13,
    SUBDOMAIN: "spectrum1",
    API_SUBDOMAIN: "spectrum1",
    API_ENV: envMapper.TEST,
  },
  FCMB1: {
    id: 14,
    SUBDOMAIN: "fcmb1",
    API_SUBDOMAIN: "fcmb1",
    API_ENV: envMapper.LIVE,
  },
  FCMB: {
    id: 15,
    SUBDOMAIN: "fcmb",
    API_SUBDOMAIN: "fcmb",
    API_ENV: envMapper.LIVE,
  },
  UNISPAY2: {
    id: 16,
    SUBDOMAIN: "unispay2",
    API_SUBDOMAIN: "unispay2",
    API_ENV: envMapper.TEST,
  },
  PDEMONEY2: {
    id: 17,
    SUBDOMAIN: "pdemoney2",
    API_SUBDOMAIN: "pdemoney2",
    API_ENV: envMapper.TEST,
  },
  CHROMEPAY: {
    id: 18,
    SUBDOMAIN: "chromepay",
    API_SUBDOMAIN: "chromepay",
    API_ENV: envMapper.LIVE,
  },
  EUROMONEYEXCHANGE1: {
    id: 19,
    SUBDOMAIN: "euromoneyexchange1",
    API_SUBDOMAIN: "euromoneyexchange1",
    API_ENV: envMapper.TEST,
  },
  IBANC26: {
    id: 20,
    SUBDOMAIN: "ibanc26",
    API_SUBDOMAIN: "ibanc26",
    API_ENV: envMapper.IBANC26_LIVE,
  },
  IPSGURKHA: {
    id: 21,
    SUBDOMAIN: "ipsgurkha",
    API_SUBDOMAIN: "ipsgurkha",
    API_ENV: envMapper.LIVE,
  },
  UNITYBANK: {
    id: 22,
    SUBDOMAIN: "unitybank",
    API_SUBDOMAIN: "unitybank",
    API_ENV: envMapper.LIVE,
  },
  CSLPAY: {
    id: 23,
    SUBDOMAIN: "cslpay",
    API_SUBDOMAIN: "cslpay",
    API_ENV: envMapper.LIVE,
  },
  DIGITALBANKING: {
    id: 24,
    SUBDOMAIN: "digitalbanking",
    API_SUBDOMAIN: "digitalbanking",
    API_ENV: envMapper.TEST,
  },
  FIBONACCICONSULTING: {
    id: 25,
    SUBDOMAIN: "fibonacciconsulting",
    API_SUBDOMAIN: "fibonacciconsulting",
    API_ENV: envMapper.TEST,
  },
  FIPAY: {
    id: 26,
    SUBDOMAIN: "fipay",
    API_SUBDOMAIN: "fipay",
    API_ENV: envMapper.TEST,
  },
};

export const employeesMapper = {
  ADMINS: {
    id: 1,
    name: "Administrators",
    type: "MANAGER",
    path: "/admins",
  },
  BRANCH_ADMINS: {
    id: 2,
    name: "Branch Administrators",
    type: "BRANCH_ADMIN",
    path: "/branch-admins",
  },
  EMPLOYEES: {
    id: 3,
    name: "Employees",
    type: "CUSTOM_EMPLOYEE",
    path: "/employees",
  },
  CASHIERS: {
    id: 4,
    name: "Cashiers",
    type: "CASHIER",
    path: "/cashier",
  },
  AGENTS: {
    id: 5,
    name: "Agents",
    type: "AGENTS",
    path: "/agents",
  },
  MERCHANTS: {
    id: 6,
    name: "Merchants",
    type: "MERCHANTS",
    path: "/merchants",
  },
};

export const supportedPaymentMap = {
  ONLINE_CARD_PAYMENT: {
    id: 1,
    icon: "fa fa-credit-card",
    image: cardSvg,
    text: "Card Payment",
    notePrefix: "Fast and easy payment with",
    lingualText: "para_online_card_payment",
  },
  BANK_TRANSFER: {
    id: 2,
    icon: "fa fa-university",
    image: bankSvg,
    text: "Bank Transfer",
    notePrefix: "Advanced transfer with",
    lingualText: "para_account_payment",
  },
  E_WALLET: {
    id: 3,
    icon: "fa fa-wallet",
    image: walletSvg,
    text: "Wallet Account",
    notePrefix: "Make payment from your account with",
    lingualText: "m_paycentre_account_wallet",
  },
  CRYPTO_CURRENCY_PAYMENT: {
    id: 4,
    icon: "fab fa-bitcoin",
    image: cryptoSvg,
    text: "Cryptocurrency",
    notePrefix: "Payment with multiple cryptocurrencies with",
    lingualText: "WO_SEND_CRYPTO_CURRENC_1971377118",
  },
  CASH: {
    id: 5,
    icon: "fa fa-money-bill",
    image: cashSvg,
    text: "Cash Payment",
    notePrefix: "Cash payment with",
    lingualText: "WO_SHOW_CASH_AT_AGEN_1583665807",
  },
  OTHER: {
    id: 5,
    icon: "fa fa-money-bill",
    image: transferSvg,
    text: "Other",
    notePrefix: "Fast and easy payment with",
    lingualText: "l_other",
  },
};

export const transferStatusMap = {
  TRANSFER_FUNDS: {
    id: 1,
    title: "WO_TRANSFER_FUND_-1694451190",
    transactionType: "ACCOUNTPAYMENT",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
  WALLET: {
    id: 2,
    title: "WO_TRANSFER_FUND_-1694451190",
    transactionType: "WALLET",
    paymentType: "E_WALLET",
    chargeCategory: "WALLET_MONEY_FUND_TRANSFER",
  },
  CASH_PICKUP: {
    id: 3,
    title: "para_cash_pickup",
    transactionType: "CASHPICKUP",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
  MOBILE_MONEY: {
    id: 4,
    title: "MOBILE_MONE_1497679979",
    transactionType: "MOBILE_MONEY",
    paymentType: "ONLINE_CARD_PAYMENT",
    // chargeCategory: "MOBILE_MONEY_TRANSFER",
    chargeCategory: "MONEYTRANSFER",
  },
  AIRTIME_TOPUP: {
    id: 5,
    title: "m_mobile_top_up",
    transactionType: "AIRTIME_TOPUP",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
  BILL_PAYMENT: {
    id: 6,
    title: "WO_BILL_PAYMEN_-1233806888",
    transactionType: "BILL_PAYMENT",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
  MERCHANT_PRODUCT: {
    id: 6,
    title: "WO_MERCHANT_PRODUCT_1436670176",
    transactionType: "MERCHANT_PRODUCT",
    paymentType: "MERCHANT_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
};

export const transactionMethodsMap = {
  ACCOUNTPAYMENT: {
    id: 1,
    title: "WO_TRANSFER_FUND_-1694451190",
    transactionType: "ACCOUNTPAYMENT",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
  CASHPICKUP: {
    id: 2,
    title: "para_cash_pickup",
    transactionType: "CASHPICKUP",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
  MOBILE_MONEY: {
    id: 3,
    title: "MOBILE_MONE_1497679979",
    transactionType: "MOBILE_MONEY",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MOBILE_MONEY_TRANSFER",
  },
  AIRTIME_TOPUP: {
    id: 4,
    title: "m_mobile_top_up",
    transactionType: "AIRTIME_TOPUP",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
  BILL_PAYMENT: {
    id: 5,
    title: "WO_BILL_PAYMEN_-1233806888",
    transactionType: "BILL_PAYMENT",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
  WALLET: {
    id: 6,
    title: "para_wallet_transfer",
    transactionType: "WALLET",
    paymentType: "E_WALLET",
    chargeCategory: "WALLET_MONEY_LOAD_CASH",
  },
  WALLET_CARD_PAYMENT: {
    id: 7,
    title: "para_wallet_transfer",
    transactionType: "WALLET",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
  BANK_TRANSFER: {
    id: 8,
    title: "para_account_payment",
    transactionType: "ACCOUNTPAYMENT",
    paymentType: "BANK_TRANSFER",
    chargeCategory: "MONEYTRANSFER",
  },
  CASH: {
    id: 9,
    title: "para_wallet_transfer",
    transactionType: "ACCOUNTPAYMENT",
    paymentType: "CASH",
    chargeCategory: "MONEYTRANSFER",
  },
  CRYPTO_CURRENCY: {
    id: 10,
    title: "para_wallet_transfer",
    transactionType: "ACCOUNTPAYMENT",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
  OTHER: {
    id: 11,
    title: "l_other",
    transactionType: "ACCOUNTPAYMENT",
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
  },
};

export const transactionTypesMap = {
  MULTICURRENCY_WALLET_VIRTUAL_ACCOUNT_MANAGEMENT: {
    id: 1,
    to: "/statement",
    icon: "fa fa-file-invoice",
    iconSvg: statementSvg,
    text: "Account Statement",
    lingualText: "l_wallet_transaction_history",
    visibleTo: ["MANAGER", "INDIVIDUAL", "COMPANY"],
  },
  ADD_NEW_USER: {
    id: 2,
    to: "/users",
    icon: "fa fa-user-plus",
    iconSvg: addUser,
    text: "Add new user",
    lingualText: "WO_ADD_A_NEW_USE_764293023",
    visibleTo: ["MANAGER", "INDIVIDUAL", "COMPANY"],
  },
  CASHPICKUP: {
    id: 3,
    to: "/transfer",
    icon: "fa fa-money-bill-alt",
    iconSvg: transferSvg,
    text: transferStatus.CASH_PICKUP,
    lingualText: "para_cash_pickup",
    visibleTo: ["MANAGER", "CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  MOBILEMONEY: {
    id: 4,
    to: "/transfer",
    icon: "fa fa-money-check",
    iconSvg: mobileSvg,
    text: transferStatus.MOBILE_MONEY,
    lingualText: "MOBILE_MONE_1497679979",
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  ACCOUNTPAYMENT: {
    id: 5,
    to: "/transfer",
    icon: "fa fa-exchange-alt",
    iconSvg: transferSvg,
    text: transferStatus.TRANSFER_FUNDS,
    lingualText: "WO_TRANSFER_FUND_1805465128",
    visibleTo: ["MANAGER", "CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  INTERNATIONALREMITTANCE: {
    id: 6,
    to: "/payout-search",
    icon: "fa fa-handshake",
    iconSvg: addUser,
    text: "Payout",
    lingualText: "title_payout",
    visibleTo: ["MANAGER", "CASHIER"],
  },
  REQUEST_BANK_ACCOUNT: {
    id: 7,
    to: "/request-account",
    icon: "fa fa-wallet",
    iconSvg: walletSvg,
    text: "Request bank account",
    lingualText: "WO_REQUEST_BANK_ACCOUN_1842427382",
    visibleTo: ["INDIVIDUAL", "COMPANY"],
  },
  MERCHANTPAYMENTS: {
    id: 8,
    to: "/merchant/products",
    icon: "fa fa-cart",
    iconSvg: addUser,
    text: "Merchant Product",
    lingualText: "WO_MERCHANT_PRODUCT_1436670176",
    visibleTo: ["MANAGER", "CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  CURRENCY_CONVERTER: {
    id: 9,
    to: "/merchant-product",
    icon: "fa fa-cart",
    iconSvg: addUser,
    text: "Currency Converter",
    lingualText: "WO_TRANSFER_FUND_1805465128",
    visibleTo: ["MANAGER", "CASHIER", "COMPANY"],
  },
  FUND_ACCOUNT: {
    id: 10,
    to: "fund-account",
    icon: "fa fa-credit-card",
    iconSvg: addUser,
    text: "Fund Account",
    lingualText: "l_fund_wallet",
    visibleTo: ["MANAGER", "CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  TRANSFER_MODES: {
    id: 11,
    to: "transfer",
    icon: "fa fa-exchange-alt",
    iconSvg: transferSvg,
    text: transferStatus.TRANSFER_FUNDS,
    lingualText: "WO_TRANSFER_FUND_1805465128",
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  MOBILEAIRTIMETOP_UP: {
    id: 12,
    to: "airtime-topup",
    icon: "fa fa-mobile",
    iconSvg: mobileSvg,
    text: "Airtime Top Up",
    lingualText: "m_mobile_top_up",
    visibleTo: ["MANAGER", "CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  BILLPAYMENT: {
    id: 13,
    to: "bill-payment",
    icon: "fa fa-credit-card",
    iconSvg: addUser,
    text: "Bills Payment",
    lingualText: "WO_BILL_PAYMEN_-1233806888",
    visibleTo: ["MANAGER", "CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  PAYROLL_MANAGEMENT: {
    id: 14,
    to: "bill-payment",
    icon: "fa fa-credit-card",
    iconSvg: addUser,
    text: "Payroll Management",
    lingualText: "WO_BILL_PAYMEN_-1233806888",
    visibleTo: ["MANAGER", "CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  PREPAID_CARDS_SERVICES: {
    id: 15,
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  PAYOUTBANKTRANSFER: { id: 16, visibleTo: ["CASHIER"] },
  AGENCY_BANKING_MANAGEMENT: {
    id: 17,
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  SMS_MONEY_TRANSFER: {
    id: 18,
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  SMS: { id: 19, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  SANCTION_LIST: { id: 20, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  VOUCHER_RECHARGE_CARDS_MANAGEMENT: {
    id: 21,
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  BUREAU_DE_CHANGE: { id: 22, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  MULTI_LINGUAL_LANGUAGE_SUPPORT: {
    id: 23,
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  E_DISBURSEMENT: { id: 24, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  LOAN: { id: 25, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  CRYPTO_CURRENCY: { id: 26, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
};

export const deleteModalPropsMap = {
  BENEFICIARY_BANK: {
    id: 1,
    prefix: "this beneficiary bank with details",
    name: "bankName",
    validator: "accountNumber",
    label: "label",
    placeholder: "Type in the bank account number to confirm",
  },
  PROFILE_DOCUMENT: {
    id: 2,
    prefix: "this document with issue number",
    name: "name",
    validator: "issueNo",
    label: "issueNo",
    placeholder: "Type in the issue number to confirm",
  },
};

export const transactionDataMap = {
  pendingPaymentCount: {
    id: 1,
    label: "Pending",
    fill: "#f7cb73",
  },
  cancelledCount: {
    id: 2,
    label: "Cancelled",
    fill: "#ff0e0e",
  },
  confirmedCount: {
    id: 3,
    label: "Confirmed",
    fill: "#42ba96",
  },
  expiredCount: {
    id: 4,
    label: "Expired",
    fill: "#ff0e0e",
  },
  failedCount: {
    id: 5,
    label: "Failed",
    fill: "#ff0e0e",
  },
  heldCount: {
    id: 6,
    label: "Held",
    fill: "#f7cb73",
  },
  paidOutCount: {
    id: 7,
    label: "Paid outs",
    fill: "#42ba96",
  },
  pendingCount: {
    id: 8,
    label: "Awaiting Verification",
    fill: "#f7cb73",
  },
  refundedCount: {
    id: 9,
    label: "Refunded",
    fill: "#ff0e0e",
  },
  totalCount: {
    id: 10,
    label: "Total",
    fill: "#42ba96",
  },
  voidCount: {
    id: 11,
    label: "Void Payment",
    fill: "#ff0e0e",
  },
};

export const transactionStatusMap = {
  PENDING: {
    resend: true,
    pay: false,
    view: true,
    cancel: false,
  },
  EXPIRED: {
    resend: true,
    pay: false,
    view: true,
    cancel: false,
  },
  CONFIRMED: {
    resend: true,
    pay: false,
    view: true,
    cancel: false,
  },
  CANCELLED: {
    resend: true,
    pay: false,
    view: true,
    cancel: false,
  },
  REFUNDED: {
    resend: true,
    pay: false,
    view: true,
    cancel: false,
  },
  PAID: {
    resend: true,
    pay: false,
    view: true,
    cancel: false,
  },
  PAID_OUT: {
    resend: true,
    pay: false,
    view: true,
    cancel: false,
  },
  FAILED: {
    resend: true,
    pay: false,
    view: true,
    cancel: false,
  },
  HELD: {
    resend: true,
    pay: false,
    view: true,
    cancel: false,
  },
  PENDING_PAYMENT: {
    resend: true,
    pay: true,
    view: true,
    cancel: true,
  },
};

export const currencyMap = {
  EUR: {
    symbol: "\u20AC",
    label: "Euros",
  },
  NGN: {
    symbol: "\u20A6",
    label: "Nigerian Naira",
  },
  GBP: {
    symbol: "\u00A3",
    label: "GB Pounds",
  },
  USD: {
    symbol: "\u0024",
    label: "US Dollars",
  },
  YEN: {
    symbol: "\u00A5",
    label: "Japanese Yen",
  },
  RUP: {
    symbol: "\u20B9",
    label: "Indian Rupees",
  },
  BDT: {
    symbol: "\u09F3",
    label: "Bangladesh Taka",
  },
  THB: {
    symbol: "\u0E3F",
    label: "Thailand Baht",
  },
  OTHER: {
    symbol: "\u00A3",
    label: "GB Pounds",
  },
};
