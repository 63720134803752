import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import { toggleNav } from "../../../../store/actions/component.action";
import { getAirtimeTopUpSupportedCountry } from "../../../../store/actions/country.action";
import { getProfile } from "../../../../store/actions/profile.action";
import { getAllBeneficiaries } from "../../../../store/actions/users.action";
import { resetQuoteData } from "../../../../store/actions/transfer.action";
import { transferStatusMap } from "../../../../utils/helpers/mappers";
import { Loading, transferStatus } from "../../../../utils/helpers/constants";

const AirtimeTopUp = () => {
  const dispatch = useDispatch();
  const {
    domainBrand,
    component,
    country,
    profile,
    transfer: { quoteData },
    users,
  } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      countryReducer,
      profileReducer,
      transferReducer,
      usersReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      country: countryReducer,
      component: componentReducer,
      profile: profileReducer,
      transfer: transferReducer,
      users: usersReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    country.loadingCountries === Loading.FETCHING;

  useEffect(() => {
    dispatch(resetQuoteData());
    dispatch(toggleNav(false));
    if (profile.userProfileLoading !== Loading.SUCCESS) dispatch(getProfile());
    if (users.loadingBeneficiaries !== Loading.SUCCESS)
      dispatch(getAllBeneficiaries());
  }, []);

  useEffect(() => {
    if (profile.originCountry)
      dispatch(getAirtimeTopUpSupportedCountry(profile.originCountry));
  }, [profile.originCountry]);

  const quoteObject = {
    paymentType: transferStatusMap[transferStatus.AIRTIME_TOPUP].paymentType,
    chargeCategory:
      transferStatusMap[transferStatus.AIRTIME_TOPUP].chargeCategory,
    transactionType:
      transferStatusMap[transferStatus.AIRTIME_TOPUP].transactionType,
    inverseCalculation: true,
    conversion: true,
  };

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="airtime"
              active="airtime"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              countries={country["airtimeTopUpCountries"]}
              quoteData={quoteData}
              profile={profile}
              beneficiaries={users["beneficiaries"]}
              quoteObject={quoteObject}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default AirtimeTopUp;
