import Axios from "../services/axios.service";

export const listBillsAndServices = () => {
  return Axios({
    method: "GET",
    url: "web-services/api/v6/services/report/pay-bill/reports",
  });
};

export const listMyTransactions = () => {
  return Axios({
    method: "GET",
    url: "web-services/api/v6/services/report/transaction/reports",
  });
};
