import Axios from "./axios.service";

export const listExchangeRateFeeds = () => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/exchange/rate/feed/list-exchange-rate-feeds`,
  });
};

export const addExchangeRateFeed = (payload) => {
  return Axios({
    method: "POST",
    url: `web-services/api/v6/services/exchange/rate/feed/add-exchange-rate-feed`,
    data: payload,
  });
};

export const editExchangeRateFeed = (payload) => {
  return Axios({
    method: "PUT",
    url: `web-services/api/v6/services/exchange/rate/feed/edit-exchange-rate-feeds`,
    data: payload,
  });
};

export const deleteExchangeRateFeed = (query) => {
  return Axios({
    method: "DELETE",
    url: `web-services/api/v6/services/exchange/rate/feed/delete-exchange-rate-feed/${query}`,
  });
};

export const linkExchangeRateToCorridor = (payload) => {
  return Axios({
    method: "PUT",
    url: `web-services/api/v6/services/exchange/rate/feed/link-exchange-rate-feed-to-corridor`,
    data: payload,
  });
};

export const calculatePercentage = (payload) => {
  return Axios({
    method: "POST",
    url: `web-services/api/v6/services/quote/calculate-percentage`,
    data: payload,
  });
};

export const linkExchangeRateFeedToCorridor = () => {
  return Axios({
    method: "PUT",
    url: `web-services/api/v6/services/exchange/rate/feed/link-exchange-rate-feed-to-corridor`,
  });
};

export const listPayCentersByType = (plugin_type) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/branches/list-paycenters-by-type/${plugin_type}`,
  });
};
