export const EXCHANGE_RATE_REQUEST = "EXCHANGE_RATE_REQUEST";
export const EXCHANGE_RATE_SUCCESS = "EXCHANGE_RATE_SUCCESS";
export const EXCHANGE_RATE_FAILURE = "EXCHANGE_RATE_FAILURE";

export const ADD_EXCHANGE_RATE_REQUEST = "ADD_EXCHANGE_RATE_REQUEST";
export const ADD_EXCHANGE_RATE_SUCCESS = "ADD_EXCHANGE_RATE_SUCCESS";
export const ADD_EXCHANGE_RATE_FAILURE = "ADD_EXCHANGE_RATE_FAILURE";

export const EDIT_EXCHANGE_RATE_REQUEST = "EDIT_EXCHANGE_RATE_REQUEST";
export const EDIT_EXCHANGE_RATE_SUCCESS = "EDIT_EXCHANGE_RATE_SUCCESS";
export const EDIT_EXCHANGE_RATE_FAILURE = "EDIT_EXCHANGE_RATE_FAILURE";

export const DELETE_EXCHANGE_RATE_REQUEST = "DELETE_EXCHANGE_RATE_REQUEST";
export const DELETE_EXCHANGE_RATE_SUCCESS = "DELETE_EXCHANGE_RATE_SUCCESS";
export const DELETE_EXCHANGE_RATE_FAILURE = "DELETE_EXCHANGE_RATE_FAILURE";

export const LINK_TO_EXCHANGE_RATE_CORRIDOR_REQUEST =
  "LINK_TO_EXCHANGE_RATE_CORRIDOR_REQUEST";
export const LINK_TO_EXCHANGE_RATE_CORRIDOR_SUCCESS =
  "LINK_TO_EXCHANGE_RATE_CORRIDOR_SUCCESS";
export const LINK_TO_EXCHANGE_RATE_CORRIDOR_FAILURE =
  "LINK_TO_EXCHANGE_RATE_CORRIDOR_FAILURE";

export const PAYCENTER_REQUEST = "PAYCENTER_REQUEST";
export const PAYCENTER_SUCCESS = "PAYCENTER_SUCCESS";
export const PAYCENTER_FAILURE = "PAYCENTER_FAILURE";
