import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Footer from "../../../../components/footer/Footer";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Body from "./Body";
// import { getAllAgents } from "../../../../store/actions/users.action";
import { toggleNav } from "../../../../store/actions/component.action";
import { getCountries } from "../../../../store/actions/country.action";
import { getEmployees } from "../../../../store/actions/employees.action";
import { Loading } from "../../../../utils/helpers/constants";
import { employeesMapper } from "../../../../utils/helpers/mappers";

const Employees = () => {
  const dispatch = useDispatch(),
    history = useHistory();
  const pathName = history.location.pathname.split("/")[2];
  const pageState = employeesMapper[pathName.toUpperCase()];
  const { domainBrand, component, country, employee } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      usersReducer,
      countryReducer,
      employeeReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      users: usersReducer,
      country: countryReducer,
      employee: employeeReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    employee.loadingEmployees === Loading.FETCHING;

  const currencyArray = [
    {
      capital: "",
      common_name: "",
      currencyCode: "",
      currencySymbol: "",
      delivery_methods: "",
      flag: "",
      iso2Code: "GE",
      iso3Code: "GEO",
      label: "All",
      telephoneCode: "",
      value: "All",
    },
    ...country.currencies,
  ];

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (employee.loadingEmployees !== Loading.SUCCESS) dispatch(getEmployees());
  }, []);

  useEffect(() => {
    dispatch(toggleNav(false));
    if (typeof pageState === "undefined") history.push("/");
    // dispatch(getAllAgents(pageState.type));
  }, [pageState]);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="management"
              active="employees"
              domainBrand={domainBrand}
            />
            <Body
              info={pageState}
              currency={currencyArray}
              employees={employee?.employees?.data?.employees}
              countries={country}
              domainBrand={domainBrand}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default Employees;
