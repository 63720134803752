import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import * as DomainBrand from "../../store/actions/domain-brand.action";
import MenuLinks from "./MenuLinks";
import { toggleNav } from "../../store/actions/component.action";
import {
  fetchAppLanguage,
  languageChangeHelper,
} from "../../utils/helpers/logicHelper";
import { navBarStyles } from "../selectStyles/customStyles";
import { languageDropDownOptions } from "../../utils/helpers/objectHelpers";
import { Loading } from "../../utils/helpers/constants";

const HeaderMenu = ({ active, menu, domainBrand }) => {
  const dispatch = useDispatch(),
    selectedLanguage = fetchAppLanguage();
  const profile = useSelector(({ profileReducer }) => profileReducer);
  const [languageField, updateLanguageField] = useState(
    selectedLanguage["commonName"]
  );
  const brandLangSelectorOptions =
    domainBrand.loading === Loading.SUCCESS
      ? domainBrand["domainBrand"]["language"]
      : languageDropDownOptions;
  const toggleNavBar = () => dispatch(toggleNav(false));

  const handleLanguageChange = (option) => {
    const languageHelper = languageChangeHelper(option);
    updateLanguageField(languageHelper["value"]);
    dispatch(
      DomainBrand.getDomainBrandIdentity(
        languageHelper["subdomain"],
        languageHelper["value"]["id"]
      )
    );
  };

  return (
    <>
      <div className="sticky">
        <div className="horizontal-main hor-menu clearfix">
          <div className="horizontal-mainwrapper container clearfix">
            <nav className="horizontalMenu clearfix">
              <div className="horizontal-overlapbg active" />
              <ul className="horizontalMenu-list xl-lg-nav">
                <MenuLinks
                  active={active}
                  menu={menu}
                  domainBrand={domainBrand}
                  profile={profile}
                />
              </ul>
              <ul className="horizontalMenu-list mobile-nav">
                <li onClick={toggleNavBar} className="text-end fs-20 p-5">
                  <i className="fa fa-times text-muted" />
                </li>
                <MenuLinks
                  active={active}
                  menu={menu}
                  domainBrand={domainBrand}
                  profile={profile}
                />
                <div className="dropdown mt-4rem">
                  <div className="nav-link text-center">
                    <Select
                      options={brandLangSelectorOptions}
                      isClearable={false}
                      isSearchable={false}
                      name={languageField}
                      value={selectedLanguage}
                      onChange={(option) => handleLanguageChange(option)}
                      styles={navBarStyles}
                    />
                  </div>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMenu;
