import Axios from './axios.service';

const merchantService = {

  fetchServices(country) {
    return Axios({
      method: 'GET',
      url: `/web-services/api/v6/services/merchantpayment/listmerchantcategorybycountry1/${country}`,
    });
  },

  fetchProducts(payload) {
    return Axios({
      method: 'POST',
      url: `/web-services/api/v6/services/merchantpayment/searchbyproductnameandcountry`,
      data: payload
    });
  },

  fetchCart() {
    return Axios({
      method: 'GET',
      url: `/web-services/api/v6/services/cartmanagement/get-all-items-in-cart`,
    });
  },

  addToCart(payload) {
    return Axios({
      method: 'POST',
      url: `/web-services/api/v6/services/cartmanagement/add-item-to-cart`,
      data: payload
    });
  }
}


export default merchantService;
