import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Body from "./Body";
import Footer from "../../../../components/footer/Footer";
import {Loading} from "../../../../utils/helpers/constants";

const ProductDetails = () => {
  const history = useHistory(), location = useLocation();
  const [productDescription, setProductDescription] = useState({});
  const {component, domainBrand} = useSelector(({componentReducer, domainBrandReducer}) => ({
    component: componentReducer,
    domainBrand: domainBrandReducer,
  }));

  const loadState = domainBrand.loading === Loading.FETCHING;

  useEffect(() => {
    if (typeof location.state === "undefined") history.push("/merchant/products");
    else setProductDescription(location.state.data);
  }, [location.state]);

  return <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
    {loadState ? <SphereLoader/> :
      <>
        <div className="page-main">
          <Header domainBrand={domainBrand}/>
          <HeaderMenu active="merchant" menu="details" domainBrand={domainBrand}/>
          <Body domainBrand={domainBrand} product={productDescription}/>
        </div>
        <Footer domainBrand={domainBrand}/>
      </>
    }
  </div>
};
export default ProductDetails;
