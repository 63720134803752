import {NavLink} from "react-router-dom";
import {menuType} from "../../utils/helpers/constants";

const KycHeaderMenu = ({menu, domainBrand}) => {

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(typeof dictionary === "undefined" || dictionary === null);

  return (
    <>
      <div className="sticky">
        <div className="horizontal-main hor-menu clearfix">
          <div className="horizontal-mainwrapper container clearfix">
            <nav className="horizontalMenu clearfix">
              <div className="horizontal-overlapbg active"/>
              <ul className="horizontalMenu-list">
                <li>
                  <NavLink exact to="/" className={menu === menuType.HOME ? "active" : ""}>
                    <i className="fa fa-home"/>{languageStatus ? dictionary['para_home'] : "Home"}
                  </NavLink>
                </li>

                <li>
                  <NavLink exact to="/profile"
                           className={menu === menuType.BENEFICIARY ? "sub-icon active" : "sub-icon"}>
                    <i className="fa fa-user-friends"/>
                    {languageStatus ? dictionary['WO_USER_PROFIL_548513751'] : "Profile"}
                  </NavLink>
                </li>

                <li>
                  <NavLink exact to="/support"
                           className={menu === menuType.BENEFICIARY ? "sub-icon active" : "sub-icon"}>
                    <i className="fa fa-question-circle"/>
                    {languageStatus ? dictionary['l_support'] : "Support"}
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default KycHeaderMenu;
