import Webcam from "react-webcam";
import {useCallback, useRef, useState} from "react";
import HoverTooltip from "../tootltips/HoverTooltip";

const KYCStream = ({saveStream, cancel}) => {

  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {mimeType: "video/webm"});
    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(({data}) => {
    if (data.size > 0) setRecordedChunks(prev => prev.concat(data));
  }, [setRecordedChunks]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {type: "video/webm"});
      saveStream({src: blob, type: "stream"});
      console.log(blob);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  return (
    <>
      <Webcam audio={false} ref={webcamRef}/>
      <>
        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-2">
          <div className="icons-list-wrap">
            <ul className="icons-list">
              <li className="icons-list-item pointer">
                <HoverTooltip title="Retake Stream">
                  <i className="fa fa-redo"/>
                </HoverTooltip>
              </li>
              {capturing ?
                <li className="icons-list-item bg-primary-gradient text-white pointer" onClick={handleStopCaptureClick}>
                  <HoverTooltip title="Pause Recording">
                    <i className="fa fa-square"/>
                  </HoverTooltip>
                </li> :
                <li className="icons-list-item pointer" onClick={handleStartCaptureClick}>
                  <HoverTooltip title="Record Image">
                    <i className="fa fa-circle"/>
                  </HoverTooltip>
                </li>
              }
              <li className="icons-list-item pointer" onClick={handleDownload}>
                <HoverTooltip title="Upload Capture">
                  <i className="fa fa-cloud-upload-alt"/>
                </HoverTooltip>
              </li>
              <li className="icons-list-item pointer" onClick={event => cancel(event, "FILE")}>
                <HoverTooltip title="Cancel">
                  <i className="fa fa-times"/>
                </HoverTooltip>
              </li>
            </ul>
          </div>
        </div>
      </>
    </>
  );
}

export default KYCStream;
