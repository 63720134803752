// web-services/api/v6/services/exchange/rate/feed/list-corridors
import Axios from "./axios.service";

export const getCorridorList = () => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/exchange/rate/feed/list-corridors`,
  });
};

export const deleteCorridor = (id) => {
  return Axios({
    method: "DELETE",
    url: `web-services/api/v6/services/exchange/rate/feed/delete-exchange-rate-feed/${id}`,
  });
};

export const suspendCorridor = (id) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/exchange/rate/feed/deactivate-transaction-currency/${id}`,
  });
};

export const unSuspendCorridor = (id) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/exchange/rate/feed/activate-transaction-currency/${id}`,
  });
};
