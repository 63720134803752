const TransactionSummary = ({ graphData }) => {
  return (
    <div className="row w-100">
      {graphData.slice(0, 5).map((item, index) => (
        <div key={index} className="col">
          <div className="card mx-2 pointer">
            <div className="card-body">
              <div className="text-center">
                <svg className="progress-ring mb-2" height="80" width="80">
                  <circle
                    className="progress-ring-circle"
                    stroke={item.fill}
                    strokeWidth="3"
                    fill="transparent"
                    r="36"
                    cx="40"
                    cy="40"
                  />
                  <text
                    x="40"
                    y="40"
                    textAnchor="middle"
                    stroke="black"
                    strokeWidth="0.1px"
                    alignmentBaseline="middle"
                  >
                    {item.value}
                  </text>
                </svg>
                <p className="fs-15 mb-0">{item.name}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TransactionSummary;
