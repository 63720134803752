import { toast } from "react-hot-toast";

import * as AuthConstants from "../constants/auth.constants";
import * as DomainBrand from "../constants/domain-brand.constants";
import ActionCreator from "../../utils/helpers/actionCreator";
import tokenHelpers from "../../utils/helpers/tokenHelpers";
import AuthService from "../../utils/services/auth.service";
import cookieHelper from "../../utils/helpers/cookieHelper";
import cacheHelper from "../../utils/helpers/cacheHelper";
import TokenHelpers from "../../utils/helpers/tokenHelpers";
import { accessScopes, configs } from "../../utils/helpers/constants";
import { DEFAULT_BASEURL, getSubDomain } from "../../utils/services/baseUrl";
import { getProfile } from "./profile.action";
import { setAuthHeader } from "../../utils/services/axios.service";

export const loginUser = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(ActionCreator(AuthConstants.LOGIN_REQUEST));
    const { data: responseData } = await AuthService.login(payload);

    if (
      responseData.status === "FAILED" &&
      responseData.errorCode === "LOGIN_OTP_REQUIRE_VERIFICATION"
    ) {
      dispatch(ActionCreator(AuthConstants.AWAITING_TOKEN));
      const authToken = responseData.data.token;

      await setAuthHeader(authToken);
      return;
    } else if (responseData.status === "FAILED") throw responseData;
    await loginSuccessAction(responseData, navigate, dispatch);
  } catch (error) {
    const message =
      "User tries to login with a different domain or user details are not valid";
    // const failedMsg =
    //   "message" in error ? error.message : "Internal server error";
    const failedMsg =
      error.message !== null ? error.message : "Internal server error";

    const response = failedMsg || message;

    dispatch(ActionCreator(AuthConstants.LOGIN_FAILURE));
    toast.error(response);
  }
};

export const validateOtp = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(ActionCreator(AuthConstants.VALIDATE_OTP_REQUEST));
    const { data: responseData } = await AuthService.otpVerification(payload);
    if (responseData.status === "FAILED") throw responseData;

    dispatch(ActionCreator(AuthConstants.VALIDATE_OTP_SUCCESS));
    await loginSuccessAction(responseData, navigate, dispatch);
  } catch (error) {
    dispatch(ActionCreator(AuthConstants.VALIDATE_OTP_FAILURE));
    toast.error(error.message);
  }
};

export const loginSuccessAction = async (responseData, navigate, dispatch) => {
  const userAccessScope = responseData.data["accessScope"];
  const userType = responseData.data["profileDetailsResponseDTO"]["userType"];
  const canViewDashboard =
    userAccessScope === accessScopes.CUSTOMER_FLOW ||
    userAccessScope === accessScopes.CUSTOMER_FLOW_OPTIONAL_KYC
      ? "VERIFIED"
      : userAccessScope === accessScopes.KYC_FLOW_UPLOAD_DOCUMENT ||
        userAccessScope === accessScopes.KYC_FLOW ||
        userAccessScope === accessScopes.AWAITING_APPROVAL_FLOW
      ? "UNVERIFIED"
      : null;
  await cookieHelper.set(
    configs.USER_VERIFIED_STATUS,
    canViewDashboard,
    configs.COOKIE_EXPIRY_PERIOD
  );
  const actionPayload = { ...responseData.data, userType };
  dispatch(ActionCreator(AuthConstants.LOGIN_SUCCESS, actionPayload));
  dispatch(ActionCreator(DomainBrand.SET_LOGIN_CONFIGS, actionPayload));
  const authToken = responseData.data.token;
  await TokenHelpers.saveToken(authToken);
  await setLoggedInUser(dispatch);
  navigate();
};

export const setLoggedInUser = async (dispatch) => {
  const tokenExists = TokenHelpers.checkIfLoggedIn();
  const token = tokenHelpers.getToken();
  if (tokenExists) {
    await setAuthHeader(token);
    await dispatch(ActionCreator(AuthConstants.SET_LOGGED_IN_USER));
    await dispatch(getProfile());
  }
};

export const getStoreOnReload = async (store) => {
  const tokenExists = tokenHelpers.checkIfLoggedIn();
  const token = tokenHelpers.getToken();

  if (tokenExists) {
    await setAuthHeader(token);
    await store.dispatch(getProfile());
  }
};

export const getExternalSite = async (token, url, store) => {
  await TokenHelpers.saveToken(token);
  await getStoreOnReload(store);
  window.location.href = url;
};

export const removeLoggedInUser = (url) => async (dispatch) => {
  const subdomain = getSubDomain();
  const logout =
    url === null ||
    url === "" ||
    typeof url === "undefined" ||
    subdomain === DEFAULT_BASEURL
      ? "/login"
      : url;
  dispatch(ActionCreator(AuthConstants.LOG_OUT));
  await AuthService.logout();
  await cookieHelper.remove(configs.KEY);
  await cacheHelper.remove(configs.KEY);
  await cacheHelper.clear();
  window.location.href = logout;
};

export const forgotPassword = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(AuthConstants.FORGOT_PASSWORD_REQUEST));
    const { data: responseData } = await AuthService.forgotPasswordService(
      payload
    );
    if (responseData.status === "FAILED") throw responseData;

    dispatch(ActionCreator(AuthConstants.FORGOT_PASSWORD_SUCCESS));
    toast.success(
      `${responseData.message}. Return to login after you reset your password`
    );
  } catch (error) {
    dispatch(ActionCreator(AuthConstants.FORGOT_PASSWORD_FAILURE));
    toast.error("Invalid Email Provided");
  }
};

export const expiredTokenCheck = (error, dispatch) => {
  if (
    error.message &&
    error.message.indexOf("Supplied token NOT found X-Auth-Token") > -1
  ) {
    dispatch(removeLoggedInUser());
    toast.error("Token expired. Please log in again.");
  }
};
