import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";

function ProductModal({
  show,
  handleClose,
  prodName,
  handleActivation,
  handleDeactivation,
  handleDeleteKyc,
  type,
  kyc,
  loading,
}) {
  return (
    <>
      {type === "ACTIVATE" && (
        <Modal show={show} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>{`Activate ${prodName}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{`would you like to activate ${prodName}?`}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleActivation}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {type === "DEACTIVATE" && (
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title>{`Deactivate ${prodName}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{`would you like to deactivate ${prodName}?`}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleDeactivation}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {type === "KYC-DELETE" && (
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title>{`Delete ${kyc}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{`would you like to delete ${kyc}?`}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleDeleteKyc}>
              Yes{" "}
              {loading ? (
                <>
                  &nbsp;
                  <i className="fa fa-spinner fa-spin" />
                </>
              ) : (
                ""
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default ProductModal;

ProductModal.defaultProps = {
  handleClose: () => {},
  handleActivation: () => {},
  handleDeactivation: () => {},
  handleDeleteKyc: () => {},
};

ProductModal.propTypes = {
  type: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  handleActivation: PropTypes.func,
  handleDeactivation: PropTypes.func,
  handleDeleteKyc: PropTypes.func,
  prodName: PropTypes.string,
  beneficiaryCode: PropTypes.string,
  kyc: PropTypes.string,
  loading: PropTypes.bool,
};
