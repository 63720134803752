import * as ProductConstants from "../constants/product.constant";
import { Loading } from "../../utils/helpers/constants";

const initialState = {
  products: [],
  loadingProducts: "",
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProductConstants.GET_PRODUCT_ACCOUNT_REQUEST:
      return { ...state, loadingProducts: Loading.FETCHING };

    case ProductConstants.GET_PRODUCT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingProducts: Loading.SUCCESS,
        products: action.payload,
      };

    case ProductConstants.GET_PRODUCT_ACCOUNT_FAILURE:
      return { ...state, loadingProducts: Loading.ERROR };

    case ProductConstants.CREATE_PRODUCT_ACCOUNT_REQUEST:
      return { ...state, loadingProducts: Loading.FETCHING };

    case ProductConstants.CREATE_PRODUCT_ACCOUNT_SUCCESS:
      return { ...state, loadingProducts: Loading.SUCCESS };

    case ProductConstants.CREATE_PRODUCT_ACCOUNT_FAILURE:
      return { ...state, loadingProducts: Loading.ERROR };

    default:
      return state;
  }
};

export default productReducer;
