import React, { useRef, useState, useEffect } from "react";
import {
  listPayCentersByType,
  linkExchangeRateFeedToCorridor,
} from "../../../../../utils/services/rate.service";
import { rateDeliveryMethod } from "../../../../../utils/helpers/objectHelpers";
import { checkIfStringHasUnderScore } from "../../../../../utils/helpers/logicHelper";
import Select from "react-select";
import { toast } from "react-hot-toast";

function AddRateCorridor({ domainBrand, countries, exchangeRateId }) {
  const formRef = useRef();
  const menuPortalTarget = document.getElementById("root");
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [pluginType, setPluginType] = useState("");
  const [recievingCountries, setReceivingCountries] = useState([]);
  const [transactionCountry, setTransactionCountry] = useState({});
  const [agentBranch, setAgentBranch] = useState("");
  const [loading, setLoading] = useState(false);
  //   const dictionary = domainBrand.domainBrand["languageDictionary"];
  //   const languageStatus = !(
  //     typeof dictionary === "undefined" || dictionary === null
  //   );

  const rateDelivery = rateDeliveryMethod();

  const fetchPaycenters = async (plugin_type) => {
    try {
      const response = await listPayCentersByType(plugin_type);
      if (response.status === 200) {
        setDeliveryMethod(response?.data?.data?.nearestPaycenters);
      }
    } catch (error) {
      return error;
    }
  };

  const mapCountriesToArray = (countryArray = []) => {
    if (countryArray.length > 0) {
      const newCountriesArray = countryArray.map((i) => {
        return i?.iso3Code;
      });

      return newCountriesArray;
    }
  };

  useEffect(() => {
    fetchPaycenters(pluginType.value);
  }, [pluginType]);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      corridorMappingRequestDTOs: [
        {
          transactionOriginatingCountry: transactionCountry.iso3Code,
          exchangeRateFeedId: exchangeRateId,
          recievingCountries: mapCountriesToArray(recievingCountries),
          deliveryMethods: [pluginType.value],
          allocateCurrencyToPayCenter: agentBranch ? agentBranch.id : "",
        },
      ],
    };

    try {
      const response = await linkExchangeRateFeedToCorridor(payload);
      if (response.status === 200) {
        toast.success("success");
        setAgentBranch("");
        setPluginType({});
        setReceivingCountries([]);
        setTransactionCountry({});
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  return (
    <div className="card-body">
      <form
        className="needs-validation"
        onSubmit={(e) => e.preventDefault()}
        ref={formRef}
      >
        <div className="form-row">
          <div className="col-md-6 col-lg-6 mb-3 mt-2">
            <label>
              Transaction Originating Country
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={countries}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              onChange={(option) => setTransactionCountry(option)}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3 mt-2">
            <label>
              Make this rate available to Agent/Branch
              <span style={{ color: "red" }}> *</span>
            </label>

            <Select
              options={deliveryMethod}
              getOptionLabel={(options) =>
                checkIfStringHasUnderScore(options["name"])
              }
              getOptionValue={(options) => options["id"]}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              onChange={(option) => setAgentBranch(option)}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Delivery Method
              <span style={{ color: "red" }}> *</span>
            </label>

            <Select
              options={rateDelivery}
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              onChange={(option) => setPluginType(option)}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              {/* {languageStatus
              ? dictionary["WO_SELECT_COUNTRY_1277685784"]
              : "Select Country (Select multiple countries)"} */}
              Receiving Country
              <span style={{ color: "red" }}> *</span>
            </label>
            <Select
              options={countries}
              isMulti
              isClearable={false}
              isSearchable={true}
              maxMenuHeight={250}
              menuPlacement="bottom"
              menuPortalTarget={menuPortalTarget}
              onChange={(option) => setReceivingCountries(option)}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3 text-end">
            <button
              className="btn btn-primary margin-label width-100 fw-bold"
              type="submit"
              onClick={handleSubmit}
            >
              {"Save"}
              {loading && (
                <>
                  &nbsp;
                  <i className="fa fa-spinner fa-spin" />
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddRateCorridor;
