import React, { useState, useMemo } from "react";
import { NavLink } from "react-router-dom";
// import CreateCustomer from "../CreateCustomer";
import UpdateDetails from "./atom/UpdateDetails";

import { BsFillPlusCircleFill } from "react-icons/bs";

import DataTable from "../../../../../../components/dataTable/dataTable";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { customerBeneficiaryTableConfig } from "../../../../../../utils/helpers/dataTableConfigs";

import { ReactComponent as Edit } from "../../../../../../assets/images/svgs/edit.svg";

import AddBeneficiaryModal from "../../../../../../components/modals/AddBeneficiaryModal";

function Body({ domainBrand, currency, countries, code, customerObject }) {
  const [addNewState, updateState] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [show, setShow] = useState(false);

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const addNewStatus = (event) => {
    event.preventDefault();
    updateState(!addNewState);
  };

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 5,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-center pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Edit</Tooltip>}
          >
            <Edit className="text-center mx-auto" />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...customerBeneficiaryTableConfig, actionColumn];

  const columns = useMemo(() => tableObject, []);

  return (
    <>
      <AddBeneficiaryModal
        show={show}
        handleClose={() => setShow(false)}
        domainBrand={domainBrand}
        country={countries}
        customerCode={code}
      />
      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">
                {languageStatus
                  ? dictionary["title_update_details"]
                  : "Update details"}
              </h1>
            </div>
            <div className="ms-auto pageheader-btn">
              <button
                className="btn btn-primary btn-icon text-white me-2 fw-bold text-uppercase"
                onClick={() => setShow(true)}
              >
                <BsFillPlusCircleFill
                  color={"#FFF"}
                  fontSize={22}
                  fill={"#FFF"}
                />
                &nbsp;
                <span className="align-middle">
                  {languageStatus
                    ? dictionary["button_add_new_receiver"]
                    : "ADD NEW BENEFICIARY"}
                </span>
              </button>

              <NavLink
                to="/"
                className="btn btn-primary btn-icon text-white me-2 fw-bold text-uppercase"
                onClick={addNewStatus}
              >
                {/* <BsFillPlusCircleFill
                color={"#FFF"}
                fontSize={22}
                fill={"#FFF"}
              />
              &nbsp; */}
                <span className="align-middle">
                  {languageStatus
                    ? dictionary["title_update_details"]
                    : "UPDATE DETAILS"}
                </span>
              </NavLink>
            </div>
          </div>

          <div className="row row-sm">
            {addNewState && (
              <div className="col-lg-12">
                <div className="card overflow-hidden">
                  <UpdateDetails
                    domainBrand={domainBrand}
                    languageStatus={languageStatus}
                    dictionary={dictionary}
                    currency={currency}
                    countries={countries}
                    customerObject={customerObject}
                  />
                </div>
              </div>
            )}

            <div className="col-lg-12">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">ALL BENEFICIARIES</h3>
                </div>
                <div className="card-body">
                  <div className="col-md-6 col-lg-6">
                    <input
                      type={"text"}
                      className="form-control border px-3"
                      placeholder="Search..."
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                    />
                  </div>
                  <div className="table-responsive mt-5">
                    <DataTable columns={columns} data={[]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
