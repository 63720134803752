import { useState } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import * as DomainBrand from "../../../store/actions/domain-brand.action";
import SphereLoader from "../../../components/loaders/SphereLoader";
import { forgotPassword } from "../../../store/actions/auth.action";
import {
  fetchAppLanguage,
  languageChangeHelper,
} from "../../../utils/helpers/logicHelper";
import { languageDropDownOptions } from "../../../utils/helpers/objectHelpers";
import { Loading } from "../../../utils/helpers/constants";
import { navBarStyles } from "../../../components/selectStyles/customStyles";
import TokenHelpers from "../../../utils/helpers/tokenHelpers";

const ForgotPassword = () => {
  let brandLangSelectorOptions;
  let selectedLanguage = fetchAppLanguage(),
    isLoggedIn = TokenHelpers.checkIfLoggedIn();
  const dispatch = useDispatch();
  const { auth, component, domainBrand } = useSelector(
    ({ authReducer, componentReducer, domainBrandReducer }) => ({
      auth: authReducer,
      component: componentReducer,
      domainBrand: domainBrandReducer,
    })
  );

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [languageField, updateLanguageField] = useState(
    selectedLanguage["commonName"]
  );
  const [email, updateEmail] = useState("");

  if (isLoggedIn) return <Redirect to={{ pathname: "/dashboard" }} />;
  const loadState = auth.loginStatus === Loading.FETCHING;
  const showSpinner = domainBrand.loading === Loading.FETCHING;
  const disableButton =
    email === "" || domainBrand.loading === Loading.FETCHING;
  brandLangSelectorOptions =
    domainBrand.loading === Loading.SUCCESS
      ? domainBrand["domainBrand"]["language"]
      : languageDropDownOptions;

  const handleLanguageChange = (language) => {
    const value = language.value;
    const languageHelper = languageChangeHelper(language);
    updateLanguageField(value);
    dispatch(
      DomainBrand.getDomainBrandIdentity(
        languageHelper["subdomain"],
        languageHelper["value"]["id"]
      )
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = { email };
    dispatch(forgotPassword(payload));
  };

  return (
    <div className="login-img">
      <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
        {showSpinner ? (
          <SphereLoader />
        ) : (
          <>
            <div className="login-language">
              <Select
                options={brandLangSelectorOptions}
                isClearable={false}
                isSearchable={false}
                name={languageField}
                value={selectedLanguage}
                onChange={(option) => handleLanguageChange(option)}
                styles={navBarStyles}
              />
            </div>
            <div className="container">
              <div className="row py-5 mt-4 align-items-center">
                <div className="col-md-5 mb-5 mb-md-0 text-center">
                  <img
                    src="https://spotbanc.com/wp-content/uploads/2021/11/h1.png"
                    alt=""
                    className="img-fluid mb-3 d-none d-md-block"
                  />
                  <h3 className="font-italic d-none d-md-block text-muted mb-0">
                    The simplest way to set up your
                  </h3>
                  <h1 className="font-italic d-none d-md-block text-muted fw-bolder mb-0">
                    Digital Banking Service
                  </h1>
                </div>
                <div className="col-md-7 col-lg-7 ml-auto">
                  <div className="container-login100">
                    <div className="wrap-login100 p-0">
                      <div className="card-body">
                        <form className="login100-form validate-form">
                          <span className="login100-form-title mb-2">
                            {languageStatus
                              ? dictionary["para_forgot_password"]
                              : "Forgot Password"}
                            <hr />
                          </span>
                          <div
                            className="wrap-input100 validate-input"
                            data-bs-validate="Valid email is required: ex@abc.xyz"
                          >
                            <input
                              className="input100"
                              type="text"
                              name="email"
                              value={email}
                              onChange={(event) =>
                                updateEmail(event.target.value)
                              }
                              placeholder={
                                languageStatus
                                  ? dictionary["msg_please_enter_email_address"]
                                  : "Email"
                              }
                            />
                            <span className="focus-input100" />
                            <span className="symbol-input100">
                              <i
                                className="fa fa-envelope"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <div className="container-login100-form-btn">
                            <button
                              disabled={disableButton}
                              onClick={handleSubmit}
                              className="login100-form-btn btn-primary"
                            >
                              {languageStatus
                                ? dictionary["b_submit"]
                                : "Submit"}
                              &nbsp;{" "}
                              {loadState ? (
                                <i className="fa fa-spinner fa-spin" />
                              ) : (
                                ""
                              )}
                            </button>
                          </div>
                          <div className="text-center pt-3">
                            <p className="text-dark mb-6">
                              <NavLink
                                to="/login"
                                className="text-primary ms-1"
                              >
                                {languageStatus
                                  ? dictionary[
                                      "para_or_return_to_the_home_page"
                                    ]
                                  : "Homepage"}
                              </NavLink>
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
