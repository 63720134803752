export const FETCH_MERCHANT_SERVICES_REQUEST = "FETCH_MERCHANT_SERVICES_REQUEST";
export const FETCH_MERCHANT_SERVICES_SUCCESS = "FETCH_MERCHANT_SERVICES_SUCCESS";
export const FETCH_MERCHANT_SERVICES_FAILURE = "FETCH_MERCHANT_SERVICES_FAILURE";

export const FETCH_MERCHANT_PRODUCTS_REQUEST = "FETCH_MERCHANT_PRODUCTS_REQUEST";
export const FETCH_MERCHANT_PRODUCTS_SUCCESS = "FETCH_MERCHANT_PRODUCTS_SUCCESS";
export const FETCH_MERCHANT_PRODUCTS_FAILURE = "FETCH_MERCHANT_PRODUCTS_FAILURE";

export const FETCH_ITEMS_IN_CART_REQUEST = "FETCH_ITEMS_IN_CART_REQUEST";
export const FETCH_ITEMS_IN_CART_SUCCESS = "FETCH_ITEMS_IN_CART_SUCCESS";
export const FETCH_ITEMS_IN_CART_FAILURE = "FETCH_ITEMS_IN_CART_FAILURE";
