import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import Select from "react-select";

import InfoTab from "./atom/InfoTab";
import { DebounceHook } from "./atom/DebounceHook";
import { getQuoteData } from "../../../../store/actions/transfer.action";
import { fetchRequiredBankFields } from "../../../../utils/services/transfer.service";
import { fetchRequiredTransDocuments } from "../../../../utils/services/compliance.service";
import { getCashPickupLocations } from "../../../../store/actions/country.action";
import {
  purposeOfTransfer,
  sourceOfFunds,
} from "../../../../utils/helpers/objectHelpers";
import {
  fetchBeneficiaryBankDetails,
  findBeneficiaryBySearchCriteria,
} from "../../../../utils/services/users.services";
import {
  fetchSupportedReceivingCurrencies,
  fetchSupportedSendingCurrencies,
} from "../../../../utils/services/country.service";
import {
  formatBeneficiariesDropdown,
  formatBeneficiaryBanksDropdown,
  formatCurrencyDropdown,
} from "../../../../utils/helpers/logicHelper";
import { transferStatusMap } from "../../../../utils/helpers/mappers";
import {
  bankPairs,
  Loading,
  transferStatus,
  userTypes,
} from "../../../../utils/helpers/constants";
import accountsService from "../../../../utils/services/accounts.service";
import {
  // transactionTypes,
  fetchOperatorsByCountry,
} from "../../../../utils/services/domainBrand.service";
import { BiUserPlus } from "react-icons/bi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Body = ({
  properties,
  userInterface,
  beneficiaries,
  helperValues,
  helperHooks,
  quoteData,
  fieldsChanged,
  country,
  senderCode,
}) => {
  const dispatch = useDispatch(),
    history = useHistory(),
    quoteObject = {};
  const {
    domainBrand,
    profile,
    formik,
    supportedCountries,
    cashPickupLocations,
    setBeneficiary,
  } = properties;
  const {
    pageStatusRender,
    pageStatus,
    initPageStatus,
    loading,
    originCountry,
  } = userInterface;
  const {
    receivingCountry,
    sendingCurrency,
    receivingCurrency,
    cashierSender,
  } = helperValues;
  const {
    updatePageStatus,
    setReceivingCountry,
    setSendingCurrency,
    setReceivingCurrency,
    setBankDetails,
    setGetMobileOperators,
  } = helperHooks;

  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const [loadingDetails, updateLoadingDetails] = useState(false);
  const [searchParamValid, updateSearchParamValid] = useState(false);
  const [inverseCalculation, setInverseCalculation] = useState(false);
  const [sameReceivingCurrency, updateSameReceivingCurrency] = useState(false);
  const [amountLimitError, setAmountLimitError] = useState(false);
  const [complianceBlock, updateComplianceBlock] = useState(false);
  const [documentFieldStatus, updateDocumentFieldStatus] = useState(false);
  const [searchLoading, updateSearchLoading] = useState(false);

  const [quoteType, setQuoteType] = useState("");
  const [searchParam, updateSearchParam] = useState("");
  const [sendingAmount, setSendingAmount] = useState("");
  const [receivingAmount, setReceivingAmount] = useState("");
  const [amountLimitMessage, setAmountLimitMessage] = useState("");
  const [beneficiary, updateBeneficiary] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [location, updateLocation] = useState("");

  const [complianceRules, updateComplianceRules] = useState({});
  const [requiredDocuments, updateRequiredDocuments] = useState([]);
  const [cashierBeneficiaries, updateCashierBeneficiaries] = useState([]);
  const [sendingCurrenciesDropdown, updateSendingCurrenciesDropdown] = useState(
    []
  );
  const [receivingCurrenciesDropdown, updateReceivingCurrenciesDropdown] =
    useState([]);
  const [receivingCountriesList, setReceivingCountriesList] = useState();
  const [destinationCurrency, setDestinationCurrency] = useState();
  const [outgoingCurrency, setOutgoingCurrency] = useState();
  // const [toggleAddBeneficiary, setToggleAddBeneficiary] = useState(false);
  const [mobileOperator, setOperator] = useState([{}]);
  const [beneficiaryBankDropdown, updateBeneficiaryBankDropdown] = useState([]);
  const debounceSendingAmount = DebounceHook(sendingAmount, 1000);
  const debounceReceivingAmount = DebounceHook(receivingAmount, 1000);

  const userType = profile.userData["customerType"] || profile?.userType;

  const purposeOfTransferDropdown = purposeOfTransfer(
    languageStatus,
    dictionary,
    userType
  );

  const sourceOfFundsDropdown = sourceOfFunds(
    languageStatus,
    dictionary,
    profile.userData.customerType
  );

  const FetchMobileMoneyRequestDTO = {
    destinationCountry: receivingCountriesList?.iso3Code,
    sendingCurrency: outgoingCurrency?.value,
    receivingCurrency: destinationCurrency?.value,
    receivingAmount: receivingAmount,
  };

  const getOperatorsByCountry = async (payload) => {
    if (
      payload?.destinationCountry !== "" &&
      payload?.sendingCurrency !== "" &&
      isNaN(payload?.receivingAmount) === false &&
      payload?.receivingAmount > 0
    ) {
      const res = await fetchOperatorsByCountry(payload);
      setOperator(res?.data?.data?.fetchMobileMoneyResponseDTOs);
    }
  };

  const onSubmitClick = formik.submitCount > 0;
  const hideRate =
    quoteData["fxRate"] === "1.00" &&
    quoteData["sendingCurrency"] === quoteData["receivingCurrency"];
  const amountFieldReadOnlyState = !(
    Object.entries(sendingCurrency).length > 0 &&
    Object.entries(receivingCurrency).length > 0
  );
  const disableSubmit =
    (!sendingAmount && !receivingAmount) || complianceBlock || amountLimitError;
  const addNewBeneficiary = () =>
    history.push({
      pathname: "/beneficiaries",
      state: { countries: country, senderCode: senderCode },
    });

  useEffect(() => {
    // if (pageStatus !== transferStatus.TRANSFER_FUNDS) setReceivingCountriesList(true);
    if (profile.userData["enableVamProfile"])
      formik.setFieldValue("sourceOfFunds", sourceOfFundsDropdown[0]);
  }, []);

  useEffect(async () => {
    if (setBeneficiary)
      getRecipientBankDetails(setBeneficiary).catch((err) =>
        console.trace(err)
      );
  }, [setBeneficiary]);

  useEffect(() => {
    getOperatorsByCountry(FetchMobileMoneyRequestDTO);
  }, [FetchMobileMoneyRequestDTO]);

  useEffect(() => {
    formik.setFieldValue("type", transferStatusMap[pageStatus].transactionType);
  }, [pageStatus]);

  useEffect(() => {
    setSendingAmount(quoteData["sendingAmount"]);
    setReceivingAmount(quoteData["receivingAmount"]);
    formik.setFieldValue("sendingAmount", quoteData["sendingAmount"]);
    formik.setFieldValue("receivingAmount", quoteData["receivingAmount"]);
  }, [quoteData["sendingAmount"], quoteData["receivingAmount"]]);

  useEffect(() => {
    quoteObject.conversion = true;
    quoteObject.inverseCalculation = inverseCalculation;
    quoteObject.chargeCategory = transferStatusMap[pageStatus].chargeCategory;
    quoteObject.transactionType = transferStatusMap[pageStatus].transactionType;
    if (debounceSendingAmount || debounceReceivingAmount) {
      if (pageStatus === transferStatus.WALLET) {
        quoteObject.deriveAmount = sendingAmount;
      } else {
        quoteObject.tradeOriginatingCountry = originCountry;
        quoteObject.destinationCountry = receivingCountry["value"];
        quoteObject.deriveAmountCurrency = receivingCurrency["value"];
        quoteObject.principalAmountCurrency = sendingCurrency["value"];
        if (quoteType === "sendingAmount")
          quoteObject.principalAmount = sendingAmount;
        if (quoteType === "receivingAmount")
          quoteObject.deriveAmount = receivingAmount;
      }
      dispatch(getQuoteData(quoteObject));
      if (pageStatus !== transferStatus.WALLET)
        getRequiredDocumentsForTransaction(sendingAmount).catch((err) =>
          console.trace(err)
        );
    }
  }, [debounceSendingAmount, debounceReceivingAmount]);

  const disableCopyPaste = (event) => event.preventDefault();

  const resetPrefills = () => {
    setSendingCurrency("");
    setReceivingCurrency("");
    setBankDetails("");
    setSendingAmount("");
    setReceivingAmount("");
    updateBeneficiary("");
    setAccountNumber("");
    updateBeneficiaryBankDropdown([]);
    quoteData = {};
    formik.setFieldValue("bankFields", []);
  };

  const getSupportedSendingCurrencies = async (option) => {
    await resetPrefills();
    updateLoadingDetails(true);
    const { data: responseData } = await fetchSupportedSendingCurrencies(
      originCountry,
      option["iso3Code"],
      transferStatusMap[pageStatus].transactionType
    );
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch sending currencies, try again`);
    else {
      const formattedCurrencies = formatCurrencyDropdown(
        responseData.data["currencies"]
      );
      updateSendingCurrenciesDropdown(formattedCurrencies);
    }
    updateLoadingDetails(false);
  };

  const getSupportedReceivingCurrencies = async (option) => {
    updateLoadingDetails(true);
    updateSameReceivingCurrency(false);
    const sending = option["value"];
    const { data: responseData } = await fetchSupportedReceivingCurrencies(
      originCountry,
      receivingCountry.value,
      transferStatusMap[pageStatus].transactionType,
      sending
    );
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch sending currencies, try again`);
    else {
      const formattedCurrencies = formatCurrencyDropdown(
        responseData.data["currencies"]
      );
      if (
        formattedCurrencies.length === 1 &&
        sending === formattedCurrencies[0].value
      ) {
        updateSameReceivingCurrency(true);
        setReceivingCurrency(formattedCurrencies[0]);
      } else updateReceivingCurrenciesDropdown(formattedCurrencies);
    }
    updateLoadingDetails(false);
  };

  const getRequiredDocumentsForTransaction = async (sendingAmount) => {
    const payload = {
      direction: "PAYIN",
      senderCode: profile.customerCode,
      receivingCountryIso3Code: receivingCountry.value,
      sendingCurrencyCode: sendingCurrency["value"],
      devliveryMethod: transferStatusMap[pageStatus].transactionType,
      amountSend: parseFloat(sendingAmount),
    };
    const { data: responseData } = await fetchRequiredTransDocuments(payload);
    if (responseData.status !== Loading.SUCCESS) {
      // toast.error(`Could not fetch required documents, try again`);
    } else {
      updateComplianceRules(responseData.data);
      const blockRestriction = responseData.data["detailedRule"].some(
        (e) => e["restrictionLevel"] === "BLOCK"
      );
      if (blockRestriction) updateComplianceBlock(true);
      else updateComplianceBlock(false);
      if (
        responseData.data["amountLimit"] !== null &&
        responseData.data["amountLimit"] !== ""
      ) {
        setAmountLimitError(true);
        const amountLimitObject = responseData.data["detailedRule"].find(
          (forEach) => forEach.document === "amountLimit"
        );
        setAmountLimitMessage(amountLimitObject.message);
      }
      const newRequiredDocuments = responseData.data["detailedRule"].filter(
        (forEach) => forEach["thresholdType"] === "THRESHOLD_IDENTIFICATION"
      );
      if (newRequiredDocuments.length > 0) {
        updateDocumentFieldStatus(true);
        updateRequiredDocuments(newRequiredDocuments);
      } else {
        updateDocumentFieldStatus(false);
        updateRequiredDocuments(newRequiredDocuments);
      }
    }
  };

  const searchForSenderBeneficiary = async () => {
    updateSearchLoading(true);
    updateSearchParamValid(false);
    const searchParams = `?customer-code=${cashierSender["code"]}&search-criteria=${searchParam}`;
    const { data: responseData } = await findBeneficiaryBySearchCriteria(
      searchParams
    );

    if (responseData.data["receivers"].length < 1) {
      toast.error(`No beneficiary exists for ${searchParam}`);
    } else {
      updateSearchParamValid(true);
      const formattedBeneficiaries = formatBeneficiariesDropdown(
        responseData.data["receivers"]
      );
      updateCashierBeneficiaries(formattedBeneficiaries);
    }
    updateSearchLoading(false);
  };

  const findAccount = async () => {
    updateLoadingDetails(true);
    updatePageStatus(transferStatus.WALLET);
    const payload = { findbyAccountNumber: accountNumber };

    const { data: responseData } = await accountsService.accountLookUp(payload);
    if (responseData.status === Loading.FAILED) {
      toast.error(responseData.message);
      updatePageStatus(initPageStatus);
    } else {
      const country = {
        value: responseData.data.countryIso3,
        label: responseData.data.countryIso3,
      };
      const currency = {
        value: responseData.data["currencyCode"],
        label: responseData.data["currencyCode"],
      };
      const accountOwner = responseData.data;
      accountOwner.beneficiaryCode = responseData.data.code;
      setReceivingCountry(country);
      setReceivingCurrency(currency);
      formik.setFieldValue("beneficiary", accountOwner);
      formik.setFieldValue("accountNumber", accountNumber);
    }
    updateLoadingDetails(false);
  };

  const getRecipientBankDetails = async (beneficiaryInfo) => {
    // await getOperatorsByCountry(FetchMobileMoneyRequestDTO);
    const beneficiaryNumber =
      beneficiaryInfo.phoneNumber !== null ? beneficiaryInfo.phoneNumber : "";

    formik.setFieldValue("phoneNumber", beneficiaryNumber);
    setAccountNumber("");
    updateBeneficiary(beneficiaryInfo);
    updateLoadingDetails(true);
    updatePageStatus(initPageStatus);
    if (profile.userData["enablePickupLocation"])
      dispatch(getCashPickupLocations(receivingCountriesList?.iso3Code));
    const { data: responseData } = await fetchBeneficiaryBankDetails(
      beneficiaryInfo["beneficiaryCode"]
    );
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch beneficiary bank information, try again`);
    else {
      formik.setFieldValue("beneficiary", beneficiaryInfo);
      const formattedBeneficiaries = formatBeneficiaryBanksDropdown(
        responseData.data["bankDetails"]
      );
      updateBeneficiaryBankDropdown(formattedBeneficiaries);
    }
    updateLoadingDetails(false);
  };

  const getRequiredBankFields = async (country, currency, selectedBank) => {
    updateLoadingDetails(true);
    const path =
      currency === "" || currency === null || typeof currency === "undefined"
        ? country
        : `${country}?delivery_method=${transferStatusMap[pageStatus].transactionType}&currency_ode=${currency}`;
    const { data: responseData } = await fetchRequiredBankFields(path);

    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch required bank fields, try again`);
    else setBankFields(selectedBank, responseData.data);
    updateLoadingDetails(false);
  };

  const setBankFields = (option, requiredFields) => {
    let formikMap;
    const bankFields = { bankFields: requiredFields["bankFields"] };
    const requiredBankFields = {
      requiredBankFields: requiredFields["requiredBankFields"],
    };

    const validOptions = Object.keys(requiredFields["requiredBankFields"] || {})
      .filter((value) => requiredFields["requiredBankFields"][value] === true)
      .map((value) => {
        let newValue;
        if (value.includes("Required")) newValue = value.split("Required")[0];
        else if (value.includes("iban")) newValue = "ibanCode";
        else if (value.includes("required"))
          newValue = value.split("required")[0];
        return newValue;
      });

    validOptions.forEach(
      (element) =>
        (formikMap = {
          ...formikMap,
          ...{ [bankPairs[element]]: option[element] },
        })
    );
    formik.setValues({
      ...formik.values,
      ...formikMap,
      ...bankFields,
      ...requiredBankFields,
    });
  };

  const tabProps = {
    formik,
    pageStatus,
    helperValues,
    loadingDetails,
    quoteData,
    hideRate,
    disableSubmit,
    loading,
  };
  const docxProps = {
    documentFieldStatus,
    requiredDocuments,
    profile,
    domainBrand,
    supportedCountries,
    sendingAmount,
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus
                ? dictionary["WO_TRANSFER_FUND_-1694451190"]
                : "Transfer Funds"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary[pageStatusRender]
                    : "Transfer Funds"}
                </h3>
              </div>
              <div className="card-body">
                <div className="needs-validation">
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["l_destination_country"]
                          : "Receiving Country"}
                      </label>
                      <Select
                        options={supportedCountries}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="receivingCountry"
                        onChange={(option) => {
                          setReceivingCountry(option);
                          setReceivingCountriesList(option);
                          getSupportedSendingCurrencies(option).catch((err) =>
                            console.trace(err)
                          );
                        }}
                      />
                    </div>
                  </div>

                  {pageStatus === transferStatus.CASH_PICKUP ||
                  pageStatus === transferStatus.MOBILE_MONEY ||
                  !domainBrand.serviceControl.wallet ||
                  !profile.userData["enableVamProfile"] ? (
                    ""
                  ) : (
                    <>
                      <hr
                        className="hr-text"
                        data-content="Or find wallet account"
                      />
                      <div className="form-row">
                        <div className="col-xl-9 col-lg-8 col-md-8 mb-1">
                          <input
                            type="text"
                            className={`form-control ${
                              searchParamValid ? "is-valid" : ""
                            }`}
                            value={accountNumber}
                            placeholder="Enter Account Number"
                            onChange={(event) =>
                              setAccountNumber(event.target.value)
                            }
                          />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 mb-3">
                          <button
                            onClick={findAccount}
                            className="btn btn-primary"
                            type="button"
                            disabled={!accountNumber}
                            style={{ minWidth: "100%" }}
                          >
                            {languageStatus
                              ? dictionary["FIND_ACCOUN_31978564"]
                              : "Find Account"}
                            {searchLoading ? (
                              <>
                                &nbsp; <i className="fa fa-spinner fa-spin" />
                              </>
                            ) : (
                              ""
                            )}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {Object.entries(receivingCountry).length > 0 ? (
                    <>
                      {pageStatus === transferStatus.WALLET ? (
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label>
                              {languageStatus
                                ? dictionary["l_sending_amount"]
                                : "Sending Amount"}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                searchParamValid ? "is-valid" : ""
                              }`}
                              value={sendingAmount}
                              required
                              onChange={(event) => {
                                setQuoteType("sendingAmount");
                                setInverseCalculation(true);
                                setSendingAmount(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="form-row">
                          <div className="col-md-6 mb-3">
                            <label>
                              {languageStatus
                                ? dictionary["l_sending_currency"]
                                : "Sending Currency"}
                            </label>
                            <Select
                              options={sendingCurrenciesDropdown}
                              isClearable={false}
                              isSearchable={true}
                              maxMenuHeight={250}
                              menuPlacement="bottom"
                              menuPortalTarget={menuPortalTarget}
                              name="sendingCurrency"
                              onChange={(option) => {
                                setSendingCurrency(option);
                                setOutgoingCurrency(option);
                                getSupportedReceivingCurrencies(option).catch(
                                  (err) => console.trace(err)
                                );
                              }}
                            />
                          </div>
                          {Object.entries(sendingCurrency).length === 0 ||
                          Object.entries(receivingCurrency).length === 0 ? (
                            ""
                          ) : (
                            <div className="col-md-6 mb-3">
                              <label>
                                {languageStatus
                                  ? dictionary["l_sending_amount"]
                                  : "Sending Amount"}
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  searchParamValid ? "is-valid" : ""
                                }`}
                                value={sendingAmount}
                                disabled={amountFieldReadOnlyState}
                                required
                                onChange={(event) => {
                                  setQuoteType("sendingAmount");
                                  setAmountLimitError(false);
                                  setInverseCalculation(false);
                                  setSendingAmount(event.target.value);
                                }}
                              />
                              {amountLimitError ? (
                                <div className="input-error">
                                  {amountLimitMessage}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      {Object.entries(sendingCurrency).length === 0 ||
                      sameReceivingCurrency ||
                      pageStatus === transferStatus.WALLET ? (
                        ""
                      ) : (
                        <div className="form-row">
                          <div className="col-md-6 mb-3">
                            <label>
                              {languageStatus
                                ? dictionary["l_receiving_currency"]
                                : "Receiving Currency"}
                            </label>
                            <Select
                              options={receivingCurrenciesDropdown}
                              isClearable={false}
                              isSearchable={true}
                              maxMenuHeight={250}
                              menuPlacement="bottom"
                              menuPortalTarget={menuPortalTarget}
                              name="receivingCurrency"
                              onChange={(option) => {
                                setReceivingCurrency(option);
                                setDestinationCurrency(option);
                              }}
                            />
                          </div>
                          {Object.entries(receivingCurrency).length === 0 ? (
                            ""
                          ) : (
                            <div className="col-md-6 mb-3">
                              <label>
                                {languageStatus
                                  ? dictionary["para_receiving_amount"]
                                  : "Receiving Amount"}
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  searchParamValid ? "is-valid" : ""
                                }`}
                                value={receivingAmount}
                                disabled={amountFieldReadOnlyState}
                                required
                                onChange={async (event) => {
                                  setQuoteType("receivingAmount");
                                  setInverseCalculation(true);
                                  setReceivingAmount(event.target.value);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {sendingAmount ? (
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">Receiver Details</h3>
                </div>
                <div className="card-body">
                  <div className="needs-validation">
                    {/* FIX HERE */}
                    {profile.userType === userTypes.CASHIER ? (
                      <div className="form-row">
                        <div className="col-xl-8 col-lg-8 col-md-8 mb-3">
                          <label>
                            {languageStatus
                              ? dictionary["para_find_beneficiary"]
                              : "Find beneficiary"}
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              searchParamValid ? "is-valid" : ""
                            }`}
                            value={searchParam}
                            onChange={(event) => {
                              updateSearchParam(event.target.value);
                            }}
                            required
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 mt-1 mb-3 d-flex flex-row">
                          <>
                            <button
                              onClick={searchForSenderBeneficiary}
                              className="btn btn-primary mt-5"
                              type="button"
                            >
                              {languageStatus
                                ? dictionary["button_search"]
                                : "Search"}
                              {searchLoading ? (
                                <>
                                  &nbsp; <i className="fa fa-spinner fa-spin" />
                                </>
                              ) : (
                                ""
                              )}
                            </button>
                            <button
                              onClick={addNewBeneficiary}
                              className="btn btn-primary mt-5 ms-2"
                              type="button"
                              style={{ maxWidth: "100%" }}
                            >
                              <OverlayTrigger
                                key={"2"}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-${``}`}>
                                    {languageStatus
                                      ? dictionary[
                                          "WO_ADD_BENEFICIAR_-2140027024"
                                        ]
                                      : "Add Beneficiary"}
                                  </Tooltip>
                                }
                              >
                                <BiUserPlus size={24} />
                              </OverlayTrigger>
                            </button>
                          </>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* MIGHT NEED TO ADD TO TOP */}
                    {profile.userType === userTypes.CUSTOMER ? (
                      <>
                        {pageStatus === transferStatus.WALLET ? (
                          ""
                        ) : (
                          <div className="form-row">
                            <div className="col-xl-9 col-lg-8 col-md-8">
                              <label>
                                {languageStatus
                                  ? dictionary[
                                      "WO_WHOM_WILL_YOU_LIKE_T_-26099070"
                                    ]
                                  : "Select Beneficiary"}
                              </label>
                              <Select
                                options={beneficiaries}
                                isClearable={false}
                                isSearchable={true}
                                maxMenuHeight={250}
                                menuPlacement="top"
                                menuPortalTarget={menuPortalTarget}
                                value={beneficiary}
                                onChange={(option) => {
                                  getRecipientBankDetails(option).catch((err) =>
                                    console.trace(err)
                                  );
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4 mt-1">
                              <button
                                onClick={addNewBeneficiary}
                                className="btn btn-primary mt-5"
                                type="button"
                                style={{ minWidth: "100%" }}
                              >
                                {languageStatus
                                  ? dictionary["WO_ADD_BENEFICIAR_-2140027024"]
                                  : "Add Beneficiary"}
                                {searchLoading ? (
                                  <>
                                    &nbsp;{" "}
                                    <i className="fa fa-spinner fa-spin" />
                                  </>
                                ) : (
                                  ""
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    ) : searchParamValid &&
                      profile.userType === userTypes.CASHIER ? (
                      <div className="form-row">
                        <div className="col-md-12 mb-3">
                          <label>
                            {languageStatus
                              ? dictionary["WO_WHOM_WILL_YOU_LIKE_T_-26099070"]
                              : "Select Beneficiary"}
                          </label>
                          {/* HERE  */}
                          <Select
                            options={cashierBeneficiaries}
                            isClearable={false}
                            isSearchable={true}
                            maxMenuHeight={250}
                            menuPlacement="top"
                            menuPortalTarget={menuPortalTarget}
                            value={beneficiary}
                            onChange={(option) =>
                              getRecipientBankDetails(option).catch((err) =>
                                console.trace(err)
                              )
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* CASH PICK UP LOCATION */}

                    {pageStatus === transferStatus.CASH_PICKUP ? (
                      profile.userData["enablePickupLocation"] &&
                      cashPickupLocations?.length > 0 ? (
                        <div className="form-row">
                          <div className="col-xl-12 col-lg-12 col-md-12 mb-3 mt-3">
                            <label>Select Cash Pickup Location</label>
                            <Select
                              options={cashPickupLocations}
                              noOptionsMessage={() =>
                                "No cash pickup location added"
                              }
                              isClearable={false}
                              isSearchable={true}
                              maxMenuHeight={250}
                              menuPlacement="bottom"
                              menuPortalTarget={menuPortalTarget}
                              value={location}
                              name="location"
                              onChange={(option) => {
                                updateLocation(option);
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {beneficiary || pageStatus === transferStatus.WALLET ? (
                      <>
                        {pageStatus === transferStatus.TRANSFER_FUNDS ? (
                          <div className="form-row">
                            <div className="col-md-12 mt-2 mb-3">
                              <label>
                                {languageStatus
                                  ? dictionary["BANK_DETAIL_2044985812"]
                                  : "Bank Details"}
                              </label>
                              <Select
                                options={beneficiaryBankDropdown}
                                noOptionsMessage={() => "No bank added"}
                                isClearable={false}
                                isSearchable={true}
                                maxMenuHeight={250}
                                menuPlacement="top"
                                menuPortalTarget={menuPortalTarget}
                                name="bankDetails"
                                onChange={(option) => {
                                  setBankDetails(option);
                                  getRequiredBankFields(
                                    receivingCountry.value,
                                    receivingCurrency.value,
                                    option
                                  ).catch((err) => console.trace(err));
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {pageStatus === transferStatus.TRANSFER_FUNDS ||
                        pageStatus === transferStatus.WALLET ? (
                          <div className="form-row">
                            <div className="col-md-12 mb-3">
                              <label>
                                {languageStatus
                                  ? dictionary["payment_reference"]
                                  : "Payment Reference"}
                              </label>
                              <input
                                type="text"
                                name="transactionReference"
                                className={`form-control ${
                                  formik.errors["transactionReference"] &&
                                  onSubmitClick
                                    ? "is-invalid"
                                    : ""
                                }`}
                                required
                                onChange={formik.handleChange}
                              />
                              {formik.errors["transactionReference"] ? (
                                <div className="invalid-feedback">
                                  Please provide payment reference.
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : pageStatus === transferStatus.CASH_PICKUP &&
                          complianceRules["securityQuestionRequired"] ? (
                          <>
                            <div className="form-row">
                              <div className="col-md-12 mb-3">
                                <label>
                                  {languageStatus
                                    ? dictionary["para_security_question"]
                                    : "Security Question"}
                                </label>
                                <input
                                  type="text"
                                  name="securityQuestion"
                                  className={`form-control ${
                                    formik.errors["securityQuestion"] &&
                                    onSubmitClick
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  required
                                  onChange={formik.handleChange}
                                />
                                {formik.errors["securityQuestion"] ? (
                                  <div className="invalid-feedback">
                                    Please provide security question.
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-3">
                                <label>
                                  {languageStatus
                                    ? dictionary["para_security_answer"]
                                    : "Security Answer"}
                                </label>
                                <input
                                  type="text"
                                  name="securityAnswer"
                                  className={`form-control ${
                                    formik.errors["securityAnswer"] &&
                                    onSubmitClick
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  required
                                  onChange={formik.handleChange}
                                />
                                {formik.errors["securityAnswer"] ? (
                                  <div className="invalid-feedback">
                                    Please provide security answer.
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        ) : pageStatus === transferStatus.MOBILE_MONEY ? (
                          <>
                            <div className="form-row">
                              <div className="col-md-12 mb-3 mt-3">
                                <label>
                                  {languageStatus
                                    ? dictionary["para_operator"]
                                    : "Operator"}
                                </label>
                                <Select
                                  options={mobileOperator}
                                  noOptionsMessage={() => "No Operator added"}
                                  getOptionLabel={(options) => options["value"]}
                                  getOptionValue={(options) => options["key"]}
                                  isClearable={false}
                                  isSearchable={true}
                                  maxMenuHeight={250}
                                  menuPlacement="top"
                                  menuPortalTarget={menuPortalTarget}
                                  name="operator"
                                  onChange={(option) => {
                                    setGetMobileOperators(option);
                                  }}
                                />
                              </div>

                              <div className="col-md-12 mb-3 mt-3">
                                <label>
                                  {languageStatus
                                    ? dictionary["para_phone_number"]
                                    : "Phone Number"}
                                </label>
                                <input
                                  type="text"
                                  name="phoneNumber"
                                  value={formik.values.phoneNumber}
                                  className={`form-control ${
                                    formik.errors["phoneNumber"] &&
                                    onSubmitClick
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  required
                                  onChange={formik.handleChange}
                                />
                                {formik.errors["phoneNumber"] ? (
                                  <div className="invalid-feedback">
                                    Please provide phone number.
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-3">
                                <label>
                                  {languageStatus
                                    ? dictionary["para_confirm_phone_number"]
                                    : "Confirm Phone"}
                                </label>
                                <input
                                  type="text"
                                  name="confirmPhoneNumber"
                                  onPaste={(e) => disableCopyPaste(e)}
                                  className={`form-control ${
                                    formik.errors["confirmPhoneNumber"] &&
                                    onSubmitClick
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  required
                                  onChange={formik.handleChange}
                                />
                                {formik.errors["confirmPhoneNumber"] ? (
                                  <div className="invalid-feedback">
                                    Please confirm phone number.
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="form-row">
                          {userType === "CASHIER" && (
                            <div className="col-md-12 mb-3">
                              <label>
                                {languageStatus
                                  ? dictionary["WO_SOURCE_OF_FUND_-1732861383"]
                                  : "Source of Funds"}
                              </label>
                              <Select
                                options={sourceOfFundsDropdown}
                                isClearable={false}
                                isSearchable={true}
                                maxMenuHeight={250}
                                menuPlacement="bottom"
                                menuPortalTarget={menuPortalTarget}
                                name="sourceOfFunds"
                                onChange={(option) =>
                                  formik.setFieldValue("sourceOfFunds", option)
                                }
                              />
                            </div>
                          )}
                          <div className="col-md-12 mb-3 mt-3">
                            <label>
                              {languageStatus
                                ? dictionary["l_purpose_transfer"]
                                : "Purpose of Transfer"}
                            </label>
                            <Select
                              options={purposeOfTransferDropdown}
                              isClearable={false}
                              isSearchable={true}
                              maxMenuHeight={250}
                              menuPlacement="top"
                              menuPortalTarget={menuPortalTarget}
                              name="remittancePurpose"
                              onChange={(option) =>
                                formik.setFieldValue(
                                  "remittancePurpose",
                                  option
                                )
                              }
                            />
                          </div>
                        </div>
                        {profile.userData["enableVamProfile"] ? (
                          ""
                        ) : (
                          <div className="form-row">
                            <div className="col-md-12 mb-3">
                              <label>
                                {languageStatus
                                  ? dictionary["WO_SOURCE_OF_FUND_-1732861383"]
                                  : "Source of Funds"}
                              </label>
                              <Select
                                options={sourceOfFundsDropdown}
                                isClearable={false}
                                isSearchable={true}
                                maxMenuHeight={250}
                                menuPlacement="bottom"
                                menuPortalTarget={menuPortalTarget}
                                name="sourceOfFunds"
                                onChange={(option) =>
                                  formik.setFieldValue("sourceOfFunds", option)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <InfoTab
            getRequiredDocumentsForTransaction={
              getRequiredDocumentsForTransaction
            }
            properties={tabProps}
            docxProps={docxProps}
            fieldsChanged={fieldsChanged}
            userType={userType}
            onSubmitClick={onSubmitClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Body;
