import ActionCreator from "../../utils/helpers/actionCreator";
import * as CustomerProductsList from "../constants/customer-products.constants";
import { expiredTokenCheck } from "./auth.action";
import { toast } from "react-hot-toast";

import {
  fetchCustomerProducts,
  fetchSingleCustomerProduct,
} from "../../utils/services/customer-product.service";

export const getCustomerProductList = () => async (dispatch) => {
  try {
    dispatch(
      ActionCreator(CustomerProductsList.FETCH_CUSTOMER_PRODUCTS_LIST_REQUEST)
    );

    const { data: responseData } = await fetchCustomerProducts();

    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        CustomerProductsList.FETCH_CUSTOMER_PRODUCTS_LIST_SUCCESS,
        responseData.data
      )
    );
    toast.success("products fetched successfully");
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(
      ActionCreator(CustomerProductsList.FETCH_CUSTOMER_PRODUCTS_LIST_FAILURE)
    );
  }
};

export const getManagerApproval = (accountproductId) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerProductsList.GET_MANAGER_APPROVAL_REQUEST));

    const { data: responseData } = await fetchSingleCustomerProduct(
      accountproductId
    );

    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        CustomerProductsList.GET_MANAGER_APPROVAL_SUCCESS,
        responseData.data
      )
    );
    toast.success("Request sent successfully");
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    toast.error(error?.message);
    dispatch(ActionCreator(CustomerProductsList.GET_MANAGER_APPROVAL_FAILURE));
  }
};
