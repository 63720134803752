import { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import moment from "moment";

import trendUp from "../../../../../assets/images/svgs/trending-up.svg";
import DashboardMenu from "../../../../../components/slickSlider/DashboardMenu";
import TransactionSummary from "./TransactionSummary";
import { formatStringCurrency } from "../../../../../utils/helpers/logicHelper";
import { currencyMap } from "../../../../../utils/helpers/mappers";
import { StatementMode } from "../../../../../utils/helpers/constants";

const CustomerDashboard = ({
  profile,
  domainBrand,
  recentHistory,
  statement,
  graphData,
  accounts,
}) => {
  const history = useHistory();
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [quote, updateQuote] = useState(statement[0]);
  const [canShowMore, setCanShowMore] = useState(false);
  const [showMoreState, setShowMoreState] = useState(false);
  const goToAddMoney = () => history.push("/fund-wallet");
  const goToWallets = () => history.push("/balances");
  const goToTransferFunds = () => history.push("/transfer");
  const showMore = (event) => {
    event.preventDefault();
    setShowMoreState(!showMoreState);
  };
  const goToStatement = (item) =>
    history.push({
      pathname: "/statement",
      state: { data: item, text: StatementMode.BALANCE },
    });

  useEffect(() => {
    const interval = setInterval(() => {
      const statementIndex = Math.floor(Math.random() * statement.length);
      updateQuote(statement[statementIndex]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const ifWallet = domainBrand.serviceControl.wallet;

  return (
    <>
      <div className="row">
        {ifWallet ? (
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 height-100">
            <div className="card overflow-hidden" style={{ height: "40%" }}>
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  <span className="">
                    {languageStatus
                      ? dictionary["WO_ACCOUNT_BALANCE_960871353"]
                      : "Account Balances"}
                  </span>
                </h3>
              </div>
              <div className="card-body">
                <div className="card-order">
                  <div className="visitor-list mt-3">
                    {accounts.length === 0 ? (
                      <div className="border-bottom pointer">
                        <h6 className="text-default fw-semibold mt-3">
                          No wallet account created.
                        </h6>
                      </div>
                    ) : (
                      ""
                    )}
                    {accounts.slice(0, 3).map((item, index) => (
                      <div
                        key={index}
                        onClick={() => goToStatement(item)}
                        className="border-bottom pointer"
                      >
                        <h6 className="text-default fw-semibold mt-3">
                          <span className="text-primary">
                            {item["walletAccountNumber"] ?? "No Account Name"}
                          </span>
                          <span
                            className="fw-bold float-end"
                            style={{ fontSize: "12px" }}
                          >
                            {`${formatStringCurrency(
                              item["walletAccountBalance"]
                            )} ${item["walletAccountCurrency"]}`}
                          </span>
                        </h6>
                      </div>
                    ))}
                  </div>
                  <div className="mt-5 mb-4">
                    <button className="btn btn-primary" onClick={goToAddMoney}>
                      {languageStatus
                        ? dictionary["l_fund_wallet"]
                        : "Add Money"}
                    </button>
                  </div>
                  <div />
                </div>
              </div>
            </div>
            <div className="card overflow-hidden" style={{ height: "30%" }}>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h6 className="fw-600 mb-4 fs-18 text-black">
                      {languageStatus
                        ? dictionary["WO_MONEY_RECEIVE_-2032688864"]
                        : "Money in"}
                    </h6>
                    {statement.length === 0 ? (
                      <span className="mb-2 fw-bolder number-font">
                        {" "}
                        No Transaction made
                      </span>
                    ) : (
                      <h3 className="mb-2 fw-bolder fs-25 text-primary number-font">
                        {formatStringCurrency(quote?.moneyIn) || 0}
                      </h3>
                    )}
                    <p className="text-muted mb-4">
                      <span className="text-black">Last 3 months</span>
                    </p>
                    <div
                      onClick={goToWallets}
                      className="text-primary pointer fw-bold mb-4 text-decoration-underline"
                    >
                      Account balances
                    </div>
                    <div />
                  </div>
                  <div className="col col-auto">
                    <div className="counter-icon bg-primary-gradient box-shadow-primary brround ms-auto home-card">
                      <span className="text-primary fs-35 text-center fw-bold vertical-middle">
                        {/* {currencyMap[quote?.currency ?? "OTHER"]?.symbol} */}
                        {currencyMap[quote?.currency]
                          ? currencyMap[quote?.currency]?.symbol
                          : currencyMap["OTHER"]?.symbol}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card overflow-hidden" style={{ height: "30%" }}>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h6 className="fw-600 mb-4 fs-18 text-black">
                      {languageStatus
                        ? dictionary["WO_MONEY_SEN_-847440872"]
                        : "Money out"}
                    </h6>
                    {statement.length === 0 ? (
                      <span className="mb-2 fw-bolder number-font">
                        {" "}
                        No Transaction made
                      </span>
                    ) : (
                      <h3 className="mb-2 fw-bolder fs-25 text-primary number-font">
                        {formatStringCurrency(quote?.moneyOut) || 0}
                      </h3>
                    )}
                    <p className="text-muted">
                      <span className="text-black">Last 3 months</span>
                    </p>
                    <div
                      onClick={goToTransferFunds}
                      className="text-primary pointer mb-4 fw-bold text-decoration-underline"
                    >
                      {languageStatus
                        ? dictionary["WO_TRANSFER_FUND_-1694451190"]
                        : "Transfer Funds"}
                    </div>
                  </div>
                  <div className="col col-auto">
                    <div className="counter-icon bg-primary-gradient box-shadow-primary brround ms-auto home-card">
                      <span className="text-primary fs-35 text-center fw-bold vertical-middle">
                        {/* {currencyMap[quote?.currency ?? "OTHER"]?.symbol} */}
                        {currencyMap[quote?.currency]
                          ? currencyMap[quote?.currency]?.symbol
                          : currencyMap["OTHER"]?.symbol}
                      </span>
                    </div>
                  </div>
                </div>
                <div />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className={`${
            ifWallet ? "col-sm-12 col-md-8 col-lg-8 col-xl-9" : "col-12"
          } height-100`}
        >
          <div className="row">
            {/* RENDER THIS CONDITIONALLY */}
            {domainBrand.transTypes.length > 0 ? (
              <div className="col-xl-12 col-md-12">
                <div className="card overflow-hidden">
                  <div className="card-header bg-primary text-white">
                    <h3 className="card-title">
                      <span className="">Quick Actions</span>
                    </h3>
                    <div className="card-options">
                      {canShowMore ? (
                        <NavLink
                          onClick={showMore}
                          to=""
                          className="card-options-collapse"
                        >
                          {showMoreState ? "View Less" : "View More"}
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="card-body pb-0 pt-4">
                    <DashboardMenu
                      domainBrand={domainBrand}
                      transTypes={domainBrand.transTypes}
                      profile={profile}
                      showMoreState={showMoreState}
                      canShowMore={setCanShowMore}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">
                    <span className="">Transactions</span>
                  </h3>
                </div>
                <div className="card-body pb-0 pt-4">
                  <TransactionSummary graphData={graphData} />
                </div>
              </div>
            </div>
          </div>
          {recentHistory.length === 0 ? (
            ""
          ) : (
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card overflow-hidden">
                  <div className="card-header bg-primary text-white">
                    <h3 className="card-title">
                      <img src={trendUp} alt="arrowRight" /> &nbsp;
                      <span className="">Recent Transfer</span>
                    </h3>
                    <div className="card-options">
                      <NavLink
                        to="/transfer-history"
                        className="card-options-collapse"
                        data-bs-toggle="card-collapse"
                      >
                        View More
                      </NavLink>
                    </div>
                  </div>
                  <div className="card-body pb-0 pt-4">
                    <div className="activity1">
                      <div className="table-responsive">
                        <table className="table text-nowrap text-md-nowrap table-striped mg-b-0">
                          <thead>
                            <tr>
                              <th className="fw-bold">Date</th>
                              <th className="fw-bold">Details</th>
                              <th className="fw-bold">PCN</th>
                              <th className="fw-bold">Status</th>
                              <th className="fw-bold activity-fig">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recentHistory
                              .slice(0, 3)
                              .map((eachField, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(
                                      new Date(eachField["createdDate"])
                                    ).format("DD/MM/YYYY h:mm A")}
                                  </td>
                                  <td> Payment to {eachField["receiver"]}</td>
                                  <td> {eachField["pcn"]}</td>
                                  <td>
                                    <span
                                      className={`list-inline-item w-2 h-2 brround ${eachField["fill"]} me-2`}
                                    />
                                    {eachField["display_status"]}
                                  </td>
                                  <td className="activity-fig">
                                    {eachField["principalAmount"]}{" "}
                                    {eachField["principalCurrencyCode"]}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerDashboard;
