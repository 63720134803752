/* eslint-disable jsx-a11y/role-supports-aria-props */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";

import ProfileService from "../../../../utils/services/profile.service";
import { formatDate } from "../../../../utils/helpers/logicHelper";
import {
  fetchDocuments,
  getProfile,
} from "../../../../store/actions/profile.action";

import { removeLoggedInUser } from "../../../../store/actions/auth.action";
import OpacityLoader from "../../../../components/loaders/OpacityLoader";
import DeleteModal from "../../../../components/modals/DeleteModal";
import UploadProfilePhotoModal from "../../../../components/modals/UploadProfilePhotoModal";
import UploadDocuments from "./atom/UploadDocuments";
import { Loading } from "../../../../utils/helpers/constants";
import { genderDropdown } from "../../../../utils/helpers/objectHelpers";
import avatar from "../../../../assets/images/gravatar.png";
import "./profile.css";

const Body = ({ domainBrand, profile, properties, setPhotoUrl }) => {
  const dispatch = useDispatch();
  const {
    photoUrl,
    countries: { supportedCountries },
  } = properties;

  const user = profile.userData || {};
  const address = user.address || {};
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  // const [defaultPhoto] = useState(photoUrl);
  const [uploading, setUploading] = useState(false);
  const [deleteRender, setDeleteRender] = useState(false);
  const [uploadRender, setUploadRender] = useState(false);
  const [documentFieldStatus, setDocumentFieldStatus] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gender, setGender] = useState(user?.customerGender);
  const [deleteRenderProps, setDeleteRenderProps] = useState({});
  const [open, setOpen] = useState(false);
  const [pinFields, setPinFields] = useState(false);
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [isLoading, setLoading] = useState(false);
  const addDocumentFields = () => setDocumentFieldStatus(true);
  const uploadModal = () => setUploadRender(true);
  const closeAttachDocument = (event) => {
    event.preventDefault();
    setDocumentFieldStatus(false);
  };

  const changePassword = async () => {
    setUploading(true);
    if (newPassword !== confirmPassword) toast.error(`Passwords do not match`);
    else {
      const payload = { newPassword, currentPassword };
      const { data: responseData } = await ProfileService.changePassword(
        payload
      );
      if (responseData.status !== Loading.SUCCESS)
        toast.error(`${responseData.message}`);
      else {
        const url = domainBrand.domainBrand["publicWebSite"];
        toast.success(`Password changed, login out off all devices`);
        dispatch(removeLoggedInUser(url));
      }
    }
    setUploading(false);
  };

  const createUserPin = async () => {
    setLoading(true);
    if (pin !== confirmPin) {
      toast.error(`Pins do not match`);
    } else {
      const payload = { customerPin: pin, confirmPin: confirmPin };
      const { data: responseData } = await ProfileService.createUserPIn(
        payload
      );
      if (responseData.status !== Loading.SUCCESS) {
        toast.error(`${responseData.message}`);
        setLoading(false);
      } else {
        toast.success(`Pin successfully created`);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const updateProfile = async () => {
    setUploading(true);
    const payload = { customerGender: gender };

    const { data: responseData } = await ProfileService.editUserProfile(
      payload
    );

    if (responseData.status !== Loading.SUCCESS) {
      toast.error(`${responseData.message}`);
    } else toast.success(`Profile Information successfully changed`);
    setUploading(false);
    // const data = await ProfileService.fetchUser();
    // setGender(data?.data?.data?.customerGender);
    dispatch(getProfile());
  };

  const deleteDocument = (item) => {
    setDeleteRender(true);
    setDeleteRenderProps(item);
  };

  const downloadDocument = (item) =>
    window.open(item["download_url"], "_blank");
  const deleteDocx = async (data) => {
    await ProfileService.deleteDocuments(
      profile.userData["customerCode"],
      data.id
    );
    dispatch(fetchDocuments(profile.userData["customerCode"]));
  };

  const changeDeleteRenderStatus = () => setDeleteRender(false);
  const deleteModalRender = deleteRender ? (
    <DeleteModal
      onchange={changeDeleteRenderStatus}
      deleteProps={deleteRenderProps}
      propsType="PROFILE_DOCUMENT"
      onDeleteResource={deleteDocx}
    />
  ) : null;

  const changeUploadRenderStatus = () => setUploadRender(false);
  const uploadModalRender = uploadRender ? (
    <UploadProfilePhotoModal
      onchange={changeUploadRenderStatus}
      setPhotoUrl={setPhotoUrl}
      profile={profile}
      photoUrl={photoUrl}
    />
  ) : null;

  const genderArray = genderDropdown(languageStatus, dictionary);

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          {deleteModalRender}
          {uploadModalRender}
          <div>
            <h1 className="page-title">
              {languageStatus
                ? dictionary["WO_USER_PROFIL_548513751"]
                : "User Profile"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn">
            <span className="badge bg-primary me-2 mb-1 mt-1 p-4">
              {languageStatus
                ? dictionary["CUSTOMER_COD_-69633046"]
                : "Customer Code"}{" "}
              {"-  "}
              {user.customerCode}
            </span>
          </div>
        </div>
        <div className="row mb-6">
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div className="height-100">
              {uploading ? <OpacityLoader top="20px" /> : ""}
              <div className="d-flex avatar-flex mb-3" onClick={uploadModal}>
                <img
                  alt="Profile"
                  className="rounded-circle avatar-lg me-2"
                  src={photoUrl || avatar}
                  onError={(e) => (e.target.src = avatar)}
                />
                <div className="edit-avatar">
                  <div className="rounded-circle avatar-md browse-file me-2">
                    <i className="fa fa-pen" />
                  </div>
                </div>
              </div>
              <div className="card overflow-hidden">
                <div className="card-body">
                  <ul className="list-group mb-4">
                    <li className="list-group-item">
                      <i className="fa fa-user" aria-hidden="true" /> &nbsp;
                      {languageStatus ? dictionary["para_profile"] : "Profile"}
                    </li>
                    <li
                      className="list-group-item collapse-text"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-cog" aria-hidden="true" /> &nbsp;
                      {languageStatus
                        ? dictionary["WO_CHANGE_PASSWOR_-1471876974"]
                        : "Change Password"}
                    </li>
                    {/* COLLAPSE PASSWORD FIELDS */}
                    <Collapse in={open}>
                      <div id="example-collapse-text">
                        <div className="form-group">
                          <label className="form-label">
                            {languageStatus
                              ? dictionary["para_current_password"]
                              : "Current Password"}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={currentPassword}
                            onChange={(event) =>
                              setCurrentPassword(event.target.value)
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            {languageStatus
                              ? dictionary["para_new_password"]
                              : "New Password"}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={newPassword}
                            onChange={(event) =>
                              setNewPassword(event.target.value)
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            {languageStatus
                              ? dictionary["para_confirm_password"]
                              : "Confirm Password"}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={confirmPassword}
                            onChange={(event) =>
                              setConfirmPassword(event.target.value)
                            }
                          />
                        </div>
                        <div className="text-center mt-6 pb-2">
                          <button
                            onClick={changePassword}
                            className="btn btn-primary w-80"
                            type="button"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </Collapse>
                    <li
                      className="list-group-item collapse-text"
                      onClick={() => setPinFields(!pinFields)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-solid fa-lock" aria-hidden="true" />
                      &nbsp;
                      {languageStatus ? dictionary["WO_PI_1757522841"] : "Pin"}
                    </li>
                    {/* COLLAPSE PIN FIELDS */}
                    <Collapse in={pinFields}>
                      <div id="example-collapse-text">
                        <div className="form-group">
                          <label className="form-label">
                            {languageStatus
                              ? dictionary["WO_ENTER_YOUR_PI_483786945"]
                              : "Enter Your Pin"}{" "}
                            (pin must not exceed 4 digits)
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={pin}
                            onChange={(event) => setPin(event.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            {languageStatus
                              ? dictionary["WO_CONFIRM_PI_999278846"]
                              : "Confirm Pin"}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={confirmPin}
                            onChange={(event) =>
                              setConfirmPin(event.target.value)
                            }
                          />
                        </div>
                        <div className="text-center mt-6 pb-2">
                          <button
                            onClick={createUserPin}
                            className="btn btn-primary w-80"
                            type="button"
                          >
                            Save
                            {isLoading && (
                              <>
                                &nbsp;
                                <i className="fa fa-spinner fa-spin" />
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </Collapse>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* SIDE */}
          <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8">
            <div className="card overflow-hidden height-100">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary["WO_VIEW_PROFIL_1113374480"]
                    : "View Profile"}
                </h3>
                <div className="card-options" />
              </div>
              <div className="card-body">
                <div className="row mt-6">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus
                          ? dictionary["para_first_name"]
                          : "First name"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={user.firstName ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus
                          ? dictionary["para_last_name"]
                          : "Last name"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={user.lastName ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus ? dictionary["para_email"] : "Email"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={user.email ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus
                          ? dictionary["para_phone_number"]
                          : "Phone"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={user.phone ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus
                          ? dictionary["para_date_of_birth"]
                          : "DOB"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={new Date(user["dob"]).toLocaleDateString() ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 d-flex flex-column">
                    <div className="form-group d-flex flex-column">
                      <label>
                        {languageStatus
                          ? dictionary["WO_GENDE_-274590021"]
                          : "Gender"}
                      </label>

                      <select
                        className="px-3 py-2"
                        style={{
                          borderRadius: "6px",
                          backgroundColor: "#f5f6fb",
                          border: "1px solid #eaedf1",
                          outline: "none",
                          boxShadow: "none",
                          color: "#4d5875",
                        }}
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        {genderArray.map((item) => (
                          <option value={item.value} id={item.id}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus
                          ? dictionary["para_address_1"]
                          : "Address 1"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={address.address1 ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus
                          ? dictionary["WO_ADDRESS_-498499570"]
                          : "Address 2"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={address.address2 ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus ? dictionary["l_city"] : "City"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={address.city ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus
                          ? dictionary["l_post_code"]
                          : "Post Code"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={address.postcode ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus
                          ? dictionary["WO_STAT_-878960465"]
                          : "State"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={address.state ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>
                        {languageStatus ? dictionary["l_country"] : "Country"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={address.countryCommonName ?? ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="text-center mt-6 pb-2">
                    <button
                      onClick={updateProfile}
                      className="btn btn-primary w-80"
                      type="button"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {documentFieldStatus ? (
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">
                    {languageStatus
                      ? dictionary["para_attach_customer_documents"]
                      : "Documents"}
                  </h3>
                  <div className="card-options">
                    <NavLink
                      onClick={closeAttachDocument}
                      to=""
                      className="card-options-collapse"
                    >
                      Close
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <UploadDocuments
                    languageStatus={languageStatus}
                    dictionary={dictionary}
                    countries={supportedCountries}
                    profile={profile}
                    dispatch={dispatch}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary["para_customer_documents"]
                    : "Documents"}
                </h3>
                <div className="card-options">
                  {documentFieldStatus ? (
                    ""
                  ) : (
                    <button
                      onClick={addDocumentFields}
                      type="button"
                      className="btn btn-md btn-white"
                    >
                      {languageStatus
                        ? dictionary["button_attach_document"]
                        : "Add a new document"}{" "}
                      &nbsp;
                      <i className="fa fa-plus" />
                    </button>
                  )}
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover card-table table-striped table-vcenter table-outline text-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">
                        {languageStatus
                          ? dictionary["WO_DOCUMENT_TYP_-13031371"]
                          : "Type"}
                      </th>
                      <th scope="col">
                        {languageStatus
                          ? dictionary["l_issue_country"]
                          : "Country"}
                      </th>
                      <th scope="col">
                        {languageStatus ? dictionary["l_issue_date"] : "Date"}
                      </th>
                      <th scope="col">
                        {languageStatus
                          ? dictionary["l_expiry_date"]
                          : "Expiry Date"}
                      </th>
                      <th scope="col">
                        {languageStatus
                          ? dictionary["WO_ACTION_1788593463"]
                          : "Actions"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {profile["documents"].map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.type}</td>
                        <td>{item["issueCountry"] ?? <>&ndash;</>}</td>
                        <td>{formatDate(item["issueDate"])}</td>
                        <td>{formatDate(item["expiryDate"])}</td>
                        <td>
                          <>
                            <button
                              type="button"
                              onClick={() => downloadDocument(item)}
                              className="btn btn-info btn-sm"
                            >
                              Download
                            </button>
                            &nbsp;
                          </>
                          <>
                            <button
                              type="button"
                              onClick={() => deleteDocument(item)}
                              className="btn btn-danger btn-sm"
                            >
                              Delete
                            </button>
                            &nbsp;
                          </>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
