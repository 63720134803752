import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getEmployees } from "../../store/actions/employees.action";
import {
  addUserRole,
  passwordExpiration,
  userType,
} from "../../utils/helpers/objectHelpers";
import {
  employeeInitialValues,
  schemas,
} from "../../utils/helpers/validationHelpers";
import { addEmployee, addRole } from "../../utils/services/employees.service";
import "./modal.css";

function AddNewEmployeeModal({
  domainBrand,
  countries,
  show,
  handleClose,
  type,
}) {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const [getOption, setGetOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState();
  const [errors, setErrors] = useState({});
  const [roles, setRoles] = useState({});
  const [maxAmount, setMaxAmount] = useState({});

  const phoneCodes = countries["supportedPhoneCodes"];
  const countryList = countries["countries"];
  const currencyList = countries["currencies"];
  const formikRef = useRef();

  const dispatch = useDispatch();

  const employeeAction = async (type) => {
    const values = formikRef?.current?.values;
    const error = formikRef?.current?.errors;
    setErrors({ ...error });
    setReset(formikRef?.current?.resetForm);
    if (type === "new-role") {
      await addNewRole(values);
    } else {
      await addNewEmployee(values);
    }
  };

  const addNewEmployee = async (values) => {
    setLoading(true);
    const handleUserMap = () => {
      if (values.userRoles) {
        const userRole = values?.userRoles;
        const userMap = userRole.map((i) => i.value);
        return userMap;
      } else {
        return ["ADMIN"];
      }
    };

    const payload = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      phone: `${values?.phoneCountryCode?.telephoneCode}${values?.phone}`,
      staffActiveDirectoryId: values?.staffId,
      addressEntityDTO: {
        address1: values?.addressLine1,
        address2: values?.addressLine2,
        city: values?.city,
        countryCommonName: values?.country?.common_name,
        postcode: values?.postCode,
        province: values?.province,
        countryIso3: values?.country?.iso3Code,
      },
      userTypes: "ADMIN",
      userRoles: handleUserMap(),
      ableToPayIn: values?.enablePayIn,
      ableToPayOut: values?.enablePayOut,
      ableToBeReceiver: values?.paymentCollector,
      ableToRefund: values?.enableRefund,
      ableToFund: values?.enableFundWallet,
      ableToWithdraw: values?.enableWithdrawFromWallet,
      ableToCreateWallet: values?.enableCreateWallet,
      ableToSeeTransactions: values?.seeTransactions,
      ableToSeeDetailedTransactions: values?.seeDetailedTransactions,
      ableToSeeDetailedTransactionSummary: values?.seeTransactionSummary,
      ableToSeePaidOutTransactions: values?.paidOutTransactions,
      ableToSeeApiTransactions: values?.seeApiTransactions,
      ableToSeeOpeningBalance: values?.seeOpeningBalance,
      ableToSeeOpeningBalanceHistory: values?.seeOpeningBalanceHistory,
      ableToSeeWalletLedger: values?.seeWalletLedger,
      enableMultipleCountryPayOut: values?.multipleCountries,
      profileApproved: values?.approveProfile,
      passwordExpires: values?.passwordExpires,
      passwordExpireType: values?.passwordExpireType?.value,
      ipAddress: "",
      maxAmounts: maxAmount,
      autoConfirmTransaction: true,
    };

    try {
      const response = await addEmployee(payload);
      if (response.data.status === "SUCCESS") {
        toast.success(response?.data?.message);
        dispatch(getEmployees());
        reset({});
        setErrors({});
        setGetOption([]);
        setMaxAmount({});
        handleClose();
      } else {
        toast.error(response?.data?.message);
        reset({});
        setErrors({});
        setGetOption([]);
        setMaxAmount({});
      }
    } catch (error) {
      toast.error(error?.data?.message);
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  const addNewRole = async (values) => {
    setLoading(true);
    const payload = { email: values?.email, ...roles };

    try {
      const response = await addRole(payload);
      console.log(response);
      if (response.data.status === "SUCCESS") {
        toast.success(response?.data?.message);
        dispatch(getEmployees());
        reset({});
        setRoles({});
        setLoading(false);
        handleClose();
      } else {
        toast.success(response?.data?.message);
        reset({});
        setRoles({});
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setRoles({});
      return error;
    }
    setLoading(false);
  };

  const onChangeInputValue = (val, index) => {
    let temp = getOption;
    temp[index].value = val.target.value;
    temp[index].name = val.target.name;
    setMaxAmount({ ...maxAmount, [val.target.name]: val.target.value });
  };

  const onChangeInputRole = (val) => {
    setRoles({ ...roles, [val.target.name]: val.target.checked });
  };

  const onChangeInputRight = (val) => {
    setRoles({ ...roles, [val.target.name]: val.target.checked });
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size={"xl"}
      scrollable
    >
      <Modal.Header closeButton={false}>
        {type === "new-role" ? (
          <Modal.Title className="fw-bold">Add New User</Modal.Title>
        ) : (
          <Modal.Title className="fw-bold">Add New Employee</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <Formik
            initialValues={{ ...employeeInitialValues(type) }}
            validationSchema={schemas(type)}
            onSubmit={async () => {}}
            innerRef={formikRef}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => {
              const disablePhoneInput =
                typeof values?.phoneCountryCode?.value === "undefined" ||
                values?.phoneCountryCode?.value === "";

              return (
                <Form className="needs-validation" onSubmit={handleSubmit}>
                  {/* CASHIER INFORMATION */}
                  <div className="col-lg-12 col-md-12">
                    <h6 className="text-center fw-bold text-uppercase pt-3 pb-3">
                      {dictionary
                        ? dictionary["para_cashier_information"]
                        : "Cashier Information"}
                    </h6>

                    {type !== "new-role" && (
                      <div className="form-row">
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label>
                            {dictionary
                              ? dictionary["para_first_name"]
                              : "First Name"}
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={values.firstName}
                            className={`form-control ${
                              errors?.firstName ? "is-invalid" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>

                        <div className="col-md-6 col-lg-6 mb-3">
                          <label>
                            {dictionary
                              ? dictionary["l_last_name"]
                              : "Last Name"}
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={values.lastName}
                            className={`form-control ${
                              errors["lastName"] ? "is-invalid" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-row">
                      {type !== "new-role" && (
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label>
                            {dictionary
                              ? dictionary["para_user_role"]
                              : "User Role"}
                          </label>
                          <Select
                            options={userType()}
                            isClearable={false}
                            maxMenuHeight={250}
                            menuPlacement="bottom"
                            //   menuPortalTarget={menuPortalTarget}
                            value={values.userRoles}
                            name="userRoles"
                            onChange={(option) =>
                              setFieldValue("userRoles", option)
                            }
                            className="is-invalid"
                            isMulti
                          />
                        </div>
                      )}

                      <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary ? dictionary["l_email"] : "Email"}
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={values.email}
                          className={`form-control ${
                            errors["email"] ? "is-invalid" : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                    </div>

                    {type !== "new-role" && (
                      <div className="form-row">
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label>
                            {dictionary
                              ? dictionary["para_province"]
                              : "Province"}
                          </label>
                          <input
                            type="text"
                            name="province"
                            id="province"
                            value={values.province}
                            className={`form-control`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label>
                            {dictionary
                              ? dictionary["para_address1"]
                              : "Address Line 1"}
                          </label>
                          <input
                            type="text"
                            name="addressLine1"
                            id="addressLine1"
                            value={values.addressLine1}
                            className={`form-control ${
                              errors["addressLine1"] ? "is-invalid" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>

                        {/* <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary
                            ? dictionary["para_address_line_2"]
                            : "Address Line 2"}
                        </label>
                        <input
                          type="text"
                          name="addressLine2"
                          id="addressLine2"
                          value={values.addressLine2}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div> */}
                      </div>
                    )}

                    {type !== "new-role" && (
                      <div className="form-row">
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label>
                            {dictionary
                              ? dictionary["para_address_line_2"]
                              : "Address Line 2"}
                          </label>
                          <input
                            type="text"
                            name="addressLine2"
                            id="addressLine2"
                            value={values.addressLine2}
                            className={`form-control`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>

                        <div className="col-md-6 col-lg-6 mb-3">
                          <label>
                            {dictionary ? dictionary["para_city"] : "City"}
                          </label>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            value={values.city}
                            className={`form-control ${
                              errors["city"] ? "is-invalid" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>

                        {/* <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary ? dictionary["l_country"] : "Country"}
                        </label>
                        <Select
                          options={countryList}
                          isClearable={false}
                          maxMenuHeight={250}
                          menuPlacement="bottom"
                          //   menuPortalTarget={menuPortalTarget}
                          value={values.country}
                          name="country"
                          onChange={(option) =>
                            setFieldValue("country", option)
                          }
                          className="is-invalid"
                        />
                      </div> */}
                      </div>
                    )}
                    {type !== "new-role" && (
                      <div className="form-row">
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label>
                            {dictionary ? dictionary["l_country"] : "Country"}
                          </label>
                          <Select
                            options={countryList}
                            isClearable={false}
                            maxMenuHeight={250}
                            menuPlacement="bottom"
                            //   menuPortalTarget={menuPortalTarget}
                            value={values.country}
                            name="country"
                            onChange={(option) =>
                              setFieldValue("country", option)
                            }
                            className="is-invalid"
                          />
                        </div>
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label>
                            {dictionary
                              ? dictionary["para_postcode"]
                              : "Area/Post code"}
                          </label>
                          <input
                            type="text"
                            name="postCode"
                            id="postCode"
                            value={values.postCode}
                            className={`form-control`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    )}

                    {type !== "new-role" && (
                      <div className="form-row mb-3">
                        <div className="col-lg-6 col-md-6 mb-3">
                          <label id="phoneNumber">
                            {dictionary
                              ? dictionary["para_phone_number"]
                              : "Phone Number"}
                          </label>
                          <div className="form-row">
                            <div className="col-md-5 col-lg-5 mb-3">
                              <Select
                                options={phoneCodes}
                                isClearable={false}
                                maxMenuHeight={250}
                                menuPlacement="bottom"
                                //   menuPortalTarget={menuPortalTarget}
                                value={values.phoneCountryCode}
                                name="phoneCountryCode"
                                onChange={(option) =>
                                  setFieldValue("phoneCountryCode", option)
                                }
                              />
                            </div>
                            <div
                              className="col-md-7 col-lg-7 mb-3"
                              id="phoneContainer"
                            >
                              <input
                                disabled={disablePhoneInput}
                                type="text"
                                name="phone"
                                id="phoneField"
                                value={values.phone}
                                className={`form-control ${
                                  errors["phone"] ? "is-invalid" : ""
                                }`}
                                onChange={handleChange}
                                required
                              />
                              {/* {errors["phone"] ? (
                              <div className="invalid-feedback">
                                Phone number is Required
                              </div>
                            ) : (
                              ""
                            )} */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 mb-3">
                          <label>
                            {dictionary
                              ? dictionary["WO_STAFF_I_-725097220"]
                              : "Staff ID"}
                          </label>
                          <input
                            type="text"
                            name="staffId"
                            id="staffId"
                            value={values.staffId}
                            className={`form-control ${
                              errors["staffId"] ? "is-invalid" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>

                        {type !== "new-role" && (
                          <div className="col-lg-6 col-md-6 mb-3">
                            <label>
                              {dictionary
                                ? dictionary[
                                    "WO_TILL_ACCOUNT_NUMBER_-1450217434"
                                  ]
                                : "Till Account Number (Core Banking System)"}
                            </label>
                            <input
                              type="text"
                              name="tillAccountNumber"
                              id="tillAccountNumber"
                              value={values.tillAccountNumber}
                              className={`form-control`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        )}

                        {type !== "new-role" && (
                          <div className="col-lg-6 col-md-6 mb-3">
                            <label id="phoneNumber">
                              {dictionary
                                ? dictionary["SET_AUTOCONFIRM_TRA_861913692"]
                                : "Set auto-confirm transactions"}
                            </label>
                            <div className="form-row">
                              <div className="col-md-12 col-lg-12">
                                <Select
                                  options={currencyList}
                                  isClearable={false}
                                  maxMenuHeight={250}
                                  menuPlacement="bottom"
                                  value={values.transactionCurrency}
                                  name="transactionCurrency"
                                  onChange={(option) => {
                                    setFieldValue(
                                      "transactionCurrency",
                                      option
                                    );
                                    setGetOption(option);
                                  }}
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {type !== "new-role" && (
                          <div className="col-lg-6 col-md-6">
                            <Table striped>
                              <thead>
                                <tr>
                                  <th>
                                    <span style={{ fontSize: "15px" }}>
                                      {dictionary
                                        ? dictionary["l_currency"]
                                        : "Currency"}
                                    </span>
                                  </th>
                                  <th>
                                    <span style={{ fontSize: "15px" }}>
                                      {dictionary
                                        ? dictionary[
                                            "para_max_auto_confirm_amount"
                                          ]
                                        : "Maximum Auto-confirm Amount"}
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="">
                                    {getOption?.map((i, idx) => {
                                      return (
                                        <>
                                          <p className="pt-3" key={idx}>
                                            {i.currencyCode}
                                          </p>
                                        </>
                                      );
                                    })}
                                  </td>

                                  <td>
                                    {getOption?.map((i, idx) => {
                                      return (
                                        <>
                                          <input
                                            className="form-control mb-2"
                                            key={idx}
                                            type={"text"}
                                            name={i.currencyCode}
                                            id={i.currencyCode}
                                            onChange={(val) =>
                                              onChangeInputValue(val, idx)
                                            }
                                          />
                                        </>
                                      );
                                    })}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* PERMISSION PART OF THE FORM  */}
                  {type !== "new-role" ? (
                    <React.Fragment>
                      {/* CASHIER PERMISSION */}
                      <div className="col-lg-12 col-md-12 mt-5">
                        <h6 className="text-center fw-bold text-uppercase pt-3 pb-4">
                          {dictionary
                            ? dictionary["para_cashier_permission"]
                            : "Cashier Permission"}
                        </h6>

                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="enablePayIn"
                                name="enablePayIn"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary["para_enable_pay_in"]
                                  : "Enable Pay-in"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="enablePayOut"
                                name="enablePayOut"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary["para_enable_pay_out"]
                                  : "Enable Pay-out"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="enableRefund"
                                name="enableRefund"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary["ENABLE_REFUN_-1225235910"]
                                  : "Enable Refund"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="paymentCollector"
                                name="paymentCollector"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary["WO_PAYMENT_COLLECTO_489281550"]
                                  : "Payment Collector"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="enableFundWallet"
                                name="enableFundWallet"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary[
                                      "WO_ENABLE_FUND_WALLE_-1967309241"
                                    ]
                                  : "Enable Fund Wallet"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="enableWithdrawFromWallet"
                                name="enableWithdrawFromWallet"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary[
                                      "WO_ENABLE_WITHDRAW_FROM_-1527160427"
                                    ]
                                  : "Enable Withdraw From Wallet"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="enableCreateWallet"
                                name="enableCreateWallet"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary[
                                      "WO_ENABLE_CREATE_WALLE_-622126988"
                                    ]
                                  : "Enable Create Wallet"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="multipleCountries"
                                name="multipleCountries"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary[
                                      "WO_ALLOW_CASHIERTELLER_-1043847541"
                                    ]
                                  : "Allow Cashier/Teller to Payout in multiple countries"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* REPORT PERMISSION */}
                      <div className="col-lg-12 col-md-12 mt-5">
                        <h6 className="text-center fw-bold text-uppercase pt-3 pb-4">
                          {dictionary
                            ? dictionary["WO_REPORT_PERMISSIO_-895695025 "]
                            : "Report Permission"}
                        </h6>

                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="seeTransactions"
                                name="seeTransactions"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary["WO_SEE_TRANSACTION_-1011804851"]
                                  : "See Transactions"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="seeApiTransactions"
                                name="seeApiTransactions"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary[
                                      "WO_SEE_API_TRANSACTIO_-1455924850"
                                    ]
                                  : "See API Transaction"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="seeDetailedTransactions"
                                name="seeDetailedTransactions"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary[
                                      "WO_SEE_DETAILED_TRANSAC_-1938789957"
                                    ]
                                  : "See Detailed Transactions"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="seeTransactionSummary"
                                name="seeTransactionSummary"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary[
                                      "WO_SEE_TRANSACTION_SUMM_-1722708159"
                                    ]
                                  : "See Transaction Summary"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="seeWalletLedger"
                                name="seeWalletLedger"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary["WO_SEE_WALLET_LEDGE_2010273207"]
                                  : "See Wallet Ledger"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="paidOutTransactions"
                                name="paidOutTransactions"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary[
                                      "WO_SEE_PAIDOUT_TRANSAC_1098561465"
                                    ]
                                  : "See Paid-out Transactions"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="seeOpeningBalance"
                                name="seeOpeningBalance"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary[
                                      "WO_SEE_OPENING_BALANC_-652587606"
                                    ]
                                  : "See Opening Balance"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 mb-3">
                            <div className="d-flex flex-row align-items-center">
                              <Field
                                type={"checkbox"}
                                className="me-4"
                                style={{ width: "24px", height: "16px" }}
                                id="seeOpeningBalanceHistory"
                                name="seeOpeningBalanceHistory"
                              />
                              <span className="fw-bold align-middle">
                                {dictionary
                                  ? dictionary[
                                      "WO_SEE_OPENING_BALANCE_-935765414"
                                    ]
                                  : "See Opening Balance History"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* LOGIN EXPIRE && TRANSACTIONS PERMISSION */}
                      <div className="col-lg-12 col-md-12 mt-5">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 mb-3">
                            <h6 className="text-center fw-bold text-uppercase pt-3 pb-4">
                              {dictionary
                                ? dictionary["para_transactions_permission"]
                                : "TRANSACTIONS PERMISSION"}
                            </h6>

                            <div className="form-row">
                              <div className="col-lg-6 col-md-6 mb-3">
                                <div className="d-flex flex-row align-items-center">
                                  <Field
                                    type={"checkbox"}
                                    className="me-4"
                                    style={{ width: "24px", height: "16px" }}
                                    id="recieveMoneyWithSystem"
                                    name="recieveMoneyWithSystem"
                                  />
                                  <span className="fw-bold align-middle">
                                    {dictionary
                                      ? dictionary[
                                          "para_cashier_receives_money_as_receiver"
                                        ]
                                      : "Receive money, using the system as a receiver"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 mb-3">
                            <h6 className="text-center fw-bold text-uppercase pt-3 pb-4">
                              {dictionary
                                ? dictionary["para_login_expire"]
                                : "LOGIN EXPIRE"}
                            </h6>

                            <div className="form-row">
                              <div className="col-lg-6 col-md-6 mb-3">
                                <div className="d-flex flex-row align-items-center">
                                  <Field
                                    type={"checkbox"}
                                    className="me-4"
                                    style={{ width: "24px", height: "16px" }}
                                    id="passwordExpires"
                                    name="passwordExpires"
                                  />
                                  <span className="fw-bold align-middle">
                                    {dictionary
                                      ? dictionary["para_password_expires"]
                                      : "Password Expires"}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="form-row">
                              <div className="col-lg-12 col-md-6 mb-3 mt-3">
                                <label className="pb-2 ">
                                  {dictionary
                                    ? dictionary["para_password_expire_type"]
                                    : "Password Expiration Type"}
                                </label>
                                <div className="" style={{ width: "100%" }}>
                                  <Select
                                    options={passwordExpiration}
                                    isClearable={false}
                                    maxMenuHeight={250}
                                    menuPlacement="bottom"
                                    value={values.passwordExpiration}
                                    name="passwordExpiration"
                                    onChange={(option) =>
                                      setFieldValue(
                                        "passwordExpiration",
                                        option
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <h6 className="text-center fw-bold text-uppercase pt-3 pb-4">
                              {dictionary
                                ? dictionary["WO_PROFILE_APPROVA_-1100027161"]
                                : "PROFILE APPROVAL"}
                            </h6>

                            <div className="form-row">
                              <div className="col-lg-6 col-md-6 mb-3">
                                <div className="d-flex flex-row align-items-center">
                                  <Field
                                    type={"checkbox"}
                                    className="me-4"
                                    style={{ width: "24px", height: "16px" }}
                                    name="approveProfile"
                                    id="approveProfile"
                                  />
                                  <span className="fw-bold align-middle">
                                    {dictionary
                                      ? dictionary[
                                          "WO_APPROVE_PROFIL_1196123766"
                                        ]
                                      : "Approve Profile"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="col-lg-12 col-md-12 mt-3">
                        <h6 className="text-center fw-bold text-uppercase pt-3 pb-4">
                          {dictionary
                            ? dictionary["WO_CUSTOM_EMPLOYEE_ROLE_-1104106561"]
                            : "Custom Employee Roles"}
                        </h6>
                        {addUserRole.map((i, idx) => {
                          return (
                            <>
                              <div
                                className="form-row d-flex flex-row align-items-center"
                                key={idx}
                              >
                                <div
                                  className="col-lg-6 col-md-6"
                                  key={i.id % 2 === 0 && i.id}
                                >
                                  {i.id % 2 === 0 && (
                                    <div
                                      className="d-flex flex-row align-items-center"
                                      key={i.id % 2 === 0 && i.id}
                                    >
                                      {i.id % 2 === 0 && (
                                        <input
                                          type={"checkbox"}
                                          className="me-4"
                                          key={idx}
                                          style={{
                                            width: "24px",
                                            height: "16px",
                                          }}
                                          id={i.id % 2 === 0 && i.name}
                                          name={
                                            i.id % 2 === 0 ? i.name : undefined
                                          }
                                          onChange={(val) =>
                                            onChangeInputRole(val)
                                          }
                                        />
                                      )}
                                      <span className="fw-bold align-middle">
                                        {dictionary
                                          ? dictionary[
                                              i.id % 2 === 0 && i.dictionary
                                            ]
                                          : `${i.id % 2 === 0 && `${i.role}`}`}
                                      </span>
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="col-lg-6 col-md-6"
                                  key={i.id % 2 !== 0 && i.id}
                                >
                                  {i.id % 2 !== 0 && (
                                    <div
                                      className="d-flex flex-row align-items-center"
                                      key={i.id % 2 !== 0 && i.id}
                                    >
                                      {i.id % 2 !== 0 && (
                                        <input
                                          type={"checkbox"}
                                          className="me-4"
                                          style={{
                                            width: "24px",
                                            height: "16px",
                                          }}
                                          id={i.id % 2 !== 0 && i.name}
                                          name={
                                            i.id % 2 !== 0 ? i.name : undefined
                                          }
                                          onChange={(val) =>
                                            onChangeInputRight(val)
                                          }
                                        />
                                      )}
                                      <span className="fw-bold align-middle">
                                        {dictionary
                                          ? dictionary[
                                              i.id % 2 !== 0 && i.dictionary
                                            ]
                                          : `${i.id % 2 !== 0 && `${i.role}`}`}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
            setGetOption([]);
            setErrors({});
            setLoading(false);
            setMaxAmount({});
            setRoles({});
          }}
        >
          {dictionary ? dictionary["button_close"] : "Close"}
        </Button>

        <Button
          variant="primary"
          className="fw-bold px-3"
          onClick={() => employeeAction(type)}
          type={"submit"}
        >
          {dictionary ? dictionary["b_create"] : "Create"}
          {loading ? (
            <>
              &nbsp;
              <i className="fa fa-spinner fa-spin" />
            </>
          ) : (
            ""
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddNewEmployeeModal;
