import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../../../../../components/footer/Footer";
import Header from "../../../../../../../components/header/Header";
import HeaderMenu from "../../../../../../../components/header/HeaderMenu";
import SphereLoader from "../../../../../../../components/loaders/SphereLoader";
import { toggleNav } from "../../../../../../../store/actions/component.action";
import { getCountries } from "../../../../../../../store/actions/country.action";
import { Loading } from "../../../../../../../utils/helpers/constants";
import Body from "./Body";

function AddNewEmployee() {
  const dispatch = useDispatch();
  const { domainBrand, component, country } = useSelector(
    ({ domainBrandReducer, componentReducer, countryReducer }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      country: countryReducer,
    })
  );

  const loadState = domainBrand.loading === Loading.FETCHING;

  useEffect(() => {
    dispatch(toggleNav(false));
  }, []);

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="management"
              active="employees"
              domainBrand={domainBrand}
            />
            <Body domainBrand={domainBrand} countries={country} />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
}

export default AddNewEmployee;
