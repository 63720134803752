import React, { useState } from "react";
import DatePicker from "react-datepicker";

const CustomDatePicker = ({
  withPortal,
  getDate,
  showYearDropdown,
  selectDropdownMode,
  setMinDate,
  setMaxDate,
}) => {
  const [date, setDate] = useState("");
  const dropDownMode = selectDropdownMode ? "select" : "";

  const updateGetDate = (date) => {
    getDate(date);
    setDate(date);
  };

  return (
    <DatePicker
      selected={date}
      onChange={(date) => updateGetDate(date)}
      placeholderText="Select date"
      dateFormat="dd/MM/yyyy"
      showYearDropdown={showYearDropdown}
      dropdownMode={dropDownMode}
      minDate={setMinDate}
      maxDate={setMaxDate}
      withPortal={withPortal}
    />
  );
};

export default CustomDatePicker;
