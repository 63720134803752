import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import Select from "react-select";

import * as DomainBrand from "../../../store/actions/domain-brand.action";
import logo from "../../../assets/images/logo.png";
import splash from "../../../assets/images/splash1.png";
import NameFields from "./NameFields";
import DetailsFields from "./DetailsFields";
import SphereLoader from "../../../components/loaders/SphereLoader";
import Footer from "../../../components/footer/Footer";
import { getDynamicFields } from "../../../store/actions/component.action";
import {
  fetchAppLanguage,
  fieldsValidator,
  languageChangeHelper,
} from "../../../utils/helpers/logicHelper";
import {
  getCountries,
  getOriginSupportedCountries,
} from "../../../store/actions/country.action";
import { fetchComplianceRequiredFields } from "../../../store/actions/compliance.action";
import {
  customerTypeDropdown,
  languageDropDownOptions,
} from "../../../utils/helpers/objectHelpers";
import TokenHelpers from "../../../utils/helpers/tokenHelpers";
import { navBarStyles } from "../../../components/selectStyles/customStyles";
import {
  dynamicFieldsTypes,
  Loading,
  mandatoryFieldsTypes,
} from "../../../utils/helpers/constants";

const SignUp = () => {
  let selectedLanguage = fetchAppLanguage(),
    isLoggedIn = TokenHelpers.checkIfLoggedIn();
  const dispatch = useDispatch();
  // STATES
  const [languageField, updateLanguageField] = useState(
    selectedLanguage["commonName"]
  );
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [visibleFields, setVisibleFields] = useState([]);
  const [initialDomState, setInitialDomState] = useState(true);
  const [namesFieldsData, setNamesFieldsData] = useState({});

  const { domainBrand, country, compliance, component } = useSelector(
    ({
      domainBrandReducer,
      countryReducer,
      complianceReducer,
      componentReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      country: countryReducer,
      compliance: complianceReducer,
      component: componentReducer,
    })
  );

  const loading =
    domainBrand.loading === Loading.FETCHING ||
    component.loading === Loading.FETCHING ||
    country.loadingCountries === Loading.FETCHING ||
    country.loadingOriginCountries === Loading.FETCHING ||
    compliance.fetchingRequiredFields === Loading.FETCHING;

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const customerTypesDropdown = customerTypeDropdown(
    languageStatus,
    dictionary
  );

  useEffect(() => {
    batch(() => {
      dispatch(getCountries());
      dispatch(getOriginSupportedCountries());
      dispatch(
        fetchComplianceRequiredFields(mandatoryFieldsTypes.ONLINE_SENDER)
      );
    });
  }, []);

  useEffect(() => {
    if (compliance.fetchingRequiredFields === Loading.SUCCESS) {
      setMandatoryFields(
        fieldsValidator(compliance[mandatoryFieldsTypes.ONLINE_SENDER])[
          "requiredFields"
        ]
      );
      setVisibleFields(
        fieldsValidator(compliance[mandatoryFieldsTypes.ONLINE_SENDER])[
          "visibleFields"
        ]
      );
    }
  }, [compliance]);

  useEffect(() => {
    batch(() => {
      dispatch(getDynamicFields(dynamicFieldsTypes.REGISTERED_CUSTOMER_SIGNUP));
      dispatch(getDynamicFields(dynamicFieldsTypes.COMPANY_CUSTOMER_SIGNUP));
    });
  }, []);

  if (isLoggedIn) return <Redirect to={{ pathname: "/dashboard" }} />;
  const brandLangSelectorOptions =
    domainBrand.loading === Loading.SUCCESS
      ? domainBrand["domainBrand"]["language"]
      : languageDropDownOptions;

  const handleLanguageChange = (option) => {
    const languageHelper = languageChangeHelper(option);
    updateLanguageField(languageHelper["value"]);
    dispatch(
      DomainBrand.getDomainBrandIdentity(
        languageHelper["subdomain"],
        languageHelper["value"]["id"]
      )
    );
  };

  const deliverables = { languageStatus, dictionary, customerTypesDropdown };
  const properties = {
    visibleFields,
    mandatoryFields,
    namesFieldsData,
    component,
  };
  const countries = {
    supportedCountries: country["supportedOriginCountries"],
    countries: country["supportedCountries"],
    phoneCodes: country["supportedPhoneCodes"],
  };

  return (
    <div className="login-img">
      <div className="page" id="signup">
        {loading ? (
          <SphereLoader />
        ) : (
          <>
            <div className="login-language" id="language-select">
              <Select
                options={brandLangSelectorOptions}
                isClearable={false}
                isSearchable={false}
                name={languageField}
                value={selectedLanguage}
                onChange={(option) => handleLanguageChange(option)}
                styles={navBarStyles}
              />
            </div>
            <div className="container">
              <div className="row py-5 mt-4 align-items-center">
                <div className="col-md-5 mb-5 mb-md-0 text-center">
                  <img
                    src={splash}
                    alt=""
                    className="img-fluid mb-3 d-none d-md-block"
                  />
                  <h3
                    className="font-italic d-none d-md-block text-muted mb-0"
                    id="simplest-way"
                  >
                    The simplest way to set up your
                  </h3>
                  <h1
                    className="font-italic d-none d-md-block text-muted fw-bolder mb-0"
                    id="digital-banking"
                  >
                    Digital Banking Service
                  </h1>
                </div>
                <div className="col-md-7 col-lg-7 ml-auto">
                  <div className="page-header" />
                  <div className="card">
                    <div className="card-body wrap-signup p-lg-5 pb-1">
                      <div className="mx-auto">
                        <div className="mb-3" id="img-container">
                          <img
                            src={domainBrand["domainBrand"]["logo"] || logo}
                            className="header-brand-img mb-1"
                            alt="LandingLogo"
                          />
                          <h3
                            className="fs-24 fw-bolder mb-6"
                            id="create-account"
                          >
                            {languageStatus
                              ? dictionary["button_create_wallet_account"]
                              : "Create Account"}
                          </h3>
                        </div>
                      </div>
                      {initialDomState ? (
                        <NameFields
                          deliverables={deliverables}
                          properties={properties}
                          countriesProps={countries}
                          setInitialDomState={setInitialDomState}
                          formikData={setNamesFieldsData}
                        />
                      ) : (
                        <DetailsFields
                          deliverables={deliverables}
                          properties={properties}
                          countriesProps={countries}
                          setInitialDomState={setInitialDomState}
                          formikData={setNamesFieldsData}
                        />
                      )}
                    </div>
                    <div>
                      <div className="d-flex justify-content-center">
                        {domainBrand["domainBrand"]["isGoogleLoginEnabled"] ? (
                          <a
                            href="/"
                            className="social-login  text-center me-2"
                          >
                            <i className="fab fa-google" />
                          </a>
                        ) : (
                          ""
                        )}
                        {domainBrand["domainBrand"][
                          "isFacebookLoginEnabled"
                        ] ? (
                          <a href="/" className="social-login text-center me-2">
                            <i className="fab fa-facebook" />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="d-flex justify-content-center my-1">
                        <div className="text-center pt-3" id="already-have-acc">
                          <p className="text-dark mb-0">
                            Already have account?
                            <NavLink
                              to="/login"
                              className="text-primary ms-1"
                              id="login"
                            >
                              {languageStatus
                                ? dictionary["b_login"]
                                : "Login In"}
                            </NavLink>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer domainBrand={domainBrand} />
          </>
        )}
      </div>
    </div>
  );
};

export default SignUp;
