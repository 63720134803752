import * as BankFieldConstants from "../constants/bankfield.constant";
import { Loading } from "../../utils/helpers/constants";

const initialState = {
  bankFields: [],
  bankFieldsLoading: "",
};

const bankFieldReducer = (state = initialState, action) => {
  switch (action.type) {
    case BankFieldConstants.LIST_BANK_FIELDS_REQUEST:
      return { ...state, bankFieldsLoading: Loading.FETCHING };

    case BankFieldConstants.LIST_BANK_FIELDS_SUCCESS:
      return {
        ...state,
        bankFieldsLoading: Loading.SUCCESS,
        bankFields: action.payload,
      };

    case BankFieldConstants.LIST_BANK_FIELDS_FAILED:
      return {
        ...state,
        bankFieldsLoading: Loading.ERROR,
      };

    default:
      return state;
  }
};

export default bankFieldReducer;
