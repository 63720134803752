import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../../components/footer/Footer";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { getCountries } from "../../../../store/actions/country.action";
import { Loading } from "../../../../utils/helpers/constants";
import Body from "./Body";

function WalletLedger() {
  const dispatch = useDispatch();
  const { component, domainBrand, country } = useSelector(
    ({ componentReducer, domainBrandReducer, countryReducer }) => ({
      component: componentReducer,
      domainBrand: domainBrandReducer,
      country: countryReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    country.loadingCountries === Loading.FETCHING;

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
  }, []);
  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="reports"
              active="reports"
              domainBrand={domainBrand}
            />
            <Body domainBrand={domainBrand} currency={country?.currencies} />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
}

export default WalletLedger;
