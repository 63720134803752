import { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-hot-toast";

import video from "../../../assets/images/svgs/video.svg";
import KYCUploadModal from "../../../components/modals/KYCUploadModal";
import KYCWebcam from "../../../components/webcam/KYCWebcam";
import KYCStream from "../../../components/webcam/KYCStream";
import { uploadDocuments } from "../../../store/actions/profile.action";
import { fileToBase64, formatDate } from "../../../utils/helpers/logicHelper";
import KYCSuccessModal from "../../../components/modals/KYCSuccessModal";

const Body = ({
  domainBrand,
  documents,
  eVideo,
  ePhoto,
  verifyEmail,
  countries,
  profile,
  steps,
  initMethod,
}) => {
  const dispatch = useDispatch();
  const [fileData, setFileData] = useState("");
  const [nextRender, setNextRender] = useState("");
  const [capturePhoto, setCapturePhoto] = useState("FILE");
  const [activeMethod, setActiveMethod] = useState(initMethod);
  const [loading, setLoading] = useState(false);
  const [uploadRender, setUploadRender] = useState(false);
  const [successRender, setSuccessRender] = useState(false);
  const [uploadRenderProps, setUploadRenderProps] = useState({});
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  console.log(loading);

  const onSelectFile = (item) => {
    setUploadRender(true);
    setUploadRenderProps(item);
  };

  const captureState = (event, state) => {
    event.preventDefault();
    setCapturePhoto(state);
  };

  const onChangeFile = async (event) => {
    const allowedExtensions = [
      "jpg",
      "jpeg",
      "webp",
      "mkv",
      "png",
      "mp4",
      "JPG",
      "JPEG",
      "WEBP",
      "MKV",
      "PNG",
      "MP4",
    ];
    const sizeLimit = 2097152;
    const selectedFileSize = event.target.files[0].size || "";
    const selectedFileName = event.target.files[0].name || null;
    const fileExtension = selectedFileName.split(".").pop();
    if (typeof selectedFileSize === "undefined") return false;
    else if (
      event.target.files[0] &&
      !allowedExtensions.includes(fileExtension)
    )
      toast.error("File must be an image or video");
    else if (selectedFileSize > sizeLimit) toast.error("File size too large");
    else await setFileData(event.target.files[0]);
  };

  const submitEKYC = async (file) => {
    setLoading(true);
    const idType = file.type === "image" ? ePhoto.idType : eVideo.idType;
    const next = file.type === "image" ? "E-VIDEO" : "DOCUMENTS";
    const file_base64 =
      file.type === "image"
        ? file.src
        : file.type === "stream"
        ? await fileToBase64(file.src)
        : await fileToBase64(fileData);
    const payload = {
      customerCode: profile["customerCode"],
      attachedDocument: [
        {
          file_base64,
          idType,
          issueCountry: profile["address"]["countryIso3"],
        },
      ],
    };
    await dispatch(uploadDocuments(payload));
    setNextRender(next);
    setSuccessRender(true);
    setLoading(false);
  };

  const uploadButton = (
    <button
      className="btn btn-white btn-sm"
      onClick={(event) => captureState(event, "FILE")}
    >
      Upload File{" "}
    </button>
  );
  const videoButton = eVideo.status ? (
    <button
      className="btn btn-white btn-sm"
      onClick={(event) => captureState(event, "STREAM")}
    >
      Capture Video
    </button>
  ) : (
    ""
  );

  const photoButton = ePhoto.status ? (
    <button
      className="btn btn-white btn-sm"
      onClick={(event) => captureState(event, "PHOTO")}
    >
      Capture Photo{" "}
    </button>
  ) : (
    ""
  );

  const changeUploadRenderStatus = () => setUploadRender(false);
  const uploadModalRender = uploadRender ? (
    <KYCUploadModal
      domainBrand={domainBrand}
      onchange={changeUploadRenderStatus}
      type="KYC"
      uploadProps={uploadRenderProps}
      countries={countries}
      profile={profile}
    />
  ) : null;

  let renderSteps = [];
  for (let i = 0; i <= steps; i++) {
    let active = false;
    if (initMethod === "E-PHOTO" && i === 0) active = true;
    else if (initMethod === "E-VIDEO" && i === 1) active = true;
    else if (initMethod === "DOCUMENTS" && i === 2) active = true;
    else if (initMethod === "E-VIDEO" && !ePhoto.status && i === 0)
      active = true;
    else if (initMethod === "DOCUMENTS" && !ePhoto.status && i === 1)
      active = true;
    else if (
      initMethod === "DOCUMENTS" &&
      !ePhoto.status &&
      !eVideo.status &&
      i === 0
    )
      active = true;

    renderSteps.push(
      <li
        key={i}
        role="tab"
        className={`first ${active ? "current" : "disabled"}`}
        aria-selected="true"
      >
        <NavLink
          onClick={(e) => e.preventDefault()}
          id="wizard2-t-0"
          to=""
          className="text-center"
        >
          <span className="number text-center">{i + 1}</span>
        </NavLink>
      </li>
    );
  }

  const changeSuccessRenderStatus = (value) => {
    setActiveMethod(value);
    setSuccessRender(false);
  };
  const deleteModalRender = successRender ? (
    <KYCSuccessModal
      setNextRender={changeSuccessRenderStatus}
      nextRender={nextRender}
    />
  ) : null;

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          {uploadModalRender}
          {deleteModalRender}
          <div>
            <h1 className="page-title">
              {languageStatus
                ? dictionary["WO_EKYC__IDENTITY_VERI_-368816879"]
                : "E-KYC"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>
        <div className="row">
          {documents.length === 0 && !profile.userAccountVerified ? (
            <div className="col-md-12 col-xl-10">
              <div className="card">
                <div className="card-status card-status-left bg-red br-bl-7 br-tl-7" />
                <div className="card-header">
                  <h3 className="card-title">
                    {languageStatus
                      ? dictionary["WO_MORE_INF_-1708316510"]
                      : "Information"}
                  </h3>
                </div>
                <div className="card-body">
                  {verifyEmail.status
                    ? `There is need for an email verification. 
                  ${
                    languageStatus
                      ? dictionary["WO_IF_YOU_DO_NOT_RECEIV_-1320552170"]
                      : ""
                  }`
                    : languageStatus
                    ? dictionary["WO_THERE_ARE_CURRENTLY_445624668"]
                    : "No documents"}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div id="wizard2" className="wizard kyc clearfix">
                {activeMethod === "DOCUMENTS" && documents.length > 0 ? (
                  <div className="steps clearfix">
                    <ul role="tablist">{renderSteps}</ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {activeMethod === "E-PHOTO" && ePhoto.status ? (
                <div className="col-lg-12 col-md-12">
                  <form className="card overflow-hidden">
                    <div className="card-header bg-primary text-white">
                      <h3 className="card-title">Electronic KYC</h3>
                      <div className="card-options">
                        {capturePhoto === "PHOTO" ? (
                          <>{uploadButton}</>
                        ) : (
                          <>{photoButton}</>
                        )}
                      </div>
                    </div>
                    <div className="card-body">
                      {capturePhoto === "PHOTO" ? (
                        <div className="row mb-5 justify-content-center">
                          <KYCWebcam
                            saveImage={submitEKYC}
                            cancel={captureState}
                          />
                        </div>
                      ) : (
                        <div className="row mb-5">
                          <div className="col-lg-12 col-sm-12 mb-4 mt-2 mb-lg-0">
                            <div className="dropify-wrapper">
                              <div className="dropify-message">
                                <span className="file-icon">
                                  <img src={video} alt="Upload" />
                                  <p>
                                    Drag and drop your electronic KYC image file
                                    here or use capture photo button
                                  </p>
                                </span>
                              </div>
                              <input
                                onChange={onChangeFile}
                                type="file"
                                className="dropify"
                                data-bs-height="180"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                ""
              )}
              {activeMethod === "E-VIDEO" && eVideo.status ? (
                <div className="col-lg-12 col-md-12">
                  <form className="card overflow-hidden">
                    <div className="card-header bg-primary text-white">
                      <h3 className="card-title">Electronic KYC</h3>
                      <div className="card-options">
                        {capturePhoto === "PHOTO" ? (
                          <>{uploadButton}</>
                        ) : (
                          <>{videoButton}</>
                        )}
                      </div>
                    </div>
                    <div className="card-body">
                      {capturePhoto === "STREAM" ? (
                        <div className="row mb-5 justify-content-center">
                          <KYCStream
                            saveStream={submitEKYC}
                            cancel={captureState}
                          />
                        </div>
                      ) : (
                        <div className="row mb-5">
                          <div className="col-lg-12 col-sm-12 mb-4 mt-2 mb-lg-0">
                            <div className="dropify-wrapper">
                              <div className="dropify-message">
                                <span className="file-icon">
                                  <img src={video} alt="Upload" />
                                  <p>
                                    Drag and drop your electronic KYC video file
                                    here or use capture video button
                                  </p>
                                </span>
                              </div>
                              <input
                                onChange={onChangeFile}
                                type="file"
                                className="dropify"
                                data-bs-height="180"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                ""
              )}
              {activeMethod === "DOCUMENTS" && documents.length > 0 ? (
                <div className="col-12">
                  <div className="card overflow-hidden">
                    <div className="card-header bg-primary text-white">
                      <h3 className="card-title">
                        {languageStatus
                          ? dictionary["WO_PLEASE_UPLOAD_THE_BE_1913169394"]
                          : "Documents"}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover card-table table-striped table-vcenter table-outline text-nowrap">
                        <thead>
                          <tr>
                            <th scope="col">No.</th>
                            <th scope="col">
                              {languageStatus
                                ? dictionary["WO_DOCUMENT_TYP_-13031371"]
                                : "Type"}
                            </th>
                            <th scope="col">Required</th>
                            <th scope="col">
                              {languageStatus
                                ? dictionary["l_issue_date"]
                                : "Date"}
                            </th>
                            <th className="text-center">
                              {languageStatus
                                ? dictionary["l_status"]
                                : "Status"}
                            </th>
                            <th className="text-center">
                              {languageStatus
                                ? dictionary["WO_ACTION_1788593463"]
                                : "Actions"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item["docTypeCommonName"]}</td>
                              <td>
                                {item["restrictionLevel"] === "BLOCK"
                                  ? "Mandatory"
                                  : "Optional"}
                              </td>
                              <td>{formatDate(item["issueDate"])}</td>
                              <td>
                                {item.status
                                  ? "Ready to upload"
                                  : "File not selected"}
                              </td>
                              <td>
                                {item.status ? (
                                  <>
                                    <>
                                      <i className="fa fa-cloud-upload-alt text-primary pointer" />{" "}
                                      &nbsp;
                                    </>
                                    <>
                                      <i className="fa fa-times text-red pointer" />{" "}
                                      &nbsp;
                                    </>
                                  </>
                                ) : (
                                  <label
                                    onClick={() => onSelectFile(item)}
                                    className="btn btn-primary btn-sm m-1"
                                  >
                                    <i className="fa fa-file-alt me-1" /> Select
                                    File
                                  </label>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Body;
