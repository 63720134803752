import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../../../../components/header/Header";
import HeaderMenu from "../../../../../components/header/HeaderMenu";
import Footer from "../../../../../components/footer/Footer";
import Body from "./Body";
import SphereLoader from "../../../../../components/loaders/SphereLoader";
import { Loading } from "../../../../../utils/helpers/constants";
import { getCountries } from "../../../../../store/actions/country.action";

function ExchangeRateCorridor() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { domainBrand, component, country, profile } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      countryReducer,
      profileReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      country: countryReducer,
      profile: profileReducer,
    })
  );

  const loadState = domainBrand.loading === Loading.FETCHING;

  const exchangeRateId = location?.state?.detail?.rateId;

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="settings"
              active="rates"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              country={country.countries}
              exchangeRateId={exchangeRateId}
              userId={profile?.userData?.id}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
}

export default ExchangeRateCorridor;
