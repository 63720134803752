import React, { useState, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import AddExchange from "./atom/AddExchange";
import DataTable from "../../../../components/dataTable/dataTable";
import { exchangeRateTableConfig } from "../../../../utils/helpers/dataTableConfigs";
import EditRateModal from "../../../../components/modals/EditRateModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Edit } from "../../../../assets/images/svgs/edit.svg";
import { ReactComponent as Trash } from "../../../../assets/images/svgs/trash.svg";
import { BiLinkExternal } from "react-icons/bi";

const Body = ({ domainBrand, currency, rate, userId }) => {
  const history = useHistory();

  const [addNewState, updateState] = useState(false);
  const [getId, setGetId] = useState("");
  const [getName, setGetName] = useState("");
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [data, setData] = useState({});
  const [filterData, setFilterData] = useState("");

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const addNewStatus = (event) => {
    event.preventDefault();
    updateState(!addNewState);
  };

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 5,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-center pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Edit</Tooltip>}
          >
            <Edit
              onClick={() => {
                setShow(true);
                setType("EDIT-RATE-MODAL");
                setGetId(row?.original?.id);
                setGetName(row?.original?.commonName);
                setData({
                  ...data,
                  currencyFrom: row?.original?.currencyFrom,
                  currencyTo: row?.original?.currencyTo,
                });
              }}
            />
          </OverlayTrigger>

          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Delete</Tooltip>}
          >
            <Trash
              onClick={() => {
                setShow(true);
                setType("DELETE-RATE-MODAL");
                setGetId(row?.original?.id);
                setGetName(row?.original?.commonName);
              }}
            />
          </OverlayTrigger>

          <OverlayTrigger
            key={"3"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${`3`}`}>Link to Corridor</Tooltip>}
          >
            <BiLinkExternal
              color={"#6259CA"}
              fontSize={23}
              fill={"#6259CA"}
              onClick={() => {
                history.push({
                  pathname: `/settings/rates/rate-corridor/${row?.original?.id}`,
                  state: {
                    detail: {
                      rateId: row?.original?.id,
                    },
                  },
                });
              }}
            />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...exchangeRateTableConfig, actionColumn];

  const columns = useMemo(() => tableObject, []);

  return (
    <>
      <EditRateModal
        show={show}
        handleClose={() => setShow(false)}
        currency={currency}
        type={type}
        getId={getId}
        rateName={getName}
        data={data}
      />
      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">
                {languageStatus
                  ? dictionary["para_global_exchange_rates"]
                  : "Exchange Rate"}
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  {languageStatus
                    ? dictionary["para_use_this_section_to_edit_exchange_rates"]
                    : "Exchange Rate"}
                </li>
              </ol>
            </div>
            <div className="ms-auto pageheader-btn">
              <NavLink
                to="/request-account"
                className="btn btn-primary btn-icon text-white me-2"
                onClick={addNewStatus}
              >
                <span>
                  <i className="fa fa-wallet" />
                </span>{" "}
                &nbsp;
                {languageStatus
                  ? dictionary["para_add_exchange_rate"]
                  : "Add Exchange Rates"}
              </NavLink>
            </div>
          </div>
          <div className="row row-sm">
            {addNewState && (
              <div className="col-lg-12">
                <div className="card overflow-hidden">
                  <div className="card-header bg-primary text-white">
                    <h3 className="card-title">ADD EXCHANGE RATE RANGE</h3>
                  </div>
                  <AddExchange
                    currency={currency}
                    userId={userId}
                    domainBrand={domainBrand}
                  />
                </div>
              </div>
            )}
            <div className="col-lg-12">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">ALL EXCHANGE RATES</h3>
                </div>
                <div className="card-body">
                  <div className="col-md-6 col-lg-6">
                    <p className="text-muted card-sub-title">
                      Sort and filter table
                    </p>

                    <input
                      type={"text"}
                      className="form-control border px-3"
                      placeholder="Search..."
                      autoComplete=""
                      value={filterData}
                      onChange={(e) => setFilterData(e.target.value)}
                    />
                  </div>

                  <div className="table-responsive mt-5">
                    <DataTable
                      columns={columns}
                      data={rate !== undefined ? rate : []}
                      filterData={filterData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
