import { toast } from "react-hot-toast";
import ActionCreator from "../../utils/helpers/actionCreator";
import { listMyTransactions } from "../../utils/services/bills.service";
import * as MyTransactionConstants from "../constants/mytransactions.constants";
import { expiredTokenCheck } from "./auth.action";

export const fetchMyTransactions = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(MyTransactionConstants.MY_TRANSACTION_REQUEST));
    const { data: responseData } = await listMyTransactions();

    if (responseData.status === "FAILED") {
      throw responseData;
    } else {
      dispatch(
        ActionCreator(
          MyTransactionConstants.MY_TRANSACTION_SUCCESS,
          responseData
        )
      );
    }

    toast.success("transactions fetched successfully.");
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(MyTransactionConstants.MY_TRANSACTION_FAILURE));
  }
};
