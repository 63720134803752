import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Body from "./Body";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { getAMLRules } from "../../../../store/actions/compliance.action";
import { getCountries } from "../../../../store/actions/country.action";
import { Loading } from "../../../../utils/helpers/constants";

const AmlRules = () => {
  const dispatch = useDispatch();
  const { component, compliance, country, domainBrand } = useSelector(
    ({
      componentReducer,
      complianceReducer,
      countryReducer,
      domainBrandReducer,
    }) => ({
      component: componentReducer,
      compliance: complianceReducer,
      country: countryReducer,
      domainBrand: domainBrandReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    compliance.fetchingAML === Loading.FETCHING ||
    country.loadingCountries === Loading.FETCHING;

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
    if (compliance.fetchingAML !== Loading.SUCCESS) dispatch(getAMLRules());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="compliance"
              active="aml"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              rules={compliance}
              countries={country.supportedCountries}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default AmlRules;
