import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Body from "./Body";
import Footer from "../../../../components/footer/Footer";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import {getMerchantProducts, getMerchantServices} from "../../../../store/actions/merchant.action";
import {getMerchantProductsSupportedCountry} from "../../../../store/actions/country.action";
import {Loading} from "../../../../utils/helpers/constants";

const Products = () => {
  const dispatch = useDispatch();
  const {domainBrand, component, country, merchant, profile} = useSelector(
    ({domainBrandReducer, componentReducer, countryReducer, merchantReducer, profileReducer}) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      country: countryReducer,
      merchant: merchantReducer,
      profile: profileReducer,
    }));

  const loadState = domainBrand.loading === Loading.FETCHING || country.loadingCountries === Loading.FETCHING ||
    profile.userProfileLoading === Loading.FETCHING || merchant.loadingServices === Loading.FETCHING || merchant.loadingProducts === Loading.FETCHING;

  useEffect(() => {
    if (profile.originCountry) dispatch(getMerchantProductsSupportedCountry(profile.originCountry));
  }, [profile.originCountry]);

  useEffect(() => {
    if (country.merchantProductCountries.length > 0) dispatch(getMerchantServices(country.merchantProductCountries[0].value));
  }, [country.merchantProductCountries]);

  useEffect(() => {
    if (country.merchantProductCountries.length > 0) {
      const destinationIsoCode3 = country.merchantProductCountries[0].value;
      const payload = {destinationIsoCode3, startNumber: 1, totalNumber: 10};
      dispatch(getMerchantProducts(payload));
    }
  }, [country.merchantProductCountries]);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? <SphereLoader/> :
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"/>
            <Header domainBrand={domainBrand}/>
            <HeaderMenu menu="merchant" active="products" domainBrand={domainBrand}/>
            <Body domainBrand={domainBrand} countries={country.merchantProductCountries} services={merchant.services}
                  products={merchant.products}/>
          </div>
          <Footer domainBrand={domainBrand}/>
        </>
      }
    </div>
  );
}

export default Products;
