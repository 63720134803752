import { fetchSubDomain } from "../services/baseUrl";
import moment from "moment";
import { menuOptions } from "./objectHelpers";
import { configs, statusStyle } from "./constants";
import cookieHelper from "./cookieHelper";

export const getYear = () => {
  const date = new Date();
  return date.getFullYear();
};

export const yearArray = () => {
  let startYearQuery = getYear(),
    yearData = [];
  for (let i = 1990; i <= startYearQuery; i++) {
    yearData[i] = {};
    yearData[i].label = i.toString(10);
    yearData[i].value = i.toString(10);
  }
  return yearData.reverse();
};

export const reorderGraphData = (arr, index, n) => {
  const temp = [...Array(n)];
  for (let i = 0; i < n; i++) temp[index[i]] = arr[i];
  for (let i = 0; i < n; i++) {
    arr[i] = temp[i];
    index[i] = i;
  }
  return arr;
};

export const SUBDOMAIN_CHECK = (url) => {
  const regex = new RegExp(/(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i);
  return !!url.match(regex);
};

export const fetchAppLanguage = () => {
  let cookieLanguage, selectedLanguage;
  cookieLanguage = cookieHelper.get(configs.LANGUAGE_DATA);
  cookieLanguage === null ||
  cookieLanguage === "" ||
  typeof cookieLanguage === "undefined"
    ? (selectedLanguage = {
        id: "en",
        commonName: "ENGLISH",
        label: "ENGLISH",
        value: "en",
      })
    : (selectedLanguage = cookieLanguage);
  return selectedLanguage;
};

export const languageChangeHelper = (value) => {
  cookieHelper.set(configs.LANGUAGE_DATA, value, configs.COOKIE_EXPIRY_PERIOD);
  const subdomainObject = fetchSubDomain();
  const subdomain = subdomainObject.API_SUBDOMAIN;
  return { value, subdomain };
};

export const sortArrayOfObjectsByKey = (key) => {
  let sortOrder = 1;
  if (key[0] === "-") {
    sortOrder = -1;
    key = key.substr(1);
  }

  return (a, b) => {
    if (sortOrder === -1) return b[key].localeCompare(a[key]);
    else return a[key].localeCompare(b[key]);
  };
};

export const fieldsValidator = (entity) => {
  let requiredFields = [],
    visibleFields = [];
  entity.forEach((element) => {
    if (element["mandatory"]) requiredFields.push(element);
    if (element["visible"]) visibleFields.push(element);
  });
  return { requiredFields, visibleFields };
};

export const formatCurrencyDropdown = (payloadData) => {
  return payloadData.map((item) => ({ value: item, label: item }));
};

export const dynamicFieldsDropdown = (array) => {
  return array.map((item) => ({
    value: item["optionValue"],
    label: item["displayValue"],
  }));
};

export const dynamicFieldsFormikGetKeys = (array, values) => {
  let data, fieldsData;
  array.forEach((element) => {
    data = { [element["key"]]: values[element["key"]] };
    fieldsData = { ...fieldsData, ...data };
  });
  return fieldsData;
};

export const formatDocumentSelector = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    status: false,
  }));
};

export const formatPayCentres = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    label: item["name"],
    value: item.id,
  }));
};

export const formatMerchantServices = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    label: item["service"],
    value: item["service_id"],
  }));
};

export const formatComplianceAwaitingDocument = (payloadData) => {
  let array = [...payloadData],
    eVideo = false,
    ePhoto = false,
    verifyEmail = false;
  payloadData.forEach((element) => {
    if (element["actionType"] === "VERIFY_ACCOUNT_VIA_EMAIL") {
      verifyEmail = { status: true };
      array = array.filter(
        (element) => element["actionType"] !== "VERIFY_ACCOUNT_VIA_EMAIL"
      );
    }
    if (element["actionType"] === "VERIFY_ACCOUNT_VIA_PHONE_NUMBER") {
      array = array.filter(
        (element) => element["actionType"] !== "VERIFY_ACCOUNT_VIA_PHONE_NUMBER"
      );
    }
    if (element["actionType"] === "VERIFY_ACCOUNT_VIA_EKYC_FACE_PHOTO") {
      ePhoto = { status: true, idType: element["docIdType"] };
      array = array.filter(
        (element) =>
          element["actionType"] !== "VERIFY_ACCOUNT_VIA_EKYC_FACE_PHOTO"
      );
    }
    if (element["actionType"] === "VERIFY_ACCOUNT_VIA_EKYC_FACE_VIDEO") {
      eVideo = { status: true, idType: element["docIdType"] };
      array = array.filter(
        (element) =>
          element["actionType"] !== "VERIFY_ACCOUNT_VIA_EKYC_FACE_VIDEO"
      );
    }
  });
  const documents = formatDocumentSelector(array);
  return { ePhoto, eVideo, documents: documents, verifyEmail };
};

export const formatDashboardStatement = (payloadData) => {
  let moneyIn = 0,
    moneyOut = 0;
  payloadData.forEach((element) => {
    if (element["ledgerType"] === "WALLET_MONEY_OUT")
      moneyOut = moneyOut + element["principalAmount"];
    if (element["ledgerType"] === "WALLET_MONEY_IN")
      moneyIn = moneyIn + element["principalAmount"];
  });
  return { moneyIn, moneyOut };
};

export const formatQuote = (payloadData) => {
  payloadData.sendAmount = payloadData["sendingAmount"];
  return payloadData;
};

export const formatSupportedWallets = (payload) => {
  const ifWallet = findItemsInArray(
    payload["paymentMethodsMetadata"],
    "type",
    "E_WALLET"
  );
  if (ifWallet.length < 1) return [];
  else return formatAccountsDropdown(ifWallet[0]["supported-payment-accounts"]);
};

export const removeFundingWalletFromWallets = (wallets, wallet) => {
  const newArray = [];
  wallets.forEach((element) => {
    if (element["walletAccountNumber"] !== wallet.accountNumber)
      newArray.push(element);
  });
  return newArray;
};

export const filterSupportedWallets = (wallets, supportedCurrencies) => {
  const newArray = [];

  supportedCurrencies.forEach((element) => {
    wallets.forEach((wallet) => {
      if (wallet["walletAccountCurrency"] === element) newArray.push(wallet);
    });
  });
  return newArray;
};

export const formatStatements = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    credit:
      item["ledgerType"] === "WALLET_MONEY_IN" ? item["principalAmount"] : "",
    debit:
      item["ledgerType"] === "WALLET_MONEY_OUT" ? item["principalAmount"] : "",
  }));
};

export const formatAccountsDropdown = (payloadData) => {
  return payloadData?.map((item) => ({
    ...item,
    value: `${item["walletAccountNumber"]} (${item["walletAccountCurrency"]})`,
    label: `${
      item["accountName"] === null || item["accountName"] === ""
        ? "No Account Name"
        : item["accountName"].toUpperCase()
    } (${item["walletAccountCurrency"]} ${item[
      "walletAccountBalance"
    ].toLocaleString()})`,
  }));
};

export const formatBeneficiariesDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item["beneficiaryCode"],
    label: `${item["firstName"]}${
      item["middleName"] === null || item["middleName"] === ""
        ? ""
        : " " + item["middleName"].charAt(0) + "."
    } ${item["lastName"]}`,
  }));
};

export const formatSupportedBanksDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item["id"],
    label: `${item["name"]}`,
  }));
};

export const formatBeneficiaryBanksDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item["beneficiaryCode"],
    label: `${handleBeneficiaryBankLabel(item)}`,
  }));
};

export const handleBeneficiaryBankLabel = (data) => {
  let label;
  const bankName =
    data.bankName === null || data.bankName === "" ? "" : data.bankName;
  const accountNumber =
    data.accountNumber === null || data.accountNumber === ""
      ? ""
      : data.accountNumber;
  if (!accountNumber && !bankName) label = `IBAN (${data["ibanCode"]})`;
  else if (accountNumber && bankName) label = `${bankName} (${accountNumber})`;
  else
    label = `${bankName ? bankName : "No Bank Name"} (${
      accountNumber ? accountNumber : "No Account Number"
    })`;
  return label;
};

export const formatPayCentreDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item["id"],
    label: item["name"],
  }));
};

export const formatCountriesDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item["iso3Code"],
    label: item["common_name"],
  }));
};

export const formatCurrenciesInCountriesDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item["currencyCode"],
    label: `${item["common_name"]} (${item["currencyCode"]})`,
  }));
};

export const formatCountriesPhoneCode = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item["telephoneCode"],
    label: `${item["common_name"]} (${item["telephoneCode"]})`,
  }));
};

export const formatBillProducts = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item["product_id"],
    label: `${item["product_name"]}`,
  }));
};

export const formatBillOperators = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item["providerId"],
    label: `${item["provider"]}`,
  }));
};

export const formatBillServices = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item["service_id"],
    label: item["service"],
  }));
};

export const formatTopupValues = (payloadData, currency) => {
  return payloadData.map((item) => ({
    value: item,
    label: `${currency} ${item.split(".")[0]}`,
  }));
};

export const formatStringCurrency = (data) => {
  const format =
    typeof data === "undefined" || data === null || data === "" ? "0.00" : data;
  if (format.toString() === "0.00") {
    return format.toLocaleString();
  } else if (format.toString().includes(",")) {
    const newFormat = format.toString().replace(",", "");
    const formattedNumber = parseFloat(newFormat);
    return formattedNumber.toLocaleString();
  } else if (format.toString().includes(".")) {
    const formatValue = truncateDecimalValue(format);
    return formatValue.toLocaleString();
  } else return `${format.toLocaleString()}.00`;
};

export const truncateDecimalValue = (value) => {
  value = value.toString();
  const numberValue = value.split(".")[0];
  const suffixedNumber = value.split(".")[1].substring(0, 2);
  const decimalCount = value.split(".")[1].length;
  const prefixedNumber = Number(numberValue).toLocaleString();
  if (decimalCount > 2) value = `${prefixedNumber}.${suffixedNumber}`;
  else if (decimalCount < 2) value = `${prefixedNumber}.${suffixedNumber}0`;
  else
    value =
      suffixedNumber === "00"
        ? prefixedNumber
        : `${prefixedNumber}.${suffixedNumber}`;
  return value;
};

export const menuFilter = (key, userType, languageStatus, dictionary) => {
  return menuOptions(userType, languageStatus, dictionary).filter(
    (forEach) =>
      JSON.stringify(forEach).toLowerCase().indexOf(key.toLowerCase()) !== -1
  );
};

export const findItemInArray = (array, value) => {
  return array.find((forEach) => forEach.value === value);
};

export const findItemsInArray = (array, key, value) => {
  const newArray = [];
  array.forEach((element) => {
    if (element[key] === value) newArray.push(element);
  });
  return newArray;
};

export const navigatePageTo = (history, pathname) => (state) =>
  history.push({ pathname, state });

export const navigatePageToDetails = (history, pathname) => (state) => {
  history.push({ pathname, data: state });
};

export const navigatePageToDetailsPage = (history, pathname) => (state) => {
  history.push({ pathname, data: state });
};

export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.readAsDataURL(file);
  });
};

export const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    // img.setAttribute("X-MTA-Sec", "true");
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = (error) => reject(error);
    img.src = url;
  });
};

export const getBase64Image = async (img) => {
  const axios = require("axios");
  let image = await axios.get(img, {
    headers: { platform: "fincode", UUID: "200", "X-MTA-Sec": "true" },
    responseType: "arraybuffer",
  });
  let raw = Buffer.from(image.data).toString("base64");
  return "data:" + image.headers["content-type"] + ";base64," + raw;
};

export const fetchMessageSenders = (list) => {
  const newArray = [];
  list.forEach((element) => {
    if (!newArray.includes(element["senderName"]))
      newArray.push(element["senderName"]);
  });
  return newArray;
};

export const fetchMessagesBySender = (sender, messages) => {
  const newArray = [];
  messages.forEach((element) => {
    if (element["senderName"] === sender) newArray.push(element);
  });
  return newArray;
};

export const randomColourPicker = () => {
  const random = Math.floor(Math.random() * 6);
  const newArray = [
    "bg-primary",
    "bg-secondary",
    "bg-azure",
    "bg-info",
    "bg-danger",
    "bg-cyan",
    "bg-purple",
  ];
  return newArray[random];
};

export const addFillToTransactionHistoryStatus = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    fill: statusStyle[item["display_status"]],
  }));
};

export const formatDate = (dateValue) =>
  dateValue ? new Date(dateValue).toLocaleDateString() : "-";

export const checkIfStringHasUnderScore = (str) => {
  if (str !== "" && str !== undefined && str.includes("_")) {
    const label = str.replace(/_/g, " ");
    return label;
  } else {
    return str;
  }
};

export const handleFloat = (t) => {
  const v = t?.receivingAmount;
  const strfy = v.toString();
  if (strfy.indexOf(",") !== -1) {
    return parseFloat(v.replace(/,/g, ""));
  } else {
    return v;
  }
};

export const checkIfDatehasValidType = (dateString) => {
  if (dateString !== "") {
    return moment(dateString).format();
  } else {
    return "";
  }
};
