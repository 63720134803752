import {Helmet} from "react-helmet";

const DefaultMeta = ({title}) => (
  <Helmet>
    <meta charSet="utf-8"/>
    <title>{title}</title>
  </Helmet>
);

export default DefaultMeta;
