import Axios from "./axios.service";

export const getQuote = async (payload) => {
  return Axios({
    method: "POST",
    url: `/web-services/api/v6/services/quote/callQuote`,
    data: payload,
  });
};

export const fetchTransactionHistory = async (payload) => {
  return Axios({
    method: "POST",
    url: `web-services/api/v6/services/transactionmanagement/history-data`,
    data: payload,
  });
};

export const fetchMonthlyTransaction = async (
  customerCode,
  startYearSuffix
) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/transactionmanagement/fetch-monthly-transactions/${customerCode}${startYearSuffix}`,
  });
};

export const fetchTransactionDetails = async (pcn, payableType) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/transactionmanagement/fetch-payable-type/${pcn}/${payableType}`,
  });
};

export const fetchRequiredBankFields = async (path) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/bankmanagement/required-bank-fields/${path}`,
  });
};

export const fetchSupportedBanks = async (countryCode) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/bankmanagement/list-supported-banks-iso3/${countryCode}`,
  });
};

export const createTransaction = async (
  {
    senderCustomerCode,
    recipientCustomerCode,
    amount,
    sendAmount,
    receivingCurrency,
    originCountry,
    receivingCountry,
    creditParty,
    securityQuestion,
    securityAnswer,
    sendingCurrency,
    transactionReference,
    sourceOfFunds,
    remittancePurpose,
    type,
    selectedMobileOperator,
  },
  password
) => {
  const requestPayload = {
    type,
    creditParty,
    securityQuestion,
    securityAnswer,
    transactionReference,
    amount,
    sendAmount,
    receivingCurrency,
    sendCurrency: sendingCurrency,
    inclussive: true,
    transactionProcessingMode: "NONE_AGGREGATION",
    descriptionText: "",
    preExistingSenderCustomerCode: senderCustomerCode,
    preExistingRecipientCustomerCode: recipientCustomerCode,
    internationalTransferInformation: {
      originCountry,
      receivingCountry,
      sourceOfFunds: sourceOfFunds,
      remittancePurpose: remittancePurpose,
      deliveryMethod: "directtoaccount",
    },
    selectedMobileOperator,
  };

  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/transactionmanagement/create-transaction",
    data: requestPayload,
    headers: { password: password },
  });
};

export const initiateFundWalletPayment = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/walletmanagement/initatefundmywalletaccount",
    data: payload,
  });
};

export const bookWalletToWalletPayment = async (payload) => {
  return Axios({
    method: "POST",
    url: "web-services/api/v6/services/walletmanagement/book-wallet-to-wallet-transafer",
    data: payload,
  });
};

export const billsPayment = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/billpayment/orderproduct",
    data: payload,
  });
};

export const airtimeTopup = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/transactionmanagement/orderairtime",
    data: payload,
  });
};

export const makePaymentFromWallet = async (payload) => {
  return Axios({
    method: "POST",
    url: `/web-services/api/v6/services/paymentmanagement/makepaymentfromwalletaccount`,
    data: payload,
  });
};

export const cancelTransaction = async (payload) => {
  return Axios({
    method: "POST",
    url: `/web-services/api/v6/services/transactionmanagement/cancel-payable-type`,
    data: payload,
  });
};

export const fetchSupportedPaymentMethods = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/paymentmanagement/supported-payment-methods",
    data: payload,
  });
};

export const validatePreTopup = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/transactionmanagement/pre-topup",
    data: payload,
  });
};

export const fetchCountryServices = async (countryCode) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/billpayment/fetchservicesbycountry1/${countryCode}`,
  });
};

export const fetchServiceOperators = async (countryCode, serviceId) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/billpayment/fetchoperatorsbycountry2/${countryCode}/${serviceId}`,
  });
};

export const fetchProductsByOperator = async (
  countryCode,
  serviceId,
  operatorId
) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/billpayment/fetchcountrysproducts3/${countryCode}/${serviceId}/${operatorId}`,
  });
};

export const lockTransaction = async (pcn, query) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/transactionmanagement/locktransactionforpayout/${pcn}${query}`,
  });
};

export const listPayoutCentres = async () => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/branches/list-paycenters-by-type/MONEY_TRANSFER_PAY_IN`,
  });
};

export const confirmPayout = async (payload, password) => {
  return Axios({
    method: "POST",
    url: `web-services/api/v6/services/transactionmanagement/payouttransaction`,
    headers: { password: password },
    data: payload,
  });
};
