import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-hot-toast";
import moment from "moment";

import Table from "./Table";
import CustomDatePicker from "../../../../components/datePickers/CustomDatePicker";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import TransactionSummary from "../../home/atom/customer/TransactionSummary";
import pdfGeneratorHistory from "../../../../utils/helpers/pdfGeneratorHistory";
import { fetchTransactionHistory } from "../../../../utils/services/transfer.service";
import {
  addFillToTransactionHistoryStatus,
  formatStringCurrency,
  reorderGraphData,
} from "../../../../utils/helpers/logicHelper";
import {
  transactionDataMap,
  transactionMethodsMap,
} from "../../../../utils/helpers/mappers";

import { Loading } from "../../../../utils/helpers/constants";
import downloadSvg from "../../../../assets/images/svgs/download.svg";

const Body = ({ domainBrand, profile }) => {
  const date = new Date();
  const priorDate = new Date().setDate(date.getDate() - 90);
  const monthsAgo = new Date(priorDate);
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [startDate, getStartDate] = useState(monthsAgo);
  const [endDate, getEndDate] = useState(date);
  const [loadingHistory, updateLoadingHistory] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const [transactionInfo, updateTransactionInfo] = useState([]);
  const [transactionData, updateTransactionData] = useState([]);

  useEffect(() => {
    if (startDate && endDate) {
      const fromDate = moment(startDate).format();
      const toDate = moment(endDate).endOf("day").format();
      dispatchTransferHistory({ fromDate, toDate }).catch((err) =>
        console.log(err)
      );
    }
  }, [startDate, endDate]);

  const dispatchTransferHistory = async (historyObject) => {
    updateLoadingHistory(true);
    const { data: responseData } = await fetchTransactionHistory(historyObject);
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch transaction history, try again`);
    else {
      updateTransactionData(
        addFillToTransactionHistoryStatus(
          responseData.data["transactionHistory"]
        )
      );
      filterChart(responseData.data);
    }
    updateLoadingHistory(false);
  };

  const reloadHistory = () => {
    const historyObject = {
      fromDate: moment(startDate).format(),
      toDate: moment(endDate).endOf("day").format(),
    };
    dispatchTransferHistory(historyObject).catch((err) => console.trace(err));
  };

  const filterChart = (data) => {
    let chartData = [];
    delete data["transactionHistory"];
    delete data["voidCount"];
    delete data["heldCount"];
    delete data["failedCount"];
    delete data["pendingCount"];
    delete data["expiredCount"];
    delete data["totalCount"];
    Object.keys(data).forEach((key) =>
      chartData.push({
        name: transactionDataMap[key].label,
        value: data[key],
        fill: transactionDataMap[key].fill,
      })
    );
    updateTransactionInfo(
      reorderGraphData(chartData, [0, 3, 1, 4, 2], chartData.length)
    );
  };

  const downloadPDF = (event) => {
    event.preventDefault();
    const fromDate = moment(startDate).format("MMM Do yyyy");
    const toDate = moment(endDate).endOf("day").format("MMM Do yyyy");
    const data = { profile, fromDate, toDate };
    const pdfData = formatPDFData(transactionData);
    pdfGeneratorHistory(data, pdfData, domainBrand).catch((err) =>
      console.log(err)
    );
  };

  const formatPDFData = (data) => {
    return data.map(
      ({
        createdDate,
        transactionType,
        receiver,
        pcn,
        principalCurrencyCode,
        principalAmount,
        display_status,
      }) => [
        moment(new Date(createdDate)).format("DD/MM/YYYY hh:mmA") || "",
        {
          text: `${
            dictionary[transactionMethodsMap[transactionType ?? "OTHER"].title]
          } payment to ${receiver}`,
        },
        pcn || "",
        display_status || "",
        {
          text:
            `${principalCurrencyCode} ${formatStringCurrency(
              principalAmount
            )}` || "0.00",
          alignment: "center",
        },
      ]
    );
  };

  const handleCurrency = (e) => {
    const { value } = e.target;

    if (value !== "") {
      const historyObject = {
        selectedCurrency: value,
      };
      dispatchTransferHistory(historyObject).catch((err) => console.trace(err));
    }
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus
                ? dictionary["WO_TRANSFER_HISTOR_57573459"]
                : "Transfer History"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn">
            <NavLink
              onClick={downloadPDF}
              to=""
              className="btn btn-primary btn-icon text-white me-2"
            >
              <span>
                <img src={downloadSvg} alt="downloadSvg" />
                &nbsp;
              </span>
              {languageStatus
                ? dictionary["para_download_statements"]
                : "Download Statements"}
            </NavLink>
            <NavLink
              to="/transfer"
              className="btn btn-primary btn-icon text-primary me-2"
            >
              <span>
                <i className="fa fa-exchange-alt" /> &nbsp;
              </span>
              {languageStatus
                ? dictionary["WO_TRANSFER_FUND_1805465128"]
                : "Transfer Funds"}
            </NavLink>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  <span className="">
                    {languageStatus
                      ? dictionary["l_transactions_summary_reports"]
                      : "Transaction summary"}
                  </span>
                </h3>
              </div>
              <div className="card-body pb-0 pt-4">
                <TransactionSummary graphData={transactionInfo} />
              </div>
            </div>
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  <span className="">
                    {languageStatus
                      ? dictionary["WO_TRANSFER_HISTOR_57573459"]
                      : "Transfer History"}
                  </span>
                </h3>
              </div>
              <div className="card-body">
                <div className="needs-validation">
                  <div className="row form-row">
                    <div className="col-md-6 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["l_search_transactions"]
                          : "Search"}
                      </label>
                      <input
                        type="search"
                        value={filterInput}
                        onChange={(event) => setFilterInput(event.target.value)}
                        className="form-control form-control-sm"
                        placeholder="Search..."
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label className="d-flex flex-column">
                        {/* {languageStatus
                          ? dictionary["l_start_date"]
                          : "Start Date"} */}
                        <span>Currency</span>
                        <select
                          onChange={handleCurrency}
                          className="pb-2 pt-2 px-1 mt-2"
                          style={{
                            borderRadius: "5px",
                            background: "#fff",
                            border: "1px solid #cccccc",
                            outline: "none",
                            boxShadow: "none",
                          }}
                        >
                          <option value="" disabled selected hidden>
                            Select...
                          </option>
                          <option value={"USD"}>US Dollars (USD)</option>
                          <option value={"GBP"}>Pounds Sterling (GBP)</option>
                          <option value={"NGN"}>Nigerian Naira (NGN)</option>
                          <option value={"EUR"}>Euros (EUR)</option>
                        </select>
                      </label>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["l_start_date"]
                          : "Start Date"}
                      </label>
                      <div>
                        <CustomDatePicker
                          getDate={getStartDate}
                          showYearDropdown
                          selectDropdownMode
                          withPortal
                          setMinDate={new Date("1/1/1998")}
                          setMaxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label>
                        {languageStatus ? dictionary["l_end_date"] : "End Date"}
                      </label>
                      <div>
                        <CustomDatePicker
                          getDate={getEndDate}
                          showYearDropdown
                          selectDropdownMode
                          withPortal
                          setMinDate={new Date("1/1/1998")}
                          setMaxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive mt-3">
                  {loadingHistory ? (
                    <SphereLoader />
                  ) : (
                    <Table
                      domainBrand={domainBrand}
                      data={transactionData}
                      filterInput={filterInput}
                      reload={reloadHistory}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
