import React, { useState, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { BsFillPlusCircleFill } from "react-icons/bs";
import { searchAndFindcustomer } from "../../../../utils/services/cashierbeneficiary.service";

import DataTable from "../../../../components/dataTable/dataTable";
import { customerBeneficiaryTableConfig } from "../../../../utils/helpers/dataTableConfigs";
import CreateCustomer from "./atom/CreateCustomer";

import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";

import { ReactComponent as Edit } from "../../../../assets/images/svgs/edit.svg";

function Body({ domainBrand, currency, countries, component }) {
  const history = useHistory();

  const [addNewState, updateState] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const addNewStatus = (event) => {
    event.preventDefault();
    updateState(!addNewState);
  };

  const handleSearch = async (value) => {
    setIsLoading(true);

    let dataArray = [];

    if (value === "emailAddress") {
      const payload = {
        emailAddress: filterValue,
      };
      const res = await searchAndFindcustomer(payload);
      if (res.status === 200) {
        dataArray[0] = res?.data?.data;
        setResult(dataArray);
        setFilterValue("");
        setIsLoading(false);
      }
    } else if (value === "customerCode") {
      const payload = {
        customerCode: filterValue,
      };
      const res = await searchAndFindcustomer(payload);
      if (res.status === 200) {
        dataArray[0] = res?.data?.data;
        setResult(dataArray);
        setFilterValue("");
        setIsLoading(false);
      }
    } else if (value === "findbyAccountNumber") {
      const payload = {
        findbyAccountNumber: filterValue,
      };
      const res = await searchAndFindcustomer(payload);
      if (res.status === 200) {
        dataArray[0] = res?.data?.data;
        setResult(dataArray);
        setFilterValue("");
        setIsLoading(false);
      }
    } else if (value === "phoneNumber") {
      const payload = {
        phoneNumber: filterValue,
      };
      const res = await searchAndFindcustomer(payload);
      if (res.status === 200) {
        dataArray[0] = res?.data?.data;
        setResult(dataArray);
        setFilterValue("");
        setIsLoading(false);
      }
    } else {
      setResult([]);
      setIsLoading(false);
    }
  };

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 5,
    accessor: "actions",
    Cell: ({ row }) => {
      const customerObject = row?.original;
      return (
        <div className="text-center pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${`1`}`}>Edit</Tooltip>}
          >
            <Edit
              className="text-center mx-auto"
              onClick={() =>
                history.push({
                  pathname: `/customers-beneficiaries/${row?.original?.code}`,
                  state: {
                    domainBrand: domainBrand,
                    component: component,
                    currency: currency,
                    countries: countries,
                    code: row?.original?.code,
                    customerObject: customerObject,
                  },
                })
              }
            />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...customerBeneficiaryTableConfig, actionColumn];

  const columns = useMemo(() => tableObject, []);

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus
                ? dictionary["m_general2"]
                : "Customers/Beneficiaries"}
            </h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                {languageStatus
                  ? dictionary["para_senders_receivers_desc"]
                  : `Here you can create customers and their beneficiaries. To create customer simply press "+Add new customer". In order to create a beneficiary, select a customer and press "+Add new beneficiary".`}
              </li>
            </ol>
          </div>
          <div className="ms-auto pageheader-btn">
            <NavLink
              to="/request-account"
              className="btn btn-primary btn-icon text-white me-2 fw-bold text-uppercase"
              onClick={addNewStatus}
            >
              {/* <span>
                <i className="fa fa-wallet" />
              </span>{" "} */}
              <BsFillPlusCircleFill
                color={"#FFF"}
                fontSize={22}
                fill={"#FFF"}
              />
              &nbsp;
              <span className="align-middle">
                {languageStatus
                  ? dictionary["button_add_new_sender"]
                  : "ADD NEW CUSTOMER"}
              </span>
            </NavLink>
          </div>
        </div>

        <div className="row row-sm">
          {addNewState && (
            <div className="col-lg-12">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">
                    {languageStatus
                      ? dictionary["WO_CREATE_BENEFICIAR_-246469314"]
                      : " Create Customer"}
                  </h3>
                </div>
                <CreateCustomer
                  domainBrand={domainBrand}
                  languageStatus={languageStatus}
                  dictionary={dictionary}
                  currency={currency}
                  countries={countries}
                  controlView={false}
                />
              </div>
            </div>
          )}

          <div className="col-lg-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">CUSTOMERS</h3>
              </div>
              <div className="card-body">
                <div className="col-md-6 col-lg-6">
                  <p className="text-muted card-sub-title w-100">
                    {languageStatus
                      ? dictionary["WO_TO_FIND_SENDER_TYPE_-1312245638"]
                      : `To find sender, type in the sender's name or phone number or
                    email address`}
                  </p>
                </div>
                <div className="col-md-7 col-lg-7">
                  <div className="d-flex flex-row align-items-center w-full">
                    <select
                      className="py-2 px-2 me-1 select"
                      value={selectValue}
                      onChange={(e) => setSelectValue(e.target.value)}
                      name="select-value"
                      id="select-value"
                      style={{ width: "45%" }}
                    >
                      <option value="" disabled selected hidden>
                        Select...
                      </option>
                      <option value={"phoneNumber"}>Phone</option>
                      <option value={"findbyAccountNumber"} className="px-2">
                        Account No
                      </option>
                      <option value={"emailAddress"}>Email</option>
                      <option value={"customerCode"}>Code</option>
                    </select>

                    <input
                      type={"text"}
                      className="form-control border px-3"
                      placeholder="Search..."
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                    />

                    <button
                      className="btn btn-primary width-25 fw-bold ms-1"
                      type="button"
                      onClick={() => handleSearch(selectValue)}
                    >
                      {isLoading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : (
                        "Search"
                      )}
                    </button>
                  </div>
                </div>

                <div className="table-responsive mt-5">
                  <DataTable columns={columns} data={result} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
