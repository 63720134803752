import React, { useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "../../../../components/dataTable/dataTable";
import { myTransactionsDataTableConfig } from "../../../../utils/helpers/dataTableConfigs";

function Body({ domainBrand, transactions = [] }) {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const [filterData, setFilterData] = useState("");

  const myTransactionsTableConfig = myTransactionsDataTableConfig(
    languageStatus,
    dictionary
  );

  const actionColumn = {
    Header: () => {
      return (
        <span className="text-center fw-bold">
          {languageStatus ? dictionary["l_action"] : "Action"}
        </span>
      );
    },
    columnId: 10,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-center pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={
              <Tooltip id={`tooltip-${``}`}>View Transaction Details</Tooltip>
            }
          >
            <i
              className="fa fa-eye"
              style={{ width: "70px", color: "#6259CA" }}
            />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...myTransactionsTableConfig, actionColumn];
  const columns = useMemo(() => tableObject, []);

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {dictionary ? dictionary["l_my_transactions"] : "My Transactions"}
            </h1>
          </div>
        </div>
        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title fw-bold">
                  {languageStatus
                    ? dictionary["l_transactions"]
                    : "Transactions"}
                </h3>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <input
                      className="form-control"
                      type={"text"}
                      placeholder="Search..."
                      value={filterData}
                      onChange={(e) => setFilterData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={transactions}
                    filterData={filterData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
