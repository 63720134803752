import Axios from "../services/axios.service";

export const searchAndFindcustomer = (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/walletmanagement/walletaccountinfolookup",
    data: payload,
  });
};

export const addNewCustomerBeneficiary = (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/usermanagement/new-beneficiary",
    data: payload,
  });
};
