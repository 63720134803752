import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { walletPayment } from "../../store/actions/transfer.action";

function OtpModal({ show, handleClose, paymentData, activeData }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const navigateToTransferDetails = () =>
    history.push({
      pathname: "/transfer/details",
      state: { data: activeData },
    });

  const firstInput = React.useRef();
  const secondInput = React.useRef();
  const thirdInput = React.useRef();
  const fourthInput = React.useRef();
  const fifthInput = React.useRef();
  const sixthInput = React.useRef();

  const [pin, setPin] = React.useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });

  const handlePayment = async () => {
    const otpDigits =
      pin.first + pin.second + pin.third + pin.fourth + pin.fifth + pin.sixth;
    const data = { ...paymentData, otp: otpDigits };
    await dispatch(walletPayment(data, navigateToTransferDetails));
  };

  return (
    <Modal show={show} backdrop="static" keyboard={false} onHide={handleClose}>
      <Modal.Header closeButton={false}>
        <Modal.Title className="fw-bold">Enter OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="fw-bold text-center pb-3">
          Kindly Enter the OTP sent to your sms inbox
        </h5>
        <div className="container">
          <div className="row text-center">
            <div className="col-md-6 col-lg-6 col-sm-6 mx-auto">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <input
                  className="form-controll otp-pin-input-bar"
                  value={pin.first}
                  onChange={(e) => {
                    setPin({ ...pin, first: e.target.value });
                    e.target.value && secondInput.current?.focus();
                  }}
                  maxLength={1}
                  ref={firstInput}
                />

                <input
                  className="form-controll otp-pin-input-bar"
                  value={pin.second}
                  onChange={(e) => {
                    setPin({ ...pin, second: e.target.value });
                    e.target.value
                      ? thirdInput.current?.focus()
                      : firstInput.current?.focus();
                  }}
                  maxLength={1}
                  ref={secondInput}
                />

                <input
                  className="form-controll otp-pin-input-bar"
                  value={pin.third}
                  onChange={(e) => {
                    setPin({ ...pin, third: e.target.value });
                    e.target.value
                      ? fourthInput.current?.focus()
                      : secondInput.current?.focus();
                  }}
                  maxLength={1}
                  ref={thirdInput}
                />

                <input
                  className="form-controll otp-pin-input-bar"
                  value={pin.fourth}
                  onChange={(e) => {
                    setPin({ ...pin, fourth: e.target.value });
                    e.target.value
                      ? fifthInput.current?.focus()
                      : thirdInput.current?.focus();
                  }}
                  maxLength={1}
                  ref={fourthInput}
                />

                <input
                  className="form-controll otp-pin-input-bar"
                  value={pin.fifth}
                  onChange={(e) => {
                    setPin({ ...pin, fifth: e.target.value });
                    e.target.value
                      ? sixthInput.current?.focus()
                      : fourthInput.current?.focus();
                  }}
                  maxLength={1}
                  ref={fifthInput}
                />

                <input
                  className="form-controll otp-pin-input-bar"
                  value={pin.sixth}
                  onChange={(e) => {
                    setPin({ ...pin, sixth: e.target.value });
                    !e.target.value && fifthInput.current?.focus();
                  }}
                  maxLength={1}
                  ref={sixthInput}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={handlePayment}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OtpModal;

OtpModal.defaultProps = {
  handleClose: () => {},
  handlePayment: () => {},
};

OtpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
