import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Body from "./Body";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { toggleNav } from "../../../../store/actions/component.action";
import { getAllCustomers } from "../../../../store/actions/users.action";
import { Loading } from "../../../../utils/helpers/constants";

const Customers = () => {
  const dispatch = useDispatch();
  const { domainBrand, component, users } = useSelector(
    ({ domainBrandReducer, componentReducer, usersReducer }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      users: usersReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    users.loadingCustomers === Loading.FETCHING;

  useEffect(async () => {
    dispatch(toggleNav(false));
    // dispatch(getAllCustomers("?page=1&size=10"));
    dispatch(getAllCustomers("NONE"));
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="crm"
              active="customers"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              customers={users?.customers?.customerList}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default Customers;
