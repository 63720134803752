import * as AuthConstants from "../constants/auth.constants";
import {Loading} from "../../utils/helpers/constants";

const initialState = {
  isAuthenticated: false,
  awaitingToken: false,
  loginStatus: "",
  forgotPasswordStatus: "",
  userType: "",
  awaitingComplianceAction: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthConstants.LOGIN_REQUEST:
      return {...state, loginStatus: Loading.FETCHING};

    case AuthConstants.AWAITING_TOKEN:
      return {...state, loginStatus: Loading.SUCCESS, awaitingToken: true};

    case AuthConstants.DISABLED_AWAITING_TOKEN:
      return {...state, awaitingToken: false};

    case AuthConstants.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loginStatus: Loading.SUCCESS,
        authBody: action.payload,
        awaitingComplianceAction: action.payload.awaitingComplianceAction,
        userType: action.payload.userType,
        userAccountVerified: action.payload["profileDetailsResponseDTO"]["userAccountVerified"],
      };

    case AuthConstants.LOGIN_FAILURE:
      return {...state, loginStatus: Loading.ERROR};

    case AuthConstants.VALIDATE_OTP_REQUEST:
      return {...state, loginStatus: Loading.FETCHING};

    case AuthConstants.VALIDATE_OTP_SUCCESS:
      return {...state, loginStatus: Loading.SUCCESS};

    case AuthConstants.VALIDATE_OTP_FAILURE:
      return {...state, loginStatus: Loading.ERROR};

    case AuthConstants.SET_LOGGED_IN_USER:
      return {...state, isAuthenticated: true};

    default:
      return state;
  }
};

export default authReducer;
