import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";

function CustomerProductModals({
  show,
  handleClose,
  handleTrigger,
  prodName,
  loading,
}) {
  return (
    <Modal show={show} backdrop="static" keyboard={false} size="md">
      <Modal.Header closeButton={false}>
        <Modal.Title className="fw-bold">{prodName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-2 fw-bold">
          By clicking yes your request for this product will be sent to the
          administrator for approval
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={handleTrigger}>
          {"Yes"}
          {loading === "FETCHING" && (
            <>
              &nbsp;
              <i className="fa fa-spinner fa-spin" />
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomerProductModals;

CustomerProductModals.defaultProps = {
  handleClose: () => {},
  handleTrigger: () => {},
};

CustomerProductModals.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleTrigger: PropTypes.func.isRequired,
  prodName: PropTypes.string.isRequired,
  loading: PropTypes.string.isRequired,
};
