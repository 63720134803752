import Axios from "./axios.service";

export const listEmployees = () => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/admin/usermanagement/list-employees`,
  });
};

export const editEmployee = (payload) => {
  return Axios({
    method: "PUT",
    url: `web-services/api/v6/services/admin/usermanagement/edit-employee/${payload.userId}`,
    data: payload,
  });
};

export const suspendEmployee = (userId) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/admin/usermanagement/deactivate-user-account/${userId}`,
  });
};

export const activateEmployee = (userId) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/admin/usermanagement/activate-user-account/${userId}`,
  });
};

export const addEmployee = (payload) => {
  return Axios({
    method: "POST",
    url: `web-services/api/v6/services/admin/usermanagement/add-employee`,
    data: payload,
  });
};

export const addRole = (payload) => {
  return Axios({
    method: "PUT",
    url: `web-services/api/v6/services/admin/usermanagement/add-role`,
    data: payload,
  });
};
