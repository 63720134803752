import {useState} from "react";
import {NavLink} from "react-router-dom";
import {toast} from "react-hot-toast";

import Messages from "./atom/Messages";
import Compose from "./atom/Compose";
import Inbox from "./atom/Inbox";
import {fetchMessagesBySender, fetchMessageSenders, randomColourPicker} from "../../../../utils/helpers/logicHelper";
import {Loading, messageStates} from "../../../../utils/helpers/constants";
import ProfileService from "../../../../utils/services/profile.service";

const Body = ({domainBrand, initMessages}) => {

  const [mode, updateMode] = useState(messageStates.ALL);
  const [messageType, setMessageType] = useState(true);
  const [inboxState, setInboxState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(initMessages);
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(typeof dictionary === "undefined" || dictionary === null);
  const senderList = fetchMessageSenders(initMessages);
  const switchDOM = (state) => {
    setMessageType(state);
    setInboxState(false);
  }

  const setMode = async (event, type) => {
    event.preventDefault();
    setInboxState(false);
    setLoading(true);
    updateMode(type);
    const {data: responseData} = await ProfileService.fetchNotifications(type);
    if (responseData.status !== Loading.SUCCESS) toast.error(`Could not fetch support messages, try again`);
    else {
      setMessages(responseData.data["notificationsDTOs"]);
      setLoading(false);
    }
  }

  const getMessageBySender = (event, item) => {
    event.preventDefault();
    const newMessages = fetchMessagesBySender(item, initMessages);
    setMessages(newMessages);
  }

  const viewMessage = item => {
    setMessage(item);
    setInboxState(true);
  }

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">{languageStatus ? dictionary["l_support"] : "Customer Profile"}</h1>
          </div>
          <div className="ms-auto pageheader-btn"/>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-4 col-xl-3">
            <div className="card">
              <div className="list-group list-group-transparent mb-0 mail-inbox pb-3">
                <div className="mt-4 mb-4 ms-4 me-4 text-center">
                  <div onClick={() => switchDOM(!messageType)} className="btn btn-primary btn-lg d-grid">
                    {messageType ? (languageStatus ? dictionary["para_compose_mail"] : "Compose") :
                      (languageStatus ? dictionary["para_inbox"] : "Inbox")
                    }
                  </div>
                </div>
                <NavLink onClick={event => setMode(event, messageStates.ALL)} to="" exact
                         className={`list-group-item d-flex align-items-center ms-4 me-4 
                         ${mode === messageStates.ALL ? "active" : ""}`}>
                  <span className="icons"><i className="fa fa-inbox"/></span>
                  {languageStatus ? dictionary["para_inbox"] : "Inbox"}
                  <span className="ms-auto badge bg-success">{messages.length}</span>
                </NavLink>
                <NavLink onClick={event => setMode(event, messageStates.UNREAD)} to="" exact
                         className={`list-group-item d-flex align-items-center ms-4 me-4 
                         ${mode === messageStates.UNREAD ? "active" : ""}`}>
                  <span className="icons"><i className="fa fa-envelope"/></span>
                  Unread mails
                </NavLink>
                <NavLink onClick={event => setMode(event, messageStates.READ)} to="" exact
                         className={`list-group-item d-flex align-items-center ms-4 me-4 
                         ${mode === messageStates.READ ? "active" : ""}`}>
                  <span className="icons"><i className="fa fa-envelope-open-text"/></span>
                  Read Mails
                </NavLink>
                <NavLink onClick={event => setMode(event, messageStates.SENT)} to="" exact
                         className={`list-group-item d-flex align-items-center ms-4 me-4 
                         ${mode === messageStates.SENT ? "active" : ""}`}>
                  <span className="icons"><i className="fa fa-paper-plane"/></span>
                  {languageStatus ? dictionary["para_sent_mail"] : "Sent Mail"}
                </NavLink>
              </div>
              <div className="card-body border-top">
                <div className="list-group list-group-transparent mb-0 mail-inbox ms-4 me-4">
                  {senderList.map((item, index) => (
                    <NavLink key={index} to="" exact onClick={event => getMessageBySender(event, item)}
                             className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                      <span className={`w-3 h-3 brround ${randomColourPicker()} me-2`}/> {item}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {inboxState ?
            <Inbox domainBrand={domainBrand} message={message} inboxState={setInboxState}
                   compose={setMessageType}/> : messageType ?
              <Messages domainBrand={domainBrand} messages={messages} view={viewMessage} mode={mode} setMode={setMode}
                        loading={loading}/> :
              <Compose domainBrand={domainBrand} compose={setMessageType} switchDom={switchDOM}/>
          }
        </div>
      </div>
    </div>
  );
}

export default Body;
