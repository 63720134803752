import ActionCreator from "../../utils/helpers/actionCreator";
import * as ComplianceConstants from "../constants/compliance.constants";
import {
  fetchAMLRules,
  fetchComplianceRequirements,
  fetchKYCRules,
  fetchMandatoryFields,
  createAmlRules,
} from "../../utils/services/compliance.service";
import { expiredTokenCheck } from "./auth.action";
import { formatComplianceAwaitingDocument } from "../../utils/helpers/logicHelper";

export const getKycRules = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(ComplianceConstants.GET_KYC_RULES_REQUEST));
    const { data: responseData } = await fetchKYCRules();

    if (responseData.status === "FAILED") throw responseData;
    dispatch(
      ActionCreator(
        ComplianceConstants.GET_KYC_RULES_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(ComplianceConstants.GET_KYC_RULES_FAILURE));
  }
};

export const getAMLRules = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(ComplianceConstants.GET_AML_RULES_REQUEST));
    const { data: responseData } = await fetchAMLRules();

    if (responseData.status === "FAILED") throw responseData;
    dispatch(
      ActionCreator(
        ComplianceConstants.GET_AML_RULES_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(ComplianceConstants.GET_AML_RULES_FAILURE));
  }
};

export const addAMLRules = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(ComplianceConstants.CREATE_AML_RULES_REQUEST));
    const { data: responseData } = await createAmlRules(payload);

    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        ComplianceConstants.CREATE_AML_RULES_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(ComplianceConstants.CREATE_AML_RULES_FAILURE));
  }
};

export const fetchComplianceRequiredFields = (type) => async (dispatch) => {
  try {
    dispatch(
      ActionCreator(ComplianceConstants.GET_REQUIRED_COMPLIANCE_FIELDS_REQUEST)
    );
    const { data: responseData } = await fetchMandatoryFields(type);

    if (responseData.status === "FAILED") throw responseData;
    const fieldsData = responseData.data;
    fieldsData.type = type;
    dispatch(
      ActionCreator(
        ComplianceConstants.GET_REQUIRED_COMPLIANCE_FIELDS_SUCCESS,
        fieldsData
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(
      ActionCreator(ComplianceConstants.GET_REQUIRED_COMPLIANCE_FIELDS_FAILURE)
    );
  }
};

export const awaitingComplianceRequirements = () => async (dispatch) => {
  try {
    dispatch(
      ActionCreator(ComplianceConstants.GET_AWAITING_REQUIREMENTS_REQUEST)
    );
    const { data: responseData } = await fetchComplianceRequirements();

    if (responseData.status === "FAILED") throw responseData;

    const formattedCompliance = formatComplianceAwaitingDocument(
      responseData.data["awaitingComplianceActions"]
    );
    dispatch(
      ActionCreator(
        ComplianceConstants.GET_AWAITING_REQUIREMENTS_SUCCESS,
        formattedCompliance
      )
    );
  } catch (error) {
    dispatch(
      ActionCreator(ComplianceConstants.GET_AWAITING_REQUIREMENTS_FAILURE)
    );
  }
};
