import { Form, Formik } from "formik";
import React from "react";
import Select from "react-select";
import {
  employeeInitialValues,
  newEmployeeSchema,
} from "../../../../../../../utils/helpers/validationHelpers";

function Body({ domainBrand, countries }) {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const menuPortalTarget = document.getElementById("root");

  const phoneCodes = countries["supportedPhoneCodes"];

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {dictionary
                ? dictionary["WO_ADD_NEW_EMPLOYE_-831459411"]
                : "Add New Employee"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>

        <div className="row row-sm">
          <Formik
            initialValues={{ ...employeeInitialValues }}
            validationSchema={newEmployeeSchema}
            onSubmit={() => []}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              setFieldValue,
              touched,
            }) => {
              const disablePhoneInput =
                typeof values.phoneCountryCode.value === "undefined" ||
                values.phoneCountryCode.value === "";

              return (
                <Form>
                  <div className="col-lg-12">
                    <h6 className="text-center fw-bold card-title text-uppercase">
                      Cashier Information
                    </h6>
                    <hr
                      style={{ border: "1px solid #d5d5d5", height: "0px" }}
                    />

                    <div className="form-row">
                      <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary
                            ? dictionary["para_first_name"]
                            : "First Name"}
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          value={values.firstName}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>

                      <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary ? dictionary["l_last_name"] : "Last Name"}
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          value={values.lastName}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary ? dictionary["l_email"] : "Email"}
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={values.email}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>

                      <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary
                            ? dictionary["para_province"]
                            : "Province"}
                        </label>
                        <input
                          type="text"
                          name="province"
                          id="province"
                          value={values.province}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary
                            ? dictionary["para_address1"]
                            : "Address Line 1"}
                        </label>
                        <input
                          type="text"
                          name="addressLine1"
                          id="addressLine1"
                          value={values.addressLine1}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>

                      <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary
                            ? dictionary["para_address_line_2"]
                            : "Address Line 2"}
                        </label>
                        <input
                          type="text"
                          name="addressLine2"
                          id="addressLine2"
                          value={values.addressLine2}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary ? dictionary["para_city"] : "City"}
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          value={values.city}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>

                      <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary
                            ? dictionary["para_postcode"]
                            : "Area/Post code"}
                        </label>
                        <input
                          type="text"
                          name="postCode"
                          id="postCode"
                          value={values.postCode}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 mb-3">
                        <label id="phoneNumber">
                          {dictionary
                            ? dictionary["para_phone_number"]
                            : "Phone Number"}
                        </label>
                        <div className="form-row">
                          <div className="col-md-5 col-lg-5 mb-3">
                            <Select
                              options={phoneCodes}
                              isClearable={false}
                              maxMenuHeight={250}
                              menuPlacement="bottom"
                              menuPortalTarget={menuPortalTarget}
                              value={values.phoneCountryCode}
                              name="phoneCountryCode"
                              onChange={(option) =>
                                setFieldValue("phoneCountryCode", option)
                              }
                            />
                          </div>
                          <div
                            className="col-md-7 col-lg-7 mb-3"
                            id="phoneContainer"
                          >
                            <input
                              disabled={disablePhoneInput}
                              type="text"
                              name="phone"
                              id="phoneField"
                              value={values.phone}
                              className={`form-control ${errors["phone"]}`}
                              onChange={handleChange}
                              required
                            />
                            {errors["phone"] ? (
                              <div className="invalid-feedback">
                                Phone number is Required
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-6 mb-3">
                        <label>
                          {dictionary
                            ? dictionary["WO_STAFF_I_-725097220"]
                            : "Staff ID"}
                        </label>
                        <input
                          type="text"
                          name="staffId"
                          id="staffId"
                          value={values.staffId}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-lg-6 col-md-6 mb-3">
                        <label>
                          {dictionary
                            ? dictionary["WO_TILL_ACCOUNT_NUMBER_-1450217434"]
                            : "Till Account Number (Core Banking System)"}
                        </label>
                        <input
                          type="text"
                          name="tillAccountNumber"
                          id="tillAccountNumber"
                          value={values.tillAccountNumber}
                          className={`form-control`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>

                      <div className="col-lg-6 col-md-6 mb-3">
                        <div className="form-row">
                          <div className="col-lg-3 col-md-3">
                            <label>Set auto-confirm transactions</label>
                            <Select
                              options={phoneCodes}
                              isClearable={false}
                              maxMenuHeight={250}
                              menuPlacement="bottom"
                              menuPortalTarget={menuPortalTarget}
                              value={values.phoneCountryCode}
                              name="phoneCountryCode"
                              onChange={(option) =>
                                setFieldValue("phoneCountryCode", option)
                              }
                            />
                          </div>
                          <div className="col-lg-3 col-md-3">
                            <button className="btn btn-primary w-100 fw-bold px-3">
                              ADD CURRENCY
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Body;
