export const GET_DOMAIN_BRAND_IDENTITY_REQUEST =
  "GET_DOMAIN_BRAND_IDENTITY_REQUEST";
export const GET_DOMAIN_BRAND_IDENTITY_SUCCESS =
  "GET_DOMAIN_BRAND_IDENTITY_SUCCESS";
export const GET_DOMAIN_BRAND_IDENTITY_FAILURE =
  "GET_DOMAIN_BRAND_IDENTITY_FAILURE";

export const GET_TRANSACTION_TYPES_REQUEST = "GET_TRANSACTION_TYPES_REQUEST";
export const GET_TRANSACTION_TYPES_SUCCESS = "GET_TRANSACTION_TYPES_SUCCESS";
export const GET_TRANSACTION_TYPES_FAILURE = "GET_TRANSACTION_TYPES_FAILURE";

export const SET_LOGIN_CONFIGS = "SET_LOGIN_CONFIGS";
