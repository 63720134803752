import * as TransferConstants from "../constants/transfer.constants";
import {Loading} from "../../utils/helpers/constants";
import {
  formatPayCentres,
  formatQuote,
  formatSupportedWallets
} from "../../utils/helpers/logicHelper";

const initialState = {
  quoteData: {},
  supportedMethods: [],
  supportedPaymentWallets: [],
  transactionDetails: [],
  monthlyTransactions: [],
  paycentreList: [],
  termsConditionsURL: "",
  loading: "",
  loadingPayCentres: "",
  loadingQuote: "",
};

const transferReducer = (state = initialState, action) => {
  switch (action.type) {
    case TransferConstants.GET_QUOTE_REQUEST:
      return {...state, loadingQuote: Loading.FETCHING};

    case TransferConstants.GET_QUOTE_SUCCESS:
      const quoteData = formatQuote(action.payload);
      return {...state, quoteData, loadingQuote: Loading.SUCCESS};

    case TransferConstants.GET_QUOTE_FAILURE:
      return {...state, loadingQuote: Loading.ERROR};

    case TransferConstants.RESET_QUOTE_SUCCESS:
      return {...state, quoteData: {}, loadingQuote: ""};

    case TransferConstants.FETCH_MONTHLY_TRANSACTIONS_REQUEST:
      return {...state, loading: Loading.FETCHING};

    case TransferConstants.FETCH_MONTHLY_TRANSACTIONS_SUCCESS:
      return {...state, monthlyTransactions: action.payload["transactionCountDTO"], loading: Loading.SUCCESS};

    case TransferConstants.FETCH_MONTHLY_TRANSACTIONS_FAILURE:
      return {...state, loading: Loading.ERROR};

    case TransferConstants.FETCH_PAYMENT_METHODS_REQUEST:
      return {...state, loading: Loading.FETCHING};

    case TransferConstants.FETCH_PAYMENT_METHODS_SUCCESS:
      const expressData = {...action.payload};
      delete expressData["paymentMethodsMetadata"];
      delete expressData["paymentTermsNConditions"];
      const supportedPaymentWallets = formatSupportedWallets(action.payload);
      const supportedMethods = action.payload["paymentMethodsMetadata"];
      const termsConditionsURL = action.payload["paymentTermsNConditions"];
      return {
        ...state, supportedPaymentWallets, supportedMethods, termsConditionsURL, expressData,
        loading: Loading.SUCCESS
      };

    case TransferConstants.FETCH_PAYMENT_METHODS_FAILURE:
      return {...state, loading: Loading.ERROR};

    case TransferConstants.FETCH_PAYCENTRE_LIST_REQUEST:
      return {...state, loadingPayCentres: Loading.FETCHING};

    case TransferConstants.FETCH_PAYCENTRE_LIST_SUCCESS:
      const paycentreList = formatPayCentres(action.payload["nearestPaycenters"])
      return {...state, loadingPayCentres: Loading.SUCCESS, paycentreList};

    case TransferConstants.FETCH_PAYCENTRE_LIST_FAILURE:
      return {...state, loadingPayCentres: Loading.ERROR};

    default:
      return state;

  }
}

export default transferReducer;
