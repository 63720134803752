/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../../components/footer/Footer";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { fetchMyTransactions } from "../../../../store/actions/mytransactions.action";
import { Loading } from "../../../../utils/helpers/constants";
import Body from "./Body";

function MyTransaction() {
  const dispatch = useDispatch();
  const { component, domainBrand, myTransaction } = useSelector(
    ({ componentReducer, domainBrandReducer, myTransactionsReducer }) => ({
      component: componentReducer,
      domainBrand: domainBrandReducer,
      myTransaction: myTransactionsReducer,
    })
  );
  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    myTransaction.loadingMyTransactions === Loading.FETCHING;

  useEffect(() => {
    if (myTransaction.loadingMyTransactions !== Loading.SUCCESS) {
      dispatch(fetchMyTransactions());
    }
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="reports"
              active="reports"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              transactions={
                myTransaction?.myTransactions?.data?.transactionResponseDtosList
              }
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
}

export default MyTransaction;
