export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILURE = "CREATE_CUSTOMER_FAILURE";

export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const GET_BENEFICIARIES_REQUEST = "GET_BENEFICIARIES_REQUEST";
export const GET_BENEFICIARIES_SUCCESS = "GET_BENEFICIARIES_SUCCESS";
export const GET_BENEFICIARIES_FAILURE = "GET_BENEFICIARIES_FAILURE";

export const CREATE_BENEFICIARY_REQUEST = "CREATE_BENEFICIARY_REQUEST";
export const CREATE_BENEFICIARY_SUCCESS = "CREATE_BENEFICIARY_SUCCESS";
export const CREATE_BENEFICIARY_FAILURE = "CREATE_BENEFICIARY_FAILURE";

export const UPDATE_BENEFICIARY_REQUEST = "UPDATE_BENEFICIARY_REQUEST";
export const UPDATE_BENEFICIARY_SUCCESS = "UPDATE_BENEFICIARY_SUCCESS";
export const UPDATE_BENEFICIARY_FAILURE = "UPDATE_BENEFICIARY_FAILURE";

export const GET_PAYCENTRE_REQUEST = "GET_PAYCENTRE_REQUEST";
export const GET_PAYCENTRE_SUCCESS = "GET_PAYCENTRE_SUCCESS";
export const GET_PAYCENTRE_FAILURE = "GET_PAYCENTRE_FAILURE";

export const ARCHIVE_BENEFICIARY_REQUEST = "ARCHIVE_BENEFICIARY_REQUEST";
export const ARCHIVE_BENEFICIARY_SUCCESS = "ARCHIVE_BENEFICIARY_SUCCESS";
export const ARCHIVE_BENEFICIARY_FAILURE = "ARCHIVE_BENEFICIARY_FAILURE";

export const UNARCHIVE_BENEFICIARY_REQUEST = "UNARCHIVE_BENEFICIARY_REQUEST";
export const UNARCHIVE_BENEFICIARY_SUCCESS = "UNARCHIVE_BENEFICIARY_SUCCESS";
export const UNARCHIVE_BENEFICIARY_FAILURE = "UNARCHIVE_BENEFICIARY_FAILURE";

export const GET_ARCHIVED_BENEFICIARY_REQUEST = "ARCHIVE_BENEFICIARY_REQUEST";
export const GET_ARCHIVE_BENEFICIARY_SUCCESS = "ARCHIVE_BENEFICIARY_SUCCESS";
export const GET_ARCHIVE_BENEFICIARY_FAILURE = "ARCHIVE_BENEFICIARY_FAILURE";

export const GET_CUSTOMER_USER_REQUEST = "GET_CUSTOMER_USER_REQUEST";
export const GET_CUSTOMER_USER_SUCCESS = "GET_CUSTOMER_USER_SUCCESS";
export const GET_CUSTOMER_USER_FAILURE = "GET_CUSTOMER_USER_FAILURE";
