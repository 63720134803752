import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CreateAccountForm from "./CreateAccountForm";
import DataTable from "../../../../components/dataTable/dataTable";
import { accountProductCreationConfig } from "../../../../utils/helpers/dataTableConfigs";
import { ReactComponent as Activate } from "../../../../assets/images/svgs/check-circle.svg";
import { ReactComponent as Deactivate } from "../../../../assets/images/svgs/circle-off.svg";
import { getProductsList } from "../../../../store/actions/product.action";
import { toast } from "react-hot-toast";

import {
  activateAccountProduct,
  deactivateAccountProduct,
} from "../../../../utils/services/product.service";
import ProductModal from "../../../../components/modals/ProductModal";

function Body({ domainBrand, products = [], countries, currency, status }) {
  const dispatch = useDispatch();

  const [addNewState, updateState] = useState(false);
  const [type, setType] = useState("");
  const [show, setShow] = useState(false);
  const [prodName, setProdName] = useState("");
  const [prodId, setProdId] = useState("");
  const [filterInput, setFilterInput] = useState("");

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const addNewStatus = (event) => {
    event.preventDefault();
    updateState(!addNewState);
  };

  const handleClose = () => setShow(false);

  const handleActivation = async () => {
    const data = await activateAccountProduct(prodId);
    if (data.status === 200) {
      toast.success(data.data.message);
      dispatch(getProductsList());
      setShow(false);
      setType("");
      setProdName("");
      setProdId("");
    } else {
      toast.error("Invalid ID");
    }
  };

  const handleDeactivation = async () => {
    const data = await deactivateAccountProduct(prodId);
    if (data.status === 200) {
      toast.success(data.data.message);
      dispatch(getProductsList());
      setShow(false);
      setType("");
      setProdName("");
      setProdId("");
    } else {
      toast.error("Invalid ID");
    }
  };

  // activated status api

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 4,
    accessor: "actions",
    Cell: ({ row }) => {
      const activatedStatus = row?.original?.activatedStatus;

      return (
        <div className="text-start pointer m-auto">
          {activatedStatus === false ? (
            <OverlayTrigger
              key={"1"}
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-${``}`}>Activate</Tooltip>}
            >
              <Activate
                onClick={() => {
                  setShow(true);
                  setType("ACTIVATE");
                  setProdId(row.original.accountProductId);
                  setProdName(row.original.productName);
                }}
                className="m-auto"
              />
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              key={"2"}
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-${``}`}>Deactivate</Tooltip>}
            >
              <Deactivate
                onClick={() => {
                  setShow(true);
                  setType("DEACTIVATE");
                  setProdId(row.original.accountProductId);
                  setProdName(row.original.productName);
                }}
                className="m-auto"
              />
            </OverlayTrigger>
          )}
        </div>
      );
    },
  };

  const tableObject = [...accountProductCreationConfig, actionColumn];

  const columns = useMemo(() => tableObject, []);

  return (
    <>
      <ProductModal
        show={show}
        handleClose={handleClose}
        type={type}
        handleActivation={handleActivation}
        handleDeactivation={handleDeactivation}
        prodName={prodName}
      />

      <div className="app-content hor-content">
        <div className="container">
          {/* PAGE TITLES AND CREATE ACCOUNT BUTTON GOES HERE  */}
          <div className="page-header">
            <div>
              <h1 className="page-title">
                {/* {languageStatus
                ? dictionary["WO_CUSTOMER_AM_75995121"]
                : "AML Rules"} */}
                Account Product Creation
              </h1>
            </div>
            <div className="ms-auto pageheader-btn">
              <NavLink
                onClick={addNewStatus}
                to="/"
                className="btn btn-primary btn-icon text-white me-2"
              >
                <span>
                  <i className="fa fa-folder-plus" />
                </span>{" "}
                &nbsp;
                {/* {languageStatus ? dictionary["button_add_new"] : "Add New"} */}
                Create Account
              </NavLink>
            </div>
          </div>

          <div className="row row-sm">
            {addNewState && (
              <div className="col-lg-12">
                <div className="card overflow-hidden">
                  <div className="card-header bg-primary text-white">
                    <h3 className="card-title">
                      {/* {languageStatus ? dictionary["button_add_new"] : "Add New"} */}
                      Create Account
                    </h3>
                  </div>
                  <CreateAccountForm
                    countries={countries}
                    languageStatus={languageStatus}
                    dictionary={dictionary}
                    currency={currency}
                    status={status}
                    handleNewState={() => updateState(false)}
                  />
                </div>
              </div>
            )}

            <div className="col-lg-12">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">
                    {/* {languageStatus
                    ? dictionary["m_transaction_rules"]
                    : "All AML Rules"} */}
                    All Accounts
                  </h3>
                </div>
                <div className="card-body">
                  <div className="needs-validation">
                    <div className="row form-row">
                      <div className="col-md-6 mb-3">
                        <label>
                          {languageStatus
                            ? dictionary["l_search_for_payment_service"]
                            : "Search"}
                        </label>
                        <input
                          type="search"
                          value={filterInput}
                          onChange={(e) => setFilterInput(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Search..."
                        />
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={products !== null || undefined ? products : []}
                      filterData={filterInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
