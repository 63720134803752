export const SEARCH_AND_FINDCUSTOMER_REQUEST =
  "SEARCH_AND_FINDCUSTOMER_REQUEST";
export const SEARCH_AND_FINDCUSTOMER_SUCCESS =
  "SEARCH_AND_FINDCUSTOMER_SUCCESS";
export const SEARCH_AND_FINDCUSTOMER_FAILURE =
  "SEARCH_AND_FINDCUSTOMER_FAILURE";

export const REGISTER_CUSTOMER_BENEFICIARY_REQUEST =
  "REGISTER_CUSTOMER_BENEFICIARY_REQUEST";
export const REGISTER_CUSTOMER_BENEFICIARY_SUCCESS =
  "REGISTER_CUSTOMER_BENEFICIARY_SUCCESS";
export const REGISTER_CUSTOMER_BENEFICIARY_FAILURE =
  "REGISTER_CUSTOMER_BENEFICIARY_FAILURE";
