import React, { useState, useMemo } from "react";

import DataTable from "../../../../components/dataTable/dataTable";
import { agentTableConfig } from "../../../../utils/helpers/dataTableConfigs";

import { ReactComponent as Edit } from "../../../../assets/images/svgs/edit.svg";
import { ReactComponent as Lock } from "../../../../assets/images/svgs/lock.svg";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Body() {
  const [filterInput] = useState("");

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 5,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-center pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Edit</Tooltip>}
          >
            <Edit />
          </OverlayTrigger>

          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Suspend</Tooltip>}
          >
            <Lock />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...agentTableConfig, actionColumn];
  const columns = useMemo(() => tableObject, []);

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">Agents</h1>
          </div>
        </div>
        <div className="ms-auto pageheader-btn" />
        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  Agent can be either a bank, bank branch and Agent. Here you
                  can create branches for the registered bank which can be used
                  as an agent.
                </h3>
              </div>
              <div className="card-body">
                <div className="form-row mb-5 pb-5">
                  <div className="col-md-6 col-lg-6">
                    <button
                      className="btn btn-primary w-100 fw-bold mb-2"
                      type="button"
                    >
                      +NEW AGENT (CASH PICK-UP, BUREAU DE CHANGE)
                    </button>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <button
                      className="btn btn-primary w-100 fw-bold mb-2"
                      type="button"
                    >
                      +NEW MERCHANT
                    </button>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <button
                      className="btn btn-primary w-100 fw-bold mb-2"
                      type="button"
                    >
                      +NEW API(BANK TRANSFER, AIRTIME, BILL PAYMENT, MOBILE
                      MONEY)
                    </button>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <button
                      className="btn btn-primary w-100 fw-bold mb-2"
                      type="button"
                    >
                      +PAYMENT COLLECTION POINT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card custom-card">
              <div className="col-md-6 col-lg-6 mt-2">
                <label className="mb-1">Filter</label>
                <input
                  type={"text"}
                  className="form-control border px-3"
                  placeholder="Search..."
                  autoComplete=""
                />
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={[]}
                    filterData={filterInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
