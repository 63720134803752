import Select from "react-select";
import {paymentType} from "../../../../../utils/helpers/constants";

const SelectWallet = ({languageStatus, dictionary, wallets, selectedWallet, updateWalletData}) => {
  const menuPortalTarget = document.getElementById('root');

  const selectWallet = option => {
    updateWalletData(paymentType.E_WALLET, option);
    selectedWallet(option);
  }

  return (
    <div>
      <p><strong>{languageStatus ? dictionary["WO_SELECT_ACCOUN_416302202"] : "Select Acc"}</strong></p>
      <div className="col-md-12 mt-3 mb-3">
        <Select options={wallets}
                isClearable={false}
                maxMenuHeight={200}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                name="searchType"
                onChange={option => selectWallet(option)}
        />
      </div>
    </div>
  );
}

export default SelectWallet;
