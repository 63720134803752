import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import DataTable from "../../../../components/dataTable/dataTable";
import OpacityLoader from "../../../../components/loaders/OpacityLoader";
import CancelTransactionModal from "../../../../components/modals/CancelTransactionModal";
import { transferHistoryConfig } from "../../../../utils/helpers/dataTableConfigs";
import { fetchBeneficiaries } from "../../../../utils/services/users.services";
import {
  findItemInArray,
  formatBeneficiariesDropdown,
  formatStringCurrency,
} from "../../../../utils/helpers/logicHelper";
import { transactionStatusMap } from "../../../../utils/helpers/mappers";

const Table = ({ data, domainBrand, filterInput, reload }) => {
  const history = useHistory();
  const [loadingBeneficiary, setLoading] = useState(false);
  const [cancelRender, setCancelRender] = useState(false);
  const [cancelRenderProps, setCancelRenderProps] = useState({});

  const actionColumn = {
    Header: () => {
      return (
        <span className="text-center">
          {languageStatus ? dictionary["para_action"] : "Action"}
        </span>
      );
    },
    columnId: 4,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <span className="text-center pointer">
          {transactionStatusMap[row.original.status].resend ? (
            <>
              <button
                type="button"
                onClick={() => resendTransaction(row.original)}
                className="btn btn-success btn-sm"
              >
                Resend
              </button>
              &nbsp;
            </>
          ) : (
            ""
          )}
          {transactionStatusMap[row.original.status].pay ? (
            <>
              <button
                type="button"
                onClick={() => makePayment(row.original)}
                className="btn btn-warning text-white btn-sm"
              >
                Make Payment
              </button>
              &nbsp;
            </>
          ) : (
            ""
          )}
          {transactionStatusMap[row.original.status].view ? (
            <>
              <button
                type="button"
                onClick={() =>
                  history.push({
                    pathname: "/transfer/details",
                    state: { data: row.original },
                  })
                }
                className="btn btn-info text-white btn-sm"
              >
                View Payment
              </button>
              &nbsp;{" "}
            </>
          ) : (
            ""
          )}
          {transactionStatusMap[row.original.status].cancel ? (
            <>
              <button
                type="button"
                onClick={() => cancelTransaction(row.original)}
                className="btn btn-danger text-white btn-sm"
              >
                Cancel
              </button>
              &nbsp;
            </>
          ) : (
            ""
          )}
        </span>
      );
    },
  };

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const tableObject = [
    ...transferHistoryConfig(languageStatus, dictionary),
    actionColumn,
  ];
  const columns = useMemo(() => tableObject, []);

  const resendTransaction = async (data) => {
    setLoading(true);
    const { data: responseData } = await fetchBeneficiaries();
    const beneficiaries = formatBeneficiariesDropdown(
      responseData.data["receivers"]
    );
    const beneficiary = findItemInArray(beneficiaries, data["beneficiaryCode"]);
    history.push({
      pathname: `/transfer`,
      state: { data: {}, beneficiary: beneficiary },
    });
    setLoading(false);
  };

  const makePayment = async (data) => {
    const paymentData = {
      amount: formatStringCurrency(data["receivingAmount"]),
      sendAmount: formatStringCurrency(data.principalAmount),
      payableType: data["payableItemType"],
      payAbleId: data["payableItemId"],
      pcn: data.pcn,
      sendingCurrency: data["principalCurrencyCode"],
      receivingCurrency: data["receivingCurrencyCode"],
      originCountry: data["countryFrom"],
      receivingCountry: data["countryTo"],
      transactionType: data["transactionType"],
    };

    history.push({
      pathname: "/transfer/choose-payment-method",
      state: paymentData,
    });
  };

  const cancelTransaction = (transaction) => {
    setCancelRender(true);
    setCancelRenderProps(transaction);
  };

  const changeCancelRenderStatus = () => setCancelRender(false);
  const cancelModalRender = cancelRender ? (
    <CancelTransactionModal
      onchange={changeCancelRenderStatus}
      cancelProps={cancelRenderProps}
      domainBrand={domainBrand}
      callTransactionHistory={reload}
    />
  ) : null;

  return (
    <>
      {cancelModalRender}
      {loadingBeneficiary ? <OpacityLoader /> : ""}
      <DataTable columns={columns} data={data} filterData={filterInput} />
    </>
  );
};

export default Table;
