import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../../../../components/loaders/SphereLoader";
import Header from "../../../../../../components/header/Header";
import HeaderMenu from "../../../../../../components/header/HeaderMenu";
import Footer from "../../../../../../components/footer/Footer";
import { fetchPayCentres } from "../../../../../../store/actions/transfer.action";
import { Loading } from "../../../../../../utils/helpers/constants";

const PayoutSearch = () => {
  const dispatch = useDispatch();
  const { domainBrand, component, transfer } = useSelector(
    ({ domainBrandReducer, componentReducer, transferReducer }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      transfer: transferReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    transfer.loadingPayCentres === Loading.FETCHING;

  useEffect(() => {
    if (transfer.loadingPayCentres !== Loading.SUCCESS)
      dispatch(fetchPayCentres());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="accounting"
              active="fundAccounts"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              payCentres={transfer.paycentreList}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default PayoutSearch;
