import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Body from "./Body";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import { toggleNav } from "../../../../store/actions/component.action";
import { Loading } from "../../../../utils/helpers/constants";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { getCountries } from "../../../../store/actions/country.action";
import { getProductsList } from "../../../../store/actions/product.action";

const AccountCreation = () => {
  const dispatch = useDispatch();

  const { domainBrand, component, users, country, products } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      usersReducer,
      countryReducer,
      productReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      users: usersReducer,
      country: countryReducer,
      products: productReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    users.loadingCustomers === Loading.FETCHING;

  useEffect(() => {
    const payload = {};
    if (products.loadingProducts !== Loading.SUCCESS) {
      dispatch(getProductsList(payload));
    }
  }, []);

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
  }, []);

  useEffect(() => {
    dispatch(toggleNav(false));
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="crm"
              active="account creation"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              account={users?.customers}
              countries={country?.supportedCountries}
              currency={country?.currencies}
              products={products?.products}
              status={products?.loadingProducts}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default AccountCreation;
