import Axios from "../services/axios.service";

export const fetchDomainBrandIdentity = async (subdomain, languageOption) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/generalmanagement/load-domain-brand-identity?lang_iso2=${languageOption}`,
  });
};

export const transactionTypes = async () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/transactionmanagement/transaction-types`,
  });
};

export const languageDictionary = async (language) => {
  return Axios({
    method: "GET",
    url: `https://mta-cdn-public.s3.eu-west-1.amazonaws.com/languages/${language}.json`,
  });
};

export const fetchLanguages = async () => {
  return Axios({
    method: "GET",
    url: `https://mta-cdn-public.s3.eu-west-1.amazonaws.com/languages.json`,
  });
};

export const fetchDynamicFields = async (type) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/dynamicfields/dynamic-fields-group/${type}`,
  });
};

export const fetchOperatorsByCountry = async (payload) => {
  return Axios({
    method: "POST",
    url: `/web-services/api/v6/services/utilitiesmanagement/mobile-money-operator-list`,
    data: payload,
  });
};
