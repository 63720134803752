import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import DynamicFields from "../../../components/dynamicFields/DynamicFields";
import { signUpDetailsValidator } from "../../../utils/helpers/validationHelpers";
import { dynamicFieldsFormikGetKeys } from "../../../utils/helpers/logicHelper";
import { registerCustomer } from "../../../store/actions/users.action";
import {
  customerTypes,
  dynamicFieldsTypes,
  Loading,
} from "../../../utils/helpers/constants";
import { nationalities } from "../../../utils/helpers/nationalities";
import { genderDropdown } from "../../../utils/helpers/objectHelpers";

const DetailsFields = ({
  deliverables,
  properties,
  countriesProps,
  setInitialDomState,
  formikData,
}) => {
  const history = useHistory(),
    dispatch = useDispatch();
  const { languageStatus, dictionary } = deliverables;
  const { visibleFields, mandatoryFields, namesFieldsData, component } =
    properties;
  const { supportedCountries } = countriesProps;
  const menuPortalTarget = document.getElementById("root");
  const initialDynamicFormType =
    namesFieldsData.type.value === customerTypes.INDIVIDUAL
      ? dynamicFieldsTypes.REGISTERED_CUSTOMER_SIGNUP
      : dynamicFieldsTypes.COMPANY_CUSTOMER_SIGNUP;
  const [dynamicFormType, setDynamicFormType] = useState(
    initialDynamicFormType
  );

  const genderOptions = genderDropdown(languageStatus, dictionary);

  const initialValues = {
    ...namesFieldsData,
    address1: "",
    address2: "",
    city: "",
    postcode: "",
    state: "",
    country: "",
    nationality: "",
    customerGender: "",
    occupation: "",
    employer: "",
    annualSalary: "",
    accountNumber: "",
  };

  const initialValidation = signUpDetailsValidator(mandatoryFields);
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(initialValidation),
    onSubmit: async () => {},
  });

  useEffect(() => {
    if (Object.entries(namesFieldsData).length > 0)
      formik.setValues({ ...namesFieldsData });
  }, [namesFieldsData]);

  useEffect(() => {
    if (component.loading === Loading.SUCCESS) {
      if (formik.values.type.value === customerTypes.COMPANY)
        setDynamicFormType(dynamicFieldsTypes.COMPANY_CUSTOMER_SIGNUP);
      else if (formik.values.type.value === customerTypes.COMPANY)
        setDynamicFormType(dynamicFieldsTypes.REGISTERED_CUSTOMER_SIGNUP);
      else setDynamicFormType(dynamicFieldsTypes.REGISTERED_CUSTOMER_SIGNUP);
    }
  }, []);

  const register = async () => {
    await formik.setFieldValue("submitting", true);
    formikData({ ...formik.values });
    const values = formik.values;
    let dynamicValues = dynamicFieldsFormikGetKeys(
      component[dynamicFormType]["dynamicFields"],
      formik.values
    );

    const payload = {
      dynamicFields: {
        ...dynamicValues,
      },
      companyName: values.companyName || "",
      companyRegistrationNumber: values.companyRegistrationNumber || "",
      customerType:
        values.type !== undefined ? values.type.value : "INDIVIDUAL",
      dialingCode: values.phoneCountryCode.value || "",
      password: values.password || "",
      confirmPassword: values.password || "",
      firstName: values.firstName || "",
      lastName: values.lastName || "",
      phone: values.phone || "",
      email: values.email || "",
      dob: values.dateOfBirth.toISOString(),
      nationality:
        values.nationality !== undefined ? values.nationality.value : "",
      address: {
        address1: values.address1 ?? "",
        address2: values.address2 ?? "",
        city: values.city ?? "",
        state: values.state ?? "",
        postcode: values.postcode ?? "",
        countryIso3: values.country !== undefined ? values.country.value : "",
        countryCommonName:
          values.country !== undefined ? values.country.label : "",
      },
    };

    if (visibleFields.some((o) => o["fieldType"] === "GENDER")) {
      const signUpObject = {
        ...payload,
        customerGender:
          values.customerGender !== undefined
            ? values.customerGender.value
            : "UNKNOWN",
      };
      const navigateMethod = () => history.push("/login");
      await dispatch(registerCustomer(signUpObject, navigateMethod));
      await formik.setFieldValue("submitting", false);
    } else if (visibleFields.some((o) => o["fieldType"] === "OCCUPATION")) {
      const signUpObject = {
        ...payload,
        occupation: values.occupation || "",
      };
      const navigateMethod = () => history.push("/login");
      await dispatch(registerCustomer(signUpObject, navigateMethod));
      await formik.setFieldValue("submitting", false);
    } else if (visibleFields.some((o) => o["fieldType"] === "EMPLOYER")) {
      const signUpObject = {
        ...payload,
        employer: values.employer || "",
      };
      const navigateMethod = () => history.push("/login");
      await dispatch(registerCustomer(signUpObject, navigateMethod));
      await formik.setFieldValue("submitting", false);
    } else if (visibleFields.some((o) => o["fieldType"] === "ANNUAL_SALARY")) {
      const signUpObject = {
        ...payload,
        annualSalary: values.annualSalary || "",
      };
      const navigateMethod = () => history.push("/login");
      await dispatch(registerCustomer(signUpObject, navigateMethod));
      await formik.setFieldValue("submitting", false);
    } else if (visibleFields.some((o) => o["fieldType"] === "ACCOUNT_NUMBER")) {
      const signUpObject = {
        ...payload,
        accountNumber: values.accountNumber || "",
      };
      const navigateMethod = () => history.push("/login");
      await dispatch(registerCustomer(signUpObject, navigateMethod));
      await formik.setFieldValue("submitting", false);
    } else {
      const signUpObject = payload;
      const navigateMethod = () => history.push("/login");
      await dispatch(registerCustomer(signUpObject, navigateMethod));
      await formik.setFieldValue("submitting", false);
    }
  };

  const allDynamicFields =
    component.loading === Loading.FETCHING ||
    typeof component[dynamicFormType] === "undefined"
      ? []
      : component[dynamicFormType]["dynamicFields"];

  const renderDynamicFields = allDynamicFields.map((eachField, index) => (
    <DynamicFields
      divClass="col-xl-6 col-lg-6 col-md-6 mb-3"
      key={index}
      eachField={eachField}
      formik={formik}
    />
  ));

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-row">
            {!visibleFields.some((o) => o["fieldType"] === "ADDRESS_1") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_address1"]
                    : "Address Line 1"}
                  {mandatoryFields.some(
                    (o) => o["fieldType"] === "ADDRESS_1"
                  ) ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="address1"
                  value={formik.values.address1}
                  className={`form-control ${
                    formik.errors["address1"] ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["address1"] ? (
                  <div className="invalid-feedback">Address 1 is Required</div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "ADDRESS_2") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_address_line_2"]
                    : "Address Line 2"}
                  {mandatoryFields.some(
                    (o) => o["fieldType"] === "ADDRESS_2"
                  ) ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="address2"
                  value={formik.values.address2}
                  className={`form-control ${
                    formik.errors["address2"] ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["address2"] ? (
                  <div className="invalid-feedback">Address 2 is Required</div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "CITY") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus ? dictionary["l_city"] : "City"}
                  {mandatoryFields.some((o) => o["fieldType"] === "CITY") ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="city"
                  value={formik.values.city}
                  className={`form-control ${
                    formik.errors["city"] ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["city"] ? (
                  <div className="invalid-feedback">City is Required</div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "POSTCODE") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus ? dictionary["para_postcode"] : "Post code"}
                  {mandatoryFields.some(
                    (o) => o["fieldType"] === "POSTCODE"
                  ) ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="postcode"
                  value={formik.values.postcode}
                  className={`form-control ${
                    formik.errors["postcode"] ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["postcode"] ? (
                  <div className="invalid-feedback">Post code is Required</div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "STATE") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus ? dictionary["WO_STAT_-878960465"] : "State"}
                  {mandatoryFields.some((o) => o["fieldType"] === "STATE") ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="state"
                  value={formik.values.state}
                  className={`form-control ${
                    formik.errors["state"] ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["state"] ? (
                  <div className="invalid-feedback">State is Required</div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "OCCUPATION") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label className="text-capitalize">
                  {languageStatus
                    ? dictionary["WO_OCCUPATIO_-885866472"]
                    : "Occupation"}
                  {mandatoryFields.some(
                    (o) => o["fieldType"] === "OCCUPATION"
                  ) ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="occupation"
                  value={formik.values.occupation}
                  className={`form-control ${
                    formik.errors["occupation"] ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["occupation"] ? (
                  <div className="invalid-feedback">Occupation is Required</div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "EMPLOYER") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label className="text-capitalize">
                  {languageStatus
                    ? dictionary["WO_EMPLOYE_1313991626"]
                    : "Employer"}
                  {mandatoryFields.some(
                    (o) => o["fieldType"] === "EMPLOYER"
                  ) ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="employer"
                  value={formik.values.employer}
                  className={`form-control ${
                    formik.errors["employer"] ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["employer"] ? (
                  <div className="invalid-feedback">employer is Required</div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "ANNUAL_SALARY") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label className="text-capitalize">
                  {languageStatus
                    ? dictionary["WO_ANNUAL_SALAR_1783123859"]
                    : "Annual Salary"}
                  {mandatoryFields.some(
                    (o) => o["fieldType"] === "ANNUAL_SALARY"
                  ) ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="annualSalary"
                  value={formik.values.annualSalary}
                  className={`form-control ${
                    formik.errors["annualSalary"] ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["annualSalary"] ? (
                  <div className="invalid-feedback">
                    Annual salary is Required
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "ACCOUNT_NUMBER") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label className="text-capitalize">
                  {languageStatus
                    ? dictionary["WO_ACCOUNT_NUMBE_554490787"]
                    : "Account Number"}
                  {mandatoryFields.some(
                    (o) => o["fieldType"] === "ACCOUNT_NUMBER"
                  ) ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="text"
                  name="accountNumber"
                  value={formik.values.accountNumber}
                  className={`form-control ${
                    formik.errors["accountNumber"] ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  required
                />
                {formik.errors["accountNumber"] ? (
                  <div className="invalid-feedback">
                    Account number is Required
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!visibleFields.some((o) => o["fieldType"] === "GENDER") ? (
              ""
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label className="text-capitalize">
                  {languageStatus
                    ? dictionary["WO_GENDE_-274590021"]
                    : "Gender"}
                  {mandatoryFields.some((o) => o["fieldType"] === "GENDER") ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </label>
                <Select
                  options={genderOptions}
                  isClearable={false}
                  isSearchable={true}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                  name="country"
                  value={formik.values.customerGender}
                  onChange={(option) =>
                    formik.setFieldValue("customerGender", option)
                  }
                />
                {formik.errors["customerGender"] ? (
                  <div className="invalid-feedback">Gender is Required</div>
                ) : (
                  ""
                )}
              </div>
            )}

            <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
              <label>
                {languageStatus ? dictionary["l_country"] : "Country"}
                <span style={{ color: "red" }}>*</span>
              </label>
              <Select
                options={supportedCountries}
                isClearable={false}
                isSearchable={true}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                name="country"
                value={formik.values.country}
                onChange={(option) => formik.setFieldValue("country", option)}
              />
            </div>

            {formik.values.type.value === customerTypes.INDIVIDUAL ? (
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_nationality"]
                    : "Nationality"}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  options={nationalities}
                  isClearable={false}
                  isSearchable={true}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                  name="nationality"
                  value={formik.values.nationality}
                  onChange={(option) =>
                    formik.setFieldValue("nationality", option)
                  }
                />
              </div>
            ) : (
              ""
            )}

            {renderDynamicFields}
          </div>
          <button
            onClick={() => setInitialDomState(true)}
            className="btn btn-danger mt-3"
            type="button"
          >
            <i className="fa fa-arrow-left" /> Previous
          </button>
          <button
            onClick={register}
            className="btn btn-primary mt-3 float-end"
            type="button"
          >
            Create Account
            {formik.values["submitting"] ? (
              <>
                &nbsp;
                <i className="fa fa-spin fa-spinner" />
              </>
            ) : (
              ""
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DetailsFields;
