import * as Profile from "../constants/profile.constants";
import { Loading } from "../../utils/helpers/constants";

const initialState = {
  userData: {},
  documents: [],
  messages: [],
  notification: "",
  fetchingNotification: "",
  userProfileLoading: "",
  documentsLoading: "",
  messagesLoading: "",
  userType: "",
  originCountry: "",
  defaultCurrency: "",
  editProfileStatus: "",
  profileImage: "",
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case Profile.FETCH_USER_PROFILE_REQUEST:
      return { ...state, userProfileLoading: Loading.FETCHING, userData: {} };

    case Profile.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileLoading: Loading.SUCCESS,
        userData: action.payload,
        userType: action.payload.userType,
        originCountry: action.payload.address["countryIso3"],
        defaultCurrency: action.payload["defaultCurrency"],
        userAccountVerified: action.payload.userAccountVerified,
        profileImage: action.payload.profileImage,
      };

    case Profile.FETCH_USER_PROFILE_FAILURE:
      return { ...state, userProfileLoading: Loading.ERROR, userData: {} };

    case Profile.UPDATE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        userProfileLoading: Loading.SUCCESS,
        profileImage: action.payload["userProfileUrl"],
      };

    case Profile.FETCH_DOCUMENTS_REQUEST:
      return { ...state, documentsLoading: Loading.FETCHING };

    case Profile.FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentsLoading: Loading.SUCCESS,
        documents: action.payload["docs"],
      };

    case Profile.FETCH_DOCUMENTS_FAILURE:
      return { ...state, documentsLoading: Loading.ERROR };

    case Profile.FETCH_MESSAGES_REQUEST:
      return { ...state, messagesLoading: Loading.FETCHING };

    case Profile.FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        messagesLoading: Loading.SUCCESS,
        messages: action.payload["notificationsDTOs"],
      };

    case Profile.FETCH_MESSAGES_FAILURE:
      return { ...state, messagesLoading: Loading.ERROR };

    case Profile.FETCH_NOTIFICATIONS_REQUEST:
      return { ...state, fetchingNotification: Loading.FETCHING };

    case Profile.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        fetchingNotification: Loading.SUCCESS,
        notification: action.payload["showNotification"],
      };

    case Profile.FETCH_NOTIFICATIONS_FAILURE:
      return { ...state, fetchingNotification: Loading.ERROR };

    default:
      return state;
  }
};

export default profileReducer;
