import { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import Select from "react-select";
import * as Yup from "yup";

import { requestAccountValidator } from "../../../../utils/helpers/validationHelpers";
import { createWalletAccount } from "../../../../store/actions/accounts.action";
import {
  accountTypes,
  purposeOfAccount,
} from "../../../../utils/helpers/objectHelpers";

const Body = ({ domainBrand, currencies }) => {
  const dispatch = useDispatch();
  const [onSubmitClick, setOnSubmitClick] = useState(false);
  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const accountTypesDropdown = accountTypes(languageStatus, dictionary);
  const purposeOfAccountDropdown = purposeOfAccount(languageStatus, dictionary);
  const initialValidation = requestAccountValidator();
  const initialValues = {
    currency: currencies[0],
    accountName: "",
    purposeOfAccount: "",
    accountType: "",
    description: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(initialValidation),
    onSubmit: () => {},
  });

  const onRequestAccount = async () => {
    setOnSubmitClick(true);
    if (Object.entries(formik.errors).length === 0) {
      const payload = {
        name: formik.values.accountName,
        description: formik.values.description,
        currencyCode: formik.values.currency.value,
        accountType: formik.values.accountType.value,
        accountPurpose: formik.values.purposeOfAccount.value,
      };

      console.log(payload);
      await dispatch(createWalletAccount(payload));
      setOnSubmitClick(false);
    }
  };

  const disableSubmit =
    typeof formik.values.accountName === "undefined" ||
    formik.values.accountName === "";

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus
                ? dictionary["WO_REQUEST_BANK_ACCOUN_1842427382"]
                : "Request Account"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <form className="mt-4" onSubmit={formik.handleSubmit}>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label>Currency</label>
                      <Select
                        options={currencies}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="currency"
                        value={formik.values.currency}
                        onChange={(option) =>
                          formik.setFieldValue("currency", option)
                        }
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Account Name</label>
                      <input
                        type="text"
                        name="accountName"
                        className={`form-control ${
                          formik.errors["accountName"] && onSubmitClick
                            ? "is-invalid"
                            : ""
                        }`}
                        value={formik.values.accountName}
                        onChange={formik.handleChange}
                      />
                      {formik.errors["accountName"] ? (
                        <div className="invalid-feedback">
                          Account name is required.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Purpose of Account</label>
                      <Select
                        options={purposeOfAccountDropdown}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="purposeOfAccount"
                        value={formik.values.purposeOfAccount}
                        onChange={(option) =>
                          formik.setFieldValue("purposeOfAccount", option)
                        }
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Account Type</label>
                      <Select
                        options={accountTypesDropdown}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="accountType"
                        value={formik.values.accountType}
                        onChange={(option) =>
                          formik.setFieldValue("accountType", option)
                        }
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label>Description</label>
                      <textarea
                        name="description"
                        rows="4"
                        className="form-control"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      disabled={disableSubmit}
                      onClick={onRequestAccount}
                      type="button"
                    >
                      {languageStatus ? dictionary["button_submit"] : "Submit"}
                      {formik.values["submitting"] ? (
                        <>
                          &nbsp;
                          <i className="fa fa-spin fa-spinner" />
                        </>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
