import Slider from "react-slick";
import splash1 from "../../assets/images/splash1.png";
import splash2 from "../../assets/images/splash2.png";
import splash3 from "../../assets/images/splash3.png";


const AuthImageSlider = () => {

  const settings = {
    fade: true,
    infinite: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    arrows: false,
    initialSlide: 0,
  };

  return (
    <Slider {...settings}>
      <>
        <img src={splash1} alt=""
             className="img-fluid mb-3 d-none d-md-block"/>
        <h3 className="font-italic d-none d-md-block text-muted mb-0">The easiest way to set up your</h3>
        <h1 className="font-italic d-none d-md-block text-muted fw-bolder mb-0">Virtual Accounts Solution</h1>
      </>
      <>
        <img src={splash2} alt=""
             className="img-fluid mb-3 d-none d-md-block"/>
        <h3 className="font-italic d-none d-md-block text-muted mb-0">The easiest way to set up your</h3>
        <h1 className="font-italic d-none d-md-block text-muted fw-bolder mb-0">E-money Service</h1>
      </>
      <>
        <img src={splash3} alt=""
             className="img-fluid mb-3 d-none d-md-block"/>
        <h3 className="font-italic d-none d-md-block text-muted mb-0">The easiest way to set up your</h3>
        <h1 className="font-italic d-none d-md-block text-muted fw-bolder mb-0">Lending</h1>
      </>
      <>
        <img src={splash2} alt=""
             className="img-fluid mb-3 d-none d-md-block"/>
        <h3 className="font-italic d-none d-md-block text-muted mb-0">The easiest way to set up your</h3>
        <h1 className="font-italic d-none d-md-block text-muted fw-bolder mb-0">Niche bank</h1>
      </>
    </Slider>
  );
}

export default AuthImageSlider;
