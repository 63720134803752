import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import avatar from "../../../../assets/images/gravatar.png";
import Body from "./Body";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import KycHeaderMenu from "../../../../components/header/KycHeaderMenu";
import Footer from "../../../../components/footer/Footer";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { toggleNav } from "../../../../store/actions/component.action";
import { getCountries } from "../../../../store/actions/country.action";
import {
  fetchDocuments,
  getProfile,
} from "../../../../store/actions/profile.action";
import { Loading } from "../../../../utils/helpers/constants";

const Profile = () => {
  const dispatch = useDispatch();
  const { domainBrand, component, countries, profile } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      countryReducer,
      profileReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      countries: countryReducer,
      profile: profileReducer,
    })
  );

  const [photoUrl, setPhotoUrl] = useState(avatar);
  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    profile.userProfileLoading === Loading.FETCHING ||
    profile.documentsLoading === Loading.FETCHING ||
    countries.loadingCountries === Loading.FETCHING;

  useEffect(async () => {
    dispatch(toggleNav(false));
    if (countries.loadingCountries !== Loading.SUCCESS)
      dispatch(getCountries());
  }, []);

  useEffect(async () => {
    if (profile.userProfileLoading !== Loading.SUCCESS)
      await dispatch(getProfile());
    setPhotoUrl(profile.profileImage);
  }, [profile.profileImage]);

  useEffect(() => {
    if (
      profile.documentsLoading !== Loading.SUCCESS &&
      Object.entries(profile.userData).length > 1
    )
      dispatch(fetchDocuments(profile.userData["customerCode"]));
  }, [profile.userData]);

  const properties = { photoUrl, countries };

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            {profile["userAccountVerified"] ? (
              <HeaderMenu
                menu="general"
                active="profile"
                domainBrand={domainBrand}
              />
            ) : (
              <KycHeaderMenu menu="profile" domainBrand={domainBrand} />
            )}

            <Body
              domainBrand={domainBrand}
              profile={profile}
              properties={properties}
              setPhotoUrl={setPhotoUrl}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default Profile;
