import * as CustomerConstants from "../constants/users.constants";
import { Loading } from "../../utils/helpers/constants";
import { formatBeneficiariesDropdown } from "../../utils/helpers/logicHelper";

const initialState = {
  customers: [],
  agents: [],
  beneficiaries: [],
  loadingCustomers: "",
  loadingAgents: "",
  loadingBeneficiaries: "",
  users: [],
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerConstants.GET_CUSTOMERS_REQUEST:
      return { ...state, loadingCustomers: Loading.FETCHING };

    case CustomerConstants.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loadingCustomers: Loading.SUCCESS,
        customers: action.payload,
      };

    case CustomerConstants.GET_CUSTOMERS_FAILURE:
      return { ...state, loadingCustomers: Loading.ERROR };

    case CustomerConstants.GET_PAYCENTRE_REQUEST:
      return { ...state, loadingAgents: Loading.FETCHING };

    case CustomerConstants.GET_PAYCENTRE_SUCCESS:
      return {
        ...state,
        agents: action.payload["team"],
        loadingAgents: Loading.SUCCESS,
      };

    case CustomerConstants.GET_CUSTOMER_USER_REQUEST:
      return { ...state, loadingCustomers: Loading.FETCHING };

    case CustomerConstants.GET_CUSTOMER_USER_SUCCESS:
      return {
        ...state,
        users: action.payload["team"],
        loadingCustomers: Loading.SUCCESS,
      };

    case CustomerConstants.GET_CUSTOMER_USER_FAILURE:
      return { ...state, loadingCustomers: Loading.ERROR };

    case CustomerConstants.GET_PAYCENTRE_FAILURE:
      return { ...state, loadingAgents: Loading.ERROR };

    case CustomerConstants.GET_BENEFICIARIES_REQUEST:
      return { ...state, loadingBeneficiaries: Loading.FETCHING };

    case CustomerConstants.GET_BENEFICIARIES_SUCCESS:
      const formattedBeneficiaries = formatBeneficiariesDropdown(
        action.payload["receivers"]
      );
      return {
        ...state,
        beneficiaries: formattedBeneficiaries,
        loadingBeneficiaries: Loading.SUCCESS,
      };

    case CustomerConstants.ARCHIVE_BENEFICIARY_FAILURE:
      return { ...state, loadingBeneficiaries: Loading.ERROR };

    case CustomerConstants.ARCHIVE_BENEFICIARY_REQUEST:
      return { ...state, loadingBeneficiaries: Loading.FETCHING };

    case CustomerConstants.ARCHIVE_BENEFICIARY_SUCCESS:
      const archivedBeneficiaries = formatBeneficiariesDropdown(
        action.payload["receivers"]
      );
      return {
        ...state,
        beneficiaries: archivedBeneficiaries,
        loadingBeneficiaries: Loading.SUCCESS,
      };

    case CustomerConstants.UNARCHIVE_BENEFICIARY_FAILURE:
      return { ...state, loadingBeneficiaries: Loading.ERROR };

    case CustomerConstants.UNARCHIVE_BENEFICIARY_REQUEST:
      return { ...state, loadingBeneficiaries: Loading.FETCHING };

    case CustomerConstants.UNARCHIVE_BENEFICIARY_SUCCESS:
      const unArchivedBeneficiaries = formatBeneficiariesDropdown(
        action.payload["receivers"]
      );
      return {
        ...state,
        beneficiaries: unArchivedBeneficiaries,
        loadingBeneficiaries: Loading.SUCCESS,
      };

    default:
      return state;
  }
};

export default usersReducer;
