import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import AddBankConfig from "./atom/AddBankConfig";
import DataTable from "../../../../components/dataTable/dataTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Edit } from "../../../../assets/images/svgs/edit.svg";
import { ReactComponent as Trash } from "../../../../assets/images/svgs/trash.svg";
import { bankFieldsConfig } from "../../../../utils/helpers/dataTableConfigs";
import { BiLinkExternal } from "react-icons/bi";
import { deleteBankFieldConfig } from "../../../../utils/services/bankfield.service";
import { toast } from "react-hot-toast";
import { getBankFields } from "../../../../store/actions/bankfield.action";
import EditRateModal from "../../../../components/modals/EditRateModal";

const Body = ({ domainBrand, currencies, countries, userId, bankFields }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const [addNewState, updateState] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [show, setShow] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ruleId, setRuleId] = useState("");

  const addNewStatus = (event) => {
    event.preventDefault();
    updateState(!addNewState);
  };

  const deleteBankField = async (id) => {
    if (id !== "") {
      setLoading(true);
      const res = await deleteBankFieldConfig(id);
      if (res?.data?.status !== "FAILED") {
        setLoading(false);
        toast.success(res?.data?.message);
        dispatch(getBankFields());
        setShow(false);
      } else {
        toast.error(res?.data?.message);
        setLoading(false);
      }
    }
  };

  const bankFieldsTable = bankFieldsConfig(
    languageStatus,
    dictionary,
    "ORDINARY"
  );

  const actionColumn = {
    Header: () => {
      return (
        <span className="text-center fw-bold">
          {languageStatus ? dictionary["l_action"] : "Action"}
        </span>
      );
    },
    columnId: 3,
    accessor: "actions",
    Cell: ({ row }) => {
      const bankConfigId = row?.original?.id;
      const templateName = row?.original?.templateName;

      return (
        <div className="text-start pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${`1`}`}>Edit</Tooltip>}
          >
            <Edit />
          </OverlayTrigger>

          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${`2`}`}>Delete</Tooltip>}
          >
            <Trash
              className="mx-2"
              onClick={() => {
                setTemplateName(templateName);
                setShow(true);
                setRuleId(bankConfigId);
              }}
            />
          </OverlayTrigger>

          <OverlayTrigger
            key={"3"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${`3`}`}>Link to Corridor</Tooltip>}
          >
            <BiLinkExternal
              color={"#6259CA"}
              fontSize={23}
              fill={"#6259CA"}
              onClick={() => {
                history.push({
                  pathname: `/settings/bank-config/${bankConfigId}`,
                  state: {
                    detail: {
                      bankConfigId: bankConfigId,
                      templateName: templateName,
                    },
                  },
                });
              }}
            />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...bankFieldsTable, actionColumn];

  const columns = useMemo(() => tableObject, []);

  return (
    <>
      <EditRateModal
        type="DELETE-RATE-MODAL"
        show={show}
        handleClose={() => {
          setTemplateName("");
          setShow(false);
          setRuleId("");
        }}
        handleDelete={() => deleteBankField(ruleId)}
        rateName={templateName}
        isLoading={loading}
      />
      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">
                {languageStatus
                  ? dictionary["WO_BANK_ACCOUNT_SETTING_1025629397"]
                  : "Bank Account Fields"}
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  {languageStatus
                    ? dictionary["WO_YOU_CAN_SET_MANDATOR_1316489625"]
                    : "You can set mandatory fields for each country here."}
                </li>
              </ol>
            </div>
            <div className="ms-auto pageheader-btn">
              <NavLink
                onClick={addNewStatus}
                to="/"
                className="btn btn-primary btn-icon text-white me-2 fw-bold"
              >
                <span>
                  <i className="fa fa-folder-plus" />
                </span>{" "}
                &nbsp;
                {/* {languageStatus
                ? dictionary["WO_ADD_NEW_BANK_CONFIGU_1840035176"]
                : "Add New Bank Configuration"} */}
                Add New Bank Configuration
              </NavLink>
            </div>
          </div>
          <div className="row row-sm">
            {addNewState && (
              <div className="col-lg-12">
                <div className="card overflow-hidden">
                  <div className="card-header bg-primary text-white">
                    <h3 className="card-title">BANK ACCOUNT FIELDS</h3>
                  </div>
                  <div className="card-body">
                    <AddBankConfig
                      domainBrand={domainBrand}
                      currencies={currencies}
                      countries={countries}
                      userId={userId}
                      addNewStatus={() => updateState(false)}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="col-lg-12">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title fw-bold">
                    {dictionary
                      ? dictionary["WO_BANK_CONFIGURATIONS_-1412875706"]
                      : "Bank Configurations"}
                  </h3>
                </div>
                <div className="card-body">
                  <div className="col-lg-6 col-md-6 mb-3">
                    <p className="text-muted card-sub-title">
                      Sort and filter table
                    </p>

                    <input
                      className="form-control border"
                      type={"text"}
                      value={filterData}
                      onChange={(e) => setFilterData(e.target.value)}
                    />
                  </div>
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={bankFields}
                      filterData={filterData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
