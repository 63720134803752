import { Loading } from "../../utils/helpers/constants";
import * as AccManagementConstants from "../constants/acc-management.constants";

const initialState = {
  interestRates: [],
  loadingInterestRates: "",
};

const accManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case AccManagementConstants.FETCH_INTEREST_RATE_REQUEST:
      return { ...state, loadingInterestRates: Loading.FETCHING };

    case AccManagementConstants.FETCH_INTEREST_RATE_SUCCESS:
      return {
        ...state,
        loadingInterestRates: Loading.SUCCESS,
        interestRates: action.payload,
      };

    case AccManagementConstants.FETCH_INTEREST_RATE_FAILURE:
      return { ...state, loadingInterestRates: Loading.ERROR };

    default:
      return state;
  }
};

export default accManagementReducer;
