import { Loading } from "../../utils/helpers/constants";
import * as BillsConstants from "../constants/bills.constants";

const initialState = {
  billsAndServices: [],
  loadingBillsAndServices: "",
};

const billsAndServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case BillsConstants.BILLS_AND_SERVICES_REQUEST:
      return { ...state, loadingBillsAndServices: Loading.FETCHING };

    case BillsConstants.BILLS_AND_SERVICES_SUCCESS:
      return {
        ...state,
        loadingBillsAndServices: Loading.SUCCESS,
        billsAndServices: action.payload,
      };

    case BillsConstants.BILLS_AND_SERVICES_FAILURE:
      return {
        ...state,
        loadingBillsAndServices: Loading.ERROR,
      };

    default:
      return state;
  }
};

export default billsAndServicesReducer;
