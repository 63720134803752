import CustomerDashboard from "./atom/customer/CustomerDashboard";
import ManagerDashboard from "./atom/manager/ManagerDashboard";
import CashierDashboard from "./atom/cashier/CashierDashboard";
import { userTypes } from "../../../utils/helpers/constants";

const Body = ({
  profile,
  domainBrand,
  recentHistory,
  statement,
  graphData,
  accounts,
}) => {
  const DashboardComponents = () => ({
    [userTypes.CUSTOMER]: (
      <CustomerDashboard
        profile={profile}
        domainBrand={domainBrand}
        recentHistory={recentHistory}
        accounts={accounts}
        statement={statement}
        graphData={graphData}
      />
    ),
    [userTypes.CASHIER]: (
      <CashierDashboard profile={profile} domainBrand={domainBrand} />
    ),
    [userTypes.MANAGER]: (
      <ManagerDashboard profile={profile} domainBrand={domainBrand} />
    ),
  });

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">Dashboard</h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>
        {DashboardComponents()[profile["userType"]]}
      </div>
    </div>
  );
};

export default Body;
