import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEye } from "react-icons/fi";
import DataTable from "../../../../components/dataTable/dataTable";
import { currentOpeningDataTableConfig } from "../../../../utils/helpers/dataTableConfigs";

function Body({ domainBrand }) {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const actionColumn = {
    Header: () => {
      return (
        <span className="text-center fw-bold">
          {languageStatus ? dictionary["l_action"] : "Action"}
        </span>
      );
    },
    columnId: 10,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-start pointer">
          <OverlayTrigger
            key={"1"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${`1`}`}>View</Tooltip>}
          >
            <FiEye />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const currentBalanceTable = currentOpeningDataTableConfig(
    languageStatus,
    dictionary
  );

  const balanceTable = [...currentBalanceTable, actionColumn];

  const columns = useMemo(() => balanceTable, []);

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {dictionary
                ? dictionary["title_current_opening_balances"]
                : "Current Opening Balance"}
            </h1>
          </div>
        </div>

        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title fw-bold">Balances</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable columns={columns} data={[]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
