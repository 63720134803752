import axios from "axios";
import { getBaseUrl } from "./baseUrl";
import { toast } from "react-hot-toast";

const instance = axios.create({
  baseURL: getBaseUrl(),
});

export const setAuthHeader = (token) => {
  instance.defaults.headers.common["X-Auth-Token"] = token;
};

// console.log(instance, "pp");

instance.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      platform: "fincode",
      UUID: "200",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log("Internal server error", error);
    // console.log(error.response);
    if (error === 500) {
      toast.error("Internal server error");
      console.log("Internal server error", error);
    } else return error.response;
  }
);

export default instance;
