import Axios from "../services/axios.service";

export const fetchKYCRules = async () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/compliance/list-kyc-rules`,
  });
};

export const fetchAMLRules = async () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/compliance/list-aml-rules`,
  });
};

export const fetchMandatoryFields = async (type) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/complaince/required-fields-by-operation/${type}`,
  });
};

export const fetchComplianceRequirements = async () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/complaince/awaiting-compliance-requirements`,
  });
};

export const fetchRequiredTransDocuments = async (payload) => {
  return Axios({
    method: "POST",
    url: `web-services/api/v6/services/complaince/documents-required-for-transaction`,
    data: payload,
  });
};

export const createKycRule = async (payload) => {
  return Axios({
    method: "POST",
    url: `web-services/api/v6/services/admin/compliance/add-kyc-rule`,
    data: payload,
  });
};

export const editKycRule = async (payload) => {
  return Axios({
    method: "PUT",
    url: `web-services/api/v6/services/admin/compliance/edit-kyc-rule`,
    data: payload,
  });
};

export const deleteKycRule = async (id) => {
  return Axios({
    method: "DELETE",
    url: `web-services/api/v6/services/admin/compliance/delete-kyc-rule/${id}`,
  });
};

export const createAmlRules = async (payload) => {
  return Axios({
    method: "POST",
    url: `/web-services/api/v6/services/admin/compliance/create-aml-rules`,
    data: payload,
  });
};

export const deleteAmlRule = async (id) => {
  return Axios({
    method: "DELETE",
    url: `/web-services/api/v6/services/admin/compliance/delete-aml-rule/${id}`,
  });
};

export const editAmlRule = async (payload) => {
  return Axios({
    method: "DELETE",
    url: `/web-services/api/v6/services/admin/compliance/update-aml-rules`,
  });
};

export const activateAmlRule = async (id) => {
  return Axios({
    method: "PUT",
    url: `/web-services/api/v6/services/admin/compliance/activate-aml-rule/${id}`,
  });
};

export const deactivateAmlRule = async (id) => {
  return Axios({
    method: "PUT",
    url: `/web-services/api/v6/services/admin/compliance/deactivate-aml-rule/${id}`,
  });
};
