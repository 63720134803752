import * as CustomerProductsList from "../constants/customer-products.constants";
import { Loading } from "../../utils/helpers/constants";

const initialState = {
  customerLists: [],
  loadingCustomerList: "",
  loadingRequest: "",
  requestList: [],
};

const customerProductsRedcucer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerProductsList.FETCH_CUSTOMER_PRODUCTS_LIST_REQUEST:
      return { ...state, loadingCustomerList: Loading.FETCHING };

    case CustomerProductsList.FETCH_CUSTOMER_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        customerLists: action.payload,
        loadingCustomerList: Loading.SUCCESS,
      };

    case CustomerProductsList.FETCH_CUSTOMER_PRODUCTS_LIST_FAILURE:
      return { ...state, customerLists: action.payload };

    case CustomerProductsList.GET_MANAGER_APPROVAL_REQUEST:
      return { ...state, loadingRequest: Loading.FETCHING };

    case CustomerProductsList.GET_MANAGER_APPROVAL_SUCCESS:
      return {
        ...state,
        requestList: action.payload,
        loadingRequest: Loading.SUCCESS,
      };

    case CustomerProductsList.GET_MANAGER_APPROVAL_FAILURE:
      return {
        ...state,
        requestList: action.payload,
        loadingRequest: Loading.FAILED,
      };

    default:
      return state;
  }
};

export default customerProductsRedcucer;
