import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line} from 'recharts';

const root = document.documentElement;
const themeColor = root.style.getPropertyValue('--label-color') || "#6259CA";
const LineXChart = ({data, status, dictionary}) => {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart width={500} height={400} data={data} margin={{top: 10, right: 30, left: 1, bottom: 0}}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="month" padding={{top: 30, right: 30}}/>
        <YAxis label={{
          value: [status ? dictionary["WO_ACCOUNT_BALANC_-1400513605"] : "Account Balance"],
          angle: -90,
          position: "insideLeft"
        }}/>
        <Tooltip/>
        <Line type="monotone" unit="M" strokeLinecap="round" strokeWidth={2} style={{strokeDasharray: `40% 60%`}}
              dataKey="close" stroke={themeColor} dot={false} legendType="none"
        />
        <Area type="monotone" name={status ? dictionary["WO_ACCOUNT_BALANC_-1400513605"] : "Account Balance"}
              dataKey="walletBalance" stroke={themeColor} fill={themeColor} activeDot={{r: 8}}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
};

export default LineXChart;
