import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getCountries } from "../../../../store/actions/country.action";
import { fetchExchangeRate } from "../../../../store/actions/rate.action";
import { Loading } from "../../../../utils/helpers/constants";

import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";

const ExchangeRates = () => {
  const dispatch = useDispatch();

  const { domainBrand, component, country, rate, profile } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      countryReducer,
      rateReducer,
      profileReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      country: countryReducer,
      rate: rateReducer,
      profile: profileReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    rate.loadingRates === Loading.FETCHING;

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (rate.loadingRates !== Loading.SUCCESS) dispatch(fetchExchangeRate());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="settings"
              active="rates"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              currency={country.currencies}
              rate={rate?.exchangeRates?.data?.exchangeRateFeeds}
              userId={profile?.userData?.id}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default ExchangeRates;
