import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import ToggleUserStatusModal from "../../../../components/modals/ToggleUserStatusModal";
import avatar from "../../../../assets/images/gravatar.png";
import goBackSvg from "../../../../assets/images/svgs/go-back.svg";
import editSvg from "../../../../assets/images/svgs/edit.svg";
import enabledSvg from "../../../../assets/images/svgs/enabled.svg";
import disabledSvg from "../../../../assets/images/svgs/disabled.svg";
import CreateUser from "./CreateUser";
import UpdateUser from "./UpdateUser";

const Body = ({ domainBrand, toggled, users, profile, phoneCodes }) => {
  const [renderType, setRenderType] = useState("CREATE");
  const [toggleRender, setToggleRender] = useState(false);
  const [updateRenderProps, setUpdateRenderProps] = useState("");
  const [toggleRenderProps, setToggleRenderProps] = useState("");
  const [searchInput, updateSearchInput] = useState("");
  const [filteredList, updateFilteredList] = useState(users);
  const [loadMore, updateLoadMore] = useState(false);
  const size = loadMore ? filteredList.length : 8;
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const filterSearch = (value) => {
    return users.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  useEffect(() => {
    updateFilteredList(filterSearch(searchInput));
  }, [searchInput]);

  const updateUserRender = (userData) => {
    setRenderType("UPDATE");
    setUpdateRenderProps(userData);
  };

  const createUserRender = () => {
    setRenderType("CREATE");
    setUpdateRenderProps("");
  };

  const toggleUserAction = (data) => {
    console.log(data);
    setToggleRenderProps(data);
    setToggleRender(true);
  };

  const changeToggleRenderStatus = () => setToggleRender(false);
  const toggleModalRender = toggleRender ? (
    <ToggleUserStatusModal
      onchange={changeToggleRenderStatus}
      toggledSuccess={toggled}
      toggleProps={toggleRenderProps}
    />
  ) : null;

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          {toggleModalRender}
          <div>
            <h1 className="page-title">
              {languageStatus ? dictionary["title_users"] : "Users"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn">
            <NavLink
              to="/request-account"
              className="btn btn-primary btn-icon text-white me-2"
            >
              <span>
                <i className="fa fa-wallet" />
              </span>{" "}
              &nbsp;
              {languageStatus
                ? dictionary["WO_REQUEST_BANK_ACCOUN_1842427382"]
                : "Request an account"}
            </NavLink>
          </div>
        </div>
        <div className="row row-sm mb-4">
          <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
            <div className="card overflow-hidden height-100">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary["para_your_users_accounts"]
                    : "Users"}
                </h3>
              </div>
              <div className="main-content-app pt-0">
                <div className="main-content-left main-content-left-chat">
                  <div className="card-body">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={searchInput}
                        onChange={(event) =>
                          updateSearchInput(event.target.value)
                        }
                        placeholder="Search for a user.."
                      />
                      <button className="input-group-text btn btn-primary">
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="tab-content main-chat-list">
                    <div className="tab-pane active">
                      {filteredList.slice(0, size).map((forEach, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center media"
                        >
                          <div className="mb-0 me-2">
                            <div className="main-img-user online">
                              <img alt="" src={avatar} />
                            </div>
                          </div>
                          <div className="align-items-center justify-content-between">
                            <div className="media-body ms-2">
                              <div className="media-contact-name">
                                <span>{`${forEach["firstName"]} ${forEach["lastName"]}`}</span>
                                <span />
                              </div>
                            </div>
                          </div>
                          <div className="ms-auto">
                            <div className="text-center pointer">
                              <img
                                onClick={() => updateUserRender(forEach)}
                                src={editSvg}
                                alt="edit"
                              />{" "}
                              &nbsp;
                              {forEach.status === "DEACTIVATED" ? (
                                <img
                                  onClick={() => toggleUserAction(forEach)}
                                  src={disabledSvg}
                                  alt="edit"
                                />
                              ) : (
                                <img
                                  onClick={() => toggleUserAction(forEach)}
                                  src={enabledSvg}
                                  alt="edit"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                      {filteredList.length < 8 ? (
                        ""
                      ) : (
                        <div
                          onClick={() => updateLoadMore(!loadMore)}
                          className="load-more"
                        >
                          <span className="">
                            {loadMore ? "Show less" : "Load More"}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
            <div className="card overflow-hidden height-100">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {renderType === "CREATE" ? (
                    languageStatus ? (
                      dictionary["WO_ADD_NEW_USE_1247195183"]
                    ) : (
                      "Users"
                    )
                  ) : (
                    <>
                      <img
                        onClick={createUserRender}
                        alt=""
                        className="mr-2rem pointer"
                        src={goBackSvg}
                      />
                      {languageStatus
                        ? dictionary["title_update_details"]
                        : "Update Users"}
                    </>
                  )}
                </h3>
              </div>
              {renderType === "CREATE" ? (
                <CreateUser
                  domainBrand={domainBrand}
                  profile={profile}
                  phoneCodes={phoneCodes}
                />
              ) : (
                <UpdateUser
                  domainBrand={domainBrand}
                  profile={profile}
                  userDetails={updateRenderProps}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
