import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../../components/footer/Footer";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { fetchPayCentres } from "../../../../store/actions/transfer.action";
import { Loading } from "../../../../utils/helpers/constants";
import Body from "./Body";

function ApiReport() {
  const dispatch = useDispatch();
  const { component, domainBrand, transfer } = useSelector(
    ({ componentReducer, domainBrandReducer, transferReducer }) => ({
      component: componentReducer,
      domainBrand: domainBrandReducer,
      transfer: transferReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    transfer.loadingPayCentres === Loading.FETCHING;

  useEffect(() => {
    if (transfer.loadingPayCentres !== Loading.SUCCESS)
      dispatch(fetchPayCentres());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="reports"
              active="reports"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              payCentres={transfer.paycentreList}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
}

export default ApiReport;
