import { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { transactionMethodsMap } from "../../utils/helpers/mappers";
import { cancelTransactionPayment } from "../../store/actions/transfer.action";

const CancelTransactionModal = ({
  onchange,
  cancelProps,
  domainBrand,
  callTransactionHistory,
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validateInput, setValidateInput] = useState("");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const transactionType =
    dictionary[
      transactionMethodsMap[cancelProps.transactionType].title
    ].toLowerCase();
  const amount = `${cancelProps["principalCurrencyCode"]} ${cancelProps.principalAmount}`;
  const disabledState = validateInput === "";

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const cancelTransaction = async () => {
    setLoading(true);
    const payload = {
      password: validateInput,
      pcn: cancelProps.pcn,
      payableType: cancelProps["payableItemType"],
    };
    await dispatch(cancelTransactionPayment(payload));
    callTransactionHistory();
    setLoading(false);
    handleClose();
  };

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="m-4">
            <strong>
              {dictionary["l_cancel_transaction"]} - PCN ({cancelProps.pcn})
            </strong>
            <hr />
            <div className="mb-4">
              Do you want to cancel this {transactionType} transaction of{" "}
              <strong>{amount}</strong> sent to
              <strong>{` ${cancelProps.receiver}`}</strong>, with pcn{" "}
              <strong>{cancelProps.pcn}</strong>?
            </div>
            <div className="mb-4">
              You can send in a support request about this information, and may
              choose not to continue with this action.
            </div>
            <div className="mb-5 text-danger">
              <strong>This action cannot be undone</strong>
            </div>
            <input
              type="password"
              className="form-control"
              placeholder={dictionary["WO_PLEASE_ENTER_PASSWOR_-1313224274"]}
              value={validateInput}
              onChange={(event) => setValidateInput(event.target.value)}
            />
          </div>
          <div className="float-end ">
            <Button
              className="ms-lg-4"
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </Button>{" "}
            &nbsp;
            <Button
              disabled={disabledState}
              className="mr-2"
              variant="danger"
              onClick={cancelTransaction}
            >
              Delete{" "}
              {loading ? (
                <>
                  &nbsp;
                  <i className="fa fa-spin fa-spinner" />
                </>
              ) : (
                ""
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CancelTransactionModal;
