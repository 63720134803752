import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loading } from "../../../../utils/helpers/constants";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { fetchCorridors } from "../../../../store/actions/corridor.action";

const Corridors = () => {
  const dispatch = useDispatch();

  const { domainBrand, component, corridor } = useSelector(
    ({ domainBrandReducer, componentReducer, corridorReducer }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      corridor: corridorReducer,
    })
  );
  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    corridor.loadingCorridor === Loading.FETCHING;

  useEffect(() => {
    if (corridor.loadingCorridor !== Loading.SUCCESS) {
      dispatch(fetchCorridors());
    }
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="settings"
              active="Corridors"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              corridor={corridor?.exchangeRates?.data?.corridorList}
            />
          </div>
        </>
      )}
      <Footer domainBrand={domainBrand} />
    </div>
  );
};

export default Corridors;
