import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import Select from "react-select";

import FilledBankFields from "../../../../../../components/bankFields/FilledBankFields";
import SphereLoader from "../../../../../../components/loaders/SphereLoader";
import BankFields from "../../../../../../components/bankFields/BankFields";
import DeleteModal from "../../../../../../components/modals/DeleteModal";
import { deleteBankDetailsFromBeneficiary } from "../../../../../../utils/services/users.services";
import {
  fetchRequiredBankFields,
  fetchSupportedBanks,
} from "../../../../../../utils/services/transfer.service";
import {
  findItemInArray,
  formatSupportedBanksDropdown,
} from "../../../../../../utils/helpers/logicHelper";
import {
  customerTypeDropdown,
  relationshipOptions,
} from "../../../../../../utils/helpers/objectHelpers";
import {
  createBeneficiaryBankDetails,
  updateBeneficiaryBankDetails,
} from "../../../../../../store/actions/users.action";
import {
  bankPairs,
  customerTypes,
  Loading,
} from "../../../../../../utils/helpers/constants";

const Body = ({
  countries,
  beneficiaryBanks,
  formik,
  properties,
  updated,
  setMandatoryValidator,
}) => {
  const {
    individualFields,
    companyFields,
    beneficiary,
    currencies,
    domainBrand,
  } = properties;
  const dispatch = useDispatch(),
    history = useHistory();
  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const customerTypesDropdown = customerTypeDropdown(
    languageStatus,
    dictionary
  );
  const [loading, updateLoading] = useState(false);
  const [updateType, setUpdateType] = useState(true);
  const [deleteRender, setDeleteRender] = useState(false);
  const [loadingDetails, updateLoadingDetails] = useState(false);
  const [loadingFields, updateLoadingFields] = useState(false);
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [bankFields, updateBankFields] = useState([]);
  const [visibleFields, setVisibleFields] = useState([]);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [supportedBanks, updateSupportedBanks] = useState([]);
  const [selectedBank, updateSelectedBank] = useState({});
  const [deleteRenderProps, setDeleteRenderProps] = useState({});
  const noneObject = [
    {
      label: languageStatus ? dictionary["NON_1239141700"] : "None",
      value: "NONE",
    },
  ];
  const currencyDropdown = [...noneObject, ...currencies];

  useEffect(() => {
    if (
      formik.values.type.value === customerTypes.INDIVIDUAL &&
      "requiredFields" in individualFields
    ) {
      setMandatoryFields(individualFields.requiredFields);
      setMandatoryValidator(individualFields.requiredFields);
      setVisibleFields(individualFields.visibleFields);
    } else if (
      formik.values.type.value === customerTypes.COMPANY &&
      "requiredFields" in companyFields
    ) {
      setMandatoryFields(companyFields.requiredFields);
      setMandatoryValidator(companyFields.requiredFields);
      setVisibleFields(companyFields.visibleFields);
    }
  }, [formik.values.type, individualFields, companyFields]);

  useEffect(async () => {
    updateLoadingDetails(true);
    updateLoadingFields(true);
    if (Object.entries(country).length > 0) {
      const path = `${country.value}?delivery_method=ACCOUNTPAYMENT&currency_ode=${currency.value}`;
      const method =
        currency.value === "NONE" || currency.value === "" || currency === ""
          ? country.value
          : path;
      await fetchBankFields(method);
      await fetchSupportedCountryBank();
    }
    updateLoadingDetails(false);
    updateLoadingFields(false);
  }, [country, currency, formik.values.bankDetails]);

  const fetchBankFields = async (method) => {
    const { data: responseData } = await fetchRequiredBankFields(method);
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch required bank fields, try again`);
    else {
      await formik.setFieldValue("bankFields", responseData.data["bankFields"]);
      updateBankFields(responseData.data["bankFields"]);
      if (updateType) {
        let formikMap, validOptions;
        validOptions = Object.keys(
          responseData.data["requiredBankFields"] || {}
        )
          .filter(
            (value) => responseData.data["requiredBankFields"][value] === true
          )
          .map((value) => {
            let newValue;
            if (value.includes("Required"))
              newValue = value.split("Required")[0];
            else if (value.includes("iban")) newValue = "ibanCode";
            else if (value.includes("required"))
              newValue = value.split("required")[0];
            return newValue;
          });
        validOptions.forEach(
          (element) =>
            (formikMap = {
              ...formikMap,
              ...{ [bankPairs[element]]: selectedBank[element] },
            })
        );
        formik.setValues({
          ...formik.values,
          ...formikMap,
          bankFields: [...responseData.data["bankFields"]],
        });
      }
    }
  };

  const fetchSupportedCountryBank = async () => {
    const { data: responseData } = await fetchSupportedBanks(country.value);
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch supported banks, try again`);
    else {
      const formattedBank = formatSupportedBanksDropdown(
        responseData.data["bankDetails"]
      );
      updateSupportedBanks(formattedBank);
    }
  };

  const setBankFieldsOnUpdate = (option) => {
    updateSelectedBank(option);
    formik.setFieldValue("bankDetails", option);
    const country = findItemInArray(countries, option.country);
    if (option["currencyCode"]) {
      const currency = findItemInArray(
        currencyDropdown,
        option["currencyCode"]
      );
      setCurrency(currency);
      setCountry(country);
    } else {
      setCurrency("");
      setCountry(country);
    }
  };

  const setBankFieldsOnCreate = (option) => {
    updateSelectedBank(option);
    formik.setFieldValue("bankName", option.name);
  };

  const toggleBankDetailsType = (event, state) => {
    event.preventDefault();
    setUpdateType(state);
    formik.setFieldValue("bankDetails", null);
  };

  const deleteBeneficiaryBank = () => {
    setDeleteRender(true);
    setDeleteRenderProps(selectedBank);
  };

  const updateBeneficiaryDetails = async () => {
    console.log(mandatoryFields);
    updateLoading(true);
    let bankDetails = {},
      inclusionList = [],
      bankAccountMetaData = [];
    if (currency.value && currency.value !== "NONE")
      bankDetails.currencyCode = currency.value;
    formik?.values?.bankFields?.forEach((element) =>
      inclusionList.push(element["apiName"])
    );
    const bankName = selectedBank.bankName ?? selectedBank.name;
    inclusionList.forEach((element) => {
      if (element === bankPairs.bankName)
        bankAccountMetaData.push({ key: "bankname", value: bankName });
      else if (element === bankPairs.country)
        bankAccountMetaData.push({ key: "country", value: country.value });
      else
        bankAccountMetaData.push({
          key: element,
          value: formik.values[element],
        });
    });
    bankDetails = {
      bankAccountMetaData,
      id: selectedBank.id,
      customerId: formik.values.id,
      accountOwnerCustomerCode: beneficiary["senderCode"],
    };
    if (updateType) {
      bankDetails.country = selectedBank.country;
      await dispatch(updateBeneficiaryBankDetails(bankDetails));
      updated(bankDetails);
    } else {
      bankDetails.country = country.value;
      await dispatch(createBeneficiaryBankDetails(bankDetails));
      updated(bankDetails);
    }
    updateLoading(false);
  };

  const deleteBank = async (data) => {
    await deleteBankDetailsFromBeneficiary(data.customerId, data.id);
    const mods = { state: { ...history.location.state, modified: true } };
    const newHistory = { ...history.location, ...mods };
    await history.replace(newHistory);
  };
  const changeDeleteRenderStatus = () => setDeleteRender(false);
  const deleteModalRender = deleteRender ? (
    <DeleteModal
      onchange={changeDeleteRenderStatus}
      deleteProps={deleteRenderProps}
      propsType="BENEFICIARY_BANK"
      onDeleteResource={deleteBank}
    />
  ) : null;

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          {deleteModalRender}
          <div>
            <h1 className="page-title">Update Beneficiary</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to={`/beneficiaries`}>Beneficiaries</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {`${beneficiary.firstName} ${beneficiary.lastName}`}
              </li>
            </ol>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>
        <div className="row row-sm">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary["title_update_beneficiary_details"]
                    : "Update"}
                </h3>
              </div>
              <div className="card-body">
                <form
                  className="needs-validation"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="form-row">
                    <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                      <label>UserType</label>
                      <Select
                        options={customerTypesDropdown}
                        isClearable={false}
                        isSearchable={false}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="type"
                        defaultValue={customerTypesDropdown[0]}
                        value={formik.values.type}
                        onChange={(option) =>
                          formik.setFieldValue("type", option)
                        }
                      />
                    </div>

                    {formik.values.type.value === customerTypes.INDIVIDUAL ? (
                      <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                        <label>
                          {languageStatus
                            ? dictionary["para_first_name"]
                            : "First name"}
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          className={`form-control ${
                            formik.errors["firstName"]
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                        />
                        {formik.errors["firstName"] ? (
                          <div className="invalid-feedback">
                            Please provide first name.
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {formik.values.type.value === customerTypes.INDIVIDUAL ? (
                      !visibleFields.some(
                        (o) => o["fieldType"] === "MIDDLE_NAME"
                      ) ? (
                        ""
                      ) : (
                        <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                          <label>
                            {languageStatus
                              ? dictionary["WO_MIDDLE_NAM_1847397348"]
                              : "Middle name"}
                          </label>
                          <input
                            type="text"
                            name="middleName"
                            className={`form-control ${
                              formik.errors["middleName"]
                                ? "is-invalid"
                                : "is-valid"
                            }`}
                            value={formik.values.middleName}
                            onChange={formik.handleChange}
                          />
                        </div>
                      )
                    ) : (
                      ""
                    )}

                    {formik.values.type.value === customerTypes.INDIVIDUAL ? (
                      <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                        <label>
                          {languageStatus
                            ? dictionary["l_last_name"]
                            : "Last Name"}
                        </label>
                        <input
                          className={`form-control ${
                            formik.errors["lastName"]
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          type="text"
                          name="lastName"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                        />
                        {formik.errors["lastName"] ? (
                          <div className="invalid-feedback">
                            Please provide last name.
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {formik.values.type.value === customerTypes.COMPANY ? (
                      <>
                        <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                          <label>
                            {languageStatus
                              ? dictionary["WO_COMPANY_NAM_-1629274032"]
                              : "Company name"}
                          </label>
                          <input
                            className={`form-control ${
                              formik.errors["companyName"]
                                ? "is-invalid"
                                : "is-valid"
                            }`}
                            type="text"
                            name="companyName"
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                          />
                          {formik.errors["companyName"] ? (
                            <div className="invalid-feedback">
                              Please provide company name.
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                          <label>
                            {languageStatus
                              ? dictionary["WO_REGISTRATION_NUMBE_1407955590"]
                              : "Reg. Number"}
                          </label>
                          <input
                            type="text"
                            name="regNumber"
                            className={`form-control ${
                              formik.errors["regNumber"]
                                ? "is-invalid"
                                : "is-valid"
                            }`}
                            value={formik.values.regNumber}
                            onChange={formik.handleChange}
                          />
                          {formik.errors["regNumber"] ? (
                            <div className="invalid-feedback">
                              Please provide registration number.
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {!visibleFields.some(
                      (o) =>
                        o["fieldType"] === "EMAIL" ||
                        o["fieldType"] === "COMPANY_EMAIL"
                    ) ? (
                      ""
                    ) : (
                      <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                        <label>
                          {languageStatus ? dictionary["l_email"] : "Email"}
                        </label>
                        <input
                          type="email"
                          name="email"
                          className={`form-control ${
                            formik.errors["email"] ? "is-invalid" : "is-valid"
                          }`}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                        {formik.errors["email"] ? (
                          <div className="invalid-feedback">
                            Email is required.
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    {!visibleFields.some((o) => o["fieldType"] === "PHONE") ? (
                      ""
                    ) : (
                      <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                        <label>
                          {languageStatus
                            ? dictionary["para_phone_number"]
                            : "Phone Number"}
                        </label>
                        <input
                          type="text"
                          name="phone"
                          className={`form-control ${
                            formik.errors["phone"] ? "is-invalid" : "is-valid"
                          }`}
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                        />
                        {formik.errors["phone"] ? (
                          <div className="invalid-feedback">Required field</div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    {!visibleFields.some(
                      (o) => o["fieldType"] === "ADDRESS_1"
                    ) ? (
                      ""
                    ) : (
                      <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                        <label>
                          {languageStatus
                            ? dictionary["para_address_line_1"]
                            : "Address line 1"}
                        </label>
                        <input
                          type="text"
                          name="address1"
                          className={`form-control ${
                            formik.errors["address1"]
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          value={formik.values.address1}
                          onChange={formik.handleChange}
                        />
                        {formik.errors["address1"] ? (
                          <div className="invalid-feedback">
                            Field is required
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    {!visibleFields.some(
                      (o) => o["fieldType"] === "ADDRESS_2"
                    ) ? (
                      ""
                    ) : (
                      <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                        <label>
                          {languageStatus
                            ? dictionary["para_address_line_2"]
                            : "Address line 2"}
                        </label>
                        <input
                          className={`form-control ${
                            formik.errors["address2"]
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          type="text"
                          name="address2"
                          value={formik.values.address2}
                          onChange={formik.handleChange}
                        />
                      </div>
                    )}

                    {!visibleFields.some((o) => o["fieldType"] === "CITY") ? (
                      ""
                    ) : (
                      <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                        <label>
                          {languageStatus ? dictionary["para_city"] : "City"}
                        </label>
                        <input
                          type="text"
                          name="city"
                          className={`form-control ${
                            formik.errors["city"] ? "is-invalid" : "is-valid"
                          }`}
                          value={formik.values.city}
                          onChange={formik.handleChange}
                        />
                      </div>
                    )}

                    {!visibleFields.some(
                      (o) => o["fieldType"] === "POSTCODE"
                    ) ? (
                      ""
                    ) : (
                      <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                        <label>
                          {languageStatus
                            ? dictionary["para_postcode"]
                            : "Post code"}
                        </label>
                        <input
                          className={`form-control ${
                            formik.errors["postcode"]
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          type="text"
                          name="postcode"
                          value={formik.values.postcode}
                          onChange={formik.handleChange}
                        />
                      </div>
                    )}

                    {!visibleFields.some((o) => o["fieldType"] === "STATE") ? (
                      ""
                    ) : (
                      <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                        <label>
                          {languageStatus
                            ? dictionary["WO_STAT_-878960465"]
                            : "State"}
                        </label>
                        <input
                          type="text"
                          name="state"
                          className={`form-control ${
                            formik.errors["state"] ? "is-invalid" : "is-valid"
                          }`}
                          value={formik.values.state}
                          onChange={formik.handleChange}
                        />
                      </div>
                    )}

                    <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
                      <label>
                        {languageStatus ? dictionary["l_country"] : "Country"}
                      </label>
                      <Select
                        options={countries}
                        isClearable={false}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="country"
                        value={formik.values.country}
                        onChange={(option) =>
                          formik.setFieldValue("country", option)
                        }
                      />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["WO_BENEFICIARY_RELATION_1083165793"]
                          : "Relationship"}
                      </label>
                      <Select
                        options={relationshipOptions(formik.values.type.value)}
                        isClearable={false}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="country"
                        value={formik.values.relationShip}
                        onChange={(option) =>
                          formik.setFieldValue("relationShip", option)
                        }
                      />
                    </div>

                    <button
                      className="btn btn-primary mt-4 float-end mb-0"
                      type="submit"
                    >
                      {languageStatus
                        ? dictionary["WO_UPDATE_DETAIL_1271084717"]
                        : "Update"}
                      {formik.values["submitting"] ? (
                        <>
                          &nbsp; <i className="fa fa-spin fa-spinner" />{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary["BANK_DETAIL_2044985812"]
                    : "Bank Details"}
                </h3>
              </div>
              <div className="card-body">
                <div className="card-pay">
                  <ul className="double tabs-menu nav">
                    <li className="">
                      <NavLink
                        onClick={(event) => toggleBankDetailsType(event, false)}
                        exact
                        to=""
                        className={updateType ? "" : "active"}
                      >
                        Add Bank Details
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={(event) => toggleBankDetailsType(event, true)}
                        exact
                        to=""
                        className={!updateType ? "" : "active"}
                      >
                        Update Bank Details
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <form className="needs-validation">
                  <div className="form-row">
                    {updateType ? (
                      <div className="col-md-12 col-lg-12 mb-3">
                        <Select
                          options={beneficiaryBanks}
                          noOptionsMessage={() => "No beneficiary bank added"}
                          isClearable={false}
                          maxMenuHeight={250}
                          menuPlacement="bottom"
                          menuPortalTarget={menuPortalTarget}
                          name="bankDetails"
                          value={formik.values.bankDetails}
                          onChange={(option) => setBankFieldsOnUpdate(option)}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="col-md-12 col-lg-12 mb-3">
                          <label>
                            {languageStatus
                              ? dictionary["WO_SELECT_BANK_ACCOUNT_-1095805078"]
                              : "Country"}
                          </label>
                          <Select
                            options={countries}
                            isClearable={false}
                            maxMenuHeight={250}
                            menuPlacement="bottom"
                            menuPortalTarget={menuPortalTarget}
                            value={country}
                            onChange={(option) => setCountry(option)}
                          />
                        </div>
                        <div className="col-md-12 col-lg-12 mb-3">
                          <label>Select bank account currency (optional)</label>
                          <Select
                            options={currencyDropdown}
                            isClearable={false}
                            maxMenuHeight={250}
                            menuPlacement="bottom"
                            menuPortalTarget={menuPortalTarget}
                            value={currency}
                            onChange={(option) => setCurrency(option)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {loadingDetails ? (
                    <SphereLoader />
                  ) : (
                    <>
                      {updateType && !loadingFields ? (
                        <>
                          <FilledBankFields
                            bankFields={bankFields}
                            formik={formik}
                          />
                        </>
                      ) : (
                        <>
                          <div className="form-row">
                            <div className="col-md-12 col-lg-12 mb-3">
                              <label>
                                {languageStatus
                                  ? dictionary["l_bank_name"]
                                  : "Bank Name"}
                              </label>
                              <Select
                                options={supportedBanks}
                                isClearable={false}
                                maxMenuHeight={250}
                                menuPlacement="bottom"
                                menuPortalTarget={menuPortalTarget}
                                onChange={(option) =>
                                  setBankFieldsOnCreate(option)
                                }
                              />
                            </div>
                          </div>
                          <BankFields bankFields={bankFields} formik={formik} />
                        </>
                      )}
                      {updateType ? (
                        <i
                          style={{ color: "#f82649" }}
                          onClick={deleteBeneficiaryBank}
                          className="fa fa-trash mt-5 pt-1 pointer"
                        />
                      ) : (
                        ""
                      )}
                      <div className="form-row float-end mt-4 mb-0">
                        <button
                          onClick={updateBeneficiaryDetails}
                          className="btn btn-primary"
                          type="button"
                        >
                          {updateType === true ? (
                            <>
                              {languageStatus
                                ? dictionary["WO_UPDATE_DETAIL_1271084717"]
                                : "Update"}
                            </>
                          ) : (
                            <>Add Bank Details</>
                          )}

                          {loading ? (
                            <>
                              &nbsp; <i className="fa fa-spin fa-spinner" />{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
