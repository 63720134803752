import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import ForgotPassword from "./pages/auth/forgotPassword/ForgotPassword";
import KycUpload from "./pages/auth/kycUpload/KycUpload";
import Login from "./pages/auth/login/Login";
import SignUp from "./pages/auth/signUp/SignUp";
import AccountCreation from "./pages/dashboard/crm/account-creation/AccountCreation";
import Customers from "./pages/dashboard/crm/customers/Customers";
import Home from "./pages/dashboard/home/Home";
import Users from "./pages/dashboard/management/users/Users";
import GetSender from "./pages/dashboard/transaction/getSender/GetSender";
// import CreateUser from "./pages/dashboard/management/users/CreateUser";
import AmlRules from "./pages/dashboard/compliance/aml/AmlRules";
import KycRules from "./pages/dashboard/compliance/kyc/KycRules";
import UpdateBeneficiary from "./pages/dashboard/crm/beneficiaries/atom/update/UpdateBeneficiary";
import Beneficiaries from "./pages/dashboard/crm/beneficiaries/Beneficiaries";
import Agent from "./pages/dashboard/management/agents/Agent";
import EmployeesDetails from "./pages/dashboard/management/employees/atom/Details";
import AddNewEmployee from "./pages/dashboard/management/employees/atom/Employee-Forms/AddNewEmployee/AddNewEmployee";
import Employees from "./pages/dashboard/management/employees/Employees";
import TransferHistory from "./pages/dashboard/transaction/transferHistory/TransferHistory";
import Transfers from "./pages/dashboard/transaction/transfers/Transfers";
// import PaymentMethod from "./pages/dashboard/transaction/paymentMethod/PaymentMethod";
import DefaultMeta from "./components/meta/DefaultMeta";
import CustomersProducts from "./pages/dashboard/crm/customer-products/CustomersProducts";
import Profile from "./pages/dashboard/general/profile/Profile";
import Support from "./pages/dashboard/general/support/Support";
import Cart from "./pages/dashboard/merchants/cart/Cart";
import ProductDetails from "./pages/dashboard/merchants/productDetails/ProductDetails";
import Products from "./pages/dashboard/merchants/products/Products";
import ApiReport from "./pages/dashboard/report/api-report/ApiReport";
import BillsAndServices from "./pages/dashboard/report/bills-and-services/BillsAndServices";
import CashierBalance from "./pages/dashboard/report/cashier-balance/CashierBalance";
import MyTransaction from "./pages/dashboard/report/my-transaction/MyTransaction";
import AccMaintenanceFee from "./pages/dashboard/settings/account-maintenance-fee/AccMaintenanceFee";
import BankConfigCorridor from "./pages/dashboard/settings/bank-account-fields/atom/BankConfigCorridor";
import BankAccountFields from "./pages/dashboard/settings/bank-account-fields/BankAccountFields";
import Corridors from "./pages/dashboard/settings/corridors/Corridors";
import ExchangeRateCorridor from "./pages/dashboard/settings/rates/corridors/ExchangeRateCorridor";
import ExchangeRates from "./pages/dashboard/settings/rates/ExchangeRates";
import RemittanceSettings from "./pages/dashboard/settings/remittance/RemittanceSettings";
import AirtimeTopUp from "./pages/dashboard/transaction/airtimeTopUp/AirtimeTopUp";
import BillPayment from "./pages/dashboard/transaction/billPayment/BillPayment";
import SingleCustomerBeneficiary from "./pages/dashboard/transaction/customer-beneficiary/atom/single-customer/SingleCustomerBeneficiary";
import CustomerBeneficiary from "./pages/dashboard/transaction/customer-beneficiary/CustomerBeneficiary";
import FundAccounts from "./pages/dashboard/transaction/fundAccounts/FundAccounts";
import Overdrafts from "./pages/dashboard/transaction/overdrafts/Overdrafts";
import PayoutSearch from "./pages/dashboard/transaction/payout/atom/search/PayoutSearch";
import Payout from "./pages/dashboard/transaction/payout/Payout";
import SupportedPaymentMethod from "./pages/dashboard/transaction/supportedPayment/SupportedPayment";
import TransactionDetails from "./pages/dashboard/transaction/transactionDetails/TransactionDetails";
import TreasuryAccounts from "./pages/dashboard/transaction/treasury/TreasuryAccounts";
import AccountBalance from "./pages/dashboard/wallet/balance/AccountBalance";
import FundWallet from "./pages/dashboard/wallet/fundWallet/FundWallet";
import RequestAccount from "./pages/dashboard/wallet/requestAccount/RequestAccount";
import AccountStatement from "./pages/dashboard/wallet/statement/AccountStatement";
import Construction from "./pages/landing/construction/Construction";
import Error401 from "./pages/landing/errors/Error401";
import Error404 from "./pages/landing/errors/Error404";

import "./App.css";
import OpeningBalance from "./pages/dashboard/report/opening-balance/OpeningBalance";
import WalletLedger from "./pages/dashboard/report/wallet-ledger/WalletLedger";
import * as DomainBrand from "./store/actions/domain-brand.action";
import { getTransTypes } from "./store/actions/domain-brand.action";
import { configs, Loading } from "./utils/helpers/constants";
import cookieHelper from "./utils/helpers/cookieHelper";
import { fetchAppLanguage } from "./utils/helpers/logicHelper";
import TokenHelpers from "./utils/helpers/tokenHelpers";
import { modifyStyle, modifyStyleWithCSS } from "./utils/helpers/updateStyles";
import { fetchSubDomain } from "./utils/services/baseUrl";

const App = () => {
  const dispatch = useDispatch();
  const subdomain = fetchSubDomain(),
    selectedLanguage = fetchAppLanguage();
  const domainBrand = useSelector(
    ({ domainBrandReducer }) => domainBrandReducer
  );
  const checkIfBrandCSSExists = !(
    typeof domainBrand["domainBrand"]["css"] === "undefined" ||
    domainBrand["domainBrand"]["css"] === null ||
    domainBrand["domainBrand"]["css"] === ""
  );
  const serviceController = cookieHelper.get(configs.SERVICE_CONTROL);

  useEffect(() => {
    if (domainBrand.loading !== Loading.SUCCESS)
      dispatch(
        DomainBrand.getDomainBrandIdentity(subdomain, selectedLanguage["id"])
      );
  }, []);

  useEffect(() => {
    if (checkIfBrandCSSExists)
      modifyStyleWithCSS(domainBrand["domainBrand"]["css"]);
    else modifyStyle(domainBrand["domainBrand"]);
  }, [domainBrand]);

  useEffect(() => {
    if (domainBrand.transTypesLoading !== Loading.SUCCESS)
      dispatch(getTransTypes());
  }, []);

  const IsLoggedInRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        TokenHelpers.checkIfLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  const VerifiedRoute = ({ children, path, ...rest }) => {
    const renderFunc = ({ location }) => {
      const accountStatus = cookieHelper.get(configs.USER_VERIFIED_STATUS);
      const accountVerifiedStatus = !(
        accountStatus === null ||
        accountStatus === "" ||
        accountStatus === "UNVERIFIED"
      );
      return accountVerifiedStatus ? (
        <Switch>{children}</Switch>
      ) : (
        <Redirect
          to={{ pathname: "/kyc_document_upload", state: { from: location } }}
        />
      );
    };
    return <Route {...rest} render={renderFunc} />;
  };

  const TransferEnabled = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        TokenHelpers.checkIfLoggedIn() && serviceController.transfer ? (
          <Component {...props} />
        ) : (
          <Error401 />
        )
      }
    />
  );

  const WalletEnabled = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        TokenHelpers.checkIfLoggedIn() && serviceController.wallet ? (
          <Component {...props} />
        ) : (
          <Error401 />
        )
      }
    />
  );

  const BillPaymentEnabled = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        TokenHelpers.checkIfLoggedIn() && serviceController.billPayment ? (
          <Component {...props} />
        ) : (
          <Error401 />
        )
      }
    />
  );

  const AirtimeTopupEnabled = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        TokenHelpers.checkIfLoggedIn() && serviceController.airtimeTopup ? (
          <Component {...props} />
        ) : (
          <Error401 />
        )
      }
    />
  );

  return (
    <Router>
      <Toaster />
      <DefaultMeta title={domainBrand.domainBrand.companyName} />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <IsLoggedInRoute
          exact
          path="/kyc_document_upload"
          component={KycUpload}
        />
        <IsLoggedInRoute exact path="/support" component={Support} />
        <IsLoggedInRoute exact path="/profile" component={Profile} />
        <IsLoggedInRoute exact path="/management/agents" component={Agent} />

        <VerifiedRoute>
          <IsLoggedInRoute exact path={["/dashboard", "/"]} component={Home} />
          <IsLoggedInRoute exact path="/customers" component={Customers} />
          <IsLoggedInRoute exact path="/users" component={Users} />
          <IsLoggedInRoute
            exact
            path="/account-creation"
            component={AccountCreation}
          />
          {/* <IsLoggedInRoute exact path="/create-users" component={CreateUser} /> */}
          <IsLoggedInRoute
            exact
            path="/beneficiaries"
            component={Beneficiaries}
          />
          <IsLoggedInRoute
            exact
            path="/customer-products"
            component={CustomersProducts}
          />
          <IsLoggedInRoute
            exact
            path="/beneficiaries/update/:name"
            component={UpdateBeneficiary}
          />
          <IsLoggedInRoute
            exact
            path="/management/:name"
            component={Employees}
          />

          <IsLoggedInRoute
            exact
            path="/management/:name/:name"
            component={EmployeesDetails}
          />
          <IsLoggedInRoute
            exact
            path="/addNewEmployee"
            component={AddNewEmployee}
          />
          {/* <IsLoggedInRoute exact path="/management/agents" component={Agent} /> */}
          <IsLoggedInRoute exact path="/kyc-rules" component={KycRules} />
          <IsLoggedInRoute exact path="/aml-rules" component={AmlRules} />

          <IsLoggedInRoute
            exact
            path="/settings/remittance-settings"
            component={RemittanceSettings}
          />
          <IsLoggedInRoute
            exact
            path="/settings/interest-rate"
            component={AccMaintenanceFee}
          />
          <IsLoggedInRoute
            exact
            path="/settings/rates"
            component={ExchangeRates}
          />
          <IsLoggedInRoute exact path="/overdrafts" component={Overdrafts} />
          <IsLoggedInRoute
            exact
            path="/settings/rates/rate-corridor/:id"
            component={ExchangeRateCorridor}
          />
          <IsLoggedInRoute
            exact
            path="/settings/transaction-corridors"
            component={Corridors}
          />
          <IsLoggedInRoute
            excact
            path="/settings/bank-account-settings"
            component={BankAccountFields}
          />
          <IsLoggedInRoute
            excact
            path="/settings/bank-config/:id"
            component={BankConfigCorridor}
          />
          {/*<IsLoggedInRoute
            exact
            path="/transfer/choose-payment-method"
            component={PaymentMethod}
          />*/}
          <IsLoggedInRoute
            exact
            path="/transfer/choose-payment-method"
            component={SupportedPaymentMethod}
          />
          <TransferEnabled
            exact
            path={["/transfer", "/transfers"]}
            component={Transfers}
          />
          <TransferEnabled exact path="/get-sender" component={GetSender} />
          <TransferEnabled
            exact
            path="/transfer-history"
            component={TransferHistory}
          />
          <TransferEnabled
            exact
            path="/transfer/details"
            component={TransactionDetails}
          />
          <IsLoggedInRoute
            exact
            path="/treasury-accounts"
            component={TreasuryAccounts}
          />
          <IsLoggedInRoute
            exact
            path="/fund-accounts"
            component={FundAccounts}
          />
          <IsLoggedInRoute
            exact
            path="/merchant/products"
            component={Products}
          />
          <IsLoggedInRoute
            exact
            path="/report/my-transactions"
            component={MyTransaction}
          />
          <IsLoggedInRoute
            exact
            path="/report/api-transaction-report"
            component={ApiReport}
          />
          <IsLoggedInRoute
            exact
            path="/report/bills-and-services"
            component={BillsAndServices}
          />
          <IsLoggedInRoute
            exact
            path="/report/cashier-opening-balance"
            component={CashierBalance}
          />
          <IsLoggedInRoute
            exact
            path="/report/opening-balance"
            component={OpeningBalance}
          />
          <IsLoggedInRoute
            exact
            path="/report/wallet-ledger"
            component={WalletLedger}
          />
          <IsLoggedInRoute
            exact
            path="/merchant/products/:slug"
            component={ProductDetails}
          />
          <IsLoggedInRoute exact path="/cart" component={Cart} />
          <AirtimeTopupEnabled
            exact
            path="/airtime-topup"
            component={AirtimeTopUp}
          />
          <BillPaymentEnabled
            exact
            path="/bill-payment"
            component={BillPayment}
          />
          <IsLoggedInRoute exact path="/payout" component={Payout} />
          <IsLoggedInRoute
            exact
            path="/payout-search"
            component={PayoutSearch}
          />
          <IsLoggedInRoute
            exact
            path="/customers-beneficiaries"
            component={CustomerBeneficiary}
          />
          <IsLoggedInRoute
            exact
            path="/customers-beneficiaries/:id"
            component={SingleCustomerBeneficiary}
          />
          <WalletEnabled
            exact
            path="/request-account"
            component={RequestAccount}
          />
          <WalletEnabled exact path="/balances" component={AccountBalance} />
          <WalletEnabled exact path="/statement" component={AccountStatement} />
          <WalletEnabled exact path="/fund-wallet" component={FundWallet} />
          <Route exact path="/construction" component={Construction} />
          <Route component={Error404} />
        </VerifiedRoute>
      </Switch>
    </Router>
  );
};

export default App;
