export const FETCH_CUSTOMER_PRODUCTS_LIST_REQUEST =
  "FETCH_CUSTOMER_PRODUCTS_LIST_REQUEST";
export const FETCH_CUSTOMER_PRODUCTS_LIST_SUCCESS =
  "FETCH_CUSTOMER_PRODUCTS_LIST_SUCCESS";
export const FETCH_CUSTOMER_PRODUCTS_LIST_FAILURE =
  "FETCH_CUSTOMER_PRODUCTS_LIST_FAILURE";

export const GET_MANAGER_APPROVAL_REQUEST = "GET_MANAGER_APPROVAL_REQUEST";
export const GET_MANAGER_APPROVAL_SUCCESS = "GET_MANAGER_APPROVAL_SUCCESS";
export const GET_MANAGER_APPROVAL_FAILURE = "GET_MANAGER_APPROVAL_FAILURE";
