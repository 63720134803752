import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { fetchExchangeRate } from "../../store/actions/rate.action";
import {
  exchangeRateProvider,
  exchangeRateUpdate,
  units,
} from "../../utils/helpers/objectHelpers";
import { deleteExchangeRateFeed } from "../../utils/services/rate.service";

const EditRateModal = ({
  show,
  handleClose,
  handleSave,
  currency,
  data = {},
  type = "",
  getId,
  rateName,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const menuPortalTarget = document.getElementById("root");
  const [loading, setLoading] = useState(false);
  const [currencyFrom, setCurrencyFrom] = useState(data?.currencyFrom);
  const [currencyTo, setCurrencyTo] = useState(data?.currencyTo);
  const [exchangeUpdate, setExchangeRateUpdate] = useState();
  const [exchangeProvider, setExchangeRateProvider] = useState();
  const [name, setName] = useState("");
  const [expiredQuote, setExpiredQuote] = useState("");
  const [unit, setUnit] = useState();
  const [buyRate, setBuyRate] = useState("");
  const [spread, setSpread] = useState("");

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deleteExchangeRateFeed(getId);
      if (response?.data?.status === "SUCCESS") {
        toast.success(response?.data?.message);
        handleClose();
        await dispatch(fetchExchangeRate);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      {type === "EDIT-RATE-MODAL" && (
        <Modal show={show} backdrop="static" keyboard={false} size={"xl"}>
          <Modal.Header closeButton={false}>
            <Modal.Title className="fw-bold">
              Edit Exchange Rate Range
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form
              className="needs-validation"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="form-row">
                <div className="col-md-6 col-lg-6 mb-3">
                  <label>
                    Exchange Rate update source
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <select
                    className="py-2 px-3 select me-2 w-100"
                    value={exchangeUpdate}
                    onChange={(e) => {
                      setExchangeRateUpdate(e.target.value);
                    }}
                  >
                    <option className="" selected hidden disabled>
                      Select...
                    </option>
                    {exchangeRateUpdate?.map((item) => (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <label>
                    Exchange Rate Feed Provider
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <select
                    className="py-2 px-3 select me-2 w-100"
                    value={exchangeProvider}
                    onChange={(e) => {
                      setExchangeRateProvider(e.target.value);
                    }}
                  >
                    <option className="" selected hidden disabled>
                      Select...
                    </option>
                    {exchangeRateProvider?.map((item) => (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
                  <label>
                    Currency From
                    <span style={{ color: "red" }}> *</span>
                  </label>

                  <select
                    className="py-2 px-3 select me-2"
                    value={currencyFrom}
                    onChange={(e) => {
                      setCurrencyFrom(e.target.value);
                    }}
                  >
                    <option className="" selected hidden disabled>
                      Select...
                    </option>
                    {currency?.map((item) => (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
                  <label>
                    Currency To
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <select
                    className="py-2 px-3 select me-2"
                    value={currencyTo}
                    onChange={(e) => {
                      setCurrencyTo(e.target.value);
                    }}
                  >
                    <option className="" selected hidden disabled>
                      Select...
                    </option>
                    {currency?.map((item) => (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <label>
                    Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control border px-3"
                    placeholder="Name..."
                    value={name}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    autoComplete=""
                  />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <div className="row row-sm">
                    <div className="col-md-6">
                      <label>
                        Expired Quote After
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control border px-3"
                        value={expiredQuote}
                        placeholder=""
                        onChange={(e) => setExpiredQuote(e.target.value)}
                        autoComplete=""
                      />
                    </div>
                    <div className="col-md-6">
                      <label>
                        Unit
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <Select
                        options={units}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        value={unit}
                        onChange={(option) => setUnit(option)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-6 mb-3">
                  <div className="row row-sm">
                    <div className="col-md-3">
                      <label>
                        Buy Rate
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control border px-3"
                        value={buyRate}
                        onChange={(e) => setBuyRate(e.target.value)}
                        placeholder=""
                        autoComplete=""
                      />
                    </div>
                    <div className="col-md-3">
                      <label>
                        Spread(%)
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control border px-3"
                        value={spread}
                        onChange={(e) => setSpread(e.target.value)}
                        placeholder=""
                        autoComplete=""
                      />
                    </div>
                    <div className="col-md-3">
                      <label>
                        Sell Rate
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control border px-3"
                        value=""
                        placeholder=""
                        autoComplete=""
                        disabled
                      />
                    </div>
                    <div className="col-md-3">
                      <label>
                        Total Profit
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control border px-3 border-less"
                        value=""
                        placeholder=""
                        autoComplete=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-6 mb-3 text-end">
                  <button
                    // onClick={addNewAMLRule}
                    className="btn btn-primary margin-label width-100"
                    type="button"
                  >
                    {loading ? (
                      <Spinner
                        animation="border"
                        variant="dark"
                        className="py-1"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleSave}>
              SAVE
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {type === "DELETE-RATE-MODAL" && (
        <Modal show={show} backdrop="static" keyboard={false}>
          <Modal.Header closeButton={false}>
            <Modal.Title>{`Delete ${rateName}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{`would you like to delete ${rateName}?`}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleDelete}>
              {"Yes"}
              {loading && (
                <>
                  &nbsp;
                  <i className="fa fa-spinner fa-spin" />
                </>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default EditRateModal;

EditRateModal.defaultProps = {
  handleClose: () => {},
  handleSave: () => {},
  handleDelete: () => {},
};

EditRateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
