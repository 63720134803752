import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import QCELL from "../../../../assets/images/QCELL.jpg";
import CountriesDataTable from "../../../../components/dataTable/countries";
import {
  getQuoteData,
  initiateAirtimeTopup,
  resetQuoteData,
} from "../../../../store/actions/transfer.action";
import { Loading } from "../../../../utils/helpers/constants";
import {
  findItemsInArray,
  formatStringCurrency,
  formatTopupValues,
  navigatePageTo,
  sortArrayOfObjectsByKey,
} from "../../../../utils/helpers/logicHelper";
import { validatePreTopup } from "../../../../utils/services/transfer.service";

const Body = ({
  domainBrand,
  countries,
  profile,
  beneficiaries,
  quoteObject,
  quoteData,
}) => {
  const dispatch = useDispatch(),
    history = useHistory();
  countries.sort(sortArrayOfObjectsByKey("common_name"));
  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [loading, setLoading] = useState(false);
  const [enableBeneficiary, setEnableBeneficiary] = useState(false);
  const [topupLoading, setTopupLoading] = useState(false);
  const [beneficiariesDropdown, setBeneficiariesDropdown] = useState([]);
  const [country, updateCountry] = useState("");
  const [beneficiary, updateBeneficiary] = useState("");
  const [phone, updatePhone] = useState("");
  const [amount, updateAmount] = useState("");
  const [description, updateDescription] = useState("");
  const [validationData, updateValidationData] = useState({});
  const [topupDropdown, updateTopupDropdown] = useState([]);
  // const [qcell, setQcell] = useState(0);

  const selectCountryRender = () => {
    dispatch(resetQuoteData());
    updateValidationData({});
    setEnableBeneficiary(false);
  };

  const selectCountry = async (option) => {
    updateCountry(option);
    const filter = findItemsInArray(beneficiaries, "country", option.value);
    setBeneficiariesDropdown(filter);
    setEnableBeneficiary(true);
    updatePhone(`${option["telephoneCode"]}`);
  };

  const validatePhoneNumber = async () => {
    setLoading(true);
    const beneficiaryPhone = phone ? phone : `${beneficiary["phoneNumber"]}`;
    updatePhone(beneficiaryPhone);
    const destinationCountryIso3 = country.value;
    const payload = {
      beneficiaryPhone,
      destinationCountryIso3,
    };
    const { data: responseData } = await validatePreTopup(payload);

    if (responseData.status !== Loading.SUCCESS)
      toast.error(`${responseData.message}`);
    else {
      const formattedTopup = formatTopupValues(
        responseData.data["topupProductList"],
        responseData.data.currency
      );
      updateTopupDropdown(formattedTopup);
      updateValidationData(responseData.data);
    }
    setLoading(false);
  };

  /**
   * {
    "beneficiaryPhone": "123456788",
    "beneficiaryCode": "223",
    "web_agent_from_iso_country": "GBR",
    "productCurrency": "GBP",
    "destinationCountryIso3": "SLE",
    "productAmount":"100.0",
    "payerCurrency":"GBP"
}
   */

  const topupAirtime = async () => {
    setTopupLoading(true);

    const removePlus = () => {
      if (phone.includes("+")) {
        const str = phone.slice(1);
        return str;
      } else {
        return phone;
      }
    };

    const payload = {
      payerCurrency: quoteData.sendingCurrency || profile["defaultCurrency"],
      productAmount: amount.value || amount.toString(),
      beneficiaryPhone: removePlus(),
      destinationCountryIso3: country.value,
      message: description,
      operatorID: validationData["operatorId"] || "1537",
      web_agent_from_iso_country: profile.userData?.address.countryIso3,
    };
    const paymentMethodOptions = {
      sendAmount: amount.value || amount.toString(),
      originCountry: profile.userData?.address.countryIso3,
      sendingCurrency: quoteData.sendingCurrency || profile["defaultCurrency"],
      receivingCountry: country.value,
      receivingCurrency: quoteData.receivingCurrency || country.currencyCode,
      transactionType: quoteObject.transactionType,
    };

    const navigator = navigatePageTo(
      history,
      "/transfer/choose-payment-method"
    );
    await dispatch(
      initiateAirtimeTopup(payload, navigator, paymentMethodOptions)
    );
    setTopupLoading(false);
  };

  const getQuote = (option) => {
    updateAmount(option);
    const quoteData = {
      ...quoteObject,
      principalAmount: parseFloat(option.value),
      principalAmountCurrency: profile.defaultCurrency,
      deriveAmount: parseFloat(option.value),
      deriveAmountCurrency: validationData.currency,
      mobileOperator: validationData["operatorId"] || "1537",
    };
    dispatch(getQuoteData(quoteData));
  };

  const qCellQuote = (event) => {
    const value = event.target.value;
    const quoteData = {
      ...quoteObject,
      principalAmount: parseFloat(value),
      principalAmountCurrency: profile.defaultCurrency,
      deriveAmount: parseFloat(value),
      deriveAmountCurrency: validationData.currency,
      mobileOperator: validationData["operatorId"] || "1537",
    };
    dispatch(getQuoteData(quoteData));
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title mx-auto text-center">
              {languageStatus ? dictionary["m_mobile_top_up"] : "Airtime TopUp"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>
        <div className="row mb-4">
          <div
            className={`col-lg-6 col-md-6 ${
              validationData.operator === "QCELL_AIRTIME" ? "mx-auto" : ""
            }`}
          >
            <div className="card overflow-hidden height-100">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary["para_send_airtime"]
                    : "Send Airtime"}
                </h3>
              </div>
              <div className="card-body">
                {!enableBeneficiary ? (
                  <>
                    <div className="col-12 d-none d-md-block d-xl-block">
                      <CountriesDataTable
                        countries={countries}
                        selectCountry={selectCountry}
                      />
                    </div>
                    <div className="col-sm-12 d-lg-none d-xl-none mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["l_destination_country"]
                          : "Receiving Country"}
                      </label>
                      <Select
                        options={countries}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="country"
                        value={country}
                        onChange={(option) => selectCountry(option)}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label>
                          {languageStatus
                            ? dictionary["para_find_beneficiary"]
                            : "Beneficiary"}
                        </label>
                        <Select
                          options={beneficiariesDropdown}
                          noOptionsMessage={() => "No beneficiary by country"}
                          isClearable={false}
                          isSearchable={true}
                          maxMenuHeight={250}
                          menuPlacement="bottom"
                          menuPortalTarget={menuPortalTarget}
                          name="beneficiary"
                          value={beneficiary}
                          onChange={(option) => updateBeneficiary(option)}
                        />
                      </div>
                    </div>
                    <hr
                      className="hr-text"
                      data-content="Or enter phone number"
                    />
                    <div className="form-row">
                      <div className="col-md-12 mt-1">
                        <input
                          type="text"
                          name="phone"
                          value={phone}
                          placeholder={
                            languageStatus
                              ? dictionary["l_phone_number"]
                              : "Phone"
                          }
                          className={`form-control`}
                          onChange={(event) => updatePhone(event.target.value)}
                          required
                        />
                      </div>
                    </div>
                    {Object.entries(validationData).length === 0 ? (
                      <div className="col-md-12 mb-3 text-end">
                        <button
                          onClick={selectCountryRender}
                          className="btn btn-danger margin-label"
                          type="button"
                        >
                          {
                            <>
                              <i className="fa fa-arrow-left" />
                              &nbsp;
                            </>
                          }{" "}
                          Back
                        </button>
                        &nbsp;
                        <button
                          onClick={validatePhoneNumber}
                          className="btn btn-primary width-50 margin-label"
                          type="button"
                        >
                          {languageStatus ? dictionary["l_verify"] : "Search"}
                          {loading ? (
                            <>
                              &nbsp;
                              <i className="fa fa-spin fa-spinner" />
                            </>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    ) : (
                      <div className="form-row">
                        {validationData?.operator === "QCELL_AIRTIME" ? (
                          <div className="col-md-12 mb-3 mt-5">
                            <label>
                              {languageStatus
                                ? dictionary["TOPUP_AMOUN_474816230"]
                                : "Topup Amount"}
                            </label>
                            <input
                              type="number"
                              name="amount"
                              value={amount}
                              className={`form-control`}
                              onChange={(event) => {
                                updateAmount(event.target.value);
                                qCellQuote(event);
                              }}
                              required
                            />
                          </div>
                        ) : (
                          <div className="col-md-12 mb-3 mt-5">
                            <label>
                              {languageStatus
                                ? dictionary["TOPUP_AMOUN_474816230"]
                                : "Topup Amount"}
                            </label>
                            <Select
                              options={topupDropdown}
                              isClearable={false}
                              isSearchable={true}
                              maxMenuHeight={250}
                              menuPlacement="bottom"
                              menuPortalTarget={menuPortalTarget}
                              name="amount"
                              value={amount}
                              onChange={(option) => getQuote(option)}
                            />
                          </div>
                        )}

                        <div className="col-md-12 mb-3">
                          <label>
                            {languageStatus
                              ? dictionary["MESSAGE_FOR_THE_RECE_821862653"]
                              : "Message"}
                          </label>
                          <textarea
                            name="description"
                            rows="4"
                            className="form-control"
                            value={description}
                            onChange={(event) =>
                              updateDescription(event.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <button
                            onClick={selectCountryRender}
                            className="btn btn-danger margin-label"
                            type="button"
                          >
                            {
                              <>
                                <i className="fa fa-arrow-left" />
                                &nbsp;
                              </>
                            }{" "}
                            Back
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 position-relative">
            <div
              className={`card overflow-hidden ${
                Object.entries(validationData).length > 0 ? "height-100" : ""
              }`}
            >
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary["l_transaction_details"]
                    : "Transaction Details"}
                </h3>
              </div>
              <div className="card-body">
                <div className="text-center mb-3rem">
                  {Object.entries(validationData).length < 1 ? (
                    <p className="text-muted">
                      Find an account and validate in order to top up airtime
                    </p>
                  ) : (
                    <>
                      {validationData?.operator === "QCELL_AIRTIME" ? (
                        <>
                          <img
                            className="avatar avatar-xxl bradius cover-image"
                            src={QCELL}
                            alt={validationData["operator"]}
                          />
                        </>
                      ) : (
                        <img
                          className="avatar avatar-xxl bradius cover-image"
                          src={validationData["operatorLogo"]}
                          alt={validationData["operator"]}
                        />
                      )}
                      <h6 className="mb-0 mt-2">
                        {validationData["operator"]}
                      </h6>
                    </>
                  )}
                </div>
                <div className="border-top">
                  {Object.entries(validationData).length === 0 ? (
                    ""
                  ) : (
                    <div className="row mb-4 mt-4">
                      <div className="col-6">
                        <p className="text-muted">
                          {languageStatus ? dictionary["l_price"] : "Price"}
                        </p>
                      </div>
                      <div className="col-6 text-end">
                        {amount["label"] || amount}
                      </div>
                    </div>
                  )}
                  {Object.entries(quoteData).length !== 0 ? (
                    <>
                      <>
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus
                                ? dictionary["l_vat"]
                                : "Tax rate"}
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            {quoteData["tax"]} {quoteData["sendingCurrency"]}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus
                                ? dictionary["para_payment_fee"]
                                : "Payment fee"}
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            {quoteData["payment_method_fee"]}{" "}
                            {quoteData["sendingCurrency"]}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus
                                ? dictionary["l_exchangerate"]
                                : "Exchange Rate"}
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            {`1.00 ${
                              quoteData.sendingCurrency !== null
                                ? quoteData.sendingCurrency
                                : ""
                            } = ${quoteData["fxRate"]} ${
                              quoteData["receivingCurrency"] !== null
                                ? quoteData["receivingCurrency"]
                                : ""
                            }`}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus
                                ? dictionary["para_total_amount_to_pay"]
                                : "Amount"}
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            {formatStringCurrency(quoteData["total_amount"])}{" "}
                            {quoteData["sendingCurrency"]}
                          </div>
                        </div>

                        {Object.entries(quoteData).length !== 0 ||
                        Object.entries(validationData).length !== 0 ? (
                          <button
                            onClick={topupAirtime}
                            className="btn btn-primary mt-3 float-end"
                            type="button"
                          >
                            {languageStatus
                              ? dictionary["WO_PROCEE_1790863502"]
                              : "Proceed"}
                            {topupLoading ? (
                              <>
                                &nbsp;
                                <i className="fa fa-spin fa-spinner" />
                              </>
                            ) : (
                              ""
                            )}
                          </button>
                        ) : (
                          ""
                        )}
                      </>
                    </>
                  ) : (
                    <button
                      onClick={topupAirtime}
                      className="btn btn-primary mt-3 float-end"
                      type="button"
                    >
                      {languageStatus
                        ? dictionary["WO_PROCEE_1790863502"]
                        : "Proceed"}
                      {topupLoading ? (
                        <>
                          &nbsp;
                          <i className="fa fa-spin fa-spinner" />
                        </>
                      ) : (
                        ""
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
