import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Select from "react-select";

import logoSqr from "../../assets/images/logo.png";
import avatar from "../../assets/images/gravatar.png";
import inboxSvg from "../../assets/images/svgs/inbox.svg";
import cartSvg from "../../assets/images/svgs/cart.svg";
import exitSvg from "../../assets/images/svgs/exit.svg";
import * as DomainBrand from "../../store/actions/domain-brand.action";
import { toggleNav } from "../../store/actions/component.action";
import { removeLoggedInUser } from "../../store/actions/auth.action";
import {
  fetchAwaitingNotificationState,
  getProfile,
} from "../../store/actions/profile.action";
import {
  fetchAppLanguage,
  languageChangeHelper,
  menuFilter,
} from "../../utils/helpers/logicHelper";
import { configs, Loading } from "../../utils/helpers/constants";
import { navBarStyles } from "../selectStyles/customStyles";
import { languageDropDownOptions } from "../../utils/helpers/objectHelpers";
import cookieHelper from "../../utils/helpers/cookieHelper";

const Header = ({ domainBrand }) => {
  let brandLangSelectorOptions,
    selectedLanguage = fetchAppLanguage();
  const dispatch = useDispatch();
  const profile = useSelector(({ profileReducer }) => profileReducer);

  const serviceController = cookieHelper.get(configs.SERVICE_CONTROL);

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [languageField, updateLanguageField] = useState(
    selectedLanguage["commonName"]
  );
  const [headerMenuStatus, updateHeaderMenuStatus] = useState(false);
  const [searchFieldStatus, updateSearchFieldStatus] = useState(false);
  const [menuDropdownStatus, updateMenuDropdownStatus] = useState(false);
  const [unreadState, updateUnreadState] = useState(false);
  const [menuSearch, updateMenuSearch] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [menuArray, updateMenuArray] = useState([]);
  const showHeaderMenu = () => updateHeaderMenuStatus(!headerMenuStatus);
  const toggleSearchField = () => updateSearchFieldStatus(!searchFieldStatus);
  const toggleNavBar = () => dispatch(toggleNav(true));

  brandLangSelectorOptions =
    domainBrand.loading === Loading.SUCCESS
      ? domainBrand["domainBrand"]["language"]
      : languageDropDownOptions;

  useEffect(async () => {
    if (profile.userProfileLoading !== Loading.SUCCESS)
      await dispatch(getProfile());
    setPhotoUrl(
      profile.profileImage !== null || profile.profileImage !== ""
        ? profile.profileImage
        : avatar
    );
  }, [profile.profileImage]);

  useEffect(async () => {
    if (profile.fetchingNotification !== Loading.SUCCESS)
      await dispatch(fetchAwaitingNotificationState());
    updateUnreadState(profile.notification);
  }, []);

  useEffect(() => {
    if (menuSearch.length > 1) {
      updateMenuDropdownStatus(true);
      const data = menuFilter(
        menuSearch,
        profile.userType,
        languageStatus,
        dictionary
      );
      updateMenuArray(data);
    } else updateMenuDropdownStatus(false);
  }, [menuSearch]);

  const handleLanguageChange = (option) => {
    const languageHelper = languageChangeHelper(option);
    updateLanguageField(languageHelper["value"]);
    dispatch(
      DomainBrand.getDomainBrandIdentity(
        languageHelper["subdomain"],
        languageHelper["value"]["id"]
      )
    );
  };

  const logoutUser = (event) => {
    const url = domainBrand.domainBrand["publicWebSite"];
    event.preventDefault();
    dispatch(removeLoggedInUser(url));
  };

  return (
    <>
      <div className="header hor-header">
        <div className="container">
          <div className="d-flex">
            <div
              onClick={toggleNavBar}
              className="animated-arrow hor-toggle horizontal-navtoggle"
            >
              <span />
            </div>
            <a className="header-brand1" href="/">
              <img
                src={domainBrand["domainBrand"]["logo"] || logoSqr}
                className="header-brand-img desktop-logo"
                alt="logo"
              />
            </a>
            <div className="main-header-center ms-3 d-none d-md-block">
              <input
                className="form-control"
                placeholder="Quick Menu Search.."
                value={menuSearch}
                onChange={(event) => updateMenuSearch(event.target.value)}
                type="search"
              />
              <button className="btn">
                <i className="fa fa-search" aria-hidden="true" />
              </button>

              <div
                className={`dropdown-menu search dropdown-menu-end ${
                  menuDropdownStatus ? "show" : ""
                }`}
              >
                {menuArray.length === 0 ? (
                  <NavLink className="dropdown-item" to="">
                    No menu item found
                  </NavLink>
                ) : (
                  menuArray.map((item, index) => (
                    <NavLink
                      key={index}
                      className="dropdown-item"
                      to={item.link}
                    >
                      <i className={`dropdown-icon ${item.icon}`} />{" "}
                      {item.label}
                    </NavLink>
                  ))
                )}
              </div>
            </div>
            <div className="d-flex order-lg-2 ms-auto header-right-icons">
              <div className="dropdown d-lg-none d-md-block d-none">
                <a href="/" className="nav-link icon" data-bs-toggle="dropdown">
                  <i className="fa fa-search" />
                </a>
                <div className="dropdown-menu header-search dropdown-menu-start">
                  <div className="input-group w-100 p-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search...."
                    />
                    <div className="input-group-text btn btn-primary">
                      <i className="fa fa-search" aria-hidden="true" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="dropdown">
                <div className="nav-link">
                  <Select
                    options={brandLangSelectorOptions}
                    isClearable={false}
                    isSearchable={false}
                    name={languageField}
                    value={selectedLanguage}
                    onChange={(option) => handleLanguageChange(option)}
                    styles={navBarStyles}
                  />
                </div>
              </div>

              <button
                className="navbar-toggler navresponsive-toggler d-md-none ms-auto"
                type="button"
                onClick={toggleNavBar}
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent-4"
                aria-controls="navbarSupportedContent-4"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon fa fa-bars text-dark" />
              </button>

              <button
                onClick={showHeaderMenu}
                className="navbar-toggler navresponsive-toggler d-md-none ms-auto"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent-4"
                aria-controls="navbarSupportedContent-4"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon fa fa-ellipsis-v text-dark" />
              </button>

              <div className="dropdown d-none d-md-flex">
                <NavLink
                  to="/support"
                  className="nav-link text-primary icon"
                  data-bs-toggle="dropdown"
                >
                  <img src={inboxSvg} alt="inboxSvg" />
                  <span className={unreadState < 1 ? "" : "pulse-danger"} />
                </NavLink>
              </div>

              {serviceController && serviceController.merchant ? (
                <div className="dropdown d-md-flex message">
                  <NavLink
                    to="/cart"
                    className="nav-link icon text-primary text-center"
                    data-bs-toggle="dropdown"
                  >
                    <img src={cartSvg} alt="cartSvg" />
                    <span className={unreadState < 1 ? "" : "pulse-primary"} />
                  </NavLink>
                </div>
              ) : (
                ""
              )}

              {/* PROFILE USER */}
              <div className="dropdown d-none d-md-flex profile-1">
                <NavLink
                  to="/profile"
                  data-bs-toggle="dropdown"
                  className="nav-link pe-2 leading-none d-flex"
                >
                  <span>
                    <img
                      src={photoUrl || avatar}
                      alt="profile-user"
                      className="avatar profile-user brround cover-image"
                    />
                  </span>
                </NavLink>
              </div>

              <div className="dropdown  d-none d-md-flex">
                <NavLink
                  onClick={logoutUser}
                  to=""
                  className="nav-link icon text-center"
                  data-bs-toggle="dropdown"
                >
                  <img src={exitSvg} alt="exitSvg" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-header hor-mobile-header">
        <div className="container">
          <div className="d-flex">
            <div
              onClick={toggleNavBar}
              className="animated-arrow hor-toggle horizontal-navtoggle"
            >
              <span />
            </div>
            <NavLink className="header-brand" to="/">
              <img
                src={domainBrand["domainBrand"]["logo"] || logoSqr}
                className="header-brand-img desktop-logo mobile-light"
                alt="logoMobile"
              />
            </NavLink>
            <div className="d-flex order-lg-2 ms-auto header-right-icons">
              <button
                onClick={showHeaderMenu}
                className="navbar-toggler navresponsive-toggler d-md-none ms-auto"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent-4"
                aria-controls="navbarSupportedContent-4"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon fa fa-ellipsis-v text-dark" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-1 navbar navbar-expand-lg responsive-navbar navbar-dark d-md-none bg-white">
        <div
          className={`collapse navbar-collapse ${
            headerMenuStatus ? "show" : ""
          }`}
        >
          <div className="d-flex order-lg-2 ms-auto float-end">
            <div className="dropdown d-sm-flex">
              <NavLink
                onClick={toggleSearchField}
                to=""
                className="nav-link icon show"
                data-bs-toggle="dropdown"
              >
                <i className="fa fa-search" />
              </NavLink>
              <div
                className={`dropdown-menu header-search dropdown-menu-start ${
                  searchFieldStatus ? "show" : ""
                }`}
              >
                <div className="input-group w-100 p-2">
                  <input
                    type="text"
                    className="form-control"
                    value={menuSearch}
                    onChange={(event) => updateMenuSearch(event.target.value)}
                    placeholder="Search...."
                  />
                  <div className="input-group-text btn btn-primary">
                    <i className="fa fa-search" aria-hidden="true" />
                  </div>
                </div>
                {menuSearch.length < 2 || menuArray.length === 0
                  ? ""
                  : menuArray.map((item, index) => (
                      <NavLink
                        key={index}
                        className="dropdown-item"
                        to={item.link}
                      >
                        <i className={`dropdown-icon ${item.icon}`} />{" "}
                        {item.label}
                      </NavLink>
                    ))}
              </div>
            </div>

            <div className="dropdown d-md-flex message">
              <NavLink
                to="/support"
                className="nav-link icon text-primary text-center"
                data-bs-toggle="dropdown"
              >
                <img src={inboxSvg} alt="inboxSvg" />
                <span className={unreadState < 1 ? "" : "pulse-danger"} />
              </NavLink>
            </div>

            {serviceController.merchant ? (
              <div className="dropdown d-md-flex message">
                <NavLink
                  to="/cart"
                  className="nav-link icon text-primary text-center"
                  data-bs-toggle="dropdown"
                >
                  <img src={cartSvg} alt="cartSvg" />
                  <span className={unreadState < 1 ? "" : "pulse-primary"} />
                </NavLink>
              </div>
            ) : (
              ""
            )}

            <div className="dropdown d-md-flex profile-1">
              <NavLink
                to="/profile"
                data-bs-toggle="dropdown"
                className="nav-link pe-2 leading-none d-flex"
              >
                <img
                  alt="Profile"
                  className="rounded-circle avatar-lg"
                  src={photoUrl}
                  onError={(e) => (e.target.src = avatar)}
                />
              </NavLink>
            </div>

            <div className="dropdown d-md-flex message">
              <NavLink
                onClick={logoutUser}
                to=""
                className="nav-link icon text-center"
                data-bs-toggle="dropdown"
              >
                <img src={exitSvg} alt="exitSvg" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
