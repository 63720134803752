import { toast } from "react-hot-toast";
import ActionCreator from "../../utils/helpers/actionCreator";
import { expiredTokenCheck } from "./auth.action";
import {
  listBankFieldConfig,
  // editBankFieldConfig,
  // deleteBankFieldConfig,
} from "../../utils/services/bankfield.service";
import * as BankFieldConstants from "../constants/bankfield.constant";

export const getBankFields = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(BankFieldConstants.LIST_BANK_FIELDS_REQUEST));
    const { data: responseData } = await listBankFieldConfig();

    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        BankFieldConstants.LIST_BANK_FIELDS_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(BankFieldConstants.LIST_BANK_FIELDS_FAILED));
    toast.error(`Registration failed`);
  }
};
