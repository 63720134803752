export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_PHONE_CODE_SUCCESS = 'FETCH_COUNTRIES_PHONE_CODE_SUCCESS';
export const FETCH_COUNTRIES_CURRENCIES_SUCCESS = 'FETCH_COUNTRIES_CURRENCIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';

export const FETCH_ORIGIN_COUNTRIES_REQUEST = 'FETCH_ORIGIN_COUNTRIES_REQUEST';
export const FETCH_ORIGIN_COUNTRIES_SUCCESS = 'FETCH_ORIGIN_COUNTRIES_SUCCESS';
export const FETCH_ORIGIN_COUNTRIES_FAILURE = 'FETCH_ORIGIN_COUNTRIES_FAILURE';

export const FETCH_SUPPORTED_CURRENCIES_REQUEST = 'FETCH_SUPPORTED_CURRENCIES_REQUEST';
export const FETCH_SUPPORTED_CURRENCIES_SUCCESS = 'FETCH_SUPPORTED_CURRENCIES_SUCCESS';
export const FETCH_SUPPORTED_CURRENCIES_FAILURE = 'FETCH_SUPPORTED_CURRENCIES_FAILURE';

export const FETCH_SUPPORTED_WALLET_CURRENCIES_REQUEST = 'FETCH_SUPPORTED_WALLET_CURRENCIES_REQUEST';
export const FETCH_SUPPORTED_WALLET_CURRENCIES_SUCCESS = 'FETCH_SUPPORTED_WALLET_CURRENCIES_SUCCESS';
export const FETCH_SUPPORTED_WALLET_CURRENCIES_FAILURE = 'FETCH_SUPPORTED_WALLET_CURRENCIES_FAILURE';

export const FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_REQUEST = 'FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_REQUEST';
export const FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_SUCCESS = 'FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_SUCCESS';
export const FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_FAILURE = 'FETCH_CASHPICKUP_SUPPORTED_COUNTRIES_FAILURE';

export const FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_REQUEST = 'FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_REQUEST';
export const FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_SUCCESS = 'FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_SUCCESS';
export const FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_FAILURE = 'FETCH_MOBILE_MONEY_SUPPORTED_COUNTRIES_FAILURE';

export const FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_REQUEST = 'FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_REQUEST';
export const FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_SUCCESS = 'FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_SUCCESS';
export const FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_FAILURE = 'FETCH_TRANSFER_FUNDS_SUPPORTED_COUNTRIES_FAILURE';

export const FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_REQUEST = 'FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_REQUEST';
export const FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_SUCCESS = 'FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_SUCCESS';
export const FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_FAILURE = 'FETCH_AIRTIME_TOPUP_SUPPORTED_COUNTRIES_FAILURE';

export const FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_REQUEST = 'FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_REQUEST';
export const FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_SUCCESS = 'FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_SUCCESS';
export const FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_FAILURE = 'FETCH_BILL_PAYMENT_SUPPORTED_COUNTRIES_FAILURE';

export const FETCH_MERCHANT_SUPPORTED_COUNTRIES_REQUEST = 'FETCH_MERCHANT_SUPPORTED_COUNTRIES_REQUEST';
export const FETCH_MERCHANT_SUPPORTED_COUNTRIES_SUCCESS = 'FETCH_MERCHANT_SUPPORTED_COUNTRIES_SUCCESS';
export const FETCH_MERCHANT_SUPPORTED_COUNTRIES_FAILURE = 'FETCH_MERCHANT_SUPPORTED_COUNTRIES_FAILURE';

export const FETCH_CASHPICKUP_LOCATIONS_REQUEST = 'FETCH_CASHPICKUP_LOCATIONS_REQUEST';
export const FETCH_CASHPICKUP_LOCATIONS_SUCCESS = 'FETCH_CASHPICKUP_LOCATIONS_SUCCESS';
export const FETCH_CASHPICKUP_LOCATIONS_FAILURE = 'FETCH_CASHPICKUP_LOCATIONS_FAILURE';
