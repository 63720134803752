import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from "react-select";

function AddNewBrandModal({ show, handleClose, handleSave, domainBrand }) {
  const menuPortalTarget = document.getElementById("root");

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  return (
    <Modal show={show} backdrop="static" keyboard={false}>
      <Modal.Header closeButton={false}>
        <Modal.Title className="fw-bold">
          Add new or modify Fee for Money Transfer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="needs-validation" onSubmit={(e) => e.preventDefault()}>
          <div className="form-row">
            <div className="col-md-12 col-lg-12 mb-3">
              <label>
                {languageStatus
                  ? dictionary["WO_CORRIDOR_166705112"]
                  : "Corridors"}
              </label>
              <Select
                // options={countries}
                isClearable={false}
                isSearchable={true}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                // value={country}
                // onChange={(option) => setCountry(option)}
              />
            </div>

            <div className="col-md-12 col-lg-12 mb-3">
              <div className="row row-sm">
                <div className="col-lg-6 col-md-6">
                  <label>
                    {languageStatus
                      ? dictionary["l_charge_category"]
                      : "Fee Category"}
                  </label>
                  <Select
                    // options={countries}
                    isClearable={false}
                    isSearchable={true}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    menuPortalTarget={menuPortalTarget}
                    // value={country}
                    // onChange={(option) => setCountry(option)}
                  />
                </div>
                <div className="col-lg-6 col-md-6">
                  <label>
                    {languageStatus
                      ? dictionary["para_charge_type"]
                      : "Fee Type"}
                  </label>
                  <Select
                    // options={countries}
                    isClearable={false}
                    isSearchable={true}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    menuPortalTarget={menuPortalTarget}
                    // value={country}
                    // onChange={(option) => setCountry(option)}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 mb-3">
              <div className="row row-sm">
                <div className="col-lg-6 col-md-6">
                  <label>
                    {languageStatus
                      ? dictionary["l_voucher_paycenter"]
                      : "Pay Center"}
                  </label>
                  <Select
                    // options={countries}
                    isClearable={false}
                    isSearchable={true}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    menuPortalTarget={menuPortalTarget}
                    // value={country}
                    // onChange={(option) => setCountry(option)}
                  />
                </div>
                <div className="col-lg-6 col-md-6">
                  <label>
                    {languageStatus
                      ? dictionary["m_set_remittance_commission"]
                      : "Fee"}
                  </label>
                  <input
                    type={"number"}
                    className="form-control border"
                    value={""}
                    placeholder=""
                    autoComplete=""
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 mb-3">
              <div className="row row-sm">
                <div className="col-lg-4 col-md-4">
                  <label>
                    {languageStatus
                      ? dictionary["para_starting_amount"]
                      : "Starting Amount"}
                  </label>
                  <input
                    type={"number"}
                    className="form-control border"
                    value={""}
                    placeholder=""
                    autoComplete=""
                  />
                </div>

                <div className="col-md-8">
                  <label>
                    {languageStatus
                      ? dictionary["WO_CHARGES_INCLUSIVE_IN_-1545297777"]
                      : " Charges Inclusive in Sending Amount"}
                  </label>
                  <div
                    className="border py-2 rounded-3"
                    style={{ height: "40px" }}
                  >
                    <input type={"checkbox"} className="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 mb-1">
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <label>
                    {languageStatus
                      ? dictionary["para_end_amount"]
                      : "End Amount"}
                  </label>
                  <input
                    type={"number"}
                    className="form-control border"
                    value={""}
                    placeholder=""
                    autoComplete=""
                  />
                </div>
                <div className="col-lg-6 col-md-6"></div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={handleSave}>
          SAVE FEE
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddNewBrandModal;

AddNewBrandModal.defaultProps = {
  handleClose: () => {},
  handleSave: () => {},
};

AddNewBrandModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
