import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import Body from "./Body";

import { getProfile } from "../../../../store/actions/profile.action";
import { toggleNav } from "../../../../store/actions/component.action";
import { navigatePageTo } from "../../../../utils/helpers/logicHelper";
import {
  // getAllBeneficiaries,
  updateBeneficiaryBankDetails,
  fetchArchivedBeneficiary,
} from "../../../../store/actions/users.action";
import {
  initiateTransaction,
  initiateWalletToWalletTransaction,
  resetQuoteData,
} from "../../../../store/actions/transfer.action";
import {
  getCashPickupSupportedCountry,
  getMobileMoneySupportedCountry,
  getTransferFundsSupportedCountry,
  getCountries,
} from "../../../../store/actions/country.action";
import {
  Loading,
  transferStatus,
  userTypes,
} from "../../../../utils/helpers/constants";
import { transferStatusMap } from "../../../../utils/helpers/mappers";

const Transfers = () => {
  const dispatch = useDispatch(),
    history = useHistory(),
    location = useLocation();
  const initPageStatus = location.state?.text ?? transferStatus.TRANSFER_FUNDS;
  const setBeneficiary = location.state?.beneficiary ?? "";
  const senderCode =
    location?.state?.user !== undefined ? location?.state?.user["code"] : "";

  const [pageStatus, updatePageStatus] = useState(initPageStatus);
  const [loading, setLoading] = useState(false);
  const [editBeneficiary, setEditBeneficiary] = useState(false);
  const [originCountry, updateOriginCountry] = useState("");
  const [cashierSender, updateCashierSender] = useState({});
  const [receivingCountry, setReceivingCountry] = useState({});
  const [sendingCurrency, setSendingCurrency] = useState({});
  const [receivingCurrency, setReceivingCurrency] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [getMobileOperators, setGetMobileOperators] = useState("");
  const bankFieldsChanged = (value) => setEditBeneficiary(value);

  const { domainBrand, component, profile, users, transfers } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      profileReducer,
      usersReducer,
      transferReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      profile: profileReducer,
      users: usersReducer,
      transfers: transferReducer,
    })
  );

  const {
    cashPickupCountries,
    transferFundsCountries,
    mobileMoneyCountries,
    cashPickupLocations,
    countries,
  } = useSelector(({ countryReducer }) => ({
    cashPickupCountries: countryReducer["cashPickupCountries"],
    transferFundsCountries: countryReducer["transferFundsCountries"],
    mobileMoneyCountries: countryReducer["mobileMoneyCountries"],
    cashPickupLocations: countryReducer["cashPickupLocations"],
    countries: countryReducer["countries"],
  }));

  const initialValues = {
    pageStatus,
    beneficiary: setBeneficiary,
    bankDetails: "",
    bankFields: [],
    requiredBankFields: "",
    securityQuestion: "",
    securityAnswer: "",
    transactionReference: "",
    phoneNumber: "",
    confirmPhoneNumber: "",
    remittancePurpose: "",
    sourceOfFunds: "",
    password: "",
  };

  const initialValidation = {
    phoneNumber: Yup.string().when("pageStatus", {
      is: (val) => {
        return val === transferStatus.MOBILE_MONEY;
      },
      then: Yup.string().required(),
    }),
    confirmPhoneNumber: Yup.string().when("pageStatus", {
      is: (val) => val === transferStatus.MOBILE_MONEY,
      then: Yup.string()
        .oneOf([Yup.ref("phoneNumber"), null], "Phone numbers don't match!")
        .required(),
    }),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(initialValidation),
    onSubmit: async (values) => {
      const handleFloat = (t) => {
        const v = t?.receivingAmount;
        const strfy = v.toString();
        if (strfy.indexOf(",") !== -1) {
          return parseFloat(v.replace(/,/g, ""));
        } else {
          return v;
        }
      };
      setLoading(true);
      const quoteData = transfers.quoteData;
      let creditParty = [],
        inclusionList = [],
        payload;
      if (pageStatus === transferStatus.WALLET) {
        const accountOwnerCustomerId =
          profile.userType === userTypes.CASHIER
            ? cashierSender.beneficiaryId
            : null;
        payload = {
          accountOwnerCustomerId,
          beneficiaryId: values.beneficiary.beneficiaryId,
          amount: handleFloat(values),
          creditingCurrency: receivingCurrency["value"],
          sourceOfFund: values.sourceOfFunds.value ?? "",
          purposeOfTransfer: formik.values.remittancePurpose.value ?? "",
          creditAccountNumber: values.accountNumber ?? "",
        };

        const navigator = navigatePageTo(
          history,
          "/transfer/choose-payment-method"
        );
        await dispatch(initiateWalletToWalletTransaction(payload, navigator));
      } else {
        if (pageStatus === transferStatus.MOBILE_MONEY)
          creditParty.push({
            key: "mobilemoneynumber",
            value: values.phoneNumber,
          });
        else {
          values.bankFields.forEach((element) =>
            inclusionList.push(element["apiName"])
          );
          const creditPartyBankFieldsMap = inclusionList.map((val) => {
            const key = val;
            const value =
              val === "bankname" && typeof values[val] === "string"
                ? values[val]
                : val === "bankname"
                ? values[val].label
                : values[[val]];
            return { key, value };
          });
          creditParty = creditParty.concat(creditPartyBankFieldsMap);
        }

        const customerCode =
          profile.userType === userTypes.CASHIER
            ? cashierSender.code
            : profile.userData["customerCode"];
        const password =
          profile.userType === userTypes.CASHIER
            ? formik.values.password
            : null;
        const originCountry = profile.userData.address["countryIso3"];
        payload = {
          originCountry,
          creditParty,
          senderCustomerCode: customerCode,
          type: formik.values.type,
          tax: transfers.quoteData.tax,
          sendAmount: values.sendingAmount,
          amount: values.receivingAmount,
          recipientCustomerCode: values.beneficiary["beneficiaryCode"],
          receivingCountry: receivingCountry["iso3Code"],
          receivingCurrency: receivingCurrency["value"],
          sendingCurrency: sendingCurrency["value"],
          securityQuestion: formik.values.securityQuestion,
          securityAnswer: formik.values.securityAnswer,
          phoneNumber: formik.values.phoneNumber,
          transactionReference: formik.values.transactionReference,
          sourceOfFunds: formik.values.sourceOfFunds.value ?? "",
          remittancePurpose: formik.values.remittancePurpose.value ?? "",
          selectedMobileOperator: getMobileOperators?.key || "",
        };

        if (editBeneficiary) {
          const bankPayload = {
            id: bankDetails.id,
            accountOwnerCustomerCode: bankDetails["accountOwnerCustomerCode"],
            bankAccountMetaData: creditParty,
          };
          await dispatch(updateBeneficiaryBankDetails(bankPayload));
        }
        const navigator = navigatePageTo(
          history,
          "/transfer/choose-payment-method"
        );
        await dispatch(
          initiateTransaction({ payload, quoteData, navigator, password })
        );
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    dispatch(toggleNav(false));
    dispatch(resetQuoteData());
  }, []);

  useEffect(async () => {
    dispatch(getCountries());
    if (profile.userProfileLoading !== Loading.SUCCESS)
      await dispatch(getProfile());
    if (!!location.state === false && profile.userType === userTypes.CASHIER) {
      history.push({
        pathname: "/get-sender",
        state: { prevLocation: history.location.pathname, pageStatus },
      });
    } else {
      if (
        profile.userType === userTypes.CASHIER &&
        (location.state.user === null ||
          typeof location.state.user === "undefined")
      ) {
        history.push({
          pathname: "/get-sender",
          state: { prevLocation: history.location.pathname, pageStatus },
        });
      } else {
        if (
          profile.userType === userTypes.CASHIER &&
          "user" in history.location.state
        )
          updateCashierSender(location.state.user);
        else dispatch(fetchArchivedBeneficiary("UN_ARCHIVED"));
        const originCountry =
          profile.userType === userTypes.CASHIER &&
          typeof location.state !== "undefined"
            ? location.state.user["countryIso3"]
            : profile.originCountry;
        updateOriginCountry(originCountry);
        if (profile.originCountry) {
          if (pageStatus === transferStatus.TRANSFER_FUNDS)
            dispatch(getTransferFundsSupportedCountry(originCountry));
          if (pageStatus === transferStatus.MOBILE_MONEY)
            dispatch(getMobileMoneySupportedCountry(originCountry));
          if (pageStatus === transferStatus.CASH_PICKUP)
            dispatch(getCashPickupSupportedCountry(originCountry));
        }
      }
    }
  }, [profile.originCountry]);

  // country.loadingCountries === Loading.FETCHING;

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    users.loadingBeneficiaries === Loading.FETCHING;
  const pageStatusRender = transferStatusMap[pageStatus].title;
  const supportedCountries =
    pageStatus === transferStatus.TRANSFER_FUNDS
      ? transferFundsCountries
      : pageStatus === transferStatus.CASH_PICKUP
      ? cashPickupCountries
      : pageStatus === transferStatus.MOBILE_MONEY
      ? mobileMoneyCountries
      : [];

  const properties = {
    domainBrand,
    profile,
    formik,
    supportedCountries,
    cashPickupLocations,
    setBeneficiary,
  };
  const userInterface = {
    pageStatusRender,
    pageStatus,
    initPageStatus,
    loading,
    originCountry,
  };
  const helperValues = {
    receivingCountry,
    sendingCurrency,
    receivingCurrency,
    cashierSender,
  };
  const helperHooks = {
    updatePageStatus,
    setReceivingCountry,
    setSendingCurrency,
    setReceivingCurrency,
    setBankDetails,
    setGetMobileOperators,
  };

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="transfer"
              active="transfers"
              domainBrand={domainBrand}
            />
            <Body
              properties={properties}
              userInterface={userInterface}
              helperValues={helperValues}
              beneficiaries={users["beneficiaries"]}
              helperHooks={helperHooks}
              quoteData={transfers.quoteData}
              fieldsChanged={bankFieldsChanged}
              country={countries}
              senderCode={senderCode}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default Transfers;
