import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";

export default function UnArchiveModal({
  show,
  handleClose,
  firstName,
  lastName,
  beneficiaryCode,
  handleUnArchive,
}) {
  return (
    <Modal show={show} backdrop="static" keyboard={false} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Unarchive {firstName} {lastName}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {`Do you want to Unarchive beneficiary with code - ${beneficiaryCode}?.`}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={handleUnArchive}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

UnArchiveModal.defaultProps = {
  handleClose: () => {},
  handleUnArchive: () => {},
};

UnArchiveModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUnArchive: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  beneficiaryCode: PropTypes.string.isRequired,
};
