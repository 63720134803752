import { useFormik } from "formik";
import Select from "react-select";
import moment from "moment";
import { toast } from "react-hot-toast";
import * as Yup from "yup";

import CustomDatePicker from "../../../../../components/datePickers/CustomDatePicker";
import { attachDocumentsValidator } from "../../../../../utils/helpers/validationHelpers";
import { documentType } from "../../../../../utils/helpers/objectHelpers";
import { fileToBase64 } from "../../../../../utils/helpers/logicHelper";
import { uploadDocuments } from "../../../../../store/actions/profile.action";

const UploadDocuments = ({
  languageStatus,
  dictionary,
  countries,
  profile,
  dispatch,
}) => {
  const menuPortalTarget = document.getElementById("root");
  const documentTypes = documentType(languageStatus, dictionary);
  const getDOB = (date) => formik.setFieldValue("dob", date);
  const getIssueDate = (date) => formik.setFieldValue("issueDate", date);
  const getExpiryDate = (date) => formik.setFieldValue("expiryDate", date);
  const initialValidation = attachDocumentsValidator();
  const initialValues = {
    idType: "",
    country: "",
    issueNo: "",
    file_data: "",
    dob: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(initialValidation),
    onSubmit: async (values) => {
      await formik.setFieldValue("submitting", true);
      const file_base64 = await fileToBase64(values.file_data);
      const payload = {
        customerCode: profile.userData["customerCode"],
        attachedDocument: [
          {
            file_base64,
            issueNo: values.issueNo,
            issueCountry: values.country.value,
            idType: values.idType.value,
            dob: moment(values.dob).format(),
            issueDate: moment(values.issueDate).format(),
            expiryDate: moment(values.expiryDate).format(),
          },
        ],
      };
      await dispatch(uploadDocuments(payload));
      await formik.setFieldValue("submitting", false);
    },
  });

  const onChangeFile = async (event) => {
    const allowedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "JPG",
      "JPEG",
      "PNG",
      "PDF",
    ];
    const sizeLimit = 2097152;
    const selectedFileSize = event.target.files[0].size || "";
    const selectedFileName = event.target.files[0].name || null;
    const fileExtension = selectedFileName.split(".").pop();
    if (typeof selectedFileSize === "undefined") return false;
    else if (
      event.target.files[0] &&
      !allowedExtensions.includes(fileExtension)
    )
      toast.error("File must be an image or document");
    else if (selectedFileSize > sizeLimit) toast.error("File size too large");
    else await formik.setFieldValue("file_data", event.target.files[0]);
  };

  return (
    <form className="needs-validation" onSubmit={formik.handleSubmit}>
      <div className="form-row">
        <div className="col-md-3 mb-3">
          <label>
            {languageStatus ? dictionary["WO_DOCUMENT_TYP_-13031371"] : "Type"}
          </label>
          <Select
            options={documentTypes}
            isClearable={false}
            maxMenuHeight={250}
            menuPlacement="bottom"
            menuPortalTarget={menuPortalTarget}
            name="idType"
            onChange={(option) => formik.setFieldValue("idType", option)}
          />
        </div>
        <div className="col-md-3 mb-3">
          <label>
            {languageStatus ? dictionary["l_issue_country"] : "Issue Country"}
          </label>
          <Select
            options={countries}
            isClearable={false}
            maxMenuHeight={250}
            menuPlacement="bottom"
            menuPortalTarget={menuPortalTarget}
            name="country"
            onChange={(option) => formik.setFieldValue("country", option)}
          />
          {formik.errors["country"] ? (
            <div className="invalid-feedback">Issue country is required</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label>
            {languageStatus ? dictionary["l_id_issue_no"] : "Issue Number"}
          </label>
          <input
            type="text"
            name="issueNo"
            className={`form-control ${
              formik.errors["issueNo"] ? "is-invalid" : ""
            }`}
            onChange={formik.handleChange}
          />
          {formik.errors["issueNo"] ? (
            <div className="invalid-feedback">Issue number is required</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label>
            {languageStatus ? dictionary["l_date_of_birth"] : "Date of birth"}
          </label>
          <div>
            <CustomDatePicker
              getDate={getDOB}
              showYearDropdown
              selectDropdownMode
              withPortal
              setMaxDate={new Date()}
            />
            {formik.errors["dob"] ? (
              <div className="invalid-feedback">Date of birth is required</div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3 mb-3">
          <label>
            {languageStatus ? dictionary["l_issue_date"] : "Issue Date"}
          </label>
          <div>
            <CustomDatePicker
              getDate={getIssueDate}
              showYearDropdown
              selectDropdownMode
              withPortal
              setMaxDate={new Date()}
            />
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <label>
            {languageStatus ? dictionary["l_expiry_date"] : "Expiry Date"}
          </label>
          <div>
            <CustomDatePicker
              getDate={getExpiryDate}
              showYearDropdown
              selectDropdownMode
              withPortal
            />
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <label>
            {languageStatus ? dictionary["l_file_upload"] : "File Upload"}
          </label>
          <div className="input-group">
            <input
              onChange={onChangeFile}
              type="file"
              className="form-control"
              placeholder="Choose.."
              readOnly
            />
          </div>
        </div>
        <div className="col-md-3 mt-5 mb-3 text-center">
          <button className="btn btn-primary mt-2 w-80" type="submit">
            {languageStatus
              ? dictionary["para_upload_from_computer"]
              : "Upload"}{" "}
            &nbsp;
            {formik.values["submitting"] ? (
              <i className="fa fa-spin fa-spinner" />
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default UploadDocuments;
