import React, { useState, useMemo } from "react";
import Select from "react-select";

import DataTable from "../../../../../components/dataTable/dataTable";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Trash } from "../../../../../assets/images/svgs/trash.svg";

import { deliveryMethod } from "../../../../../utils/helpers/objectHelpers";
import { fixedAmountFeeTableConfig } from "../../../../../utils/helpers/dataTableConfigs";

function FixedAmountFee({ dictionary, languageStatus, currency, countries }) {
  const menuPortalTarget = document.getElementById("root");

  const [filterInput, setFilterInput] = useState("");

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 14,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-center pointer">
          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Delete</Tooltip>}
          >
            <Trash />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...fixedAmountFeeTableConfig, actionColumn];
  const columns = useMemo(() => tableObject, []);

  return (
    <div className="card overflow-hidden">
      <div className="card-header bg-primary text-white">
        <h3 className="card-title fw-bold">Fix Amount Fees</h3>
      </div>

      <div className="card-body">
        <form className="needs-validation" onSubmit={(e) => e.preventDefault()}>
          <div className="form-row">
            <p className="fw-bold text-primary">
              View and allocate commission share to various combinations of
              paycenters.
            </p>

            <div className="col-md-6 col-lg-6 mb-3">
              <label className="fw-bold">
                {/* {languageStatus ? dictionary["l_transaction_tyep"] : "Type"} */}
                Customer(search Phone, email, name, customer reference no)
                <span style={{ color: "red" }}> *</span>
              </label>
              <Select
                // options={customerBeneficiaryTypes}
                isClearable={false}
                isSearchable={true}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                //   value={exchangeUpdate}
                //   onChange={(option) => setExchangeRateUpdate(option)}
              />
            </div>

            <div className="col-md-6 col-lg-6 mb-3">
              <label className="fw-bold">
                {/* {languageStatus ? dictionary["l_transaction_tyep"] : "Type"} */}
                Delivery Method
                <span style={{ color: "red" }}> *</span>
              </label>
              <Select
                options={deliveryMethod}
                isClearable={false}
                isSearchable={true}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                //   value={exchangeUpdate}
                //   onChange={(option) => setExchangeRateUpdate(option)}
              />
            </div>

            <div className="col-md-6 col-lg-6 mb-3">
              <label>
                {languageStatus
                  ? dictionary["WO_CURRENCY_COD_1626671491"]
                  : "Currency Code"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <Select
                options={currency}
                isClearable={false}
                isSearchable={true}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                //   value={exchangeUpdate}
                //   onChange={(option) => setExchangeRateUpdate(option)}
              />
            </div>

            <div className="col-md-6 col-lg-6 mb-3">
              <label>
                {/* {languageStatus
                  ? dictionary["WO_COUNTRY_1435082597"]
                  : "Country"} */}
                Transaction Originating Country
                <span style={{ color: "red" }}> *</span>
              </label>
              <Select
                options={countries}
                isClearable={false}
                isSearchable={true}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                //   value={exchangeUpdate}
                //   onChange={(option) => setExchangeRateUpdate(option)}
              />
            </div>

            <div className="col-md-6 col-lg-6 mb-3">
              <div className="row row-sm">
                <div className="col-md-8">
                  <label>
                    {/* {languageStatus
                      ? dictionary["WO_COUNTRY_1435082597"]
                      : "Country"} */}
                    Commission Type
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <Select
                    options={countries}
                    isClearable={false}
                    isSearchable={true}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    menuPortalTarget={menuPortalTarget}
                    //   value={exchangeUpdate}
                    //   onChange={(option) => setExchangeRateUpdate(option)}
                  />
                </div>
                <div className="col-md-4">
                  <label className="fw-bold">
                    {/* {languageStatus
                    ? dictionary["WO_COMMISSION_-1834937170"]
                    : "Commission%"} */}

                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control border px-3"
                    placeholder=""
                    value={""}
                    name="address_line_2"
                    id="address_line_2"
                    //   onChange={(e) => setName(e.target.value)}
                    autoComplete=""
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 mb-3">
              <div className="row row-sm">
                <div className="col-md-6 col-lg-6">
                  <label className="fw-bold">
                    {languageStatus
                      ? dictionary["msg_enter_your_password"]
                      : "Enter password"}
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type={"password"}
                    className="form-control border px-3"
                    placeholder=""
                    value={""}
                    name="password"
                    id="password"
                    //   onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-lg-6">
                  <label>
                    {/* {languageStatus
                          ? dictionary["para_account_type"] + " Required"
                          : "Account Type Required"} */}
                    Sort code required:
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="border py-2 rounded-3"
                    style={{ height: "40px" }}
                  >
                    <input type={"checkbox"} className="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <button
                // onClick={addNewAMLRule}
                className="btn btn-primary margin-label width-100 fw-bold"
                type="button"
              >
                SAVE
              </button>
            </div>
          </div>
        </form>

        <div className="col-md-6 col-lg-6 mt-5">
          <p className="text-muted card-sub-title">Sort and filter table</p>

          <input
            type={"text"}
            className="form-control border px-3"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            placeholder="Search..."
            autoComplete=""
          />
        </div>
        <div className="table-responsive mt-5">
          {" "}
          <DataTable columns={columns} data={[]} />{" "}
        </div>
      </div>
    </div>
  );
}

export default FixedAmountFee;
