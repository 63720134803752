import React from "react";
import { useSelector } from "react-redux";

import Body from "./Body";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";

const Agent = () => {
  const { domainBrand, component } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      usersReducer,
      countryReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      users: usersReducer,
      country: countryReducer,
    })
  );

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      <div className="page-main">
        <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
        <Header domainBrand={domainBrand} />
        <HeaderMenu
          menu="management"
          active="agents"
          domainBrand={domainBrand}
        />
        <Body />
        <Footer domainBrand={domainBrand} />
      </div>
    </div>
  );
};

export default Agent;
