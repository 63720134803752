import { useState } from "react";
import SphereLoader from "../../../../../components/loaders/SphereLoader";
import KYCUploadModal from "../../../../../components/modals/KYCUploadModal";
import FilledBankFields from "../../../../../components/bankFields/FilledBankFields";
import { transferStatus } from "../../../../../utils/helpers/constants";

const InfoTab = ({
  getRequiredDocumentsForTransaction,
  properties,
  docxProps,
  fieldsChanged,
  userType,
  onSubmitClick,
}) => {
  const {
    formik,
    pageStatus,
    helperValues,
    loadingDetails,
    quoteData,
    hideRate,
    disableSubmit,
    loading,
  } = properties;
  const {
    documentFieldStatus,
    requiredDocuments,
    profile,
    domainBrand,
    supportedCountries,
    sendingAmount,
  } = docxProps;
  const [documentModalStatus, setDocumentModalStatus] = useState(false);
  const [documentProps, setDocumentProps] = useState({});
  const sendCurrency =
    pageStatus === transferStatus.WALLET
      ? helperValues["receivingCurrency"]["value"]
      : quoteData["sendingCurrency"];
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const addDocumentFields = (item) => {
    setDocumentProps(item);
    setDocumentModalStatus(true);
  };

  const changeUploadRenderStatus = async () => {
    await getRequiredDocumentsForTransaction(sendingAmount);
    setDocumentModalStatus(false);
  };
  const uploadModalRender = documentModalStatus ? (
    <KYCUploadModal
      domainBrand={domainBrand}
      onchange={changeUploadRenderStatus}
      profile={profile["userData"]}
      uploadProps={documentProps}
      countries={supportedCountries}
      type="AML"
    />
  ) : null;

  return (
    <div className="col-xl-6 col-md-6">
      {uploadModalRender}
      <div className="card overflow-hidden">
        <div className="card-header bg-primary text-white">
          <div className="card-title">
            {languageStatus
              ? dictionary["l_transaction_details"]
              : "Transaction Details"}
          </div>
        </div>
        <div className="card-body">
          {loadingDetails ? (
            <SphereLoader />
          ) : (
            <>
              <div className="row">
                <div className="col-6">
                  <p className="text-muted">
                    {languageStatus
                      ? dictionary["para_account_holder_name"]
                      : "Holder Name"}
                  </p>
                  <p>
                    {formik.values.beneficiary
                      ? `${formik.values.beneficiary["firstName"]} ${formik.values.beneficiary["lastName"]}`
                      : "-"}
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-muted">
                    {languageStatus
                      ? dictionary["l_beneficiary_id"]
                      : "Beneficiary ID"}
                  </p>
                  <p>
                    {formik.values.beneficiary
                      ? formik.values.beneficiary["beneficiaryCode"]
                      : "-"}
                  </p>
                </div>
              </div>
              {Object.entries(quoteData).length > 0 ? (
                <>
                  {hideRate || pageStatus === transferStatus.WALLET ? (
                    ""
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <p className="text-muted">
                          {languageStatus
                            ? dictionary["l_exchangerate"]
                            : "Exchange Rate"}
                        </p>
                        {/* HERE */}
                        <p>
                          {`1.00 ${
                            quoteData.sendingCurrency !== null
                              ? quoteData.sendingCurrency
                              : ""
                          } = ${quoteData["fxRate"]} ${
                            quoteData["receivingCurrency"] !== null
                              ? quoteData["receivingCurrency"]
                              : ""
                          }`}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-6">
                      <p className="text-muted">
                        {languageStatus
                          ? dictionary["para_charges"]
                          : "Charges"}
                      </p>
                      <p>{`${quoteData["sendMoneyfee"]} ${
                        sendCurrency !== null ? sendCurrency : ""
                      }`}</p>
                    </div>
                    <div className="col-6">
                      <p className="text-muted">
                        {languageStatus ? dictionary["l_vat"] : "Tax rate"}
                      </p>
                      <p>{`${quoteData["tax"]} ${
                        sendCurrency !== null ? sendCurrency : ""
                      }`}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p className="text-muted">
                        {languageStatus
                          ? dictionary["para_total_amount"]
                          : "Total amount"}
                      </p>
                      <p>{`${quoteData["total_amount"]} ${
                        sendCurrency !== null ? sendCurrency : ""
                      }`}</p>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {pageStatus !== transferStatus.TRANSFER_FUNDS &&
              formik.values.beneficiary ? (
                <button
                  onClick={() => formik.submitForm()}
                  disabled={disableSubmit}
                  className="btn btn-primary mt-3 float-end"
                  type="submit"
                >
                  {languageStatus
                    ? dictionary["button_make_payment"]
                    : "Make Payment"}
                  {loading ? (
                    <>
                      &nbsp; <i className="fa fa-spin fa-spinner" />
                    </>
                  ) : (
                    ""
                  )}
                </button>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
      {documentFieldStatus ? (
        <div className="card overflow-hidden">
          <div className="card-header bg-primary text-white">
            <h3 className="card-title">
              {languageStatus
                ? dictionary["para_customer_documents"]
                : "Documents"}
            </h3>
          </div>
          <div className="table-responsive">
            <table className="table table-hover card-table table-striped table-vcenter table-outline text-nowrap">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col">
                    <span className="text-danger fs-12 mr-2rem">
                      Some Documents are required for the transaction
                    </span>
                  </th>
                  <th scope="col" className="fs-12">
                    {languageStatus
                      ? dictionary["WO_ACTION_1788593463"]
                      : "Actions"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {requiredDocuments?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.message}</td>
                      <td>
                        <button
                          onClick={() => addDocumentFields(item)}
                          type="button"
                          className="btn btn-info btn-sm"
                        >
                          Upload
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
      {pageStatus === transferStatus.TRANSFER_FUNDS &&
      formik.values.beneficiary &&
      formik.values.bankFields ? (
        <div className="card overflow-hidden">
          <div className="card-header bg-primary text-white">
            <div className="card-title">
              {languageStatus
                ? dictionary["BANK_DETAIL_2044985812"]
                : "Bank Details"}
            </div>
          </div>
          <div className="card-body">
            {/* HERE */}
            <form className="needs-validation" onSubmit={formik?.handleSubmit}>
              <FilledBankFields
                bankFields={formik.values["bankFields"]}
                formik={formik}
                keyPressed={fieldsChanged}
                userType={userType}
              />
              {userType === "CASHIER" && (
                <div className="row row-sm">
                  <div className="col-md-12 mb-3">
                    <label>
                      {languageStatus ? dictionary["l_password"] : "Password"}
                    </label>
                    <input
                      type="password"
                      name="password"
                      className={`form-control ${
                        formik.errors["password"] && onSubmitClick
                          ? "is-invalid"
                          : ""
                      }`}
                      required
                      onChange={formik.handleChange}
                    />
                    {formik.errors["password"] ? (
                      <div className="invalid-feedback">
                        Please provide your password.
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              <button
                disabled={disableSubmit}
                className="btn btn-primary mt-3 float-end"
                type="submit"
              >
                {languageStatus
                  ? dictionary["button_make_payment"]
                  : "Make Payment"}
                {loading ? (
                  <>
                    &nbsp; <i className="fa fa-spin fa-spinner" />
                  </>
                ) : (
                  ""
                )}
              </button>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InfoTab;
