import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

import Header from "../../../../../components/header/Header";
import HeaderMenu from "../../../../../components/header/HeaderMenu";
import Footer from "../../../../../components/footer/Footer";
import Body from "./Body";
import SphereLoader from "../../../../../components/loaders/SphereLoader";

const EmployeesDetails = () => {

  const history = useHistory();
  const [details, setDetails] = useState('');
  const {domainBrand, component} = useSelector(({domainBrandReducer, componentReducer}) => ({
    domainBrand: domainBrandReducer,
    component: componentReducer,
  }));

  useEffect(() => {
    if (typeof history.location.state === "undefined") history.goBack();
    setDetails(history.location.state)
  }, [history.location]);

  const loadState = Object.entries(details).length === 0;

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? <SphereLoader/> :
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"/>
            <Header domainBrand={domainBrand}/>
            <HeaderMenu menu="management" active="employees" domainBrand={domainBrand}/>
            <Body details={details.data} pageStatus={details.pageState}/>
          </div>
          <Footer domainBrand={domainBrand}/>
        </>
      }
    </div>
  );
}

export default EmployeesDetails;
