import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import Select from "react-select";
import CustomDatePicker from "../../../../../components/datePickers/CustomDatePicker";
import { fetchAccInterestRate } from "../../../../../store/actions/acc-management.action";
import {
  accountTypes,
  commissionType,
  daysCount,
  feesType,
  intMethod,
  paymentFeeMethods,
} from "../../../../../utils/helpers/objectHelpers";
import {
  interestInitialValues,
  interestRateSchema,
} from "../../../../../utils/helpers/validationHelpers";
import { addNewInterestRate } from "../../../../../utils/services/acc-management.service";

function AddInterestRate({
  domainBrand,
  handleCloseForm,
  actionType,
  intObject,
}) {
  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const formRef = useRef();
  const dispatch = useDispatch();

  const [startDate, getStartDate] = useState("");

  const accTypes = accountTypes(languageStatus, dictionary);

  const addRate = async (values, { resetForm }) => {
    delete values.principal;
    const payload = {
      interestRateRequestDTOs: [
        {
          ...values,
          accountMaintenanceFeeInterestType:
            values?.accountMaintenanceFeeInterestType?.value,
          accountMaintenanceFeePaymentMethod:
            values?.accountMaintenanceFeePaymentMethod?.value,
          accountType: values?.accountType?.value,
          interestCalculationMethods: values?.interestCalculationMethods?.value,
          interestRateType: values?.interestRateType?.value,
          repeatDaysCountUnit: values?.repeatDaysCountUnit?.value,
          startDate: startDate,
        },
      ],
    };

    try {
      const response = await addNewInterestRate(payload);

      if (response?.data?.status === "SUCCESS") {
        toast.success("Interest added successfully");
        dispatch(fetchAccInterestRate());
        getStartDate("");
        handleCloseForm();
        resetForm({});
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <Formik
      initialValues={interestInitialValues(actionType, intObject)}
      validationSchema={interestRateSchema}
      onSubmit={addRate}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        errors,
        setFieldValue,
      }) => {
        return (
          <Form
            className="needs-validation"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <div className="form-row">
              <div className="col-lg-6 col-md-6 mb-3">
                <label>
                  {dictionary
                    ? dictionary["WO_WHAT_IS_THE_NAME_OF_1619155431"]
                    : "What is the Reference Code of this fee?"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  name="chargeRef"
                  id="chargeRef"
                  value={values.chargeRef}
                  className={`form-control ${
                    errors["chargeRef"] ? "is-invalid" : ""
                  }`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <label>
                  {dictionary
                    ? dictionary["WO_STARTING_DAT_1625712635"]
                    : "Starting Date"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <CustomDatePicker
                  getDate={getStartDate}
                  showYearDropdown
                  selectDropdownMode
                  setMaxDate={new Date()}
                  withPortal={true}
                />
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <div className="row row-sm">
                  <div className="col-lg-6 col-md-6 mb-3">
                    <label id="commissionType">
                      {dictionary
                        ? dictionary["WO_COMMISSION_TYPE_-1343064437"]
                        : "Commission Type"}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Select
                      options={commissionType}
                      isClearable={false}
                      maxMenuHeight={250}
                      menuPlacement="bottom"
                      menuPortalTarget={menuPortalTarget}
                      value={values.interestRateType}
                      name="interestRateType"
                      onChange={(option) =>
                        setFieldValue("interestRateType", option)
                      }
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 mb-3">
                    <label>
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type={"number"}
                      name="charge"
                      id="charge"
                      value={values.charge}
                      className={`form-control ${
                        errors["charge"] ? "is-invalid" : ""
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <div className="row row-sm">
                  <div className="col-lg-6 col-md-6">
                    <label>
                      {dictionary
                        ? dictionary["WO_APPLY_EVERY_NUMBER_O_-1179967928"]
                        : "Apply every"}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type={"number"}
                      value={values.repeatDaysCount}
                      className={`form-control ${
                        errors["repeatDaysCount"] ? "is-invalid" : ""
                      }`}
                      name="repeatDaysCount"
                      id="repeatDaysCount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label>
                      {dictionary ? dictionary["l_transaction_tyep"] : "Type"}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Select
                      options={daysCount}
                      isClearable={false}
                      maxMenuHeight={250}
                      menuPlacement="bottom"
                      menuPortalTarget={menuPortalTarget}
                      value={values.repeatDaysCountUnit}
                      name="repeatDaysCountUnit"
                      onChange={(option) =>
                        setFieldValue("repeatDaysCountUnit", option)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <label>
                  {dictionary
                    ? dictionary["WO_ALLOCATE_TO_PRINCIPA_-878815115"]
                    : "Allocate to principal"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="number"
                  name="principal"
                  id="principal"
                  value={values.principal}
                  className={`form-control ${
                    errors["principal"] ? "is-invalid" : ""
                  }`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <label id="commissionType">
                  {dictionary
                    ? dictionary["WO_FEE_INTEREST_TYPE_709094701"]
                    : "Fee Interest Types"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  options={feesType}
                  isClearable={false}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                  value={values.accountMaintenanceFeeInterestType}
                  name="accountMaintenanceFeeInterestType"
                  onChange={(option) =>
                    setFieldValue("accountMaintenanceFeeInterestType", option)
                  }
                />
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <div className="row row-sm">
                  <div className="col-lg-6 col-md-6 mb-3">
                    <label id="commissionType">
                      {dictionary
                        ? dictionary["WO_MINIMUM_AMOUN_-106404525"]
                        : "Minimum Amount"}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type={"number"}
                      name="minimum_amount"
                      id="minimum_amount"
                      value={values.minimum_amount}
                      className={`form-control ${
                        errors["minimum_amount"] ? "is-invalid" : ""
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 mb-3">
                    <label>
                      {dictionary
                        ? dictionary["WO_MAXIMUM_AMOUN_-1198115106"]
                        : "Maximum Amount"}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type={"number"}
                      name="maximum_amount"
                      id="maximum_amount"
                      value={values.maximum_amount}
                      className={`form-control ${
                        errors["maximum_amount"] ? "is-invalid" : ""
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <label>
                  {dictionary
                    ? dictionary["WO_GRACE_PERIO_-1111466201"]
                    : "Grace Period"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="number"
                  name="gracePeriod"
                  id="gracePeriod"
                  value={values.gracePeriod}
                  className={`form-control ${
                    errors["gracePeriod"] ? "is-invalid" : ""
                  }`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <label id="commissionType">
                  {dictionary
                    ? dictionary["WO_APPLY_TO_ACCOUN_-157463306"]
                    : "Apply to account"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  options={accTypes}
                  isClearable={false}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                  value={values.accountType}
                  name="accountType"
                  onChange={(option) => setFieldValue("accountType", option)}
                />
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <label id="commissionType">
                  {dictionary
                    ? dictionary["WO_FEE_PAYMENT_METHO_2005973532"]
                    : "Fee Payment Method"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  options={paymentFeeMethods}
                  isClearable={false}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                  value={values.accountMaintenanceFeePaymentMethod}
                  name="accountMaintenanceFeePaymentMethod"
                  onChange={(option) =>
                    setFieldValue("accountMaintenanceFeePaymentMethod", option)
                  }
                />
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <label id="commissionType">
                  {dictionary
                    ? dictionary["WO_INTEREST_CALCULATION_-403402091"]
                    : "Interest Calculation Method"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  options={intMethod}
                  isClearable={false}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                  value={values.interestCalculationMethods}
                  name="interestCalculationMethods"
                  onChange={(option) =>
                    setFieldValue("interestCalculationMethods", option)
                  }
                />
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <label>
                  {dictionary
                    ? dictionary["msg_enter_your_password"]
                    : "Enter your password"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type={"password"}
                  name="password"
                  id="password"
                  value={values.password}
                  className={`form-control ${
                    errors["password"] ? "is-invalid" : ""
                  }`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </div>

              <div className="col-lg-6 col-md-6">
                <Button
                  variant={"primary"}
                  type={"submit"}
                  className="fw-bold w-100 float-end mb-3"
                >
                  {dictionary ? dictionary["WO_SAVE_-91866246"] : "SAVE"}
                  {isSubmitting ? (
                    <>
                      &nbsp;
                      <i className="fa fa-spinner fa-spin" />
                    </>
                  ) : (
                    ""
                  )}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AddInterestRate;
