import {useState} from 'react';
import Modal from 'react-bootstrap/Modal';

const KYCSuccessModal = ({setNextRender, nextRender}) => {
  const [show, setShow] = useState(true);

  const nextKYC = async () => {
    setShow(false);
    setNextRender(nextRender);
  }

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="modal-body text-center p-4">
            <i className="fa fa-check-circle fs-65 text-success lh-1 mb-4 d-inline-block"/>
            <h4 className="text-primary mb-20">E-KYC Upload Successful</h4>
            <button aria-label="Close" onClick={nextKYC} className="btn btn-primary pd-x-25">Next</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default KYCSuccessModal;
