import { toast } from "react-hot-toast";
import * as RateConstants from "../constants/rate.constant";
import ActionCreator from "../../utils/helpers/actionCreator";
import { expiredTokenCheck } from "./auth.action";
import {
  listExchangeRateFeeds,
  addExchangeRateFeed,
  editExchangeRateFeed,
  deleteExchangeRateFeed,
  linkExchangeRateFeedToCorridor,
  listPayCentersByType,
} from "../../utils/services/rate.service";

export const fetchExchangeRate = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(RateConstants.EXCHANGE_RATE_REQUEST));

    const { data: responseData } = await listExchangeRateFeeds();

    // if (responseData.status === "FAILED") throw responseData;

    dispatch(ActionCreator(RateConstants.EXCHANGE_RATE_SUCCESS, responseData));

    toast.success("Exchange rate fetched successfully.");
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(RateConstants.EXCHANGE_RATE_FAILURE));
  }
};

export const fetchExchangeRateCorridor = () => async (dispatch) => {
  try {
    dispatch(
      ActionCreator(RateConstants.LINK_TO_EXCHANGE_RATE_CORRIDOR_REQUEST)
    );

    const { data: responseData } = await linkExchangeRateFeedToCorridor();

    // if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        RateConstants.LINK_TO_EXCHANGE_RATE_CORRIDOR_SUCCESS,
        responseData
      )
    );

    toast.success("Corridor fetched successfully.");
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(
      ActionCreator(RateConstants.LINK_TO_EXCHANGE_RATE_CORRIDOR_FAILURE)
    );
  }
};

export const fetchPaycenters = (plugin_type) => async (dispatch) => {
  try {
    dispatch(ActionCreator(RateConstants.PAYCENTER_REQUEST));

    const { data: responseData } = await listPayCentersByType(plugin_type);

    dispatch(ActionCreator(RateConstants.PAYCENTER_SUCCESS, responseData));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(RateConstants.PAYCENTER_FAILURE));
  }
};

export const addExchangeRate = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(RateConstants.ADD_EXCHANGE_RATE_REQUEST));
    const { data: responseData } = await addExchangeRateFeed(payload);

    // if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(RateConstants.ADD_EXCHANGE_RATE_SUCCESS, responseData.data)
    );
    toast.success("Exchange rate registered successfully.");
  } catch (error) {
    dispatch(ActionCreator(RateConstants.ADD_EXCHANGE_RATE_FAILURE));
    toast.error(`${error?.message}`);
  }
};

export const editExchangeRate = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(RateConstants.EDIT_EXCHANGE_RATE_REQUEST));
    const { data: responseData } = await editExchangeRateFeed(payload);

    // if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(RateConstants.EDIT_EXCHANGE_RATE_SUCCESS, responseData.data)
    );
    toast.success("Exchange rate edited successfully.");
  } catch (error) {
    dispatch(ActionCreator(RateConstants.EDIT_EXCHANGE_RATE_FAILURE));
    toast.error(`${error?.message}`);
  }
};

export const deleteExchangeRate = (query) => async (dispatch) => {
  try {
    dispatch(ActionCreator(RateConstants.DELETE_EXCHANGE_RATE_REQUEST));
    const { data: responseData } = await deleteExchangeRateFeed(query);

    // if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(RateConstants.ADD_EXCHANGE_RATE_SUCCESS, responseData.data)
    );
    toast.success("Exchange rate deleted successfully.");
  } catch (error) {
    dispatch(ActionCreator(RateConstants.DELETE_EXCHANGE_RATE_FAILURE));
    toast.error(`${error?.message}`);
  }
};
