import React from "react";
import { BsWalletFill } from "react-icons/bs";
import PropTypes from "prop-types";

const ProductCard = ({ products = {}, handleClick }) => {
  return (
    <div className="col-lg-4 col-md-4 mt-3" key={products["accountProductId"]}>
      <div className="card shadow">
        <div className="card-body">
          <BsWalletFill color={"#6259CA"} fontSize={23} />
          <h5 class="card-title mt-3">{products["productName"]}</h5>
          <button
            className="btn btn-primary margin-label width-100 fw-bold"
            type="button"
            onClick={handleClick}
          >
            Request
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

ProductCard.defaultProps = {
  handleClick: () => {},
};

ProductCard.propTypes = {
  id: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};
