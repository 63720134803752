import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Body from "./Body";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import { toggleNav } from "../../../../store/actions/component.action";
import { Loading } from "../../../../utils/helpers/constants";

function Overdrafts() {
  const dispatch = useDispatch();
  const { domainBrand, component } = useSelector(
    ({ domainBrandReducer, componentReducer }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
    })
  );
  const loadState = domainBrand.loading === Loading.FETCHING;

  useEffect(() => {
    dispatch(toggleNav(false));
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="accounting"
              active="treasury"
              domainBrand={domainBrand}
            />
            <Body domainBrand={domainBrand} />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
}

export default Overdrafts;
