import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Footer from "../../../../components/footer/Footer";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { Loading } from "../../../../utils/helpers/constants";
import Body from "./Body";

const Payout = () => {
  const history = useHistory();
  const [payoutData, setPayoutData] = useState({});
  const [secretQuestionState, setSecretQuestionState] = useState(false);
  const [securityOTPState, setSecurityOTPState] = useState(false);

  const { domainBrand, component, profile } = useSelector(
    ({ domainBrandReducer, componentReducer, profileReducer }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      profile: profileReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    profile.userProfileLoading === Loading.FETCHING;

  useEffect(() => {
    if (typeof history.location.state === "undefined") {
      history.push("/payout-search");
    } else setPayoutData(history.location.state);
  }, []);

  useEffect(() => {
    if (Object.entries(payoutData).length > 0) {
      payoutData["requiredQuestionAndAnswer"] === true
        ? setSecretQuestionState(true)
        : setSecretQuestionState(false);
      payoutData["requiredOtpOnPayout"]
        ? setSecurityOTPState(true)
        : setSecurityOTPState(false);
    }
  }, [history.location.state]);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="payout"
              active="payout"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              payoutData={payoutData}
              secretQuestionState={secretQuestionState}
              securityOTPState={securityOTPState}
              profile={profile}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default Payout;
