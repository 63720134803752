/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import Select from "react-select";
import DataTable from "../../../../components/dataTable/dataTable";
import CustomDatePicker from "../../../../components/datePickers/CustomDatePicker";
import { openBalanceDataTableConfig } from "../../../../utils/helpers/dataTableConfigs";
import { openingBalAction } from "../../../../utils/helpers/objectHelpers";

function Body({ domainBrand, currency }) {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const menuPortalTarget = document.getElementById("root");

  const action = openingBalAction(dictionary);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [openAction, setOpenAction] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");

  const actionColumn = {
    Header: () => {
      return (
        <span className="text-center fw-bold">
          {languageStatus ? dictionary["l_action"] : "Action"}
        </span>
      );
    },
    columnId: 10,
    accessor: "actions",
  };

  const openBalTable = openBalanceDataTableConfig(languageStatus, dictionary);
  const tableObject = [...openBalTable, actionColumn];

  const columns = useMemo(() => tableObject, []);

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {dictionary
                ? dictionary["m_opening_balance_history"]
                : "Opening Balance History"}
            </h1>
          </div>
        </div>

        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title fw-bold">
                  {dictionary
                    ? dictionary["m_opening_balance_history"]
                    : "Opening Balance History"}
                </h3>
              </div>
              <div className="card-body">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="needs-validation"
                >
                  <div className="form-row">
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <label>From</label>
                      <CustomDatePicker
                        getDate={setFrom}
                        showYearDropdown
                        selectDropdownMode
                        withPortal={true}
                        setMaxDate={new Date()}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <label>To</label>
                      <CustomDatePicker
                        getDate={setTo}
                        showYearDropdown
                        selectDropdownMode
                        withPortal={true}
                        setMaxDate={new Date()}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["para_currency_code"]
                          : "Currency Code"}
                      </label>
                      <Select
                        options={currency}
                        isClearable={true}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="currency-code"
                        value={currencyCode}
                        onChange={(option) => setCurrencyCode(option)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <label>
                        {languageStatus ? dictionary["l_action"] : "Action"}
                      </label>
                      <Select
                        options={action}
                        isClearable={true}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="open-action"
                        value={openAction}
                        onChange={(option) => setOpenAction(option)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <button
                        onClick={() => {}}
                        className="btn btn-primary margin-label w-100"
                        type="button"
                      >
                        Search
                        {loading && (
                          <>
                            &nbsp;
                            <i className="fa fa-spinner fa-spin" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>

                <div className="col-lg-12 col-md-12 mt-5">
                  <div className="table-responsive">
                    <DataTable columns={columns} data={[]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
