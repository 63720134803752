import * as ComplianceConstants from "../constants/compliance.constants";
import { Loading } from "../../utils/helpers/constants";

const initialState = {
  kycRules: [],
  amlRules: [],
  awaitingDocuments: [],
  eVideo: {},
  ePhoto: {},
  verifyEmail: {},
  fetchingKYC: "",
  fetchingAML: "",
  fetchingRequiredFields: "",
  fetchingAwaitingCompliance: "",
  addAMLRule: {},
  creatingAML: "",
};

const complianceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ComplianceConstants.GET_KYC_RULES_REQUEST:
      return { ...state, fetchingKYC: Loading.FETCHING };

    case ComplianceConstants.GET_KYC_RULES_SUCCESS:
      return {
        ...state,
        kycRules: action.payload["knowYourCustomerRuleDTOs"],
        fetchingKYC: Loading.SUCCESS,
      };

    case ComplianceConstants.GET_KYC_RULES_FAILURE:
      return { ...state, fetchingKYC: Loading.ERROR };

    case ComplianceConstants.GET_AML_RULES_REQUEST:
      return { ...state, fetchingAML: Loading.FETCHING };

    case ComplianceConstants.GET_AML_RULES_SUCCESS:
      return {
        ...state,
        amlRules: action.payload["businessTransactionRuleResponseDTOs"],
        fetchingAML: Loading.SUCCESS,
      };

    case ComplianceConstants.GET_AML_RULES_FAILURE:
      return { ...state, fetchingAML: Loading.ERROR };

    case ComplianceConstants.GET_REQUIRED_COMPLIANCE_FIELDS_REQUEST:
      return { ...state, fetchingRequiredFields: Loading.FETCHING };

    case ComplianceConstants.GET_REQUIRED_COMPLIANCE_FIELDS_SUCCESS:
      const data = action.payload;
      return {
        ...state,
        [data.type]: data["dynamicFieldDTOs"],
        fetchingRequiredFields: Loading.SUCCESS,
      };

    case ComplianceConstants.GET_REQUIRED_COMPLIANCE_FIELDS_FAILURE:
      return { ...state, fetchingRequiredFields: Loading.ERROR };

    case ComplianceConstants.GET_AWAITING_REQUIREMENTS_REQUEST:
      return { ...state, fetchingAwaitingCompliance: Loading.FETCHING };

    case ComplianceConstants.GET_AWAITING_REQUIREMENTS_SUCCESS:
      const documents = action.payload;
      return {
        ...state,
        awaitingDocuments: documents.documents,
        verifyEmail: documents.verifyEmail,
        eVideo: documents.eVideo,
        ePhoto: documents.ePhoto,
        fetchingAwaitingCompliance: Loading.SUCCESS,
      };

    case ComplianceConstants.CREATE_AML_RULES_REQUEST:
      return { ...state, creatingAML: Loading.FETCHING };

    case ComplianceConstants.CREATE_AML_RULES_FAILURE:
      return { ...state, creatingAML: Loading.ERROR };

    case ComplianceConstants.CREATE_AML_RULES_SUCCESS:
      return {
        ...state,
        addAMLRule: action.payload,
        creatingAML: Loading.SUCCESS,
      };

    case ComplianceConstants.GET_AWAITING_REQUIREMENTS_FAILURE:
      return { ...state, fetchingAwaitingCompliance: Loading.ERROR };

    default:
      return state;
  }
};

export default complianceReducer;
