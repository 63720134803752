import pdfmake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

const pdfGeneratorPayout = async (data, domainBrand, profile) => {
  pdfmake.vfs = pdfFonts["pdfMake"].vfs;
  const {userData: {address}} = profile;
  const {
    companyName,
    companyContactEmailAddress,
    companyContactPhoneNumber,
    companyWebSiteUrl,
    logo
  } = domainBrand.domainBrand;

  const {
    date,
    objectReference,
    transactionType,
    transactionStatus,
    receiverFirstName,
    receiverMiddleName,
    receiverLastName,
    receivingAmount,
    receiverCurrency,
    sendingAmount,
    sendingCurrency,
    senderFirstName,
    senderLastName
  } = data;

  // const {
  //   domainBrand: {companyName, companyContactEmailAddress, companyContactPhoneNumber, companyWebSiteUrl, logo}
  // } = domainBrand;


  console.log(logo);

  const docDefinition = {
    pageOrientation: "portrait",

    content: [
      {
        columns: []
      },
      {
        text: "Payout Details",
        style: "header"
      },
      {
        columns: [
          {
            columns: [
              {
                text: `${transactionType ? "Delivery Method" : ""} \u200B \n ${objectReference ? "PCN" : ""} \u200B \n ${transactionStatus ? "Status" : ""}`,
                width: "auto",
                style: "infoNude"
              },
              {
                text: date,
                width: "auto",
                style: "info"
              }
            ]
          },
          {
            text: `${transactionType ?? "\n"} \n ${objectReference ?? "\n"} \n ${transactionStatus ?? "\n"}`,
            width: "*",
            style: "address"
          }
        ]
      },
      {
        text: "Transaction Information",
        style: "subheader"
      },
      {canvas: [{type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2}], style: "pretext"},
      {
        columns: [
          {
            text: "Date of Transaction",
            width: 300,
            style: "infoNude"
          },
          {
            text: "PCN",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: "-",
            width: 300,
            style: "info"
          },
          {
            text: objectReference,
            width: 300,
            style: "info"
          }
        ]
      },
      {
        columns: [
          {
            text: "Sender Details",
            width: 300,
            style: "infoNude"
          },
          {
            text: "Receiver Details",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: `${senderFirstName} ${senderLastName}`,
            width: 300,
            style: "info"
          },
          {
            text: `${receiverFirstName} ${receiverMiddleName} ${receiverLastName}`,
            width: 300,
            style: "info"
          }
        ]
      },
      {
        columns: [
          {
            text: `Amount sent to ${receiverFirstName} ${receiverMiddleName} ${receiverLastName}`,
            width: 300,
            style: "infoNude"
          },
          {
            text: "Total amount received",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: parseFloat(sendingAmount).toFixed(2) + " " + sendingCurrency,
            width: 300,
            style: "info"
          },
          {
            text: parseFloat(receivingAmount).toFixed(2) + " " + receiverCurrency,
            width: 300,
            style: "info"
          }
        ]
      },
      {
        text: "Pay Center",
        style: "subheader"
      },
      {canvas: [{type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2}], style: "pretext"},
      {
        columns: [
          {
            text: "Agency / Cashier",
            width: 300,
            style: "infoNude"
          },
          {
            text: "Email",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: `${companyName ?? ""} \n ${address.address1 + " " + address.address2 ?? ""} \n ${address.city + ", " + address.postcode ?? ""} \n ${address.countryCommonName}`,
            width: 300,
            style: "info"
          },
          {
            text: `${companyContactEmailAddress ?? ""}`,
            width: 300,
            style: "info"
          }
        ]
      },
      {
        columns: [
          {
            text: "Website URL",
            width: 300,
            style: "infoNude"
          },
          {
            text: "Phone Number",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: companyWebSiteUrl,
            width: 300,
            style: "info"
          },
          {
            text: companyContactPhoneNumber,
            width: 300,
            style: "info"
          }
        ]
      },
      {
        text: "Signature",
        style: "subheader"
      },
      {canvas: [{type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2}], style: "pretext"},
      {
        columns: [
          {
            text: "________________________________ \n Beneficiary Signature",
            width: 180,
            style: "infoNude"
          },
          {
            text: "________________________________ \n Cashier's Signature",
            width: 180,
            style: "infoNude"
          },
          {
            text: "________________________________ \n Authorization",
            width: 180,
            style: "infoNude"
          }
        ]
      },

    ],

    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 20],
        alignment: "left"
      },
      subheader: {
        fontSize: 12,
        bold: true,
        margin: [0, 40, 0, 10],
        alignment: "left"
      },
      pretext: {
        margin: [0, 0, 0, 20],
        alignment: "left"
      },
      infoNude: {
        color: "#696969",
        fontSize: 10,
        margin: [0, 10, 0, 0],
        bold: true,
        alignment: "left"
      },
      info: {
        color: "black",
        fontSize: 10,
        margin: [0, 10, 0, 0],
        alignment: "left"
      },
      address: {
        fontSize: 10,
        alignment: "right",
        color: "black"
      }
    }
  };

  pdfmake.createPdf(docDefinition).open();
};

export default pdfGeneratorPayout;
