import * as ComponentConstants from '../constants/component.constants';
import {Loading} from "../../utils/helpers/constants";

const initialState = {
  mobileNav: false,
  loading: "",
};

const componentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ComponentConstants.TOGGLE_MOBILE_NAV:
      return {...state, mobileNav: action.payload};

    case ComponentConstants.FETCH_DYNAMIC_FIELDS_REQUEST:
      return {...state, loading: Loading.FETCHING};

    case ComponentConstants.FETCH_DYNAMIC_FIELDS_SUCCESS:
      let formType = action.payload["formType"];
      return {...state, [formType]: action.payload, loading: Loading.SUCCESS}

    case ComponentConstants.FETCH_DYNAMIC_FIELDS_FAILURE:
      return {...state, loading: Loading.ERROR}

    default:
      return state;
  }
};

export default componentReducer;
