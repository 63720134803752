export const generalAppInfo = {
  APP_NAME: "Multiview Money Transfer Application",
  APP_VERSION: "v1",
  API_VERSION: "v6",
};

export const configs = {
  KEY: "12384-0987mu7t1V13wMT4-987to890f1nC0D3529;CUlpCyvNmm4r5h4llYPJFdk0ll451k3KAxfCyP48SCR36Gp8a5JU",
  COOKIE_EXPIRY_PERIOD: 1,
  LANGUAGE_DATA: "LANgTYygFTP4vmgVjjGvBVcRXvTYx",
  USER_VERIFIED_STATUS: "U53rgTPcRyyFTY4cRXvTYgFTjjGvBVx",
  SERVICE_CONTROL: "S3rU5gcRXvTYgFTjjGvBTPcRyyFTY4Vx",
};

export const accessScopes = {
  CUSTOMER_FLOW: "CUSTOMER_FLOW",
  CUSTOMER_FLOW_OPTIONAL_KYC: "CUSTOMER_FLOW_OPTIONAL_KYC",
  KYC_FLOW: "KYC_FLOW",
  KYC_FLOW_UPLOAD_DOCUMENT: "KYC_FLOW_UPLOAD_DOCUMENT",
  AWAITING_APPROVAL_FLOW: "AWAITING_APPROVAL_FLOW",
};

export const userTypes = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  CASHIER: "CASHIER",
  CUSTOMER: "REGISTERED_CUSTOMER",
};

export const userRoles = {};

export const customerTypes = {
  INDIVIDUAL: "INDIVIDUAL",
  COMPANY: "COMPANY",
  CASHIER: "CASHIER",
};

export const Loading = {
  FETCHING: "FETCHING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  FAILED: "FAILED",
};

export const STATUS = {
  FETCHING: "FETCHING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  FAILED: "FAILED",
};

export const StatementMode = {
  STATEMENT: "STATEMENT",
  BALANCE: "BALANCE",
};

export const menuType = {
  HOME: "home",
  CRM: "crm",
  REPORTS: "reports",
  ACCOUNTS: "accounts",
  TRANSFER: "transfer",
  UTILITIES: "utilities",
  BENEFICIARY: "beneficiary",
  BENEFICIARIES: "beneficiaries",
  PAYOUT: "payout",
  COMPLIANCE: "compliance",
  MANAGEMENT: "management",
  ACCOUNTING: "accounting",
  SETTINGS: "settings",
  GENERAL: "general",
  PROFILE: "profile",
  SUPPORT: "support",
  CUSTOMERPRODUCTS: "CustomerProducts",
};

export const menuInitialStatus = {
  home: false,
  crm: false,
  reports: false,
  accounts: false,
  transfer: false,
  beneficiary: false,
  payout: false,
  compliance: false,
  management: false,
  accounting: false,
  settings: false,
  general: false,
  profile: false,
  support: false,
};

export const accountTypes = {
  TREASURY: "view-treasury-balance",
};

export const transactionTypes = {
  WALLET: "WALLET",
};

export const transferStatus = {
  TRANSFER_FUNDS: "TRANSFER_FUNDS",
  WALLET: "WALLET",
  CASH_PICKUP: "CASH_PICKUP",
  MOBILE_MONEY: "MOBILE_MONEY",
  AIRTIME_TOPUP: "AIRTIME_TOPUP",
  BILL_PAYMENT: "BILL_PAYMENT",
};

export const paymentType = {
  E_WALLET: "E_WALLET",
  BANK_TRANSFER: "BANK_TRANSFER",
  ONLINE_CARD_PAYMENT: "ONLINE_CARD_PAYMENT",
};

export const chargeCategory = {
  WALLET_MONEY_LOAD_CASH: "WALLET_MONEY_LOAD_CASH",
};

export const dynamicFieldsTypes = {
  REGISTERED_CUSTOMER_SIGNUP: "REGISTERED_CUSTOMER_SIGNUP",
  COMPANY_CUSTOMER_SIGNUP: "COMPANY_CUSTOMER_SIGNUP",
  CUSTOMER_CREATE_BENEFICIARY: "REGISTERED_CUSTOMER_CREATE_BENE",
  COMPANY_CREATE_BENEFICIARY: "COMPANY_CUSTOMER_CREATE_BENE",
};

export const mandatoryFieldsTypes = {
  ONLINE_SENDER: "ONLINE_SENDER",
  RECEIVER: "RECEIVER",
  RECEIVER_COMPANY: "RECEIVER_COMPANY",
};

export const messageStates = {
  ALL: "ALL",
  READ: "READ",
  UNREAD: "UNREAD",
  SENT: "SENT",
};

export const messageDirection = {
  OUTGOING_MAIL: "OUTGOING_MAIL",
  INCOMING_MAIL: "INCOMING_MAIL",
};

export const bankPairs = {
  accountCategory: "accountcategory",
  accountHolderName: "accountholdername",
  accountId: "accountid",
  accountNumber: "bankaccountno",
  accountRank: "accountrank",
  accountType: "accounttype",
  addressLine: "addressline1",
  areaCode: "areacode",
  bankBranchId: "bankbranchid",
  bankCode: "bankcode",
  bankName: "bankname",
  branchCode: "branchcode",
  branchDistrict: "branchdistrict",
  branchName: "branchname",
  bankState: "bankstate",
  cardType: "cardtype",
  city: "bankcity",
  cnpj: "cnpj",
  cpf: "cpf",
  country: "country",
  iban: "iban",
  ibanCode: "iban",
  identityAlias: "identityalias",
  msisdn: "msisdn",
  ifsc: "ifsccode",
  ifsccode: "ifsccode",
  ifscCode: "ifscCode",
  linkRef: "linkref",
  mobileMoneyNumber: "mobilemoneynumber",
  op: "op",
  organisationId: "organisationid",
  postCode: "areacode",
  recipientType: "recipientType",
  RUTNumber: "rutingnumber",
  routingNumber: "routingNumber",
  rutNumber: "rutnumber",
  rutnumber: "rutnumber",
  sortCode: "sortcode",
  swiftCode: "swiftbic",
  state: "state",
  transitNumber: "accountrank",
  username: "username",
  walletId: "walletid",
};

export const statusStyle = {
  PAID: "bg-success",
  "Waiting to pay": "bg-warning",
  Initiated: "bg-muted",
  Cancelled: "bg-danger",
  Refunded: "bg-info",
  Completed: "bg-success",
};
