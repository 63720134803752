import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function ArchiveModal({
  show,
  handleClose,
  firstName,
  lastName,
  beneficiaryCode,
  handleArchive,
}) {
  return (
    <Modal show={show} backdrop="static" keyboard={false}>
      <Modal.Header closeButton={false}>
        <Modal.Title>
          Archive {firstName} {lastName}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {`Do you want to archive beneficiary with code - ${beneficiaryCode}?.`}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={handleArchive}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ArchiveModal.defaultProps = {
  handleClose: () => {},
  handleArchive: () => {},
};

ArchiveModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleArchive: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  beneficiaryCode: PropTypes.string.isRequired,
};
