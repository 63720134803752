import { useMemo, useState } from "react";

import DataTable from "../../../../components/dataTable/dataTable";
import { remittanceTableConfig } from "../../../../utils/helpers/dataTableConfigs";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Trash } from "../../../../assets/images/svgs/trash.svg";

import AddNewBrandModal from "../../../../components/modals/AddNewBrandModal";
import FixedAmountFee from "./atom/FixedAmountFee";

import { BsPlusCircleFill } from "react-icons/bs";

const Body = ({ domainBrand, currency, countries }) => {
  const [filterInput, setFilterInput] = useState("");
  const [show, setShow] = useState(false);
  const [addNewState, updateState] = useState(false);

  // const cardAccordion = (id) => {
  //   const element = document.getElementById(id);
  //   const cardCollapsed = element.className.split(" ")[1];
  //   updateCardCollapsedStatus(!cardCollapsed);
  //   if (
  //     typeof cardCollapsed === "undefined" ||
  //     cardCollapsed === null ||
  //     cardCollapsed === ""
  //   )
  //     element.classList.add("card-collapsed");
  //   else element.classList.remove("card-collapsed");
  // };

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const addNewStatus = (event) => {
    event.preventDefault();
    updateState(!addNewState);
  };

  const actionColumn = {
    Header: () => {
      return <span className="text-center">Action</span>;
    },
    columnId: 14,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="text-center pointer">
          <OverlayTrigger
            key={"2"}
            placement={"bottom"}
            overlay={<Tooltip id={`tooltip-${``}`}>Delete</Tooltip>}
          >
            <Trash />
          </OverlayTrigger>
        </div>
      );
    },
  };

  const tableObject = [...remittanceTableConfig, actionColumn];
  const columns = useMemo(() => tableObject, []);

  return (
    <>
      <AddNewBrandModal
        show={show}
        handleClose={() => setShow(false)}
        domainBrand={domainBrand}
      />

      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">
                {languageStatus
                  ? dictionary["para_money_transfer_charges_1"]
                  : "Money Transfer Fee"}
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  {languageStatus
                    ? dictionary["WO_A_FEE_BAND_IS_A_SET_-1512307040"]
                    : "A fee band is a set of rules governing how much customers will pay for each service."}
                </li>
              </ol>
            </div>
          </div>
          <div className="row row-sm">
            <div className="col-lg-12">
              <div className="form-row">
                <div className="col-md-6 col-lg-6">
                  <button
                    className="btn btn-primary w-100 fw-bold mb-2"
                    type="button"
                    onClick={() => setShow(true)}
                  >
                    <BsPlusCircleFill />{" "}
                    <span className="align-middle">ADD NEW BRAND</span>
                  </button>
                </div>

                <div className="col-md-6 col-lg-6">
                  <button
                    className="btn btn-primary w-100 fw-bold mb-2"
                    type="button"
                    onClick={addNewStatus}
                  >
                    <BsPlusCircleFill />{" "}
                    <span className="align-middle">ADD FIXED AMOUNT FEE</span>
                  </button>
                </div>
              </div>
              {addNewState && (
                <FixedAmountFee
                  dictionary={dictionary}
                  languageStatus={languageStatus}
                  domainBrand={domainBrand}
                  currency={currency}
                  countries={countries}
                />
              )}
            </div>

            <div className="col-lg-12">
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title">REMITTANCES</h3>
                </div>
                <div className="card-body">
                  <div className="col-md-6 col-lg-6">
                    <p className="text-muted card-sub-title">
                      Sort and filter table
                    </p>

                    <input
                      type={"text"}
                      className="form-control border px-3"
                      value={filterInput}
                      onChange={(e) => setFilterInput(e.target.value)}
                      placeholder="Search..."
                      autoComplete=""
                    />
                  </div>

                  <div className="table-responsive mt-5">
                    <DataTable
                      columns={columns}
                      data={[]}
                      filterData={filterInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
