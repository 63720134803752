import { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import moment from "moment";

import CustomDatePicker from "../../../../components/datePickers/CustomDatePicker";
import pdfGeneratorStatement from "../../../../utils/helpers/pdfGeneratorStatement";
import DataTable from "../../../../components/dataTable/dataTable";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { walletStatementConfig } from "../../../../utils/helpers/dataTableConfigs";
import {
  formatStatements,
  formatStringCurrency,
} from "../../../../utils/helpers/logicHelper";
import AccountsService from "../../../../utils/services/accounts.service";
import downloadSvg from "../../../../assets/images/svgs/download.svg";

const Body = ({ pageProps, accountData, accounts }) => {
  const { domainBrand, profile } = pageProps;
  const date = new Date();
  const monthAgo = moment(date).subtract(3, "month");
  const menuPortalTarget = document.getElementById("root");
  const [loading, updateLoading] = useState(false);
  const [startDate, updateStartDate] = useState(monthAgo);
  const [endDate, updateEndDate] = useState(date);
  const [selectedAccount, updateSelectedAccount] = useState(accountData);
  const [statement, updateStatement] = useState([]);
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  useEffect(async () => {
    const payload = {
      selectedCurrency: selectedAccount["walletAccountCurrency"] ?? "",
      accountNumber: selectedAccount["walletAccountNumber"] ?? "",
      fromDate: moment(startDate).format(),
      toDate: moment(endDate).endOf("day").format(),
    };

    if (
      selectedAccount["walletAccountNumber"] &&
      startDate &&
      endDate &&
      selectedAccount["walletAccountCurrency"]
    ) {
      updateLoading(true);
      const { data: responseData } = await AccountsService.fetchWalletLedger(
        payload
      );
      const formattedStatement = formatStatements(
        responseData.data["statemtent"]
      );
      updateStatement(formattedStatement);
      updateLoading(false);
    }
  }, [
    selectedAccount,
    selectedAccount["walletAccountCurrency"],
    startDate,
    endDate,
  ]);

  const getStartDate = (date) => updateStartDate(date);
  const getEndDate = (date) => updateEndDate(date);
  const tableObject = [...walletStatementConfig(languageStatus, dictionary)];
  const columns = useMemo(() => tableObject, []);

  const downloadPDF = (event) => {
    event.preventDefault();
    const fromDate = moment(startDate).format("MMM Do yyyy");
    const toDate = moment(endDate).endOf("day").format("MMM Do yyyy");
    const data = { profile, selectedAccount, fromDate, toDate };
    const pdfData = formatPDFData(statement);
    pdfGeneratorStatement(data, pdfData, domainBrand).catch((err) =>
      console.log(err)
    );
  };

  const formatPDFData = (data) => {
    return data.map(
      ({ executionDate, pcn, paymentReference, debit, credit, newBalance }) => [
        moment(new Date(executionDate)).format("DD-MM-yyyy") || "",
        pcn || "",
        paymentReference || "",
        { text: debit || "", alignment: "right" },
        { text: credit || "", alignment: "right" },
        { text: formatStringCurrency(newBalance) || "", alignment: "right" },
      ]
    );
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus
                ? dictionary["l_wallet_transaction_history"]
                : "Account Statements"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn">
            <NavLink
              onClick={downloadPDF}
              to=""
              className="btn btn-primary btn-icon text-white me-2"
            >
              <span>
                <img src={downloadSvg} alt="downloadSvg" />
                &nbsp;
              </span>
              {languageStatus
                ? dictionary["para_download_statements"]
                : "Download Statements"}
            </NavLink>
            <NavLink
              to="/balances"
              className="btn btn-primary btn-icon text-white me-2"
            >
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.47656 4.875H19.4766C19.6518 4.87491 19.8268 4.88603 20.0006 4.90828C19.9417 4.49473 19.7997 4.0974 19.583 3.74024C19.3664 3.38308 19.0797 3.07349 18.7402 2.83012C18.4007 2.58676 18.0154 2.41467 17.6076 2.32423C17.1998 2.2338 16.7779 2.22688 16.3673 2.3039L4.03125 4.41H4.01719C3.24285 4.55807 2.55425 4.99618 2.09203 5.63484C2.7884 5.13952 3.622 4.87389 4.47656 4.875V4.875ZM19.4766 6H4.47656C3.68118 6.00087 2.91862 6.31722 2.3562 6.87964C1.79378 7.44206 1.47743 8.20462 1.47656 9V18C1.47743 18.7954 1.79378 19.5579 2.3562 20.1204C2.91862 20.6828 3.68118 20.9991 4.47656 21H19.4766C20.2719 20.9991 21.0345 20.6828 21.5969 20.1204C22.1593 19.5579 22.4757 18.7954 22.4766 18V9C22.4757 8.20462 22.1593 7.44206 21.5969 6.87964C21.0345 6.31722 20.2719 6.00087 19.4766 6V6ZM17.25 15C16.9533 15 16.6633 14.912 16.4166 14.7472C16.17 14.5824 15.9777 14.3481 15.8642 14.074C15.7506 13.7999 15.7209 13.4983 15.7788 13.2074C15.8367 12.9164 15.9796 12.6491 16.1893 12.4393C16.3991 12.2296 16.6664 12.0867 16.9574 12.0288C17.2483 11.9709 17.5499 12.0006 17.824 12.1142C18.0981 12.2277 18.3324 12.42 18.4972 12.6666C18.662 12.9133 18.75 13.2033 18.75 13.5C18.75 13.8978 18.592 14.2794 18.3107 14.5607C18.0294 14.842 17.6478 15 17.25 15Z"
                    fill="#FFF"
                  />
                  <path
                    d="M1.5 12.1641V7.5C1.5 6.48422 2.0625 4.78125 4.01484 4.41234C5.67188 4.10156 7.3125 4.10156 7.3125 4.10156C7.3125 4.10156 8.39062 4.85156 7.5 4.85156C6.60938 4.85156 6.63281 6 7.5 6C8.36719 6 7.5 7.10156 7.5 7.10156L4.00781 11.0625L1.5 12.1641Z"
                    fill="#FFF"
                  />
                </svg>
                &nbsp;
              </span>
              {languageStatus
                ? dictionary["l_wallet_balance"]
                : "Account Balances"}
            </NavLink>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-body bg-primary">
                <h3 className="card-title mb-1 text-white">
                  {selectedAccount ? selectedAccount.accountName : "Select "}{" "}
                  Account
                </h3>
              </div>
              <div className="card-body">
                <div className="needs-validation">
                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label>Filter Accounts</label>
                      <Select
                        options={accounts}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="selectedAccount"
                        value={selectedAccount}
                        onChange={(option) => updateSelectedAccount(option)}
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["l_start_date"]
                          : "Start Date"}
                      </label>
                      <div>
                        <CustomDatePicker
                          getDate={getStartDate}
                          showYearDropdown
                          selectDropdownMode
                          withPortal
                          setMinDate={new Date("1/1/1998")}
                          setMaxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 mb-3">
                      <label>
                        {languageStatus ? dictionary["l_end_date"] : "End Date"}
                      </label>
                      <div>
                        <CustomDatePicker
                          getDate={getEndDate}
                          showYearDropdown
                          selectDropdownMode
                          withPortal
                          setMinDate={new Date("1/1/1998")}
                          setMaxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {statement.length < 1 ? (
                  <div className="text-center mt-4rem">
                    {loading ? (
                      <SphereLoader />
                    ) : selectedAccount && startDate && endDate ? (
                      "No Data Found"
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="table-responsive mt-3">
                    <DataTable columns={columns} data={statement} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
