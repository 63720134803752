import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Body from "./Body";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import {
  // getAllBeneficiaries,
  fetchArchivedBeneficiary,
} from "../../../../store/actions/users.action";
import {
  getCountries,
  getOriginSupportedCountries,
} from "../../../../store/actions/country.action";
import { fieldsValidator } from "../../../../utils/helpers/logicHelper";
import {
  getDynamicFields,
  toggleNav,
} from "../../../../store/actions/component.action";
import { fetchComplianceRequiredFields } from "../../../../store/actions/compliance.action";
import {
  dynamicFieldsTypes,
  Loading,
  mandatoryFieldsTypes,
} from "../../../../utils/helpers/constants";

const Beneficiaries = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const nation = location?.state?.countries;
  const senderCode =
    location?.state?.senderCode !== undefined
      ? location?.state?.senderCode
      : "";

  const [individualFields, setIndividualFields] = useState({});
  const [companyFields, setCompanyFields] = useState({});
  const { domainBrand, country, component, compliance, users } = useSelector(
    ({
      domainBrandReducer,
      countryReducer,
      componentReducer,
      complianceReducer,
      usersReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      country: countryReducer,
      component: componentReducer,
      compliance: complianceReducer,
      users: usersReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    users.loadingBeneficiaries === Loading.FETCHING ||
    country.loadingCountries === Loading.FETCHING ||
    country.loadingOriginCountries === Loading.FETCHING ||
    component.loading === Loading.FETCHING ||
    compliance.fetchingRequiredFields === Loading.FETCHING;

  useEffect(() => {
    dispatch(toggleNav(false));
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
    if (country.loadingOriginCountries !== Loading.SUCCESS)
      dispatch(getOriginSupportedCountries());
    if (users.loadingBeneficiaries !== Loading.SUCCESS)
      // dispatch(getAllBeneficiaries());
      dispatch(fetchArchivedBeneficiary("UN_ARCHIVED"));
  }, []);

  useEffect(() => {
    batch(() => {
      dispatch(getDynamicFields(dynamicFieldsTypes.COMPANY_CREATE_BENEFICIARY));
      dispatch(
        getDynamicFields(dynamicFieldsTypes.CUSTOMER_CREATE_BENEFICIARY)
      );
      dispatch(fetchComplianceRequiredFields(mandatoryFieldsTypes.RECEIVER));
      dispatch(
        fetchComplianceRequiredFields(mandatoryFieldsTypes.RECEIVER_COMPANY)
      );
    });
  }, []);

  useEffect(() => {
    const receiverFields = compliance[mandatoryFieldsTypes.RECEIVER] ?? [];
    const receiverCompanyFields =
      compliance[mandatoryFieldsTypes.RECEIVER_COMPANY] ?? [];
    if (receiverFields.length > 0 && receiverCompanyFields.length > 0) {
      setIndividualFields(
        fieldsValidator(compliance[mandatoryFieldsTypes.RECEIVER])
      );
      setCompanyFields(
        fieldsValidator(compliance[mandatoryFieldsTypes.RECEIVER_COMPANY])
      );
    }
  }, [compliance]);

  const mandatoryFields = { individualFields, companyFields };

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="beneficiary"
              active="beneficiary"
              domainBrand={domainBrand}
            />
            <Body
              countries={country.countries}
              phoneCodes={country.supportedPhoneCodes}
              currencies={country.currencies}
              mandatoryFields={mandatoryFields}
              component={component}
              // component={compliance}
              beneficiaries={users["beneficiaries"]}
              domainBrand={domainBrand}
              nation={nation}
              senderCode={senderCode}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default Beneficiaries;
