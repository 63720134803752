import { configs } from "./constants";
import cookieHelper from "./cookieHelper";
import encryptHelper from "./encryptHelper";

const tokenHelpers = {
  checkIfLoggedIn() {
    const TOKEN = cookieHelper.get(configs.KEY);
    return !!TOKEN;
  },

  saveToken(token) {
    cookieHelper.set(configs.KEY, token, configs.COOKIE_EXPIRY_PERIOD);
    localStorage.setItem(configs.KEY, token);
  },

  getToken() {
    return cookieHelper.get(configs.KEY);
  },

  userData: () => {
    const TOKEN = cookieHelper.get(configs.KEY);
    return encryptHelper.jwtDecode(TOKEN);
  },
};

export default tokenHelpers;
