import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Body({ domainBrand }) {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [addNewState, updateState] = useState(false);
  const addNewStatus = (event) => {
    event.preventDefault();
    updateState(!addNewState);
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {/* {languageStatus
                ? dictionary["WO_KNOW_YOUR_CUSTOMERS_829353076"]
                : "Know Your Customers (KYC) Rules"} */}
              Overdrafts
            </h1>
          </div>
          <div className="ms-auto pageheader-btn">
            <NavLink
              onClick={addNewStatus}
              to="/"
              className="btn btn-primary btn-icon text-white me-2"
            >
              <span>
                <i className="fa fa-folder-plus" />
              </span>{" "}
              &nbsp;
              {languageStatus ? dictionary["button_add_new"] : "Add New"}
            </NavLink>
          </div>
        </div>
        <div className="row row-sm">Body</div>
      </div>
    </div>
  );
}

export default Body;
