/* eslint-disable no-use-before-define */
import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";

import {
  exchangeRateUpdate,
  exchangeRateProvider,
  units,
} from "../../../../../utils/helpers/objectHelpers";

import {
  addExchangeRateFeed,
  calculatePercentage,
} from "../../../../../utils/services/rate.service";
import { fetchExchangeRate } from "../../../../../store/actions/rate.action";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-hot-toast";

import "../rates.css";

const AddExchange = ({ currency, userId, domainBrand }) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const menuPortalTarget = document.getElementById("root");
  const [loading, setLoading] = useState(false);
  const [exchangeProvider, setExchangeRateProvider] = useState("");
  const [buyRate, setBuyRate] = useState("");
  const [spreadVal, setSpreadVal] = useState("");

  const schema = yup
    .object({
      exchangeRateFeedSource: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(),
      exchangeRateFeedProvider: yup
        .object()
        .shape({
          label: yup.string().notRequired(),
          value: yup.string().notRequired(),
        })
        .notRequired(),
      currencyFrom: yup
        .object()
        .shape({
          iso3Code: yup.string().required(),
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(),
      currencyTo: yup
        .object()
        .shape({
          iso3Code: yup.string().required(),
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(),
      commonName: yup.string().required(),
      maxCacheDuration: yup.string().required(),
      cacheDurationUnit: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(),
      liveRate: yup.string().notRequired(),
      rate: yup.string().notRequired(),
      adjustmentPercentage: yup.string().notRequired(),
      amountMargin: yup.string().notRequired(),
    })
    .required();

  const getPercentageValue = async (liveRate, spreadRate) => {
    const payload = {
      principalAmount: parseInt(liveRate),
      percentageValue: parseInt(spreadRate),
      type: "POSITIVE",
    };

    try {
      const response = await calculatePercentage(payload);
      const responseData = response?.data;
      setValue("rate", responseData?.data?.totalAmount, {
        shouldValidate: true,
      });
      setValue("amountMargin", responseData?.data?.amountMargin, {
        shouldValidate: true,
      });
    } catch (error) {
      return error;
    }
  };

  const handleChange = async (buyRate, spreadVal) => {
    await getPercentageValue(buyRate, spreadVal);
  };

  useEffect(() => {
    handleChange(buyRate, spreadVal);
  }, [buyRate, spreadVal]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const payload = {
      exchangeRateFeedRequestDTOs: [
        {
          currencyTo: data?.currencyTo?.value,
          currencyFrom: data?.currencyFrom?.value,
          rate: data?.rate,
          automaticUpdate: true,
          adjustmentPercentage: spreadVal,
          adjustmentType: "POSITIVE",
          liveRate: buyRate,
          profitAbility: true,
          cacheDurationUnit: data?.cacheDurationUnit?.value,
          maxCacheDuration: data?.maxCacheDuration,
          apiProviderId: userId,
          exchangeRateFeedSource: data?.exchangeRateFeedSource?.value,
          commonName: data?.commonName,
        },
      ],
    };

    if (payload) {
      const res = await addExchangeRateFeed(payload);

      if (res.status === 200) {
        toast.success("exchange rate successfully added");
        // setLoading(false);
        reset({
          cacheDurationUnit: { label: "", value: "" },
          currencyTo: {
            iso3Code: "",
            label: "",
            value: "",
          },
          currencyFrom: {
            iso3Code: "",
            label: "",
            value: "",
          },
          exchangeRateFeedSource: { label: "", value: "" },
          exchangeRateFeedProvider: { label: "", value: "" },
          rate: "",
          liveRate: "",
          maxCacheDuration: "",
          commonName: "",
          adjustmentPercentage: "",
          amountMargin: "",
        });
        dispatch(fetchExchangeRate());
        setBuyRate("");
        setSpreadVal("");
        setLoading(false);
      } else {
        toast.error("something went wrong");
        setLoading(false);
      }
    }
  };

  return (
    <div className="card-body">
      <form
        className="needs-validation"
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
      >
        <div className="form-row">
          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Exchange Rate update source
              <span style={{ color: "red" }}> *</span>
            </label>
            <Controller
              name="exchangeRateFeedSource"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                const { value } = field;
                if (value?.value === "EXTERNAL_EXCHANGE_RATE_FEED") {
                  setExchangeRateProvider("EXTERNAL_EXCHANGE_RATE_FEED");
                }
                return (
                  <Select
                    {...field}
                    options={exchangeRateUpdate}
                    isClearable={false}
                    isSearchable={true}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    menuPortalTarget={menuPortalTarget}
                  />
                );
              }}
            />
          </div>

          {exchangeProvider === "EXTERNAL_EXCHANGE_RATE_FEED" && (
            <div className="col-md-6 col-lg-6 mb-3">
              <label>
                Exchange Rate Feed Provider
                <span style={{ color: "red" }}> *</span>
              </label>
              <Controller
                name="exchangeRateFeedProvider"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      options={exchangeRateProvider}
                      isClearable={false}
                      isSearchable={true}
                      maxMenuHeight={250}
                      menuPlacement="bottom"
                      menuPortalTarget={menuPortalTarget}
                    />
                  );
                }}
              />
            </div>
          )}

          <div className="col-md-6 col-lg-6 mb-3">
            <label>
              Currency From
              <span style={{ color: "red" }}> *</span>
            </label>
            <Controller
              name="currencyFrom"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    options={currency}
                    isClearable={false}
                    isSearchable={true}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    menuPortalTarget={menuPortalTarget}
                  />
                );
              }}
            />
          </div>

          <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
            <label>
              Currency To
              <span style={{ color: "red" }}> *</span>
            </label>
            <Controller
              name="currencyTo"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    options={currency}
                    isClearable={false}
                    isSearchable={true}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    menuPortalTarget={menuPortalTarget}
                  />
                );
              }}
            />
            <p
              className="text-danger fst-italic pt-1"
              style={{ fontSize: "12px" }}
            >
              {errors?.currencyTo?.type === "required" &&
                "This field is required"}
            </p>
          </div>

          <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
            <label>
              Name
              <span style={{ color: "red" }}> *</span>
            </label>
            <input
              type={"text"}
              className="form-control border px-3"
              name="commonName"
              {...register("commonName", {
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
            />

            <p
              className="text-danger fst-italic pt-1"
              style={{ fontSize: "12px" }}
            >
              {errors?.commonName?.type === "required" && "Name is required"}
            </p>
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <div className="row row-sm">
              <div className="col-md-6 d-flex flex-column">
                <label>
                  Expired Quote After
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type={"text"}
                  className="form-control border px-3"
                  id="maxCacheDuration"
                  name="maxCacheDuration"
                  {...register("maxCacheDuration", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />

                <p
                  className="text-danger fst-italic pt-1"
                  style={{ fontSize: "12px" }}
                >
                  {errors?.maxCacheDurationTo?.type === "required" &&
                    "This field is required"}
                </p>
              </div>

              <div className="col-md-6">
                <label>
                  Unit
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Controller
                  name="cacheDurationUnit"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        options={units}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6 mb-3">
            <div className="row row-sm">
              <div className="col-md-3">
                <label>
                  Buy Rate
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Controller
                  name="liveRate"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => value === "",
                  }}
                  render={({ field }) => {
                    return (
                      <input
                        {...field}
                        type={"text"}
                        className="form-control border px-3"
                        name="liveRate"
                        id="liveRate"
                        value={buyRate}
                        onChange={(e) => setBuyRate(e.target.value)}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-md-3">
                <label>
                  Spread(%)
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Controller
                  name="adjustmentPercentage"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => value === "",
                  }}
                  render={({ field }) => {
                    return (
                      <input
                        {...field}
                        type={"text"}
                        className="form-control border px-3"
                        name="adjustmentPercentage"
                        id="adjustmentPercentage"
                        value={spreadVal}
                        onChange={async (e) => {
                          setSpreadVal(e.target.value);
                        }}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-md-3">
                <label>
                  Sell Rate
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type={"text"}
                  className="form-control border px-3"
                  name="rate"
                  id="rate"
                  {...register("rate", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              </div>
              <div className="col-md-3">
                <label>
                  Total Profit
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type={"text"}
                  className="form-control border px-3 border-less"
                  id="amountMargin"
                  name="amountMargin"
                  {...register("amountMargin", {})}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6 mb-3 text-end">
            <button
              className="btn btn-primary margin-label width-100 fw-bold"
              type="submit"
            >
              {"Save"}
              {loading && (
                <>
                  &nbsp;
                  <i className="fa fa-spinner fa-spin" />
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddExchange;

// sell spot == live rate
// spot price == rate
//  profit == live rate - rate
// buy rate = live rate
// sell rate = rate

// for quote percentage api
/**
 * {
  "principalAmount": buy rate,
  "percentageValue": spread,
  "type": "POSITIVE"
}
supplying the above DTO returns 
"data": {
    "totalAmount": 22, --> sell rate
    "newPrincipalAmount": 2,
    "amountMargin": 18 --> total profit 
  }

  EXCHANGE RATE API ALL DONE 
 */
