import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Body from "./Body";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { toggleNav } from "../../../../store/actions/component.action";
import { Loading } from "../../../../utils/helpers/constants";
import { getCountries } from "../../../../store/actions/country.action";
import { getAllCustomersUsers } from "../../../../store/actions/users.action";

const Users = () => {
  const dispatch = useDispatch();
  const [loading] = useState(false);
  const [toggle, updateToggled] = useState(false);

  const { domainBrand, component, country, profile, user } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      countryReducer,
      profileReducer,
      usersReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      country: countryReducer,
      profile: profileReducer,
      user: usersReducer,
    })
  );

  const loadState =
    loading ||
    domainBrand.loading === Loading.FETCHING ||
    profile.loading === Loading.FETCHING ||
    country.loadingCountries === Loading.FETCHING ||
    user.loadingCustomers === Loading.FETCHING;

  const toggled = () => updateToggled(true);

  useEffect(() => {
    // dispatch(getCountries());
    dispatch(toggleNav(false));
  }, [toggle]);

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
    dispatch(toggleNav(false));
  }, []);

  useEffect(() => {
    if (user.loadingCustomers !== Loading.SUCCESS)
      dispatch(getAllCustomersUsers());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="general"
              active="users"
              domainBrand={domainBrand}
            />
            <Body
              toggled={toggled}
              domainBrand={domainBrand}
              users={user?.users}
              profile={profile}
              phoneCodes={country.supportedPhoneCodes}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default Users;
