import * as DomainBrand from "../constants/domain-brand.constants";
import {configs, Loading} from "../../utils/helpers/constants";
import cookieHelper from "../../utils/helpers/cookieHelper";

const initialState = {
  domainBrand: {},
  serviceControl: {},
  userConfigs: {},
  transTypes: [],
  loading: "",
  transTypesLoading: "",
};

const formatLanguage = payloadData => {
  return payloadData.map(item => ({...item, value: item.id, label: item.commonName}));
};

const formatTransTypes = dataObject => {
  let services, newTransTypes = [];
  newTransTypes.push("ADD_NEW_USER", "REQUEST_BANK_ACCOUNT", "TRANSFER_MODES");
  dataObject.forEach(element => newTransTypes.push(element["serviceType"]));
  if (!newTransTypes.includes("ACCOUNTPAYMENT")) {
    newTransTypes = newTransTypes.filter(e => e !== "TRANSFER_MODES");
    services = {transfer: false, wallet: true};
  }
  if (!newTransTypes.includes("MULTICURRENCY_WALLET_VIRTUAL_ACCOUNT_MANAGEMENT")) {
    newTransTypes = newTransTypes.filter(e => e !== "ADD_NEW_USER");
    newTransTypes = newTransTypes.filter(e => e !== "TRANSFER_MODES");
    newTransTypes = newTransTypes.filter(e => e !== "REQUEST_BANK_ACCOUNT");
    services = {transfer: true, wallet: false};
  }
  if (newTransTypes.includes("ACCOUNTPAYMENT") && newTransTypes.includes("MULTICURRENCY_WALLET_VIRTUAL_ACCOUNT_MANAGEMENT")) {
    newTransTypes = newTransTypes.filter(e => e !== "ACCOUNTPAYMENT");
    services = {transfer: true, wallet: true};
  }
  services.merchant = newTransTypes.indexOf("MERCHANTPAYMENTS") > -1;
  services.billPayment = newTransTypes.indexOf("BILLPAYMENT") > -1;
  services.airtimeTopup = newTransTypes.indexOf("MOBILEAIRTIMETOP_UP") > -1;
  cookieHelper.set(configs.SERVICE_CONTROL, services, configs.COOKIE_EXPIRY_PERIOD);
  return {newTransTypes, services};
};

const domainBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case DomainBrand.GET_DOMAIN_BRAND_IDENTITY_REQUEST:
      return {...state, loading: Loading.FETCHING};

    case DomainBrand.GET_DOMAIN_BRAND_IDENTITY_SUCCESS:
      const data = {...action.payload};
      const formattedLanguage = formatLanguage(action.payload.language);
      data.language = formattedLanguage;
      return {...state, domainBrand: data, loading: Loading.SUCCESS};

    case DomainBrand.GET_DOMAIN_BRAND_IDENTITY_FAILURE:
      return {...state, loading: Loading.ERROR};

    case DomainBrand.SET_LOGIN_CONFIGS:
      delete action.payload["profileDetailsResponseDTO"]
      return {...state, userConfigs: action.payload};

    case DomainBrand.GET_TRANSACTION_TYPES_REQUEST:
      return {...state, transTypesLoading: Loading.FETCHING};

    case DomainBrand.GET_TRANSACTION_TYPES_SUCCESS:
      const types = formatTransTypes(action.payload['transactionTypes']);
      return {
        ...state,
        transTypes: types.newTransTypes,
        serviceControl: types.services,
        transTypesLoading: Loading.SUCCESS
      };

    case DomainBrand.GET_TRANSACTION_TYPES_FAILURE:
      return {...state, transTypesLoading: Loading.ERROR};

    default:
      return state;
  }
};

export default domainBrandReducer;
