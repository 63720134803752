import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../../../../utils/helpers/constants";
import Header from "../../../../../../components/header/Header";
import HeaderMenu from "../../../../../../components/header/HeaderMenu";
import Footer from "../../../../../../components/footer/Footer";
import { getCountries } from "../../../../../../store/actions/country.action";

import Body from "./Body";

function SingleCustomerBeneficiary() {
  const location = useLocation();
  const dispatch = useDispatch();

  const component = location?.state?.component;
  const domainBrand = location?.state?.domainBrand;
  const code = location?.state?.code;
  const customerObject = location?.state?.customerObject;

  const { country } = useSelector(({ countryReducer }) => ({
    country: countryReducer,
  }));

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      <div className="page-main">
        <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
        <Header domainBrand={domainBrand} />
        <HeaderMenu menu="settings" active="rates" domainBrand={domainBrand} />
        <Body
          domainBrand={domainBrand}
          currency={country.currencies}
          countries={country.countries}
          code={code}
          customerObject={customerObject}
        />
      </div>
      <Footer domainBrand={domainBrand} />
    </div>
  );
}

export default SingleCustomerBeneficiary;

// place pass word beside save changes
// remove allocation sections
// remove black list and risk assessment
// remove allow customer to login

// NEEDS
// edit customer api for cashier
