import {useState} from 'react';
import Modal from 'react-bootstrap/Modal';


const NetworkModal = () => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="modal-body text-center p-4">
            <button aria-label="Close" onClick={handleClose} className="btn-close" data-bs-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
            <i className="fa fa-exclamation-circle fs-65 text-danger lh-1 mb-4 d-inline-block"/>
            <h4 className="text-danger mb-20">Seems like your internet may be down!</h4>
            <p className="mb-4 mx-4">No network connection. Check your connection..</p>
            <button aria-label="Close" onClick={handleClose} className="btn btn-danger pd-x-25">Continue Anyway</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NetworkModal;
