import { useState } from "react";
import { toast } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import downloadSvg from "../../../../assets/images/svgs/download.svg";
import {
  fileToBase64,
  formatStringCurrency,
} from "../../../../utils/helpers/logicHelper";
import { documentType } from "../../../../utils/helpers/objectHelpers";
import pdfGeneratorPayout from "../../../../utils/helpers/pdfGeneratorPayout";
import { confirmPayout } from "../../../../utils/services/transfer.service";

const Body = ({
  domainBrand,
  profile,
  payoutData,
  secretQuestionState,
  securityOTPState,
}) => {
  const menuPortalTarget = document.getElementById("root");
  const [loading, setLoading] = useState(false);
  const [successPayout, setSuccessPayout] = useState(false);
  const [activeMethod, setActiveMethod] = useState("TRANSACTION");
  const [securityAnswer, setSecurityAnswer] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [fileType, setFileType] = useState({});
  const [fileData, setFileData] = useState("");

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const docTypes = documentType(languageStatus, dictionary);

  const onChangeFile = async (event) => {
    const allowedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "pdf",
      "doc",
      "docx",
      "JPG",
      "JPEG",
      "PNG",
      "PDF",
      "DOC",
      "DOCX",
    ];
    const sizeLimit = 2097152;
    const selectedFileSize = event.target.files[0].size || "";
    const selectedFileName = event.target.files[0].name || null;
    const fileExtension = selectedFileName.split(".").pop();
    if (typeof selectedFileSize === "undefined") return false;
    else if (
      event.target.files[0] &&
      !allowedExtensions.includes(fileExtension)
    )
      toast.error("File must be an image or document");
    else if (selectedFileSize > sizeLimit) toast.error("File size too large");
    else await setFileData(event.target.files[0]);
  };

  const switchMethod = (event, type) => {
    event.preventDefault();
    setActiveMethod(type);
  };

  const downloadPDF = (event) => {
    event.preventDefault();
    pdfGeneratorPayout(payoutData, domainBrand, profile).catch((err) =>
      console.log(err)
    );
  };

  const payoutTransaction = async () => {
    setLoading(true);
    const file_base64 = await fileToBase64(fileData);

    const payload = {
      pcn: payoutData.pcn,
      paymentReferenceCode: payoutData["serverCorrelationId"],
      file_base64,
    };

    if (
      secretQuestionState ||
      payoutData["secretQuestion"] ||
      payoutData["requiredQuestionAndAnswer"]
    ) {
      payload["security_question_answer"] = securityAnswer;
    }

    if (securityOTPState || payoutData["requiredOtpOnPayout"]) {
      payload["security_otp"] = otp;
    }
    if ("payCentreId" in payoutData) {
      payload["originatingImtoId"] = payoutData["payCentreId"];
    }
    if ("payCentreId" in payoutData) {
      payload["paymentReferenceCode"] = payoutData["pcn"];
    }
    if ("password" in payoutData) {
      payload["password"] = password;
    }
    // if ("file_base64" in payoutData) {
    //   payload["file_base64"] = "file_base64";
    // }
    const { data: responseData } = await confirmPayout(payload, password);
    console.log(responseData, "po");
    if (responseData.status === "ERROR") {
      toast.error(responseData.message);
    } else {
      setSuccessPayout(true);
      toast.success(
        `Payout Successful, please pay out ${
          payoutData["receiverCurrency"]
        }${formatStringCurrency(payoutData["receivingAmount"])}`,
        { duration: 10000 }
      );
    }
    setLoading(false);
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus ? dictionary["l_payout_transaction"] : "Payouts"}
            </h1>
          </div>
          {successPayout ? (
            <div className="ms-auto pageheader-btn">
              <NavLink
                onClick={downloadPDF}
                to=""
                className="btn btn-primary btn-icon text-white me-2"
              >
                <span>
                  <img src={downloadSvg} alt="downloadSvg" />
                  &nbsp;
                </span>
                {languageStatus
                  ? dictionary["para_download_receipt"]
                  : "Download Receipt"}
              </NavLink>
              <NavLink
                to="/transfer-history"
                className="btn btn-primary btn-icon text-white me-2"
              >
                <span>
                  <i className="fa fa-history" />
                </span>{" "}
                Transfer History
              </NavLink>
            </div>
          ) : (
            <div className="ms-auto pageheader-btn" />
          )}
        </div>
        <div className="row">
          <div
            className={`${
              successPayout ? "col-lg-12 col-md-12" : "col-lg-7 col-md-12"
            }`}
          >
            <div className="card productdesc overflow-hidden height-100">
              <div className="card-body">
                <div className="panel panel-primary">
                  <div className="tab-menu-heading mb-2">
                    <div className="tabs-menu">
                      <ul className="nav panel-tabs">
                        <li>
                          <NavLink
                            onClick={(event) =>
                              switchMethod(event, "TRANSACTION")
                            }
                            to=""
                            exact
                            className={`tab-pane ${
                              activeMethod === "TRANSACTION"
                                ? "active me-2"
                                : "me-2"
                            }`}
                          >
                            {languageStatus
                              ? dictionary["l_transaction_details"]
                              : "Transaction"}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {Object.entries(payoutData).length === 0 ? (
                    ""
                  ) : (
                    <div className="panel-body tabs-menu-body">
                      <div className="tab-content">
                        <div
                          className={`tab-pane ${
                            activeMethod === "TRANSACTION" ? "active" : ""
                          }`}
                        >
                          <ul className="list-unstyled mb-0">
                            <li className="row">
                              <div className="col-sm-4 text-muted">
                                {languageStatus
                                  ? dictionary["para_pcn"]
                                  : "PCN"}
                              </div>
                              <div className="col-sm-8">
                                {payoutData["pcn"]}
                              </div>
                            </li>
                            <li className="row">
                              <div className="col-sm-4 text-muted">
                                {languageStatus
                                  ? dictionary["para_delivery_method"]
                                  : "Method"}
                              </div>
                              <div className="col-sm-8">
                                {payoutData["transactionType"]}
                              </div>
                            </li>
                            <li className="row">
                              <div className="col-sm-4 text-muted">
                                {languageStatus
                                  ? dictionary["l_transaction_status"]
                                  : "Status"}
                              </div>
                              <div className="col-sm-8">
                                {payoutData["transactionStatus"]}
                              </div>
                            </li>
                            <li className="row">
                              <div className="col-sm-4 text-muted">
                                {languageStatus
                                  ? dictionary["l_sending_amount"]
                                  : "Send Amount"}
                              </div>
                              <div className="col-sm-8">
                                {payoutData["sendingCurrency"]}{" "}
                                {payoutData["sendingAmount"]}
                              </div>
                            </li>
                            <li className="row">
                              <div className="col-sm-4 text-muted">
                                {languageStatus
                                  ? dictionary["para_receiving_amount"]
                                  : "Receiving Amount"}
                              </div>
                              <div className="col-sm-8">
                                {payoutData["receiverCurrency"]}{" "}
                                {formatStringCurrency(
                                  payoutData["receivingAmount"]
                                )}
                              </div>
                            </li>
                            <li className="row">
                              <div className="col-sm-4 text-muted">
                                {languageStatus
                                  ? dictionary["l_receiver_name"]
                                  : "Name"}
                              </div>
                              <div className="col-sm-8">
                                {payoutData["receiverFirstName"]}{" "}
                                {payoutData["receiverMiddleName"]}{" "}
                                {payoutData["receiverLastName"]}
                                {successPayout ? (
                                  ""
                                ) : (
                                  <>
                                    {/* <button
                                      className="btn btn-sm btn-danger mx-3"
                                      type="button"
                                    >
                                      {languageStatus
                                        ? dictionary[
                                            "WO_BLACKLIST_REQUES_-1508842516"
                                          ]
                                        : ""}
                                    </button> */}
                                  </>
                                )}
                              </div>
                            </li>
                            <li className="row">
                              <div className="col-sm-4 text-muted">
                                {languageStatus
                                  ? dictionary["l_sender_name"]
                                  : "Name"}
                              </div>
                              <div className="col-sm-8">
                                {payoutData["senderFirstName"]}{" "}
                                {payoutData["senderLastName"]}
                                {successPayout ? (
                                  ""
                                ) : (
                                  <>
                                    {/* <button
                                      className="btn btn-sm btn-danger mx-3"
                                      type="button"
                                    >
                                      {languageStatus
                                        ? dictionary[
                                            "WO_BLACKLIST_REQUES_-1508842516"
                                          ]
                                        : ""}
                                    </button> */}
                                  </>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {successPayout ? (
            ""
          ) : (
            <div className="col-lg-5 col-md-12">
              <div className="card overflow-hidden height-100">
                <div className="card-header bg-primary text-white">
                  <div className="card-title">
                    {languageStatus
                      ? dictionary["l_beneficiary_authorisation"]
                      : "Beneficiary Authorization"}
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    {secretQuestionState ||
                    payoutData["requiredQuestionAndAnswer"] ? (
                      <div className="col-md-12 mb-3">
                        <label>{payoutData["secretQuestion"]}</label>
                        <input
                          className="form-control"
                          type="text"
                          value={securityAnswer}
                          onChange={(event) =>
                            setSecurityAnswer(event.target.value)
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {securityOTPState || payoutData["requiredOtpOnPayout"] ? (
                      <div className="col-md-12 mb-3">
                        <label>
                          {languageStatus
                            ? dictionary["WO_ENTER_OT_430460335"]
                            : "OTP"}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={otp}
                          onChange={(event) => setOTP(event.target.value)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-md-12 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["param_password"]
                          : "Password"}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        value={password}
                        placeholder={
                          languageStatus
                            ? dictionary["msg_enter_password"]
                            : "Enter Password"
                        }
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["WO_DOCUMENT_TYPE_1023917504"]
                          : "Document Type"}
                      </label>
                      <Select
                        options={docTypes}
                        isClearable={false}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="country"
                        value={fileType}
                        onChange={(option) => setFileType(option)}
                      />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["l_file_upload"]
                          : "File Upload"}
                      </label>
                      <div className="input-group">
                        <input
                          onChange={onChangeFile}
                          type="file"
                          className="form-control"
                          placeholder="Choose.."
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="float-end">
                    <button
                      onClick={payoutTransaction}
                      className="btn btn-primary"
                      type="button"
                    >
                      {languageStatus ? dictionary["button_payout"] : ""}
                      {loading ? (
                        <>
                          &nbsp;
                          <i className="fa fa-spin fa-spinner" />{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Body;
