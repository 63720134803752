import { useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { transactionTypesMap } from "../../utils/helpers/mappers";

const DashboardMenu = ({
  domainBrand,
  transTypes,
  profile,
  showMoreState,
  canShowMore,
}) => {
  const history = useHistory();
  const [width, setWidth] = useState(0);
  const [cards, updateCards] = useState(0);
  const [cardsRow, updateCardsRow] = useState(2);
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const userType =
    profile.userData["customerType"] === null
      ? profile.userData["userType"]
      : profile.userData["customerType"];
  const visibleCardsCount = transTypes.filter((forEach) =>
    transactionTypesMap[forEach].visibleTo.includes(userType)
  ).length;

  const routeToPage = (event, type) =>
    history.push(`${type.to}`, { text: type.text });

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (width < 640) updateCardsRow(4);
    else if (width <= 1024) updateCardsRow(4);
    else if (width <= 1280) updateCardsRow(4);
    else updateCardsRow(6);
  }, [width]);

  useEffect(() => {
    if (visibleCardsCount > cardsRow) canShowMore(true);
    else canShowMore(false);
  }, [visibleCardsCount, cardsRow]);

  useEffect(() => {
    if (showMoreState) updateCards(visibleCardsCount + 1);
    else updateCards(cardsRow + 1);
  }, [showMoreState, cardsRow]);

  return (
    <div className="row w-100 card-dashboard-center">
      {transTypes.slice(0, cards).map((transType) => {
        let type = transactionTypesMap[transType];
        if (typeof type === "undefined")
          type = {
            text: transType,
            to: "/",
            visibleTo: ["ADMIN"],
          };
        const userType =
          profile.userData["customerType"] === null
            ? profile.userData["userType"]
            : profile.userData["customerType"];
        const show = type?.visibleTo.includes(userType);
        return (
          type.to &&
          show && (
            <div
              key={type.id}
              className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-6"
            >
              <div
                onClick={(event) => routeToPage(event, type)}
                className="card mx-2 pointer shadow"
              >
                <div className="card-body text-center">
                  {/*<i className={`${type.icon} text-primary fa-2x`}/>*/}
                  <img src={type.iconSvg} alt="arrowRight" />
                  <h6 className="mt-2 fs-12 mb-2">
                    {languageStatus ? dictionary[type.lingualText] : type.text}
                  </h6>
                </div>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default DashboardMenu;
