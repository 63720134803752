import { useState } from "react";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import Select from "react-select";

import { Loading } from "../../../../../../utils/helpers/constants";
import { lockTransaction } from "../../../../../../utils/services/transfer.service";

const Body = ({ domainBrand, payCentres }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [pcn, setPcn] = useState("");
  const [payCentre, setPayCentre] = useState("");
  const [lockedTransaction, setLockedTransaction] = useState({});
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const menuPortalTarget = document.getElementById("root");
  const payCentresDropdown = [
    { label: domainBrand.domainBrand.companyName, value: "DEFAULT" },
  ].concat(payCentres);
  const continueToPayout = () => {
    return history.push({ pathname: "/payout", state: lockedTransaction });
  };

  const lockTransactionForPayout = async () => {
    setLoading(true);
    const query =
      payCentre.value === "DEFAULT"
        ? ""
        : `?originating-imto-id=${payCentre.value}`;
    const { data: responseData } = await lockTransaction(pcn, query);
    if (responseData.status !== Loading.SUCCESS)
      toast.error(responseData.message);
    else {
      let payoutData = {};
      if (payCentre.value !== "DEFAULT")
        payoutData.payCentreId = payCentre.value;
      payoutData = { ...payoutData, ...responseData.data, ...{ pcn } };
      setLockedTransaction(payoutData);
    }
    setLoading(false);
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus ? dictionary["title_payout"] : "Payouts"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">
                  {languageStatus
                    ? dictionary["para_transaction_pcn_pin"]
                    : "Find PCN"}
                </h3>
              </div>
              <div className="card-body">
                <form className="needs-validation">
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["WO_PLEASE_SELECT_PARTNE_-1226449852"]
                          : "Partner"}
                      </label>
                      <Select
                        options={payCentresDropdown}
                        isClearable={false}
                        isSearchable={true}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        value={payCentre}
                        onChange={(option) => setPayCentre(option)}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["WO_VERIFY_PAYMENT_COLLE_-61899059"]
                          : "PCN"}{" "}
                        {"(PCN)"}
                      </label>
                      <input
                        type="text"
                        value={pcn}
                        className="form-control"
                        onChange={(event) => setPcn(event.target.value)}
                        required
                        placeholder={`${
                          languageStatus ? dictionary["para_pcn"] : "PCN"
                        }..`}
                      />
                    </div>
                  </div>
                  <button
                    onClick={lockTransactionForPayout}
                    className="btn btn-primary mt-5"
                    type="button"
                  >
                    Lock Transaction{" "}
                    {loading ? <i className="fa fa-spin fa-spinner" /> : ""}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <div className="card-title">
                  {languageStatus
                    ? dictionary["para_transaction_information"]
                    : "Transaction Information"}
                </div>
              </div>
              <div className="card-body">
                {Object.entries(lockedTransaction).length === 0 ? (
                  <p className="text-muted">
                    {languageStatus
                      ? dictionary["para_enter_pcn"]
                      : "Search PCN"}
                  </p>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <p className="text-muted">
                          {languageStatus
                            ? dictionary["WO_RECEIVER_NAM_-2123568047"]
                            : "Receiver Name"}
                        </p>
                        <p>{`${lockedTransaction["receiverFirstName"]} ${lockedTransaction["receiverMiddleName"]} ${lockedTransaction["receiverLastName"]}`}</p>
                      </div>
                      <div className="col-6">
                        <p className="text-muted">
                          {languageStatus ? dictionary["para_pcn"] : "PCN"}
                        </p>
                        <p>{lockedTransaction["objectReference"]}</p>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-6">
                        <p className="text-muted">
                          {languageStatus
                            ? dictionary["para_delivery_method"]
                            : "Method"}
                        </p>
                        <p>{lockedTransaction["transactionType"]}</p>
                      </div>
                      <div className="col-6">
                        <p className="text-muted">
                          {languageStatus ? dictionary["l_status"] : "Status"}
                        </p>
                        <p>{lockedTransaction["transactionStatus"]}</p>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-12">
                        <button
                          onClick={continueToPayout}
                          className="btn btn-primary mt-5"
                          style={{ minWidth: "100%" }}
                          type="button"
                        >
                          {languageStatus
                            ? dictionary["l_confirm_transaction"]
                            : "Confirm"}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
