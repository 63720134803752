import * as TreasuryConstants from "../constants/treasury.constants";
import { Loading } from "../../utils/helpers/constants";

const initialState = {
  treasuryAccounts: [],
  loadingTreasuries: "",
};

const treasuryReducer = (state = initialState, action) => {
  switch (action.type) {
    case TreasuryConstants.GET_TREASURY_ACCOUNT_REQUEST:
      return { ...state, loadingTreasuries: Loading.FETCHING };

    case TreasuryConstants.GET_TREASURY_ACCOUNT_SUCCESS:
      return {
        ...state,
        treasuryAccounts: action.payload,
        loadingTreasuries: Loading.SUCCESS,
      };
    case TreasuryConstants.GET_TREASURY_ACCOUNT_FAILURE:
      return { ...state, loadingTreasuries: Loading.ERROR };

    default:
      return state;
  }
};

export default treasuryReducer;
