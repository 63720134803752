import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import { toggleNav } from "../../../../store/actions/component.action";
import { getAllWallets } from "../../../../store/actions/accounts.action";
import { Loading } from "../../../../utils/helpers/constants";

const AccountStatement = () => {
  const dispatch = useDispatch(),
    location = useLocation();
  const locationAccount =
    location.state === null || typeof location.state.data === "undefined"
      ? {}
      : location.state.data;
  const [initialAccount, updateInitialAccount] = useState(locationAccount);

  const { accounts, domainBrand, component, profile } = useSelector(
    ({
      accountsReducer,
      domainBrandReducer,
      componentReducer,
      profileReducer,
    }) => ({
      accounts: accountsReducer,
      domainBrand: domainBrandReducer,
      component: componentReducer,
      profile: profileReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    accounts.loading === Loading.FETCHING;

  useEffect(() => {
    dispatch(toggleNav(false));
    dispatch(getAllWallets());
  }, []);

  useEffect(() => {
    if (
      Object.entries(locationAccount).length === 0 &&
      accounts.accountList.length > 0
    )
      updateInitialAccount(accounts.accountList[0]);
  }, [accounts.accountList]);

  const pageProps = { domainBrand, profile };
  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="accounts"
              active="statement"
              domainBrand={domainBrand}
            />
            <Body
              pageProps={pageProps}
              accountData={initialAccount}
              accounts={accounts.accountList}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default AccountStatement;
