/* eslint-disable no-unused-vars */
import moment from "moment";
import { transferStatus } from "./constants";
import { formatStringCurrency } from "./logicHelper";
//  const date = new Date(row.original["updatedDate"]);
// const formattedDate = moment(date).format("DD/MM/YYYY");

export const bankFieldsConfig = (status, dictionary, type) => {
  if (type === "ORDINARY") {
    return [
      {
        Header: () => {
          return (
            <span className="fw-bold">
              {status
                ? dictionary["WO_TEMPLATE_NAM_2095784414"]
                : "Template Name"}
            </span>
          );
        },
        columnId: 0,
        accessor: "templateName",
      },
      {
        Header: () => {
          return (
            <span className="fw-bold">
              {status ? dictionary["para_updated_details"] : "Updated Details"}
            </span>
          );
        },
        columnId: 1,
        accessor: "updatedDate",
        Cell: ({ row }) => {
          const date = new Date(row.original["updatedDate"]);
          const formattedDate = moment(date).format("DD/MM/YYYY");
          return <span className="pointer text-right">{formattedDate}</span>;
        },
      },
    ];
  } else {
    return [
      {
        Header: () => {
          return (
            <span className="fw-bold">
              {status
                ? dictionary["WO_TEMPLATE_NAM_2095784414"]
                : "Template Name"}
            </span>
          );
        },
        columnId: 0,
        accessor: "templateName",
      },
      {
        Header: () => {
          return (
            <span className="fw-bold">
              {status ? dictionary["para_updated_details"] : "Updated Details"}
            </span>
          );
        },
        columnId: 1,
        accessor: "updatedDate",
      },
      {
        Header: () => {
          return (
            <span className="fw-bold">
              {status ? dictionary["WO_COUNTRY_1435082597"] : "Country"}
            </span>
          );
        },
        columnId: 2,
        accessor: "country",
      },
      {
        Header: () => {
          return (
            <span className="fw-bold">
              {status ? dictionary["WO_CURRENCY_1471979772"] : "Currency"}
            </span>
          );
        },
        columnId: 3,
        accessor: "currency",
      },
    ];
  }
};

export const kycTableConfig = (status, dictionary) => {
  return [
    // {
    //   Header: () => {
    //     return (
    //       <span className="fw-bold">
    //         {status ? dictionary["para_country"] : "Country"}
    //       </span>
    //     );
    //   },
    //   accessor: "country",
    //   sortType: "basic",
    // },
    // {
    //   Header: () => {
    //     return (
    //       <span className="fw-bold">
    //         {status
    //           ? `${dictionary["para_customer"]} / ${dictionary["para_paycenter"]}`
    //           : "Customer/Paycenter"}
    //       </span>
    //     );
    //   },
    //   accessor: "customer",
    //   sortType: "basic",
    //   Cell: ({ row }) => {
    //     return (
    //       <span className="pointer text-right">
    //         {row.original.customer !== null && row.original.customer.firstName}{" "}
    //         {row.original.customer !== null && row.original.customer.lastName}
    //       </span>
    //     );
    //   },
    //   columnId: 1,
    // },
    {
      Header: "Rule to Apply",
      accessor: "ruleType",
      sortType: "basic",
      columnId: 2,
      Cell: ({ row }) => {
        return (
          <span className="pointer text-right">
            {row.original.ruleType === "AMOUNT_THRESHOLD_LIMIT" &&
              "Apply when customer ract to threshold amount"}
            {row.original.ruleType === "ON_SIGN_UP" &&
              "Apply when customer is signing up for new account"}
          </span>
        );
      },
    },
    {
      Header: "Restriction Type",
      accessor: "restriction",
      sortType: "basic",
      columnId: 3,
      Cell: ({ row }) => {
        return (
          <span className="pointer text-right">
            {row.original.restriction === "WARN" &&
              "Allow customer to login to system but flag all transactions for compliance officer"}
            {row.original.restriction === "BLOCK" &&
              "Do not allow customer to login to system until this condition is satisfied"}
          </span>
        );
      },
    },
    {
      Header: "Scope of Rule",
      accessor: "actionType",
      sortType: "basic",
      columnId: 4,
      Cell: ({ row }) => {
        return (
          <span className="pointer text-right">
            {row.original.actionType === "VERIFY_ACCOUNT_VIA_EKYC_FACE_PHOTO" &&
              "EKYC | Take live face photo"}
            {row.original.actionType ===
              "VERIFY_ACCOUNT_VIA_IDENTIFICATION_DOCUMENT" &&
              "Request customer document(Passport, Address, ID)"}
            {row.original.actionType === "VERIFY_ACCOUNT_VIA_PHONE_NUMBER" &&
              "Request customer phone number"}
            {row.original.actionType === "VERIFY_ACCOUNT_VIA_EMAIL" &&
              "Request customer email"}
            {row.original.actionType === "VERIFY_ACCOUNT_VIA_EKYC_FACE_VIDEO" &&
              "EKYC | Take live video"}
          </span>
        );
      },
    },
    {
      Header: "Document",
      accessor: "idType",
      sortType: "basic",
      columnId: 5,
      Cell: ({ row }) => {
        return (
          <span className="pointer text-right">
            {row.original.idType === "PASSPORT" && "Passport"}
            {row.original.idType === "NATIONAL_ID_CARD" && "National Id Card"}
            {row.original.idType === "EKYC_FACE_PHOTO" &&
              "Customer Facial Image"}
          </span>
        );
      },
    },
  ];
};

export const employeeTableConfig = [
  {
    Header: "Last Login",
    accessor: "lastLogin",
    sortType: "basic",
    columnId: 1,
  },
  {
    Header: "Staff ID",
    accessor: "staffActiveDirectoryId",
    sortType: "basic",
    columnId: 2,
  },
  {
    Header: "Full Name",
    accessor: "name",
    sortType: "basic",
    Cell: ({ row }) => {
      return (
        <span className="pointer">{`${row.original.firstName} ${row.original.lastName}`}</span>
      );
    },
    columnId: 3,
  },
  {
    Header: "Address Line 1",
    accessor: "address_line",
    sortType: "basic",
    Cell: ({ row }) => {
      return (
        <span className="pointer">
          {row?.original?.addressEntityDTO?.address1}
        </span>
      );
    },
    columnId: 4,
  },
  {
    Header: "City",
    accessor: "city",
    sortType: "basic",
    Cell: ({ row }) => {
      return (
        <span className="pointer">{row?.original?.addressEntityDTO?.city}</span>
      );
    },
    columnId: 5,
  },
  {
    Header: "Phone",
    accessor: "phone",
    sortType: "basic",
    columnId: 6,
  },
  {
    Header: "Status",
    accessor: "status",
    sortType: "basic",
    columnId: 7,
  },
  {
    Header: "User Role",
    accessor: "userType",
    sortType: "basic",
    Cell: ({ row }) => {
      return (
        <span className="pointer text-center">{row?.original?.userType}</span>
      );
    },
    columnId: 8,
  },
];

export const agentTableConfig = [
  {
    Header: "Name",
    accessor: "name",
    sortType: "basic",
    columnId: 1,
    Cell: ({ row }) => {
      return (
        <span className="pointer">{`${row.original.firstName} ${row.original.lastName}`}</span>
      );
    },
  },
  {
    Header: "Super Agent",
    accessor: "super_agent",
    sortType: "basic",
    columnId: 2,
  },
  {
    Header: "Agent Code",
    accessor: "agent_code",
    sortType: "basic",
    columnId: 3,
  },
  {
    Header: "Type",
    accessor: "type",
    sortType: "basic",
    columnId: 4,
  },
  {
    Header: "Country",
    accessor: "country",
    sortType: "basic",
    columnId: 5,
  },
  {
    Header: "Status",
    accessor: "status",
    sortType: "basic",
    columnId: 6,
  },
];

export const exchangeRateTableConfig = [
  {
    Header: "Template Name",
    accessor: "commonName",
    sortType: "basic",
    columnId: 1,
  },
  {
    Header: "Currency Pair",
    accessor: "currencyPair",
    sortType: "basic",
    columnId: 2,
    Cell: ({ row }) => {
      return (
        <span className="pointer">{`${row?.original?.currencyFrom}/${row?.original?.currencyTo}`}</span>
      );
    },
  },
  {
    Header: "Updated Details",
    accessor: "updatedDate",
    sortType: "basic",
    columnId: 3,
  },
  {
    Header: "Sell Spot Prices/Spot Prices",
    accessor: "spot_prices",
    sortType: "basic",
    columnId: 4,
    Cell: ({ row }) => {
      return (
        <span className="pointer">{`${row?.original?.rate}/${row?.original?.liveRate}`}</span>
      );
    },
  },
  {
    Header: "Profit",
    accessor: "profitMargin",
    sortType: "basic",
    columnId: 5,
  },
  {
    Header: "Exchange Rate Feed Provider",
    accessor: "exchangeRateFeedSource",
    sortType: "basic",
    columnId: 6,
  },
];

export const corridorTableConfig = [
  {
    Header: "Originating Country/Receiving Country",
    accessor: "Transaction Originating Country/Receiving Country",
    sortType: "basic",
    columnId: 1,
    Cell: ({ row }) => {
      return (
        <>
          <span className="pointer">
            {row.original.tradeOriginatingCountry}
          </span>
          <span className="pointer ps-1 pe-1">/</span>
          <span className="pointer">{row.original.destinationCountry}</span>
        </>
      );
    },
  },
  {
    Header: "Currency Pair",
    accessor: "curencypair",
    sortType: "basic",
    columnId: 2,
    Cell: ({ row }) => {
      return (
        <>
          {row.original.currencyFrom && row.original.currencyTo ? (
            <>
              <span className="pointer">{row.original.currencyFrom}</span>
              <span className="pointer ps-1 pe-1">/</span>
              <span className="pointer">{row.original.currencyTo}</span>
            </>
          ) : (
            ""
          )}
        </>
      );
    },
  },
  {
    Header: "Delivery Method",
    accessor: "deliveryMethod",
    sortType: "basic",
    columnId: 3,
    Cell: ({ row }) => {
      return (
        <>
          <span className="pointer">{row.original.deliveryMethod}</span>
        </>
      );
    },
  },
  {
    Header: "Updated Details",
    accessor: "updated_details",
    sortType: "basic",
    columnId: 4,
    Cell: ({ row }) => {
      const date = new Date(row.original["updatedDate"]);
      const formattedDate = moment(date).format("DD/MM/YYYY");
      return (
        <>
          <span className="pointer">{formattedDate}</span>
        </>
      );
    },
  },
  {
    Header: "Sell Spot Prices/Spot Prices",
    accessor: "sell_spot",
    sortType: "basic",
    columnId: 5,
    Cell: ({ row }) => {
      return (
        <>
          <span className="pointer">{row.original.liveRate}</span>
          <span className="pointer ps-1 pe-1">/</span>
          <span className="pointer">{row.original.rate}</span>
        </>
      );
    },
  },
  {
    Header: "Profit",
    accessor: "profit",
    sortType: "basic",
    columnId: 6,
    Cell: ({ row }) => {
      return (
        <>
          <span className="pointer">{row.original.profitMargin}</span>
        </>
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    sortType: "basic",
    columnId: 7,
  },
  {
    Header: "Approved",
    accessor: "approved",
    sortType: "basic",
    columnId: 8,
    Cell: ({ row }) => {
      return (
        <>
          <span
            className={"pointer fw-bold"}
            style={{
              color: `${row.original.approved === true ? "#3d9830" : "#"}`,
            }}
          >
            {row.original.approved === true ? "yes" : "none"}
          </span>
        </>
      );
    },
  },
];

export const accountProductCreationConfig = [
  // {
  //   Header: "User Name",
  //   accessor: "userName",
  //   sortType: "basic",
  //   columnId: 0,
  // },
  {
    Header: "Product Name",
    accessor: "productName",
    sortType: "basic",
    columnId: 1,
  },
  {
    Header: "Currency",
    accessor: "currencyCode",
    sortType: "basic",
    columnId: 2,
  },
  {
    Header: "Country",
    accessor: "country",
    sortType: "basic",
    columnId: 3,
  },
  {
    Header: "Created Date",
    accessor: "createdDate",
    columnId: 4,
    sortType: "basic",
    Cell: ({ row }) => {
      const date = new Date(row.original["createdDate"]);
      const formattedDate = moment(date).format("DD/MM/YYYY");
      return <span className="pointer text-right">{formattedDate}</span>;
    },
  },
  // {
  //   Header: "Updated At",
  //   accessor: "",
  //   columnId: 5,
  //   sortType: "basic",
  //   Cell: ({ row }) => {
  //     const date = new Date(row.original["createdDate"]);
  //     const formattedDate = moment(date).format("DD/MM/YYYY");
  //     return <span className="pointer text-right">{formattedDate}</span>;
  //   },
  // },
];

export const remittanceTableConfig = [
  { Header: "Ref Code", accessor: "ref_code", columnId: 1, sortType: "basic" },
  {
    Header: "Fee Category",
    accessor: "fee_category",
    columnId: 2,
    sortType: "basic",
  },
  {
    Header: "PayCenter",
    accessor: "pay_center",
    columnId: 3,
    sortType: "basic",
  },
  {
    Header: "Transaction Originating Country",
    accessor: "trans_country",
    columnId: 4,
    sortType: "basic",
  },
  {
    Header: "Destination Country",
    accessor: "destination_country",
    columnId: 5,
    sortType: "basic",
  },
  {
    Header: "Currency Pair ",
    accessor: "currency_pair",
    columnId: 6,
    sortType: "basic",
  },
  {
    Header: "Range",
    accessor: "range",
    columnId: 7,
    sortType: "basic",
  },
  {
    Header: "Modified",
    accessor: "modified",
    columnId: 8,
    sortType: "basic",
  },
  {
    Header: "Delivery Method",
    accessor: "delivery_method",
    columnId: 9,
    sortType: "basic",
  },
  {
    Header: "Rate Type",
    accessor: "rate_type",
    columnId: 10,
    sortType: "basic",
  },
  {
    Header: "Fee",
    accessor: "fee",
    columnId: 11,
    sortType: "basic",
  },
  {
    Header: "Inclusive",
    accessor: "inclusive",
    columnId: 12,
    sortType: "basic",
  },
  {
    Header: "Approved",
    accessor: "approved",
    columnId: 13,
    sortType: "basic",
  },
];

export const userTableConfig = [
  {
    Header: "Full name",
    accessor: "name",
    columnId: 0,
    Cell: ({ row }) => {
      return (
        <span className="pointer">{`${row.original.firstName} ${row.original.lastName}`}</span>
      );
    },
    sortType: "basic",
  },
  { Header: "Email", accessor: "email", columnId: 1, sortType: "basic" },
  { Header: "Phone", accessor: "phone", columnId: 2, sortType: "basic" },
  // { Header: "Country", accessor: "country", columnId: 3, sortType: "basic" },
  { Header: "User Role", accessor: "userType", columnId: 4, sortType: "basic" },
  { Header: "Status", accessor: "status", columnId: 5, sortType: "basic" },
  {
    Header: "Date Added",
    accessor: "createdDate",
    columnId: 5,
    sortType: "basic",
    Cell: ({ row }) => {
      const date = new Date(row.original["createdDate"]);
      const formattedDate = moment(date).format("DD/MM/YYYY");
      return <span className="pointer text-right">{formattedDate}</span>;
    },
  },
  {
    Header: "Last Login",
    accessor: "lastLoginTime",
    columnId: 6,
    sortType: "basic",
    Cell: ({ row }) => {
      const date = new Date(row.original["lastLoginTime"]);
      const formattedDate = moment(date).format("DD/MM/YYYY hh:mmA");
      return <span className="pointer text-right">{formattedDate}</span>;
    },
  },
];

export const amlTableConfig = [
  { Header: "When to Apply Rule", accessor: "direction" },
  { Header: "Rule Type", accessor: "ruleType" },
  { Header: "Threshold Type", accessor: "thresholdType" },
  { Header: "Buisness Rule", accessor: "businessRuleNumber" },
  { Header: "Sanction Criteria", accessor: "sanctionComplianceCriteria" },
  { Header: "Country", accessor: "country" },
  { Header: "Delivery Method", accessor: "deliveryMethod" },
  { Header: "Amount From", accessor: "amountFrom" },
  { Header: "Amount To", accessor: "amountTo" },
  { Header: "Currency", accessor: "currencyCode" },
];

export const managementConfig = [
  {
    Header: () => {
      return <span>Date</span>;
    },
    columnId: 0,
    accessor: "creationDate",
    Cell: ({ row }) => {
      const date = new Date(row?.original?.creationDate);
      const formattedDate = moment(date).format("DD/MM/YYYY");
      return <span className="pointer">{formattedDate}</span>;
    },
  },
  {
    Header: "Account Holder Name",
    accessor: "account_holder_name",
    columnId: 1,
  },
  {
    Header: "Find Beneficiary By their Account Number",
    accessor: "find_by_number",
    columnId: 2,
  },
  { Header: "Account Type", accessor: "acc_type", columnId: 3 },
  { Header: "Balance", accessor: "accountBalance", columnId: 4 },
];

export const walletBalanceConfig = [
  { Header: "Account Name", accessor: "accountName", columnId: 1 },
  { Header: "Account Number", accessor: "walletAccountNumber", columnId: 2 },
];

export const customersTableConfig = (status, dictionary) => {
  return [
    {
      Header: "Full name",
      accessor: "firstname",
      columnId: 0,
      // Cell: ({ row }) => {
      //   return (
      //     <span className="pointer">{`${row.original.firstName} ${row.original.lastName}`}</span>
      //   );
      // },
      sortType: "basic",
    },
    { Header: "Email", accessor: "email", columnId: 1, sortType: "basic" },
    { Header: "Phone", accessor: "phone", columnId: 2, sortType: "basic" },
    { Header: "Country", accessor: "country", columnId: 3, sortType: "basic" },
    {
      Header: "Customer Type",
      accessor: "customerType",
      columnId: 4,
      sortType: "basic",
    },
    // {
    //   Header: "Actions",
    //   accessor: "actions",
    //   columnId: 5,
    //   Cell: ({ row }) => {
    //     return (
    //       <>
    //         <Activate className="pointer me-2 pt-1" />
    //         <Deactivate className="pointer ms-2 pt-1" />
    //       </>
    //     );
    //   },
    //   disableSortBy: true,
    // },
  ];
};

export const usersDataTableConfig = (status, dictionary) => {
  return [
    {
      Header: () => {
        return <span>{status ? dictionary["para_name"] : "Name"}</span>;
      },
      columnId: 0,
      accessor: "firstName",
      Cell: ({ row }) => {
        return (
          <span className="pointer">{`${row.original["firstName"]} ${row.original["lastName"]}`}</span>
        );
      },
    },
    {
      Header: () => {
        return <span>{status ? dictionary["para_email"] : "Email"}</span>;
      },
      columnId: 1,
      accessor: "email",
      Cell: ({ row }) => {
        return (
          <span className="pointer">
            <a
              href={`mailto:${row.original["email"]}`}
            >{`${row.original["email"]}`}</a>
          </span>
        );
      },
    },
    {
      Header: () => {
        return (
          <span>{status ? dictionary["para_phone_number"] : "Phone"}</span>
        );
      },
      columnId: 2,
      accessor: "phone",
      Cell: ({ row }) => {
        return (
          <span className="pointer">
            <a
              href={`tel:${row.original["phone"]}`}
            >{`${row.original["phone"]}`}</a>
          </span>
        );
      },
    },
    {
      Header: [status ? dictionary["para_country"] : "Country"],
      accessor: "country",
      columnId: 3,
    },
  ];
};

export const customerBeneficiaryTableConfig = [
  {
    Header: "Code",
    accessor: "code",
    columnId: 0,
    sortType: "basic",
  },
  {
    Header: "Name",
    accessor: "firstName",
    columnId: 1,
    sortType: "basic",
    Cell: ({ row }) => {
      return (
        <div className="d-flex flex-column">
          <span className="pointer">{`${row.original["firstName"]} ${row.original["lastName"]}`}</span>
          <span>{row.original.type}</span>
        </div>
      );
    },
  },
  { Header: "Company", accessor: "company", columnId: 2, sortType: "basic" },
  { Header: "Email", accessor: "emailAddress", columnId: 3, sortType: "basic" },
  {
    Header: "Address",
    accessor: "countryCommonName",
    columnId: 4,
    sortType: "basic",
  },
  { Header: "Phone", accessor: "phoneNumber", columnId: 5, sortType: "basic" },
];

export const updateDetails = [];

export const fixedAmountFeeTableConfig = [
  {
    Header: "Applicable To",
    accessor: "applicable_to",
    columnId: 0,
    sortType: "basic",
  },
  {
    Header: "Delivery Method",
    accessor: "delivery_method",
    columnId: 1,
    sortType: "basic",
  },
  {
    Header: "Transaction Origination Country",
    accessor: "country",
    columnId: 2,
    sortType: "basic",
  },
  {
    Header: "Currency Code",
    accessor: "currency",
    columnId: 3,
    sortType: "basic",
  },
  {
    Header: "Amount",
    accessor: "amount",
    columnId: 4,
    sortType: "basic",
  },
  {
    Header: "Commission Type",
    accessor: "commission",
    columnId: 5,
    sortType: "basic",
  },
];

export const walletStatementConfig = (status, dictionary) => {
  return [
    {
      Header: () => {
        return (
          <span className="text-center">
            {status ? dictionary["para_date"] : "Date"}
          </span>
        );
      },
      columnId: 0,
      accessor: "executionDate",
      Cell: ({ row }) => {
        const date = new Date(row.original["executionDate"]);
        const formattedDate = moment(date).format("DD/MM/YYYY hh:mmA");
        return <span className="pointer text-right">{formattedDate}</span>;
      },
    },
    { Header: "PCN", accessor: "pcn", columnId: 1 },
    {
      Header: status
        ? dictionary["para_payment_reference"]
        : "Payment Reference",
      accessor: "paymentReference",
      columnId: 2,
    },
    {
      Header: () => {
        return (
          <span className="text-center">
            {status ? dictionary["l_debit"] : "Debit"}
          </span>
        );
      },
      columnId: 3,
      accessor: "debit",
      Cell: ({ row }) => {
        const data =
          row.original["debit"] === ""
            ? ""
            : formatStringCurrency(row.original["debit"]);
        return <div className="pointer text-start">{data}</div>;
      },
    },
    {
      Header: () => {
        return (
          <span className="text-center">
            {status ? dictionary["l_credit"] : "Credit"}
          </span>
        );
      },
      columnId: 4,
      accessor: "credit",
      Cell: ({ row }) => {
        const data =
          row.original["credit"] === ""
            ? ""
            : formatStringCurrency(row.original["credit"]);
        return <div className="pointer text-start">{data}</div>;
      },
    },
    {
      Header: () => {
        return <span>Balance</span>;
      },
      columnId: 5,
      accessor: "newBalance",
      Cell: ({ row }) => {
        return (
          <span className="pointer text-right">
            {`${row.original["currencyCode"]} ${row.original[
              "newBalance"
            ].toFixed(2)}`}
          </span>
        );
      },
    },
  ];
};

export const transferHistoryConfig = (status, dictionary) => {
  return [
    {
      Header: () => {
        return <span>Date</span>;
      },
      columnId: 0,
      accessor: "createdDate",
      Cell: ({ row }) => {
        const date = new Date(row.original["createdDate"]);
        const formattedDate = moment(date).format("DD/MM/YYYY hh:mmA");
        return <span className="pointer">{formattedDate}</span>;
      },
    },
    {
      Header: () => {
        return (
          <span>{status ? dictionary["para_beneficiary"] : "Beneficiary"}</span>
        );
      },
      columnId: 1,
      accessor: "receiver",
      Cell: ({ row }) => {
        const render =
          row.original["transactionType"] === transferStatus.BILL_PAYMENT
            ? row.original.phoneNumber
            : row.original["receiver"];
        return <span className="pointer">{render}</span>;
      },
    },
    { Header: "PCN", accessor: "pcn", columnId: 2 },
    {
      Header: () => {
        return <span>{status ? dictionary["para_amount__"] : "Amount"}</span>;
      },
      columnId: 3,
      accessor: "principalAmount",
      Cell: ({ row }) => {
        return (
          <span className="text-end">
            {`${row.original["principalCurrencyCode"]} `}
            <strong>{row.original["principalAmount"]}</strong>
          </span>
        );
      },
    },
    {
      Header: () => {
        return <span>Status</span>;
      },
      columnId: 4,
      accessor: "display_status",
      Cell: ({ row }) => {
        return (
          <span className="pointer">
            <span
              className={`list-inline-item w-2 h-2 brround ${row.original["fill"]} me-2`}
            />
            {`${row.original["display_status"]}`}
          </span>
        );
      },
    },
  ];
};

export const interestTableConfig = (dictionary) => {
  return [
    {
      Header: () => {
        return (
          <span>
            {dictionary
              ? dictionary["WO_FEE_REFERENCE_COD_1921669480"]
              : "Fee Reference Code"}
          </span>
        );
      },
      columnId: 0,
      accessor: "feeReferenceCode",
      Cell: ({ row }) => {
        return <span className="pointer">{row.original.feeReferenceCode}</span>;
      },
    },
    {
      Header: () => {
        return (
          <span>
            {dictionary ? dictionary["para_created_date"] : "Created Date"}
          </span>
        );
      },
      columnId: 1,
      accessor: "createdDate",
      Cell: ({ row }) => {
        const date = new Date(row.original["createdDate"]);
        const formattedDate = moment(date).format("DD/MM/YYYY");
        return <span className="pointer">{formattedDate}</span>;
      },
    },
    {
      Header: () => {
        return (
          <span>
            {dictionary ? dictionary["WO_LAST_DAT_1050897174"] : "Last Date"}
          </span>
        );
      },
      columnId: 1,
      accessor: "lastDate",
      Cell: ({ row }) => {
        const date = new Date(row.original["lastDate"]);
        const formattedDate = moment(date).format("DD/MM/YYYY");
        return <span className="pointer">{formattedDate}</span>;
      },
    },
    {
      Header: () => {
        return (
          <span>
            {dictionary ? dictionary["WO_NEXT_DAT_2095244049"] : "Next Date"}
          </span>
        );
      },
      columnId: 2,
      accessor: "nextDate",
      Cell: ({ row }) => {
        const date = new Date(row.original["nextDate"]);
        const formattedDate = moment(date).format("DD/MM/YYYY");
        return <span className="pointer">{formattedDate}</span>;
      },
    },
    {
      Header: () => {
        return (
          <span>
            {dictionary ? dictionary["param_wallet_amount"] : "Amount"}
          </span>
        );
      },
      columnId: 3,
      accessor: "amount",
      Cell: ({ row }) => {
        const amount = row.original.amount;
        return <span className="pointer">{amount}%</span>;
      },
    },
    {
      Header: () => {
        return (
          <span>
            {dictionary ? dictionary["para_charges_type"] : "Commission Type"}
          </span>
        );
      },
      columnId: 4,
      accessor: "interestRateType",
    },
  ];
};

export const myTransactionsDataTableConfig = (status, dictionary) => {
  return [
    {
      Header: () => {
        return (
          <span>
            {status ? dictionary["para_created_date"] : "Created Date"}
          </span>
        );
      },
      columnId: 0,
      accessor: "transactionDate",
      Cell: ({ row }) => {
        const date = new Date(row.original["transactionDate"]);
        const formattedDate = moment(date).format("DD/MM/YYYY h:mm:s a");
        return <span className="pointer">{formattedDate}</span>;
      },
    },
    {
      Header: () => {
        return (
          <span>
            {status ? dictionary["para_paid_out_date"] : "Paid Out Date"}
          </span>
        );
      },
      columnId: 1,
      accessor: "paidDate",
      Cell: ({ row }) => {
        const date = new Date(row.original["paidDate"]);
        const formattedDate = moment(date).format("DD/MM/YYYY h:mm:s a");
        return <span className="pointer">{""}</span>;
      },
    },
    {
      Header: () => {
        return (
          <span>
            {status ? dictionary["l_receiving_country"] : "Receiving Country"}
          </span>
        );
      },
      columnId: 2,
      accessor: "country",
      Cell: ({ row }) => {
        return (
          <div className="text-center m-auto">
            <span className="pointer">
              {row?.original?.countryTo?.commonName}
            </span>
          </div>
        );
      },
    },
    {
      Header: [status ? dictionary["l_sending_amount"] : "Sending Amount"],
      accessor: "amountSent",
      columnId: 3,
      Cell: ({ row }) => {
        return (
          <div className="text-center m-auto">
            <span className="pointer">
              <span className="pe-2">{row?.original["amountSent"]}</span>
              {row?.original?.countryFrom?.currencyCode}
            </span>
          </div>
        );
      },
    },
    {
      Header: [status ? dictionary["l_receiving_amount"] : "Receiving Amount"],
      accessor: "amountReceived",
      columnId: 4,
      Cell: ({ row }) => {
        return (
          <div className="text-center m-auto">
            <span className="pointer">
              <span className="pe-2">{row?.original["amountReceived"]}</span>
              {row?.original?.countryTo?.currencyCode}
            </span>
          </div>
        );
      },
    },
    {
      Header: [status ? dictionary["para_delivery_method"] : "Delivery Method"],
      accessor: "transactionType",
      columnId: 5,
      Cell: ({ row }) => {
        return (
          <div className="text-center m-auto">
            <span className="pointer">{row.original?.transactionType}</span>
          </div>
        );
      },
    },
    {
      Header: [status ? dictionary["param_services_type_status"] : "Status"],
      accessor: "status",
      columnId: 6,
    },
  ];
};

export const apiReportDataTableConfig = (status, dictionary) => {
  return [
    {
      Header: () => {
        return (
          <span>
            {status ? dictionary["l_transaction_date"] : "Transaction date"}
          </span>
        );
      },
      columnId: 0,
      accessor: "transactionDate",
    },
    {
      Header: () => {
        return <span>{"Pay Out Date"}</span>;
      },
      columnId: 1,
      accessor: "payOutDate",
    },
    {
      Header: () => {
        return (
          <span>
            {status
              ? dictionary["WO_TRANSACTION_I_-307229512"]
              : "Transaction ID"}
          </span>
        );
      },
      columnId: 2,
      accessor: "transactionID",
    },
    {
      Header: () => {
        return <span>{"Third Party Reference"}</span>;
      },
      columnId: 3,
      accessor: "thirdPartyReference",
    },
    {
      Header: () => {
        return <span>{"Transaction Currency"}</span>;
      },
      columnId: 4,
      accessor: "transactionCurrency",
    },
    {
      Header: () => {
        return <span>{"Transaction Amount"}</span>;
      },
      columnId: 5,
      accessor: "transactionAmount",
    },
    {
      Header: () => {
        return <span>{"Transaction Type"}</span>;
      },
      columnId: 6,
      accessor: "transactionType",
    },
    {
      Header: () => {
        return <span>{"Beneficiary name"}</span>;
      },
      columnId: 7,
      accessor: "beneficiaryName",
    },
  ];
};

export const billsAndServicesDataTableConfig = (status, dictionary) => {
  return [
    {
      Header: () => {
        return (
          <span>{status ? dictionary["para_sent_date"] : "Sent date"}</span>
        );
      },
      columnId: 0,
      accessor: "dueDate",
      Cell: ({ row }) => {
        const date = new Date(row.original["dueDate"]);
        const formattedDate = moment(date).format("DD/MM/YYYY hh:mmA");
        return <span className="pointer text-right">{formattedDate}</span>;
      },
    },
    {
      Header: () => {
        return <span>{status ? dictionary["para_amount"] : "Amount"}</span>;
      },
      columnId: 1,
      accessor: "totalAmount",
      Cell: ({ row }) => {
        return (
          <div className="pointer text-center">
            {row?.original["totalAmount"]}
          </div>
        );
      },
    },
    {
      Header: () => {
        return <span>{status ? dictionary["para_company"] : "Company"}</span>;
      },
      columnId: 2,
      accessor: "operatorCode",
      Cell: ({ row }) => {
        return (
          <div className="pointer text-center">
            {row?.original["operatorCode"]}
          </div>
        );
      },
    },
    {
      Header: () => {
        return (
          <span>
            {status
              ? dictionary["WO_PAYMENT_STATU_-1941631114"]
              : "Payment Status"}
          </span>
        );
      },
      columnId: 3,
      accessor: "status",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["para_customer"] : "Customer"}</span>;
      },
      columnId: 4,
      accessor: "customer",
      Cell: ({ row }) => {
        return (
          <div className="pointer text-start d-flex flex-column">
            <span>
              <span className="pe-2">{row.original["firstName"]}</span>
              <span className="">{row.original["lastName"]}</span>
            </span>
            <span>({row.original["phone"]})</span>
          </div>
        );
      },
    },
    {
      Header: () => {
        return (
          <span>
            {status
              ? dictionary["API_PROVIDER_STATU_680979735"]
              : "API Provider Status"}
          </span>
        );
      },
      columnId: 5,
      accessor: "apiPaymantStatus",
    },
    {
      Header: () => {
        return (
          <span>
            {status
              ? dictionary["WO_API_REPOR_-1067201474"]
              : "API Transaction Report"}
          </span>
        );
      },
      columnId: 6,
      accessor: "apiResponseMessage",
    },
  ];
};

export const currentOpeningDataTableConfig = (status, dictionary) => {
  return [
    {
      Header: () => {
        return (
          <span>
            {status ? dictionary["para_currency_code"] : "Currency Code"}
          </span>
        );
      },
      columnId: 0,
      accessor: "sentDate",
    },
    {
      Header: () => {
        return (
          <span>
            {status
              ? dictionary["m_currency_account_balance"]
              : "Current Balance"}
          </span>
        );
      },
      columnId: 1,
      accessor: "amount",
    },
  ];
};

export const openBalanceDataTableConfig = (status, dictionary) => {
  return [
    {
      Header: () => {
        return (
          <span>
            {status ? dictionary["para_agent_branch"] : "Agent/Branch"}
          </span>
        );
      },
      columnId: 0,
      accessor: "transactionDate",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["l_cashier"] : "Cashier"}</span>;
      },
      columnId: 1,
      accessor: "payOutDate",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["para_date"] : "Date"}</span>;
      },
      columnId: 2,
      accessor: "transactionID",
    },
    {
      Header: () => {
        return (
          <span>
            {status ? dictionary["para_currency_code"] : "Currency Code"}
          </span>
        );
      },
      columnId: 3,
      accessor: "thirdPartyReference",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["para_credit"] : "Credit"}</span>;
      },
      columnId: 4,
      accessor: "transactionCurrency",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["l_debit"] : "Debit"}</span>;
      },
      columnId: 5,
      accessor: "transactionAmount",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["l_balance"] : "Balance"}</span>;
      },
      columnId: 6,
      accessor: "transactionType",
    },
    {
      Header: () => {
        return (
          <span>{status ? dictionary["l_action_type"] : "Action Type"}</span>
        );
      },
      columnId: 7,
      accessor: "beneficiaryName",
    },
    {
      Header: () => {
        return (
          <span>
            {status
              ? dictionary["PAYMENT_REFERENCE_1737194208"]
              : "Payment Reference"}
          </span>
        );
      },
      columnId: 8,
      accessor: "xx",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["l_user_name"] : "User Name"}</span>;
      },
      columnId: 9,
      accessor: "username",
    },
  ];
};

export const walletBalanceDataTableConfig = (status, dictionary) => {
  return [
    {
      Header: () => {
        return <span>{status ? dictionary["customer_name"] : "Holder"}</span>;
      },
      columnId: 0,
      accessor: "transactionDate",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["WO_REF_279919257"] : "REF#"}</span>;
      },
      columnId: 1,
      accessor: "payOutDate",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["para_date"] : "Date"}</span>;
      },
      columnId: 2,
      accessor: "transactionID",
    },
    {
      Header: () => {
        return (
          <span>{status ? dictionary["l_action_type"] : "Action Type"}</span>
        );
      },
      columnId: 7,
      accessor: "beneficiaryName",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["para_credit"] : "Credit"}</span>;
      },
      columnId: 4,
      accessor: "transactionCurrency",
    },
    {
      Header: () => {
        return <span>{status ? dictionary["l_debit"] : "Debit"}</span>;
      },
      columnId: 5,
      accessor: "transactionAmount",
    },
  ];
};
