import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import {getAllWallets} from "../../../../store/actions/accounts.action";
import {toggleNav} from "../../../../store/actions/component.action";
import {Loading} from "../../../../utils/helpers/constants";

const AccountBalance = () => {
  const dispatch = useDispatch();
  const {accounts, domainBrand, component} = useSelector(
    ({accountsReducer, domainBrandReducer, componentReducer}) => ({
      accounts: accountsReducer,
      domainBrand: domainBrandReducer,
      component: componentReducer
    }));

  const loadState = domainBrand.loading === Loading.FETCHING || accounts.loading === Loading.FETCHING;
  useEffect(() => {
    dispatch(toggleNav(false));
    if (accounts.loading !== Loading.SUCCESS) dispatch(getAllWallets());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? <SphereLoader/> :
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"/>
            <Header domainBrand={domainBrand}/>
            <HeaderMenu domainBrand={domainBrand} menu="accounts" active="balances"/>
            <Body accounts={accounts.accountList} domainBrand={domainBrand}/>
          </div>
          <Footer domainBrand={domainBrand}/>
        </>
      }
    </div>
  );
}

export default AccountBalance;
