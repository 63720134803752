import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { awaitingComplianceRequirements } from "../../store/actions/compliance.action";
import { uploadDocuments } from "../../store/actions/profile.action";
import {
  checkIfDatehasValidType,
  fileToBase64,
} from "../../utils/helpers/logicHelper";
import { documentType } from "../../utils/helpers/objectHelpers";
import CustomDatePicker from "../datePickers/CustomDatePicker";

const KYCUploadModal = ({
  domainBrand,
  onchange,
  uploadProps,
  countries,
  profile,
  type,
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [issueNo, setIssueNo] = useState("");
  const [amlDocument, setAMLDocument] = useState("");
  const [documents, setDocuments] = useState("");
  const [country, setCountry] = useState("");
  const [dateOfBirth, getDOB] = useState("");
  const [issueDate, getIssueDate] = useState("");
  const [expiryDate, getExpiryDate] = useState("");
  const [fileData, setFileData] = useState("");
  const [kycDocument, setKycDocument] = useState("");

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const docs = documentType(languageStatus, dictionary);

  useEffect(() => {
    if (type === "AML" && uploadProps["multiChoice"]) {
      const formattedDropdown = uploadProps.document.map((item) => ({
        value: item,
        label: item,
      }));
      setDocuments(formattedDropdown);
    }
  }, [uploadProps.document]);

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const onChangeFile = async (event) => {
    const allowedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "pdf",
      "doc",
      "docx",
      "JPG",
      "JPEG",
      "PNG",
      "PDF",
      "DOC",
      "DOCX",
    ];
    const sizeLimit = 2097152;
    const selectedFileSize = event.target.files[0].size || "";
    const selectedFileName = event.target.files[0].name || null;
    const fileExtension = selectedFileName.split(".").pop();
    if (typeof selectedFileSize === "undefined") return false;
    else if (
      event.target.files[0] &&
      !allowedExtensions.includes(fileExtension)
    )
      toast.error("File must be an image or document");
    else if (selectedFileSize > sizeLimit) toast.error("File size too large");
    else await setFileData(event.target.files[0]);
  };

  const handleFalseMultiChoice = (docObject) => {
    let documentArray = [];

    if (docObject["multiChoice"] === false) {
      documentArray[0] = docObject;

      const docOptions = documentArray?.map((item) => {
        return {
          value: item.document,
          label: item.document,
        };
      });

      return (
        <div className="form-row">
          <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
            <label>
              {languageStatus
                ? dictionary["WO_DOCUMENT_TYPE_1023917504"]
                : "Document Type"}
            </label>
            <Select
              options={docOptions}
              getOptionLabel={(docOptions) => docOptions["label"]}
              getOptionValue={(docOptions) => docOptions["value"]}
              isClearable={false}
              maxMenuHeight={250}
              menuPlacement="bottom"
              name="country"
              defaultValue={docOptions[0]}
            />
          </div>
        </div>
      );
    }
  };

  const submitKYC = async () => {
    setLoading(true);
    const file_base64 = await fileToBase64(fileData);

    const attachedDocument = {
      file_base64,
      issueNo,
      issueCountry: country["iso3Code"],
      dob: checkIfDatehasValidType(dateOfBirth),
      issueDate: checkIfDatehasValidType(issueDate),
      expiryDate: checkIfDatehasValidType(expiryDate),
    };

    if (type === "KYC") {
      attachedDocument.idType = uploadProps["docIdType"] || kycDocument?.value;
      const payload = {
        customerCode: profile["customerCode"],
        attachedDocument: [attachedDocument],
      };
      dispatch(uploadDocuments(payload));
      dispatch(awaitingComplianceRequirements());
      setLoading(false);
      handleClose();
    } else {
      attachedDocument.idType = uploadProps["multiChoice"]
        ? amlDocument.value
        : uploadProps["document"];
      const payload = {
        customerCode: profile["customerCode"],
        attachedDocument: [attachedDocument],
      };
      await dispatch(uploadDocuments(payload));
      setLoading(false);
      handleClose();
    }
  };

  // console.log(kycDocument?.value);

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="m-4">
            {type === "KYC" ? (
              <strong>Upload {uploadProps["docTypeCommonName"]}</strong>
            ) : (
              <strong>{uploadProps["message"]}</strong>
            )}
            <hr />
            {type === "AML" && uploadProps["multiChoice"] ? (
              <div className="form-row">
                <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["WO_DOCUMENT_TYPE_1023917504"]
                      : "Document Type"}
                  </label>
                  <Select
                    options={documents}
                    isClearable={false}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    name="country"
                    value={amlDocument}
                    onChange={(option) => setAMLDocument(option)}
                  />
                </div>
              </div>
            ) : (
              <>{handleFalseMultiChoice(uploadProps)}</>
            )}
            {uploadProps["multiChoice"] === true ? (
              <div className="form-row">
                <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["l_id_issue_no"]
                      : "Issue Number"}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={issueNo}
                    onChange={(event) => setIssueNo(event.target.value)}
                    required
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["l_issue_country"]
                      : "Issue Country"}
                  </label>
                  <Select
                    options={countries}
                    isClearable={false}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    name="country"
                    value={country}
                    onChange={(option) => setCountry(option)}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                  <label>
                    {languageStatus ? dictionary["l_issue_date"] : "Issue Date"}
                  </label>
                  <div>
                    <CustomDatePicker
                      getDate={getIssueDate}
                      showYearDropdown
                      selectDropdownMode
                      setMaxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["l_expiry_date"]
                      : "Expiry Date"}
                  </label>
                  <div>
                    <CustomDatePicker
                      getDate={getExpiryDate}
                      showYearDropdown
                      selectDropdownMode
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["l_date_of_birth"]
                      : "Date of birth"}
                  </label>
                  <div>
                    <CustomDatePicker
                      getDate={getDOB}
                      showYearDropdown
                      selectDropdownMode
                      withPortal={false}
                      setMaxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["l_file_upload"]
                      : "File Upload"}
                  </label>
                  <div className="input-group">
                    <input
                      onChange={onChangeFile}
                      type="file"
                      className="form-control"
                      placeholder="Choose.."
                      readOnly
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="form-row">
                <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["WO_DOCUMENT_TYPE_1023917504"]
                      : "Document Type"}
                  </label>
                  <Select
                    options={docs}
                    getOptionLabel={(docs) => docs["label"]}
                    getOptionValue={(docs) => docs["value"]}
                    isClearable={false}
                    maxMenuHeight={250}
                    menuPlacement="bottom"
                    name="country"
                    value={kycDocument}
                    onChange={(option) => setKycDocument(option)}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                  <label>
                    {languageStatus
                      ? dictionary["l_file_upload"]
                      : "File Upload"}
                  </label>
                  <div className="input-group">
                    <input
                      onChange={onChangeFile}
                      type="file"
                      className="form-control"
                      placeholder="Choose.."
                      readOnly
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="float-end ">
            <Button
              className="ms-lg-4"
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </Button>{" "}
            &nbsp;
            <button
              className="btn btn-primary"
              onClick={submitKYC}
              type="button"
            >
              {languageStatus ? dictionary["b_submit"] : "Submit"} &nbsp;
              {loading ? (
                <i className="fa fa-spin fa-spinner" />
              ) : (
                <i className="fa fa-cloud-upload-alt" />
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default KYCUploadModal;
