import ActionCreator from "../../utils/helpers/actionCreator";
import * as ComponentConstants from '../constants/component.constants';
import {expiredTokenCheck} from "./auth.action";
import {fetchDynamicFields} from "../../utils/services/domainBrand.service";

export const toggleNav = data => async dispatch => {
  dispatch(ActionCreator(ComponentConstants.TOGGLE_MOBILE_NAV, data));
};

export const getDynamicFields = type => async dispatch => {
  try {
    dispatch(ActionCreator(ComponentConstants.FETCH_DYNAMIC_FIELDS_REQUEST));
    const {data: responseData} = await fetchDynamicFields(type);
    if (responseData.status === "FAILED") throw responseData;

    const fields = responseData['data'];
    fields.formType = type;
    dispatch(ActionCreator(ComponentConstants.FETCH_DYNAMIC_FIELDS_SUCCESS, fields));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(ComponentConstants.FETCH_DYNAMIC_FIELDS_FAILURE));
  }
};

