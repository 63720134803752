import { useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-hot-toast";

import { senderSearchMethods } from "../../../../utils/helpers/objectHelpers";
import AccountsService from "../../../../utils/services/accounts.service";

const Body = ({ domainBrand, prevLocation, pageStatus }) => {
  const history = useHistory();
  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const searchDropdown = senderSearchMethods(languageStatus, dictionary);
  const [lookupUser, updateLookup] = useState({});
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      searchParam: "",
      searchType: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      const payload = { [values.searchType.value]: values.searchParam };
      const { data: responseData } = await AccountsService.accountLookUp(
        payload
      );
      if (responseData["status"] === "FAILED") {
        updateLookup({});
        toast.error(`${responseData["message"]}`);
        setLoading(false);
      } else {
        updateLookup(responseData.data);
        setLoading(false);
      }
    },
  });

  const confirmSenderInfo = () =>
    history.push({
      pathname: prevLocation,
      state: {
        user: lookupUser,
        text: pageStatus,
      },
    });

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">Get Sender</h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">Find Sender</h3>
              </div>
              <div className="card-body">
                <form
                  className="needs-validation"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["WO_SEARCH_MOD_-746607406"]
                          : "Search Mode"}
                      </label>
                      <Select
                        options={searchDropdown}
                        isClearable={false}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        name="searchType"
                        onChange={(option) =>
                          formik.setFieldValue("searchType", option)
                        }
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["l_search_criteria"]
                          : "Search Criteria"}
                      </label>
                      <input
                        type="text"
                        name="searchParam"
                        className="form-control"
                        onChange={formik.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <button className="btn btn-primary mt-5" type="submit">
                    Find Account{" "}
                    {loading ? <i className="fa fa-spin fa-spinner" /> : ""}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="card overflow-hidden">
              <div className="card-header bg-primary text-white">
                <div className="card-title">
                  {languageStatus
                    ? dictionary["para_sender_information"]
                    : "Sender Information"}
                </div>
              </div>
              <div className="card-body">
                {Object.entries(lookupUser).length < 1 ? (
                  <p className="text-muted">Search for a sender account</p>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <p className="text-muted">
                          {languageStatus
                            ? dictionary["l_sender_name"]
                            : " Sender Name"}
                        </p>
                        <p>{`${lookupUser["firstName"]} ${lookupUser["lastName"]}`}</p>
                      </div>
                      <div className="col-6">
                        <p className="text-muted">
                          {languageStatus
                            ? dictionary["l_sender_id"]
                            : "Sender ID"}
                        </p>
                        <p>{lookupUser["code"]}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="text-muted">
                          {languageStatus
                            ? dictionary["l_sender_email_address"]
                            : "Sender Email"}
                        </p>
                        <p>{lookupUser["emailAddress"]}</p>
                      </div>
                      <div className="col-6">
                        <p className="text-muted">
                          {languageStatus
                            ? dictionary["l_sender_phone"]
                            : "Sender Phone"}
                        </p>
                        <p>{lookupUser["phoneNumber"]}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <button
                          onClick={confirmSenderInfo}
                          className="btn btn-primary mt-5"
                          style={{ minWidth: "100%" }}
                          type="button"
                        >
                          {languageStatus
                            ? dictionary["button_confirm_user"]
                            : "Confirm"}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
