
const OpacityLoader = ({top}) => {
  return (
    <div className="openModal">
      <div className="loader-modal" style={{top: `${top}`}}>
        <div className="spinner1">
          <div className="double-bounce1"/>
          <div className="double-bounce2"/>
        </div>
      </div>
    </div>
  );
}

export default OpacityLoader;
