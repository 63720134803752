import ActionCreator from "../../utils/helpers/actionCreator";
import * as TreasuryConstants from "../constants/treasury.constants";
// import { Loading } from "../../utils/helpers/constants";
import { listTreasuryAccountBasedOnTypes } from "../../utils/services/treasury.service";
import toast from "react-hot-toast";

export const getTreasuryAccount = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(TreasuryConstants.GET_TREASURY_ACCOUNT_REQUEST));

    const { data: responseData } = await listTreasuryAccountBasedOnTypes(
      payload
    );

    console.log(responseData);

    if (responseData.status === "FAILED") {
      throw responseData;
    } else {
      dispatch(
        ActionCreator(
          TreasuryConstants.GET_TREASURY_ACCOUNT_SUCCESS,
          responseData.data
        )
      );
      toast.success("account fetched successfully");
    }
  } catch (error) {
    console.log(error?.response);
    dispatch(ActionCreator(TreasuryConstants.GET_TREASURY_ACCOUNT_FAILURE));
  }
};
