import { useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-hot-toast";

import BankFields from "../../../../../../components/bankFields/BankFields";
import SphereLoader from "../../../../../../components/loaders/SphereLoader";
import {
  fetchRequiredBankFields,
  fetchSupportedBanks,
} from "../../../../../../utils/services/transfer.service";
import { formatSupportedBanksDropdown } from "../../../../../../utils/helpers/logicHelper";
import { createBeneficiaryBankDetailsDeprecated } from "../../../../../../store/actions/users.action";
import { bankPairs, Loading } from "../../../../../../utils/helpers/constants";

const CreateBank = ({
  countries,
  currencies,
  domainBrand,
  bankInfo,
  formik,
}) => {
  const dispatch = useDispatch();
  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const noneObject = [
    {
      label: languageStatus ? dictionary["NON_1239141700"] : "None",
      value: "NONE",
    },
  ];
  const currencyDropdown = [...noneObject, ...currencies];
  const [loadingCountryProps, updateLoadingCountryProps] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [country, updateCountry] = useState("");
  const [currency, setCurrency] = useState({});
  const [selectedBank, updateSelectedBank] = useState({});
  const [supportedBanks, updateSupportedBanks] = useState([]);

  const selectedBankCountry = async (option) => {
    updateLoadingCountryProps(true);
    updateCountry(option);
    const { data: responseData } = await fetchRequiredBankFields(option.value);
    const { data: response } = await fetchSupportedBanks(option.value);
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch required bank fields, try again`);
    if (response.status !== Loading.SUCCESS)
      toast.error(`Could not fetch supported banks, try again`);
    else {
      const formattedBank = formatSupportedBanksDropdown(
        response.data["bankDetails"]
      );
      updateSupportedBanks(formattedBank);
      formik.setFieldValue("bankFields", responseData.data["bankFields"]);
      formik.setFieldValue(
        "requiredBankFields",
        responseData.data["requiredBankFields"]
      );
    }
    updateLoadingCountryProps(false);
  };

  const selectBankCurrency = async (option) => {
    setCurrency(option);
    updateLoadingCountryProps(true);
    const path = `${country.value}?delivery_method=ACCOUNTPAYMENT&currency_ode=${option.value}`;
    const { data: responseData } = await fetchRequiredBankFields(path);
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch required bank fields, try again`);
    else {
      formik.setFieldValue("bankFields", responseData.data["bankFields"]);
      formik.setFieldValue(
        "requiredBankFields",
        responseData.data["requiredBankFields"]
      );
    }
    updateLoadingCountryProps(false);
  };

  const createBeneficiaryBank = async () => {
    updateLoading(true);
    let bankDetailsData = {};
    let inclusionList = Object.keys(formik.values.requiredBankFields || {})
      .filter((value) => formik.values.requiredBankFields[value] === true)
      .map((value) => value.split("Required")[0]);
    inclusionList = inclusionList.filter((forEach) => forEach !== "country");
    inclusionList.forEach(
      (element) =>
        (bankDetailsData = {
          ...bankDetailsData,
          ...{ [element]: formik.values[bankPairs[element]] },
        })
    );
    const bankDetails = {
      ...bankInfo,
      ...bankDetailsData,
      ...{ bankName: selectedBank.name },
      ...{ country: country["iso3Code"] },
    };
    await dispatch(createBeneficiaryBankDetailsDeprecated(bankDetails));
    updateLoading(false);
  };

  return (
    <>
      <form className="needs-validation">
        <>
          <div className="form-row">
            <div className="col-md-12 col-lg-12 mb-3">
              <label>
                {languageStatus ? dictionary["l_country"] : "Country"}
              </label>
              <Select
                options={countries}
                isClearable={false}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                name="country"
                onChange={(option) => selectedBankCountry(option)}
              />
            </div>
            <div className="col-md-12 col-lg-12 mb-3">
              <label>Select bank account currency (optional)</label>
              <Select
                options={currencyDropdown}
                isClearable={false}
                maxMenuHeight={250}
                menuPlacement="bottom"
                menuPortalTarget={menuPortalTarget}
                value={currency}
                onChange={(option) => selectBankCurrency(option)}
              />
            </div>
          </div>
          {loadingCountryProps ? (
            <SphereLoader />
          ) : (
            <>
              {country ? (
                <>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label>
                        {languageStatus
                          ? dictionary["l_bank_name"]
                          : "Bank Name"}
                      </label>
                      <Select
                        options={supportedBanks}
                        noOptionsMessage={() => "No supported bank added"}
                        isClearable={false}
                        maxMenuHeight={250}
                        menuPlacement="bottom"
                        menuPortalTarget={menuPortalTarget}
                        onChange={(option) =>
                          updateSelectedBank({ ...selectedBank, ...option })
                        }
                      />
                    </div>
                  </div>
                  <BankFields
                    bankFields={formik.values["bankFields"]}
                    formik={formik}
                  />
                </>
              ) : (
                ""
              )}
            </>
          )}
        </>
        <div className="mt-4 float-end">
          <button
            onClick={createBeneficiaryBank}
            className="btn mx-3 btn-primary"
            type="button"
          >
            {languageStatus
              ? dictionary["title_create_beneficiary"]
              : "Create Beneficiary"}
            {loading ? (
              <>
                &nbsp;
                <i className="fa fa-spin fa-spinner" />
              </>
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateBank;
