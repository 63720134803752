import Axios from "../services/axios.service";

export const customerSignUp = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/usermanagement/register-customer",
    data: payload,
  });
};

export const fetchCustomers = async (customer_filter) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/adminmanagement/list-customers?page=1&size=10&customer_filter=${customer_filter}`,
  });
};

export const createBeneficiary = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/usermanagement/new-beneficiary",
    data: payload,
  });
};

export const updateBeneficiary = async (payload) => {
  return Axios({
    method: "PUT",
    url: `/web-services/api/v6/services/usermanagement/edit-beneficiary`,
    data: payload,
  });
};

export const addBankDetailsToBeneficiaryDeprecated = async (payload) => {
  return Axios({
    method: "POST",
    url: `web-services/api/v6/services/bankmanagement/add-bank-details`,
    data: payload,
  });
};

export const addBankDetailsToBeneficiary = async (payload) => {
  return Axios({
    method: "POST",
    url: `web-services/api/v6/services/bankmanagement/add-bank-details-key-value-pairs`,
    data: payload,
  });
};

export const updateBankDetailsToBeneficiary = async (payload) => {
  return Axios({
    method: "PUT",
    // url: `web-services/api/v6/services/bankmanagement/update-customer-bank-details`,
    url: `web-services/api/v6/services/bankmanagement/update-customer-bank-details-key-value-pairs`,
    data: payload,
  });
};

export const deleteBankDetailsFromBeneficiary = async (accountId, bankId) => {
  return Axios({
    method: "PUT",
    url: `web-services/api/v6/services/bankmanagement/delete-customer-bank-details/${accountId}/${bankId}`,
  });
};

export const fetchBeneficiaries = async () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/usermanagement/find-all-beneficiery`,
  });
};

export const archiveBeneficiary = async (beneficiary_id) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/usermanagement/archive-beneficiary/${beneficiary_id}`,
  });
};

export const unArchiveBeneficiary = async (beneficiary_id) => {
  console.log(beneficiary_id, "id-beneficiary");
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/usermanagement/unarchive-beneficiary/${beneficiary_id}`,
  });
};

// created services
export const getArchivedBeneficiary = async (params) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/usermanagement/find-all-beneficiery?customer_filter=${params}`,
  });
};

export const findBeneficiaryBySearchCriteria = async (payload) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/usermanagement/find-beneficiery-by-search-criteria${payload}`,
  });
};

export const fetchBeneficiaryDetails = async (customerCode) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/usermanagement/view-beneficiery-details/${customerCode}`,
  });
};

export const fetchBeneficiaryBankDetails = async (customerCode) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/bankmanagement/list-beneficiary-bank-details/${customerCode}`,
  });
};

export const fetchCustomerUsers = async () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/usermanagement/list-users`,
  });
};

export const toggleCustomerUserStatus = async (status, id) => {
  const urlSuffix = status
    ? "activate-user-account"
    : "deactivate-user-account";
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/usermanagement/${urlSuffix}/${id}`,
  });
};

export const fetchManagementUsers = async (type) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/adminmanagement/list-users/${type}`,
  });
};

export const editManagementUsers = async (payload, id) => {
  return Axios({
    method: "PUT",
    url: `/web-services/api/v6/services/admin/adminmanagement/list-users/${id}`,
    data: payload,
  });
};

export const activateUser = async (id) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/adminmanagement/activate-user-account/${id}`,
  });
};

export const deactivateUser = async (id) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/admin/adminmanagement/deactivate-user-account/${id}`,
  });
};
