import { toast } from "react-hot-toast";
import ActionCreator from "../../utils/helpers/actionCreator";
import { listBillsAndServices } from "../../utils/services/bills.service";
import * as BillsConstants from "../constants/bills.constants";
import { expiredTokenCheck } from "./auth.action";

export const fetchBillsAndServices = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(BillsConstants.BILLS_AND_SERVICES_REQUEST));
    const { data: responseData } = await listBillsAndServices();

    if (responseData.status === "FAILED") {
      throw responseData;
    } else {
      dispatch(
        ActionCreator(BillsConstants.BILLS_AND_SERVICES_SUCCESS, responseData)
      );
    }

    toast.success("Bills and services fetched successfully.");
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(BillsConstants.BILLS_AND_SERVICES_FAILURE));
  }
};
