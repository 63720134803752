import React from "react";
import AddRateCorridor from "./AddRateCorridor";

function Body({ domainBrand, country, exchangeRateId, userId }) {
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus
                ? dictionary["WO_CORRIDOR_MAPPIN_-824789932"]
                : "Corridor Mapping"}
            </h1>
          </div>
        </div>
        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card overflow-hidden">
              {/* <div className="card-header bg-primary text-white"></div> */}

              <AddRateCorridor
                domainBrand={domainBrand}
                countries={country}
                exchangeRateId={exchangeRateId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
