import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";

import DataTable from "../../../../components/dataTable/dataTable";
import LineXChart from "../../../../components/charts/LineXChart";
import { Loading, StatementMode } from "../../../../utils/helpers/constants";
import { walletBalanceConfig } from "../../../../utils/helpers/dataTableConfigs";
import { yearArray } from "../../../../utils/helpers/logicHelper";
import { toast } from "react-hot-toast";
import accountsService from "../../../../utils/services/accounts.service";
import OverdraftModal from "../../../../components/modals/OverdraftModal";

const Body = ({ domainBrand, accounts }) => {
  const history = useHistory(),
    years = yearArray();
  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [selectedYear, updateSelectedYear] = useState(years[0]);
  const [selectedAccount, setSelectedAccount] = useState(accounts[0]);
  const [chartData, updateChartData] = useState([]);
  const [filterData, setfilterData] = useState("");
  const [show, setShow] = useState(false);
  const [walletDetails, setWalletDetails] = useState({
    accName: "",
    accNumber: "",
    id: "",
  });
  const goToStatement = (data) =>
    history.push({
      pathname: `/statement`,
      state: { data: data, text: StatementMode.BALANCE },
    });

  const balanceColumn = {
    Header: () => {
      return <span>Balance</span>;
    },
    columnId: 3,
    accessor: "walletAccountBalance",
    Cell: ({ row }) => {
      return (
        <span>
          {`${row.original["walletAccountCurrency"]} ${row.original[
            "walletAccountBalance"
          ].toFixed(2)}`}
        </span>
      );
    },
  };

  const actionColumn = {
    Header: () => {
      return <span className="center-span">Action</span>;
    },
    columnId: 4,
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <div className="d-flex flex-row align-items-center justify-content-start">
          <button
            onClick={() => goToStatement(row.original)}
            className="btn btn-sm btn-primary"
          >
            View Statement
          </button>

          <button
            onClick={() => {
              setShow(true);
              setWalletDetails({
                ...walletDetails,
                accName: row.original.accountName,
                accNumber: row.original.walletAccountNumber,
                id: row.original.accountIdentificationNumberEntity,
              });
            }}
            className="btn btn-sm btn-primary ms-2"
          >
            Apply for an Overdraft
          </button>
        </div>
      );
    },
  };
  const tableObject = [...walletBalanceConfig, balanceColumn, actionColumn];
  const columns = useMemo(() => tableObject, []);

  useEffect(() => {
    if (selectedYear && selectedAccount) {
      let accountQuery = "";
      if (selectedAccount["walletAccountNumber"] !== null)
        accountQuery = `&accountNumber=${selectedAccount["walletAccountNumber"]}`;
      dispatchWalletData(accountQuery).catch((err) => console.log(err));
    }
  }, [selectedYear, selectedAccount]);

  const dispatchWalletData = async (accountQuery) => {
    const { data: responseData } = await accountsService.fetchWalletBalance(
      selectedAccount["walletAccountCurrency"],
      selectedYear.value,
      accountQuery
    );
    if (responseData.status !== Loading.SUCCESS)
      toast.error(`Could not fetch balance information, try again`);
    else updateChartData(responseData.data["walletBalance"]);
  };

  return (
    <>
      <OverdraftModal
        show={show}
        handleClose={() => setShow(false)}
        walletDetails={walletDetails}
      />
      <div className="app-content hor-content">
        <div className="container">
          <div className="page-header">
            <div>
              <h1 className="page-title">
                {languageStatus
                  ? dictionary["WO_ACCOUNT_BALANC_-1400513605"]
                  : "Account Balances"}
              </h1>
            </div>
            <div className="ms-auto pageheader-btn" />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title mb-1">
                    {languageStatus
                      ? dictionary["m_paycentre_account_wallet"]
                      : "Wallet Accounts"}
                  </h3>
                  <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12 mr-2 ms-auto">
                      <div className="form-row">
                        <div className="col-md-4 mb-3">
                          <label>
                            {languageStatus
                              ? dictionary["WO_SELECT_YEA_15189667"]
                              : "Select year"}
                          </label>
                          <Select
                            options={years}
                            isClearable={false}
                            isSearchable={true}
                            maxMenuHeight={250}
                            menuPlacement="bottom"
                            menuPortalTarget={menuPortalTarget}
                            name="selectedYear"
                            value={selectedYear}
                            onChange={(option) => updateSelectedYear(option)}
                          />
                        </div>
                        <div className="col-md-8 mb-3">
                          <label>
                            {languageStatus
                              ? dictionary["WO_SELECT_ACCOUN_416302202"]
                              : "Select Account"}
                          </label>
                          <Select
                            options={accounts}
                            isClearable={false}
                            isSearchable={true}
                            maxMenuHeight={250}
                            menuPlacement="bottom"
                            menuPortalTarget={menuPortalTarget}
                            name="selectedAccount"
                            value={selectedAccount}
                            onChange={(option) => setSelectedAccount(option)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <LineXChart
                        data={chartData}
                        status={languageStatus}
                        dictionary={dictionary}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card overflow-hidden">
                <div className="card-header bg-primary text-white">
                  <h3 className="card-title mb-1">
                    {languageStatus
                      ? dictionary["m_paycentre_account_wallet"]
                      : "Wallet Accounts"}
                  </h3>
                </div>
                <div className="card-body">
                  <div className="col-lg-6 col-md-6 mb-3 mt-2">
                    <input
                      type={"text"}
                      value={filterData}
                      className="form-control"
                      placeholder="filter by account number, account name..."
                      onChange={(e) => setfilterData(e.target.value)}
                    />
                  </div>
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={accounts}
                      filterData={filterData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
