import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import {toggleNav} from "../../../../store/actions/component.action";
import {getBillPaymentSupportedCountry} from "../../../../store/actions/country.action";
import {resetQuoteData} from "../../../../store/actions/transfer.action";
import {getProfile} from "../../../../store/actions/profile.action";
import {Loading, transferStatus} from "../../../../utils/helpers/constants";
import {transferStatusMap} from "../../../../utils/helpers/mappers";

const BillPayment = () => {
  const dispatch = useDispatch();
  const {domainBrand, country, component, profile, transfer: {quoteData}} = useSelector(
    ({domainBrandReducer, componentReducer, countryReducer, profileReducer, transferReducer}) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      country: countryReducer,
      profile: profileReducer,
      transfer: transferReducer
    }));

  const loadState = domainBrand.loading === Loading.FETCHING || country.loadingCountries === Loading.FETCHING;

  useEffect(async () => {
    dispatch(resetQuoteData());
    dispatch(toggleNav(false));
    if (profile.userProfileLoading !== Loading.SUCCESS) await dispatch(getProfile());
  }, []);

  useEffect(async () => {
    if (profile.originCountry) await dispatch(getBillPaymentSupportedCountry(profile.originCountry));
  }, [profile.originCountry]);

  const quoteObject = {
    paymentType: transferStatusMap[transferStatus.BILL_PAYMENT].paymentType,
    chargeCategory: transferStatusMap[transferStatus.BILL_PAYMENT].chargeCategory,
    transactionType: transferStatusMap[transferStatus.BILL_PAYMENT].transactionType,
    inverseCalculation: true,
    conversion: true,
  };

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? <SphereLoader/> :
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"/>
            <Header domainBrand={domainBrand}/>
            <HeaderMenu menu="billPayment" active="billPayment" domainBrand={domainBrand}/>
            <Body domainBrand={domainBrand} countries={country["billPaymentCountries"]}
                  profile={profile} quoteObject={quoteObject} quoteData={quoteData}/>
          </div>
          <Footer domainBrand={domainBrand}/>
        </>
      }
    </div>
  );
}

export default BillPayment;
