import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import Select from "react-select";
import * as Yup from "yup";
import { getKycRules } from "../../store/actions/compliance.action";
import {
  actionTypeOptions,
  complianceGroupOptions,
  kycRuleTypeOptions,
  restrictionOptions,
} from "../../utils/helpers/objectHelpers";
import { addKYCValidator } from "../../utils/helpers/validationHelpers";
import { editKycRule } from "../../utils/services/compliance.service";
import { errorStyles } from "../selectStyles/customStyles";

function EditKyc({
  show,
  handleClose,
  languageStatus,
  dictionary,
  countries,
  kycObject,
  id,
}) {
  const dispatch = useDispatch();
  //   const menuPortalTarget = document.getElementById("root");
  const [loading, setLoading] = useState(false);
  const [approveAccountOnExecution, setApproveAccountOnExecution] =
    useState(false);
  const [onSubmitClick, setOnSubmitClick] = useState(false);
  const groupDropdown = complianceGroupOptions(
    languageStatus,
    dictionary,
    "KYC"
  );
  const kycRuleDropdown = kycRuleTypeOptions(languageStatus, dictionary);
  const restrictionDropdown = restrictionOptions(languageStatus, dictionary);
  const actionTypeDropdown = actionTypeOptions(languageStatus, dictionary);
  const toggleCheckBox = () =>
    setApproveAccountOnExecution(!approveAccountOnExecution);

  const initialValidation = addKYCValidator();
  const initialValues = {
    country: "",
    ruleType: "",
    restriction: "",
    group: "",
    actionType: "",
    businessRuleNumber: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(initialValidation),
    onSubmit: async () => {},
  });

  const addNewKYC = async () => {
    setOnSubmitClick(true);
    const payload = {
      knowYourCustomerRuleUpdateDTOs: [
        {
          approveAccountOnExecution,
          businessRuleNumber: formik?.values?.businessRuleNumber,
          country: formik?.values?.country?.value,
          ruleType: formik?.values?.ruleType?.value,
          restriction: formik?.values?.restriction?.value,
          thresholdLimitType: formik?.values?.group?.value,
          actionType: formik?.values?.actionType?.value,
          id: id,
        },
      ],
    };
    if (Object.entries(formik.errors).length === 0) {
      setLoading(true);
      const { data: response } = await editKycRule(payload);
      if (response.status !== "SUCCESS") {
        toast.error("Something went wrong, kindly fill all required fields");
        setLoading(false);
      } else {
        setLoading(false);
        toast.success("kycRules created successfully");
        await dispatch(getKycRules());
      }
    }
  };

  const disableButton = formik.values.country === "";

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size={"xl"}
      scrollable
    >
      <Modal.Header closeButton={false}>
        <Modal.Title className="fw-bold">
          Edit {kycObject?.businessRuleNumber}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="card-body">
          <form className="needs-validation">
            <div className="form-row">
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["l_destination_country"]
                    : "Receiving Country"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  options={countries}
                  isClearable={false}
                  isSearchable={true}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  name="country"
                  value={formik.values.country}
                  onChange={(option) => formik.setFieldValue("country", option)}
                />
              </div>
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus ? dictionary["l_group"] : "Group"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  options={groupDropdown}
                  isClearable={false}
                  isSearchable={false}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  styles={
                    formik.errors["group"] && onSubmitClick ? errorStyles : ""
                  }
                  name="group"
                  value={formik.values.group}
                  onChange={(option) => formik.setFieldValue("group", option)}
                />
              </div>
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["para_rule_type"]
                    : "Rule Type to apply"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  options={kycRuleDropdown}
                  isClearable={false}
                  isSearchable={false}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  styles={
                    formik.errors["ruleType"] && onSubmitClick
                      ? errorStyles
                      : ""
                  }
                  name="ruleType"
                  value={formik.values.ruleType}
                  onChange={(option) =>
                    formik.setFieldValue("ruleType", option)
                  }
                />
              </div>
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["RESTRICTION_TYP_-1672091348"]
                    : "Restrictions"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  options={restrictionDropdown}
                  isClearable={false}
                  isSearchable={false}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  styles={
                    formik.errors["restriction"] && onSubmitClick
                      ? errorStyles
                      : ""
                  }
                  name="restriction"
                  value={formik.values.restriction}
                  onChange={(option) =>
                    formik.setFieldValue("restriction", option)
                  }
                />
              </div>
              <div className="col-md-6 col-lg-6 mb-3">
                {/* formerly scopr of rule */}
                <label>
                  {languageStatus
                    ? dictionary["para_threshold_type"]
                    : "Scope of Rule"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  options={actionTypeDropdown}
                  isClearable={false}
                  isSearchable={false}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  styles={
                    formik.errors["actionType"] && onSubmitClick
                      ? errorStyles
                      : ""
                  }
                  name="actionType"
                  value={formik.values.actionType}
                  onChange={(option) =>
                    formik.setFieldValue("actionType", option)
                  }
                />
              </div>
              <div className="col-md-6 col-lg-6 mb-3">
                <label>
                  {languageStatus
                    ? dictionary["WO_RULE_SCOR_-1334001714"] + " (%)"
                    : "Rule Score"}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="number"
                  min="1"
                  max="100"
                  maxLength={100}
                  name="businessRuleNumber"
                  value={formik.values.businessRuleNumber}
                  className={`form-control`}
                  onChange={formik.handleChange}
                  required
                />
              </div>
              <div className="col-md-12 mb-3 text-end">
                <div className="form-row m-0">
                  <label className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      defaultChecked={false}
                      onChange={toggleCheckBox}
                    />
                    <span className="custom-control-label pointer fs-12">
                      <span>
                        {" "}
                        {languageStatus
                          ? dictionary["WO_AUTOMATICALLY_APPROV_710831922"]
                          : "Approve"}
                      </span>
                    </span>
                  </label>
                </div>
                {/* <button>
                  Add
                  {loading && (
                    <>
                      &nbsp;
                      <i className="fa fa-spinner fa-spin" />
                    </>
                  )}
                </button> */}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          className="fw-bold px-3"
          disabled={disableButton}
          onClick={addNewKYC}
          type="button"
        >
          Update
          {loading ? (
            <>
              &nbsp;
              <i className="fa fa-spinner fa-spin" />
            </>
          ) : (
            ""
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditKyc;

EditKyc.defaultProps = {
  handleClose: () => {},
  handleUpdate: () => {},
};

EditKyc.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
