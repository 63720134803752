import {NavLink} from "react-router-dom";

const Error401 = () => (
  <div className="error-bg">
    <div className="page">
      <div className="page-content error-page error2">
        <div className="container text-center">
          <div className="error-template">
            <h1 className="display-1 text-dark mb-2">401</h1>
            <h5 className="error-details text-dark">
              Sorry, you cannot view this page or its resources, contact your administrator!
            </h5>
            <div className="text-center">
              <NavLink className="btn btn-primary mt-5 mb-5" to="/">
                <i className="fa fa-long-arrow-left"/> Back to Home
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Error401;
