import { toast } from "react-hot-toast";
import ActionCreator from "../../utils/helpers/actionCreator";
import * as CustomerConstants from "../constants/users.constants";
import { expiredTokenCheck } from "./auth.action";
import {
  addBankDetailsToBeneficiary,
  addBankDetailsToBeneficiaryDeprecated,
  createBeneficiary,
  customerSignUp,
  fetchBeneficiaries,
  fetchCustomers,
  fetchManagementUsers,
  updateBankDetailsToBeneficiary,
  updateBeneficiary,
  getArchivedBeneficiary,
  archiveBeneficiary,
  unArchiveBeneficiary,
  fetchCustomerUsers,
} from "../../utils/services/users.services";
import { customerTypes, Loading } from "../../utils/helpers/constants";

export const registerCustomer = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerConstants.CREATE_CUSTOMER_REQUEST));
    const { data: responseData } = await customerSignUp(payload);

    if (responseData.status === "FAILED") {
      throw responseData;
    } else {
      dispatch(ActionCreator(CustomerConstants.CREATE_CUSTOMER_SUCCESS));
      toast.success("Customer registered successfully. You can now login");
      navigate();
    }
  } catch (error) {
    dispatch(ActionCreator(CustomerConstants.CREATE_CUSTOMER_FAILURE));
    toast.error(`Signup Failed! ${error?.message}`);
  }
};

export const getAllCustomers = (query) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerConstants.GET_CUSTOMERS_REQUEST));
    // const { data: responseData } = await fetchCustomers(query);
    const { data: responseData } = await fetchCustomers();

    if (responseData.status === "FAILED") throw responseData;
    dispatch(
      ActionCreator(CustomerConstants.GET_CUSTOMERS_SUCCESS, responseData.data)
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CustomerConstants.GET_CUSTOMERS_FAILURE));
  }
};

export const getAllCustomersUsers = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerConstants.GET_CUSTOMER_USER_REQUEST));
    const { data: responseData } = await fetchCustomerUsers();

    if (responseData.status === "FAILED") throw responseData;
    dispatch(
      ActionCreator(
        CustomerConstants.GET_CUSTOMER_USER_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CustomerConstants.GET_CUSTOMER_USER_FAILURE));
  }
};

export const getAllAgents = (type) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerConstants.GET_PAYCENTRE_REQUEST));
    const { data: responseData } = await fetchManagementUsers(type);

    if (responseData.status === "FAILED") throw responseData;
    dispatch(
      ActionCreator(CustomerConstants.GET_PAYCENTRE_SUCCESS, responseData.data)
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CustomerConstants.GET_PAYCENTRE_FAILURE));
  }
};

export const getAllBeneficiaries = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerConstants.GET_BENEFICIARIES_REQUEST));
    const { data: responseData } = await fetchBeneficiaries();

    if (responseData.status === "FAILED") throw responseData;
    dispatch(
      ActionCreator(
        CustomerConstants.GET_BENEFICIARIES_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CustomerConstants.GET_BENEFICIARIES_FAILURE));
  }
};

export const fetchArchivedBeneficiary = (params) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerConstants.GET_ARCHIVED_BENEFICIARY_REQUEST));
    const { data: responseData } = await getArchivedBeneficiary(params);

    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        CustomerConstants.GET_ARCHIVE_BENEFICIARY_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CustomerConstants.GET_ARCHIVE_BENEFICIARY_FAILURE));
  }
};

export const archiveSingleBeneficary = (beneficiary_id) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerConstants.ARCHIVE_BENEFICIARY_REQUEST));
    const { data: responseData } = await archiveBeneficiary(beneficiary_id);

    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        CustomerConstants.ARCHIVE_BENEFICIARY_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CustomerConstants.ARCHIVE_BENEFICIARY_FAILURE));
  }
};

export const unArchiveSingleBeneficary =
  (beneficiary_id) => async (dispatch) => {
    try {
      dispatch(ActionCreator(CustomerConstants.UNARCHIVE_BENEFICIARY_REQUEST));
      const { data: responseData } = await unArchiveBeneficiary(beneficiary_id);

      if (responseData.status === "FAILED") throw responseData;

      dispatch(
        ActionCreator(
          CustomerConstants.UNARCHIVE_BENEFICIARY_SUCCESS,
          responseData.data
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(ActionCreator(CustomerConstants.UNARCHIVE_BENEFICIARY_FAILURE));
    }
  };

export const createBeneficiaryDetails =
  (payload, navigateToTransfer) => async (dispatch) => {
    try {
      // const {beneficiaryDetails, bankDetails, bankDetailsState} = payload;
      dispatch(ActionCreator(CustomerConstants.CREATE_BENEFICIARY_REQUEST));
      const { data: responseData } = await createBeneficiary(payload);

      /*    if (bankDetailsState) {
          const {firstName, lastName, companyName, type} = beneficiaryDetails;
          const accountHolderName = type === "COMPANY" ? companyName : `${firstName} ${lastName}`;

          const addBankToBeneficiary = {
            accountHolderName,
            accountOwnerCustomerCode: responseData.data["beneficiaryCode"], ...bankDetails
          };
          createBeneficiaryBankDetails(addBankToBeneficiary);
        }*/

      const { firstName, lastName, companyName, type } = payload;
      const accountHolderName =
        type === customerTypes.COMPANY
          ? companyName
          : `${firstName} ${lastName}`;
      const bankDetails = {
        accountHolderName,
        accountOwnerCustomerCode: responseData.data["beneficiaryCode"],
      };

      if (responseData.status === "FAILED") throw responseData;
      toast.success("New beneficiary added");
      dispatch(
        ActionCreator(
          CustomerConstants.CREATE_BENEFICIARY_SUCCESS,
          responseData.data
        )
      );
      navigateToTransfer(bankDetails);

      dispatch(getAllBeneficiaries());
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(ActionCreator(CustomerConstants.CREATE_BENEFICIARY_FAILURE));
    }
  };

export const updateBeneficiaryDetails = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_REQUEST));
    const { data: responseData } = await updateBeneficiary(payload);
    if (responseData.status === "FAILED") throw responseData;

    toast.success("Beneficiary information updated successfully");
    dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_SUCCESS));
    dispatch(getAllBeneficiaries());
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    toast.error("Could not update beneficiary details");
    dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_FAILURE));
  }
};

export const createBeneficiaryBankDetailsDeprecated =
  (payload) => async (dispatch) => {
    try {
      dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_REQUEST));
      const { data: responseData } =
        await addBankDetailsToBeneficiaryDeprecated(payload);
      if (responseData.status !== Loading.SUCCESS) throw responseData;

      toast.success("New bank details added successfully");
      dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_SUCCESS));
      dispatch(getAllBeneficiaries());
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      toast.error("Could not add new bank details to beneficiary");
      dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_FAILURE));
    }
  };

export const createBeneficiaryBankDetails = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_REQUEST));
    const { data: responseData } = await addBankDetailsToBeneficiary(payload);
    if (responseData.status !== Loading.SUCCESS) throw responseData;

    toast.success("New bank details added successfully");
    dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_SUCCESS));
    dispatch(getAllBeneficiaries());
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    toast.error("Could not add new bank details to beneficiary");
    dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_FAILURE));
  }
};

export const updateBeneficiaryBankDetails = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_REQUEST));
    const { data: responseData } = await updateBankDetailsToBeneficiary(
      payload
    );
    if (responseData.status === "FAILED") throw responseData;

    toast.success("Beneficiary bank details updated successfully");
    dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_SUCCESS));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    toast.error("Could not update beneficiary bank details");
    dispatch(ActionCreator(CustomerConstants.UPDATE_BENEFICIARY_FAILURE));
  }
};
