/* eslint-disable no-undef */
import { customerTypes, userTypes } from "./constants";

export const languageDropDownOptions = [
  { value: "en", label: "English", id: "en" },
];

export const pageSizeOptions = [
  { label: "Showing 10", value: "10" },
  { label: "Showing 20", value: "20" },
  { label: "Showing 30", value: "30" },
  { label: "Showing 40", value: "40" },
  { label: "Showing 50", value: "50" },
];

export const customerBeneficiaryTypes = [
  { label: "COMPANY", value: "COMPANY" },
  { label: "INDIVIDUAL", value: "INDIVIDUAL" },
];

export const genderDropdown = (status, dictionary) => {
  return [
    {
      id: 1,
      label: status ? dictionary["WO_MAL_-1187462577"] : "Male",
      value: "MALE",
    },
    {
      id: 2,
      label: status ? dictionary["WO_FEMAL_-701150533"] : "Female",
      value: "FEMALE",
    },
    // {
    //   id: 3,
    //   label: status ? dictionary["l_other"] : "Others",
    //   value: "OTHERS",
    // },
  ];
};

export const complianceGroupOptions = (status, dictionary, type) => {
  if (type === "KYC")
    return [
      {
        label: status
          ? dictionary["WO_ONLINE_CUSTOMER_-556615961"]
          : "Online Customer",
        value: "ONLINE_CUSTOMER",
      },
      {
        label: status
          ? dictionary["l_wallet_receipt_actioned_by_paycenter"]
          : "Pay Centre",
        value: "PAYCENTER",
      },
      {
        label: status ? dictionary["para_pay_center_admin"] : "Agent Admin",
        value: "AGENT_ADMIN",
      },
      { label: status ? dictionary["l_cashier"] : "Cashier", value: "CASHIER" },
    ];
  else if (type === "AML")
    return [
      { label: "Apply to transaction created by customer", value: "CUSTOMER" },
      {
        label: "Apply to transaction created by cashier/agent",
        value: "PAYCENTER",
      },
    ];
  else return [];
};

export const sanctionComplianceCriteria = (status, dictionary) => {
  return [
    { label: "No order", value: "NO_ORDER" },
    { label: "Fixed", value: "FIXED" },
  ];
};

export const kycRuleTypeOptions = (status, dictionary) => {
  return [
    {
      label: "Apply when customer is signing up for a new account",
      value: "ON_SIGN_UP",
    },
  ];
};

// RULE TYPE TO APPLY --> ADD AML RULE END POINT
export const amlRuleTypeOptions = (status, dictionary) => {
  return [
    { label: "Consider only this transaction", value: "ONE_OFF_TRANSACTION" },
    { label: "Apply to all transaction", value: "GENERIC_TRANSACTION" },
    { label: "Check in sanction list", value: "SANCTION_WORKFLOW" },
    { label: "Daily", value: "DAILY" },
    { label: "Weekly", value: "WEEKLY" },
    { label: "Monthly", value: "MONTHLY" },
    { label: "Annually", value: "ANNUALLY" },
    { label: "Condsider previous transactions", value: "LINKED_TRANSACTION" },
    { label: "Apply on customer account", value: "GENERIC_ACCOUNT" },
  ];
};

// WHEN TO APPLY AML RULE --> ON THE ADD AML RULE ENDPOINT
export const amlRuleDirectionOptions = (status, dictionary) => {
  return [
    { label: "Apply rule when creating a new transaction", value: "PAYIN" },
    { label: "Apply rule when paying out a transaction", value: "PAYOUT" },
    {
      label: "Apply when crediting customer's account",
      value: "ACCOUNT_CREDITING",
    },
  ];
};

// DELIVERY METHODS --> AML RULES
export const deliveryMethod = () => {
  return [
    { label: "AIRTIME_TOPUP", value: "AIRTIME_TOPUP" },
    { label: "ACCOUNTPAYMENT", value: "ACCOUNTPAYMENT" },
    { label: "ACCOUNTPAYMENT_DEBIT_CARD", value: "ACCOUNTPAYMENT_DEBIT_CARD" },
    {
      label: "ACCOUNTPAYMENT_CREDIT_CARD",
      value: "ACCOUNTPAYMENT_CREDIT_CARD",
    },
    { label: "CASHPICKUP", value: "CASHPICKUP" },
    { label: "CURRENCY_CONVERTER", value: "CURRENCY_CONVERTER" },
    { label: "CRYPTO_CURRENCY", value: "CRYPTO_CURRENCY" },
    { label: "BILL_PAYMENT", value: "BILL_PAYMENT" },
    { label: "WALLET", value: "WALLET" },
    { label: "MERCHANT_PRODUCT", value: "MERCHANT_PRODUCT" },
    { label: "MOBILE_MONEY", value: "MOBILE_MONEY" },
    { label: "MERCHANT_PAYMENT", value: "MERCHANT_PAYMENT" },
    { label: "PAYMENT_METHOD", value: "PAYMENT_METHOD" },
    { label: "AGGREGATOR_MOBILE_TOPUP", value: "AGGREGATOR_MOBILE_TOPUP" },
    { label: "AGGREGATOR_BILL", value: "AGGREGATOR_BILL" },
    { label: "AGGREGATOR_SMS", value: "AGGREGATOR_SMS" },
    { label: "SMS_PAYMENT", value: "SMS_PAYMENT" },
  ];
};

// remove aitime pop up and bill payment
export const rateDeliveryMethod = () => {
  return [
    { label: "AGGREGATOR SMS", value: "AGGREGATOR_SMS" },
    { label: "AGGREGATOR AIRTIME TOPUP", value: "AGGREGATOR_AIRTIME_TOPUP" },
    { label: "AGGREGATOR BILL PAYMENTS", value: "AGGREGATOR_BILL_PAYMENTS" },
    { label: "SOCIAL LOGIN", value: "SOCIAL_LOGIN" },
    { label: "SECURITY", value: "SECURITY" },
    { label: "ACTIVE DIRECTORY", value: "ACTIVE_DIRECTORY" },
    { label: "ADDRESS LOOKUP", value: "ADDRESS_LOOKUP" },
    { label: "MONEY TRANSFER", value: "MONEY_TRANSFER" },
    { label: "MONEY TRANSFER PAY IN", value: "MONEY_TRANSFER_PAY_IN" },
    { label: "CRYPTOCURRENCY", value: "CRYPTO_CURRENCY" },
    { label: "MOBILE MONEY TRANSFER", value: "MOBILE_MONEY_TRANSFER" },
    { label: "ONLINE PAYMENT", value: "ONLINE_PAYMENT" },
    { label: "ONLINE PAYMENT", value: "ONLINE_PAYMENT" },
    {
      label: "CUSTOM POINT OF SALE PAYMENT",
      value: "CUSTOM_POINT_OF_SALE_PAYMENT",
    },
    {
      label: "ONLINE BANK PAYMENT",
      value: "ONLINE_BANK_PAYMENT",
    },
    {
      label: "CRYPTO CURRENCY PAYMENT",
      value: "CRYPTO_CURRENCY_PAYMENT",
    },
    {
      label: "PAYMENT POINT",
      value: "PAYMENT_POINT",
    },
    {
      label: "CURRENCY EXCHANGE RATE FEED API",
      value: "CURRENCY_EXCHANGE_RATE_FEED_API",
    },
    {
      label: "IBAN VALIDATOR",
      value: "IBAN_VALIDATOR",
    },
    {
      label: "SWIFT CODE VALIDATOR",
      value: "SWIFT_CODE_VALIDATOR",
    },
    {
      label: "EXTERNAL KYC PROVIDER",
      value: "EXTERNAL_KYC_PROVIDER",
    },
    {
      label: "TRANSACTION MONITORING",
      value: "TRANSACTION_MONITORING",
    },
    {
      label: "CORRESPONDENT BANK ACCOUNT CREATION",
      value: "CORRESPONDENT_BANK_ACCOUNT_CREATION",
    },
    {
      label: "CORRESPONDENT BANK UTIL",
      value: "CORRESPONDENT_BANK_UTIL",
    },
    {
      label: "WALLET SYNCHRONIZATION",
      value: "WALLET_SYNCHRONIZATION",
    },
    {
      label: "WALLET ME",
      value: "WALLET_ME",
    },
    {
      label: "EXTERNAL LEDGER SYNCHRONIZATION",
      value: "EXTERNAL_LEDGER_SYNCHRONIZATION",
    },
    {
      label: "THIRD PARTY CARD ISSUER",
      value: "THIRD_PARTY_CARD_ISSUER",
    },
  ];
};

export const threshold = [
  { label: "Check sending/receiving amount", value: "THRESHOLD_LIMIT" },
  {
    label: "Linked transaction check, match by (Country, Name, DOB)",
    value: "LINKED_TRANSACTION",
  },
  { label: "Check customer documents", value: "THRESHOLD_IDENTIFICATION" },
];

export const restrictionOptions = (status, dictionary) => {
  return [
    {
      label:
        "Do not allow customer to login into the system until this condition is satisfied",
      value: "BLOCK",
    },
    {
      label:
        "Allow Customer to login into system but flag all transaction for compliance officer",
      value: "WARN",
    },
  ];
};

export const actionTypeOptions = (status, dictionary) => {
  return [
    {
      label: status ? dictionary["WO_VERIFY_CUSTOMER_EMAI_583224316"] : "Email",
      value: "VERIFY_ACCOUNT_VIA_EMAIL",
    },
    {
      label: "Verify Customer Phone Number",
      value: "VERIFY_ACCOUNT_VIA_PHONE_NUMBER",
    },
    {
      label: "eKYC | Take live photo",
      value: "VERIFY_ACCOUNT_VIA_EKYC_FACE_PHOTO",
    },
    {
      label: "eKYC | Take live video",
      value: "VERIFY_ACCOUNT_VIA_EKYC_FACE_VIDEO",
    },
    {
      label: "Request Customer Document",
      value: "VERIFY_ACCOUNT_VIA_IDENTIFICATION_DOCUMENT",
    },
  ];
};

export const customerOptionsDropdown = (status, dictionary) => {
  return [
    {
      label: status
        ? dictionary["WO_ONLINE_CUSTOMER_-556615961"]
        : "Online Customers",
      value: "ARCHIVED",
    },
    { label: "Offline Customers", value: "UN_ARCHIVED" },
  ];
};
export const idRequired = [
  { label: "", value: "" },
  { label: "", value: "" },
];

export const senderSearchMethods = (status, dictionary) => {
  return [
    {
      label: status ? dictionary["para_phone_number"] : "Phone Number",
      value: "phoneNumber",
    },
    {
      label: status ? dictionary["para_account_number"] : "Account Number",
      value: "findbyAccountNumber",
    },
    {
      label: status ? dictionary["l_sender_email_address"] : "Email Address",
      value: "emailAddress",
    },
    {
      label: status ? dictionary["CUSTOMER_COD_-69633046"] : "Customer Code",
      value: "customerCode",
    },
  ];
};

export const treasuryAccountTypes = [
  { value: "AGENT", label: "Agent" },
  { value: "MTO_TREASURY_SINGLE_GENERAL_ACCOUNT", label: "General Account" },
  {
    value: "MTO_TREASURY_SINGLE_LIABILITY_ACCOUNT",
    label: "Liability Account",
  },
  { value: "MTO_TREASURY_SINGLE_BOP_EXIST_ACCOUNT", label: "BOP Out Account" },
  { value: "MTO_TREASURY_SINGLE_REVENUE_ACCOUNT", label: "Revenue Account" },
  { value: "MTO_TREASURY_SINGLE_SUSPENSE_ACCOUNT", label: "Suspense Account" },
  { value: "REGISTERED_CUSTOMER", label: "Registered Customer" },
  { value: "MTO_TREASURY_ACCURED_INTEREST_RATE", label: "Interest Account" },
];

export const currencies = [
  { value: "USD", label: "US Dollars (USD)" },
  { value: "NGN", label: "Nigerian Naira (NGN)" },
  { value: "GBP", label: "Pounds Sterling (GBP)" },
  { value: "EUR", label: "Euros (EUR)" },
];

export const purposeOfAccount = (status, dictionary) => {
  return [
    { value: "INTERNAL_TRANSFER", label: "Internal Transfer" },
    { value: "EXTERNAL_TRANSFER", label: "External Transfer" },
  ];
};

export const accountTypes = (status, dictionary) => {
  return [
    { value: "CURRENT_ACCOUNT", label: "Current Account" },
    { value: "SAVINGS_ACCOUNT", label: "Savings Account" },
    { value: "BUSINESS_CURRENT_ACCOUNT", label: "Business Current Account" },
    { value: "BUSINESS_SAVINGS_ACCOUNT", label: "Business Savings Account" },
    { value: "JOINT_CURRENT_ACCOUNT", label: "Joint Current Account" },
    { value: "JOINT_SAVINGS_ACCOUNT", label: "Joint Savings Account" },
    { value: "LOAN_FINANCE_ACCOUNT", label: "Loan Account" },
    { value: "TRADE_FINANCE_ACCOUNT", label: "Trade Finance Account" },
  ];
};

export const purposeOfTransfer = (status, dictionary, type) => {
  if (type === customerTypes.INDIVIDUAL) {
    return [
      {
        label: status ? dictionary["l_family_support"] : "Family Profile",
        value: "FAMILY_SUPPORT",
      },
      {
        label: status ? dictionary["l_building_project"] : "Building Project",
        value: "BUILDING_PROJECT",
      },
      {
        label: status
          ? dictionary["l_educational_expenses"]
          : "Educational Expenses",
        value: "EDUCATIONAL_EXPENSES",
      },
      { label: status ? dictionary["l_payback"] : "Payback", value: "PAYBACK" },
      {
        label: status ? dictionary["WO_SAVING_-2067137167"] : "Savings",
        value: "SAVINGS",
      },
      {
        label: status ? dictionary["WO_GIFT_-239192890"] : "Gift",
        value: "GIFT",
      },
      {
        label: status
          ? dictionary["WO_CHARITABLE_DONATION_-1792384979"]
          : "Charitable Donations",
        value: "CHARITABLE_DONATION",
      },
      {
        label: status
          ? dictionary["WO_GOODS_AND_SERVICE_1662627370"]
          : "Goods and services",
        value: "GOODS_AND_SERVICES",
      },
      { label: status ? dictionary["l_other"] : "Other", value: "OTHER" },
    ];
  } else if (type === customerTypes.COMPANY) {
    return [
      {
        label: status
          ? dictionary["WO_MERCHANDISE_TRANSACT_-1030759306"]
          : "Merchandise transaction/Goods purchase",
        value: "MERCHANDISE_TRANSACTION/GODS_PURCHASE",
      },
      {
        label: status
          ? dictionary["WO_SERVICE_TRANSACTION_-1623581626"]
          : "Services transaction/ services purchase",
        value: "SERVICES_TRANSACTION/SERVICES_PURCHASE",
      },
      {
        label: status ? dictionary["l_family_support"] : "Credit payment",
        value: "CARD_PAYMENT",
      },
      {
        label: status ? dictionary["WO_INVESTMEN_590167787"] : "Investment",
        value: "INVESTMENT",
      },
      {
        label: status
          ? dictionary["WO_REGULATORY_PAYMENT_-1428391854"]
          : "Regulatory Payment (e.g., penalty, fine)",
        value: "REGULATORY_PAYMENT",
      },
      {
        label: status
          ? dictionary["WO_GRANTS_AND_DONATION_1124949350"]
          : "Grants/donations",
        value: "GRANTS/DONATIONS",
      },
      {
        label: status ? dictionary["WO_BANKIN_862229199"] : "Banking",
        value: "BANKING",
      },
      {
        label: status
          ? dictionary["WO_BUSINESS_EXPENSE_-1067199771"]
          : "Business expenses",
        value: "BUSINESS_EXPENSES",
      },
      {
        label: status
          ? dictionary["WO_ROYALTY_PAYMEN_500586228"]
          : "Royalty Payment",
        value: "ROYALTY_PAYMENT",
      },
      {
        label: status
          ? dictionary["WO_DIVIDEND_PAYMEN_-852868320"]
          : "Dividend Payment",
        value: "DIVIDEND_PAYMENT",
      },
      {
        label: status
          ? dictionary["WO_LOAN_PAYMEN_1322847405"]
          : "Loan payment",
        value: "LOAN_PAYMENT",
      },
      {
        label: status
          ? dictionary["WO_REAL_ESTATEREN_1288296402"]
          : "Real estate/Rent",
        value: "REAL_ESTATE/RENT",
      },
      {
        label: status ? dictionary["WO_TAX_PAYMEN_-294122991"] : "Tax Payment",
        value: "TAX_PAYMENT",
      },
      { label: status ? dictionary["l_other"] : "Other", value: "OTHER" },
    ];
  } else if (type === customerTypes.CASHIER) {
    return [
      {
        label: status
          ? dictionary["WO_MERCHANDISE_TRANSACT_-1030759306"]
          : "Merchandise transaction/Goods purchase",
        value: "MERCHANDISE_TRANSACTION/GODS_PURCHASE",
      },
      {
        label: status
          ? dictionary["WO_SERVICE_TRANSACTION_-1623581626"]
          : "Services transaction/ services purchase",
        value: "SERVICES_TRANSACTION/SERVICES_PURCHASE",
      },
      {
        label: status ? dictionary["l_family_support"] : "Credit payment",
        value: "CARD_PAYMENT",
      },
      {
        label: status ? dictionary["WO_INVESTMEN_590167787"] : "Investment",
        value: "INVESTMENT",
      },
      {
        label: status
          ? dictionary["WO_REGULATORY_PAYMENT_-1428391854"]
          : "Regulatory Payment (e.g., penalty, fine)",
        value: "REGULATORY_PAYMENT",
      },
      {
        label: status
          ? dictionary["WO_GRANTS_AND_DONATION_1124949350"]
          : "Grants/donations",
        value: "GRANTS/DONATIONS",
      },
      {
        label: status ? dictionary["WO_BANKIN_862229199"] : "Banking",
        value: "BANKING",
      },
      {
        label: status
          ? dictionary["WO_BUSINESS_EXPENSE_-1067199771"]
          : "Business expenses",
        value: "BUSINESS_EXPENSES",
      },
      {
        label: status
          ? dictionary["WO_ROYALTY_PAYMEN_500586228"]
          : "Royalty Payment",
        value: "ROYALTY_PAYMENT",
      },
      {
        label: status
          ? dictionary["WO_DIVIDEND_PAYMEN_-852868320"]
          : "Dividend Payment",
        value: "DIVIDEND_PAYMENT",
      },
      {
        label: status
          ? dictionary["WO_LOAN_PAYMEN_1322847405"]
          : "Loan payment",
        value: "LOAN_PAYMENT",
      },
      {
        label: status
          ? dictionary["WO_REAL_ESTATEREN_1288296402"]
          : "Real estate/Rent",
        value: "REAL_ESTATE/RENT",
      },
      {
        label: status ? dictionary["WO_TAX_PAYMEN_-294122991"] : "Tax Payment",
        value: "TAX_PAYMENT",
      },
      { label: status ? dictionary["l_other"] : "Other", value: "OTHER" },
    ];
  }
};

export const sourceOfFunds = (status, dictionary, type) => {
  if (type === customerTypes.INDIVIDUAL) {
    return [
      { label: "Borrowed Funds/Loan", value: "BORROW_LOAN" },
      { label: "Charitable Donations", value: "CHARITABLE_DONATION" },
      { label: "Gift", value: "GIFT" },
      {
        label: "Pensions/Government/Welfare",
        value: "PENSION_GOVERNMENT_WELFARE",
      },
      { label: "Inheritance", value: "INHERITANCE" },
      { label: "Medical aid", value: "MEDICAL_AID" },
      { label: "Salary/Income", value: "SALARY_INCOME" },
      { label: "Savings", value: "SAVINGS" },

      // {label: status ? dictionary["l_other"] : "Borrowed Funds/Loan", value: "BORROW_LOAN"},
      // {label: status ? dictionary["l_other"] : "Charitable Donations", value: "CHARITABLE_DONATION"},
      // {label: status ? dictionary["l_other"] : "Gift", value: "GIFT"},
      // {label: status ? dictionary["l_other"] : "Pensions/Government/Welfare", value: "PENSION_GOVERNMENT_WELFARE"},
      // {label: status ? dictionary["l_other"] : "Inheritance", value: "INHERITANCE"},
      // {label: status ? dictionary["l_other"] : "Medical aid", value: "MEDICAL_AID"},
      // {label: status ? dictionary["l_other"] : "Salary/Income", value: "SALARY_INCOME"},
      // {label: status ? dictionary["l_other"] : "Savings", value: "SAVINGS"},
      { label: status ? dictionary["l_other"] : "Other", value: "OTHER" },
    ];
  } else {
    return [
      { label: "Company Earnings (this company)", value: "COMPANY_EARNINGS" },
      {
        label: "Company Earnings (related company)",
        value: "COMPANY_EARNINGS",
      },
      { label: "Credit", value: "CREDIT" },
      {
        label: "Dividends or Profits from Company",
        value: "DIVIDENDS_OR_PROFITS_FROM_COMPANY",
      },
      { label: "Investment Income", value: "INVESTMENT_INCOME" },
      { label: "Loan", value: "LOAN" },
      { label: "Sale of Assets", value: "SALE_OF_ASSETS" },
      { label: "Personal Savings", value: "PERSONAL_SAVINGS" },
      {
        label: "Venture Capital / Angel Investor / Crowdfunding",
        value: "VENTURE_CAPITAL/ANGEL_INVESTOR/CROWDFUNDING",
      },
      { label: "Grant", value: "GRANT" },

      // {label: status ? dictionary["l_other"] : "Company Earnings (this company)", value: "COMPANY_EARNINGS"},
      // {label: status ? dictionary["l_other"] : "Company Earnings (related company)", value: "COMPANY_EARNINGS"},
      // {label: status ? dictionary["l_other"] : "Credit", value: "CREDIT"},
      // {label: status ? dictionary["l_other"] : "Dividends or Profits from Company", value: "DIVIDENDS_OR_PROFITS_FROM_COMPANY"},
      // {label: status ? dictionary["l_other"] : "Investment Income", value: "INVESTMENT_INCOME"},
      // {label: status ? dictionary["l_other"] : "Loan", value: "LOAN"},
      // {label: status ? dictionary["l_other"] : "Sale of Assets", value: "SALE_OF_ASSETS"},
      // {label: status ? dictionary["l_other"] : "Personal Savings", value: "PERSONAL_SAVINGS"},
      // {label: status ? dictionary["l_other"] : "Venture Capital / Angel Investor / Crowdfunding", value: "VENTURE_CAPITAL/ANGEL_INVESTOR/CROWDFUNDING"},
      // {label: status ? dictionary["l_other"] : "Grant", value: "GRANT"},
      { label: status ? dictionary["l_other"] : "Other", value: "OTHER" },
    ];
  }
};

export const documentType = (status, dictionary) => {
  return [
    {
      value: "PASSPORT",
      label: status ? dictionary["WO_PASSPORT_-1233190375"] : "Passport",
    },
    {
      value: "DRIVING_LICENSE",
      label: status
        ? dictionary["WO_DRIVING_LICENS_-549077171"]
        : "Driving License",
    },
    {
      value: "NATIONAL_ID_CARD",
      label: status
        ? dictionary["WO_NATIONAL_ID_CAR_1183568493"]
        : "National ID Card",
    },
    {
      value: "VOTE_CARD",
      label: status ? dictionary["WO_VOTERS_CAR_-1248461269"] : "Vote Card",
    },
    {
      value: "ADDRESS_PROOF",
      label: status
        ? dictionary["WO_PROOF_OF_ADDRES_1457761845"]
        : "Proof of address",
    },
    {
      value: "SOURCE_OF_INCOME_PROOF",
      label: status
        ? dictionary["WO_PROOF_OF_SOURCE_OF_I_-1447307523"]
        : "Proof for the source of income",
    },
    {
      value: "OTHER_OFFICIAL_DOCUMENT",
      label: status
        ? dictionary["WO_OTHER_OFFICIAL_DOCUM_1089654015"]
        : "Other Official Document",
    },
    {
      value: "PROOF_OF_PAYMENT_DOCUMENT",
      label: status
        ? dictionary["WO_PROOF_OF_PAYMEN_1942323012"]
        : "Proof Of Payment",
    },
    {
      value: "EXPRESS_DEPOSIT_DOCUMENT",
      label: status
        ? dictionary["WO_DIRECT_DEBIT_MANDATE_1967644447"]
        : "Direct Debit Mandate Document",
    },
    {
      value: "CUSTOMER_DECLARATION_FORM",
      label: status
        ? dictionary["WO_CUSTOMER_DECLARATION_-377713555"]
        : "Customer declaration form",
    },
    {
      value: "BUSINESS_REGISTRATION",
      label: status
        ? dictionary["WO_CERTIFICATE_OF_INCOR_814579902"]
        : "Certificate of Incorporation",
    },
    {
      value: "TAX_CERTIFICATE",
      label: status
        ? dictionary["WO_VALID_BUSINESS_LICEN_-2146478446"]
        : "Valid Business Licence/Tax Certificate",
    },
    {
      value: "ARTICLES_OF_INCORPORATION",
      label: status
        ? dictionary["WO_ARTICLES_OF_INCORPOR_-168144592"]
        : "Articles of Incorporation / Memorandum of Association",
    },
    {
      value: "DIRECTORS_PASSPORT",
      label: status
        ? dictionary["WO_PASSPORT_OR_OTHER_ID_-681376684"]
        : "Passport or other identification documents of directors",
    },
    {
      value: "REGULATORY_APPROVAL",
      label: status
        ? dictionary["WO_REGULATORY_APPROVAL_729871986"]
        : "Regulatory Approval/License/Registration to engage in Remittance/Money Transfer",
    },
    {
      value: "AUDITED_FINANCIAL_STATEMENTS",
      label: status
        ? dictionary["WO_LAST__YEARS_AUDITED_-447368720"]
        : "Last 3 years audited financial statements",
    },
    {
      value: "PHOTOGRAPH_OF_THE_OUTLET",
      label: status
        ? dictionary["WO_PHOTOGRAPH_OF_OUTLE_2069789672"]
        : "Photograph of the outlet",
    },
    {
      value: "COMPLIANCE_POLICIES",
      label: status
        ? dictionary["WO_COMPLIANCE_POLICY_FO_288834685"]
        : "Compliance policies for Anti-money laundering and counter terrorism financing",
    },
    {
      value: " KYC_QUESTIONNAIRES",
      label: status
        ? dictionary["l_family_support"]
        : "Complete AML/CFT and KYC Questionnaires for Remittance Tie-Up",
    },
    {
      value: "OTHER",
      label: status ? dictionary["l_other"] : "Other",
    },
  ];
};

export const customerTypeDropdown = (status, dictionary) => {
  return [
    {
      label: status ? dictionary["l_individual"] : "Individual",
      value: "INDIVIDUAL",
    },
    {
      label: status ? dictionary["para_company"] : "Company",
      value: "COMPANY",
    },
  ];
};

export const productOptions = [
  { id: 1, label: "signup", value: "ON_SIGNUP" },
  { id: 2, label: "request", value: "ON_REQUEST" },
  { id: 3, label: "approval", value: "ON_APPROVAL" },
];

export const productAccountTypes = [
  { id: 1, label: "Current Account", value: "CURRENT_ACCOUNT" },
  { id: 2, label: "Savings Account", value: "SAVINGS_ACCOUNT" },
  { id: 3, label: "Joint Savings Account", value: "JOINT_SAVINGS_ACCOUNT" },
  { id: 4, label: "Joint Current Account", value: "JOINT_CURRENT_ACCOUNT" },
  { id: 5, label: "Trade Finance Account ", value: "TRADE_FINANCE_ACCOUNT" },
  {
    id: 6,
    label: "Business Current Account",
    value: "BUSINESS_CURRENT_ACCOUNT",
  },
  {
    id: 7,
    label: "Business Savings Account",
    value: "BUSINESS_SAVINGS_ACCOUNT",
  },
  { id: 8, label: "Loan Account", value: "LOAN_FINANCE_ACCOUNT" },
  { id: 9, label: "Buisness Account", value: "BUSINESS_ACCOUNT" },
  { id: 10, label: "Deposit Account", value: "DEPOSIT_ACCOUNT" },
];

//

export const productConfiguration = [
  {
    id: 1,
    label: "Which product account should be created automatically upon signup",
    value: "Which product account should be created automatically upon signup",
  },
  {
    id: 2,
    label: "Which product account should be created automatically upon request",
    value: "Which product account should be created automatically upon request",
  },
  {
    id: 1,
    label: "Which product account  request should go to an admin for approval",
    value: "Which product account  request should go to an admin for approval",
  },
];

export const relationshipOptions = (type, status, dictionary) => {
  if (type === customerTypes.COMPANY) {
    return [
      {
        value: "BANKING",
        label: status ? dictionary["WO_BANKIN_788719409"] : "Banking",
      },
      {
        value: "BUYER_MERCHANT",
        label: status
          ? dictionary["WO_BUYERMERCHAN_-1108372707"]
          : "Buyer/Merchant",
      },
      {
        value: "CHARITY",
        label: status
          ? dictionary["WO_CHARITY_EG_DONATI_-273771862"]
          : "Charity (e.g., Donation)",
      },
      {
        value: "CLIENT",
        label: status
          ? dictionary["WO_CLIENTCUSTOME_157633074"]
          : "Client/Customer",
      },
      {
        value: "CONTRACTOR",
        label: status ? dictionary["WO_CONTRACTO_-2132459368"] : "Contractor",
      },
      {
        value: "COOPERATE_SERVICE",
        label: status
          ? dictionary["WO_CORPORATE_SERVICE_-774059698"]
          : "Corporate Services",
      },
      {
        value: "EMPLOYEE",
        label: status ? dictionary["WO_EMPLOYEE_713112792"] : "Employee",
      },
      {
        value: "INVESTOR",
        label: status ? dictionary["WO_INVESTOR_772127637"] : "Investor",
      },
      {
        value: "REGULATOR",
        label: status ? dictionary["WO_REGULATO_1751524996"] : "Regulator",
      },
      {
        value: "SPONSOR",
        label: status ? dictionary["WO_SPONSO_-975176876"] : "Sponsor",
      },
      {
        value: "RELATED_ENTITY",
        label: status
          ? dictionary["WO_RELATED_ENTIT_1743969298"]
          : "Related Entity",
      },
      {
        value: "VENDOR",
        label: status ? dictionary["WO_VENDO_628333713"] : "Vendor",
      },
    ];
  } else if (type === customerTypes.INDIVIDUAL) {
    return [
      {
        value: "FRIENDS",
        label: status ? dictionary["WO_FRIEND_1753472052"] : "Friends",
      },
      {
        value: "PARENTS",
        label: status ? dictionary["WO_PARENT_-2054649633"] : "Parents",
      },
      {
        value: "RELATIVES",
        label: status ? dictionary["WO_RELATIVE_1775521148"] : "Relatives",
      },
      {
        value: "SPOUSE",
        label: status ? dictionary["WO_SPOUS_2065390488"] : "Spouse",
      },
      {
        value: "AUNT",
        label: status ? dictionary["WO_AUN_980850965"] : "Aunt",
      },
      {
        value: "BROTHER",
        label: status ? dictionary["WO_BROTHE_-1782942538"] : "Brother",
      },
      {
        value: "BROTHER_IN_LAW",
        label: status
          ? dictionary["WO_BROTHERINLA_2012369431"]
          : "Brother-in-law",
      },
      {
        value: "COUSIN",
        label: status ? dictionary["WO_COUSI_280008418"] : "Cousin",
      },
      {
        value: "DAUGHTER",
        label: status ? dictionary["WO_DAUGHTE_-1804138737"] : "Daughter",
      },
      {
        value: "FATHER",
        label: status ? dictionary["WO_FATHE_-1911543009"] : "Father",
      },
      {
        value: "FATHER_IN_LAW",
        label: status
          ? dictionary["WO_FATHERINLA_-1489925340"]
          : "Father-in-law",
      },
      {
        value: "FRIEND",
        label: status ? dictionary["WO_FRIEN_-87268010"] : "Friend",
      },
      {
        value: "GRAND_FATHER",
        label: status ? dictionary["WO_GRANDFATHE_2063370195"] : "Grandfather",
      },
      {
        value: "GRAND_MOTHER",
        label: status ? dictionary["WO_GRANDMOTHE_1816581927"] : "Grandmother",
      },
      {
        value: "HUSBAND",
        label: status ? dictionary["WO_HUSBAN_-1125213877"] : "Husband",
      },
      {
        value: "MOTHER",
        label: status ? dictionary["WO_MOTHE_-2030388943"] : "Mother",
      },
      {
        value: "MOTHER_IN_LAW",
        label: status
          ? dictionary["WO_MOTHERINLA_2058276440"]
          : "Mother-in-law",
      },
      {
        value: "NEPHEW",
        label: status ? dictionary["WO_NEPHE_-2022426521"] : "Nephew",
      },
      {
        value: "NIECE",
        label: status ? dictionary["WO_NIEC_1212287674"] : "Niece",
      },
      {
        value: "SELF",
        label: status
          ? dictionary["WO_SEL_-642733950"]
          : "Self (i.e. the sender, himself)",
      },
      {
        value: "SISTER",
        label: status ? dictionary["WO_SISTE_144750714"] : "Sister",
      },
      {
        value: "SISTER_IN_LAW",
        label: status ? dictionary["WO_SISTERINLA_294697436"] : "Sister-in-law",
      },
      { value: "SON", label: status ? dictionary["WO_SO_-1067693988"] : "Son" },
      {
        value: "UNCLE",
        label: status ? dictionary["WO_UNCL_-726799893"] : "Uncle",
      },
      {
        value: "WIFE",
        label: status ? dictionary["WO_WIF_-1395015896"] : "Wife",
      },
      {
        value: "OTHER",
        label: status ? dictionary["WO_OTHER_1080255776"] : "Others not listed",
      },
    ];
  } else return [];
};

export const menuOptions = (userType, status, dictionary) => {
  const general = [
    {
      name: "Dashboard",
      label: status ? dictionary["l_dashboard"] : "Dashboard",
      link: "/dashboard",
      icon: "fa fa-chart-line",
    },
    {
      name: "Profile",
      label: status ? dictionary["WO_USER_PROFIL_548513751"] : "Profile",
      link: "/profile",
      icon: "fa fa-user-alt",
    },
    {
      name: "Contact Support",
      label: status ? dictionary["l_support"] : "Support",
      link: "/support",
      icon: "fa fa-envelope-open",
    },
    {
      name: "Help",
      label: status ? dictionary["para_help"] : "Help",
      link: "/help",
      icon: "fa fa-question-circle",
    },
  ];
  const admin = [
    {
      name: "Customers",
      label: status ? dictionary["m_manage_customer"] : "Customers",
      link: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Administrators",
      label: "",
      link: "/management/admins",
      icon: "fa fa-users-cog",
    },
    {
      name: "Employees",
      label: "",
      link: "/management/employees",
      icon: "fa fa-user-tag",
    },
    {
      name: "Agents",
      label: "",
      link: "/management/agents",
      icon: "fa fa-user-secret",
    },
    {
      name: "Partners",
      label: "",
      link: "/management/agents",
      icon: "fa fa-user-friends",
    },
    {
      name: "Branches",
      label: "",
      link: "/management/agents",
      icon: "fa fa-warehouse",
    },
    { name: "Cashiers", label: "", link: "/cashier", icon: "fa fa-receipt" },
    { name: "KYC Rules", label: "", link: "/kyc-rules", icon: "fa fa-gavel" },
    { name: "AML Rules", label: "", link: "/aml-rules", icon: "fa fa-gavel" },
    {
      name: "Treasury Accounts",
      label: "",
      link: "/treasury-accounts",
      icon: "fa fa-file-invoice-dollar",
    },
  ];
  const customer = [
    {
      name: "Users",
      label: status ? dictionary["l_users"] : "Users",
      link: "/users",
      icon: "fa fa-users",
    },
    {
      name: "Transfer Funds",
      label: status
        ? dictionary["WO_TRANSFER_FUND_-1694451190"]
        : "Transfer Funds",
      link: "/transfer",
      icon: "fa fa-exchange-alt",
    },
    {
      name: "Transfer History",
      label: status
        ? dictionary["WO_TRANSFER_HISTOR_57573459"]
        : "Transfer History",
      link: "/transfer-history",
      icon: "fa fa-history",
    },
    {
      name: "Account Balance",
      label: status
        ? dictionary["WO_ACCOUNT_BALANCE_960871353"]
        : "Account Balances",
      link: "/balance",
      icon: "fa fa-wallet",
    },
    {
      name: "Account Statement",
      label: status
        ? dictionary["l_wallet_transaction_history"]
        : "Account Statements",
      link: "/statement",
      icon: "fa fa-file-invoice",
    },
    {
      name: "Fund Wallet",
      label: status ? dictionary["WO_FUND_WALLE_-1271550883"] : "Fund Wallet",
      link: "/fund-wallet",
      icon: "fa fa-wallet",
    },
    {
      name: "Beneficiaries",
      label: status
        ? dictionary["WO_BENEFICIARIE_-1845397122"]
        : "Beneficiaries",
      link: "/beneficiaries",
      icon: "fa fa-user-friends",
    },
  ];

  return userType === userTypes.CUSTOMER
    ? [...general, ...customer]
    : userTypes.ADMIN
    ? [...general, ...admin, ...customer]
    : [...general];
};

export const beneficiaryTriggers = [
  { id: 1, label: "Archived", value: "ARCHIVED" },
  { id: 2, label: "Un-archived", value: "UN_ARCHIVED" },

  { id: 3, label: "All", value: "NONE" },
];

export const exchangeRateUpdate = [
  {
    label: "I will use MTA exchange Rate Feed",
    value: "MTA_EXCHANGE_RATE_FEED",
  },
  {
    label: "I will use external exchange Rate",
    value: "EXTERNAL_EXCHANGE_RATE_FEED",
  },
  {
    label: "I will input exchange Rate",
    value: "MANUAL",
  },

  {
    label: "Link to another currency",
    value: "LINKED_TO_ANOTHER_EXCHANGE_RATE",
  },
];

export const exchangeRateProvider = [
  {
    id: 1,
    label: "Thunes",
    value: "Thunes",
  },
  {
    id: 2,
    label: "TerraPay Exchange Rate",
    value: "TerraPay Exchange Rate",
  },
  {
    id: 3,
    label: "More_Feed",
    value: "More_Feed",
  },
  {
    id: 4,
    label: "Tranglo_GBP",
    value: "Tranglo_GBP",
  },
  {
    id: 5,
    label: "Tranglo_USD",
    value: "Tranglo_USD",
  },
  {
    id: 6,
    label: "Daytona",
    value: "Daytona",
  },
  {
    id: 7,
    label: "Huveane_WISELY TEST",
    value: "Huveane_WISELY TEST",
  },
];

export const units = [
  {
    id: 1,
    label: "MINUTE",
    value: "MINUTE",
  },
  {
    id: 2,
    label: "HOUR",
    value: "HOUR",
  },
];

export const passwordExpiration = [
  {
    id: 1,
    label: "DAILY",
    value: "DAILY",
  },
  {
    id: 2,
    label: "WEEKLY",
    value: "WEEKLY",
  },
];

export const addUserRole = [
  {
    id: 1,
    role: "Auditor/Reports",
    name: "reports",
    dictionary: "WO_AUDITORREPORT_474386666",
  },
  {
    id: 2,
    role: "Compliance Parameter Setup",
    name: "compliance",
    dictionary: "WO_COMPLIANCE_PARAMETER_1655247147",
  },
  {
    id: 3,
    role: "Management",
    name: "management",
    dictionary: "m_management",
  },
  {
    id: 4,
    role: "Pay Centre Management",
    name: "payCentreManagement",
    dictionary: "WO_PAY_CENTRE_MANAGEMEN_-1050798757",
  },
  {
    id: 5,
    role: "Accounting",
    name: "accounting",
    dictionary: "m_clearing",
  },
  {
    id: 6,
    role: "View Treasury Accounts",
    name: "manageTreasuryAccount",
    dictionary: "WO_MASTER_WALLET_ACCOUN_-1763093340",
  },
  { id: 7, role: "Compliance", name: "compliance", dictionary: "m_compliance" },
  {
    id: 8,
    role: "Payment Collector",
    name: "paymentCollector",
    dictionary: "WO_PAYMENT_COLLECTO_489281550",
  },
  {
    id: 9,
    role: "API Transaction Report",
    name: "apiTransactionReport",
    dictionary: "WO_API_REPOR_-1067201474",
  },
  {
    id: 10,
    role: "Forex",
    name: "forex",
    dictionary: "WO_FORE_1219234404",
  },
  {
    id: 11,
    role: "Fee Management",
    name: "feeManagement",
    dictionary: "WO_FEE_MANAGEMEN_319095533",
  },
  {
    id: 12,
    role: "Marketing CRM",
    name: "marketingCrm",
    dictionary: "WO_MARKETING_CR_1201929625",
  },
  {
    id: 13,
    role: "IT Security",
    name: "itSecurity",
    dictionary: "WO_IT_SECURIT_-642440267",
  },
  {
    id: 14,
    role: "Fraud Prevention Team",
    name: "fraudPreventionTeam",
    dictionary: "WO_FRAUD_PREVENTION_TEA_1511663365",
  },
  { id: 15, role: "Editor", name: "editorTeam", dictionary: "l_editor" },
  {
    id: 16,
    role: "Regulatory Report Access",
    name: "regulator",
    dictionary: "WO_REGULATORY_REPORT_AC_-1760117632",
  },
  {
    id: 17,
    role: "Sales Person",
    name: "salesPerson",
    dictionary: "WO_SALES_PERSO_1533427802",
  },
  {
    id: 18,
    role: "Customer Service",
    name: "customerServices",
    dictionary: "WO_CUSTOMER_SERVIC_608474509",
  },
];

export const userType = () => {
  return [
    { id: 1, label: "Admin", value: "ADMIN" },
    { id: 2, label: "Agent", value: "AGENTS" },
    { id: 3, label: "Super agent", value: "SUPER_AGENT" },
    { id: 4, label: "Manager", value: "MANAGER" },
    { id: 5, label: "Bank admin", value: "BANK_ADMIN" },
    { id: 6, label: "Branch admin", value: "BRANCH_ADMIN" },
    { id: 7, label: "Compliance user", value: "COMPLIANCE_USER" },
    { id: 8, label: "Customer service", value: "CUSTOMER_SERVICE" },
    {
      id: 9,
      label: "Customer service manager",
      value: "CUSTOMER_SERVICE_MANAGER",
    },
    { id: 10, label: "Cashier", value: "CASHIER" },
    { id: 11, label: "Merchant", value: "MERCHANT" },
    { id: 12, label: "Super agent cashier", value: "SUPER_AGENT_CASHIER" },
    { id: 13, label: "Registered customer", value: "REGISTERED_CUSTOMER" },
    {
      id: 14,
      label: "Registered customer personal assistance",
      value: "REGISTERED_CUSTOMER_PERSONAL_ASSISTANCE",
    },
    { id: 15, label: "System admin", value: "SYSTEM_ADMIN" },
    { id: 16, label: "Auditor", value: "AUDITOR" },
    { id: 17, label: "Editor", value: "EDITOR" },
    { id: 18, label: "Custom employee", value: "CUSTOM_EMPLOYEE" },
    { id: 19, label: "Reports", value: "REPORTS" },
    { id: 20, label: "Compliance", value: "COMPLIANCE" },
    { id: 21, label: "Management", value: "MANAGEMENT" },
    { id: 22, label: "Pay center management", value: "PAY_CENTRE_MANAGEMENT" },
    { id: 23, label: "Accounting", value: "ACCOUNTING" },
    {
      id: 24,
      label: "Manage treasury account",
      value: "MANAGE_TREASURY_ACCOUNT",
    },
    { id: 25, label: "Forex", value: "FOREX" },
    { id: 26, label: "Fee management", value: "FEE_MANAGEMENT" },
    { id: 27, label: "Marketing CRM", value: "MARKETING_CRM" },
    { id: 28, label: "", value: "SALES_PERSON" },
    { id: 29, label: "IT security", value: "IT_SECURITY" },
    { id: 30, label: "Fraud prevention team", value: "FRAUD_PREVENTION_TEAM" },
    { id: 31, label: "Regulator", value: "REGULATOR" },
    { id: 32, label: "Payment collector", value: "PAYMENT_COLLECTOR" },
    { id: 33, label: "API transaction report", value: "APITRANSACTIONREPORT" },
  ];
};

export const commissionType = [
  { value: "FLAT", label: "FLAT", isFixed: true },
  { value: "PERCENTAGE", label: "PERCENTAGE", isFixed: true },
];

export const daysCount = [
  { id: 1, label: "Number of Days", value: "DAY" },
  { id: 2, label: "Days in the month", value: "DAY_OF_MONTH" },
];

export const feesType = [
  { id: 1, label: "Simple interest", value: "SIMPLE_INTEREST" },
  { id: 2, label: "Capitalized interest", value: "CAPITALIZED_INTEREST" },
  { id: 3, label: "Compound interest", value: "COMPOUND_INTEREST" },
];

export const paymentFeeMethods = [
  { id: 1, label: "Standard payments", value: "STANDARD_PAYMENTS" },
  { id: 2, label: "Balloon payments", value: "BALLOON_PAYMENTS" },
  { id: 3, label: "Payment plan", value: "PAYMENT_PLAN" },
];

export const intMethod = [
  { id: 1, label: "Fixed flat", value: "FIXED_FLAT" },
  { id: 2, label: "Declining balance", value: "DECLINING_BALANCE" },
  {
    id: 3,
    label: "Declining balance(equal installments)",
    value: "DECLINING_BALANCE_EQUAL_INSTALLMENTS",
  },
];

export const openingBalAction = (dictionary) => {
  return [
    { label: `${dictionary ? dictionary["para_all"] : "All"}`, value: "All" },
    {
      label: `${dictionary ? dictionary["para_transaction"] : "Transaction"}`,
      value: "TRANSACTION",
    },
    { label: "Admin", value: "ADMIN" },
    {
      label: `${
        dictionary ? dictionary["title_currency_buying"] : "Currency buy"
      }`,
      value: "CURRENCY_BUY",
    },
    { label: "Currency sell", value: "CURRENCY_SELL" },
    {
      label: `${dictionary ? dictionary["title_voucher"] : "Voucher"}`,
      value: "VOUCHER",
    },
    { label: "Top up", value: "TOP_UP" },
    {
      label: `${dictionary ? dictionary["para_bills"] : "Bill"}`,
      value: "BILL",
    },
  ];
};

export const walletBalAction = (dictionary) => {
  return [
    { label: `${dictionary ? dictionary["para_all"] : "All"}`, value: "All" },
    {
      label: `Wallet  money in`,
      value: "WALLET_MONEY_IN",
    },
    { label: "Wallet money out", value: "WALLET_MONEY_OUT" },
  ];
};
