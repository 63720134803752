import * as CashierBeneficiaryConstants from "../constants/cashierbeneficiary.constant";
import { Loading } from "../../utils/helpers/constants";

const initialState = {
  cashierBeneficiaries: [],
  loadingCashierBeneficiaries: "",
};

const cashierBeneficiaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CashierBeneficiaryConstants.SEARCH_AND_FINDCUSTOMER_REQUEST:
      return { ...state, loadingCashierBeneficiaries: Loading.FETCHING };

    case CashierBeneficiaryConstants.SEARCH_AND_FINDCUSTOMER_SUCCESS:
      return {
        ...state,
        loadingCashierBeneficiaries: Loading.SUCCESS,
        cashierBeneficiaries: action.payload,
      };

    case CashierBeneficiaryConstants.SEARCH_AND_FINDCUSTOMER_FAILURE:
      return {
        ...state,
        loadingCashierBeneficiaries: Loading.ERROR,
      };

    case CashierBeneficiaryConstants.REGISTER_CUSTOMER_BENEFICIARY_REQUEST:
      return { ...state, loadingCashierBeneficiaries: Loading.FETCHING };

    case CashierBeneficiaryConstants.REGISTER_CUSTOMER_BENEFICIARY_SUCCESS:
      return {
        ...state,
        loadingCashierBeneficiaries: Loading.SUCCESS,
        cashierBeneficiaries: action.payload,
      };

    case CashierBeneficiaryConstants.REGISTER_CUSTOMER_BENEFICIARY_FAILURE:
      return {
        ...state,
        loadingCashierBeneficiaries: Loading.ERROR,
      };

    default:
      return state;
  }
};

export default cashierBeneficiaryReducer;
