import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import Footer from "../../../../components/footer/Footer";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { toggleNav } from "../../../../store/actions/component.action";
import { fetchPaymentMethodsNext } from "../../../../store/actions/transfer.action";
import { Loading } from "../../../../utils/helpers/constants";
import Body from "./Body";

const SupportedPaymentMethod = () => {
  const location = useLocation(),
    dispatch = useDispatch();
  const { domainBrand, component, profile, transfers } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      profileReducer,
      transferReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      profile: profileReducer,
      transfers: transferReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    transfers.loading === Loading.FETCHING ||
    profile.userProfileLoading === Loading.FETCHING;

  useEffect(() => {
    dispatch(toggleNav(false));
    const amount = location?.state?.amount;

    if (location.state)
      dispatch(
        fetchPaymentMethodsNext({
          countryIsoCode3: location?.state?.countryIsoCode3,
          transactionType: location?.state?.transactionType,
          currency: location?.state?.currency,
          pcn: location?.state?.pcn || "",
          amount: amount !== undefined ? amount.toString() : "",
          payAbleType: location?.state?.payableType,
          appId: location?.state?.appId,
        })
      );
  }, [location.state]);

  if (!location.state) return <Redirect to="/transfer-history" />;

  console.log(location, "kmj");

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="transfer"
              active="transfers"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              activeData={location.state}
              supportedMethods={transfers["supportedMethods"]}
              extraData={transfers["expressData"]}
              supportedPaymentWallets={transfers["supportedPaymentWallets"]}
              terms={transfers["termsConditionsURL"]}
              profileData={profile["userData"]}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default SupportedPaymentMethod;
