import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Body from "./Body";
import Footer from "../../../../components/footer/Footer";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import { getKycRules } from "../../../../store/actions/compliance.action";
import { getCountries } from "../../../../store/actions/country.action";
import { Loading } from "../../../../utils/helpers/constants";

const KycRules = () => {
  const dispatch = useDispatch();
  const { domainBrand, component, compliance, country } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      complianceReducer,
      countryReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      compliance: complianceReducer,
      country: countryReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    compliance.fetchingKYC === Loading.FETCHING ||
    country.loadingCountries === Loading.FETCHING;

  useEffect(() => {
    if (country.loadingCountries !== Loading.SUCCESS) dispatch(getCountries());
    if (compliance.fetchingKYC !== Loading.SUCCESS) dispatch(getKycRules());
  }, []);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="compliance"
              active="kyc"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              rules={compliance}
              countries={country.supportedCountries}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default KycRules;
