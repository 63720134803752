import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import { formatAccountsDropdown } from "../../../../utils/helpers/logicHelper";
import { resetQuoteData } from "../../../../store/actions/transfer.action";
import { getProfile } from "../../../../store/actions/profile.action";
import { toggleNav } from "../../../../store/actions/component.action";
import {
  chargeCategory,
  Loading,
  transactionTypes,
  userTypes,
} from "../../../../utils/helpers/constants";
import AccountsService from "../../../../utils/services/accounts.service";

const FundWallet = () => {
  const dispatch = useDispatch();
  const [wallets, setWallets] = useState([]);
  const {
    domainBrand,
    component,
    profile,
    transfer: { quoteData },
    // transfer,
  } = useSelector(
    ({
      domainBrandReducer,
      componentReducer,
      profileReducer,
      transferReducer,
    }) => ({
      domainBrand: domainBrandReducer,
      component: componentReducer,
      profile: profileReducer,
      transfer: transferReducer,
    })
  );

  const loadState =
    domainBrand.loading === Loading.FETCHING ||
    profile.userProfileLoading === Loading.FETCHING;
  const quoteObject = {
    transactionType: transactionTypes.WALLET,
    chargeCategory: chargeCategory.WALLET_MONEY_LOAD_CASH,
    conversion: true,
    inverseCalculation: true,
  };

  useEffect(async () => {
    dispatch(toggleNav(false));
    dispatch(resetQuoteData());
    if (profile.userProfileLoading !== Loading.SUCCESS)
      await dispatch(getProfile());
    if (profile.userType === userTypes.CUSTOMER) {
      const { data: responseData } = await AccountsService.fetchAllWallets();
      const formattedPayload = formatAccountsDropdown(
        responseData.data.accounts
      );
      setWallets(formattedPayload);
    }
  }, [profile.userType]);

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? (
        <SphereLoader />
      ) : (
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
            <Header domainBrand={domainBrand} />
            <HeaderMenu
              menu="accounts"
              active="fund-wallet"
              domainBrand={domainBrand}
            />
            <Body
              domainBrand={domainBrand}
              profile={profile}
              wallets={wallets}
              // quote={quoteData}
              quote={quoteData}
              quoteObject={quoteObject}
            />
          </div>
          <Footer domainBrand={domainBrand} />
        </>
      )}
    </div>
  );
};

export default FundWallet;
