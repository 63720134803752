const actionCreator = (type, payload) => {
  //   console.log(type, "pp1");
  //   console.log(payload, "pp2");
  return {
    type,
    payload,
  };
};

export default actionCreator;

/** creates the action by taking in types and payload as parameter */
