import {useSelector} from "react-redux";

import SphereLoader from "../../../../components/loaders/SphereLoader";
import Header from "../../../../components/header/Header";
import HeaderMenu from "../../../../components/header/HeaderMenu";
import Footer from "../../../../components/footer/Footer";
import Body from "./Body";

import {Loading} from "../../../../utils/helpers/constants";

const FundAccounts = () => {
  const {domainBrand, component} = useSelector(
    ({componentReducer, domainBrandReducer}) => ({
    domainBrand: domainBrandReducer,
    component: componentReducer
  }));

  const loadState = domainBrand.loading === Loading.FETCHING;

  return (
    <div className={`page ${component["mobileNav"] ? "active" : ""}`}>
      {loadState ? <SphereLoader/> :
        <>
          <div className="page-main">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"/>
            <Header domainBrand={domainBrand}/>
            <HeaderMenu menu="accounting" active="fundAccounts" domainBrand={domainBrand}/>
            <Body/>
          </div>
          <Footer domainBrand={domainBrand}/>
        </>
      }
    </div>
  );
}

export default FundAccounts;
