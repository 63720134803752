import {useEffect, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import {toast} from "react-hot-toast";
import Select from "react-select";

import productImage from "../../../../assets/images/1.png";
import MerchantService from "../../../../utils/services/merchant.service";
import {Loading} from "../../../../utils/helpers/constants";

const Body = ({domainBrand, countries, services, products}) => {
  const history = useHistory();
  const menuPortalTarget = document.getElementById('root');
  const [country, selectCountry] = useState(countries[0]);
  const [searchInput, updateSearchInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(products);
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(typeof dictionary === "undefined" || dictionary === null);

  useEffect(() => {
    setFilteredProducts(filterSearch(searchInput));
  }, [searchInput]);

  const filterSearch = value => {
    return products.filter(data => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  const toggleCheckBox = (index, event) => {
    if (!event.target.checked) {
      const newArray = filteredProducts.filter(element => element["service_id"] !== index["service_id"]);
      setFilteredProducts(newArray);
    } else {
      const newArray = products.filter(element => element["service_id"] === index["service_id"]);
      const newFilteredProducts = filteredProducts.concat(newArray);
      setFilteredProducts(newFilteredProducts);
    }
  }

  const addToCart = async (event, item) => {
    event.preventDefault();
    const payload = {
      payableType: "MERCHANT",
      payableId: item.product_id,
      quantity: 1,
      productName: item["product_name"]
    }
    const {data: responseData} = await MerchantService.addToCart(payload);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else toast.success(`Item, ${item["product_name"]} added to cart`);
  }

  const quickView = (event, productDetails) => {
    event.preventDefault();
    const slug = productDetails["product_short_desc"].replace(/ /g, "");
    history.push({
      pathname: `/merchant/products/${slug}`,
      state: {data: productDetails}
    });
  }

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">Merchant Products</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to="/merchant/products">Shop</NavLink></li>
              <li className="breadcrumb-item active" aria-current="page">{country?.label ?? "Product"}</li>
            </ol>
          </div>
          <div className="ms-auto pageheader-btn"/>
        </div>
        <div className="row row-cards">
          <div className="col-xl-3 col-lg-4">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <form className="shop__filter card">
                  <div className="card-header">
                    <h3 className="card-title">
                      {languageStatus ? `${dictionary["l_filter"]} ${dictionary["para_countries"]}` : "Country"}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row gutters-xs">
                      <Select options={countries}
                              isClearable={false}
                              isSearchable={true}
                              maxMenuHeight={250}
                              menuPlacement="bottom"
                              menuPortalTarget={menuPortalTarget}
                              name="country"
                              value={country}
                              onChange={option => selectCountry(option)}/>
                    </div>
                  </div>
                </form>
                <div className="card">
                  <div className="card-header">
                    <div className="card-title"> Categories &amp; Filters</div>
                  </div>
                  <div className="card-body">
                    {services.map((item, index) => (
                      <div key={index} className="custom-checkbox custom-control">
                        <input type="checkbox" data-bs-checkboxes="mygroup" className="custom-control-input"
                               defaultChecked={true} onChange={e => toggleCheckBox(item, e)} id={index}/>
                        <label htmlFor={index} className="custom-control-label">{item.label}</label>
                      </div>
                    ))}
                    <div className="mb-5"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="card">
              <div className="row card-body p-2">
                <div className="col-sm-12 p-0">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search ..."
                           value={searchInput} onChange={e => updateSearchInput(e.target.value)}/>
                    <button className="input-group-text btn btn-primary">Search</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {filteredProducts.map((item, index) => (
                <div key={index} className="col-md-6 col-xl-4">
                  <div className="card item-card">
                    <div className="ribbone">
                      <div className="ribbon"><span>new</span></div>
                    </div>
                    <div className="product-grid6  card-body">
                      <div className="product-image6">
                        <img className="img-fluid" src={item["productMainPhotoUrl"]} alt="img"
                             onError={e => e.target.src = productImage}/>
                      </div>
                      <div className="product-content text-center">
                        <h4 className="title">
                          <div>{item["product_name"]}</div>
                        </h4>
                        <div className="price">{item["local_currency"] + " " + item["product_value"]}
                          {item["product_value"] === item["retail_price"] ? "" :
                            <span className="ms-4">{item["local_currency"] + " " + item["retail_price"]}</span>
                          }
                        </div>
                      </div>
                      <ul className="icons">
                        <li><NavLink onClick={e => quickView(e, item)} to="/" data-tip="Quick View">
                          <i className="fa fa-eye"/></NavLink>
                        </li>
                        <li><NavLink onClick={e => addToCart(e, item)} to="/" data-tip="Add to Cart">
                          <i className="fa fa-shopping-cart"/></NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mb-5">
              <div className="float-end">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
