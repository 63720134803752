import Webcam from "react-webcam";
import {useCallback, useRef, useState} from "react";
import HoverTooltip from "../tootltips/HoverTooltip";

const KYCWebcam = ({saveImage, cancel}) => {

  const webcamRef = useRef(null);
  const [image, setImage] = useState("");
  const retake = () => setImage("");
  const saveCapture = () => saveImage({src: image, type: "image"});
  const disableUpload = image === "";

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };

  return (
    <>
      {image === "" ?
        <Webcam
          audio={false}
          ref={webcamRef}
          mirrored={true}
          screenshotFormat="image/jpeg"
          width="100%"
          videoConstraints={videoConstraints}
        /> : <img src={image} alt="captured"/>
      }
      <>
        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-2">
          <div className="icons-list-wrap">
            <ul className="icons-list">
              <li className="icons-list-item pointer" onClick={retake}>
                <HoverTooltip title="Retake Image">
                  <i className="fa fa-redo"/>
                </HoverTooltip>
              </li>
              <li className="icons-list-item pointer" onClick={capture}>
                <HoverTooltip title="Capture Image">
                  <i className="fa fa-camera"/>
                </HoverTooltip>
              </li>
              <li className="icons-list-item pointer" disabled={disableUpload} onClick={saveCapture}>
                <HoverTooltip title="Upload Capture">
                  <i className="fa fa-cloud-upload-alt"/>
                </HoverTooltip>
              </li>
              <li className="icons-list-item pointer" onClick={event => cancel(event, "FILE")}>
                <HoverTooltip title="Cancel">
                  <i className="fa fa-times"/>
                </HoverTooltip>
              </li>
            </ul>
          </div>
        </div>
      </>
    </>
  );
}

export default KYCWebcam;
