
const SphereLoader = () => {
  return (
    <div className="dimmer active">
      <div className="spinner1">
        <div className="double-bounce1"/>
        <div className="double-bounce2"/>
      </div>
    </div>
  );
}

export default SphereLoader;
