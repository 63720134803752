import * as Constants from "../constants/merchant.constants";
import {Loading} from "../../utils/helpers/constants";
import {formatMerchantServices} from "../../utils/helpers/logicHelper";

const initialState = {
  services: [],
  products: [],
  cart: [],
  loadingServices: "",
  loadingProducts: "",
  loadingCart: "",
  numberOfItemsInCart: "",
  totalCostOfItems: "",
};

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {

    case Constants.FETCH_MERCHANT_SERVICES_REQUEST:
      return {...state, loadingServices: Loading.FETCHING};

    case Constants.FETCH_MERCHANT_SERVICES_SUCCESS:
      const services = formatMerchantServices(action.payload["serviceDTO"]);
      return {...state, services, loadingServices: Loading.SUCCESS};

    case Constants.FETCH_MERCHANT_SERVICES_FAILURE:
      return {...state, loadingServices: Loading.ERROR};

    case Constants.FETCH_MERCHANT_PRODUCTS_REQUEST:
      return {...state, loadingProducts: Loading.FETCHING};

    case Constants.FETCH_MERCHANT_PRODUCTS_SUCCESS:
      return {...state, loadingProducts: Loading.SUCCESS, products: action.payload["productDTO"]};

    case Constants.FETCH_MERCHANT_PRODUCTS_FAILURE:
      return {...state, loadingProducts: Loading.ERROR};

    case Constants.FETCH_ITEMS_IN_CART_REQUEST:
      return {...state, loadingCart: Loading.FETCHING};

    case Constants.FETCH_ITEMS_IN_CART_SUCCESS:
      const cart = action.payload["cart"];
      const numberOfItemsInCart = action.payload["numberOfItemsInCart"];
      const totalCostOfItems = action.payload["totalCostOfItems"];
      return {...state, loadingCart: Loading.SUCCESS, cart, numberOfItemsInCart, totalCostOfItems};

    case Constants.FETCH_ITEMS_IN_CART_FAILURE:
      return {...state, loadingCart: Loading.ERROR};

    default:
      return state;

  }
}

export default merchantReducer;
