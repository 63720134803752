import ActionCreator from "../../utils/helpers/actionCreator";
import * as Constants from "../constants/merchant.constants";
import MerchantService from "../../utils/services/merchant.service";
import {expiredTokenCheck} from "./auth.action";

export const getMerchantServices = destinationCountry => async dispatch => {
  try {
    dispatch(ActionCreator(Constants.FETCH_MERCHANT_SERVICES_REQUEST));
    const {data: responseData} = await MerchantService.fetchServices(destinationCountry);

    if (responseData.status === "FAILED") throw responseData;
    dispatch(ActionCreator(Constants.FETCH_MERCHANT_SERVICES_SUCCESS, responseData['data']));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Constants.FETCH_MERCHANT_SERVICES_FAILURE));
  }
}

export const getMerchantProducts = payload => async dispatch => {
  try {
    dispatch(ActionCreator(Constants.FETCH_MERCHANT_PRODUCTS_REQUEST));
    const {data: responseData} = await MerchantService.fetchProducts(payload);

    if (responseData.status === "FAILED") throw responseData;
    dispatch(ActionCreator(Constants.FETCH_MERCHANT_PRODUCTS_SUCCESS, responseData['data']));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Constants.FETCH_MERCHANT_PRODUCTS_FAILURE));
  }
}

export const getItemsInCart = () => async dispatch => {
  try {
    dispatch(ActionCreator(Constants.FETCH_ITEMS_IN_CART_REQUEST));
    const {data: responseData} = await MerchantService.fetchCart();

    if (responseData.status === "FAILED") throw responseData;
    dispatch(ActionCreator(Constants.FETCH_ITEMS_IN_CART_SUCCESS, responseData['data']));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Constants.FETCH_ITEMS_IN_CART_FAILURE));
  }
}
