import React, { useRef, useState } from "react";
// import { useDispatch } from "react-redux";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { linkAccounToBankConfig } from "../../../../../utils/services/bankfield.service";
import { toast } from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";

function EditCorridorForm({
  domainBrand,
  countries,
  currencies,
  bankConfigId,
  templateName,
}) {
  //   const dispatch = useDispatch();
  const formRef = useRef();
  const menuPortalTarget = document.getElementById("root");

  const [loading, setLoading] = useState(false);

  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );

  const schema = yup
    .object({
      templateName: yup.string().required(),
      currency: yup.object({}).required(),
      country: yup.array().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { currency: "", country: "", templateName: templateName },
  });

  const onSubmit = async (data) => {
    const countryArray = data?.country?.map((item) => {
      return item?.iso3Code;
    });

    const payload = {
      linkAccountToBankRequestDTOs: [
        {
          bankConfigurationId: bankConfigId,
          countries: countryArray,
          currencyCode: data?.currency?.currencyCode,
          templateName: data?.templateName,
        },
      ],
    };

    if (payload) {
      setLoading(true);
      const res = await linkAccounToBankConfig(payload);
      if (res.status === 200) {
        toast.success("account linked successfully");
        setLoading(false);
        reset({ templateName: "", currency: "", country: "" });
        // dispatch(getBankFields());
      }
    }
  };

  return (
    <form
      className="needs-validation"
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <div className="form-row">
        <div className="col-md-6 col-lg-6 mb-3 d-flex flex-column">
          <label>
            {languageStatus
              ? dictionary["WO_TEMPLATE_NAM_2095784414"]
              : "Template Name"}
            <span style={{ color: "red" }}> *</span>
          </label>

          <input
            type={"text"}
            className="form-control"
            id="templateName"
            name="templateName"
            {...register("templateName", {
              required: {
                value: "",
                message: "Template Name is required",
              },
            })}
          />
          <p
            className="text-danger fst-italic pt-1"
            style={{ fontSize: "12px" }}
          >
            {errors.templateName?.type === "required" &&
              "Template Name is required"}
          </p>
        </div>

        <div className="col-md-6 col-lg-6 mb-3">
          <label>
            {languageStatus ? dictionary["WO_CURRENCY_1471979772"] : "Currency"}
            <span style={{ color: "red" }}> *</span>
          </label>
          <Controller
            name="currency"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  options={currencies}
                  isClearable={true}
                  isSearchable={true}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                />
              );
            }}
          />
        </div>

        <div className="col-md-6 col-lg-6 mb-3">
          <label>
            {languageStatus
              ? dictionary["WO_SELECT_COUNTRY_1277685784"]
              : "Select Country (Select multiple countries)"}
            <span style={{ color: "red" }}> *</span>
          </label>
          <Controller
            name="country"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  options={countries}
                  isMulti
                  isClearable={true}
                  isSearchable={true}
                  maxMenuHeight={250}
                  menuPlacement="bottom"
                  menuPortalTarget={menuPortalTarget}
                />
              );
            }}
          />
        </div>

        <div className="col-md-6 col-lg-6">
          <button
            className="btn btn-primary margin-label width-100 fw-bold"
            type="submit"
          >
            {loading ? (
              <Spinner animation="border" variant="dark" className="py-1" />
            ) : (
              "SAVE"
            )}
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditCorridorForm;
