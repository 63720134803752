export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const VALIDATE_OTP_REQUEST = "VALIDATE_OTP_REQUEST";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const AWAITING_TOKEN = "AWAITING_TOKEN";
export const DISABLED_AWAITING_TOKEN = "DISABLED_AWAITING_TOKEN";
export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";

export const LOG_OUT = "LOG_OUT";
