export const GET_QUOTE_REQUEST = "GET_QUOTE_REQUEST";
export const GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS";
export const GET_QUOTE_FAILURE = "GET_QUOTE_FAILURE";

export const RESET_QUOTE_SUCCESS = "RESET_QUOTE_SUCCESS";

export const FETCH_MONTHLY_TRANSACTIONS_REQUEST =
  "FETCH_MONTHLY_TRANSACTIONS_REQUEST";
export const FETCH_MONTHLY_TRANSACTIONS_SUCCESS =
  "FETCH_MONTHLY_TRANSACTIONS_SUCCESS";
export const FETCH_MONTHLY_TRANSACTIONS_FAILURE =
  "FETCH_MONTHLY_TRANSACTIONS_FAILURE";

export const INITIATE_WALLET_REQUEST = "INITIATE_WALLET_REQUEST";
export const INITIATE_WALLET_SUCCESS = "INITIATE_WALLET_SUCCESS";
export const INITIATE_WALLET_FAILURE = "INITIATE_WALLET_FAILURE";

export const BOOK_WALLET_TO_WALLET_REQUEST = "BOOK_WALLET_TO_WALLET_REQUEST";
export const BOOK_WALLET_TO_WALLET_SUCCESS = "BOOK_WALLET_TO_WALLET_SUCCESS";
export const BOOK_WALLET_TO_WALLET_FAILURE = "BOOK_WALLET_TO_WALLET_FAILURE";

export const INITIATE_TRANSFER_REQUEST = "INITIATE_TRANSFER_REQUEST";
export const INITIATE_TRANSFER_SUCCESS = "INITIATE_TRANSFER_SUCCESS";
export const INITIATE_TRANSFER_FAILURE = "INITIATE_TRANSFER_FAILURE";

export const INITIATE_BILL_PAYMENT_REQUEST = "INITIATE_BILL_PAYMENT_REQUEST";
export const INITIATE_BILL_PAYMENT_SUCCESS = "INITIATE_BILL_PAYMENT_SUCCESS";
export const INITIATE_BILL_PAYMENT_FAILURE = "INITIATE_BILL_PAYMENT_FAILURE";

export const INITIATE_AIRTIME_TOPUP_REQUEST = "INITIATE_AIRTIME_TOPUP_REQUEST";
export const INITIATE_AIRTIME_TOPUP_SUCCESS = "INITIATE_AIRTIME_TOPUP_SUCCESS";
export const INITIATE_AIRTIME_TOPUP_FAILURE = "INITIATE_AIRTIME_TOPUP_FAILURE";

export const MAKE_WALLET_REQUEST = "MAKE_WALLET_REQUEST";
export const MAKE_WALLET_SUCCESS = "MAKE_WALLET_SUCCESS";
export const MAKE_WALLET_FAILURE = "MAKE_WALLET_FAILURE";

export const CANCEL_TRANSACTION_REQUEST = "CANCEL_TRANSACTION_REQUEST";
export const CANCEL_TRANSACTION_SUCCESS = "CANCEL_TRANSACTION_SUCCESS";
export const CANCEL_TRANSACTION_FAILURE = "CANCEL_TRANSACTION_FAILURE";

export const FETCH_PAYMENT_METHODS_REQUEST = "FETCH_PAYMENT_METHODS_REQUEST";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAILURE = "FETCH_PAYMENT_METHODS_FAILURE";

export const FETCH_PAYCENTRE_LIST_REQUEST = "FETCH_PAYCENTRE_LIST_REQUEST";
export const FETCH_PAYCENTRE_LIST_SUCCESS = "FETCH_PAYCENTRE_LIST_SUCCESS";
export const FETCH_PAYCENTRE_LIST_FAILURE = "FETCH_PAYCENTRE_LIST_FAILURE";
