import * as RateConstants from "../constants/rate.constant";
import { Loading } from "../../utils/helpers/constants";

const initialState = {
  exchangeRates: [],
  loadingRates: "",
  corridor: [],
  paycenters: [],
};

const rateReducer = (state = initialState, action) => {
  switch (action.type) {
    case RateConstants.EXCHANGE_RATE_REQUEST:
      return { ...state, loadingRates: Loading.FETCHING };

    case RateConstants.EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        loadingRates: Loading.SUCCESS,
        exchangeRates: action.payload,
      };

    case RateConstants.EXCHANGE_RATE_FAILURE:
      return {
        ...state,
        loadingRates: Loading.ERROR,
      };

    case RateConstants.LINK_TO_EXCHANGE_RATE_CORRIDOR_REQUEST:
      return { ...state, loadingRates: Loading.FETCHING };

    case RateConstants.LINK_TO_EXCHANGE_RATE_CORRIDOR_SUCCESS:
      return {
        ...state,
        loadingRates: Loading.SUCCESS,
        corridor: action.payload,
      };

    case RateConstants.LINK_TO_EXCHANGE_RATE_CORRIDOR_FAILURE:
      return {
        ...state,
        loadingRates: Loading.ERROR,
      };

    case RateConstants.PAYCENTER_REQUEST:
      return { ...state, loadingRates: Loading.FETCHING };

    case RateConstants.PAYCENTER_SUCCESS:
      return {
        ...state,
        loadingRates: Loading.SUCCESS,
        paycenters: action.payload,
      };

    case RateConstants.PAYCENTER_FAILURE:
      return {
        ...state,
        loadingRates: Loading.ERROR,
      };

    case RateConstants.ADD_EXCHANGE_RATE_REQUEST:
      return { ...state, loadingRates: Loading.FETCHING };

    case RateConstants.ADD_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        loadingRates: Loading.SUCCESS,
        exchangeRates: action.payload,
      };

    case RateConstants.ADD_EXCHANGE_RATE_FAILURE:
      return { ...state, loadingRates: Loading.ERROR };

    case RateConstants.EDIT_EXCHANGE_RATE_REQUEST:
      return { ...state, loadingRates: Loading.FETCHING };

    case RateConstants.EDIT_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        loadingRates: Loading.SUCCESS,
        exchangeRates: action.payload,
      };

    case RateConstants.EDIT_EXCHANGE_RATE_FAILURE:
      return { ...state, loadingRates: Loading.ERROR };

    case RateConstants.DELETE_EXCHANGE_RATE_REQUEST:
      return { ...state, loadingRates: Loading.FETCHING };

    case RateConstants.DELETE_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        loadingRates: Loading.SUCCESS,
        exchangeRates: action.payload,
      };

    case RateConstants.DELETE_EXCHANGE_RATE_FAILURE:
      return { ...state, loadingRates: Loading.ERROR };

    default:
      return state;
  }
};

export default rateReducer;
