import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";

function SuspendModal({
  show,
  handleClose,
  handleSuspend,
  handleUnsuspend,
  type,
  loading,
  employee,
  aml,
}) {
  return (
    <Modal show={show} backdrop="static" keyboard={false} onHide={handleClose}>
      <Modal.Header closeButton={false}>
        {type === "SUSPEND" && (
          <Modal.Title>
            <h5 className="fw-bold">Suspend</h5>
          </Modal.Title>
        )}

        {type === "UNSUSPEND" && (
          <Modal.Title>
            <h5 className="fw-bold">Unsuspend</h5>
          </Modal.Title>
        )}

        {type === "SUSPEND-EMPLOYEE" && (
          <Modal.Title>
            <h5 className="fw-bold">
              Suspend {employee.firstName} {employee.lastName}
            </h5>
          </Modal.Title>
        )}

        {type === "DEACTIVATE-AML" && (
          <Modal.Title>
            <h5 className="fw-bold">Deactivate {aml}</h5>
          </Modal.Title>
        )}

        {type === "ACTIVATE-AML" && (
          <Modal.Title>
            <h5 className="fw-bold">Activate {aml}</h5>
          </Modal.Title>
        )}
      </Modal.Header>
      {type === "SUSPEND" && (
        <Modal.Body>{`Do you want to suspend this corridor?`}</Modal.Body>
      )}

      {type === "SUSPEND-EMPLOYEE" && (
        <Modal.Body>{`Do you want to suspend this employee?`}</Modal.Body>
      )}

      {type === "UNSUSPEND" && (
        <Modal.Body>{`Do you want to Unsuspend this corridor?`}</Modal.Body>
      )}

      {type === "DEACTIVATE-AML" && (
        <Modal.Body>{`Do you want to Deactivate ${aml}?`}</Modal.Body>
      )}

      {type === "ACTIVATE-AML" && (
        <Modal.Body>{`Do you want to Activate ${aml}?`}</Modal.Body>
      )}

      <Modal.Footer>
        {type === "SUSPEND" && (
          <>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleSuspend}>
              Yes{" "}
              {loading ? (
                <>
                  &nbsp;
                  <i className="fa fa-spinner fa-spin" />
                </>
              ) : (
                ""
              )}
            </Button>
          </>
        )}

        {type === "SUSPEND-EMPLOYEE" || type === "DEACTIVATE-AML" ? (
          <>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleSuspend}>
              Yes{" "}
              {loading ? (
                <>
                  &nbsp;
                  <i className="fa fa-spinner fa-spin" />
                </>
              ) : (
                ""
              )}
            </Button>
          </>
        ) : (
          ""
        )}

        {type === "UNSUSPEND" || type === "ACTIVATE-AML" ? (
          <>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleUnsuspend}>
              Yes{" "}
              {loading ? (
                <>
                  &nbsp;
                  <i className="fa fa-spinner fa-spin" />
                </>
              ) : (
                ""
              )}
            </Button>
          </>
        ) : (
          ""
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default SuspendModal;

SuspendModal.defaultProps = {
  handleClose: () => {},
  handleSuspend: () => {},
  handleUnsuspend: () => {},
};

SuspendModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSuspend: PropTypes.func.isRequired,
  handleUnsuspend: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  beneficiaryCode: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  employee: PropTypes.object.isRequired,
  aml: PropTypes.string.isRequired,
};
