import ActionCreator from "../../utils/helpers/actionCreator";
import * as CashierBeneficiaryConstants from "../constants/cashierbeneficiary.constant";
import { expiredTokenCheck } from "./auth.action";
import { toast } from "react-hot-toast";

import {
  searchAndFindcustomer,
  addNewCustomerBeneficiary,
} from "../../utils/services/cashierbeneficiary.service";

export const findCashierBeneficiary = (payload) => async (dispatch) => {
  try {
    dispatch(
      ActionCreator(CashierBeneficiaryConstants.SEARCH_AND_FINDCUSTOMER_REQUEST)
    );
    const { data: responseData } = await searchAndFindcustomer(payload);

    if (responseData.status === "FAILED") throw responseData;

    dispatch(
      ActionCreator(
        CashierBeneficiaryConstants.SEARCH_AND_FINDCUSTOMER_SUCCESS,
        responseData.data
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(
      ActionCreator(CashierBeneficiaryConstants.SEARCH_AND_FINDCUSTOMER_FAILURE)
    );
  }
};

export const addCustomerBeneficiary = (payload) => async (dispatch) => {
  try {
    dispatch(
      ActionCreator(
        CashierBeneficiaryConstants.REGISTER_CUSTOMER_BENEFICIARY_REQUEST
      )
    );
    const { data: responseData } = await addNewCustomerBeneficiary(payload);

    if (responseData.status === "FAILED") throw responseData;
    dispatch(
      ActionCreator(
        CashierBeneficiaryConstants.REGISTER_CUSTOMER_BENEFICIARY_SUCCESS
      )
    );

    toast.success("Customer registered successfully");
  } catch (error) {
    dispatch(
      ActionCreator(
        CashierBeneficiaryConstants.REGISTER_CUSTOMER_BENEFICIARY_FAILURE
      )
    );
    toast.error(`Registration failed`);
  }
};
