import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/index";
import { getExternalSite, getStoreOnReload } from "./store/actions/auth.action";

import "./index.css";
import "./assets/scss/sidebar.scss";
import "./assets/scss/skin-modes.scss";
import "./assets/scss/colors.scss";
import "./assets/scss/extra.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import NetworkModal from "./components/modals/NetworkModal";
// ToDo - Added a resolution to package, for bug caused by react-scripts 4.0.3, react hot reload did not work,
//  so this is here to avoid bug; https://github.com/facebook/create-react-app/issues/11773: 11771

const store = configureStore();
getStoreOnReload(store).catch((error) => console.log(error));

const AppRoot = () => {
  const [networkStatus, updateNetworkStatus] = useState(navigator.onLine);
  const updateNetwork = () => updateNetworkStatus(navigator.onLine);
  const url = window.location.href.split("?token=")[0];
  const token = window.location.href.split("?token=")[1];
  if (typeof token !== "undefined" && token !== "")
    getExternalSite(token, url, store).catch((err) => console.log(err));

  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  }, []);

  const networkRender = !networkStatus ? <NetworkModal /> : null;

  return (
    <Provider store={store}>
      <App />
      {networkRender}
    </Provider>
  );
};

if (window.Cypress) {
  window.store = store;
}

ReactDOM.render(<AppRoot />, document.getElementById("root"));
reportWebVitals();
