import Axios from "../services/axios.service";

export const addBankFieldConfig = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/bank/config/bank-fields-configurations",
    data: payload,
  });
};

export const listBankFieldConfig = async () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/bank/config/list-bank-fields-configurations?page=1&size=10`,
  });
};

export const deleteBankFieldConfig = async (rule_id) => {
  console.log(rule_id, "l");
  return Axios({
    method: "DELETE",
    url: `web-services/api/v6/services/bank/config/delete-account-payment-rule/${rule_id}`,
  });
};

export const editBankFieldConfig = async (payload) => {
  return Axios({
    method: "PUT",
    url: `web-services/api/v6/services/bank/config/edit-bank-fields`,
    data: payload,
  });
};

export const linkAccounToBankConfig = async (payload) => {
  return Axios({
    method: "PUT",
    url: `web-services/api/v6/services/bank/config/link-account-to-bank-config`,
    data: payload,
  });
};
