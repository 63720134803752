import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import Select from "react-select";

import {
  getQuoteData,
  initiateWalletPayment,
} from "../../../../store/actions/transfer.action";
import {
  formatStringCurrency,
  navigatePageTo,
  // navigatePageToDetails,
} from "../../../../utils/helpers/logicHelper";
import { userTypes } from "../../../../utils/helpers/constants";
import AccountsService from "../../../../utils/services/accounts.service";

// cashier goes to transfer details "/transfer/details"

const Body = ({ domainBrand, profile, wallets, quote, quoteObject }) => {
  const dispatch = useDispatch(),
    history = useHistory();
  const menuPortalTarget = document.getElementById("root");
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(
    typeof dictionary === "undefined" || dictionary === null
  );
  const [loading, updateLoading] = useState(false);
  const [searchParamValid, updateSearchParamValid] = useState(false);
  const [searchParam, updateSearchParam] = useState("");
  const [password, updatePassword] = useState("");
  const [accountWallet, updateAccountWallet] = useState("");
  const [wallet, updateWallet] = useState("");
  const [amount, updateAmount] = useState("");
  const disableIfEmpty = !amount;

  useEffect(() => {
    if ((wallet && amount) || (accountWallet && amount)) {
      quoteObject.deriveAmount = amount;
      quoteObject.tradeOriginatingCountry =
        profile.userData?.address["countryIso3"] || "";
      quoteObject.principalAmountCurrency =
        profile.userType === userTypes.CUSTOMER
          ? wallet["walletAccountCurrency"]
          : "GBP";
      quoteObject.deriveAmountCurrency =
        profile.userType === userTypes.CUSTOMER
          ? wallet["walletAccountCurrency"]
          : "GBP";
      const toggleEitherUserTypeWallet = wallet || accountWallet;
      if (toggleEitherUserTypeWallet && amount)
        dispatch(getQuoteData(quoteObject));
    }
  }, [amount, wallet, accountWallet]);

  const accountLookUp = async (event) => {
    event.preventDefault();
    updateLoading(true);
    updateSearchParamValid(false);
    const data = { findbyAccountNumber: searchParam };
    const { data: responseData } = await AccountsService.accountLookUp(data);
    if (responseData.status === "FAILED") toast.error(responseData.message);
    else {
      updateAccountWallet(responseData.data);
      updateSearchParamValid(true);
    }
    updateLoading(false);
  };

  const fundWalletAccount = () => {
    const accountNumber =
      profile.userType === userTypes.CASHIER
        ? searchParam
        : wallet["walletAccountNumber"];
    const accountCurrency =
      profile.userType === userTypes.CASHIER
        ? accountWallet["currencyCode"]
        : wallet["walletAccountCurrency"];

    const dataBody = {
      accountNumber,
      accountCurrency,
      amountDepositing: amount,
    };

    if (profile.userType === userTypes.CASHIER) {
      dataBody.accountOwnerCustomerId = accountWallet.beneficiaryId;
    }
    if (profile.userType === userTypes.CASHIER) {
      dataBody.cashierPassword = password;
    }

    const paymentMethodOptions = {
      accountNumber,
      referrer: "fundWallet",
      sendAmount: formatStringCurrency(amount),
      sendingCurrency: accountCurrency,
      receivingCurrency: accountCurrency,
      appId: profile["userData"]["id"],
    };

    // if (profile.userType === userTypes.CASHIER) {
    //   const navigator = navigatePageTo(history, "/transfer/details");
    //   history.push({ pathname: "", state: { quoteObject } });
    //   dispatch(
    //     initiateWalletPayment(dataBody, navigator, paymentMethodOptions)
    //   );
    // } else {
    const navigator = navigatePageTo(
      history,
      "/transfer/choose-payment-method"
    );
    history.push({ pathname: "", state: { quoteObject } });
    dispatch(initiateWalletPayment(dataBody, navigator, paymentMethodOptions));
  };

  return (
    <div className="app-content hor-content">
      <div className="container">
        <div className="page-header">
          <div>
            <h1 className="page-title">
              {languageStatus ? dictionary["l_fund_wallet"] : "Add Money"}
            </h1>
          </div>
          <div className="ms-auto pageheader-btn" />
        </div>
        <div className="row">
          {/* LEFT */}
          <div className="col-lg-6 col-md-6">
            <div className="card overflow-hidden height-100 mb-2">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">Fund my wallet account</h3>
              </div>
              <div className="card-body">
                <div className="needs-validation">
                  <div className="form-row">
                    {profile.userType === userTypes.CUSTOMER ? (
                      <div className="col-md-12 mb-3">
                        <label>Select Wallet</label>
                        <Select
                          options={wallets}
                          isClearable={false}
                          isSearchable={true}
                          maxMenuHeight={250}
                          menuPlacement="bottom"
                          menuPortalTarget={menuPortalTarget}
                          name="wallet"
                          value={wallet}
                          onChange={(option) => updateWallet(option)}
                        />
                      </div>
                    ) : (
                      <div className="col-md-12 mb-3">
                        <label>Find Account</label>
                        <input
                          type="text"
                          className={`form-control ${
                            searchParamValid ? "is-valid" : ""
                          }`}
                          value={searchParam}
                          onChange={(event) =>
                            updateSearchParam(event.target.value)
                          }
                          required
                        />
                      </div>
                    )}
                    {accountWallet || wallet ? (
                      <div className="col-md-12 mb-3">
                        <label>Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          value={amount}
                          onChange={(event) => updateAmount(event.target.value)}
                          required
                        />
                        <div className="valid-feedback">Looks good!</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {accountWallet && profile.userType === userTypes.CASHIER ? (
                      <div className="col-md-12 mb-3">
                        <label>Password</label>
                        <input
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={(event) =>
                            updatePassword(event.target.value)
                          }
                          required
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {profile.userType === userTypes.CASHIER && !accountWallet ? (
                    <button
                      onClick={accountLookUp}
                      className="btn btn-primary mt-5"
                      type="button"
                    >
                      Find Account{" "}
                      {loading ? <i className="fa fa-spin fa-spinner" /> : ""}
                    </button>
                  ) : (
                    ""
                  )}

                  {wallet || accountWallet ? (
                    <button
                      disabled={disableIfEmpty}
                      onClick={fundWalletAccount}
                      className="btn btn-primary mt-5"
                      type="button"
                    >
                      Make Payment
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* RIGHT */}
          <div className="col-xl-6 col-md-6">
            <div className="card overflow-hidden height-100 mb-2">
              <div className="card-header bg-primary text-white">
                <div className="card-title">Transaction Details</div>
              </div>
              <div className="card-body">
                {Object.entries(quote).length < 1 &&
                !amount &&
                !wallet &&
                !accountWallet ? (
                  <>
                    {profile.userType === userTypes.CUSTOMER &&
                    Object.entries(quote).length < 1 ? (
                      <p className="text-muted">
                        Select an account and input an amount
                      </p>
                    ) : (
                      <p className="text-muted">
                        Find an account in order to fund a wallet
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    {accountWallet ? (
                      <div className="row">
                        <div className="col-6">
                          <p className="text-muted">Account Name</p>
                          <p>{`${accountWallet["firstName"]} ${accountWallet["lastName"]}`}</p>
                        </div>
                        <div className="col-6">
                          <p className="text-muted">Beneficiary ID</p>
                          <p>{accountWallet["code"]}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {Object.entries(quote).length > 0 ? (
                      <>
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus
                                ? dictionary["para_current_balance"]
                                : "Current Balance"}
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            {accountWallet ? (
                              "-"
                            ) : (
                              <p>
                                {quote["sendingCurrency"]}&nbsp;
                                <span className="fw-bold">
                                  {formatStringCurrency(
                                    wallet["walletAccountBalance"]
                                  )}
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus
                                ? dictionary["para_exchange_rate"]
                                : "Exchange Rate"}
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            <p>
                              {quote["sendingCurrency"]}{" "}
                              <span className="fw-bold">1.00</span>&nbsp; ={" "}
                              {quote["sendingCurrency"]}{" "}
                              <span className="fw-bold">
                                {quote["fxRate"] || "1.00"}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus
                                ? dictionary["para_payment_fee"]
                                : "Fee"}
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            <p>
                              {quote["sendingCurrency"]}{" "}
                              <span className="fw-bold">
                                {quote["payment_method_fee"].toFixed(2)}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus ? dictionary["l_vat"] : "Tax"}
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            <p>
                              {quote["sendingCurrency"]}{" "}
                              <span className="fw-bold">
                                {quote["tax"].toFixed(2)}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus
                                ? dictionary["para_commission"]
                                : "Commission"}
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            <p>
                              {quote["sendingCurrency"]}{" "}
                              <span className="fw-bold">
                                {quote["sendMoneyfee"].toFixed(2)}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="text-muted">
                              {languageStatus
                                ? dictionary["para_total_amount_to_pay"]
                                : "Total Amount"}
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            <p>
                              {quote["sendingCurrency"]}{" "}
                              <span className="fw-bold">
                                {quote["total_amount"].toFixed(2)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
